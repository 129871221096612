import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import L from 'lodash'
import { Avatar } from 'antd'
import { resolveTemplate } from './templates'
import { TimeAgo } from '../../components/formatters'

const ActivityLog = ({ activity = [], loaded = false }) => {
  const keyGen = (activityItem) => `${activityItem.kind}_${activityItem.date}`
  return (
    <Box>
      <Text sx={{ mb: '20px', fontWeight: 'bold' }}>Activity</Text>
      {loaded && L.isEmpty(activity) && (
        <Text>No activity at this stage :)</Text>
      )}
      {loaded &&
        L.isArray(activity) &&
        !L.isEmpty(activity) &&
        activity.map((ac) => {
          const template = resolveTemplate(ac)

          if (template) {
            return (
              <Box
                key={keyGen(ac)}
                sx={{
                  p: '20px 0',
                  borderBottom: '1px solid #eee',
                  ':last-child': { border: 0 },
                }}
              >
                <Flex>
                  <Box
                    sx={{
                      width: '44px',
                      textAlign: 'left',
                    }}
                  >
                    <Avatar src={ac.avatarUrl} />
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      pt: '4px',
                    }}
                  >
                    <Text sx={{ wordBreak: 'break-word' }}>
                      {template.text}
                    </Text>
                    <Text
                      sx={{ fontSize: '14px', mt: '5px', color: '#11336a6a' }}
                    >
                      <TimeAgo date={template.date} />
                    </Text>
                  </Box>
                </Flex>
              </Box>
            )
          }
          return null
        })}
    </Box>
  )
}

export default ActivityLog
