/* eslint-disable react/no-danger */
/** @jsx jsx */
import { Divider, Checkbox, Tooltip, Collapse } from 'antd'
import { jsx, Box, Flex, Text } from 'theme-ui'
import { useCallback, useState } from 'react'
import { Engines } from '@spectral/types'
import { Instructions } from './instructions'
import tracker from '../../common/track'
import SnippetView from './snippet-view'
import {
  SECRET_MODE_DEFAULT_LEVEL,
  SecretsEngineModePicker,
} from './snippets-viewer/spectral-engine-scan-mode'
import { generateOptionsString } from './snippets-viewer/scanner-cli-options'
import { EnginesCheckedState, isEngineSolelyChecked } from './utils/engines'

const { Panel } = Collapse

const EnginePicker = ({
  tooltip,
  text,
  engine,
  checked,
  disabled = false,
  onChangeEngineState,
}: {
  tooltip: string
  text: string
  engine: Engines
  checked: boolean
  disabled: boolean
  onChangeEngineState: Function
}) => {
  const tooltipToPresent = disabled
    ? 'At least one engine should be selected'
    : tooltip
  return (
    <Flex>
      <Box sx={{ mr: '10px' }}>
        <Tooltip title={tooltipToPresent}>
          <Checkbox
            disabled={disabled}
            checked={checked}
            onChange={(event) =>
              onChangeEngineState(event.target?.checked || false, engine)
            }
          />
        </Tooltip>
      </Box>

      <Text sx={{ flexBasis: '250px' }}>{text}</Text>
    </Flex>
  )
}

const SnippetsViewer = ({
  snippets,
  dynamicData,
  onCopy = null,
  instructions,
  isScansSettingHidden = false,
}) => {
  const [isEngineChecked, setIsEngineChecked] = useState<EnginesCheckedState>({
    [Engines.Secrets]: true,
    [Engines.IaC]: false,
    [Engines.OpenSource]: false,
    [Engines.Spectral]: false,
  })

  const [secretsEngineMode, setSecretsEngineMode] = useState<number>(
    SECRET_MODE_DEFAULT_LEVEL
  )

  const onChangeSecretScanMode = useCallback((mode: number) => {
    tracker.changedScanSpectralEnginesSecurityLevel(mode)
    setSecretsEngineMode(mode)
  }, [])

  const onChangeEngineState = useCallback(
    (event, engine: Engines) => {
      const newState: EnginesCheckedState = {
        ...isEngineChecked,
        [engine]: event,
      }
      tracker.changedScanEnginesSettings({ ...newState })
      setIsEngineChecked(newState)
    },
    [isEngineChecked]
  )

  const optionsStr = generateOptionsString([
    {
      engine: Engines.Secrets,
      isChecked: isEngineChecked[Engines.Secrets],
      additionalOptionsState: { mode: secretsEngineMode },
    },
    {
      engine: Engines.IaC,
      isChecked: isEngineChecked[Engines.IaC],
      additionalOptionsState: {},
    },
    {
      engine: Engines.OpenSource,
      isChecked: isEngineChecked[Engines.OpenSource],
      additionalOptionsState: {},
    },
  ])

  return (
    <Box>
      {!isScansSettingHidden && (
        <Box>
          <Flex>
            <Collapse
              bordered={false}
              ghost
              activeKey={[
                isEngineChecked[Engines.Secrets] ? 'secret-picker-panel' : null,
              ]}
            >
              <Panel
                showArrow={false}
                header={
                  <EnginePicker
                    tooltip="Scan for misconfiguration and secrets."
                    text="Secrets and misconfigurations"
                    engine={Engines.Secrets}
                    checked={isEngineChecked[Engines.Secrets]}
                    disabled={isEngineSolelyChecked(
                      isEngineChecked,
                      Engines.Secrets
                    )}
                    onChangeEngineState={onChangeEngineState}
                  />
                }
                key="secret-picker-panel"
              >
                <SecretsEngineModePicker
                  secretsTagsEnd={secretsEngineMode}
                  onChangeScanMode={onChangeSecretScanMode}
                />
              </Panel>
              <Panel
                showArrow={false}
                header={
                  <EnginePicker
                    tooltip="Scan for misconfiguration in your IaC files (such as terraform, cloudformation, and more)."
                    text="Infrastructure as code"
                    engine={Engines.IaC}
                    checked={isEngineChecked[Engines.IaC]}
                    disabled={isEngineSolelyChecked(
                      isEngineChecked,
                      Engines.IaC
                    )}
                    onChangeEngineState={onChangeEngineState}
                  />
                }
                key="iac-picker-panel"
              />
              <Panel
                showArrow={false}
                header={
                  <EnginePicker
                    tooltip="Scan for vulnerabilities in your project's open source dependencies."
                    text="Open source packages"
                    engine={Engines.OpenSource}
                    checked={isEngineChecked[Engines.OpenSource]}
                    disabled={isEngineSolelyChecked(
                      isEngineChecked,
                      Engines.OpenSource
                    )}
                    onChangeEngineState={onChangeEngineState}
                  />
                }
                key="open-source-picker-panel"
              />
            </Collapse>
          </Flex>
          <Divider />
        </Box>
      )}
      {instructions && <Instructions instructions={instructions} />}
      <Box>
        {snippets.map((snippet) => (
          <SnippetView
            key={`${snippet?.type}${snippet?.title}`}
            snippet={snippet}
            dynamicData={{
              ...dynamicData,
              optionsStr,
            }}
            onCopy={onCopy}
          />
        ))}
      </Box>
    </Box>
  )
}
export default SnippetsViewer
