/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { Link } from 'react-router-dom'
import { Form, Input } from 'antd'
import {
  DialogPanel,
  PrimaryButtonLarge,
  WelcomeBar,
  SmallHeading,
  HGroup,
} from '../../components'
import ErrorMessage from '../../components/forms/error-message'
import Message from '../../components/forms/message'

const Recover = ({
  onSubmit,
  links: { signin },
  error = null,
  message = null,
}) => (
  <Box>
    <DialogPanel>
      <WelcomeBar />
      <Box sx={{ flex: 1 }}>
        <SmallHeading text="Reset your password" />
        <Box sx={{ p: 5, width: '500px' }}>
          {message && (
            <Box sx={{ mb: '20px' }}>
              <Message message={message} />
            </Box>
          )}
          {error ? (
            <Box sx={{ mb: '20px' }}>
              <ErrorMessage error={error} />
            </Box>
          ) : (
            false
          )}
          <Form layout="vertical" hideRequiredMark onFinish={onSubmit}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'We gotta have that email first.',
                },
                {
                  type: 'email',
                  message: 'Nope. That is not an email address.',
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <HGroup sx={{ mt: 5 }}>
              <PrimaryButtonLarge text="Send Email Instructions" isSubmit />
            </HGroup>
          </Form>
        </Box>
      </Box>
    </DialogPanel>
    <Box sx={{ padding: '30px', textAlign: 'center' }}>
      Already know your password? <Link to={signin}>Sign in</Link>
    </Box>
  </Box>
)

export default Recover
