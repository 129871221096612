import min from 'lodash/min'
import { Box } from 'theme-ui'
import React from 'react'
import AdditionalActionButton from './additional-action-button'

const SideFilterCollapse = ({
  showAllOptions,
  options,
  optionsToDisplay,
  maxItemsToDisplay,
  onExpandFilter,
  onCollapseFilter,
  currentCountItemsToDisplay,
}) => {
  const nextPageCount = min([
    maxItemsToDisplay,
    options.length - currentCountItemsToDisplay,
  ])

  const areAllOptionDisplayed =
    showAllOptions || options.length === optionsToDisplay.length

  const isCollapsible =
    currentCountItemsToDisplay > maxItemsToDisplay && !showAllOptions

  const shouldDisplayButtonsSection = isCollapsible || !areAllOptionDisplayed

  return shouldDisplayButtonsSection ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        height: '30px',
        lineHeight: '30px',
      }}
    >
      {!areAllOptionDisplayed && (
        <Box>
          <AdditionalActionButton
            text={`See more (+${nextPageCount})`}
            onClick={() => {
              const itemsToDisplay = currentCountItemsToDisplay + nextPageCount
              onExpandFilter(itemsToDisplay)
            }}
          />
        </Box>
      )}
      {isCollapsible && (
        <Box>
          <AdditionalActionButton
            text="Collapse"
            onClick={() => {
              onCollapseFilter()
            }}
          />
        </Box>
      )}
    </Box>
  ) : null
}

export default SideFilterCollapse
