/** @jsx jsx */
import { Grid, jsx } from 'theme-ui'
import { Card } from 'antd'

const PlanCard = ({ children }) => {
  return (
    <Card>
      <Grid
        sx={{
          gridTemplateColumns: '1fr',
          gridTemplateRows: '80px 90px 115px 50px 1fr',
          minHeight: '900px',
          height: '100%',
        }}
      >
        {children}
      </Grid>
    </Card>
  )
}

export default PlanCard
