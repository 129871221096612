import React from 'react'
import L from 'lodash'
import { notification } from 'antd'
import { useDispatch } from 'react-redux'
import { RoleType, SCMProvider } from '@spectral/types'
import {
  ContentPanel,
  ContentPanelHeader,
} from '../../../../components/panels/content'
import Restricted from '../../../role-based-render/restricted'
import GitOnPrem from '../../../../blocks/settings/scms/git-on-prem/view'
import githubIcon from '../../../../assets/svg/connect-github.svg'
import gitlabIcon from '../../../../assets/svg/gitlab.svg'
import bitbucketIcon from '../../../../assets/png/bitbucket.png'

const platforms = [
  {
    label: 'Github Enterprise',
    description: 'The custom domain of Github Enterprise on-premise instance',
    name: 'Github',
    key: 'github',
    icon: githubIcon,
  },
  {
    label: 'Gitlab Self Hosted',
    description: 'The custom domain of Gitlab self hosted instance',
    name: 'Gitlab',
    key: 'gitlab',
    icon: gitlabIcon,
  },
  {
    label: 'Bitbucket Server',
    description: 'The custom domain of Bitbucket server instance',
    name: 'Bitbucket',
    key: 'bitbucket',
    icon: bitbucketIcon,
  },
]

const SCMOnPremSettingsConnected = ({ scms }) => {
  const dispatch = useDispatch()

  const onUpdateGitOnPremDomain = async (
    scm: SCMProvider,
    domains: Array<string>
  ) => {
    return dispatch.SCMConfigurations.upsertSCMDomains({ scm, domains })
  }

  const onSubmit = async (domains: Array<string>, platformKey: SCMProvider) => {
    try {
      await onUpdateGitOnPremDomain(
        platformKey,
        domains.filter((domain) => domain !== '')
      )
    } catch (err) {
      notification.error({
        message: 'Failed',
        description: `Failed to set ${platformKey} on premise instance domain`,
      })
    }
  }
  return (
    <ContentPanel>
      <ContentPanelHeader>On premise instances</ContentPanelHeader>
      <Restricted roles={[RoleType.Owner, RoleType.Admin]}>
        {platforms.map((platform) => (
          <GitOnPrem
            key={platform.key}
            onPremDomains={L.get(scms, `${platform.key}.onPremDomains`)}
            onSubmit={onSubmit}
            label={platform.label}
            description={platform.description}
            platformId={platform.key}
            icon={platform.icon}
            otherDomains={
              scms &&
              Object.keys(scms)
                .filter((key) => key !== platform.key && key !== 'suggestions')
                .filter((scmKey) =>
                  Object.prototype.hasOwnProperty.call(
                    scms[scmKey],
                    'onPremDomains'
                  )
                )
                .map((key) => scms[key].onPremDomains)
                .flat()
            }
          />
        ))}
      </Restricted>
    </ContentPanel>
  )
}

export default SCMOnPremSettingsConnected
