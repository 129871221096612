/** @jsx jsx */
import { jsx, Box, Flex, Text } from 'theme-ui'
import { Link } from 'react-router-dom'
import zxcvbn from 'zxcvbn'
import { Form, Input, Button } from 'antd'
import { useEffect, useState } from 'react'
import { organizationNamePatternAsRegex } from '@spectral/types'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { ReactComponent as GoogleIcon } from '../../assets/svg/connect-google.svg'
import { ReactComponent as GithubIcon } from '../../assets/svg/connect-github.svg'
import { ReactComponent as AzureIcon } from '../../assets/svg/connect-azure.svg'
import {
  DialogPanel,
  PrimaryButtonLarge,
  LinkButton,
  WelcomeBar,
  SmallHeading,
  HGroup,
  Checkbox,
} from '../../components'
import { cubes } from '../../common/icons'
import ErrorMessage from '../../components/forms/error-message'
import PasswordStrengthBar from './password-strength-bar'
import {
  PASSWORD_STRENGTH_STEPS,
  strengthToPercent,
} from '../../common/password-strength'
import Message from '../../components/forms/message'
import { ReCaptchaFormItem } from '../../components/recaptcha'
import useReCaptcha from '../../hooks/useReCaptcha'

const Signup = ({
  onSubmit,
  isInvitation,
  invitationInfo: { email, company },
  links: { signin },
  error = null,
  successMessage = null,
  resendEmailVerification = null,
  isVerificationSent = false,
  recaptcha: {
    enabled: recaptchaEnabled = true,
    siteKey: recaptchaSiteKey = null,
  },
}) => {
  const [passwordStrength, setPasswordStrength] = useState(null)
  const onPasswordChange = (event) => {
    setPasswordStrength(
      event.target.value
        ? strengthToPercent(zxcvbn(event.target.value).score)
        : null
    )
  }

  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    setPasswordStrength(null)
  }, [successMessage, form])

  const [recaptchaRef, resetReCaptchaPostSubmit] = useReCaptcha({ onSubmit })
  const onFinish = resetReCaptchaPostSubmit

  return (
    <Box>
      <DialogPanel>
        <WelcomeBar />
        <Box sx={{ flex: 1 }}>
          <SmallHeading
            sx={{ py: '20px', textAlign: 'center' }}
            text={
              isInvitation ? 'Accept your invitation' : 'Create your account'
            }
          />
          <Box sx={{ px: '40px', pb: '40px', pt: isInvitation ? 3 : '20px' }}>
            {isInvitation && (
              <Text sx={{ mb: 3 }}>
                Hi <strong>{email}</strong>, please fill the form in order to
                join <strong>{company}</strong>
              </Text>
            )}
            {error && (
              <Box sx={{ mb: '20px' }}>
                <ErrorMessage error={error} />
              </Box>
            )}
            {successMessage && (
              <Flex sx={{ mb: '20px' }}>
                <Message
                  message={
                    <Box>
                      {successMessage}
                      <Box sx={{ mt: 2 }}>
                        {isVerificationSent ? (
                          <Flex sx={{ alignItems: 'center', gap: 2 }}>
                            <AiOutlineCheckCircle
                              size={20}
                              sx={{ color: 'green' }}
                            />
                            Verification email sent.
                          </Flex>
                        ) : (
                          <Button
                            size="small"
                            type="ghost"
                            onClick={resendEmailVerification}
                          >
                            Resend verification email
                          </Button>
                        )}
                      </Box>
                    </Box>
                  }
                />
              </Flex>
            )}

            <Form
              form={form}
              layout="vertical"
              hideRequiredMark
              onFinish={onFinish}
            >
              <Flex sx={{ flexDirection: ['column', null, 'row'] }}>
                <Form.Item
                  style={{ width: '100%', marginRight: '10px' }}
                  name="fullName"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input your name.',
                    },
                    { min: 2, message: 'Minimum 2 characters.' },
                    { max: 64, message: 'Maximum 64 characters.' },
                  ]}
                >
                  <Input
                    data-e2e-test="fullname-input"
                    width="100%"
                    placeholder="Your Name"
                    size="large"
                  />
                </Form.Item>
                {!isInvitation && (
                  <Form.Item
                    style={{ width: '100%' }}
                    name="company"
                    label="Organization"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please enter a company name.',
                      },
                      {
                        min: 2,
                        message: 'Minimum 2 characters.',
                      },
                      {
                        max: 64,
                        message: 'Maximum 64 characters.',
                      },
                      {
                        pattern: organizationNamePatternAsRegex,
                        message: 'Invalid character.',
                      },
                    ]}
                  >
                    <Input
                      data-e2e-test="company-input"
                      size="large"
                      placeholder="Organization Name"
                      disabled={isInvitation}
                    />
                  </Form.Item>
                )}
              </Flex>
              <Flex sx={{ flexDirection: ['column', null, 'row'] }}>
                {!isInvitation && (
                  <Form.Item
                    style={{ width: '100%', marginRight: '10px' }}
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: 'We gotta have that email first.',
                      },
                      {
                        type: 'email',
                        message: 'Nope. That is not an email address.',
                      },
                      {
                        max: 64,
                        message: 'Maximum 64 characters.',
                      },
                    ]}
                  >
                    <Input
                      data-e2e-test="email-input"
                      size="large"
                      placeholder="you@your-company.com"
                      disabled={isInvitation}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  style={{ width: '100%' }}
                  name="password"
                  label={
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text mr={3}>Password</Text>
                      {passwordStrength !== null && (
                        <PasswordStrengthBar
                          strength={passwordStrength}
                          steps={PASSWORD_STRENGTH_STEPS}
                        />
                      )}
                    </Flex>
                  }
                  rules={[
                    {
                      max: 64,
                      message: 'Maximum 64 characters.',
                    },
                    () => ({
                      validateTrigger: 'blur',
                      validator() {
                        if (passwordStrength === 100) {
                          return Promise.resolve()
                        }
                        return Promise.reject('Password is too weak')
                      },
                    }),
                  ]}
                >
                  <Input
                    data-e2e-test="password-input"
                    size="large"
                    placeholder="Password"
                    type="password"
                    onChange={onPasswordChange}
                  />
                </Form.Item>
              </Flex>
              <Form.Item
                valuePropName="checked"
                name="agreeterms"
                rules={[
                  {
                    // https://ant.design/components/form/
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : // eslint-disable-next-line prefer-promise-reject-errors
                          Promise.reject(
                            'Please accept the terms and conditions in order to continue'
                          ),
                  },
                ]}
              >
                <Checkbox data-e2e-test="agreeterms-checkbox">
                  I agree to the{' '}
                  <a href="tos.html" target="_blank">
                    terms and conditions
                  </a>
                </Checkbox>
              </Form.Item>
              <ReCaptchaFormItem
                enabled={recaptchaEnabled}
                sitekey={recaptchaSiteKey}
                recaptchaRef={recaptchaRef}
              />
              <HGroup sx={{ mt: 3 }}>
                <PrimaryButtonLarge
                  e2eData="signup-submit-btn"
                  text="Create Account"
                  icon={cubes}
                  isSubmit
                />
              </HGroup>
            </Form>

            <Box
              sx={{
                mt: '60px',
                mb: '20px',
                px: '40px',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridGap: '0.5rem',
                  gridTemplateColumns: '1fr max-content 1fr',
                  margin: '1.5rem 0',
                  alignItems: 'center',
                  ':before': {
                    content: "''",
                    borderBottom: '1px solid #a8a8a8',
                    transform: 'translateY(-1px)',
                  },
                  ':after': {
                    content: "''",
                    borderBottom: '1px solid #a8a8a8',
                    transform: 'translateY(-1px)',
                  },
                }}
              >
                Or sign up with
              </Box>
            </Box>
            <Flex
              sx={{
                flexDirection: ['column', null, 'row'],
                justifyContent: 'center',
              }}
            >
              <Box sx={{ mx: '10px', mb: '10px' }}>
                <LinkButton
                  sx={{ display: 'block' }}
                  icon={<GoogleIcon sx={{ width: 22, height: 22 }} />}
                  text="Google"
                  href="/api/connect/google"
                />
              </Box>
              <Box sx={{ mx: '10px', mb: '10px' }}>
                <LinkButton
                  sx={{ display: 'block' }}
                  icon={<GithubIcon sx={{ width: 22, height: 22 }} />}
                  text="Github"
                  href="/api/connect/github"
                />
              </Box>
              <Box sx={{ mx: '10px', mb: '10px' }}>
                <LinkButton
                  sx={{ display: 'block' }}
                  icon={<AzureIcon sx={{ width: 22, height: 22 }} />}
                  text="Azure Devops"
                  href="/api/connect/visualstudio"
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      </DialogPanel>
      <Box sx={{ padding: '30px', textAlign: 'center' }}>
        Already have an account? <Link to={signin}>Sign in</Link>
      </Box>
    </Box>
  )
}
export default Signup
