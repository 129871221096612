import { AssetCategory } from '@spectral/types'
import { concat, isEmpty, omit, pick, sortBy as LsortBy } from 'lodash'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import AppliedFilters from '../../../../components/applied-filters'
import DynamicDataFilter from '../../../../components/filters/dynamic-data-filter'
import SecretsListSideFilters, {
  SideFilterType,
} from '../../../../components/filters/side-filters'
import { Loading } from '../../../../components/loading'
import useDebouncedFieldFilter from '../../../../hooks/useDebouncedFieldFilter'
import useFiltersActions from '../../../../hooks/useFiltersActions'
import {
  buildAssignableMembersData,
  filtersSetup,
  sortOptions,
} from '../../shared/list-page-setup'
import SecretsListView from '../secrets-list/view'
import {
  removeEmptyFilters,
  transformAssigneeFiltersToDisplay,
  toFilterView,
  transformFiltersToDisplay,
} from '../../shared/transform-filters'
import PathFilter from '../../filters/path-filter'
import FiltersSidePanel from '../../../../containers/filters-side-panel'

const SecretsView = ({
  asset,
  secrets,
  filtersData,
  currentQueryStringParams,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  setQueryStringParam,
  clearQueryStringParam,
  trackSort,
  trackFilter,
  onPaginationChange,
  pagination,
  onSecretSelectionChanged,
  isRefreshing,
  actionHandlers,
  selectedSecrets,
  exportActions,
  isDownloadingExport,
  integrations,
  onActionIntegrationSubmit,
  loaded,
  isDataFiltered,
}) => {
  const { issueId, status } = currentQueryStringParams
  const [
    onDebouncedValueChange,
    currentDebouncedValues,
  ] = useDebouncedFieldFilter(
    {
      path: currentQueryStringParams.path,
      assignees: currentQueryStringParams.assignees,
      detectorId: currentQueryStringParams.detectorId,
      content: currentQueryStringParams.content,
      severity: currentQueryStringParams.severity,
      status: currentQueryStringParams.status,
    },
    setQueryStringParam,
    clearQueryStringParam,
    trackFilter,
    currentQueryStringParams
  )
  const filtersToAdd = []
  if (asset.category === AssetCategory.Code) {
    filtersToAdd.push({
      filter: {
        key: 'content',
        title: 'Content',
        component: DynamicDataFilter({
          searchable: false,
          showAllOptions: true,
        }),
        isActive: !isEmpty(filtersData.content),
        type: SideFilterType.Checkbox,
        data: toFilterView({
          filterData: filtersData.content,
          shouldCapitalize: true,
        }),
        collapsible: true,
      },
      index: 3,
    })
  }
  const isHiddenFilters =
    isEmpty(filtersData.status) && !isRefreshing && !isDataFiltered

  const filtersMetadata = filtersSetup(
    {
      assignableMembers: {
        ...buildAssignableMembersData(filtersData.assignees),
        isActive: !isHiddenFilters || filtersData.assignees?.length > 0,
      },
      ...filtersData,
      detectors: filtersData?.detectors || [],
      defaultStatus: status,
    },
    filtersToAdd
  )

  const fullDebouncedValues = removeEmptyFilters(currentDebouncedValues)

  const filtersToDisplay = transformFiltersToDisplay(
    omit(fullDebouncedValues, 'assignees')
  )

  const assigneesFilterToDisplay = transformAssigneeFiltersToDisplay(
    pick(fullDebouncedValues, 'assignees'),
    assignableMembers
  )

  const currentFiltersData = LsortBy(
    concat(filtersToDisplay, assigneesFilterToDisplay),
    'filterName'
  )

  const filterActions = useFiltersActions({
    setFilter: onDebouncedValueChange,
    trackFilter,
    clearFilter: clearQueryStringParam,
  })

  return (
    <Flex sx={{ height: '100%', flexDirection: 'column' }}>
      {!isEmpty(currentFiltersData) && (
        <Box sx={{ mb: 3 }}>
          <AppliedFilters
            filters={currentFiltersData}
            onClearAll={() =>
              filterActions.onCheckboxFiltersClear(
                currentFiltersData.map(({ filterKey }) => filterKey)
              )
            }
            onRemoveFilter={filterActions.onCheckboxFilterCheck}
            onClearFilter={filterActions.onCheckboxFiltersClear}
          />
        </Box>
      )}
      <Flex sx={{ height: '0', flex: 1 }}>
        {!issueId && !isHiddenFilters && (
          <FiltersSidePanel>
            <Box sx={{ mb: 2 }} />
            {asset.category === AssetCategory.Code && (
              <PathFilter
                path={currentDebouncedValues.path}
                onValueChanged={onDebouncedValueChange}
                isRefreshing={isRefreshing}
              />
            )}
            <SecretsListSideFilters
              filtersValues={currentDebouncedValues}
              isDataRefreshing={isRefreshing}
              filtersMetadata={filtersMetadata}
              filterActions={filterActions}
            />
          </FiltersSidePanel>
        )}
        <Flex sx={{ width: '100%', height: '100%', flexDirection: 'column' }}>
          {loaded ? (
            <SecretsListView
              onPaginationChange={onPaginationChange}
              currentQueryStringParams={currentQueryStringParams}
              clearQueryStringParam={clearQueryStringParam}
              totalSecrets={pagination.total}
              secrets={secrets}
              isRefreshing={isRefreshing}
              actionHandlers={actionHandlers}
              onSecretSelectionChanged={onSecretSelectionChanged}
              selectedSecrets={selectedSecrets}
              exportActions={exportActions}
              isDownloadingExport={isDownloadingExport}
              integrations={integrations}
              onActionIntegrationSubmit={onActionIntegrationSubmit}
              assignableMembers={assignableMembers}
              isLoadingAssignableMembers={isLoadingAssignableMembers}
              isAssignableMembersLoaded={isAssignableMembersLoaded}
              isNotActiveAsset={asset.isNotActive}
              isDataFiltered={isDataFiltered}
              setQueryStringParam={setQueryStringParam}
              trackSort={trackSort}
              sortOptions={sortOptions}
            />
          ) : (
            <Loading />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SecretsView
