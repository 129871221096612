/** @jsx jsx */
import styled from 'styled-components'
import { Flex, jsx } from 'theme-ui'
import EmbeddedMenu from './embedded-menu'

type Props = {
  links: { [key: string]: Function | string }
  location: any
}

const EmbeddedSidebar = ({ links, location }: Props) => {
  return (
    <StyledSidebar>
      <EmbeddedMenu links={links} location={location} />
    </StyledSidebar>
  )
}
const StyledSidebar = styled(Flex)`
  width: 200px;
  flex-direction: column;
  transition: width 200ms;
  overflow: none;
  height: 100vh;
  position: fixed;
  left: 0;
  background-color: rgb(247, 247, 247);
  z-index: 900;
  background-color: #f7f7f7;
  border-right: 1px solid #e8e9eb;
  transition: width 0.2s linear;
`

export default EmbeddedSidebar
