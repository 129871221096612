import React from 'react'
import { Box } from 'theme-ui'
import {
  PanelForm,
  SwitchFieldSection,
} from '../../../../components/panels/content'

const MembersSeeAllAssets = ({ currentTeam, submit }) => {
  return (
    <PanelForm
      onSubmit={submit}
      initialValues={{
        membersSeeAllAssets: currentTeam.membersSeeAllAssets,
      }}
    >
      <Box>
        <SwitchFieldSection
          name="membersSeeAllAssets"
          label="Allow members to see all assets"
          description="When this setting is on, spectral will ignore user-team mappings and will allow all users to see all the assets associated to this account"
          submit
        />
      </Box>
    </PanelForm>
  )
}

export default MembersSeeAllAssets
