/** @jsx jsx */
import { Box, Flex, Heading, Image, jsx } from 'theme-ui'
import { Button, Tooltip } from 'antd'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { imageMap } from '../../../common/sources/imageMap'
import IntegrationWrapper from '../integration-wrapper'
import { supportWidget } from '../../../common/support-widget'
import { TextStyle } from './share'
import awsDeployStack from '../../../assets/png/cloudformation-launch-stack.png'
import IntegrationStatus from '../integration-status'
import PersonalTokenAlert from '../personal-token-alert'
import EnvVarsTable from './common/envVarsTable'

const envVarsData = [
  {
    key: '1',
    name: 'SPECTRAL_DSN',
    required: true,
    description: 'Your Spectral DSN retrieved from SpectralOps',
  },
  {
    key: '2',
    name: 'JIRA_WEBHOOK_TOKEN',
    required: true,
    description:
      'A token used to identify the sender, should be identical to the webhook token sent in the webhook_token param to the webhook endpoint',
  },
  {
    key: '3',
    name: 'EMAIL',
    required: false,
    description:
      'The email matching the jira api token. If this is not provided, attachments will not be scanned',
  },
  {
    key: '4',
    name: 'SPECTRAL_TAGS',
    required: false,
    description:
      "Tags list to run Spectral with, separated by commas (eg base,iac,audit). Default is 'base'",
  },
  {
    key: '5',
    name: 'REDACTED_MESSAGE',
    required: false,
    description:
      'In case of active remediation - a custom message to replace findings',
  },
  {
    key: '6',
    name: 'REMEDIATION_MODE',
    required: false,
    description:
      'How to handle findings (Valid values: "Not active" / "Redact finding")',
  },
  {
    key: '7',
    name: 'JIRA_API_TOKEN',
    required: false,
    description:
      'A Jira api token to scan attachments as well. If this is not provided, attachments will not be scanned',
  },
  {
    key: '8',
    name: 'JIRA_PROJECTS_BLACKLIST',
    required: false,
    description:
      'A comma delimited list of project keys that you want to exclude from being scanned',
  },
  {
    key: '9',
    name: 'JIRA_PROJECTS_WHITELIST',
    required: false,
    description:
      'A comma delimited list of project keys that you want to scan. No other projects except these will be scanned',
  },
]

const JiraSourceIntegration = ({
  dataSource,
  isConnected,
  statusLoaded,
  shouldShowTeamToken,
  onSwitchTokenShown,
}) => {
  return (
    <IntegrationWrapper
      title={dataSource.title}
      imgSrc={imageMap[dataSource?.tileKey]}
      extraOnRight={
        <IntegrationStatus
          variant="static"
          isConnected={isConnected}
          isLoaded={statusLoaded}
        />
      }
    >
      <Flex
        sx={{
          gap: 3,
          flexDirection: 'column',
          code: {
            backgroundColor: 'black',
            borderRadius: '4px',
            color: 'white',
            py: '2px',
            px: '5px',
          },
        }}
      >
        {shouldShowTeamToken && (
          <Box sx={{ my: 4 }}>
            <PersonalTokenAlert onSwitch={onSwitchTokenShown} />
          </Box>
        )}
        <TextStyle>
          Protect your Jira content. Real-time, blazing fast scanning of Jira
          issues content, including summary, description, comments and even
          attachments!
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Setup
        </Heading>
        <TextStyle>
          Since Spectral never keeps your secrets and content, we provide an AWS
          lambda function that you deploy in your organization&#39;s
          <Tooltip
            title={
              <Box>
                <strong>Required AWS permissions:</strong>
                <br /> lambda:GetAccountSettings
                <br /> cloudformation:DescribeStacks
                <br /> iam:CreateRole
                <br /> iam:DeleteRole
                <br /> apigateway:POST
                <br /> logs:CreateLogGroup
                <br /> iam:PutRolePolicy
              </Box>
            }
            sx={{ alignItems: 'center' }}
            overlayInnerStyle={{ display: 'inline-block' }}
          >
            {' '}
            AWS account.{' '}
            <Box as="span" sx={{ verticalAlign: 'text-top' }}>
              <AiOutlineInfoCircle size={20} />
            </Box>
          </Tooltip>
          <Box>
            In addition, you&#39;ll add a Jira webhook to send relevant events
            to this lambda.
          </Box>
        </TextStyle>
        <Box sx={{ mb: '15px', mt: '15px' }}>
          <Heading sx={{ mb: '25px' }} as="h2" variant="smallheading">
            Integration Environment Variables
          </Heading>
          <EnvVarsTable envVarsData={envVarsData} />
        </Box>
        <Heading as="h2" variant="smallheading">
          Deploy the Lambda
        </Heading>
        Deploy using one of the following methods:
        <ul>
          <li>
            <Heading as="h3" variant="smalltitle">
              Cloud Formation
            </Heading>
            <TextStyle>
              <Box sx={{ mb: 2 }}>
                Go ahead and&nbsp;
                <a
                  href="https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-jira-scanner/cloudformation-template.json&stackName=spectral-jira-scanner"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image sx={{ mr: 2 }} src={awsDeployStack} />
                </a>
              </Box>
            </TextStyle>
          </li>
          <li>
            <Heading as="h3" variant="smalltitle">
              Terraform
            </Heading>
            <TextStyle>
              Deploy AWS resources using our&nbsp;
              <a
                href="https://github.com/SpectralOps/spectral-terraform-lambda-integration"
                rel="noopener noreferrer"
                target="_blank"
              >
                Terraform module
              </a>{' '}
              (set the <code>integration_type</code> param value to{' '}
              <strong>jira</strong>
              ).
            </TextStyle>
          </li>
        </ul>
        After recources has been deployed grab the function gateway api URL,
        we&#39;ll use it next.
        <Heading as="h2" variant="smallheading">
          Add Jira Webhook
        </Heading>
        <TextStyle>
          Go to your Jira instance and add a new webhook in System Settings -
          {'>'} Webhooks (
          <code>
            https://YOUR_ORG_NAME.atlassian.net/plugins/servlet/webhooks
          </code>
          ) to send events to the function.
        </TextStyle>
        <TextStyle>
          Mark the following events for the webhook to send: (1) issue-{'>'}
          create+update (2) comment-{'>'}create+update (3) attachment-{'> '}
          create
        </TextStyle>
        <TextStyle>
          The webhook url should be the lambda url you grabbed from AWS. Make
          sure to copy the full url and a query string param for the webhook
          secret you entered when installing the lambda, like so:{' '}
          <code>
            https://random1.execute-api.us-east-1.amazonaws.com/prod/api/jira_event?webhook_token=[YOUR
            WEBHOOK SECRET]
          </code>
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          That&#39;s it{' '}
          <span role="img" aria-label="strong">
            💪
          </span>
        </Heading>
        <TextStyle>
          Your Jira content should now be monitored by Spectral.
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Give it a spin
        </Heading>
        <TextStyle>
          To verify everything works you can open a Jira issue with a (fake)
          secret (like <code>AKIA4HK52OLF2AAN9KWV</code>) and watch the status
          change in the next section.
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Integration Status
        </Heading>
        <IntegrationStatus isConnected={isConnected} isLoaded={statusLoaded} />
      </Flex>
      <Flex sx={{ mt: '40px', justifyContent: 'center' }}>
        <Button size="large" onClick={() => supportWidget.open()}>
          Need help? Talk to an expert
        </Button>
      </Flex>
    </IntegrationWrapper>
  )
}

export default JiraSourceIntegration
