import React from 'react'
import { useSelector } from 'react-redux'
import { RoleType } from '@spectral/types'
import { select } from '../../../../redux/store'
import {
  ContentPanel,
  ContentPanelHeader,
} from '../../../../components/panels/content'
import Restricted from '../../../role-based-render/restricted'
import TeamKeyConnected from '../team-key/team-key-connected'
import TeamNameConnected from '../team-name/team-name-connected'
import MembersSeeAllAssetsConnected from '../members-see-all-assets/membsers-see-all-assets-connected'
import EmbeddedHidden from '../../../embeded-hidden/embeded-hidden'

const TeamSettingsConnected = () => {
  const currentTeam = useSelector(select.Teams.current)
  return (
    <ContentPanel>
      <ContentPanelHeader>
        Team Settings -&nbsp;<strong>{currentTeam?.name}</strong>
      </ContentPanelHeader>

      <Restricted roles={[RoleType.Owner]}>
        <EmbeddedHidden>
          <TeamNameConnected />
        </EmbeddedHidden>
        <MembersSeeAllAssetsConnected />
      </Restricted>
      <Restricted roles={[RoleType.Admin, RoleType.Owner]}>
        <TeamKeyConnected />
      </Restricted>
    </ContentPanel>
  )
}

export default TeamSettingsConnected
