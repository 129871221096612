import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'theme-ui'
import { MDXProvider } from '@mdx-js/react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { BrowserView, MobileView } from 'react-device-detect'
import { store } from './redux/store'
import { boot } from './boot'
import SiteRouter from './site-router'
import { stylebook } from './common/theme'
import { components } from './mdx'
import { loadScripts } from './script-append'
import NotSupportedMobile from './not-supported-mobile'
import { authPersistence, teamPersistence } from './redux/persistence'
import { features } from './common/features'
import { isEmbedded } from './common/utils'

const CLOUDGUARD_REDIRECT_URL =
  'https://portal.checkpoint.com/dashboard/cloudguard#'

const Site = () => {
  if (features.forceLoadingInIframe && !isEmbedded()) {
    window.location.replace(CLOUDGUARD_REDIRECT_URL)
    return null
  }
  return (
    <Provider store={store}>
      <ThemeProvider theme={stylebook as any}>
        <MDXProvider components={components}>
          <BrowserView style={{ height: '100%' }}>
            <SiteRouter />
          </BrowserView>
          <MobileView>
            <NotSupportedMobile />
          </MobileView>
        </MDXProvider>
      </ThemeProvider>
    </Provider>
  )
}

const SENTRY_BREADCRUMB_DISCARD_URLS_REGEX = /nr-data\.net|mixpanel|hubapi/g
Sentry.init({
  environment: process.env.SENTRY_ENV,
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  normalizeDepth: 5,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  beforeBreadcrumb(breadcrumb) {
    return breadcrumb.category === 'xhr' &&
      breadcrumb.data.url.match(SENTRY_BREADCRUMB_DISCARD_URLS_REGEX)
      ? null
      : breadcrumb
  },
})

Sentry.configureScope((scope) => {
  scope.clear()
  scope.setTag(
    'teamPid',
    teamPersistence.get() ? teamPersistence.get().pid : null
  )
  scope.setUser({
    pid: authPersistence.get() ? authPersistence.get()?.pid : null,
  })
})

boot().then(() => {
  console.log('boot OK')
  ReactDOM.render(<Site />, document.getElementById('root'), loadScripts)
})

export default Site
