import { ModelConfig } from '@rematch/core'
import { RootState } from '../../store'

type GlobalPreferences = {
  isFilterSideMenuExpanded: boolean
}

export const globalPreferences: ModelConfig<GlobalPreferences> = {
  state: {
    isFilterSideMenuExpanded: true,
  },
  reducers: {
    toggleIsFilterSideMenuExpanded(state: GlobalPreferences) {
      return { ...state, isFilterSideMenuExpanded: !state.isFilterSideMenuExpanded }
    },
  },
  selectors: (_slice, createSelector, _hasProps) => ({
    globalPreferences() {
      return createSelector(
        (rootState) => (rootState as RootState).GlobalPreferences,
        (globalPrefs) => globalPrefs
      )
    },
  }),
}
