/** @jsx jsx */
import { jsx, Heading } from 'theme-ui'
import Highlight, { defaultProps } from 'prism-react-renderer'

const HighlightedCode = ({ children, className }) => {
  const language = className.replace(/language-/, '')
  return (
    <Highlight {...defaultProps} code={children} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={{ ...style, padding: '20px' }}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}
export const components = {
  inlineCode: ({ children }) => (
    <code
      sx={{
        background: '#f4f4f4',
        border: '1px solid #e0e0e0',
        padding: '2px 4px',
        borderRadius: '4px',
      }}
    >
      {children}
    </code>
  ),
  code: HighlightedCode,
  h3: ({ children }) => (
    <Heading as="h3" variant="smalltitle" my={3}>
      {children}
    </Heading>
  ),
  h1: ({ children }) => (
    <Heading as="h1" variant="smallheading" my={3}>
      {children}
    </Heading>
  ),
  a: ({ children, href }) => {
    return (
      <a href={href} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    )
  }
}
