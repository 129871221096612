import { Box, Text } from 'theme-ui'
import React from 'react'
import * as Yup from 'yup'
import {
  InputPasswordFieldSection,
  PanelForm,
  SubmitSection,
} from '../../../../components/panels/content'
import mondayLogo from '../../../../assets/svg/monday.svg'
import { IntegrationSection } from '../../../../components/integrations/integration-sections'

export const MondayIntegration = ({
  enabled,
  setMondayEnableDisable,
  setMondaySettings,
  domain,
  token,
  MondaySettingsError,
}) => {
  const title = 'Monday Global Integration'
  const headerTitle = domain ? `${title} - ${domain}` : title
  return (
    <IntegrationSection
      enabled={enabled}
      onEnableToggle={setMondayEnableDisable}
      integrationName="Monday"
      headerLogo={mondayLogo}
      headerTitle={headerTitle}
      sectionTitle=""
      sectionDescription="Open Monday items directly from Spectral"
      integrationError={MondaySettingsError}
      sectionRenderedContent={() => (
        <Box sx={{ mt: '20px' }}>
          <Text sx={{ fontWeight: 600 }}>Integrate Monday with Spectral</Text>
          <Text>
            1. Choose a Monday user to control the Spectral access to Monday
          </Text>
          <Text>
            Recommended - create a designated user for the Spectral integration
          </Text>
          <Text>
            2. Create API Token for the Monday user{' '}
            <a
              href="https://api.developer.monday.com/docs/authentication#accessing-api-tokens"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
          </Text>
          <Text>3. Fill in the following details:</Text>
        </Box>
      )}
    >
      <PanelForm
        onSubmit={setMondaySettings}
        initialValues={{
          token,
        }}
        validationSchema={Yup.object({
          token: Yup.string().required('Api token required'),
        })}
      >
        <InputPasswordFieldSection
          name="token"
          label="Monday API token"
          description="Paste your Monday API Token here"
          placeholder="Monday private API Token"
          required
          merge
        />
        <SubmitSection text="Update" />
      </PanelForm>
    </IntegrationSection>
  )
}
