import { IssueStatus, Severity } from '@spectral/types'
import theme from '../../common/theme'

const nivoTheme = {
  axis: {
    ticks: {
      line: {
        strokeWidth: 0,
      },
      text: {
        fontSize: 11,
      },
    },
    legend: {
      text: {
        fontSize: 14,
      },
    },
  },
  legends: {
    text: {
      fontSize: 13,
    },
  },
  tooltip: {
    container: {
      fontSize: 13,
    },
  },
  labels: {
    text: {
      fontSize: 14,
    },
  },
}

const colors = {
  assetResultsOverTime: {
    issuesFoundColor: theme.stylebook.colors.severities.highHEX,
    noIssuesFoundColor: theme.stylebook.colors.severities.noneHEX,
    didntRunColor: theme.stylebook.colors.severities.oldHEX,
    labelTextColor: 'white',
  },
  issuesOverTime: [
    '#670f8f',
    '#c9183b',
    '#ee362f',
    '#fb684b',
    '#fda05e',
    '#f8c84f',
  ],
  severityPie: {
    [Severity.Critical]: theme.stylebook.colors.severities.criticalHEX,
    [Severity.High]: theme.stylebook.colors.severities.highHEX,
    [Severity.Medium]: theme.stylebook.colors.severities.mediumHEX,
    [Severity.Low]: theme.stylebook.colors.severities.lowHEX,
    [Severity.Informational]:
      theme.stylebook.colors.severities.informationalHEX,
    none: theme.stylebook.colors.severities.noneHEX,
  },
  statusPie: {
    [IssueStatus.Active]: theme.stylebook.colors.severities.mediumHEX,
    [IssueStatus.Ignored]: theme.stylebook.colors.primaryScale[700],
  },
  sourcesPie: [
    '#d95f00',
    '#1c9f76',
    '#756fb2',
    '#e7298a',
    '#66a51f',
    '#e6aa02',
    '#a7771c',
    '#666666',
  ],
  arcLabelsTextColor: 'white',
  default: theme.stylebook.colors.primary,
}

export { nivoTheme, colors }
