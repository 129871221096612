import * as Sentry from '@sentry/react'
import L from 'lodash'
// action name + list of keys to hide from Sentry
const secretActions = {
  'Teams/setCurrentTeam': ['key', 'members'],
  'Auth/setUser': ['token'],
  'Auth/me': ['token'],
  'Assets/set': ['payload'],
}

const secretStateBlocks = [
  'Auth.user.token',
  'Auth.user.username',
  'Auth.user.firstName',
  'Auth.user.avatarUrl',
  'Auth.user.lastName',
  'Teams.current.data.key',
  'Teams.settings.data',
  'Assets.assets.data',
]

const clearActionSensitiveData = (action) => {
  const actionName = action.type
  const sensitiveFields = secretActions[actionName]
  if (sensitiveFields) {
    const newPayload = Array.isArray(action.payload)
      ? '[Filtered By Spectral]'
      : L.reduce(
          action.payload,
          (res, value, key) => {
            if (sensitiveFields.includes(key)) {
              res[key] = '[Filtered By Spectral]'
            } else {
              res[key] = value
            }
            return res
          },
          {}
        )
    return { ...action, payload: newPayload }
  }
  return action
}

const clearStateSensitiveData = (state) => {
  const transformedState = L.cloneDeep(state)
  // eslint-disable-next-line no-restricted-syntax
  for (const sensitive of secretStateBlocks) {
    L.set(transformedState, sensitive, '[Filtered By Spectral]')
  }
  return transformedState
}

export const SentryEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    return clearActionSensitiveData(action)
  },
  stateTransformer: (state) => {
    return clearStateSensitiveData(state)
  },
})
