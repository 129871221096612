import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PlanExpirationAlert from '../../blocks/plan-expired-alert'
import { licenseAlertClosed } from '../../redux/persistence'
import { select } from '../../redux/store'
import { paths } from '../../routes'

export default () => {
  const location = useLocation()
  const { isFreeTrial, daysUntilTrialExpire } = useSelector(
    select.Plan.freeTrialAlertMessage
  )
  const [isLicenseAlertShown, setIsLicenseAlertShown] = useState(true)

  useEffect(() => {
    setIsLicenseAlertShown(!licenseAlertClosed.get())
  }, [setIsLicenseAlertShown])

  const handleCloseAlert = () => {
    licenseAlertClosed.persist(true)
  }

  const isUpgradeButtonShown = paths.closed.settingsPlans !== location.pathname

  if (isFreeTrial && isLicenseAlertShown) {
    return (
      <PlanExpirationAlert
        daysUntilTrialExpire={daysUntilTrialExpire}
        handleCloseAlert={handleCloseAlert}
        isUpgradeButtonShown={isUpgradeButtonShown}
      />
    )
  }
  return null
}
