import React, { useEffect } from 'react'
import { RoutesBuilder } from '@spectral/types'
import { notification } from 'antd'
import MondayCreateItemModalView from './view'
import { Loading } from '../../../components/loading'

const toMondayItemFields = ({ detectorName, pid, asset: { id, type } }) => {
  let message = `Spectral has detected a secret`
  if (detectorName) message = message.concat(` - ${detectorName}`)
  const postfix = `<a href=${RoutesBuilder({
    baseUrl: window.location.origin,
  }).issueByIssueId({
    pid,
    assetId: id,
  })} target='_blank'>See issue</a>`

  return {
    description: message,
    descriptionPostfix: postfix,
  }
}

const MondayCreateItemModal = ({
  issue,
  board,
  onSubmit,
  isItemCreationInProgress,
  isBoardFetchingInProgress,
  createItemError,
}) => {
  useEffect(() => {
    if (createItemError) {
      notification.error({
        message: 'Sorry!',
        description: `Something went wrong while creating the Monday item, our engineers are working on fixing the issue.`,
      })
    }
  }, [createItemError])

  const mondayItemFields = issue ? toMondayItemFields(issue) : null

  const preFinishEditValues = (values, descriptionPostfix) => {
    const description = `<p>${values.description}</p>\n\n<p>${descriptionPostfix}</p>`
    return {
      ...values,
      description,
    }
  }

  const preFinish = async (values, descriptionPostFix) => {
    const newValues = preFinishEditValues(values, descriptionPostFix)
    return onSubmit(newValues)
  }
  return isBoardFetchingInProgress ? (
    <Loading />
  ) : (
    <MondayCreateItemModalView
      issue={issue}
      description={mondayItemFields?.description}
      descriptionPostfix={mondayItemFields?.descriptionPostfix}
      onFinish={preFinish}
      isItemCreationInProgress={isItemCreationInProgress}
      board={board}
    />
  )
}

export default MondayCreateItemModal
