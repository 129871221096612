/** @jsx jsx */
import { jsx } from 'theme-ui'

import { Button } from 'antd'
import { FeatureHeading } from '../../components/headings/feature'
import { ReactComponent as MitigateSVG } from '../../assets/svg/mitigate.svg'
import { ReactComponent as AuditSVG } from '../../assets/svg/audit.svg'
import { ReactComponent as QuickSVG } from '../../assets/svg/quick.svg'

export const AuditLink = () => (
  <Button type="link">
    Or use&nbsp; <span style={{ textDecoration: 'underline' }}>the CLI</span>
  </Button>
)

export const Mitigate = () => (
  <FeatureHeading
    title="Protect your CI/CD pipelines"
    subtitle="Monitor private & public repositories in your your CI"
    image={
      <MitigateSVG sx={{ ml: '7px', mr: '6px', mt: '-12px', width: '60px' }} />
    }
    tags={['private', 'public']}
  />
)

export const NoPublicScanAudit = () => (
  <FeatureHeading
    title="Audit"
    subtitle="Get a full view of your organization"
    image={<AuditSVG sx={{ mt: '3px' }} />}
    tags={['private', 'public']}
  />
)

export const NoPublicScanMitigate = () => (
  <FeatureHeading
    title="Scan & Protect"
    subtitle="Scan and protect in your CI/CD Pipeline, CLI and more"
    image={<MitigateSVG sx={{ ml: '7px', mr: '6px', mt: '-12px' }} />}
    tags={['private', 'public']}
  />
)

export const Quick = () => (
  <FeatureHeading
    title="Quick Scan"
    subtitle="Find secrets and misconfigurations in a public repo"
    image={<QuickSVG sx={{ mt: '-13px', width: '57px', height: '86px' }} />}
    tags={['public']}
  />
)
