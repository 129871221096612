import React from 'react'
import { WarningFilled } from '@ant-design/icons'
import { format, formatDistanceToNow } from 'date-fns'
import { Box } from 'theme-ui'
import { Tooltip } from 'antd'
import styled from 'styled-components'
import { dateFormat } from '@spectral/types'
import theme from '../../../common/theme'

const AssetNotActiveIcon = styled(WarningFilled)`
  color: ${theme.stylebook.colors.red};
  margin-right: ${(props) => props.mr || '3px'};
  margin-top: ${(props) => props.mt || '0px'};
  margin-left: ${(props) => props.ml || '0px'}
  font-size: ${(props) => props.size || '15px'};
`

const AssetScanDate = ({ isNotActive, lastScanDate }) => {
  return (
    <Box
      sx={{
        mr: 2,
        color: isNotActive
          ? theme.stylebook.colors.red
          : theme.stylebook.text.default.color,
      }}
    >
      <Tooltip
        title={
          <Box>
            <Box>
              {isNotActive ? 'This asset has not been scanned recently.' : ''}
            </Box>
            <Box>Last scan date:</Box>
            <Box>{format(new Date(lastScanDate), dateFormat)}</Box>
          </Box>
        }
      >
        {isNotActive && <AssetNotActiveIcon />}
        Scanned {formatDistanceToNow(new Date(lastScanDate))} ago
      </Tooltip>
    </Box>
  )
}

export default AssetScanDate
