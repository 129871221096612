import React from 'react'
import styled from 'styled-components'
import { Alert } from 'antd'
import { Box, Flex } from 'theme-ui'
import AdditionalActionButton from '../../filters/additional-action-button'

const SingleIssueAlert = ({ onReset, alertText, alertType, resetText }) => {
  return (
    <SingleSecretAlert
      type={alertType}
      message={alertText}
      showIcon
      action={
        <Flex sx={{ paddingBottom: '5px' }}>
          <Box sx={{ mt: '4px' }}>
            <AdditionalActionButton
              text={resetText}
              onClick={onReset}
              style={{ fontSize: '16px' }}
            />
          </Box>
        </Flex>
      }
    />
  )
}

const SingleSecretAlert = styled(Alert)`
  margin-bottom: 10px;
  padding-left: 16px;
  .ant-alert-content {
    min-width: 0;
    flex: none;
  }
  .ant-alert-message {
    padding-left: 5px;
  }
`

export default SingleIssueAlert
