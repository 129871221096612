import { MutableRefObject, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

const useReCaptcha = ({
  onSubmit,
}): [MutableRefObject<ReCAPTCHA>, (args) => void] => {
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const resetPostSubmit = (args) => {
    onSubmit(args)
    recaptchaRef.current?.reset()
  }

  return [recaptchaRef, resetPostSubmit]
}

export default useReCaptcha
