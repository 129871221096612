import { capitalize, castArray, isEmpty } from 'lodash'

type FilterData = {
  id: string
  title?: string
  order?: number
  total: number
}

type FilterDataView = {
  key: string
  title: string
  total: number
}

const keyToTitleFilters = {
  assignees: 'Assignees',
  detectorId: 'Detector',
  severity: 'Severity',
  content: 'Content',
  status: 'Status',
  tag: 'Compliance framework',
  resources: 'Resources',
  packagesNames: 'Package name',
  OpenSourceIssueTypes: 'Issue type',
  path: 'Path',
}

export const removeEmptyFilters = (currentValues) =>
  Object.entries(currentValues).reduce((acc, [key, filterValues]) => {
    if (!isEmpty(filterValues)) {
      acc[key] = filterValues
    }
    return acc
  }, {})

export const transformFiltersToDisplay = (
  currentDebouncedValues,
  filtersData?
) =>
  Object.entries(currentDebouncedValues).map(([filterKey, values]) => {
    const filterName = keyToTitleFilters[filterKey] || filterKey
    const filteredValues = castArray(values).map((filterValue: string) => {
      if (filterName === keyToTitleFilters.tag) {
        return {
          filterDisplayValue: filtersData.tags.find(
            (filterData) => filterData.id === filterValue
          ).title,
          filterValue,
        }
      }
      return { filterDisplayValue: filterValue, filterValue }
    })
    return {
      filterKey,
      filterName,
      filtered: filteredValues,
    }
  })

export const transformAssigneeFiltersToDisplay = (
  currentAssignableValue,
  assignableMembers
) =>
  Object.entries(currentAssignableValue).map(([filterKey, values]) => {
    return {
      filterKey,
      filterName: keyToTitleFilters[filterKey],
      filtered: castArray(values).map((filterValue) => {
        if (filterValue === 'unassigned') {
          return { filterDisplayValue: 'Unassigned', filterValue }
        }
        const member = assignableMembers?.find((assignee) => {
          return filterValue === assignee.username
        })
        return {
          filterDisplayValue: member
            ? `${member.firstName} ${member.lastName}`
            : filterValue,
          filterValue,
        }
      }),
    }
  })

export const toFilterView = ({
  filterData,
  shouldCapitalize,
}: {
  filterData: Array<FilterData>
  shouldCapitalize: boolean
}): Array<FilterDataView> => {
  return filterData.map((item) => {
    const dataValue = item.title || item.id
    return {
      key: item.id || item.title,
      title: `${shouldCapitalize ? capitalize(dataValue) : dataValue}`,
      total: item.total,
    }
  })
}
