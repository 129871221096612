export interface OrgTeam {
  orgTeamId: string
  name: string
  integrations: {
    slack?: {
      webhookUrls: Array<string>
    }
    jira?: {
      projectId: string
      defaultIssueType?: string
    }
    pd?: {
      routingServiceKey: string
    }
    monday?: {
      workspaceId
    }
  }
}

export const defaultOrgTeam = {
  orgTeamId: null,
  name: null,
  integrations: {
    slack: {
      webhookUrls: null,
    },
    msTeams: {
      webhookUrls: null,
    },
    jira: {
      projectId: null,
    },
    pd: {
      routingServiceKey: null,
    },
    monday: {
      workspaceId: null,
    },
  },
}
