import debounce from 'lodash/debounce'
import { useCallback, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { keys, omit, omitBy, pick, pickBy } from 'lodash'

const useDebouncedFieldFilter = (
  initialValues,
  setFilter,
  clearFilter,
  trackFilter,
  currentQueryStringParams
) => {
  const [debouncedFieldValue, setDebouncedFieldValue] = useState(initialValues)

  const setDebouncedFieldFilter = (currentDebouncedFields, newFields) => {
    const filterToClear = pickBy(newFields, isEmpty)
    const filterToSet = omitBy(newFields, isEmpty)
    if (!isEmpty(filterToSet)) {
      setFilter({ ...currentDebouncedFields, ...filterToSet })
    }
    if (!isEmpty(filterToClear)) {
      clearFilter(keys(filterToClear))
    }
    trackFilter(keys(newFields))
  }

  const debounceNameFilterChange = useCallback(
    debounce(setDebouncedFieldFilter, 1200),
    [JSON.stringify(currentQueryStringParams)]
  )

  const onDebouncedFieldValueChanged = useCallback(
    (fields) => {
      const debouncedFields = pick(fields, keys(initialValues))
      const nonDebouncedField = omit(fields, keys(initialValues))
      if (!isEmpty(nonDebouncedField)) {
        setFilter({ ...debouncedFieldValue, ...nonDebouncedField })
      }
      if (!isEmpty(debouncedFields)) {
        setDebouncedFieldValue({ ...debouncedFieldValue, ...debouncedFields })
        debounceNameFilterChange(debouncedFieldValue, debouncedFields)
      }
    },
    [debounceNameFilterChange, debouncedFieldValue, initialValues, setFilter]
  )

  return [onDebouncedFieldValueChanged, debouncedFieldValue]
}

export default useDebouncedFieldFilter
