/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui'
import { Input, Form, Button } from 'antd'
import PagerDutyIntegrationInstructions from '../../integrations/pager-duty/instructions'
import { pagerDutyRoutingServiceKeyRegex } from '../../integrations/pager-duty/const'
import testPagerDutyConnection from '../../integrations/pager-duty/test-connection'

export const OrgTeamPagerDutyIntegration = ({
  form,
  testRoutingServiceToken,
  isTestInProgress,
}) => {
  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Box sx={{ mb: '20px' }}>
        <PagerDutyIntegrationInstructions />
      </Box>
      <Form.Item
        name="pagerDutyRoutingServiceKey"
        fieldKey="pagerDutyRoutingServiceKey"
        label="PagerDuty routing service key"
        required={false}
        rules={[
          {
            pattern: pagerDutyRoutingServiceKeyRegex,
            message: 'Must be a valid routing service key (32 chars, 0-9, a-f)',
          },
        ]}
      >
        <Input.Password placeholder="Please enter PagerDuty routing service key" />
      </Form.Item>
      <Button
        loading={isTestInProgress}
        onClick={async () => {
          const key = form.getFieldValue('pagerDutyRoutingServiceKey')
          const testDisabled = key
            ? !key.match(pagerDutyRoutingServiceKeyRegex)
            : true
          if (testDisabled) return
          await testPagerDutyConnection(testRoutingServiceToken, key)
        }}
      >
        Test
      </Button>
    </Flex>
  )
}
