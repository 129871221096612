import React from 'react'
import { Alert } from 'antd'
import {
  ContentPanel,
  ContentPanelHeader,
} from '../../../../components/panels/content'
import MembersTable from './members-table'
import AddMember from './add-member'
import EmbeddedHidden from '../../../../containers/embeded-hidden/embeded-hidden'

const MembersManagement = ({
  orgTeams,
  users,
  isLoadingUsers,
  removeMember = null,
  changeUserOrgTeams = null,
  changeMemberRole = null,
  sendInvite = null,
  readOnly = false,
  inviteError = '',
  warningsMessage = null,
  isLimitedToAddContributors = false,
  invites = [],
  isLoadingInvites = false,
  removeInvite = null,
  isSSOConfigured,
  page,
  onPageChanged,
  emailFilter,
  onFilterChanged,
  totalUsers,
  pageSize,
}) => {
  return (
    <ContentPanel>
      <ContentPanelHeader>Team members</ContentPanelHeader>
      {warningsMessage && (
        <Alert message={warningsMessage} type="warning" showIcon />
      )}
      <EmbeddedHidden>
        {!isSSOConfigured && (
          <AddMember
            sendInvite={sendInvite}
            readOnly={readOnly || isLimitedToAddContributors}
            inviteError={inviteError}
          />
        )}
      </EmbeddedHidden>
      <MembersTable
        users={users}
        isLoadingUsers={isLoadingUsers}
        removeMember={removeMember}
        changeUserOrgTeams={changeUserOrgTeams}
        changeMemberRole={changeMemberRole}
        readOnly={readOnly}
        orgTeams={orgTeams}
        invites={invites}
        removeInvite={removeInvite}
        isLoadingInvites={isLoadingInvites}
        isSSOConfigured={isSSOConfigured}
        page={page}
        onPageChanged={onPageChanged}
        emailFilter={emailFilter}
        onFilterChanged={onFilterChanged}
        totalUsers={totalUsers}
        pageSize={pageSize}
      />
    </ContentPanel>
  )
}
export default MembersManagement
