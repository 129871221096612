import L from 'lodash'
import { RoleType } from '@spectral/types'
import type { FetchStatus } from './store'

export const empty = <T>(data: T): { fetchStatus: FetchStatus; data: T } => ({
  fetchStatus: 'none',
  data,
})
export const loaded = <T>(data: T): { fetchStatus: FetchStatus; data: T } => ({
  fetchStatus: 'loaded',
  data,
})

const teamMemberToAssignableMember = (member, assignee = undefined) => {
  return member
    ? {
        avatarUrl: member.avatarUrl,
        name: `${member.firstName} ${member.lastName}`,
        username: member.username,
      }
    : {
        avatarUrl: undefined,
        name: assignee,
        username: assignee,
      }
}

const assignsDataForIssue = (issue, assetAssignableMembersDic, members) => {
  const assignableMembers =
    assetAssignableMembersDic[issue.asset.id]?.assignableMembers || members
  return {
    assignableMembers: assignableMembers.map(teamMemberToAssignableMember),
    assignedMember: issue.assignee
      ? teamMemberToAssignableMember(
          L(members)
            .filter((member) => member.username === issue.assignee)
            .first(),
          issue.assignee
        )
      : undefined,
  }
}

export const getAssignableMembersForTeam = (teamName, members) => {
  const assignableMembers = members.filter((member) => {
    return (
      ([RoleType.Admin, RoleType.Owner].includes(member.role) &&
        L.isEmpty(member.orgTeams)) ||
      member.orgTeams?.includes(teamName)
    )
  })
  // at least the owner of the organization should be part of the orgTeam
  // if not, than the asset is not mapped to any team, so all organization members will be returned
  return assignableMembers.length > 0 ? assignableMembers : members
}

const calculateAssignableMembersForAssets = (assetTeamMapping, members) => {
  const assetAssignableMembersDic = {}
  assetTeamMapping.forEach((mapping) => {
    assetAssignableMembersDic[mapping.assetId] = {
      assignableMembers: mapping.orgTeamKey
        ? getAssignableMembersForTeam(mapping.orgTeamKey, members)
        : members,
    }
  })
  return assetAssignableMembersDic
}

export const enrichIssuesWithAssignData = (issues, members, assetMapping) => {
  if (
    !assetMapping ||
    assetMapping.length === 0 ||
    !members ||
    members.length === 0
  )
    return issues
  // calculate the mapping between asset and the assignable members
  const assetAssignableMembersDic = calculateAssignableMembersForAssets(
    assetMapping,
    members
  )

  return issues.map((issue) => {
    return {
      ...issue,
      ...assignsDataForIssue(issue, assetAssignableMembersDic, members),
    }
  })
}

export const mergeIssuesLists = (listA, listB) => {
  const newIssuesDict = L.groupBy(listA, 'pid')
  const existingIssues = L.groupBy(listB, 'pid')
  const merged = { ...existingIssues, ...newIssuesDict }
  return L.flatten(Object.values(merged))
}
