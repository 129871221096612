import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'theme-ui'
import qs from 'query-string'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import { Dispatch, RootState, sdkClient } from '../../redux/store'
import View from './view'
import { paths } from '../../routes'
import { Loading } from '../../components/loading'

const recaptchaEnabled = !!process.env.REACT_APP_RECAPTCHA_ENABLED
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

const SignInConnected = ({
  login,
  isLoggedin,
  ssoLoginTeamPid,
  loginError,
  ssoLoginError,
  recoverFinishMessage,
  emailVerificationError,
  resendEmailVerification,
  fetchSsoLoginTeamPid,
  isVerificationSent,
  isGenericError,
}) => {
  const { search } = useLocation()
  const { teamPid } = useParams()
  const [ssoTeamName, setTeamName] = useState(null)
  const [teamLoading, setTeamLoading] = useState(false)
  const isSSOLogin = teamPid
  const team =
    teamPid && ssoTeamName ? { name: ssoTeamName, pid: teamPid } : null

  const [userEmail, setUserEmail] = useState(null)
  const handleResendEmailVerification = useCallback(async () => {
    await resendEmailVerification({ email: userEmail })
  }, [userEmail, resendEmailVerification])

  const handleFetchSsoLoginTeamPid = useCallback(
    ({ email, recaptcha }) => {
      fetchSsoLoginTeamPid({ email, recaptcha })
    },
    [fetchSsoLoginTeamPid]
  )

  useEffect(() => {
    if (ssoLoginTeamPid) {
      window.location.href = `/api/sso/${ssoLoginTeamPid}/login`
    }
  }, [ssoLoginTeamPid])

  useEffect(() => {
    async function loadSSOTeam() {
      if (teamPid) {
        setTeamLoading(true)
        // @ts-ignore eslint-disable-next-line no-shadow
        const { teamName } = await sdkClient
          .auth()
          .ssoTeam({ params: { teamPid } })
        setTeamName(teamName)
        setTeamLoading(false)
      }
    }
    loadSSOTeam()
  }, [teamPid])
  const { returnTo, loginAllowedProvider } = qs.parse(search)
  return isLoggedin ? (
    <Redirect to={returnTo || paths.closed.hive} />
  ) : (
    <Flex sx={{ pt: '60px', justifyContent: 'center', alignItems: 'center' }}>
      {!isSSOLogin && (
        <View
          onSubmit={(data) => {
            setUserEmail(data.email)
            login(data)
          }}
          loginAllowedProvider={loginAllowedProvider}
          error={loginError || emailVerificationError}
          ssoLoginError={ssoLoginError}
          links={paths.open}
          recoverFinishMessage={recoverFinishMessage}
          resendEmailVerification={handleResendEmailVerification}
          fetchSsoLoginTeamPid={handleFetchSsoLoginTeamPid}
          isVerificationSent={isVerificationSent}
          isGenericError={isGenericError}
          recaptcha={{
            enabled: recaptchaEnabled,
            siteKey: recaptchaSiteKey,
          }}
        />
      )}
      {isSSOLogin && (
        <Box>
          {teamLoading && <Loading />}
          {!teamLoading && (
            <View
              onSubmit={login}
              loginAllowedProvider={loginAllowedProvider}
              error={loginError}
              links={paths.open}
              activeTabs={{ sso: true, login: false }}
              team={team}
              resendEmailVerification={handleResendEmailVerification}
              isVerificationSent={isVerificationSent}
              isGenericError={isGenericError}
              recaptcha={{
                enabled: false,
              }}
              returnTo={returnTo}
            />
          )}
        </Box>
      )}
    </Flex>
  )
}

const mapDispatch = (dispatch: Dispatch) => ({
  login: ({ email, password, recaptcha }) =>
    dispatch.Auth.login({ username: email, password, recaptcha }),
  resendEmailVerification: async ({ email }) =>
    dispatch.EmailVerification.resendEmailVerification({ email }),
  fetchSsoLoginTeamPid: ({ email, recaptcha }) =>
    dispatch.Auth.fetchTeamPidForSsoLogin({ email, recaptcha }),
})

const mapState = (state: RootState) => ({
  isLoggedin: state.Auth.user,
  loginError: state.error.effects.Auth.login,
  ssoLoginError: state.error.effects.Auth.fetchTeamPidForSsoLogin,
  recoverFinishMessage: state.RecoverFinish.message,
  emailVerificationError: state.EmailVerification.verificationError,
  isVerificationSent: state.EmailVerification.isVerificationSent,
  isGenericError: state.Auth.isGenericError,
  ssoLoginTeamPid: state.Auth.teamPid,
})

export default connect(mapState, mapDispatch)(SignInConnected)
