import { ReportIssue } from '.'

export enum AssetCategory {
  Code = 'code',
  Host = 'host',
  Productivity = 'productivity',
}

export enum GitSourceCloud {
  GithubCom = 'github.com',
  GitlabCom = 'gitlab.com',
  BitbucketOrg = 'bitbucket.org',
  AzureVisualStudioCom = 'visualstudio.com',
  AzureDevCom = 'dev.azure.com',
  AwsCodeCommit = 'git-codecommit.amazonaws.com',
  GoogleCom = 'source.developers.google.com',
}

export enum AutomationSource {
  TerraformCloud = 'Terraform Cloud',
  TerraformEnterprise = 'Terraform Enterprise',
}

export enum RepositoryVisibility {
  Public = 'public',
  Private = 'private',
}

export enum AssetStats {
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Informational = 'informational',
  Ignore = 'ignore',
  Secrets = 'secrets',
  SecretsBreakdown = 'secrets-breakdown',
  Iac = 'iac',
  IacBreakdown = 'iac-breakdown',
  Discover = 'discover',
  DiscoverBreakdown = 'discover-breakdown',
  OpenSource = 'open-source',
  OpenSourceBreakdown = 'open-source-breakdown',
}

export enum AssetType {
  Git = 'git',
  Jira = 'jira',
  Confluence = 'confluence',
  Slack = 'slack',
  S3 = 's3',
  Host = 'host',
  Automation = 'automation',
}

export enum AssetDisplayStatus {
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Informational = 'informational',
  NoIssues = 'no_issues',
  NotActive = 'not_active',
}

export enum ReviewIssuesSourceType {
  Agent = 'agent',
}

export const kindToCategory = (assetKind) => {
  switch (assetKind) {
    case AssetType.Git:
    case AssetType.Automation:
      return AssetCategory.Code
    case AssetType.S3:
    case AssetType.Host:
      return AssetCategory.Host
    case AssetType.Jira:
    case AssetType.Slack:
    case AssetType.Confluence:
      return AssetCategory.Productivity
    default:
      return assetKind
  }
}

export const categoryToKinds = (assetCategory) => {
  switch (assetCategory) {
    case AssetCategory.Code:
      return [AssetType.Git]
    case AssetCategory.Host:
      return [AssetType.S3, AssetType.Host]
    case AssetCategory.Productivity:
      return [AssetType.Jira, AssetType.Slack, AssetType.Confluence]
    default:
      return assetCategory
  }
}

export interface AssetView {
  displayName: string
  uri: string
  orgTeam: string
  orgTeamId: string
  id: string
  kind: AssetType
  category: AssetCategory
  displayStatus: AssetDisplayStatus
  isNotActive: boolean
  isPublic: boolean
  source: string
  lastScanDate: Date
  stats: {
    error: number
    warning: number
    info: number
    ignore: number
    secrets: number
    iac: number
    discover: number
  }
  uniqueIssues?: Array<ReportIssue>
}

export interface AssetsStats {
  total: number
}

export interface AssetDetails {
  iac: AssetIacDetails
  secrets: Array<AssetsCount>
  discover: Array<AssetsCount>
}

export interface AssetsCount {
  assetId: string
  count: number
}

export interface AssetIacDetails {
  iacFilesPaths: Array<AssetIacFilesPaths>
  iacIssuesCount: Array<AssetsCount>
}

export interface AssetIacFilesPaths {
  assetId: string
  iacFiles: Array<string>
}

export interface AssetSecretSprawlView {
  detectorName: string
  fingerprint: string
  firstSeen: Date
  numberOfAssets: number
  issues: Array<ReportIssue>
}

export const ASSETS_PAGE_SIZE = 10
export const dateFormat = 'yyyy-MM-dd HH:mm:ss'
export const dateWithoutTimeFormat = 'yyyy-MM-dd'
export const ASSET_SUMMARY_RESULT_SIZE = 2000
