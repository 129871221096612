import React from 'react'
import cubes from './cubes-icon'
import { ReactComponent as Shuki } from './shuki-white.svg'

import { ReactComponent as Sources } from '../assets/svg/sp-sources.svg'
import { ReactComponent as Scans } from '../assets/svg/sp-scans.svg'

const sources = <Sources />
const scans = <Scans />

export { cubes, sources, scans, Shuki }
