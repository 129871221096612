/** @jsx jsx */
import { jsx, Flex, Text, Box } from 'theme-ui'

export const TextStyle = (props) => {
  const { sx, ...rest } = props
  return (
    <Text
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      sx={{
        ...sx,
        justifyContent: 'center',
        wordBreak: 'break-word',
      }}
    />
  )
}
export const Note = (props) => (
  <Text
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    sx={{
      fontSize: '12px',
      color: 'paleText',
    }}
  />
)
export const Sections = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box {...props} sx={{ counterReset: 'section' }} />
)

export const ListSection = (props) => (
  <Flex
    sx={{
      my: 2,
      ml: 3,
      alignItems: 'flex-start',
      '::before': {
        counterIncrement: 'section',
        content: 'counter(section)',
        display: 'inline-block',
        clipPath: 'polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)',
        color: 'primary',
        background: `
      linear-gradient(to right, #5f22b9 0%, transparent 8% 100%), 
      linear-gradient(to left, #5f22b9 0%, transparent 8% 100%), 
      linear-gradient(29deg, #5f22b9 16%, transparent 23% 100%),
      linear-gradient(209deg, #5f22b9 15%, transparent 22% 100%),
      linear-gradient(151deg, #5f22b9 15%, transparent 22% 100%),
      linear-gradient(331deg, #5f22b9 16%, transparent 23% 100%)
    `,
        px: '6px',
        mr: 3,
        flexGrow: 0,
        flexBase: 'auto',
      },
    }}
  >
    <Flex // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      sx={{ flex: 1, flexDirection: 'column', gap: 1 }}
    />
  </Flex>
)
