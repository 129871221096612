/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { jsx, Flex, Box, Heading, Text } from 'theme-ui'
import { Tag } from 'antd'

export const FeatureHeading = ({ image, title, subtitle, tags = [] }) => (
  <Flex>
    <Box>{image}</Box>
    <Box sx={{ ml: '30px' }}>
      <Box>
        <Heading as="h3" variant="smallheading">
          {title}
        </Heading>
      </Box>
      <Flex sx={{ flexDirection: 'row' }}>
        <Text sx={{ mr: '10px' }} variant="paleSummary">
          {subtitle}
        </Text>
        {tags && (
          <Box>
            {tags.map((t) => (
              <Tag key={t}>{t}</Tag>
            ))}
          </Box>
        )}
      </Flex>
    </Box>
  </Flex>
)
