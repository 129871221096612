/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { Alert } from 'antd'
import { LinkButton } from '../../components'
import { paths } from '../../routes'

const PlanExpirationAlert = ({
  daysUntilTrialExpire,
  handleCloseAlert,
  isUpgradeButtonShown,
}) => {
  const alertMessage = (
    <Box>
      {daysUntilTrialExpire === 0 ? (
        <span>This is the last day of your trial.</span>
      ) : (
        <span>
          <span>
            You have <strong>{daysUntilTrialExpire}</strong> day
            {daysUntilTrialExpire > 1 && <span>s</span>} left in your trial.
          </span>
        </span>
      )}
      {isUpgradeButtonShown && (
        <LinkButton
          sx={{ ml: 3 }}
          text="Upgrade Plan"
          size="small"
          type="primary"
          to={paths.closed.settingsPlans}
        />
      )}
    </Box>
  )
  return (
    <Alert
      banner
      type="info"
      message={alertMessage}
      closable
      onClose={handleCloseAlert}
    />
  )
}
export default PlanExpirationAlert
