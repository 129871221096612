import map from 'lodash/map'
import {
  IssueIgnoreAction,
  IssueIgnoreTypes,
  ReportIssue,
  IssueKind,
} from '@spectral/types'
import { FetchStatus, sdkClient, RootState } from '../../store'
import { empty, loaded, mergeIssuesLists } from '../../utils'
import track from '../../../common/track'

type IssuesState = {
  issues: { fetchStatus: FetchStatus; data: Array<ReportIssue> }
  iacIssues: { fetchStatus: FetchStatus; data: Array<ReportIssue> }
}
const initialState: IssuesState = {
  issues: empty([]),
  iacIssues: empty([]),
}

const splitBetweenIssuesAndIacIssues = (issues) => {
  const iacIssues = issues.filter((issue) => issue.kind === IssueKind.Iac)
  const issuesWithoutIac = issues.filter((issue) => {
    return issue.kind !== IssueKind.Iac
  })
  return { issuesWithoutIac, iacIssues }
}

export const issuesModel = {
  state: initialState,
  reducers: {
    upsert(state: IssuesState, newIssues: any) {
      const { iacIssues, issuesWithoutIac } = splitBetweenIssuesAndIacIssues(
        newIssues
      )

      const newState = { ...state }
      const newIssuesWithoutIac = mergeIssuesLists(
        issuesWithoutIac,
        newState.issues.data
      )

      const newIacIssues = mergeIssuesLists(iacIssues, newState.iacIssues.data)

      return {
        ...state,
        issues: loaded(newIssuesWithoutIac),
        iacIssues: loaded(newIacIssues),
      }
    },
    setIssues(state: IssuesState, newIssues: any) {
      return { ...state, issues: loaded(newIssues) }
    },
    setIacIssues(state: IssuesState, newIssues: any) {
      return { ...state, iacIssues: loaded(newIssues) }
    },
    removeIssues(state: IssuesState, issuesToRemove: any) {
      const { iacIssues, issuesWithoutIac } = splitBetweenIssuesAndIacIssues(
        issuesToRemove
      )
      const iacIdsToRemove = iacIssues.map((issue) => issue.pid)
      const issuesWithoutIacIdsToRemove = issuesWithoutIac.map(
        (issue) => issue.pid
      )
      const newState = { ...state }
      return {
        ...state,
        issues: loaded(
          newState.issues.data.filter(
            (issue) => !issuesWithoutIacIdsToRemove.includes(issue.pid)
          )
        ),
        iacIssues: loaded(
          newState.issues.data.filter(
            (issue) => !iacIdsToRemove.includes(issue.pid)
          )
        ),
      }
    },
  },
  effects: (dispatch: any) => ({
    async fetchByAssetId(payload) {
      const { assetId } = payload
      const { data } = (await sdkClient.issues().getByAssetId({
        params: { assetId: encodeURIComponent(assetId) },
      })) as {
        data: Array<any>
      }
      dispatch.Issues.setIssues(data)
    },
    async ignoreIssues({
      issues,
      action,
      ignoreType,
      comment,
      page,
      buttonType,
      daysToSnooze,
    }: {
      issues: Array<object>
      action: IssueIgnoreAction
      ignoreType: IssueIgnoreTypes
      comment: string
      page: string
      buttonType: string
      daysToSnooze?: number
    }) {
      track.ignore({ action, page, ignoreType, buttonType })
      const issuePids = map(issues, 'pid')
      let response
      if (action === IssueIgnoreAction.add)
        response = await sdkClient.issues().ignoreIssue({
          data: { issues: issuePids, ignoreType, comment, daysToSnooze },
        })

      if (action === IssueIgnoreAction.remove) {
        response = await sdkClient
          .issues()
          .unIgnoreIssue({ data: { issues: issuePids } })
      }
      dispatch.Issues.upsert(response.updatedIssues)
      dispatch.Auth.refetchUser()
    },
    async resolveIssues({ issues, page, buttonType }) {
      track.resolve({ page, buttonType })
      await sdkClient.issues().resolveIssues({ data: { issues } })
      dispatch.Auth.refetchUser()
    },
    async unresolveIssues({ issues, page, buttonType }) {
      track.unresolve({ page, buttonType })
      await sdkClient.issues().unresolveIssues({ data: { issues } })
      dispatch.Auth.refetchUser()
    },
    async assignIssues({ assigneePid, issueIds }) {
      track.assignIssues(issueIds.length)
      const response = await sdkClient.issues().assignIssues({
        data: {
          issueIds,
          assigneePid,
        },
      })
      // @ts-ignore
      dispatch.Issues.upsert(response.updatedIssues)
    },
    async unassignIssues({ issueIds }) {
      track.unassignIssues(issueIds.length)
      const response = await sdkClient.issues().unassignIssues({
        data: {
          issueIds,
        },
      })
      // @ts-ignore
      dispatch.Issues.upsert(response.updatedIssues)
    },
    changeDetectorSeverity(payload, rootState: RootState) {
      const { detectorId, newSeverity } = payload

      const isNonIaCIssues =
        rootState.Issues.issues.data.find(
          (issue) => issue.detectorId === detectorId
        ) !== undefined

      const execute = (
        issues,
        detectorIdToChange,
        newSeverityToChange,
        setIssues
      ) => {
        const oldSeverity = issues[0].displaySeverity
        const newIssues = issues.map((issue) => {
          if (issue.detectorId === detectorIdToChange) {
            return {
              ...issue,
              displaySeverity: newSeverityToChange,
            }
          }
          return issue
        })
        setIssues(newIssues)
        track.severityChange(detectorIdToChange, newSeverity, oldSeverity)
      }

      if (isNonIaCIssues) {
        execute(
          rootState.Issues.issues.data,
          detectorId,
          newSeverity,
          dispatch.Issues.setIssues
        )
      } else {
        execute(
          rootState.Issues.iacIssues.data,
          detectorId,
          newSeverity,
          dispatch.Issues.setIacIssues
        )
      }
      dispatch.Auth.refetchUser()
    },
  }),
}
