/** @jsx jsx */
// eslint-disable-next-line
import { jsx, Flex, Box, Text } from 'theme-ui'
import { imageMap } from '../../common/sources/imageMap'

const SourcesTile = ({ tileKey, title, onClick }) => (
  <Flex
    role="button"
    tabIndex={0}
    onClick={onClick}
    sx={{
      cursor: 'pointer',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '15px',
      border: 'outline',
      width: '210px',
      maxHeight: '200px',
      bg: 'white',
      borderRadius: '4px',
      padding: '15px',
      textOverflow: 'ellipsis',
      transition: 'all 0.2s',
      ':hover': {
        border: '1px solid #fff',
        boxShadow: 'tileFocused',
      },
    }}
  >
    {imageMap[tileKey] && (
      <img
        sx={{ width: '30px', height: '30px' }}
        src={imageMap[tileKey]}
        alt={tileKey}
      />
    )}
    <Box>
      <Text variant="cardSmallTitle">{title}</Text>
    </Box>
  </Flex>
)

export default SourcesTile
