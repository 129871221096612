import { ModelConfig } from '@rematch/core'
import { CustomRulesVersionStatus } from '@spectral/types'
import { FetchStatus, RootState, sdkClient, select } from '../../store'
import { empty, loaded } from '../../utils'

type CustomRulesState = {
  versions: {
    data: Array<any>
    fetchStatus: FetchStatus
  }
}

const initialState: CustomRulesState = {
  versions: empty([]),
}

export const customRules: ModelConfig<CustomRulesState> = {
  state: initialState,
  reducers: {
    setVersions(state: CustomRulesState, versionsData: any) {
      return { ...state, versions: loaded(versionsData) }
    },
  },
  effects: (dispatch: any) => ({
    async fetchCustomRulesVersions() {
      const versions = (await sdkClient.customRules().getCustomRulesVersions({
        params: {
          status: [
            CustomRulesVersionStatus.Active,
            CustomRulesVersionStatus.Draft,
          ],
        },
      })) as any

      dispatch.CustomRules.setVersions(versions)
    },
    async reviewDraftVersion({ comment, newStatus }) {
      await sdkClient
        .customRules()
        .reviewDraftVersion({ data: { comment, status: newStatus } })

      await dispatch.CustomRules.fetchCustomRulesVersions()
    },
    async deactivateVersion() {
      await sdkClient.customRules().deactivateVersion()
      dispatch.CustomRules.fetchCustomRulesVersions()
    },
  }),
  selectors: (_slice, createSelector, _hasProps) => ({
    versions() {
      return createSelector(
        (rootState) => (rootState as RootState).CustomRules,
        (customRulesState: CustomRulesState) => {
          return {
            active: customRulesState.versions.data?.find(
              (version) => version.status === CustomRulesVersionStatus.Active
            ),
            draft: customRulesState.versions.data?.find(
              (version) => version.status === CustomRulesVersionStatus.Draft
            ),
          }
        }
      )
    },
    hasCustomRulesDraftVersion() {
      return createSelector(this.versions, (versions: any) => {
        return !!versions.draft
      })
    },
    reviewError() {
      return createSelector(
        (rootState) => (rootState as RootState).error,
        (errors) => {
          return errors.effects.CustomRules.reviewDraftVersion
        }
      )
    },
    isFetchingVersions() {
      return (state) =>
        !!state.loading.effects.CustomRules.fetchCustomRulesVersions
    },
    isSubmittingReview() {
      return (state) => !!state.loading.effects.CustomRules.reviewDraftVersion
    },
    isDeactivatingVersion() {
      return (state) => !!state.loading.effects.CustomRules.deactivateVersion
    },
  }),
}
