/** @jsx jsx */
import { jsx } from 'theme-ui'
import L from 'lodash'
import { AssetType } from '@spectral/types'
import View from '../../../blocks/source-integration/independent-integrations/confluence'
import { useStatsFetch } from './use-stats-fetch'

const ConfluenceSourceIntegration = ({
  source: { data },
  setIsTeamTokenShown,
  shouldShowTeamToken,
}) => {
  const [assetKindsStats, loaded] = useStatsFetch()
  const isConnected = L.find(
    assetKindsStats,
    ({ assetType, total }) => assetType === AssetType.Confluence && total > 0
  )
  return (
    <View
      dataSource={data}
      isConnected={isConnected}
      statusLoaded={loaded}
      onSwitchTokenShown={setIsTeamTokenShown}
      shouldShowTeamToken={shouldShowTeamToken}
    />
  )
}

export default ConfluenceSourceIntegration
