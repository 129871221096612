import L from 'lodash'
import detectorsHelp from 'detectorHelp/detectors-help.json'

declare let Cypress: any

export const BASE_URL = window.location.origin
const url = new URL(BASE_URL)
export const BASE_URL_PROTOCOL = url.protocol
export const BASE_URL_HOST = url.host

export const getDetectorInfo = (playbookId) =>
  detectorsHelp[playbookId.toUpperCase()]

export const bytesToHumanText = (bytes: number) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const sizes = ['B', 'kB', 'MB', 'GB', 'TB']
  // eslint-disable-next-line no-restricted-properties
  const text = (bytes / Math.pow(1024, i)).toFixed(2)
  return `${text} ${sizes[i]}`
}

export const getDetectorIdFromFilter = (value) => {
  return value.split(' - ')[0]
}

export const getDetectorsIds = (detectorsIdsText) => {
  const detectorsIds =
    detectorsIdsText &&
    detectorsIdsText.map((detectorId) => getDetectorIdFromFilter(detectorId))
  return L.uniq(detectorsIds)
}

export const scrollToElementWithId = (elementId) => {
  const element = document.getElementById(elementId)
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset - 100

    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}

export const isEmbedded = () => window.self !== window.top

export const isCypress = () => typeof Cypress !== 'undefined'
