import { DownOutlined } from '@ant-design/icons'
import { Button, Checkbox, Dropdown, Menu, Space, Tag } from 'antd'
import React from 'react'
import { Box, Flex, Text } from 'theme-ui'

const AppliedFilterTag = ({ onClearFilter, children }) => {
  return (
    <Tag
      closable
      onClose={onClearFilter}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '400px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      {children}
    </Tag>
  )
}

const AppliedFilters = ({
  filters,
  onRemoveFilter,
  onClearAll,
  onClearFilter,
}) => {
  return (
    <Space size={8} wrap>
      <Text variant="small">Applied Filters:</Text>
      {filters.map(({ filterName, filtered, filterKey }) => (
        <Box key={filterKey}>
          {filtered.length === 1 ? (
            <AppliedFilterTag onClearFilter={() => onClearFilter([filterKey])}>
              {filterName}: {filtered[0].filterDisplayValue}
            </AppliedFilterTag>
          ) : (
            <Dropdown
              trigger={['click']}
              overlay={
                <Box
                  sx={{
                    bg: 'white',
                    border: '1px solid #d4dae2',
                    borderRadius: '4px',
                  }}
                >
                  <Menu
                    style={{
                      maxHeight: '200px',
                      overflowY: 'auto',
                      background: 'transparent',
                    }}
                  >
                    {filtered.map((filter) => (
                      <Menu.Item
                        style={{
                          lineHeight: '30px',
                          maxWidth: '200px',
                          height: 'auto',
                        }}
                        key={filter.filterValue}
                        onClick={() => {
                          onRemoveFilter(filterKey)(
                            filtered
                              .filter(
                                (val) => val.filterValue !== filter.filterValue
                              )
                              .map((f) => f.filterValue)
                          )
                        }}
                      >
                        <Flex
                          sx={{
                            alignItems: 'center',
                            gap: 2,
                          }}
                          title={filter.filterDisplayValue}
                        >
                          <Checkbox checked />
                          <Text
                            as="span"
                            variant="small"
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {filter.filterDisplayValue}
                          </Text>
                        </Flex>
                      </Menu.Item>
                    ))}
                  </Menu>
                </Box>
              }
            >
              <Box>
                <AppliedFilterTag
                  onClearFilter={() => onClearFilter([filterKey])}
                >
                  <Flex
                    sx={{
                      alignItems: 'center',
                      gap: 2,
                      cursor: 'pointer',
                    }}
                    role="button"
                  >
                    {filterName}: {filtered.length} Selected
                    <Flex
                      role="button"
                      sx={{
                        fontSize: '10px',
                        transition: 'all 0.3s',
                        '&:not(:hover)': { opacity: '0.4' },
                      }}
                    >
                      <DownOutlined />
                    </Flex>
                  </Flex>
                </AppliedFilterTag>
              </Box>
            </Dropdown>
          )}
        </Box>
      ))}
      <Button size="small" type="link" onClick={onClearAll}>
        Clear all
      </Button>
    </Space>
  )
}

export default AppliedFilters
