import { Box } from 'theme-ui'
import React from 'react'

const TooltipBox = ({ children }) => (
  <Box
    sx={{
      backgroundColor: 'white',
      fontSize: '12px',
      p: '2px 8px',
      boxShadow: 'gray 2px 2px 2px',
    }}
  >
    {children}
  </Box>
)

export default TooltipBox
