import { Alert } from 'antd'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import ErrorMessage from '../forms/error-message'
import {
  ContentPanel,
  ContentPanelHeader,
  PanelImageHeader,
  SectionHeader,
  PanelForm,
  SwitchSection,
} from '../panels/content'

const DisabledWarning = ({ enabled, values, message }) => {
  return !enabled && values && Object.values(values).some((value) => value) ? (
    <Alert message={message} type="warning" showIcon />
  ) : null
}

const IntegrationErrorMsg = ({ integrationError }) => {
  return integrationError ? (
    <ErrorMessage error={integrationError} showIcon />
  ) : null
}

export const IntegrationSection = ({
  children,
  onEnableToggle,
  integrationName,
  headerLogo = null,
  headerTitle,
  sectionTitle = '',
  sectionDescription = '',
  sectionRenderedContent = null,
  hasSectionHeader = true,
  integrationError = null,
  enabled = false,
  message = 'Integration is globally disabled',
}) => {
  const name = `${integrationName}_integrations_checkbox`
  const initialValues = {}
  initialValues[name] = enabled
  const childrenInitialValues = children.props.initialValues
  return (
    <ContentPanel>
      <ContentPanelHeader>
        <Flex
          id={`ffs_${name}`}
          sx={{ justifyContent: 'space-between', width: '100%' }}
        >
          <PanelImageHeader img={headerLogo} title={headerTitle} />
          <PanelForm onSubmit={onEnableToggle} initialValues={initialValues}>
            <SwitchSection name={name} />
          </PanelForm>
        </Flex>
      </ContentPanelHeader>
      <DisabledWarning
        enabled={enabled}
        values={childrenInitialValues}
        message={message}
      />
      <IntegrationErrorMsg integrationError={integrationError} />
      {hasSectionHeader && (
        <SectionHeader
          title={sectionTitle}
          description={sectionDescription}
          renderContent={sectionRenderedContent}
        />
      )}
      <Box sx={{ mt: hasSectionHeader ? 0 : '20px' }}>{children}</Box>
    </ContentPanel>
  )
}
