import React from 'react'
import { Divider, Modal } from 'antd'
import * as Yup from 'yup'
import { Box } from 'theme-ui'
import {
  InputFieldSection,
  PanelForm,
  SubmitSection,
} from '../../../../components/panels/content'

type Props = {
  onClose
  onSubmit
  ignore: any
  mode: 'new' | 'edit'
}

const AttributesSeparator = () => (
  <Box style={{ textAlign: 'center', color: '#A0AEC0' }}>- And -</Box>
)

const UpsertIgnoreModal = ({
  onClose,
  onSubmit,
  ignore = null,
  mode = 'new',
}: Props) => {
  return (
    <Modal
      style={{ top: 50 }}
      width={800}
      visible
      onCancel={onClose}
      footer={null}
      title={`${mode === 'new' ? 'Add New' : 'Edit'} Ignore`}
    >
      <PanelForm
        onSubmit={onSubmit}
        validate={(values) => {
          let errors = {}
          if (
            !values.rule_id &&
            !values.rule_name &&
            !values.path &&
            !values.match_fingerprint &&
            !values.match_text &&
            !values.match_line_text
          ) {
            errors = { general: 'At least one field is required' }
          }

          return errors
        }}
        validationSchema={Yup.object({
          rule_id: Yup.string().min(3).max(64),
          rule_name: Yup.string().min(2),
          path: Yup.string().min(2),
          match_fingerprint: Yup.string().length(64),
          match_text: Yup.string().min(2),
          match_line_text: Yup.string().min(2),
        })}
        initialValues={{
          rule_id: ignore?.rule_id ?? '',
          rule_name: ignore?.rule_name ?? '',
          path: ignore?.path ?? '',
          match_fingerprint: ignore?.match_fingerprint ?? '',
          match_text: ignore?.match_text ?? '',
          match_line_text: ignore?.match_line_text ?? '',
        }}
      >
        <Divider style={{ margin: '0 0' }}>Ignore by rules</Divider>
        <InputFieldSection
          name="rule_id"
          label="Rule ID"
          description="Ignore issues by rule ids regex"
          placeholder="DB.*"
          merge
        />
        <AttributesSeparator />
        <InputFieldSection
          name="rule_name"
          label="Rule Name"
          description="Ignore issues by rule names regex"
          placeholder="Visible AWS Key"
          merge
        />
        <Divider style={{ margin: '0 0' }}>Ignore by match</Divider>
        <InputFieldSection
          name="path"
          label="Path"
          description="Ignore all issues exists under all file paths matches to a regex. Path must be in Unix format."
          placeholder="tests/.*"
          merge
        />
        <AttributesSeparator />
        <InputFieldSection
          name="match_fingerprint"
          label="Match Fingerprint"
          description="Ignore an issue by fingerprint identifier regex"
          placeholder="b76fe610abe3bdaa92d4002..."
          merge
        />
        <AttributesSeparator />
        <InputFieldSection
          name="match_text"
          label="Finding Text"
          description="Ignore finding matching it's value to a regex"
          placeholder="TFAWS.*"
          merge
        />
        <AttributesSeparator />
        <InputFieldSection
          name="match_line_text"
          label="Finding Line Text"
          description="Ignore finding by matching the whole line it exists in to a regex"
          placeholder="dev_value.*"
          merge
        />
        <SubmitSection text="Save" merge />
      </PanelForm>
    </Modal>
  )
}

export default UpsertIgnoreModal
