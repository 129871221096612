import L from 'lodash'

export const getRequiredFields = (
  projects,
  selectedProjectId,
  issueTypeId,
  exclude_requierd
) => {
  if (!selectedProjectId || !issueTypeId) return []
  const selectedProjectInfo = L(
    L.filter(projects, (project) => project.key === selectedProjectId)
  ).first()
  const issueTypeInfo = L(
    L.filter(
      selectedProjectInfo.issuetypes,
      (issueType) => issueType.name === issueTypeId
    )
  ).first()
  const requiredFields = L.filter(
    issueTypeInfo?.fields || [],
    (field) => field.required
  )
  return L.filter(
    requiredFields,
    (rfield) => !exclude_requierd.includes(rfield.key)
  )
}
