/** @jsx jsx */
import { Box, jsx, Text } from 'theme-ui'
import { TileStrip } from '../tile-strip'

const SelectDataSource = ({
  onDataSourceSelect,
  dataSource,
  mode,
  operatingSystem,
  sources,
}) => {
  switch (mode) {
    case 'audit':
      return (
        <Box>
          <Box sx={{ textAlign: 'center', mb: '80px' }}>
            <Text variant="wizardheading">
              Where is the code you want to audit?
            </Text>
            <Text variant="wizardsub">
              Spectral will show you hardcoded credentials, secrets, API tokens
              and misconfigurations
            </Text>
          </Box>
          <TileStrip
            items={sources && sources.audit[operatingSystem]}
            onClick={onDataSourceSelect}
            selectedItem={dataSource}
            align="center"
          />
        </Box>
      )
    case 'mitigate':
      return (
        <Box>
          <Box sx={{ textAlign: 'center', mb: '80px' }}>
            <Text variant="wizardheading">Setup your CI/CD</Text>
            <Text variant="wizardsub">
              Start scanning for hardcoded credentials, secrets, API tokens and
              misconfigurations now
            </Text>
          </Box>
          <TileStrip
            items={sources && sources.mitigate}
            onClick={onDataSourceSelect}
            selectedItem={dataSource}
            align="center"
          />
        </Box>
      )
    default:
      return null
  }
}
export default SelectDataSource
