import { useDispatch } from 'react-redux'
import React, { useCallback } from 'react'
import View from './view'

export default ({
  detectorId,
  detectorName,
  currentSeverity,
  onSeverityChanged,
}) => {
  const dispatch = useDispatch()
  const severityChanged = useCallback(
    async (newSeverity, oldSeverity) => {
      await dispatch.Teams.changeRuleSeverity({
        detectorId,
        newSeverity,
        oldSeverity,
      })

      onSeverityChanged({
        detectorId,
        newSeverity,
      })
    },
    [detectorId, dispatch.Teams, onSeverityChanged]
  )
  return (
    <View
      detectorId={detectorId}
      detectorName={detectorName}
      currentSeverity={currentSeverity}
      onSeverityChanged={severityChanged}
    />
  )
}
