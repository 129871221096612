export type IDigestLinks = {
  darwin: string
  linux: string
  win: string
}

export enum WizardMode {
  audit = 'audit',
  mitigate = 'mitigate',
  githubPublic = 'githubPublic',
  firstUse = 'firstUse',
  discover = 'discover',
}

export enum ActionType {
  RESET = 'reset',
  SET_MODE = 'setMode',
  DECREASE_STEP = 'decreaseStep',
  INCREASE_STEP = 'increaseStep',
  SET_DATA_SOURCE = 'setDataSource',
  CHANGE_OPERATING_SYSTEM = 'changeOperatingSystem',
}
