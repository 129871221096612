import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { select } from '../redux/store'

function useIssuesActionIntegrations() {
  const { jiraSettings, mondaySettings } = useSelector(
    select.Teams.teamSettings
  )
  const isTeamSettingsLoaded = useSelector(
    (state) => state.Teams.settings.fetchStatus === 'loaded'
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isTeamSettingsLoaded) {
      dispatch.Teams.fetchSettings()
    }
  }, [])
  const { isActivated, domain: jiraDomain } = jiraSettings
  const {
    enabled: isMondayIntegrationEnabled,
    domain: mondayDomain,
  } = mondaySettings

  const integrations = {
    jira: {
      isActivated,
      jiraEndpoint: jiraDomain,
    },
    monday: {
      enabled: isMondayIntegrationEnabled,
      domain: mondayDomain,
    },
  }

  return { integrations }
}

export default useIssuesActionIntegrations
