/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Alert, Button } from 'antd'

const EmailVerificationAlert = ({
  onResendClicked,
  email,
  onCloseClicked,
  isLoading = false,
  isVerificationSent = false,
  error = null,
}) => {
  let alertType
  let alertMessage
  if (error) {
    alertType = 'error'
    alertMessage =
      'There has been a problem with resending the verification email. Try again?'
  } else if (isVerificationSent) {
    alertType = 'success'
    alertMessage =
      'Verification email sent. Follow the link that we sent to your email to complete verification and start seeing notifications.'
  } else if (isLoading) {
    alertType = 'info'
    alertMessage = 'Resending in progress...'
  } else {
    alertType = 'info'
    alertMessage = (
      <div>
        <span>Your email address </span>(<strong>{email}</strong>)
        <span>
          &nbsp;hasn&apos;t been verified yet. Please verify to enable
          notifications.
        </span>
      </div>
    )
  }
  return (
    <Alert
      banner
      type={alertType}
      message={alertMessage}
      action={
        !isVerificationSent && (
          <Button type="link" onClick={onResendClicked} loading={isLoading}>
            Resend verification email
          </Button>
        )
      }
      closable
      onClose={onCloseClicked}
    />
  )
}
export default EmailVerificationAlert
