import { timeSinceInDays } from '@spectral/types'
import { Alert } from 'antd'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import { EmphasedText } from './common'

export const ActiveVersionWithNotRulesAlert = (
  <Alert
    message="Empty custom rules active version"
    description={
      <Box>
        Current active version has no custom rules, only local custom rules
        would take place in your scans
      </Box>
    }
    type="info"
    showIcon
  />
)

export const ActiveVersionExistAlert = ({ approver, approveDate }) => (
  <Alert
    message="Your organization has an active custom rules version"
    description={
      <>
        <Flex>
          Activated{' '}
          <EmphasedText>{timeSinceInDays(approveDate)} ago</EmphasedText>by{' '}
          <EmphasedText>{approver}</EmphasedText>
        </Flex>
      </>
    }
    type="success"
    showIcon
    style={{ marginBottom: '30px' }}
  />
)

export const DraftVersionExistAlert = (
  <Alert
    message="You have a custom rules version waiting for review"
    description=""
    type="info"
    showIcon
  />
)

export const NoActiveVersionAlert = (
  <Alert
    message="Your organization does not have an active custom rules version"
    type="info"
    showIcon
  />
)

export const renderActiveVersionAlert = (
  activeVersion,
  draftVersion,
  approver,
  approveDate
) => {
  return (
    <>
      {!activeVersion && !draftVersion && NoActiveVersionAlert}
      {activeVersion && !draftVersion && (
        <ActiveVersionExistAlert
          approver={approver}
          approveDate={approveDate}
        />
      )}
      {draftVersion && DraftVersionExistAlert}
    </>
  )
}
