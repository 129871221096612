import {
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons'
import React from 'react'
import ButtonWithTooltip from '../../components/buttons/button-with-tooltip'
import AssigneeSelector from '../assignee-change-dropdown-new'

const AssignButton = ({
  isLoading,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  onUnassignClick,
  onAssignClick,
  selectedIssues,
  assignableMembers,
  onSearchAssignee,
}) => {
  const assignableIssues = selectedIssues.filter((issue) => !issue.assignee)
  const assignedIssue = selectedIssues.filter((issue) => issue.assignee)

  let view
  if (
    (assignedIssue.length === 0 && assignableIssues.length === 0) ||
    (assignedIssue.length !== 0 && assignableIssues.length !== 0)
  ) {
    view = 'disabled'
  } else if (assignedIssue.length > 0 && assignableIssues.length === 0) {
    view = 'unassign'
  } else {
    view = 'assign'
  }

  switch (view) {
    case 'disabled':
      return (
        <ButtonWithTooltip
          isLoading={isLoading}
          icon={<UsergroupAddOutlined />}
          text="Assign"
          disabled
          onActionClick={null}
        />
      )
    case 'unassign':
      return (
        <ButtonWithTooltip
          isLoading={isLoading}
          icon={<UsergroupDeleteOutlined />}
          text="Unassign"
          disabled={false}
          onActionClick={onUnassignClick}
        />
      )
    default:
      return (
        <AssigneeSelector
          assignableMembers={assignableMembers}
          onAssigneeChanged={onAssignClick}
          onSearchAssignee={onSearchAssignee}
          isLoadingAssignableMembers={isLoadingAssignableMembers}
          isAssignableMembersLoaded={isAssignableMembersLoaded}
          dropdownButton={
            <ButtonWithTooltip
              isLoading={isLoading}
              icon={<UsergroupAddOutlined />}
              text="Assign"
              disabled={false}
              onActionClick={null}
            />
          }
        />
      )
  }
}

export default AssignButton
