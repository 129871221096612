export enum AuditAction {
  CREATE_MONDAY_ITEM = 'CREATE_MONDAY_ITEM',
  CREATE_JIRA_ISSUE = 'CREATE_JIRA_ISSUE',
  ADD_IGNORES_BULK = 'ADD_IGNORES_BULK',
  REMOVE_IGNORES_BULK = 'REMOVE_IGNORES_BULK',
  ADD_IGNORES_BULK_SNOOZE = 'ADD_IGNORES_BULK_SNOOZE',
  RESOLVE_ISSUES = 'RESOLVE_ISSUES',
  UNRESOLVE_ISSUES = 'UNRESOLVE_ISSUES',
  REMOVE_IGNORES_BULK_SNOOZE = 'REMOVE_IGNORES_BULK_SNOOZE',
  ADD_MEMBER = 'ADD_MEMBER',
  REMOVE_MEMBER_OR_INVITE = 'REMOVE_MEMBER_OR_INVITE',
  UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS',
  UPDATE_TEAM_SETTINGS = 'UPDATE_TEAM_SETTINGS',
  CHANGE_MEMBER_ROLE = 'CHANGE_MEMBER_ROLE',
  UPDATE_TEAM_NAME = 'UPDATE_TEAM_NAME',
  UPDATE_SCAN_CONFIG = 'UPDATE_SCAN_CONFIG',
  UPDATE_REMOTE_CONFIG = 'UPDATE_REMOTE_CONFIG',
  RESET_REMOTE_CONFIG = 'RESET_REMOTE_CONFIG',
  SET_MEMBER_TEAMS = 'SET_MEMBER_TEAMS',
  SET_SCM_ONPREM_DOMAIN = 'SET_SCM_ONPREM_DOMAIN',
  SET_SCM_URL_FORMATS = 'SET_SCM_URL_FORMATS',
  SET_AUTH_SETTINGS = 'SET_AUTH_SETTINGS',
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGIN_CLOUD_GUARD = 'USER_LOGIN_CLOUD_GUARD',
  USER_LOGIN_GOOGLE = 'USER_LOGIN_GOOGLE',
  USER_LOGIN_GITHUB = 'USER_LOGIN_GITHUB',
  USER_LOGIN_VS = 'USER_LOGIN_VS',
  USER_LOGIN_SAML2 = 'USER_LOGIN_SAML2',
  DELETE_ASSET = 'DELETE_ASSET',
  REVIEW_CUSTOM_RULES_VERSION = 'REVIEW_CUSTOM_RULES_VERSION',
  REGENERATE_API_KEY = 'REGENERATE_API_KEY',
  REGENERATE_TEAM_KEY = 'REGENERATE_TEAM_KEY',
  INSTALL_GITHUB_APP = 'INSTALL_GITHUB_APP',
  USER_ACCEPTED_INVITE = 'USER_ACCEPTED_INVITE',
  OPT_IN_FEATURES = 'OPT_IN_FEATURES',
  CHANGE_DETECTOR_SEVERITY = 'CHANGE_DETECTOR_SEVERITY',
  MAP_USERS = 'MAP_USERS',
  DELETE_USERS = 'DELETE_USERS',
}

export interface ActivityItem {
  actorFirstName: string
  actorLastName: string
  actorEmail: string
  kind: AuditAction
  data: any
  date: Date
  avatarUrl: string
}
