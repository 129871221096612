import { Engines } from '@spectral/types'

export type EnginesCheckedState = Record<Engines, boolean>

export const isEngineSolelyChecked = (
  enginesState: EnginesCheckedState,
  engine: Engines
) => {
  return (
    enginesState[engine] &&
    Object.values(enginesState).filter(Boolean).length === 1
  )
}
