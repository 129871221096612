/* eslint-disable no-shadow */
import { enginesToDisplayName, ScanInsights } from '@spectral/types'
import omit from 'lodash/omit'
import { sdkClient } from '../../store'
import { empty, loaded } from '../../utils'
import type { FetchStatus } from '../../store'

export type ScanInsightsState = {
  scanInsights: {
    fetchStatus: FetchStatus
    data: {
      scanInsights: Array<ScanInsights>
      pagination: {
        page: number
        pageSize: number
        total: number
      }
    }
  }
}

const initialState: ScanInsightsState = {
  scanInsights: empty({
    scanInsights: [],
    pagination: null,
  }),
}
export const scanInsights = {
  state: initialState,
  reducers: {
    set(state: ScanInsightsState, scanInsightsToSet: any) {
      return { ...state, scanInsights: loaded(scanInsightsToSet) }
    },
    reset() {
      return { ...initialState }
    },
  },
  effects: (dispatch: any) => ({
    async fetchAssetScanInsights({
      assetId,
      page,
      pageSize,
      sortBy,
      sortDirection,
    }) {
      const { data } = (await sdkClient.scanInsights().byAssetId({
        params: {
          assetId,
          pagination: { page, pageSize, sortBy, sortDirection },
        },
      })) as any
      dispatch.ScanInsights.set(data)
    },
  }),
  selectors: (slice, createSelector, _hasProps) => ({
    assetScansHistoryPage() {
      return createSelector(
        slice,
        (rootState) => rootState.loading,
        (scanInsightsState, loadingState) => {
          const isLoading =
            loadingState.effects.ScanInsights.fetchAssetScanInsights > 0

          const scanInsights = scanInsightsState.scanInsights.data.scanInsights.map(
            (scanInsights) => {
              const ruleSpecItems = scanInsights.scanContext.rulesSpec
              const rulesSpec = {
                'included rules': ruleSpecItems?.include.ids,
                'included tags': ruleSpecItems?.include.tags,
                'excluded rules': ruleSpecItems?.exclude.ids,
                'excluded tags': ruleSpecItems?.exclude.tags,
              }

              const issuesEffect = Object.entries(
                scanInsights.issuesEffect.new
              ).reduce(
                (acc, [severity, value]: [string, number]) => {
                  if (value > 0) {
                    acc[severity] = value
                    acc.newIssuesCount += value
                  }
                  return acc
                },
                { newIssuesCount: 0 }
              )
              return {
                ...scanInsights,
                issuesEffect: {
                  ...scanInsights.issuesEffect,
                  new: omit(issuesEffect, 'newIssuesCount'),
                },
                scanContext: {
                  ...scanInsights.scanContext,
                  rulesSpec,
                  engines: scanInsights.scanContext.engines.map(
                    (engine) => enginesToDisplayName[engine]
                  ),
                },
                newIssuesCount: issuesEffect.newIssuesCount,
              }
            }
          )

          const { pagination } = scanInsightsState.scanInsights.data

          return {
            scanInsights,
            isLoading,
            pagination,
          }
        }
      )
    },
  }),
}
