/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AssetPageTab } from '@spectral/types'
import useQueryStringState from '../../../hooks/useQueryStringState'
import { Dispatch, select } from '../../../redux/store'
import SecretSprawlView from '../../../blocks/asset-page/sprawl/secrets-sprawl-view/view'
import { SubPage } from '../../../components/tab/sub-page'

export const secretsSprawlListInitialParams = {
  tab: AssetPageTab.SecretsSprawl,
  page: 1,
}

export default ({ asset }) => {
  const dispatch: Dispatch = useDispatch()
  const { assetId } = useParams()
  const [
    currentParamsValues,
    saveParamValueInQueryString,
  ] = useQueryStringState(['expandedSecret', 'page', 'tab'])
  const { expandedSecret, page } = currentParamsValues
  const { secrets, status } = useSelector(select.AssetPage.secretsSprawl)
  const userCanSeeAllAssets = useSelector(select.Users.canSeeAllAssets)
  const { isRefreshing, loaded } = status

  useEffect(() => {
    saveParamValueInQueryString(secretsSprawlListInitialParams, true, true)
    dispatch.AssetPage.fetchSecretsSprawl({ assetId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch.AssetPage, assetId])

  useEffect(() => {
    return dispatch.AssetPage.resetFilters
  }, [])

  const onPageChanged = (newPage) => {
    saveParamValueInQueryString({ page: newPage }, false, true)
  }

  const onChangeSecretExpansion = (updatedExpandedSecret) => {
    saveParamValueInQueryString({ expandedSecret: updatedExpandedSecret }, true)
  }
  return (
    <SubPage isLoading={!loaded} name="Secret Sprawl" sx={{ height: '100%' }}>
      <SecretSprawlView
        asset={asset}
        secrets={secrets}
        loaded={loaded}
        isRefreshing={isRefreshing}
        expandedSecret={expandedSecret}
        onChangeSecretExpansion={onChangeSecretExpansion}
        onPageChanged={onPageChanged}
        currentPage={page}
        userCanSeeAllAssets={userCanSeeAllAssets}
      />
    </SubPage>
  )
}
