/** @jsx jsx */

import { Box, Flex, jsx, Text } from 'theme-ui'
import { AuditLink, Mitigate } from '../mode-buttons'
import { ReactComponent as AuditSVG } from '../../../assets/svg/audit.svg'
import { FeatureHeading } from '../../../components/headings/feature'

import { WizardMode } from '../types'

const selected = {
  border: '1px solid #fff',
  boxShadow: 'tileFocused',
}
const tile = {
  p: '20px',
  pt: '30px',
  my: '10px',
  borderRadius: '4px',
  cursor: 'pointer',
  background: 'white',
  border: 'outline',
  ':hover': selected,
}
const Modes = ({ onModeSelect, username, showModes, tracker = null }) => {
  const onPublicConnectClick = () => {
    tracker.githubAppPublicScan()
    window.open(
      `https://github.com/apps/${
        process.env.REACT_APP_GITHUB_APP_NAME
      }/installations/new?state=${encodeURIComponent(username)}`,
      '_blank',
      'noopener,noreferrer'
    )
  }
  return (
    <Box>
      <Box sx={{ textAlign: 'center', mb: '80px' }}>
        <Text variant="wizardheading">Spectral scan setup</Text>
        <Text variant="wizardsub">
          Spectral scans your code for secrets, misconfigurations and
          vulnerabilities.
        </Text>
        <Text variant="wizardsub">
          To start scanning and viewing results, choose your setup:
        </Text>
      </Box>
      <Flex sx={{ justifyContent: 'center' }}>
        <Box>
          {showModes.some((mode) => mode === WizardMode.githubPublic) && (
            <Box sx={tile} onClick={onPublicConnectClick}>
              <FeatureHeading
                title="Public Repositories"
                subtitle="Scan public repositories (Github Account needed)"
                image={
                  <AuditSVG
                    sx={{ ml: '7px', mr: '6px', mt: '-12px', width: '60px' }}
                  />
                }
                tags={['public']}
              />
            </Box>
          )}
          {showModes.some((mode) => mode === WizardMode.mitigate) && (
            <Box
              data-e2e-test="mitigate-heading"
              sx={tile}
              onClick={() => onModeSelect(WizardMode.mitigate)}
            >
              <Mitigate />
            </Box>
          )}
          {showModes.some((mode) => mode === WizardMode.audit) && (
            <Box onClick={() => onModeSelect(WizardMode.audit)}>
              <AuditLink />
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  )
}
export default Modes
