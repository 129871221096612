import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { useCallback } from 'react'
import {
  EXPORT_FILENAME_DATE_FORMAT,
  ISSUES_REPORT_FILENAME,
} from '../utils/constants'

const contentTypePerFormat = {
  csv: 'text/csv',
  pdf: 'application/pdf',
}

const useExportToFile = (trackingCallback, exportCallback, output, filters) => {
  const exportIssuedToFile = useCallback(async () => {
    trackingCallback()
    const response = await exportCallback({
      output,
      ...filters,
    })
    const blob = new Blob([response], { type: contentTypePerFormat[output] })
    saveAs(
      blob,
      `${ISSUES_REPORT_FILENAME}_${format(
        new Date(),
        EXPORT_FILENAME_DATE_FORMAT
      )}.${output}`
    )
  }, [exportCallback, output, trackingCallback])

  return [exportIssuedToFile]
}

export default useExportToFile
