export enum Engines {
  Spectral = 'spectral',
  Secrets = 'secrets',
  IaC = 'iac',
  OpenSource = 'oss',
}

export const enginesToDisplayName = {
  secrets: 'Secrets',
  spectral: 'Spectral',
  iac: 'Iac',
  oss: 'Open source',
}

export const DEFAULT_ENGINE = Engines.Secrets
