import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from 'theme-ui'
import { Page } from '../../components/page'
import { SlackIntegrationConnected } from './integrations/slack/slack-integration-connected'
import { JiraIntegrationConnected } from './integrations/jira/jira-integration-connected'
import { CustomWebhookIntegrationConnected } from './integrations/custom-webhook/custom-webhook-integration-connected'
import { PagerDutyIntegrationConnected } from './integrations/pager-duty/pager-duty-integration-connected'
import { MondayIntegrationConnected } from './integrations/monday/monday-integration-connected'
import { MsTeamsIntegrationConnected } from './integrations/ms-teams/ms-teams-integration-connected'
import { EventsWebhookIntegrationConnected } from './integrations/events-webhook/events-webhook-integration-connected'

export default () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch.Teams.fetchSettings()
    dispatch.Teams.fetchCurrentTeam()
  }, [dispatch.Teams])
  return (
    <Page name="SETTINGS_INTEGRATION" title="Integration Settings">
      <Box sx={{ padding: '40px' }}>
        <SlackIntegrationConnected />
        <MsTeamsIntegrationConnected />
        <JiraIntegrationConnected />
        <CustomWebhookIntegrationConnected />
        <EventsWebhookIntegrationConnected />
        <PagerDutyIntegrationConnected />
        <MondayIntegrationConnected />
      </Box>
    </Page>
  )
}
