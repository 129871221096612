import { FileTextOutlined } from '@ant-design/icons'
import { AssetPageTab, AssetStats, removeFilePathSlash } from '@spectral/types'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import gt from 'lodash/gt'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Flex, Text } from 'theme-ui'
import styled from 'styled-components'
import { paths } from '../../routes'
import theme from '../../common/theme'
import assetPageTracker from '../../common/track/asset-page'
import assetsPageTracker from '../../common/track/assets-page'

const IacNavLink = styled(NavLink)`
  padding: 0 4px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${theme.stylebook.colors.primaryScale['50']};
  }
`

const AssetDetails = ({ asset: { id, iacFiles, stats } }) => {
  const encodedAssetId = encodeURIComponent(id)
  return (
    <Box>
      {!isEmpty(iacFiles) && <Text mb={2}>Top IaC issues sources:</Text>}
      {map(iacFiles, ({ path, count }) => (
        <IacNavLink
          key={path}
          onClick={assetPageTracker.iacFileClicked}
          to={`${paths.closed.asset(encodedAssetId)}?tab=${
            AssetPageTab.Iac
          }&expandedFile=${encodeURIComponent(path)}`}
        >
          <FileTextOutlined style={{ marginRight: '2px' }} />
          <Text sx={{ fontSize: '14px', flex: 1 }} variant="ellipsis">
            {removeFilePathSlash(path)}
          </Text>
          <Text sx={{ fontSize: '12px' }}>{count} Issues</Text>
        </IacNavLink>
      ))}
      <Flex sx={{ mt: 3 }}>
        {!isEmpty(iacFiles) && (
          <NavLink
            to={`${paths.closed.asset(encodedAssetId)}?tab=${AssetPageTab.Iac}`}
            style={{ marginRight: '36px' }}
            onClick={() => assetsPageTracker.seeIssuesClicked(AssetPageTab.Iac)}
          >
            See all IaC issues {`(${stats.iac})`}
          </NavLink>
        )}
        {gt(stats.secrets, 0) && (
          <NavLink
            to={`${paths.closed.asset(encodedAssetId)}?tab=${
              AssetPageTab.Secrets
            }`}
            style={{ marginRight: '36px' }}
            onClick={() =>
              assetsPageTracker.seeIssuesClicked(AssetPageTab.Secrets)
            }
            className="e2e-test-asset-page-secrets-link"
          >
            See exposed secrets {`(${stats.secrets})`}
          </NavLink>
        )}
        {gt(stats.discover, 0) && (
          <NavLink
            to={`${paths.closed.asset(encodedAssetId)}?tab=${
              AssetPageTab.DiscoverIssues
            }`}
            onClick={() =>
              assetsPageTracker.seeIssuesClicked(AssetPageTab.DiscoverIssues)
            }
          >
            See exposed CI/CD hardening issues {`(${stats.discover})`}
          </NavLink>
        )}
        {gt(stats[AssetStats.OpenSource], 0) && (
          <NavLink
            to={`${paths.closed.asset(encodedAssetId)}?tab=${
              AssetPageTab.OpenSource
            }`}
            onClick={() =>
              assetsPageTracker.seeIssuesClicked(AssetPageTab.OpenSource)
            }
          >
            See all open source issues {`(${stats[AssetStats.OpenSource]})`}
          </NavLink>
        )}
      </Flex>
    </Box>
  )
}

export default AssetDetails
