/** @jsx jsx */
import { Button, Dropdown, Menu } from 'antd'
import {
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineFileProtect,
  AiOutlineCloudDownload,
} from 'react-icons/ai'
import { Box, Flex, jsx, Text } from 'theme-ui'
import SortSelector from '../../components/filters/sort-selector'

const ActionsMenu = ({ actions }) => {
  return (
    <Menu>
      {actions.csv?.active && (
        <Menu.Item
          key="csv"
          style={{ padding: '20px' }}
          onClick={actions.csv.onExport}
        >
          <Flex>
            <Box sx={{ width: '25px', textAlign: 'left' }}>
              <AiOutlineFileExcel size={20} />
            </Box>

            <Text sx={{ fontSize: '14px' }}>Export CSV</Text>
          </Flex>
        </Menu.Item>
      )}
      {actions.pdf?.active && (
        <Menu.Item
          key="pdf"
          style={{ padding: '20px' }}
          onClick={actions.pdf.onExport}
        >
          <Flex>
            <Box sx={{ width: '25px', textAlign: 'left' }}>
              <AiOutlineFilePdf size={20} />
            </Box>
            <Text sx={{ fontSize: '14px' }}>Export PDF</Text>
          </Flex>
        </Menu.Item>
      )}
      {actions.ignores?.active && (
        <Menu.Item
          key="export_ignore"
          style={{ padding: '20px' }}
          onClick={actions.ignores?.onExport}
        >
          <Flex>
            <Box sx={{ width: '25px', textAlign: 'left' }}>
              <AiOutlineFileProtect size={20} />
            </Box>
            <Text sx={{ fontSize: '14px' }}>Export Ignores</Text>
          </Flex>
        </Menu.Item>
      )}
    </Menu>
  )
}

const ActionsMenuButton = ({
  actions,
  isDownloadingExport,
  className = undefined,
  currentQueryStringParams,
  setQueryStringParam,
  trackSort,
  sortOptions,
}) => {
  return (
    <Flex className={className} sx={{ gap: 2 }}>
      <SortSelector
        sortBy={currentQueryStringParams.sortBy}
        sortDirection={currentQueryStringParams.sortDirection}
        ifRefreshing={false}
        options={sortOptions}
        setSort={setQueryStringParam}
        trackSort={trackSort}
      />
      <Dropdown
        overlay={ActionsMenu({ actions })}
        overlayStyle={{ zIndex: 3000 }}
      >
        <Button
          loading={isDownloadingExport}
          type="ghost"
          size="middle"
          sx={{ width: '62px' }}
        >
          {!isDownloadingExport && <AiOutlineCloudDownload size={30} />}
        </Button>
      </Dropdown>
    </Flex>
  )
}

export default ActionsMenuButton
