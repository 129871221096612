import { ModelConfig } from '@rematch/core'
import omit from 'lodash/omit'
import { sdkClient } from '../../store'
import { authPersistence } from '../../persistence'
import track from '../../../common/track'

export type signupState = {
  error: string | null
  successMessage: string | null
}

export type SignupPayload = {
  fullName: string
  email: string
  password: string
  company: string
  invite?: string
  recaptcha: string
}

const initialState: signupState = {
  error: null,
  successMessage: null,
}

export const signup: ModelConfig<signupState> = {
  state: initialState,
  reducers: {
    setSignupError(state: signupState, error: string): signupState {
      return { ...state, error }
    },
    setSuccessMessage(state: signupState, successMessage: string): signupState {
      return { ...state, successMessage }
    },
    resetSignupMessage(state: signupState): signupState {
      return { ...state, successMessage: null }
    },
    reset(_state: signupState): signupState {
      return initialState
    },
  },
  effects: (dispatch: any) => ({
    async signup({
      fullName,
      email,
      password,
      company,
      invite,
      recaptcha,
    }: SignupPayload) {
      const res: any = await sdkClient.auth().signup({
        data: { fullName, email, password, team: company, recaptcha },
        ...(invite && { query: { invite } }),
      })
      if (res.user) {
        const { user } = res
        dispatch.Auth.setUser(user)
        track.event('SIGNUP_COMPLETED', { email, company })
        track.addOrUpdateUser(user, company, true)
        dispatch.Teams.fetchCurrentTeam()
        dispatch.Auth.fetchSettings()
        authPersistence.persist(omit(user, ['username', 'token']))
      }
      if (res.successMessage) {
        dispatch.Signup.setSuccessMessage(res.successMessage)
      }
    },
  }),
}
