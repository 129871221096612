import { Alert, Switch, Tooltip } from 'antd'
import React, { useState } from 'react'
import { Flex, Text } from 'theme-ui'

const PersonalTokenAlert = ({ onSwitch }) => {
  const [isTeamTokenShown, setIsTeamTokenShown] = useState(false)
  const handleSwitchTokenShown = (event) => {
    setIsTeamTokenShown(!isTeamTokenShown)
    onSwitch(event)
  }
  return (
    <Alert
      message="Team token (SPK-****) will be deprecated"
      description={
        <Flex
          sx={{
            gap: 3,
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Text>
            Please use your personal token in your DSN and scanning agents.
          </Text>
        </Flex>
      }
      action={
        <Flex sx={{ gap: 3, alignItems: 'center' }}>
          Toggle team token:
          <Tooltip
            title={`Using ${isTeamTokenShown ? 'team' : 'personal'} token`}
          >
            <Switch
              checked={isTeamTokenShown}
              onChange={handleSwitchTokenShown}
            />
          </Tooltip>
        </Flex>
      }
      type="warning"
      showIcon
    />
  )
}

export default PersonalTokenAlert
