import React, { useEffect, useState } from 'react'
import { castArray } from 'lodash'
import { Box } from 'theme-ui'
import size from 'lodash/size'
import { FilterHeader } from './filter-header'
import FilterCollapse from './filter-collapse'

export const DEFAULT_MAX_ITEMS_TO_DISPLAY = 3

export enum SideFilterType {
  Checkbox = 'checkbox',
  Date = 'date',
}

type FilterMetadata = {
  key: string
  title: string
  component: any
  isActive: boolean
  type: SideFilterType
  defaultValue?: string
  data?: any
  tooltip?: string
  collapsible: boolean
}

type Props = {
  filtersValues: any
  isDataRefreshing: boolean
  filtersMetadata: Array<FilterMetadata>
  marginTop?: number
  filterActions: any
}

const SideFilters = ({
  filtersValues,
  isDataRefreshing,
  filtersMetadata,
  filterActions,
}: Props) => {
  const {
    onExpandFilter,
    onCollapseFilter,
    onCheckboxFilterCheck,
    onCheckboxFiltersClear,
    onDateFilterChanged,
    currentCountItemsToDisplay,
  } = filterActions
  const [filtersSelectedKeys, setFiltersSelectedKeys] = useState({})
  useEffect(() => {
    setFiltersSelectedKeys({
      ...Object.entries(filtersValues).reduce((acc, [key, val]) => {
        if (val) {
          acc[key] = castArray(val)
        }
        return acc
      }, {}),
    })
  }, [setFiltersSelectedKeys, filtersValues])

  const onFilterCheck = (filterKey) => (checkedKeysList) => {
    setFiltersSelectedKeys({
      ...filtersSelectedKeys,
      [filterKey]: checkedKeysList,
    })
    onCheckboxFilterCheck(filterKey)(checkedKeysList)
  }

  const onClearClicked = (filterKey) => {
    setFiltersSelectedKeys({ ...filtersSelectedKeys, [filterKey]: [] })
    onCheckboxFiltersClear([filterKey])
  }

  const RenderCheckboxFilter = ({ filter }) => {
    const { component: Component, key, data, shouldShowAllValues } = filter
    const displayItemsCount = shouldShowAllValues
      ? size(data)
      : currentCountItemsToDisplay[key] || DEFAULT_MAX_ITEMS_TO_DISPLAY
    return (
      <Box key={`filter_${key}`}>
        <Component
          isRefreshing={isDataRefreshing}
          checkedKeys={filtersSelectedKeys[key] || []}
          data={data}
          onCheck={onFilterCheck(key)}
          onClear={onClearClicked}
          onExpandFilter={(itemsToDisplay) =>
            onExpandFilter(key, itemsToDisplay)
          }
          onCollapseFilter={() => onCollapseFilter(key)}
          currentCountItemsToDisplay={displayItemsCount}
        />
      </Box>
    )
  }

  const RenderDateFilter = ({ filter }) => {
    const { component: Component, key } = filter
    return (
      <Box key={`filter_${key}`}>
        <Component
          startDate={filtersValues[`${key}Start`]}
          endDate={filtersValues[`${key}End`]}
          isRefreshing={isDataRefreshing}
          onChange={onDateFilterChanged(key)}
        />
      </Box>
    )
  }

  const RenderFilterByType = ({ filter }) => {
    switch (filter.type) {
      case SideFilterType.Date:
        return <RenderDateFilter filter={filter} />
      case SideFilterType.Checkbox:
        return <RenderCheckboxFilter filter={filter} />
      default:
        throw new Error('invalid filter type')
    }
  }

  return (
    <Box>
      {filtersMetadata.map(
        (filter, index) =>
          filter.isActive && (
            <FilterCollapse
              collapsible={filter.collapsible}
              header={
                <FilterHeader
                  title={filter.title}
                  options={filter.data}
                  checkedKeys={filtersSelectedKeys[filter.key] || []}
                  tooltip={filter.tooltip}
                  onClearClicked={(e) => {
                    e.stopPropagation()
                    onClearClicked(filter.key)
                  }}
                />
              }
              filterKey={filter.key}
              key={filter.key}
              isDividerShown={index !== filtersMetadata.length - 1}
            >
              <RenderFilterByType key={filter.key} filter={filter} />
            </FilterCollapse>
          )
      )}
    </Box>
  )
}

export default SideFilters
