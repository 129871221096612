import { auditLocal } from './code/audit-local'
import { codebuild } from './code/codebuild'
import { auditGithub } from './code/audit-github'
import { auditGitlab } from './code/audit-gitlab'
import { gitlabci } from './code/gitlabci'
import { travisCI } from './code/travis-ci'
import { jenkins } from './code/jenkins'
import { circleCI } from './code/circle-ci'
import { githubActions } from './code/github-actions'
import { bitbucketPipeline } from './code/bitbucket-pipeline'
import { azuredevops } from './code/azuredevops'
import { gcpbuild } from './code/gcpbuild'
import { jfrogPipline } from './code/jfrog-pipline'
import { otherCI } from './code/other-ci'
import { tfc } from './code/tfc'
import { download } from './download'
import { jira } from './productivity/jira'
import { githubNative } from './productivity/github-native'
import { SourceIntegrationKey } from './enums'
import { confluence } from './productivity/confluence'
import { gitlabBot } from './productivity/gitlab-bot'
import { discoverGithub } from './discover/github'
import { discoverGitlab } from './discover/gitlab'
import { WizardMode } from '../../blocks/sources-wizard/types'

const sourcesConfig = {
  // audit
  [SourceIntegrationKey.AuditGithub]: auditGithub,
  [SourceIntegrationKey.AuditGitlab]: auditGitlab,
  [SourceIntegrationKey.AuditLocal]: auditLocal,
  // mitigate
  [SourceIntegrationKey.TravisCi]: travisCI,
  [SourceIntegrationKey.CircleCi]: circleCI,
  [SourceIntegrationKey.Jenkins]: jenkins,
  [SourceIntegrationKey.Codebuild]: codebuild,
  [SourceIntegrationKey.GithubActions]: githubActions,
  [SourceIntegrationKey.GitlabCi]: gitlabci,
  [SourceIntegrationKey.BitbucketPipeline]: bitbucketPipeline,
  [SourceIntegrationKey.Azuredevops]: azuredevops,
  [SourceIntegrationKey.Gcpbuild]: gcpbuild,
  [SourceIntegrationKey.JfrogPipline]: jfrogPipline,
  [SourceIntegrationKey.OtherCi]: otherCI,
  [SourceIntegrationKey.TerraformCloud]: tfc,
  // productivity
  [SourceIntegrationKey.Jira]: jira,
  [SourceIntegrationKey.Confluence]: confluence,
  [SourceIntegrationKey.GithubNative]: githubNative,
  [SourceIntegrationKey.GitlabBot]: gitlabBot,
  // Discover
  [SourceIntegrationKey.GithubDiscover]: discoverGithub,
  [SourceIntegrationKey.GitlabDiscover]: discoverGitlab,
}

const getIntegration = (operatingSystem, integrationId) => {
  if (
    sourcesConfig[integrationId] &&
    [WizardMode.audit, WizardMode.discover].includes(
      sourcesConfig[integrationId].mode
    )
  ) {
    return {
      ...sourcesConfig[integrationId],
      data: { ...sourcesConfig[integrationId][operatingSystem] },
    }
  }
  if (sourcesConfig[integrationId]) {
    return { ...sourcesConfig[integrationId] }
  }
  return false
}

const getDownloadSnippet = (operatingSystem) => download[operatingSystem]

const downloadSnippetByMode = (mode, operatingSystem) => {
  return [WizardMode.audit, WizardMode.discover].includes(mode)
    ? getDownloadSnippet(operatingSystem)
    : null
}

export { getIntegration, downloadSnippetByMode }
