/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import { useEffect } from 'react'
import { jsx, Box, Flex, Text } from 'theme-ui'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import track from '../../common/track'
import { Loading } from '../loading'

const ErrorContainer = ({ isError, children }) =>
  isError ? (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center', my: '200px' }}>
      <Box>
        <Text variant="smallheading">:-(</Text>
      </Box>
      <Box>
        <Text variant="smallheading">Sorry, something went wrong</Text>
      </Box>
      <Box sx={{ mt: '50px' }}>
        <a onClick={() => window.location.reload()}>Try this again?</a>
      </Box>
    </Flex>
  ) : (
    children
  )

export const TitleChevron = () => <FaChevronRight size={14} color="#a8a8a8" />
export const PageTitle = ({ children }) => (
  <Text variant="smallheading">{children}</Text>
)
export const Page = ({
  className = null,
  name,
  title,
  referringSource = null,
  children = null,
  isLoading = false,
  isError = null,
  style = null,
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch.error.reset()
    track.page(name, title, location, referringSource)
  }, [location, name, title, referringSource, dispatch])

  return (
    <Box className={className} style={{ ...style }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ErrorContainer isError={isError}>
        {isLoading && <Loading />}
        {!isLoading && children}
      </ErrorContainer>
    </Box>
  )
}
