/** @jsx jsx */
import L from 'lodash'
import { jsx, Box, Text } from 'theme-ui'
import { Form, Select } from 'antd'
import { LinkToGlobalIntegrations } from '../org-teams'
import { JiraProject } from '../../../jira-create-issue-modal/jira-project'

const selectJiraProject = ({
  team,
  projects,
  isLoading = false,
  error = null,
}) => {
  return (
    <Box>
      <Form.Item name="jiraProject" sx={{ flexGrow: 1 }}>
        {projects && (
          <Select
            placeholder="Assign jira project to org team"
            style={{ width: 300 }}
            disabled={
              !projects ||
              (projects && projects.length === 0) ||
              (!team.jiraProjectId && (isLoading || error))
            }
          >
            {projects &&
              projects.length > 0 &&
              projects.map((project) => (
                <Select.Option
                  value={project.id}
                  pid={project.id}
                  key={project.id}
                >
                  <JiraProject
                    label={project.name}
                    avatar={project.avatarUrls['16x16']}
                  />
                </Select.Option>
              ))}
            <Select.Option
              value={null}
              pid={null}
              key="select_team_jira_project_null"
            >
              None
            </Select.Option>
          </Select>
        )}
      </Form.Item>
    </Box>
  )
}

export const OrgTeamJiraIntegration = ({
  projects,
  currentJiraProjectId,
  domain,
}) => {
  return (
    <Box key="jira_wrapper">
      {domain && !L.isEmpty(projects) ? (
        <Box>
          <Box sx={{ flexGrow: 1 }}>Jira Project</Box>
          {selectJiraProject({
            team: { jiraProjectId: currentJiraProjectId },
            projects,
          })}
        </Box>
      ) : (
        <Box>
          {!domain ? (
            <Text>
              Jira integration is not set up. Go to{' '}
              <LinkToGlobalIntegrations>
                Integrations page
              </LinkToGlobalIntegrations>
            </Text>
          ) : (
            <Text>
              Jira integration failed. Go to{' '}
              <LinkToGlobalIntegrations>
                Integrations page
              </LinkToGlobalIntegrations>
            </Text>
          )}
        </Box>
      )}
    </Box>
  )
}
