/** @jsx jsx */
import { Box, Flex, Heading, jsx } from 'theme-ui'

const IntegrationWrapper = ({
  title,
  imgSrc,
  extraOnRight = null,
  children,
}) => {
  return (
    <Box
      sx={{
        mt: '50px',
        p: '40px',
        boxShadow: 'tileFocused',
        background: '#fff',
        borderRadius: '4px',
        zIndex: 10,
      }}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
          mb: '30px',
        }}
      >
        {imgSrc && (
          <Box sx={{ mr: '10px' }}>
            <img
              sx={{ height: '40px', width: '40px' }}
              src={imgSrc}
              alt={title}
            />
          </Box>
        )}
        <Heading sx={{ flex: 1 }} as="h3" variant="smalltitle">
          {title}
        </Heading>
        {extraOnRight && extraOnRight}
      </Flex>
      {children}
    </Box>
  )
}
export default IntegrationWrapper
