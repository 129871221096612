import { Modal } from 'antd'
import React from 'react'
import { Flex, Text } from 'theme-ui'

const ActivateDraftCustomRulesVersionComfirmationModal = ({
  visible,
  isLoading,
  onOk,
  onCancel,
}) => {
  return (
    <Modal
      confirmLoading={isLoading}
      visible={visible}
      okText="Yes"
      cancelText="No"
      okType="primary"
      title="Activate new version"
      onOk={onOk}
      onCancel={onCancel}
      maskClosable
    >
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text>
          The new custom rules version will be applied on all future scans of
          your organization.
        </Text>
        <Text>Are you sure you want to activate?</Text>
      </Flex>
    </Modal>
  )
}

export default ActivateDraftCustomRulesVersionComfirmationModal
