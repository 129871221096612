import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import { JiraIntegrations } from '../../../../blocks/settings/integrations/jira/jira'

export const JiraIntegrationConnected = () => {
  const { jiraSettings } = useSelector(select.Teams.teamSettings)
  const { JiraSettingsError } = useSelector(select.Teams.settingsPageStatus)
  const { isActivated } = jiraSettings
  const dispatch = useDispatch()
  return (
    <JiraIntegrations
      isActivated={isActivated}
      setJiraEnableDisable={dispatch.Teams.setJiraIntegrationStatus}
      setJiraSettings={dispatch.Teams.setJiraSettings}
      domain={jiraSettings.domain}
      integratorEmail={jiraSettings.integratorEmail}
      integratorApiToken={jiraSettings.integratorApiToken}
      JiraSettingsError={JiraSettingsError}
    />
  )
}
