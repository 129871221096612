import { AssetDisplayStatus, AssetStats, AssetType } from '@spectral/types'
import Icon from '@ant-design/icons/lib/components/Icon'
import { capitalize } from 'lodash'
import React from 'react'
import { Box, Flex, Link, Text } from 'theme-ui'
import { Divider, Tag, Tooltip } from 'antd'
import { TeamOutlined } from '@ant-design/icons'
import { IoCubeOutline } from 'react-icons/io5'
import theme from '../../common/theme'
import Cube from '../../components/cube/cube'
import { SeverityCountTag } from '../../components'
import AssetScanDate from '../../components/asset-page/asset-scan-date/asset-scan-date'
import AssetIcon from '../../components/asset-icon/AssetIcon'
import assetPageTracker from '../../common/track/asset-page'
import AssetGitVisibility from '../../components/asset-git-visibility/AssetVisibility'
import { CopyText } from '../../components/buttons/copy-text'

const AssetHeader = ({
  id,
  displayName,
  kind,
  source,
  uri,
  displayStatus,
  isNotActive,
  lastScanDate,
  orgTeam,
  stats,
}) => {
  let statusDescription

  if (displayStatus === AssetDisplayStatus.NoIssues) {
    statusDescription = 'No issues'
  } else {
    const totalIssues =
      stats[AssetStats.Critical] +
      stats[AssetStats.High] +
      stats[AssetStats.Medium] +
      stats[AssetStats.Low] +
      stats[AssetStats.Informational]
    statusDescription = `${totalIssues} issue${
      totalIssues > 1 ? 's' : ''
    } found`
  }
  if (kind === AssetType.Git) {
    statusDescription += ' (across all branches)'
  }

  const orderedStats = {
    [AssetStats.Critical]: stats[AssetStats.Critical],
    [AssetStats.High]: stats[AssetStats.High],
    [AssetStats.Medium]: stats[AssetStats.Medium],
    [AssetStats.Low]: stats[AssetStats.Low],
    [AssetStats.Informational]: stats[AssetStats.Informational],
    [AssetStats.Ignore]: stats.ignore,
  }

  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        columnGap: 5,
      }}
    >
      <Box mr={2} sx={{ maxWidth: '60%', flex: '0 1 auto' }}>
        <Flex sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Tooltip title={statusDescription}>
            <Box mr={2}>
              <Cube
                view={
                  displayStatus === AssetDisplayStatus.NotActive
                    ? AssetDisplayStatus.NoIssues
                    : displayStatus
                }
                size={24}
              />
            </Box>
          </Tooltip>
          {uri && (
            <Link
              href={uri}
              target="_blank"
              rel="noopener noreferrer"
              onClick={assetPageTracker.assetTitleClicked}
              sx={{
                wordBreak: 'break-word',
                color: theme.stylebook.colors.text,
              }}
            >
              <Text variant="smallheading">{displayName}</Text>
            </Link>
          )}
          {!uri && (
            <Text
              sx={{
                wordBreak: 'break-word',
                color: theme.stylebook.colors.text,
                fontSize: '24px',
              }}
            >
              {displayName}
            </Text>
          )}
          <Text sx={{ fontSize: '12px', paddingLeft: 1, lineHeight: '1' }}>
            <AssetScanDate
              isNotActive={isNotActive}
              lastScanDate={lastScanDate}
            />
          </Text>
        </Flex>
        <Flex sx={{ gap: 2 }}>
          <Flex sx={{ alignItems: 'center', gap: 2 }}>
            <AssetIcon kind={kind} source={source} />
            <Text sx={{ fontSize: '14px' }}>{capitalize(source)}</Text>
          </Flex>
          <Box>
            <Divider
              type="vertical"
              style={{ borderColor: theme.stylebook.colors.controlBorder }}
            />
          </Box>
          <Flex
            sx={{
              alignItems: 'center',
            }}
          >
            {[AssetType.Automation].includes(kind) === false && (
              <Tooltip title="Visibility">
                <Box>
                  <AssetGitVisibility isPublic={false} />
                </Box>
              </Tooltip>
            )}
            <Tooltip title="Team">
              <Box>
                <Tag icon={<TeamOutlined />}>{orgTeam}</Tag>
              </Box>
            </Tooltip>
            <Tooltip title={<Box>{id}</Box>}>
              <Box>
                <CopyText
                  value={id}
                  text="Copy Asset ID"
                  icon={<Icon component={IoCubeOutline} />}
                />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
      </Box>
      <Box sx={{ alignItems: 'flex-end' }}>
        {![AssetDisplayStatus.NoIssues, AssetDisplayStatus.NotActive].includes(
          displayStatus
        ) && (
          <Box>
            <Text sx={{ color: 'gray.500', fontSize: 1 }}>
              Issues breakdown
            </Text>
            <Flex>
              {Object.entries(orderedStats).map(
                ([severityKey, severityCount]) =>
                  severityCount > 0 && (
                    <Box mr={1} key={`summary_sev_${severityKey}`}>
                      <SeverityCountTag
                        sx={{
                          mx: '2px',
                        }}
                        kind={severityKey}
                        count={severityCount}
                        countOnly
                      />
                    </Box>
                  )
              )}
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default AssetHeader
