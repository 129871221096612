import {
  AssetDetails,
  AssetDetailsSortBy,
  SortDirection,
} from '@spectral/types'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import { IssueStatus } from '@spectral/types/issue'
import { RootState, sdkClient } from '../../../store'
import { empty, loaded } from '../../../utils'

const initialState = {
  filtersData: empty({
    orgTeams: [],
    sources: [],
    category: [],
    severity: [],
    detectors: [],
    tags: [],
    issuesTypes: [],
  }),
  assetsList: empty({
    data: [],
    stats: { total: 0 },
  }),
}

export const assetsPage = {
  state: initialState,
  reducers: {
    setAssetsList(state: any, assetsData: any) {
      return {
        ...state,
        filtersData: loaded({
          ...state.filtersData.data,
          ...assetsData.filtersData,
        }),
        assetsList: loaded({ data: assetsData.data, stats: assetsData.stats }),
      }
    },
    resetAssets(state: any) {
      return {
        ...state,
        assetsList: loaded({
          data: initialState.assetsList.data.data,
          stats: state.assetsList.data.stats,
        }),
      }
    },
    resetFilters(state: any) {
      return {
        ...state,
        filtersData: initialState.filtersData,
      }
    },
    setAssetsDetails(state: any, assetsDetailsData: AssetDetails) {
      const assetsDetailsIacDataMap = keyBy(
        assetsDetailsData.iac.iacFilesPaths,
        'assetId'
      )
      const updatedAssetsList = map(state.assetsList.data.data, (asset) => {
        const assetDetailsIac = assetsDetailsIacDataMap[asset.id]
        return {
          ...asset,
          iacFiles: assetDetailsIac?.iacFiles,
        }
      })

      return {
        ...state,
        assetsList: {
          ...state.assetsList,
          data: {
            ...state.assetsList.data,
            data: updatedAssetsList,
          },
        },
      }
    },
  },
  effects: (dispatch: any) => ({
    async fetchAssets(payload) {
      const assetsList = (await sdkClient
        .assets()
        .assetsList({ params: payload })) as {
        data: Array<AssetDetails>
      }
      dispatch.AssetsPage.setAssetsList(assetsList)

      dispatch.AssetsPage.fetchAssetsDetails()
    },
    async fetchAssetsDetails(_payload, rootState: RootState) {
      const assetIds = map(rootState.AssetsPage.assetsList.data.data, 'id')
      if (!isEmpty(assetIds)) {
        const assetsDetails = (await sdkClient.assets().assetsDetails({
          data: {
            assetIds,
            status: IssueStatus.Active,
            pageSize: 10,
            page: 1,
            sortBy: AssetDetailsSortBy.COUNT,
            sortDirection: SortDirection.DESC,
          },
        })) as {
          data: Array<any>
        }

        dispatch.AssetsPage.setAssetsDetails(assetsDetails.data)
      }
    },
  }),
  selectors: (slice, createSelector, _hasProps) => ({
    assetsPage() {
      return createSelector(
        // @ts-ignore
        (rootState: RootState) => rootState.AssetsPage,
        (rootState: RootState, _props) => rootState,
        (assetsState, rootState) => {
          const allAssets = assetsState.assetsList.data.data || []
          const filtersData = assetsState.filtersData.data
          const stats = assetsState.assetsList.data.stats || {}
          return {
            data: allAssets,
            filtersData,
            stats,
            status: {
              loaded: assetsState.assetsList.fetchStatus === 'loaded',
              isFiltersDataLoaded:
                assetsState.filtersData.fetchStatus === 'loaded',
              pageError:
                assetsState.assetsList.fetchStatus === 'none' &&
                rootState.error.effects.AssetsPage.fetchAssets !== null,
              isRefreshing:
                rootState.loading.effects.AssetsPage.fetchAssets > 0,
              isDetailsLoaded:
                rootState.loading.effects.AssetsPage.fetchAssetsDetails < 1,
            },
          }
        }
      )
    },
  }),
}
