import React, { useState } from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Box, Flex } from 'theme-ui'
import IssueCollapseContent from './issue-collapse-content'

const IssueCollapse = ({ detectorDescription, issue }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onMoreInfoClick = () => {
    setIsExpanded(!isExpanded)
  }

  if (
    !detectorDescription &&
    !issue.validation &&
    !issue.fingerprint &&
    !issue.ignore?.isIgnored
  )
    return null

  return (
    <Box
      sx={{
        mr: '24px',
        width: '100%',
      }}
    >
      <Flex
        onClick={onMoreInfoClick}
        sx={{ color: 'gray.500', cursor: 'pointer', fontSize: '14px' }}
      >
        <Box>{isExpanded ? 'Show less info' : 'Show more info'} </Box>
        <Box sx={{ fontSize: '10px', mt: '4px', ml: '5px' }}>
          {isExpanded ? <UpOutlined /> : <DownOutlined />}
        </Box>
      </Flex>
      <IssueCollapseContent
        detectorDescription={detectorDescription}
        isExpanded={isExpanded}
        fingerprint={issue.fingerprint}
        ignoreDetails={issue.ignore}
        validation={issue.validation}
      />
    </Box>
  )
}

export default IssueCollapse
