import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import { isArray } from 'lodash'
import AdditionalActionButton from './additional-action-button'

export const FilterHeader = ({
  title,
  tooltip = null,
  onClearClicked = null,
  checkedKeys = null,
  options = null,
}) => {
  return (
    <Flex
      className="filter-title"
      sx={{
        flex: 1,
        gap: 2,
        pr: 2,
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          fontSize: '12px',
          color: 'gray.600',
        }}
      >
        {options && isArray(options) ? (
          <Tooltip
            title={options
              .filter((option) => checkedKeys.includes(option.key))
              .map((option) => option.title)
              .join(', ')}
          >
            {title} {checkedKeys.length > 0 && `(${checkedKeys.length})`}
          </Tooltip>
        ) : (
          <Box>{title}</Box>
        )}
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleOutlined
              style={{ fontSize: '12px', marginLeft: '3px' }}
            />
          </Tooltip>
        )}
      </Box>
      {onClearClicked && checkedKeys.length > 0 && (
        <AdditionalActionButton text="Reset" onClick={onClearClicked} />
      )}
    </Flex>
  )
}
