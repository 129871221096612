/** @jsx jsx */
import { AssetType } from '@spectral/types'
import { jsx } from 'theme-ui'
import AssetAutomationSourceIcon from '../asset-automation-source-icon/AssetAutomationSourceIcon'
import AssetGitSourceIcon from '../asset-git-source-icon/AssetGitSourceIcon'
import AssetTypeIcon from '../asset-type-icon/AssetTypeIcon'

const AssetIcon = ({ kind, source }) => {
  if (kind === AssetType.Automation) {
    return <AssetAutomationSourceIcon source={source} />
  }
  if (kind === AssetType.Git) {
    return <AssetGitSourceIcon source={source} />
  }
  return (
    <AssetTypeIcon
      width="20px"
      height="20px"
      minHeight="20px"
      minWidth="20px"
      type={kind}
    />
  )
}
export default AssetIcon
