/** @jsx jsx */
import { Alert, Button } from 'antd'
import { jsx, Box, Flex } from 'theme-ui'

export enum BannerType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

const Banner = ({
  type,
  messageText,
  actionButtonText,
  actioButtonCallback,
  dismissButtonText,
  dismissButtonCallback,
  icon,
}: {
  type: BannerType
  messageText: any
  actionButtonText: string
  actioButtonCallback: () => void
  dismissButtonText: string
  dismissButtonCallback: () => void
  icon?: any
}) => {
  return (
    <Alert
      message={
        <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <Box
            sx={{
              padding: '7px 0px',
            }}
          >
            {messageText}
          </Box>
          <Box sx={{ ml: 3 }}>
            <Button size="small" type="primary" onClick={actioButtonCallback}>
              {actionButtonText}
            </Button>
          </Box>
        </Flex>
      }
      icon={icon}
      type={type}
      banner
      action={
        <Box>
          <Button size="small" type="ghost" onClick={dismissButtonCallback}>
            {dismissButtonText}
          </Button>
        </Box>
      }
    />
  )
}

export default Banner
