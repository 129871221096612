/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import Header from './Header'
import Sidebar from './Sidebar'
import { paths } from '../../routes'
import ctaMap from '../../common/cta-map'

const Layout = ({
  children,
  onRenderAlerts,
  header: { userbar },
  location,
  sidebar: { sidebarCollapseToggle, isCollapsed },
}) => {
  return (
    <Box sx={{ height: '100%' }}>
      <Sidebar
        collapsed={isCollapsed}
        onCollapseClick={sidebarCollapseToggle}
        links={paths.closed}
        location={location}
      />
      <Box
        sx={{
          marginLeft: isCollapsed ? '80px' : '200px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header userbar={userbar} location={location} ctaMap={ctaMap} />
        {onRenderAlerts()}
        <Box
          sx={{
            flex: 1,
            height: 0,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default Layout
