/** @jsx jsx */
import { Image, jsx } from 'theme-ui'
import { AutomationSource } from '@spectral/types'
import L from 'lodash'
import { Tooltip } from 'antd'
import tfcIcon from '../../assets/png/tfc.png'

const ASSET_SOURCE_TO_ICON = {
  [AutomationSource.TerraformCloud]: tfcIcon,
  [AutomationSource.TerraformEnterprise]: tfcIcon,
}

const DEFAULT_ICON_SIZE = '20px'

const AssetAutomationSourceIcon = ({
  source,
  width = DEFAULT_ICON_SIZE,
  height = DEFAULT_ICON_SIZE,
  minWidth = DEFAULT_ICON_SIZE,
  minHeight = DEFAULT_ICON_SIZE,
}) => {
  return (
    <Tooltip title={L.capitalize(source)}>
      <Image
        sx={{
          width,
          height,
          minWidth,
          minHeight,
          mr: '2px',
        }}
        src={ASSET_SOURCE_TO_ICON[source]}
      />
    </Tooltip>
  )
}

export default AssetAutomationSourceIcon
