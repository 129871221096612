import { OptInFeatures, PlanType } from '@spectral/types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Text } from 'theme-ui'
import tracker from '../../../common/track'
import CustomRules from '../../../blocks/settings/custom-rules/custom-rules'
import { Page } from '../../../components/page'
import { select } from '../../../redux/store'
import RestrictedArea from '../../restricted-area/restricted-area'

const CustomRulesConnected = () => {
  const dispatch = useDispatch()
  const versions = useSelector(select.CustomRules.versions)
  const currentTeam = useSelector(select.Teams.current)
  const reviewError = useSelector(select.CustomRules.reviewError)
  const isFetchingVersions = useSelector(select.CustomRules.isFetchingVersions)
  const isSubmittingReview = useSelector(select.CustomRules.isSubmittingReview)
  const isDeactivatingVersion = useSelector(
    select.CustomRules.isDeactivatingVersion
  )

  const onReview = async (newStatus, comment) => {
    await dispatch.CustomRules.reviewDraftVersion({ comment, newStatus })
    tracker.customRulesVersionReviewed(newStatus)
  }

  const onDeactivate = useCallback(() => {
    return dispatch.CustomRules.deactivateVersion()
  }, [dispatch.CustomRules])

  const activeVersion = versions?.active
  const draftVersion = versions?.draft

  const { key: teamKey, loginType } = currentTeam
  const user = useSelector((state) => state.Auth.user)

  return (
    <Page
      style={{ minWidth: '1200px' }}
      name="SETTINGS_CUSTOM_RULES"
      title="Custom Rules"
      isLoading={isFetchingVersions}
    >
      <RestrictedArea
        minimumPlan={PlanType.Team}
        featureDisplayName="Centralized custom rules"
        optInFeature={OptInFeatures.CustomRules}
        optInMessage={
          <>
            <Box>
              This feature allows security professionals to propogate custom
              rules to the entire organiztaion.
            </Box>
            <Box sx={{ mt: '15px' }}>
              <strong>NOTE:</strong> Please make sure your rules are not
              exposing sensitive data by not targeting a secret directly or
              using a very simple regex such as{' '}
              <Text as="span" variant="badExample">
                danny[0-9]
              </Text>{' '}
              or{' '}
              <Text as="span" variant="badExample">
                [A-Za-z]danny
              </Text>
            </Box>
          </>
        }
      >
        <CustomRules
          reviewError={reviewError}
          draftVersion={draftVersion?.rules}
          activeVersion={activeVersion?.rules}
          approver={activeVersion?.reviewedBy}
          approveDate={new Date(activeVersion?.updatedAt)}
          onReview={onReview}
          onDeactivate={onDeactivate}
          isSubmittingReview={isSubmittingReview}
          isDeactivatingVersion={isDeactivatingVersion}
          teamKey={teamKey}
          userApiKey={user.apiKey}
          loginType={loginType}
        />
      </RestrictedArea>
    </Page>
  )
}

export default CustomRulesConnected
