import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import { first } from 'lodash'
import React from 'react'
import { Image } from 'theme-ui'
import size from 'lodash/size'
import jiraLogo from '../../assets/svg/jira.svg'
import mondayLogo from '../../assets/svg/monday.svg'
import JiraCreateIssueModal from '../jira-create-issue-modal'
import MondayCreateItemModal from '../../containers/monday-create-item'
import ButtonWithTooltip from '../../components/buttons/button-with-tooltip'

const IssueActionIntegrationsView = ({
  onSubmit,
  selectedIssues,
  setJiraModalVisibility,
  isCreateJiraIssueModalVisible,
  isCreateMondayItemModalVisible,
  setMondayModalVisibility,
  integrations,
}) => {
  const isSingleIssueSelected = size(selectedIssues) === 1
  const doesAnyIssuesSelected = size(selectedIssues) > 0

  const isJiraIntegrationActivated = integrations.jira?.isActivated
  // @ts-ignore
  const isSelectedIssueWithJiraIssue = first(selectedIssues)?.jira?.issueKey
  let jiraDisabledTooltip = ''
  if (!isSingleIssueSelected) {
    jiraDisabledTooltip = 'Choose a single issue'
  } else if (isSelectedIssueWithJiraIssue) {
    jiraDisabledTooltip = 'Selected issue already linked'
  }

  const isMondayIntegrationEnabled = integrations.monday?.enabled
  // @ts-ignore
  const isSelectedIssueWithMondayIssue = first(selectedIssues)?.monday
    ?.mondayItemId

  let mondayDisabledTooltip = ''
  if (!isSingleIssueSelected) {
    mondayDisabledTooltip = 'Choose a single issue'
  } else if (isSelectedIssueWithMondayIssue) {
    mondayDisabledTooltip = 'Selected issue already linked'
  }

  const isAnyIntegrationEnabled =
    isJiraIntegrationActivated || isMondayIntegrationEnabled
  const isActionMenuPresented = isAnyIntegrationEnabled
  const isActionDropdownEnabled = doesAnyIssuesSelected
  const isJiraActionPresented = isJiraIntegrationActivated
  const isCreateJiraIssueEnabled =
    isJiraIntegrationActivated &&
    isSingleIssueSelected &&
    !isSelectedIssueWithJiraIssue
  const isMondayActionPresented = isMondayIntegrationEnabled
  const isCreateMondayIssueEnabled =
    isMondayIntegrationEnabled &&
    isSingleIssueSelected &&
    !isSelectedIssueWithMondayIssue

  if (!isActionMenuPresented) {
    return null
  }

  return (
    <>
      <Dropdown
        disabled={!isActionDropdownEnabled}
        overlay={
          <Menu>
            {isJiraActionPresented && (
              <Menu.Item key="1">
                <ButtonWithTooltip
                  tooltip={jiraDisabledTooltip}
                  onActionClick={() => setJiraModalVisibility(true)}
                  disabled={!isCreateJiraIssueEnabled}
                  icon={
                    <Image
                      sx={{ width: '16px', height: '16px', mr: 2 }}
                      src={jiraLogo}
                    />
                  }
                  text="Create Jira issue"
                  type="link"
                />
              </Menu.Item>
            )}
            {isMondayActionPresented && (
              <Menu.Item key="2">
                <ButtonWithTooltip
                  tooltip={mondayDisabledTooltip}
                  onActionClick={() => setMondayModalVisibility(true)}
                  disabled={!isCreateMondayIssueEnabled}
                  icon={
                    <Image
                      sx={{ width: '16px', height: '16px', mr: 2 }}
                      src={mondayLogo}
                    />
                  }
                  text="Create Monday Item"
                  type="link"
                />
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <Button>
          Actions
          <DownOutlined />
        </Button>
      </Dropdown>

      {isCreateJiraIssueModalVisible && (
        <JiraCreateIssueModal
          issue={first(selectedIssues)}
          onClose={() => setJiraModalVisibility(false)}
          onSubmit={onSubmit}
        />
      )}
      {isCreateMondayItemModalVisible && (
        <MondayCreateItemModal
          issue={first(selectedIssues)}
          onClose={() => {
            setMondayModalVisibility(false)
          }}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}

export default IssueActionIntegrationsView
