import { Box, Text } from 'theme-ui'
import { Link } from 'react-router-dom'
import React from 'react'
import * as Yup from 'yup'
import {
  ButtonField,
  CustomFormComponent,
  InputPasswordFieldSection,
  PanelForm,
  SubmitSection,
} from '../../../../components/panels/content'
import pagerDutyLogo from '../../../../assets/svg/pd.svg'
import { IntegrationSection } from '../../../../components/integrations/integration-sections'
import testPagerDutyKey from './test-connection'
import PagerDutyIntegrationInstructions from './instructions'
import { pagerDutyRoutingServiceKeyRegex } from './const'

export const PagerDutyIntegrations = ({
  enabled,
  togglePagerDuty,
  setPagerDutySettings,
  routingServiceKey,
  isTestInProgress,
  onTestClicked,
}) => {
  return (
    <IntegrationSection
      enabled={enabled}
      onEnableToggle={({ pager_duty_integrations_checkbox }) =>
        togglePagerDuty(pager_duty_integrations_checkbox)
      }
      integrationName="pager_duty"
      headerLogo={pagerDutyLogo}
      headerTitle="Pager Duty Global Integration"
      sectionTitle=""
      sectionDescription="Use PagerDuty for aggregating and alert about incidents"
      sectionRenderedContent={() => (
        <Box sx={{ mt: '20px' }}>
          <Text>
            This settings will direct all system notification to the configured
            service
          </Text>
          <Text>
            For settings a specific service for a team, go to{' '}
            <Link to="/settings/org-teams">Teams</Link>
          </Text>
          <PagerDutyIntegrationInstructions />
        </Box>
      )}
    >
      <PanelForm
        onSubmit={setPagerDutySettings}
        initialValues={{ routingServiceKey }}
        validationSchema={Yup.object({
          routingServiceKey: Yup.string().matches(
            pagerDutyRoutingServiceKeyRegex,
            'Must be a valid routing service key (32 chars, 0-9, a-f)'
          ),
        })}
      >
        <InputPasswordFieldSection
          name="routingServiceKey"
          label="Routing service key"
          description="Paste your Routing service key here"
          placeholder="PagerDuty Routing service key"
          required={false}
          merge
        />
        <CustomFormComponent>
          {(
            _setFieldValue,
            _setFieldTouched,
            _validateForm,
            values,
            isValid
          ) => (
            <ButtonField
              disabled={!isValid}
              loading={isTestInProgress}
              description="We'll send a dummy issue to your PagerDuty service"
              onClick={async () => {
                const key = values.routingServiceKey
                await testPagerDutyKey(onTestClicked, key)
              }}
              name="test"
              label="Test"
            />
          )}
        </CustomFormComponent>
        <SubmitSection text="Update" />
      </PanelForm>
    </IntegrationSection>
  )
}
