import { notification } from 'antd'

export const showErrorNotification = (
  message = 'Failed',
  description = 'An error has occured'
) => {
  notification.error({
    message,
    description,
  })
}
