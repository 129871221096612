/** @jsx jsx */
import { LoadingOutlined } from '@ant-design/icons'
import { jsx } from 'theme-ui'
import { Button, Tooltip } from 'antd'

const ButtonWithTooltip = ({
  onActionClick,
  icon,
  isLoading = false,
  tooltip = null,
  shape = null,
  text = null,
  type = null,
  disabled = false,
}) => {
  return (
    <Tooltip title={tooltip}>
      <Button
        onClick={onActionClick}
        type={type}
        shape={shape}
        icon={isLoading ? <LoadingOutlined /> : icon}
        disabled={disabled}
      >
        {text}
      </Button>
    </Tooltip>
  )
}

export default ButtonWithTooltip
