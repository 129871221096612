/* eslint-disable no-underscore-dangle */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import styled from 'styled-components'
import { Button } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { CopyButton } from '../buttons/copy'
import Terminal from '../terminal/terminal'

const PreCustomScrollbar = styled.pre`
  padding: 1.3rem 0.6rem;
  margin-bottom: 0;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #5e21b7;
    border-radius: 2px;
  }
`

const TerminalStyled = styled(Terminal)`
  &:hover {
    > button {
      opacity: 1;
    }
  }

  button {
    opacity: 0;
  }
`
type Props = {
  htmlCodeSnippet: { __html: string }
  codeCopy?: string
  onCopy?: Function
  onRevealClick?: Function
  isSecretIncluded?: boolean
  isSecretShown?: boolean
  setSecretShown?: Function
}

export const SnippetBox = ({
  htmlCodeSnippet,
  codeCopy = null,
  onCopy = null,
  onRevealClick = null,
  isSecretIncluded = false,
  isSecretShown = false,
}: Props) => {
  return (
    <TerminalStyled
      sx={{
        width: '100%',
        position: 'relative',
        minHeight: 'auto',
        boxShadow: '0 8px 6px -7px rgba(88, 26, 196, 0.35)',
      }}
      padding="2rem 1.4rem 0"
      boxShadow="false"
    >
      <PreCustomScrollbar dangerouslySetInnerHTML={htmlCodeSnippet} />
      <CopyButton
        value={codeCopy || htmlCodeSnippet.__html}
        onCopy={onCopy}
        buttonStyles={{ position: 'absolute', top: '4px', right: '4px' }}
      />
      {isSecretIncluded && (
        <Button
          sx={{
            backgroundColor: 'white',
            zIndex: 2,
            position: 'absolute',
            top: '4px',
            right: '50px',
            ':hover': {
              backgroundColor: 'white',
            },
            ':focus': {
              backgroundColor: 'white',
            },
          }}
          type="ghost"
          size="middle"
          htmlType="button"
          onClick={() => onRevealClick()}
          icon={
            isSecretShown ? (
              <EyeOutlined size={26} />
            ) : (
              <EyeInvisibleOutlined size={26} />
            )
          }
        />
      )}
    </TerminalStyled>
  )
}
