/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEffect, useState } from 'react'
import InProgressModal from './in-progress'
import SucceededModal from './succeeded-modal'
import FailedModal from './failed-modal'

const VALIDATE_USER_STATE_INTERVAL_MILLISECONDS = 2000
const LOADING_TIMEOUT_IN_MILLISECONDS = 30000

const PostGithubAppInstallModal = ({
  refreshUserState,
  setQuery,
  fetchAssetsSummary,
  githubAppScanSucceeded,
  postGithubAppInstall,
  disposeModal,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchInterval = setInterval(
      refreshUserState,
      VALIDATE_USER_STATE_INTERVAL_MILLISECONDS
    )
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false)
      clearInterval(fetchInterval)
      setQuery({}, true)
    }, LOADING_TIMEOUT_IN_MILLISECONDS)
    if (githubAppScanSucceeded) {
      clearInterval(fetchInterval)
      clearTimeout(loadingTimeout)
      fetchAssetsSummary()
    }
    return () => {
      clearInterval(fetchInterval)
      clearTimeout(loadingTimeout)
    }
  }, [refreshUserState, setQuery, githubAppScanSucceeded, fetchAssetsSummary])

  if (githubAppScanSucceeded) {
    return <SucceededModal postGithubAppInstall={postGithubAppInstall} />
  }
  if (isLoading) {
    return <InProgressModal />
  }
  return (
    <FailedModal
      disposeModal={disposeModal}
      postGithubAppInstall={postGithubAppInstall}
    />
  )
}

export default PostGithubAppInstallModal
