import { CheckOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import React from 'react'

export const envVarsTableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Required',
    dataIndex: 'required',
    align: 'center' as const,
    render: (value) => (value === true ? <CheckOutlined /> : ''),
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
]

export default ({ envVarsData }) => (
  <Table
    size="small"
    dataSource={envVarsData}
    columns={envVarsTableColumns}
    pagination={false}
  />
)
