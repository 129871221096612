import { AssetDisplayStatus } from '@spectral/types'
import { Collapse, Empty, Pagination } from 'antd'
import find from 'lodash/find'
import React from 'react'
import styled from 'styled-components'
import { Box, Flex } from 'theme-ui'
import isEmpty from 'lodash/isEmpty'
import theme from '../../common/theme'
import { Loading } from '../../components/loading'
import AssetDetails from './asset-details'
import AssetHeader from './asset-header'

const { Panel } = Collapse
export const assetDisplayStatusColorsMap = {
  [AssetDisplayStatus.Critical]: {
    color: theme.stylebook.colors.severities.criticalHEX,
    tooltip: 'There are critical severity issues in this asset',
  },
  [AssetDisplayStatus.High]: {
    color: theme.stylebook.colors.severities.highHEX,
    tooltip: 'There are high severity issues in this asset',
  },
  [AssetDisplayStatus.Medium]: {
    color: theme.stylebook.colors.severities.mediumHEX,
    tooltip: 'There are medium severity issues in this asset',
  },
  [AssetDisplayStatus.Low]: {
    color: theme.stylebook.colors.severities.lowHEX,
    tooltip: 'There are low severity issues in this asset',
  },
  [AssetDisplayStatus.Informational]: {
    color: theme.stylebook.colors.severities.informationalHEX,
    tooltip: 'There are informational severity issues in this asset',
  },
  [AssetDisplayStatus.NoIssues]: {
    color: theme.stylebook.colors.severities.noneHEX,
    tooltip: 'No issues found in this asset recently',
  },
  [AssetDisplayStatus.NotActive]: {
    color: theme.stylebook.colors.severities.noneHEX,
    tooltip: "This asset has no issues but hasn't been scanned recently",
  },
}

const AssetsCollapse = styled(Collapse)`
  .ant-collapse-item {
    background-color: transparent;
    transition: background-color 0.25s ease-in-out;
  }

  .ant-collapse-item-active {
    background-color: ${theme.stylebook.colors.gray['100']};
    transition: background-color 0.25s ease-in-out;
  }

  &&&&& {
    flex: 1;
    height: 0;
    overflow-y: auto;
    padding-right: 40px;
  }
`

const AssetPanel = styled(Panel)`
  .ant-collapse-header {
    border-left: 4px solid
      ${({ assetStatus }) => assetDisplayStatusColorsMap[assetStatus].color};
  }
`

const AssetsPage = ({
  isRefreshing,
  data,
  isDetailsLoaded,
  onAssetClicked,
  pagination: { currentPage, onPaginationChange, totalItems, pageSize },
}) => {
  return (
    <Flex sx={{ flexDirection: 'column', height: '100%' }}>
      {isRefreshing && (
        <Box sx={{ height: '100%' }}>
          <Loading text="Loading assets..." />
        </Box>
      )}
      {isEmpty(data) && !isRefreshing && (
        <Box sx={{ mt: '50px' }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No assets found</span>}
          />
        </Box>
      )}
      {!isRefreshing && (
        <AssetsCollapse
          accordion
          bordered={false}
          onChange={(assetId) => {
            if (assetId) {
              const asset = find(data, ({ id }) => id === assetId)
              onAssetClicked(asset)
            }
          }}
        >
          {data.map((asset) => (
            <AssetPanel
              header={<AssetHeader asset={asset} />}
              assetStatus={asset.displayStatus}
              key={asset.id}
              showArrow={false}
              className="e2e-test-assets-page-asset-row"
            >
              {isDetailsLoaded ? <AssetDetails asset={asset} /> : <Loading />}
            </AssetPanel>
          ))}
        </AssetsCollapse>
      )}
      <Box
        sx={{
          mt: 3,
          textAlign: 'right',
          paddingRight: '40px',
          paddingBottom: '20px',
        }}
      >
        <Pagination
          showTotal={(total) => `Total: ${total} assets`}
          current={currentPage}
          onChange={onPaginationChange}
          total={totalItems}
          pageSize={pageSize}
          showSizeChanger={false}
          disabled={isRefreshing}
        />
      </Box>
    </Flex>
  )
}

export default AssetsPage
