/** @jsx jsx */
import { jsx, Flex, Box, Text } from 'theme-ui'
import { Card } from 'antd'
import SourceTile from './sources-tile'
import { DataSources } from '../../containers/Sources'

type Props = {
  dataSources: DataSources
  handleClickSource: Function
}

const RenderSources = ({ sources, category, handleClickSource }) => {
  return (
    <Flex sx={{ gap: 3, flexWrap: 'wrap' }}>
      {sources.map(
        ({
          key,
          displayName,
          hidden,
          handleClickSource: handleCustomBehaviorClick,
        }) =>
          !hidden && (
            <SourceTile
              key={key}
              tileKey={key}
              title={displayName}
              onClick={
                handleCustomBehaviorClick ||
                (() => handleClickSource(category, key))
              }
            />
          )
      )}
    </Flex>
  )
}

const View = ({ dataSources, handleClickSource }: Props) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        gap: 4,
      }}
    >
      {Object.values(dataSources).map(
        (sourceCategory) =>
          !sourceCategory.hidden && (
            <Card key={sourceCategory.key}>
              <Text variant="title">{sourceCategory.displayName}</Text>
              {sourceCategory.description && (
                <Text sx={{ mb: 3 }}>{sourceCategory.description}</Text>
              )}
              {sourceCategory.sources?.length > 0 && (
                <Box sx={{ my: '20px' }}>
                  <RenderSources
                    sources={sourceCategory.sources}
                    category={sourceCategory.key}
                    handleClickSource={handleClickSource}
                  />
                </Box>
              )}
              {sourceCategory.subCategories?.length > 0 &&
                sourceCategory.subCategories
                  .filter((subCat) => !subCat.hidden)
                  .map((subCategory) => (
                    <Box sx={{ my: '20px' }} key={subCategory.key}>
                      <Text variant="smalltitle">
                        {subCategory.displayName}
                      </Text>
                      {subCategory.description && (
                        <Text sx={{ mb: 3 }}>{subCategory.description}</Text>
                      )}
                      {subCategory.sources?.length > 0 && (
                        <RenderSources
                          sources={subCategory.sources}
                          category={sourceCategory.key}
                          handleClickSource={handleClickSource}
                        />
                      )}
                    </Box>
                  ))}
            </Card>
          )
      )}
    </Flex>
  )
}

export default View
