/** @jsx jsx */
import { Table as ATable } from 'antd'
import { jsx, Box, Text } from 'theme-ui'

export const PanelTable = (props) => (
  <ATable
    sx={{
      '.ant-table': {
        borderRadius: '4px',
        border: 'contentPanel',
        boxShadow: 'contentPanel',
      },
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
)
export const AssetColumn = ({ assetName, assetSource }) => {
  return (
    <Box>
      <Text>{assetName}</Text>
      <Text color="gray.400">{assetSource}</Text>
    </Box>
  )
}
