import { useEffect } from 'react'
import { SampleIssue } from '../url-formats-settings'
import ScmUrlFormat from '@spectral/types/url-formats/scm-url-format'

function useTemplateRender(
  format: string,
  issue: SampleIssue,
  setRendered: Function
) {
  useEffect(() => {
    try {
      const scmUrlFormat = new ScmUrlFormat(format)
      setRendered(scmUrlFormat.buildUrlFromIssue(issue))
    } catch (e) {
      setRendered(format)
    }
  }, [format, issue, setRendered])
}

export default useTemplateRender
