import { digestInfo } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const azuredevops = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'Azure DevOps Pipeline',
    content: 'Integrate as a test or script step',
    tileKey: 'azuredevops',
    snippets: [
      {
        title:
          'Store your SPECTRAL_DSN in <a href="https://docs.microsoft.com/en-us/azure/devops/pipelines/library/variable-groups?view=azure-devops&tabs=yaml" target="_blank" rel="noopener noreferrer">Azure Pipeline Variables</a>',
        code: `{dsn}`,
        type: 'yaml',
      },
      {
        title: "Here's an example run of Spectral on Azure DevOps:",
        code: `trigger:
- main
pool:
  vmImage: 'ubuntu-latest'
  
steps:
- checkout: self
  clean: true
  persistCredentials: true

- script: |
    git fetch --all
    git switch \`basename $(Build.SourceBranch)\`

- script: curl -L '{baseUrl}/latest/x/sh?dsn=$(SPECTRAL_DSN)' | sh
  displayName: 'Install Spectral'
- script: $HOME/.spectral/spectral scan --ok --dsn $(SPECTRAL_DSN) {options}
  displayName: 'Spectral Scan'
`,
        type: 'yaml',
        postDescription: digestInfo,
      },
    ],
  },
}
