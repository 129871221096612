import { Box, Flex, Text } from 'theme-ui'
import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

type Props = {
  data: Array<{
    label: string
    value: string
    color?: string
    tooltip?: string
    isShown?: boolean
  }>
}

const DetailsList = ({ data }: Props) => (
  <Box
    sx={{
      '&>*:not(:last-child)': {
        borderBottom: 'outline',
      },
    }}
  >
    <Flex
      sx={{
        height: '100px',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {data.map(
        ({ label, value, color, tooltip, isShown = true }) =>
          isShown && (
            <Flex
              sx={{ flexDirection: 'column', alignItems: 'center' }}
              key={label}
            >
              <Text sx={{ fontSize: '18px', textAlign: 'center' }}>
                {value}
              </Text>
              <Text
                sx={{
                  fontSize: '12px',
                  textAlign: 'center',
                  color: color || 'gray.400',
                  fontWeight: '400',
                  width: '100px',
                }}
              >
                {label}
                {tooltip && (
                  <Tooltip title={tooltip}>
                    &nbsp;
                    <QuestionCircleOutlined />
                  </Tooltip>
                )}
              </Text>
            </Flex>
          )
      )}
    </Flex>
  </Box>
)

export default DetailsList
