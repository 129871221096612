/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../redux/store'

import { OrgTeamPagerDutyIntegration } from '../../../blocks/settings/org-teams/integrations/pager-duty'

export const OrgTeamPagerDutyIntegrationConnected = ({ form }) => {
  const dispatch = useDispatch()
  const testRoutingServiceToken = dispatch.Settings.testPagerDuty
  const { isTestInProgress } = useSelector(select.Settings.pagerDutySettings)
  return (
    <OrgTeamPagerDutyIntegration
      testRoutingServiceToken={testRoutingServiceToken}
      isTestInProgress={isTestInProgress}
      form={form}
    />
  )
}
