/** @jsx jsx */
import { RoleType } from '@spectral/types'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Box, Flex, jsx } from 'theme-ui'
import Restricted from '../../containers/role-based-render/restricted'
import { BaseSeverityTag } from '../../components/tags/severity'
import SeverityChangeDropdown from '../severity-change-dropdown'

const DetectorSeverity = ({
  detectorName,
  detectorId,
  displaySeverity,
  originalSeverity,
  onSeverityChanged,
}) => {
  return (
    <Restricted
      roles={[RoleType.Owner, RoleType.Admin]}
      fallback={<Box sx={{ mr: 1 }}>
        <BaseSeverityTag kind={displaySeverity} emphasized />
      </Box>
      }
    >
      <Box
        mr={2}
        sx={{
          minWidth: originalSeverity !== displaySeverity ? '156px' : '130px',
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Box sx={{ flex: 1, mr: 1 }}>
            <SeverityChangeDropdown
              detectorName={detectorName}
              detectorId={detectorId}
              currentSeverity={displaySeverity}
              onSeverityChanged={onSeverityChanged}
            />
          </Box>
          {originalSeverity !== displaySeverity && (
            <Tooltip
              sx={{ mr: '4px' }}
              title={
                <Box>
                  Modified severity. Original severity is{' '}
                  <strong>{originalSeverity}</strong>.
                </Box>
              }
            >
              <InfoCircleOutlined style={{ fontSize: '14px' }} />
            </Tooltip>
          )}
        </Flex>
      </Box>
    </Restricted>
  )
}

export default DetectorSeverity
