import React from 'react'
import Icon from '@ant-design/icons'
import {
  FiSettings,
  FiHelpCircle,
  FiArrowLeftCircle,
  FiArrowRightCircle,
  FiBookOpen,
} from 'react-icons/fi'

const SettingsIcon = <Icon component={FiSettings} />
const SupportIcon = <Icon component={FiHelpCircle} />
const CollapseIcon = <Icon component={FiArrowLeftCircle} />
const ExpandIcon = <Icon component={FiArrowRightCircle} />
const DocsIcon = <Icon component={FiBookOpen} />

export { SettingsIcon, DocsIcon, CollapseIcon, ExpandIcon, SupportIcon }
