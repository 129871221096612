/** @jsx jsx */
import { Modal } from 'antd'
import { jsx, Box } from 'theme-ui'

const RestrictedLayout = ({
  modalContainerId = 'modal-page-container',
  renderContent,
  children,
  style = null,
}) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        id={modalContainerId}
        sx={{
          filter: 'blur(3px)',
          pointerEvents: 'none',
          height: '100vh',
          overflowY: 'hidden',
          ...style,
        }}
      >
        {children}
      </Box>
      <Modal
        getContainer={`#${modalContainerId}`}
        visible
        closable={false}
        keyboard={false}
        maskClosable={false}
        footer={null}
        width={1000}
        bodyStyle={{ display: 'none' }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '200px',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '20',
          maxWidth: '500px',
        }}
      >
        {renderContent()}
      </Box>
    </Box>
  )
}

export default RestrictedLayout
