/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui'
import getSeverityConfig from './severity-config'

export const BaseSeverityTag = ({ kind, emphasized = false }) => {
  const severityConfig = getSeverityConfig(kind)
  return (
    <Flex
      sx={{
        border: '1px solid',
        borderRadius: '0px 4px 4px 0px',
        borderColor: 'transparent',
        backgroundColor: severityConfig.weakColor,
        color: severityConfig.strongColor,
        padding: '1px 0',
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: '12px',
        fontFamily: 'inter',
        height: '24px',
      }}
    >
      {emphasized && (
        <Box
          sx={{
            backgroundColor: severityConfig.strongColor,
            padding: '2px 2px',
            borderRadius: '4px 0 0 4px',
            height: '100%',
          }}
        />
      )}
      <Flex
        sx={{
          width: '115px',
          alignItems: 'center',
          flex: 1,
          justifyContent: 'center',
        }}
      >
        {kind.toUpperCase()}
      </Flex>
    </Flex>
  )
}
