export const features = {
  quickscan:
    process.env.NODE_ENV !== 'production' ||
    process.env.FEATURES_ENV !== 'production',
  preloadChat: process.env.FEATURES_ENV === 'demo',
  publicScanAvailable: !['production', 'cloudguard'].includes(
    process.env.FEATURES_ENV
  ),
  logIssuesPage:
    process.env.FEATURES_ENV !== 'demo' &&
    process.env.FEATURES_ENV !== 'production',
  forceLoadingInIframe: process.env.FEATURES_ENV === 'cloudguard',
  isRestrictionSettingsMode: process.env.FEATURES_ENV === 'cloudguard',
}
