import React from 'react'
import moment from 'moment'
import DateSideFilter from '../../../components/filters/date-side-filter'

const ScannedDateFilter = ({ isRefreshing, onChange, startDate, endDate }) => {
  return (
    <DateSideFilter
      disabled={isRefreshing}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      datesToDisableCallback={(date) => date && date > moment()}
      defaultPickerValue={[moment().add(-1, 'month'), undefined]}
      allowEmpty={[true, true]}
    />
  )
}

export default ScannedDateFilter
