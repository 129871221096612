/* eslint-disable @typescript-eslint/no-use-before-define */
/** @jsx jsx */
import { useEffect, useReducer } from 'react'
import { jsx } from 'theme-ui'
import { Modal } from 'antd'
import { createGlobalStyle } from 'styled-components'
import { LoginType } from '@spectral/types'
import SelectDataSource from './steps/select-data-source'
import SnippetWindow from '../source-integration/snippet-window'
import { IWizardTracking } from './utils/tracking'
import Source from '../../common/sources/sources.types'
import Wizard from './view'
import { wizardReducer, initialState } from './reducer'
import { WizardMode, ActionType } from './types'
import { getDownloadSnippet } from '../../common/sources/sources'
import useDSN from '../../hooks/useDSN'

const ModalBlurGlobalStyle = createGlobalStyle`
  #root {
    filter: blur(3px);
  }
`

type Props = {
  teamKey: string
  tracker: IWizardTracking
  teamPid: string
  teamName: string
  loginType: LoginType
  userApiKey: string
  sources: Source
  linkExternal: Function
  digestLinks: { darwin: string; linux: string; win: string }
  location: any
}

const overrideInitialState = {
  ...initialState,
  mode: WizardMode.mitigate,
}
const WizardCiOnly = ({
  teamKey,
  tracker,
  teamPid,
  teamName,
  userApiKey,
  loginType,
  sources,
  linkExternal,
  digestLinks,
  location,
}: Props) => {
  const [state, dispatch] = useReducer(wizardReducer, overrideInitialState)
  const { mode, dataSource, currentStep, operatingSystem } = state

  useEffect(() => {
    dispatch({
      type: ActionType.RESET,
      payload: { overrideInitialState },
    })
  }, [location])

  const { dsn, scanKey, setIsTeamTokenShown } = useDSN(
    teamKey,
    userApiKey,
    loginType
  )

  const onBack = () => {
    tracker?.back({ fromStep: steps[currentStep].trackingName })
    if (currentStep === 1) {
      dispatch({
        type: ActionType.SET_DATA_SOURCE,
        payload: { dataSource: { title: null } },
      })
    }
    dispatch({ type: ActionType.DECREASE_STEP })
  }

  const forward = () => {
    tracker?.progress({ fromStep: steps[currentStep].trackingName })
    dispatch({ type: ActionType.INCREASE_STEP })
  }

  const dataSourceSelected = (selectedDataSource) => {
    tracker?.dataSourceSelected({ mode, dataSource: selectedDataSource })
    if (selectedDataSource.externalLink)
      linkExternal(selectedDataSource.externalLink)
    else {
      dispatch({
        type: ActionType.SET_DATA_SOURCE,
        payload: { dataSource: selectedDataSource },
      })
      forward()
    }
  }

  const steps = [
    {
      title: 'Select your CI System',
      trackingName: 'Data Source Selection',
      component: SelectDataSource,
      props: {
        onDataSourceSelect: dataSourceSelected,
        dataSource,
        mode,
        operatingSystem,
        sources,
      },
    },
    {
      title: dataSource?.title ? `Scan with ${dataSource.title}` : 'Scan',
      trackingName: 'Snippet Window',
      component: SnippetWindow,
      props: {
        scanKey,
        dsn,
        mode,
        isAudit: false,
        operatingSystem,
        setOperatingSystem: () =>
          dispatch({ type: ActionType.CHANGE_OPERATING_SYSTEM }),
        downloadSnippet: getDownloadSnippet(operatingSystem),
        dataSource,
        onCopy: tracker?.snippetCopied.bind(tracker),
        showHelpButton: true,
        digestLinks,
        shouldShowTeamToken: loginType === LoginType.Team,
        onSwitchTokenShown: setIsTeamTokenShown,
      },
    },
  ]

  return (
    <Modal
      visible
      closable={false}
      keyboard={false}
      maskClosable={false}
      footer={null}
      width={1000}
      mask={false}
    >
      <ModalBlurGlobalStyle />
      <Wizard
        tracker={tracker}
        steps={steps}
        onBack={onBack}
        currentStep={currentStep}
        teamPid={teamPid}
        teamName={teamName}
      />
    </Modal>
  )
}
export default WizardCiOnly
