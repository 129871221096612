import React from 'react'
import L from 'lodash'
import * as Yup from 'yup'
import { Text } from 'theme-ui'
import {
  FieldSectionLayout,
  InputFieldSection,
  InputNumberFieldSection,
  SelectFieldSection,
  MultiSelectFieldSection,
} from '../../components/panels/content'

const genericPlaceHolder = 'required'
const genericRequiredWarning = genericPlaceHolder

const ArrayOptionsField = ({ field }) => {
  const options = L.map(field.allowedValues, (allowedValue) => ({
    // see https://github.com/ant-design/ant-design/issues/5670
    value: JSON.stringify({ id: allowedValue.id }),
    name: allowedValue.value,
    label: `${allowedValue.value}`,
    key: `array_options_field_${allowedValue.value}`,
  }))
  return (
    <MultiSelectFieldSection
      name={field.key}
      label={field.name}
      layout={FieldSectionLayout.Vertical}
      description=""
      options={options}
      placeholder={genericPlaceHolder}
      required
      merge
    />
  )
}

const TextField = ({ field, placeholder = genericPlaceHolder }) => (
  <InputFieldSection
    name={field.key}
    label={field.name}
    allowClear
    layout={FieldSectionLayout.Vertical}
    description=""
    placeholder={placeholder}
    required
    merge
  />
)

const NumberField = ({ field }) => (
  <InputNumberFieldSection
    name={field.key}
    label={field.name}
    allowClear
    layout={FieldSectionLayout.Vertical}
    description=""
    placeholder={genericPlaceHolder}
    required
    merge
  />
)

const DateField = ({ field }) => (
  <InputFieldSection
    name={field.key}
    label={field.name}
    allowClear
    layout={FieldSectionLayout.Vertical}
    description=""
    placeholder="use format: YYYY-MM/dd - f.e: 2021-12-30"
    required
    merge
  />
)

const OptionsField = ({ field }) => {
  const options = L.map(field.allowedValues, (allowedValue) => ({
    // see https://github.com/ant-design/ant-design/issues/5670
    value: JSON.stringify({ id: allowedValue.id }),
    name: allowedValue.value,
    label: `options_field_${allowedValue.name || allowedValue.value}`,
    key: `options_field_${allowedValue.name || allowedValue.value}`,
  }))
  if (options.length === 0) {
    // should not get options w/o allowed values. if so, give a free text
    return <TextField field={field} />
  }
  return (
    <SelectFieldSection
      name={field.key}
      label={field.name}
      description=""
      placeholder={genericPlaceHolder}
      layout={FieldSectionLayout.Vertical}
      options={options}
      filterOption={(input, option) =>
        option.children.props.label
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      onRenderOption={({ name }) => <Text>{name}</Text>}
      required
      merge
    />
  )
}

const PriorityField = ({ field }) => {
  const options = L.map(field.allowedValues, (allowedValue) => ({
    // see https://github.com/ant-design/ant-design/issues/5670
    value: JSON.stringify({ id: allowedValue.id }),
    name: allowedValue.name,
    label: `priority_field_${allowedValue.name}`,
    key: `priority_field_${allowedValue.name}`,
  }))
  if (options.length === 0) {
    // should not get options w/o allowed values. if so, give a free text
    return <TextField field={field} />
  }
  return (
    <SelectFieldSection
      name={field.key}
      label={field.name}
      description=""
      placeholder={genericPlaceHolder}
      layout={FieldSectionLayout.Vertical}
      options={options}
      filterOption={(input, option) =>
        option.children.props.label
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      onRenderOption={({ name }) => <Text>{name}</Text>}
      required
      merge
    />
  )
}

/*
  Supported Jira field types:
  * Date picker (date)
  * Number field (number)
  * Radio button (option)
  * Select List - Single choice (option)
  * Select List - Multiple choices (array, items - option)
  * Checkboxes - Multiple choices (array, items - options)
  * Text field - Multi-line (string)
  * Text field - Single-line (string)
  * priority (priority)
  * Labels (array, items - string)
  * URL field

  Non-supported:
  * DateTime picker
  * User picker
*/

const fieldsList = {
  multiselect: {
    component: (field) => <ArrayOptionsField field={field} />,
    validator: Yup.array()
      .of(Yup.string().required(genericRequiredWarning))
      .min(1, genericRequiredWarning)
      .required(genericRequiredWarning),
  },
  select: {
    component: (field) => <OptionsField field={field} />,
    validator: Yup.string().required(genericRequiredWarning),
  },
  float: {
    component: (field) => <NumberField field={field} />,
    validator: Yup.string().required(genericRequiredWarning),
  },
  radiobuttons: {
    component: (field) => <OptionsField field={field} />,
    validator: Yup.string().required(),
  },
  datepicker: {
    component: (field) => <DateField field={field} />,
    validator: Yup.string()
      .matches(
        RegExp('20[\\d]{2}-[\\d]{2}-[\\d]{2}'),
        'Must be a date in format YYYY-MM-dd'
      )
      .required(genericRequiredWarning),
  },
  priority: {
    component: (field) => <PriorityField field={field} />,
    validator: Yup.string().required(genericRequiredWarning),
  },
  textarea: {
    component: (field) => <TextField field={field} />,
    validator: Yup.string().required(genericRequiredWarning),
  },
  textfield: {
    component: (field) => <TextField field={field} />,
    validator: Yup.string().required(genericRequiredWarning),
  },
  labels: {
    component: (field) => <TextField field={field} />,
    validator: Yup.string()
      .matches(
        RegExp('\\[[\\s]*("[a-zA-Z0-9]{1,30}"[,]?[\\s]*)*[\\s]*\\]'),
        'List of labels must be of format: ["label1", "label2"]'
      )
      .required(genericRequiredWarning),
  },
  multicheckboxes: {
    component: (field) => <ArrayOptionsField field={field} />,
    validator: Yup.array()
      .of(Yup.string().required(genericRequiredWarning))
      .min(1, genericRequiredWarning)
      .required(genericRequiredWarning),
  },
  url: {
    component: (field) => <TextField field={field} />,
    validator: Yup.string()
      .matches(
        RegExp('http[s]?://[A-Za-z0-9]+'),
        'Url must be in format: http[s]://some-domain'
      )
      .required(genericRequiredWarning),
  },
  // assignee
  // attachment
  // components
  // gh-epic-link
  // fixVersions
  // issuelinks
}

const getFieldType = (field) => {
  if (!field.schema) return undefined
  return field.schema.system
    ? field.schema.system
    : field.schema.custom.split(
        'com.atlassian.jira.plugin.system.customfieldtypes:'
      )[1]
}

const supportedFields = Object.keys(fieldsList)

export const getNonSupportedFields = (requiredFields) => {
  return L.filter(
    requiredFields,
    (requiredField) => !supportedFields.includes(getFieldType(requiredField))
  )
}

export const fieldsValidators = (requiredFields) => {
  const obj = {}
  requiredFields.forEach((requiredField) => {
    const requiredFieldType = getFieldType(requiredField)
    const validator = fieldsList[requiredFieldType]?.validator
    if (validator) obj[requiredField.key] = validator
  })
  return obj
}

export const JiraCustomField = ({ field }) => {
  const fieldType = getFieldType(field)
  const fieldCreator = fieldsList[fieldType].component
  if (fieldCreator) return fieldCreator(field)
  return null
}
