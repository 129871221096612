import React from 'react'
import { DatePicker } from 'antd'
import { Box } from 'theme-ui'
import moment from 'moment'

const SmallDateRange = ({
  onChange,
  disabled,
  startDate,
  endDate,
  format,
  datesToDisableCallback,
  defaultPickerValue,
  allowEmpty,
}) => {
  return (
    <Box sx={{ '.ant-picker-input > input': { fontSize: '14px' } }}>
      <DatePicker.RangePicker
        style={{ width: '100%' }}
        size="small"
        format={format}
        disabled={disabled}
        onChange={onChange}
        value={[
          startDate ? moment(startDate) : null,
          endDate ? moment(endDate) : null,
        ]}
        defaultPickerValue={defaultPickerValue}
        disabledDate={datesToDisableCallback}
        ranges={{
          Today: [moment(), moment().endOf('day')],
          '2 Days Ago': [
            moment().subtract(2, 'days').startOf('day'),
            moment().endOf('day'),
          ],
          'This Week': [moment().startOf('week'), moment()],
          'This Month': [
            moment().startOf('month').startOf('day'),
            moment().endOf('day'),
          ],
        }}
        allowEmpty={allowEmpty}
      />
    </Box>
  )
}

export default SmallDateRange
