import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  ContentPanel,
  ContentPanelHeader,
} from '../../../../components/panels/content'
import { select } from '../../../../redux/store'
import PlanSetting from '../../../../blocks/settings/teams/plan/view'
import { paths } from '../../../../routes'

const PlanSettingsConnected = () => {
  const currentPlanType = useSelector(select.Plan.currentPlanType)
  const daysUntilTrialExpire = useSelector(select.Plan.daysUntilTrialExpire)
  const { scans, assets, maxAllowedMonthlyScans } = useSelector(
    select.Plan.usageData
  )
  const { maxAssets } = useSelector(select.Plan.currentPlanConfig)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!maxAllowedMonthlyScans) {
      dispatch.Teams.fetchUsage()
    }
  }, [dispatch.Teams, maxAllowedMonthlyScans])

  const onActionClick = useCallback(() => {
    history.push({
      pathname: paths.closed.settingsPlans,
    })
  }, [history])

  return (
    <ContentPanel>
      <ContentPanelHeader>Plan information</ContentPanelHeader>
      <PlanSetting
        planType={currentPlanType}
        daysUntilTrialExpire={daysUntilTrialExpire}
        onActionClick={onActionClick}
        totalAssetsUsage={maxAssets}
        currentAssetsUsage={assets}
        totalScansUsage={maxAllowedMonthlyScans}
        currentScansUsage={scans}
      />
    </ContentPanel>
  )
}

export default PlanSettingsConnected
