/** @jsx jsx */
import { jsx, Box, Grid } from 'theme-ui'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { defaultOrgTeam, OrgTeam } from '@spectral/types'
import { PlusOutlined } from '@ant-design/icons'
import slackLogo from '../../../assets/svg/slack.svg'
import msTeamsLogo from '../../../assets/svg/ms-teams.svg'
import jiraLogo from '../../../assets/svg/jira.svg'
import pdLogo from '../../../assets/svg/pd.svg'
import mondayLogo from '../../../assets/svg/monday.svg'
import { PrimaryButtonMedium } from '../../../components/buttons'
import { OrgTeamJiraIntegrationConnected } from '../../../containers/Settings/org-teams/jira-connected'
import { OrgTeamPagerDutyIntegrationConnected } from '../../../containers/Settings/org-teams/pager-duty-connected'
import { OrgTeamSlackIntegration } from './integrations/slack'
import { OrgTeamMsTeamsIntegration } from './integrations/ms-teams'
import { TeamCard } from './team-card'
import { paths } from '../../../routes'
import OrgTeamDrawer from './org-team-drawer'
import { OrgTeamMondayIntegrationConnected } from '../../../containers/Settings/org-teams/monday-connected'

export const LinkToGlobalIntegrations = ({ children }) => {
  return <Link to={paths.closed.settingsIntegrations}>{children}</Link>
}

type OrgTeamIntegration = {
  displayName: string
  logoSvg: any
  isActive: boolean
  component: Function
  orgTeamSettings: any
}

export type OrgTeamIntegrations = {
  [key: string]: OrgTeamIntegration
}

const OrgTeams = ({ teams, activeIntegrations, onSave, onDelete }) => {
  const [settingsShown, setSettingsShown] = useState(false)
  const [addOrUpdate, setAddOrEdit] = useState<'Add' | 'Update'>('Add')
  const [currentOrgTeamSetting, setCurrentOrgTeamSettings] = useState<OrgTeam>(
    defaultOrgTeam
  )
  const integrations: OrgTeamIntegrations = {
    slack: {
      displayName: 'Slack',
      logoSvg: slackLogo,
      isActive: activeIntegrations.slack,
      component: OrgTeamSlackIntegration,
      orgTeamSettings: null,
    },
    msTeams: {
      displayName: 'Microsoft Teams',
      logoSvg: msTeamsLogo,
      isActive: activeIntegrations.msTeams,
      component: OrgTeamMsTeamsIntegration,
      orgTeamSettings: null,
    },
    jira: {
      displayName: 'Jira',
      logoSvg: jiraLogo,
      isActive: activeIntegrations.jira,
      component: OrgTeamJiraIntegrationConnected,
      orgTeamSettings: currentOrgTeamSetting.integrations.jira,
    },
    pd: {
      displayName: 'PagerDuty',
      logoSvg: pdLogo,
      isActive: activeIntegrations.pd,
      component: OrgTeamPagerDutyIntegrationConnected,
      orgTeamSettings: currentOrgTeamSetting.integrations.pd || {},
    },
    monday: {
      displayName: 'Monday',
      logoSvg: mondayLogo,
      isActive: activeIntegrations.monday,
      component: OrgTeamMondayIntegrationConnected,
      orgTeamSettings: currentOrgTeamSetting.integrations.monday || {},
    },
  }

  const showSettings = () => {
    setSettingsShown((toggle) => !toggle)
  }

  const newOrgTeam = () => {
    setCurrentOrgTeamSettings(defaultOrgTeam)
    setAddOrEdit('Add')
    showSettings()
  }

  const editOrgTeam = (orgTeam) => {
    setCurrentOrgTeamSettings(orgTeam)
    setAddOrEdit('Update')
    showSettings()
  }

  const orgTeamSave = ({ orgTeams }) => {
    onSave({ orgTeams })
    showSettings()
  }

  return (
    <Box>
      <PrimaryButtonMedium
        text="New Team"
        icon={<PlusOutlined />}
        onClick={newOrgTeam}
      />
      <Grid gap={3} sx={{ mt: 5, gridTemplateColumns: 'repeat(3, 400px)' }}>
        {teams.map((orgTeam) => (
          <TeamCard
            key={orgTeam.orgTeamId}
            orgTeam={orgTeam}
            integrations={integrations}
            onDelete={onDelete}
            editOrgTeam={editOrgTeam}
          />
        ))}
      </Grid>
      <OrgTeamDrawer
        teams={teams}
        currentOrgTeamSetting={currentOrgTeamSetting}
        integrations={integrations}
        addOrUpdate={addOrUpdate}
        settingsShown={settingsShown}
        orgTeamSave={orgTeamSave}
        showSettings={showSettings}
      />
    </Box>
  )
}

export { OrgTeams }
