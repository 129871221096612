/** @jsx jsx */
import { useEffect, useState } from 'react'
import { Box, jsx } from 'theme-ui'
import theme from '../../common/theme'

const UsageBar = ({ totalUsage, currentUsage, name }) => {
  const [percentageUsage, setPercentageUsage] = useState('0%')

  useEffect(() => {
    setPercentageUsage(`${(currentUsage / totalUsage) * 100}%`)
  }, [totalUsage, currentUsage])

  return (
    <Box
      sx={{
        width: '100%',
        marginBottom: '20px',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          backgroundColor: 'disabledInputBackground',
          borderRadius: '4px',
          border: `1px solid ${theme.stylebook.colors.inputBorderColor}`,
        }}
      >
        <Box
          sx={{
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            width: percentageUsage,
            background: theme.stylebook.colors.primaryScale[50],
            transition: 'width 0.8s ease-in-out',
            borderRadius: '4px',
            height: '100%',
          }}
        />
        <span
          sx={{
            overflow: 'hidden',
            position: 'relative',
            p: '5px',
            textAlign: 'center',
          }}
        >
          {currentUsage} / {totalUsage} {name}
        </span>
      </Box>
    </Box>
  )
}

export default UsageBar
