export enum Severity {
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Informational = 'informational',
}

export enum OldSeverity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export const SeveritiesOrder = {
  [Severity.Informational]: 5,
  [Severity.Low]: 4,
  [Severity.Medium]: 3,
  [Severity.High]: 2,
  [Severity.Critical]: 1,
}

export const SeverityToColor = {
  [Severity.Critical]: '#f24e4e',
  [Severity.High]: '#ff9126',
  [Severity.Medium]: '#ffb72d',
  [Severity.Low]: '#ffd546',
  [Severity.Informational]: '#b1dbff',
}
