import React, { useEffect } from 'react'
import { Flex } from 'theme-ui'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import View from './view'
import { Dispatch, RootState } from '../../redux/store'
import { paths } from '../../routes'

const RecoverConnected = ({
  isLoggedin,
  onSubmit,
  error,
  message,
  clearMessage,
}) => {
  useEffect(() => {
    return () => {
      clearMessage()
    }
  }, [clearMessage])
  if (isLoggedin) return <Redirect to="/" />
  return (
    <Flex sx={{ justifyContent: 'center', pt: '60px', alignItems: 'center' }}>
      <View
        onSubmit={onSubmit}
        links={paths.open}
        error={error}
        message={message}
      />
    </Flex>
  )
}

const mapDispatch = (dispatch: Dispatch) => ({
  onSubmit: ({ email }) =>
    dispatch.Recover.recover({
      email,
    }),
  clearMessage: () => dispatch.Recover.reset(),
})

const mapState = (state: RootState) => ({
  isLoggedin: state.Auth.user,
  error: state.error.effects.Recover.recover,
  message: state.Recover.message,
})

export default connect(mapState, mapDispatch)(RecoverConnected)
