export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum AssetSortBy {
  LAST_SCAN_DATE = 'lastScanDate',
  DISPLAY_NAME = 'displayName',
  SEVERITY = 'highestSeverity',
}

export enum AssetDetailsSortBy {
  COUNT = 'count',
  PATH = 'path',
}

export enum IssuesSortBy {
  CONTENT = 'content',
  DETECTOR = 'detectorId',
  EXPOSURE = 'createdAt',
  PATH = 'path',
  SEVERITY = 'severity',
  STATUS = 'status',
}

export interface Sorter {
  sortBy: IssuesSortBy
  sortDirection: SortDirection
}
