import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React from 'react'
import { Flex, Text } from 'theme-ui'
import theme from '../../../../common/theme'

const DeactivateCustomRulesVersionConfirmationModal = ({
  onOk,
  onCancel,
  isLoading,
  visible,
}) => {
  return (
    <Modal
      visible={visible}
      confirmLoading={isLoading}
      okText="Yes"
      cancelText="No"
      okType="danger"
      title={
        <Flex>
          <Text
            sx={{ color: theme.stylebook.icons.modals.danger.iconColor, mr: 2 }}
          >
            <CloseCircleOutlined translate="no" />
          </Text>
          Deactivate current version
        </Flex>
      }
      onOk={onOk}
      onCancel={onCancel}
      maskClosable
    >
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text>Are you sure you want to deactivate current active version?</Text>
      </Flex>
    </Modal>
  )
}

export default DeactivateCustomRulesVersionConfirmationModal
