import { Collapse as ADCollapse } from 'antd'
import styled from 'styled-components'
import theme from '../../common/theme'

const StyledCollapse = styled(ADCollapse)`
  .ant-collapse-item {
    background-color: transparent;
    transition: background-color 0.25s ease-in-out;
  }

  .ant-collapse-item-active {
    background-color: ${theme.stylebook.colors.gray['100']};
    transition: background-color 0.25s ease-in-out;
  }
  flex: 1;
  overflow-y: auto;
`

export default StyledCollapse
