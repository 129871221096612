/** @jsx jsx */
import { PlanType, UNLIMITED } from '@spectral/types'
import { Box, jsx } from 'theme-ui'
import {
  PanelForm,
  ActionFieldSection,
  CustomSection,
} from '../../../../components/panels/content'
import UsageBar from '../../../../components/usage-bar/usage-bar'

const getFieldText = ({ planType, daysUntilTrialExpire }) => {
  switch (planType) {
    case PlanType.Free:
      return `Free`
    case PlanType.FreeTrial:
      return `Free trial (you have ${daysUntilTrialExpire} day${
        daysUntilTrialExpire > 1 ? 's' : ''
      } remaining on your trail)`
    case PlanType.Team:
      return `Team`
    case PlanType.Business:
    case PlanType.Poc:
      return `Business`
    case PlanType.Enterprise:
      return `Enterprise`
    default:
      return ''
  }
}

const PlanSetting = ({
  planType,
  daysUntilTrialExpire,
  onActionClick,
  totalAssetsUsage,
  currentAssetsUsage,
  totalScansUsage,
  currentScansUsage,
}) => {
  return (
    <Box>
      <PanelForm
        onSubmit={onActionClick}
        initialValues={{
          plan: getFieldText({ planType, daysUntilTrialExpire }),
        }}
      >
        <ActionFieldSection
          name="plan"
          label="Plan"
          description="Current plan"
          action="Upgrade Plan"
          disabled={
            planType === PlanType.Enterprise || planType === PlanType.Poc
          }
          submit
        />
        {totalAssetsUsage !== UNLIMITED && (
          <CustomSection
            name="Repositories usage"
            description="Total scanned repositories"
          >
            <UsageBar
              totalUsage={totalAssetsUsage}
              currentUsage={currentAssetsUsage}
              name="Repositories"
            />
          </CustomSection>
        )}
        {totalScansUsage !== UNLIMITED && (
          <CustomSection
            name="Scans usage"
            description="Total scans during usage period"
          >
            <UsageBar
              totalUsage={totalScansUsage}
              currentUsage={currentScansUsage}
              name="Monthly scans"
            />
          </CustomSection>
        )}
      </PanelForm>
    </Box>
  )
}

export default PlanSetting
