/** @jsx jsx */
import { FileSearchOutlined } from '@ant-design/icons'
import { Button, Checkbox, Collapse, List, Pagination, Tooltip } from 'antd'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import parseInt from 'lodash/parseInt'
import size from 'lodash/size'
import flatten from 'lodash/flatten'
import React, { useCallback, useState } from 'react'
import { Box, Flex, jsx, Link, Text } from 'theme-ui'
import styled from 'styled-components'
import { removeFilePathSlash, RoleType } from '@spectral/types'
import theme from '../../../../common/theme'
import IssueTags from '../../../../components/asset-page/issue-tags/issue-tags'
import SingleIssueAlert from '../../../../components/asset-page/single-issue/single-issue-alert'
import { Loading } from '../../../../components/loading'
import { toElementId } from '../../utils'
import assetPageTracker from '../../../../common/track/asset-page'
import EmptyAsset from '../../empty-asset'
import IgnorePopover from '../../../ignore-popover/view'
import DetectorInfo from '../../../detector-name/view'
import { itemsToDisplayInPage } from '../../shared/list-page-setup'
import IacFilesCollapse from '../../../../components/collapse/styled-collapse'
import DetectorSeverity from '../../severity-change-dropdown'
import IssueActions from '../../issue-action/actions'
import RegressionPopOver from '../../../regression-popover/view'
import ResolvePopOver from '../../../resolve-popover/view'
import IssueCollapse from '../../issue-collapse/issue-collapse'
import IssuesListActions from '../../issues-list-actions'
import Restricted from '../../../../containers/role-based-render/restricted'

const { Panel } = Collapse

const IacIssueItem = ({
  issue,
  actionHandlers,
  isSelected,
  onIssueSelectionChanged,
  integrations,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
}) => {
  const {
    link,
    detectorId,
    detectorName,
    detectorDescription,
    blameUrl,
    uri,
    firstSeen,
    displaySeverity,
    originalSeverity,
    ignore: {
      isIgnored,
      ignoreType,
      comment,
      ignoreDate,
      actor,
      timeSnoozeOver,
    },
    metadata,
    status,
  } = issue
  const [
    handleIgnore,
    handleSnooze,
    handleAssigneeChange,
    handleAssigneeRemove,
    handleSearchAssignee,
    _handleSeverityChange,
    handleResolve,
    handleUnresolve,
    handleAssetIssuesSeverityChange,
  ] = actionHandlers

  const handleSeverityChange = useCallback(
    (severityChange) => {
      handleAssetIssuesSeverityChange(severityChange)
      onIssueSelectionChanged([issue], false)
    },
    [handleAssetIssuesSeverityChange, issue, onIssueSelectionChanged]
  )

  const iacResource = issue.metadata.iacMetadata?.resource
  const originalCreatedAt = metadata?.originalCreatedAt
  const resolvedDate = metadata?.resolvedDate
  const url = metadata?.virtual_path ? uri || blameUrl : blameUrl || uri

  const tooltipText = metadata?.stage ? 'View run' : 'View source'

  return (
    <Box>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Flex sx={{ mb: 2 }}>
          <Box>
            <Restricted
              roles={[RoleType.Owner, RoleType.Admin, RoleType.Member]}
            >
              <Checkbox
                sx={{ mr: 3 }}
                checked={isSelected}
                onChange={(event) => {
                  onIssueSelectionChanged([issue], event.target.checked)
                }}
              />
            </Restricted>
            <Restricted roles={[RoleType.ReadOnly]}>
              <Box sx={{ mr: 3, minWidth: '16px' }} />
            </Restricted>

            {isIgnored && (
              <IgnorePopover
                ignoreType={ignoreType}
                ignoreDate={ignoreDate}
                comment={comment}
                actor={actor}
                timeSnoozeOver={timeSnoozeOver}
              />
            )}
            <RegressionPopOver
              originalCreatedAt={originalCreatedAt}
              status={status}
            />
            <ResolvePopOver resolvedDate={resolvedDate} status={status} />
          </Box>
          <Box mr={2}>
            <Flex mb={2}>
              <DetectorSeverity
                displaySeverity={displaySeverity}
                originalSeverity={originalSeverity}
                detectorId={detectorId}
                detectorName={detectorName}
                onSeverityChanged={handleSeverityChange}
              />

              <DetectorInfo
                isIgnored={isIgnored}
                link={link}
                detectorId={detectorId}
                detectorName={detectorName}
                onClick={() => assetPageTracker.playbookClicked(detectorId)}
              />
            </Flex>
            <Box mb={2}>
              {iacResource ? (
                <Flex>
                  Resource:&nbsp;
                  <Tooltip title={tooltipText}>
                    <Link
                      href={url}
                      target="_blank"
                      onClick={assetPageTracker.investigateClicked}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: isIgnored
                          ? theme.stylebook.colors.gray['500']
                          : theme.stylebook.colors.text,
                      }}
                    >
                      <FileSearchOutlined />
                      <Text
                        variant="ellipsis"
                        sx={{
                          ml: 1,
                          fontWeight: 700,
                          wordBreak: 'break-word',
                        }}
                      >
                        {iacResource}
                      </Text>
                    </Link>
                  </Tooltip>
                </Flex>
              ) : (
                <Button
                  size="small"
                  icon={<FileSearchOutlined />}
                  onClick={() =>
                    window.open(
                      blameUrl || uri,
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                >
                  Investigate
                </Button>
              )}
            </Box>
            <IssueTags issue={issue} integrations={integrations} />
          </Box>
        </Flex>
        <IssueActions
          title="Issue has been exposed for this long"
          firstSeen={firstSeen}
          isIgnored={isIgnored}
          handleIgnore={handleIgnore}
          handleSnooze={handleSnooze}
          issue={issue}
          handleResolve={handleResolve}
          handleUnresolve={handleUnresolve}
          assignableMembers={assignableMembers}
          handleAssigneeChange={handleAssigneeChange}
          handleAssigneeRemove={handleAssigneeRemove}
          handleSearchAssignee={handleSearchAssignee}
          isLoadingAssignableMembers={isLoadingAssignableMembers}
          isAssignableMembersLoaded={isAssignableMembersLoaded}
        />
      </Flex>
      <Box sx={{ pr: '20px', pl: '30px' }}>
        <IssueCollapse
          detectorDescription={detectorDescription}
          issue={issue}
        />
      </Box>
    </Box>
  )
}

const IN_FILE_ISSUES_PAGE_SIZE = 5

const IacIssuesList = styled(List)`
  .ant-list-items {
    width: 100%;
    min-height: ${({ hasMinHeight }) => (hasMinHeight ? '500px' : '0')};
  }
`
const IacFilePanelContent = ({
  isRefreshingIacFileDetails,
  filePath,
  issues,
  actionHandlers,
  selectedIssuesIds,
  currentQueryStringParams,
  onIssueSelectionChanged,
  integrations,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
}) => {
  const [inFileIssuesPage, setInFileIssuesPage] = useState(1)
  const { issueId } = currentQueryStringParams
  if (isRefreshingIacFileDetails) {
    return <Loading />
  }
  return issues ? (
    <Box>
      <div id={toElementId(filePath)} />
      <IacIssuesList
        hasMinHeight={issues.length > IN_FILE_ISSUES_PAGE_SIZE}
        dataSource={itemsToDisplayInPage(
          issues,
          inFileIssuesPage,
          IN_FILE_ISSUES_PAGE_SIZE
        )}
        renderItem={(iacIssue: any) => (
          <List.Item>
            <Box sx={{ width: '100%' }}>
              <IacIssueItem
                issue={iacIssue}
                actionHandlers={actionHandlers}
                isSelected={includes(selectedIssuesIds, iacIssue.pid)}
                onIssueSelectionChanged={onIssueSelectionChanged}
                integrations={integrations}
                assignableMembers={assignableMembers}
                isLoadingAssignableMembers={isLoadingAssignableMembers}
                isAssignableMembersLoaded={isAssignableMembersLoaded}
              />
            </Box>
          </List.Item>
        )}
      />
      {!issueId && (
        <Box sx={{ mt: 3, textAlign: 'right' }}>
          <Pagination
            current={inFileIssuesPage}
            onChange={(page) => setInFileIssuesPage(page)}
            total={size(issues)}
            pageSize={IN_FILE_ISSUES_PAGE_SIZE}
            showSizeChanger={false}
            size="small"
          />
        </Box>
      )}
    </Box>
  ) : (
    <Loading />
  )
}

const IacFilesList = ({
  onPaginationChange,
  isRefreshing,
  isRefreshingIacFileDetails,
  iacFiles,
  currentQueryStringParams,
  clearQueryStringParam,
  actionHandlers,
  onIssueSelectionChanged,
  selectedIssues,
  expandedFile,
  onChangeFileExpansion,
  exportActions,
  isDownloadingExport,
  integrations,
  onActionIntegrationSubmit,
  totalSecrets,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  isNotActiveAsset,
  isDataFiltered,
  setQueryStringParam,
  trackSort,
  sortOptions,
}) => {
  const { page, pageSize, issueId } = currentQueryStringParams
  const selectedIssuesIds = map(selectedIssues, 'pid')

  const resetSingleIssue = () => {
    clearQueryStringParam(['issueId', 'sortBy', 'path', 'sortDirection'])
  }
  const numTotalIssues = size(
    flatten(map(iacFiles, ({ issues }) => issues || []))
  )

  return (
    <Flex sx={{ height: '100%', flexDirection: 'column', pr: '40px' }}>
      {!isRefreshing && !isEmpty(iacFiles) && (
        <IssuesListActions
          actionHandlers={actionHandlers}
          selectedIssues={selectedIssues}
          issues={iacFiles}
          isDownloadingExport={isDownloadingExport}
          integrations={integrations}
          assignableMembers={assignableMembers}
          onActionIntegrationSubmit={onActionIntegrationSubmit}
          exportActions={exportActions}
          onSecretSelectionChanged={onIssueSelectionChanged}
          isSelectAllShown={false}
          currentQueryStringParams={currentQueryStringParams}
          setQueryStringParam={setQueryStringParam}
          trackSort={trackSort}
          sortOptions={sortOptions}
          isLoadingAssignableMembers={isLoadingAssignableMembers}
          isAssignableMembersLoaded={isAssignableMembersLoaded}
        />
      )}
      {isRefreshing ? ( // eslint-disable-line no-nested-ternary
        <Box sx={{ height: '100%' }}>
          <Loading />
        </Box>
      ) : isEmpty(iacFiles) && !isRefreshingIacFileDetails ? (
        <Box sx={{ height: '100%', mt: 3 }}>
          <EmptyAsset
            isNotActive={isNotActiveAsset}
            isNoDataShown={isDataFiltered}
          />
        </Box>
      ) : (
        <React.Fragment>
          {issueId && iacFiles && numTotalIssues === 1 && (
            <SingleIssueAlert
              alertType="info"
              alertText="You are viewing a single issue"
              onReset={resetSingleIssue}
              resetText="view all issues in this asset"
            />
          )}
          {issueId && iacFiles && numTotalIssues === 0 && (
            <SingleIssueAlert
              alertType="error"
              alertText="Requested issue does not exist"
              onReset={resetSingleIssue}
              resetText="view all issues in this asset"
            />
          )}
          <IacFilesCollapse
            onChange={(updatedlyExpandedFile) =>
              onChangeFileExpansion(
                updatedlyExpandedFile ? [updatedlyExpandedFile] : null
              )
            }
            activeKey={expandedFile}
            bordered={false}
            accordion
          >
            {map(iacFiles, ({ filePath, count, issues }) => (
              <Panel
                header={
                  <Box id={toElementId(filePath)}>
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text>{removeFilePathSlash(filePath)}</Text>
                      <Text sx={{ fontSize: '12px' }}>{count} issues</Text>
                    </Flex>
                  </Box>
                }
                key={filePath}
              >
                <IacFilePanelContent
                  isRefreshingIacFileDetails={isRefreshingIacFileDetails}
                  filePath={filePath}
                  issues={issues}
                  currentQueryStringParams={currentQueryStringParams}
                  actionHandlers={actionHandlers}
                  selectedIssuesIds={selectedIssuesIds}
                  onIssueSelectionChanged={onIssueSelectionChanged}
                  integrations={integrations}
                  assignableMembers={assignableMembers}
                  isLoadingAssignableMembers={isLoadingAssignableMembers}
                  isAssignableMembersLoaded={isAssignableMembersLoaded}
                />
              </Panel>
            ))}
          </IacFilesCollapse>
        </React.Fragment>
      )}
      {!issueId && (
        <Flex sx={{ pb: 2, pt: 2, justifyContent: 'end' }}>
          <Pagination
            current={page ? parseInt(page) : 1}
            onChange={onPaginationChange}
            total={totalSecrets}
            pageSize={parseInt(pageSize, 10)}
            showSizeChanger
            disabled={isRefreshing}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default IacFilesList
