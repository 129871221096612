/* eslint-disable no-nested-ternary */
import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { AssetDetailsSortBy } from '@spectral/types'
import { concat, isEmpty, omit, pick, sortBy as LsortBy } from 'lodash'
import { Loading } from '../../../../components/loading'
import OpenSourceFilesList from '../open-source-files-list/view'
import OpenSourceListSideFilters, {
  SideFilterType,
} from '../../../../components/filters/side-filters'
import useDebouncedFieldFilter from '../../../../hooks/useDebouncedFieldFilter'
import {
  buildAssignableMembersData,
  filtersSetup,
} from '../../shared/list-page-setup'
import DynamicDataFilter from '../../../../components/filters/dynamic-data-filter'
import useFiltersActions from '../../../../hooks/useFiltersActions'
import AppliedFilters from '../../../../components/applied-filters'
import {
  removeEmptyFilters,
  transformAssigneeFiltersToDisplay,
  toFilterView,
  transformFiltersToDisplay,
} from '../../shared/transform-filters'
import PathFilter from '../../filters/path-filter'
import FiltersSidePanel from '../../../../containers/filters-side-panel'

export const sortOptions = [
  { key: AssetDetailsSortBy.PATH, title: 'Path' },
  { key: AssetDetailsSortBy.COUNT, title: 'Total issues' },
]

const OpenSourceView = ({
  asset,
  openSourceFiles,
  filtersData,
  currentQueryStringParams,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  setQueryStringParam,
  clearQueryStringParam,
  trackSort,
  trackFilter,
  expandedFile,
  onPaginationChange,
  pagination,
  onIssueSelectionChanged,
  isRefreshing,
  isRefreshingOpenSourceFileDetails,
  actionHandlers,
  selectedIssues,
  exportActions,
  isDownloadingExport,
  integrations,
  onActionIntegrationSubmit,
  loaded,
  onChangeFileExpansion,
  isDataFiltered,
}) => {
  const { issueId } = currentQueryStringParams
  const [
    onDebouncedValueChange,
    currentDebouncedValues,
  ] = useDebouncedFieldFilter(
    {
      path: currentQueryStringParams.path,
      assignees: currentQueryStringParams.assignees,
      detectorId: currentQueryStringParams.detectorId,
      severity: currentQueryStringParams.severity,
      packagesNames: currentQueryStringParams.packagesNames,
      OpenSourceIssueTypes: currentQueryStringParams.OpenSourceIssueTypes,
      status: currentQueryStringParams.status,
    },
    setQueryStringParam,
    clearQueryStringParam,
    trackFilter,
    currentQueryStringParams
  )

  const isHiddenFilters =
    isEmpty(openSourceFiles) && !isRefreshing && !isDataFiltered

  const filtersMetadata = filtersSetup(
    {
      assignableMembers: {
        ...buildAssignableMembersData(filtersData.assignees),
        isActive: !isHiddenFilters || filtersData.assignees?.length > 0,
      },
      detectors: filtersData?.detectorId || [],
      severity: filtersData?.severity || [],
      status: filtersData?.status || [],
    },
    [
      {
        filter: {
          key: 'OpenSourceIssueTypes',
          title: 'Issue type',
          component: DynamicDataFilter({
            searchable: true,
            showAllOptions: true,
          }),
          isActive: !isEmpty(filtersData.OpenSourceIssueTypes),
          type: SideFilterType.Checkbox,
          data: toFilterView({
            filterData: filtersData.OpenSourceIssueTypes,
            shouldCapitalize: true,
          }),
          collapsible: true,
        },
        index: 5,
      },
      {
        filter: {
          key: 'packagesNames',
          title: 'Package name',
          component: DynamicDataFilter({
            searchable: true,
            showAllOptions: false,
          }),
          isActive: !isEmpty(filtersData.packageName),
          type: SideFilterType.Checkbox,
          data: toFilterView({
            filterData: filtersData.packageName,
            shouldCapitalize: true,
          }),
          collapsible: true,
        },
        index: 2,
      },
    ]
  )
  const fullDebouncedValues = removeEmptyFilters(currentDebouncedValues)

  const filtersToDisplay = transformFiltersToDisplay(
    omit(fullDebouncedValues, 'assignees')
  )
  const assigneesFilterToDisplay = transformAssigneeFiltersToDisplay(
    pick(fullDebouncedValues, 'assignees'),
    assignableMembers
  )

  const currentFiltersData = LsortBy(
    concat(filtersToDisplay, assigneesFilterToDisplay),
    'filterName'
  )

  const filterActions = useFiltersActions({
    setFilter: onDebouncedValueChange,
    trackFilter,
    clearFilter: clearQueryStringParam,
  })

  return (
    <Flex sx={{ height: '100%', flexDirection: 'column' }}>
      {!isEmpty(currentFiltersData) && (
        <Box sx={{ mb: 3 }}>
          <AppliedFilters
            filters={currentFiltersData}
            onClearAll={() =>
              filterActions.onCheckboxFiltersClear(
                currentFiltersData.map(({ filterKey }) => filterKey)
              )
            }
            onRemoveFilter={filterActions.onCheckboxFilterCheck}
            onClearFilter={filterActions.onCheckboxFiltersClear}
          />
        </Box>
      )}
      <Flex sx={{ height: '0', flex: 1 }}>
        {!issueId && !isHiddenFilters && (
          <FiltersSidePanel>
            <Box sx={{ mb: 2 }} />
            <PathFilter
              path={currentDebouncedValues.path}
              onValueChanged={onDebouncedValueChange}
              isRefreshing={isRefreshing}
            />
            <OpenSourceListSideFilters
              filtersValues={currentDebouncedValues}
              filterActions={filterActions}
              isDataRefreshing={isRefreshing}
              filtersMetadata={filtersMetadata}
            />
          </FiltersSidePanel>
        )}
        <Box sx={{ width: '100%', height: '100%' }}>
          {loaded ? (
            <OpenSourceFilesList
              isDownloadingExport={isDownloadingExport}
              onPaginationChange={onPaginationChange}
              currentQueryStringParams={currentQueryStringParams}
              clearQueryStringParam={clearQueryStringParam}
              totalIssues={pagination?.total}
              openSourceFiles={openSourceFiles}
              onChangeFileExpansion={onChangeFileExpansion}
              expandedFile={expandedFile}
              isRefreshing={isRefreshing}
              isRefreshingOpenSourceFileDetails={
                isRefreshingOpenSourceFileDetails
              }
              actionHandlers={actionHandlers}
              onIssueSelectionChanged={onIssueSelectionChanged}
              selectedIssues={selectedIssues}
              exportActions={exportActions}
              integrations={integrations}
              onActionIntegrationSubmit={onActionIntegrationSubmit}
              assignableMembers={assignableMembers}
              isNotActiveAsset={asset.isNotActive}
              isDataFiltered={isDataFiltered}
              setQueryStringParam={setQueryStringParam}
              trackSort={trackSort}
              sortOptions={sortOptions}
              isLoadingAssignableMembers={isLoadingAssignableMembers}
              isAssignableMembersLoaded={isAssignableMembersLoaded}
            />
          ) : (
            <Loading />
          )}
        </Box>
      </Flex>
    </Flex>
  )
}

export default OpenSourceView
