/** @jsx jsx */
import { EyeInvisibleOutlined } from '@ant-design/icons'
import { Box, jsx } from 'theme-ui'
import { Popover } from 'antd'
import { dateWithoutTimeFormat, IssueIgnoreTypes } from '@spectral/types'
import { format } from 'date-fns'
import { DateFormat } from '../../components/formatters'

const IgnorePopover = ({
  ignoreType,
  ignoreDate,
  comment,
  actor,
  timeSnoozeOver,
}) => {
  return (
    <Box>
      <Popover
        content={
          <Box>
            {ignoreType && ignoreDate && (
              <Box>
                Ignored as {ignoreType} at <DateFormat date={ignoreDate} /> by "
                {actor}"
              </Box>
            )}
            {ignoreType === IssueIgnoreTypes.snooze && (
              <Box>
                Snoozed until{' '}
                {format(new Date(timeSnoozeOver), dateWithoutTimeFormat)}
              </Box>
            )}
            <Box>{comment && `Comment: ${comment}`}</Box>
          </Box>
        }
        title="Ignored issue"
        trigger="hover"
      >
        <EyeInvisibleOutlined />
      </Popover>
    </Box>
  )
}

export default IgnorePopover
