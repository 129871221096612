/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { jsx, Flex } from 'theme-ui'

export const DialogPanel = ({ children }) => (
  <Flex
    sx={{
      border: 'outline',
      maxWidth: '840px',
      minHeight: '561px',
      bg: 'white',
      borderRadius: '4px',
    }}
  >
    {children}
  </Flex>
)
