/** @jsx jsx */
import { Box, Flex, Heading, Image, jsx } from 'theme-ui'
import { Button, Tooltip } from 'antd'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { imageMap } from '../../../common/sources/imageMap'
import IntegrationWrapper from '../integration-wrapper'
import { supportWidget } from '../../../common/support-widget'
import { TextStyle } from './share'
import awsDeployStack from '../../../assets/png/cloudformation-launch-stack.png'
import IntegrationStatus from '../integration-status'
import PersonalTokenAlert from '../personal-token-alert'

const ConfluenceSourceIntegration = ({
  dataSource,
  statusLoaded,
  isConnected,
  shouldShowTeamToken,
  onSwitchTokenShown,
}) => {
  return (
    <IntegrationWrapper
      title={dataSource.title}
      imgSrc={imageMap[dataSource?.tileKey]}
      extraOnRight={
        <IntegrationStatus
          variant="static"
          isConnected={isConnected}
          isLoaded={statusLoaded}
        />
      }
    >
      <Flex
        sx={{
          gap: 3,
          flexDirection: 'column',
          code: {
            backgroundColor: 'black',
            borderRadius: '4px',
            color: 'white',
            py: '2px',
            px: '5px',
          },
        }}
      >
        {shouldShowTeamToken && (
          <Box sx={{ my: 4 }}>
            <PersonalTokenAlert onSwitch={onSwitchTokenShown} />
          </Box>
        )}
        <TextStyle>
          Protect your Confluence content. Real-time, blazing fast scanning of
          pages, comments and even attachments!
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Setup
        </Heading>
        <TextStyle>
          Since Spectral never keeps your secrets and content, we provide an AWS
          lambda function that you deploy in your organization&#39;s
          <Tooltip
            title={
              <Box>
                <strong>Required AWS permissions:</strong>
                <br /> lambda:GetAccountSettings
                <br /> cloudformation:DescribeStacks
                <br /> iam:CreateRole
                <br /> iam:DeleteRole
                <br /> apigateway:POST
                <br /> logs:CreateLogGroup
                <br /> iam:PutRolePolicy
              </Box>
            }
            sx={{ alignItems: 'center' }}
            overlayInnerStyle={{ display: 'inline-block' }}
          >
            {' '}
            AWS account.{' '}
            <Box as="span" sx={{ verticalAlign: 'text-top' }}>
              <AiOutlineInfoCircle size={20} />
            </Box>
          </Tooltip>
        </TextStyle>{' '}
        <TextStyle>
          In addition, you&#39;ll add a Confluence webhook to send relevant
          events to this lambda.
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Deploy the Lambda
        </Heading>
        <TextStyle>
          Go ahead and deploy the stack{' '}
          <Box sx={{ margin: '6px 0' }}>
            <a
              href="https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-confluence-scanner/cloudformation-template.json&stackName=spectral-confluence-scanner"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Image sx={{ mr: 2 }} src={awsDeployStack} />
            </a>
          </Box>
          After the stack is deployed grab the function gateway api URL,
          we&#39;ll use it next.
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Add Confluence Webhook
        </Heading>
        <TextStyle>
          Go to your Confluence instance and install the&nbsp;
          <a href="https://marketplace.atlassian.com/apps/1222102/webhook-manager-for-confluence-cloud?tab=overview&hosting=cloud">
            webhooks manager extension
          </a>
          &nbsp;(costs 1$ per user per month)
        </TextStyle>
        <TextStyle>
          Add a new webhook in the webhook manager (change{' '}
          <code>YOUR_ACCOUNT</code> to your instance domain in this url:{' '}
          <code>
            https://YOUR_ACCOUNT.atlassian.net/wiki/plugins/servlet/ac/com.stiltsoft.confluence.cloud.webhooks/admin-webhooks-page
          </code>
          )
        </TextStyle>
        <TextStyle>
          Make sure to configure:
          <ul>
            <li>
              Webhook url to point to your function endpoint (see in your new
              lambda page in AWS console after deploying)
            </li>
            <li>
              Add a query string param: `webhook_token` and set it to the same
              webhook token you put in the `CONFLUENCE_WEBHOOK_TOKEN` parameter
              in your function
            </li>
            <li>
              Set the event types of the webhook to: attachment created, comment
              created, comment updated, page created, page updated, content
              created, content updated
            </li>
          </ul>
          So for example if your confluence event endpoint is&nbsp;
          <code>
            https://random123.execute-api.us-east-1.amazonaws.com/prod/api/confluence_event
          </code>
          &nbsp; and the token you set in your function env var is &nbsp;
          <code>f4lmf4kl2ldoxxxxxx</code>, the webhook url you configure in
          Confluence should be: &nbsp;
          <code>
            https://random123.execute-api.us-east-1.amazonaws.com/prod/api/confluence_event?webhook_token=f4lmf4kl2ldoxxxxxx
          </code>
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          That&#39;s it{' '}
          <span role="img" aria-label="strong">
            💪
          </span>
        </Heading>
        <TextStyle>
          Your Confluence instance should now be monitored by Spectral.
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Take it for a spin
        </Heading>
        <TextStyle>
          Publish a page with a fake secret (like{' '}
          <code>AKIA4HK52OLF2AAN9KWV</code>) in a monitored space.
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Integration Status
        </Heading>
        <IntegrationStatus isConnected={isConnected} isLoaded={statusLoaded} />
      </Flex>
      <Flex sx={{ mt: '40px', justifyContent: 'center' }}>
        <Button size="large" onClick={() => supportWidget.open()}>
          Need help? Talk to an expert
        </Button>
      </Flex>
    </IntegrationWrapper>
  )
}

export default ConfluenceSourceIntegration
