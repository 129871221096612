import { digestInfo, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const otherCI = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'Other CI Systems',
    content: 'Integrate with any CI system',
    tileKey: 'otherCI',
    selfHostedRelevantProviders: [
      selfHostedProviders.gitlabSelfHosted,
      selfHostedProviders.githubEnterprise,
      selfHostedProviders.bitbucketServer,
    ],
    snippets: [
      {
        title: 'Spectral download',
        code: `$ curl -L "{baseUrl}/latest/x/sh?key={teamKey}" | sh`,
        type: 'shell',
        postDescription: digestInfo,
      },
      {
        title: 'Set up your DSN in your environment / credential store',
        code: `SPECTRAL_DSN={dsn}`,
        type: 'shell',
      },
      {
        title: 'Run in your CI as a testing step',
        code: `$HOME/.spectral/spectral scan --ok {options}`,
        type: 'shell',
      },
    ],
  },
}
