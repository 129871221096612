import pick from 'lodash/pick'
import { AssetType } from '../asset-view'

export type Unlimited = -1
export const UNLIMITED: Unlimited = -1
const MAX_ASSET_FREE_ACCOUNT = 10
const MAX_SCAN_PER_DAY_FREE_ACCOUNT = 30
const DEFAULT_CLOUDGUARD_ALLOWED_ASSETS = 10

export enum PlanType {
  Free = 'free',
  Team = 'team',
  Business = 'business',
  FreeTrial = 'freeTrial',
  Enterprise = 'enterprise',
  Poc = 'poc',
  Cloudguard = 'cloudguard',
}

export enum NotificationServices {
  Slack = 'slack',
  Mail = 'mail',
}

export enum PlanFeature {
  ORG_TEAMS_ASSET_MAPPINGS = 'ORG_TEAMS_ASSET_MAPPINGS',
  REPORTS = 'REPORTS',
  CLASSIFICATION = 'CLASSIFICATION',
  INTEGRATIONS = 'INTEGRATIONS',
  RBAC = 'RBAC',
  SSO = 'SSO',
  API = 'API',
  SLA = 'SLA',
  CUSTOM_RULES = 'CUSTOM_RULES',
}

export enum Product {
  Iac = 'iac',
  // DEPRECATED - used to support old scaner versions
  Secret = 'secret',
  Secrets = 'secrets',
  Cicdhardening = 'cicdhardening',
  OpenSource = 'oss',
}

export enum AllocationLevel {
  None = 'none',
  UsageBased = 'usage-based',
  Unlimited = 'unlimited',
}

export type Products = { [k in Product]: AllocationLevel }

export type PlanSpecifications = {
  maxScansPerDay: number
  maxAssets: number
  maxContributors: number
  notificationServicesAllowed: NotificationServices[] | Unlimited
  assetTypesAllowed: AssetType[] | Unlimited
  dataRetentionInDays: number
  products: Products
  features: {
    [PlanFeature.CLASSIFICATION]: boolean
    [PlanFeature.ORG_TEAMS_ASSET_MAPPINGS]: boolean
    [PlanFeature.REPORTS]: boolean
    [PlanFeature.INTEGRATIONS]: boolean
    [PlanFeature.RBAC]: boolean
    [PlanFeature.SSO]: boolean
    [PlanFeature.API]: boolean
    [PlanFeature.SLA]: boolean
    [PlanFeature.CUSTOM_RULES]: boolean
  }
}

type Plans = { [key in PlanType]: PlanSpecifications }

const defaultProducts: Products = {
  [Product.Iac]: AllocationLevel.UsageBased,
  // DEPRECATED - used to support old scaner versions
  [Product.Secret]: AllocationLevel.UsageBased,
  [Product.Secrets]: AllocationLevel.UsageBased,
  [Product.Cicdhardening]: AllocationLevel.UsageBased,
  [Product.OpenSource]: AllocationLevel.UsageBased,
}

export const businessPlan: PlanSpecifications = {
  maxScansPerDay: UNLIMITED,
  maxAssets: UNLIMITED,
  maxContributors: 200,
  notificationServicesAllowed: UNLIMITED,
  assetTypesAllowed: UNLIMITED,
  dataRetentionInDays: 180,
  products: defaultProducts,
  features: {
    [PlanFeature.CLASSIFICATION]: true,
    [PlanFeature.ORG_TEAMS_ASSET_MAPPINGS]: true,
    [PlanFeature.REPORTS]: true,
    [PlanFeature.INTEGRATIONS]: true,
    [PlanFeature.RBAC]: true,
    [PlanFeature.SSO]: true,
    [PlanFeature.API]: true,
    [PlanFeature.SLA]: false,
    [PlanFeature.CUSTOM_RULES]: true,
  },
}

export const enterprisePlan: PlanSpecifications = {
  maxScansPerDay: UNLIMITED,
  maxAssets: UNLIMITED,
  maxContributors: UNLIMITED,
  notificationServicesAllowed: UNLIMITED,
  assetTypesAllowed: UNLIMITED,
  dataRetentionInDays: UNLIMITED,
  products: defaultProducts,
  features: {
    [PlanFeature.CLASSIFICATION]: true,
    [PlanFeature.ORG_TEAMS_ASSET_MAPPINGS]: true,
    [PlanFeature.REPORTS]: true,
    [PlanFeature.INTEGRATIONS]: true,
    [PlanFeature.RBAC]: true,
    [PlanFeature.SSO]: true,
    [PlanFeature.API]: true,
    [PlanFeature.SLA]: true,
    [PlanFeature.CUSTOM_RULES]: true,
  },
}

const plans: Plans = {
  [PlanType.Free]: {
    maxScansPerDay: MAX_SCAN_PER_DAY_FREE_ACCOUNT,
    maxAssets: MAX_ASSET_FREE_ACCOUNT,
    maxContributors: 10,
    notificationServicesAllowed: [
      NotificationServices.Slack,
      NotificationServices.Mail,
    ],
    assetTypesAllowed: [AssetType.Git],
    dataRetentionInDays: 30,
    products: defaultProducts,
    features: {
      [PlanFeature.CLASSIFICATION]: false,
      [PlanFeature.ORG_TEAMS_ASSET_MAPPINGS]: false,
      [PlanFeature.REPORTS]: false,
      [PlanFeature.INTEGRATIONS]: false,
      [PlanFeature.RBAC]: false,
      [PlanFeature.SSO]: false,
      [PlanFeature.API]: false,
      [PlanFeature.SLA]: false,
      [PlanFeature.CUSTOM_RULES]: false,
    },
  },
  [PlanType.Team]: {
    ...businessPlan,
    maxAssets: 100,
    maxContributors: 50,
  },
  [PlanType.Business]: {
    ...businessPlan,
  },
  [PlanType.Poc]: {
    ...businessPlan,
  },
  [PlanType.FreeTrial]: {
    ...businessPlan,
    maxAssets: MAX_ASSET_FREE_ACCOUNT,
  },
  [PlanType.Enterprise]: {
    ...enterprisePlan,
  },
  [PlanType.Cloudguard]: {
    ...enterprisePlan,
    maxAssets: DEFAULT_CLOUDGUARD_ALLOWED_ASSETS,
    products: {
      ...defaultProducts,
      [Product.Iac]: AllocationLevel.Unlimited,
    },
  },
}

export type Plan = {
  type?: PlanType
  forced?: boolean
  subscriptionDate?: Date
  trialInDays?: number
  pocExpirationInDays?: number
  subscriptionMaxContributors?: number
  status?: string
  customSpecification?: any
}

export const getPlanSpecificationsByType = (planType: PlanType) => {
  return { ...plans[planType] }
}

export const isProductEnabled = ({
  productsToCheck,
  teamProducts,
}: {
  productsToCheck: Product[]
  teamProducts: Products
}) => {
  const filteredScopedProducts = pick(teamProducts, productsToCheck)
  const levels = Object.values(filteredScopedProducts)
  return !levels.some((level) => level === AllocationLevel.None)
}
