import { ModelConfig } from '@rematch/core'
import omit from 'lodash/omit'
import track from '../../../common/track'
import { authPersistence } from '../../persistence'

import { RootState, sdkClient } from '../../store'

type EmailVerificationState = {
  isEmailAlertVisible: boolean
  isVerificationSent: boolean
  verificationError: null | string
}
const initialState: EmailVerificationState = {
  isEmailAlertVisible: false,
  isVerificationSent: false,
  verificationError: null,
}

export const emailVerification: ModelConfig<EmailVerificationState> = {
  state: initialState,
  reducers: {
    setIsEmailAlertVisible(
      state: EmailVerificationState,
      isEmailAlertVisible: boolean
    ) {
      return { ...state, isEmailAlertVisible }
    },
    setIsVerificationSent(
      state: EmailVerificationState,
      isVerificationSent: boolean
    ) {
      return { ...state, isVerificationSent }
    },
    setVerificationError(
      state: EmailVerificationState,
      verificationError: string
    ) {
      return { ...state, verificationError }
    },
  },
  effects: (dispatch: any) => ({
    async resendEmailVerification(payload, rootState: RootState) {
      const { user } = rootState.Auth
      await sdkClient.users().resendEmailVerification({
        data: { email: user ? user.username : payload.email },
      })
      dispatch.EmailVerification.setIsVerificationSent(true)
    },
    async verifyEmail(payload) {
      try {
        const user = (await sdkClient.users().verifyEmail({
          params: { token: payload.token },
        })) as any
        dispatch.Auth.setUser(user)
        track.event('LOGIN', { username: user.username })
        authPersistence.persist(omit(user, ['username', 'token']))
        dispatch.Auth.fetchSettings()
        dispatch.EmailVerification.setIsEmailAlertVisible(
          user.isEmailVerificationShown
        )
        dispatch.Teams.fetchCurrentTeam()
      } catch (error) {
        dispatch.EmailVerification.setVerificationError(error.message)
      }
    },
  }),
  selectors: (_slice, createSelector, _hasProps) => ({
    verificationStatus() {
      return createSelector(
        (rootState) => (rootState as RootState).EmailVerification,
        (rootState) => (rootState as RootState).loading,
        (emailVerificationState, loadingState) => ({
          error: emailVerificationState.verificationError,
          isLoading: loadingState.effects.EmailVerification.verifyEmail,
        })
      )
    },
  }),
}
