import React from 'react'

import { Page } from '../../components/page'

const ErrorPage = () => {
  return (
    <Page
      name="CLOUD-GUARD-CONNECT-ERROR"
      title="Error loading your account"
      isError
      isLoading={false}
    />
  )
}

export default ErrorPage
