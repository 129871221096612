import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import toLower from 'lodash/toLower'
import { Modal, Avatar, Button, Empty, Tabs, Input, Pagination } from 'antd'
import { Box, Flex, Text } from 'theme-ui'
import {
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { formatDistanceToNowStrict } from 'date-fns'
import EditMember from '../../../member-row/edit-view'
import ReadOnlyMember from '../../../member-row/readonly-view'

import { SectionHeader } from '../../../../components/panels/content'
import { Loading } from '../../../../components/loading'
import track from '../../../../common/track'
import { isEmbedded } from '../../../../common/utils'

function removeMemberConfirm({ onOk, username, title }) {
  return new Promise((resolve) => {
    Modal.confirm({
      title,
      icon: <ExclamationCircleOutlined translate="no" />,
      content: username,
      okText: 'Yes',
      centered: true,
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onOk(username)
      },
      onCancel() {
        return resolve(true)
      },
    })
  })
}

const MembersTable = ({
  orgTeams,
  users,
  isLoadingUsers,
  removeMember = null,
  removeInvite = null,
  changeUserOrgTeams = null,
  changeMemberRole = null,
  readOnly = false,
  invites = [],
  isLoadingInvites = false,
  isSSOConfigured,
  page,
  onPageChanged,
  emailFilter,
  onFilterChanged,
  totalUsers,
  pageSize,
}) => {
  const [invitesFilter, setInvitesFilter] = useState()
  const filteredInvites = invites.filter(
    ({ email }) => !invitesFilter || email.includes(toLower(invitesFilter))
  )
  const emptyStateMembersComponent = isLoadingUsers ? (
    <Loading />
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>No matching members</span>}
    />
  )

  let emptyStateInvitesComponent
  if (isLoadingInvites) {
    emptyStateInvitesComponent = <Loading />
  } else if (isEmpty(invites)) {
    emptyStateInvitesComponent = (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>No invites</span>}
      />
    )
  } else {
    emptyStateInvitesComponent = (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>No matching invites</span>}
      />
    )
  }

  const shouldDisplayPendingTab = !isEmbedded() && !isSSOConfigured

  return (
    <SectionHeader
      title=""
      renderContent={() => (
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Members" key="1" sx={{ p: 5 }}>
            <Input
              size="middle"
              placeholder="Search members by email"
              value={emailFilter}
              allowClear
              style={{ marginBottom: '12px', width: '300px' }}
              onChange={(event) => {
                // @ts-ignore
                onFilterChanged(event.target.value)
              }}
            />
            <Box sx={{ minHeight: '600px' }}>
              {!isEmpty(users) && !isLoadingUsers
                ? users.map((member) =>
                  readOnly ? (
                    <ReadOnlyMember
                      key={`read-only-member-${member.username}`}
                      member={member}
                    />
                  ) : (
                    <EditMember
                      key={`edit-member-${member.username}`}
                      member={member}
                      removeMember={() =>
                        removeMemberConfirm({
                          onOk: removeMember,
                          username: member.username,
                          title:
                            'Are you sure you want to delete this member?',
                        })
                      }
                      orgTeams={orgTeams}
                      changeMemberRole={changeMemberRole}
                      changeMemberOrgTeams={changeUserOrgTeams}
                    />
                  )
                )
                : emptyStateMembersComponent}
            </Box>
            <Flex sx={{ pr: '16px', pb: 2, pt: 2, justifyContent: 'end' }}>
              <Pagination
                current={page}
                onChange={onPageChanged}
                total={totalUsers}
                pageSize={pageSize}
                showSizeChanger={false}
                disabled={isLoadingUsers}
                hideOnSinglePage
              />
            </Flex>
          </Tabs.TabPane>

          {shouldDisplayPendingTab && (
            <Tabs.TabPane
              tab={
                invites.length > 0 ? `Pending (${invites.length})` : 'Pending'
              }
              key="2"
              sx={{ p: 5 }}
            >
              {!isEmpty(invites) && (
                <Input
                  size="middle"
                  placeholder="Search invites by email"
                  value={invitesFilter}
                  style={{ marginBottom: '12px', width: '300px' }}
                  onChange={(event) => {
                    track.filterInvites()
                    // @ts-ignore
                    setInvitesFilter(event.target.value)
                  }}
                />
              )}
              {isEmpty(filteredInvites)
                ? emptyStateInvitesComponent
                : filteredInvites.map((pendingInvite) => (
                  <Box sx={{ mb: '16px' }} key={pendingInvite.email}>
                    <Flex sx={{ alignItems: 'center' }}>
                      <Flex
                        sx={{
                          width: '300px',
                          alignItems: 'center',
                          mr: '60px',
                        }}
                      >
                        <Avatar
                          style={{
                            marginRight: '8px',
                            minWidth: '32px',
                            height: '32px',
                          }}
                        >
                          {pendingInvite.email[0].toUpperCase()}
                        </Avatar>
                        <Text variant="ellipsis" title={pendingInvite.email}>
                          {pendingInvite.email}
                        </Text>
                      </Flex>
                      <Box sx={{ width: '300px', mr: '10px' }}>
                        <Text>
                          Invited{' '}
                          {formatDistanceToNowStrict(
                            new Date(pendingInvite.sentAt),
                            {
                              addSuffix: true,
                            }
                          )}{' '}
                          as {pendingInvite.role?.toLowerCase()}
                        </Text>
                      </Box>
                      {!readOnly && (
                        <Button
                          danger
                          size="small"
                          style={{ fontSize: '12px' }}
                          onClick={() =>
                            removeMemberConfirm({
                              onOk: removeInvite,
                              username: pendingInvite.email,
                              title:
                                'Are you sure you want to delete this invite?',
                            })
                          }
                        >
                          <MinusCircleOutlined />
                          Remove
                        </Button>
                      )}
                    </Flex>
                  </Box>
                ))}
            </Tabs.TabPane>
          )}
        </Tabs>
      )}
    />
  )
}
export default MembersTable
