import { InviteViewModel } from '@spectral/types'
import { FetchStatus, sdkClient } from '../../store'
import { empty, loaded } from '../../utils'

type InvitesState = {
  invites: { fetchStatus: FetchStatus; data: Array<InviteViewModel> }
}
const initialState: InvitesState = {
  invites: empty([]),
}

export const invitesModel = {
  state: initialState,
  reducers: {
    set(state: InvitesState, invites: InviteViewModel[]) {
      return { ...state, invites: loaded(invites) }
    },
    removeByEmail(state: InvitesState, email: string) {
      return {
        ...state,
        invites: {
          ...state.invites,
          data: state.invites.data.filter((inv) => inv.email !== email),
        },
      }
    },
    add(state: InvitesState, invite: InviteViewModel) {
      return {
        ...state,
        invites: {
          ...state.invites,
          data: [...state.invites.data, invite],
        },
      }
    },
  },
  effects: (dispatch: any) => ({
    async fetchInvites() {
      const { data } = (await sdkClient.invites().listInvites()) as {
        data: Array<any>
      }
      dispatch.Invites.set(data)
    },
    async removeInvite(email) {
      await sdkClient.invites().removeInvite({ data: { email } })
      dispatch.Invites.removeByEmail(email)
    },
    async addInvite(payload) {
      const { inviteEmail, roleType } = payload
      const { data } = await sdkClient
        .invites()
        .addInvite({ data: { email: inviteEmail, role: roleType } })
      if (data) dispatch.Invites.add(data)
    },
  }),
  selectors: (slice, createSelector, _hasProps) => ({
    getInvites() {
      return createSelector(
        slice,
        (rootState) => rootState.loading,
        (rootState) => rootState.error,
        (invitesState, loadingState, errorState) => {
          const isLoadingInvites = loadingState.effects.Invites.fetchInvites > 0
          const inviteError = errorState.effects.Invites.addInvite
          const { invites } = invitesState
          return {
            isLoadingInvites,
            invites: invites.data,
            inviteError,
          }
        }
      )
    },
  }),
}
