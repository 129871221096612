import { ModelConfig } from '@rematch/core'
import {
  getPlanSpecificationsByType,
  PlanSpecifications,
  PlanType,
  UNLIMITED,
  UrlSessionResponse,
} from '@spectral/types'
import { FetchStatus, RootState, sdkClient } from '../../store'
import { empty, loaded } from '../../utils'

type PlanState = {
  daysUntilTrialExpire: number
  type: { fetchStatus: FetchStatus; data: PlanType }
  planConfig: PlanSpecifications
  usage: { scans: number; assets: number; maxAllowedMonthlyScans: number }
  subscriptionMaxContributors: number | null
}

export const plan: ModelConfig<PlanState> = {
  state: {
    daysUntilTrialExpire: -1,
    type: empty(null),
    planConfig: getPlanSpecificationsByType(PlanType.Free),
    usage: {
      scans: 0,
      assets: 0,
      maxAllowedMonthlyScans: 0,
    },
    subscriptionMaxContributors: null,
  },
  reducers: {
    setPlanDetails(state: PlanState, planState) {
      return {
        ...state,
        ...planState,
        type: loaded(planState.type),
        subscriptionMaxContributors: planState.subscriptionMaxContributors,
      }
    },
    setUsage(state: PlanState, usage) {
      return {
        ...state,
        usage,
      }
    },
  },
  effects: (dispatch: any) => ({
    async redirectToCheckout(payload) {
      const { contributors } = payload
      const { redirectUrl } = (await sdkClient
        .payments()
        .getCheckoutUrl({ data: { contributors } })) as UrlSessionResponse
      window.location.href = redirectUrl
    },
    async redirectToPortalSession(_payload) {
      const {
        redirectUrl,
      } = (await sdkClient
        .payments()
        .getBillingManagementUrl()) as UrlSessionResponse
      window.location.href = redirectUrl
    },
  }),
  selectors: (_slice, createSelector, _hasProps) => ({
    isLimitedToAddContributors() {
      return createSelector(
        (rootState) => (rootState as RootState).Teams.current.data,
        (rootState) => (rootState as RootState).Plan.type.data,
        (rootState) => (rootState as RootState).Plan.planConfig,
        (rootState) => (rootState as RootState).Invites.invites.data,
        (rootState) => (rootState as RootState).Users.users.data.totalUsers,
        (_current, planType, planConf, invites, totalUsers) => {
          const { maxContributors } = planConf
          const totalMembersAndInvites: number = invites.length + totalUsers
          return (
            maxContributors !== UNLIMITED &&
            totalMembersAndInvites >= maxContributors
          )
        }
      )
    },
    isFreeOrFreeTrail() {
      return (state) => {
        const { data } = state.Plan.type
        return data === PlanType.Free || data === PlanType.FreeTrial
      }
    },
    isLoading() {
      return (state) => {
        return state.Plan.type.fetchStatus === 'none'
      }
    },
    isFreePlan() {
      return (state) => {
        return state.Plan.type.data === PlanType.Free
      }
    },
    hasTeamPlanOrUp() {
      return (state) => {
        const { data } = state.Plan.type
        return (
          data === PlanType.FreeTrial ||
          data === PlanType.Cloudguard ||
          data === PlanType.Team ||
          data === PlanType.Poc ||
          data === PlanType.Business ||
          data === PlanType.Enterprise
        )
      }
    },
    hasBusinessPlanOrUp() {
      return (state) => {
        const { data } = state.Plan.type
        return (
          data === PlanType.FreeTrial ||
          data === PlanType.Cloudguard ||
          data === PlanType.Poc ||
          data === PlanType.Business ||
          data === PlanType.Enterprise
        )
      }
    },
    hasEnterprisePlan() {
      return (state) => {
        const { data } = state.Plan.type
        return data === PlanType.Cloudguard || data === PlanType.Enterprise
      }
    },
    freeTrialAlertMessage() {
      return (state) => {
        return {
          isFreeTrial: state.Plan.type.data === PlanType.FreeTrial,
          daysUntilTrialExpire: state.Plan.daysUntilTrialExpire,
        }
      }
    },
    currentPlanType() {
      return (state) => {
        return state.Plan.type.data
      }
    },
    subscriptionMaxContributors() {
      return (state) => {
        return state.Plan.subscriptionMaxContributors
      }
    },
    currentPlanConfig() {
      return (state) => {
        return state.Plan.planConfig
      }
    },
    daysUntilTrialExpire() {
      return (state) => {
        return state.Plan.daysUntilTrialExpire
      }
    },
    usageData() {
      return (state) => {
        return state.Plan.usage
      }
    },
    products() {
      return (state) => {
        return state.Plan.planConfig?.products
      }
    },
  }),
}
