/* eslint-disable react/no-danger */
/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui'
import { Tooltip } from 'antd'
import { AiOutlineInfoCircle } from 'react-icons/ai'

export const Instructions = ({ instructions }) => (
  <Box sx={{ marginBottom: '30px' }}>
    {instructions.map((instruction) => {
      return (
        <Flex
          key={instruction.text}
          sx={{
            mb: instruction ? '20px' : '0',
            alignItems: 'center',
            marginBottom: '5px',
            gap: '10px',
          }}
        >
          <Box dangerouslySetInnerHTML={{ __html: instruction?.text }} />
          {instruction.tooltip && (
            <Tooltip title={instruction.tooltip}>
              <AiOutlineInfoCircle size={20} />
            </Tooltip>
          )}
        </Flex>
      )
    })}
  </Box>
)
