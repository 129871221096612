import Mustache from 'mustache'

export default class ScmUrlFormat {
  private urlFormat: string | null

  constructor(urlFormat: string | null) {
    this.urlFormat = urlFormat
    const isValid = this.validateFormatPlaceholders()
    if (!isValid) {
      throw new Error('Invalid format')
    }
  }

  public get format() {
    return this.urlFormat
  }

  private placeholderToIssueField = {
    assetUri: `metadata->>'assetUri'`,
    assetName: `metadata->>'assetName'`,
    start: '"start"::text',
    branch: '"originBranch"',
    path: '"path"',
    commitSha: '"commitSha"',
  }

  get allowedPlaceholders() {
    return Object.keys(this.placeholderToIssueField)
  }

  public buildUrlFromIssue(issue) {
    if (!this.urlFormat) {
      throw new Error('Cannot build url - format is null')
    }

    return Mustache.render(this.urlFormat, issue)
  }

  public validateFormatPlaceholders(): boolean {
    if (!this.urlFormat) {
      return true
    }

    const placeholders = this.urlFormat.match(/{{{[^}]*}}}/g)

    const allPlaceholdersAllowed = placeholders
      ? placeholders.every((placeholder) => {
          const key = placeholder.slice(3, -3)
          return this.allowedPlaceholders.includes(key)
        })
      : true

    if (placeholders && !allPlaceholdersAllowed) {
      return false
    }

    return true
  }

  public buildConcatDataSources() {
    if (!this.urlFormat) {
      throw new Error('Can not build concat - format is null')
    }

    // Isolating parts of the format
    const delimiter = '^^^'
    const uriFormatParts = this.urlFormat
      .replace(/{{{/g, delimiter)
      .replace(/}}}/g, delimiter)
      .split(delimiter)
      .filter((item) => {
        return item !== ''
      })

    // Build the concat statement
    const formatConcatParts = uriFormatParts.map((part: string) => {
      if (this.allowedPlaceholders.includes(part)) {
        return this.placeholderToIssueField[part]
      }
      return `'${part}'`
    })

    return formatConcatParts
  }
}
