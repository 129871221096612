import { Menu } from 'antd'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import {
  AssetsIcon,
  HiveIcon,
  ReportsIcon,
  SourcesIcon,
} from '../../assets/svg/menu-icons'

const getSelectedKeys = (currentPath) => [`/${currentPath.split('/')[1]}`]

export default ({ collapsed, location, links }) => {
  const hive = links.hive || '#'
  const assets = links.assets || '#'
  const sources = links.sources || '#'
  const reports = links.reports || '#'

  return (
    <StyledMenu
      mode="inline"
      inlineCollapsed={collapsed}
      selectedKeys={getSelectedKeys(location?.pathname)}
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['submenu-issues']}
    >
      <MenuItem key={hive} className="sp-menu-dashboard" icon={HiveIcon}>
        <NavLink className="e2e-test-sidebar-dashboard-button" to={hive}>
          Dashboard
        </NavLink>
      </MenuItem>
      <MenuItem key={assets} className="sp-menu-dashboard" icon={AssetsIcon}>
        <NavLink className="e2e-test-sidebar-assets-button" to={assets}>
          Assets
        </NavLink>
      </MenuItem>
      <MenuItem key={sources} className="sp-menu-sources" icon={SourcesIcon}>
        <NavLink className="e2e-test-sidebar-sources-button" to={sources}>
          Sources
        </NavLink>
      </MenuItem>
      <MenuItem key={reports} className="sp-menu-reports" icon={ReportsIcon}>
        <NavLink className="e2e-test-sidebar-reports-button" to={reports}>
          Reports
        </NavLink>
      </MenuItem>
    </StyledMenu>
  )
}

const MenuItem = styled(Menu.Item)``

const StyledMenu = styled(Menu)`
  &&&&& {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #762aeb;
      border-radius: 8px;
    }
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(118, 42, 235, 0.3);
    border-right: 0;
    margin: 20px 0 0 0px;
    background-color: transparent;
    transition-duration: 0.05s;

    .ant-menu-submenu-title {
      display: flex;
      background-color: transparent;
      transition-duration: 0.2s;
      color: #ffffffa8;
      margin-left: 0px;

      a {
        color: #ffffffa8;
      }

      .ant-menu-submenu-arrow {
        display: none;
      }

      .anticon {
        flex-shrink: 0;
        transition-duration: 0.2s;
        font-size: 46px;
        width: 46px;
        height: 46px;
        justify-content: center;
        align-items: center;
        svg {
          width: 38px;
          fill: none;
        }
      }
    }

    .ant-menu-submenu-title {
      padding: 0 17px;
    }

    .ant-menu-sub {
      background-color: transparent;
      align-items: center;
      transition-duration: 0.2s;
      font-size: 16px;

      .anticon {
        color: white;
        flex-shrink: 0;
        transition-duration: 0.2s;
        font-size: 6px;
        width: 6px;
        height: 46px;
        justify-content: center;
        align-items: center;
        svg {
          width: 5px;
          fill: none;
        }
      }
    }

    .ant-menu-item {
      display: flex;
      margin: 15px 0;
      height: 46px;
      background-color: transparent;
      padding: 0 17px;
      align-items: center;
      transition-duration: 0.2s;

      a {
        color: #ffffffa8;
      }
      &::after {
        border: 0;
      }

      .anticon {
        flex-shrink: 0;
        transition-duration: 0.2s;
        font-size: 46px;
        width: 46px;
        height: 46px;
        justify-content: center;
        align-items: center;
        svg {
          width: 38px;
          fill: none;
        }
      }
    }
    .ant-menu-item-selected {
      a {
        color: #fff;
      }
      span.anticon {
        background: rgba(0, 0, 0, 0.25);
        border-radius: 23px;
      }
      svg {
        color: #fff;
      }
    }
  }
`
