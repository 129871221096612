import React, { useState } from 'react'
import { Box, Flex } from 'theme-ui'
import { InputFieldSection } from '../../../../components/panels/content'
import { SampleIssue } from './url-formats-settings'
import useTemplateRender from './hooks/useTemplateRender'
import { isUrl } from '@spectral/types'

interface UrlFormatInputParams {
  name: string
  description: string
  placeholder: string
  label: string
  sampleIssue: SampleIssue
  initialValue: string
}

const UrlFormatInput = ({
  name,
  description,
  placeholder,
  label,
  sampleIssue,
  initialValue,
}: UrlFormatInputParams) => {
  const [uriFormat, setUriFormat] = useState<string>(initialValue)
  const [renderedUriFormat, setRenderedUriFormat] = useState<string>(uriFormat)

  useTemplateRender(uriFormat, sampleIssue, setRenderedUriFormat)

  return (
    <Box sx={{ height: '100px', pb: '20px' }}>
      <InputFieldSection
        key={name}
        name={name}
        label={label}
        description={description}
        merge
        disabled={false}
        placeholder={placeholder}
        customOnChange={(value: string) => setUriFormat(value)}
      />
      <Flex>
        <Box sx={{ width: '100%' }}></Box>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            fontSize: '12px',
            pl: '15px',
          }}
        >
          {sampleIssue && (
            <Box sx={{ position: 'absolute', top: '-15px' }}>
              {isUrl(renderedUriFormat) ? (
                <a
                  href={renderedUriFormat}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {renderedUriFormat}
                </a>
              ) : (
                <span>{renderedUriFormat}</span>
              )}
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default UrlFormatInput
