import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ApiKey from '../../../../blocks/settings/teams/api-key/view'

const ApiKeyConnected = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.Auth.user)

  useEffect(() => {
    if (!user) dispatch.Auth.me()
  }, [dispatch.Auth, user])

  return (
    <ApiKey
      apiKey={user.apiKey}
      onGenerateConfirm={dispatch.Auth.generateApiKey}
    />
  )
}

export default ApiKeyConnected
