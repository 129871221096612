import React from 'react'
import { Box } from 'theme-ui'
import { Tag, Typography } from 'antd'
import {
  dateWithoutTimeFormat,
  IgnoreSourceName,
  ReportIssueIgnoreDetails,
} from '@spectral/types'
import { format } from 'date-fns'

const { Text } = Typography

interface Props {
  ignoreDetails: ReportIssueIgnoreDetails
}

const IgnoreDetailsView = ({ ignoreDetails }: Props) => {
  if (!ignoreDetails.isIgnored) return null
  return (
    <Box>
      <Box sx={{ fontWeight: 'bold' }}> Ignore details:</Box>
      <Box>
        {ignoreDetails.comment && (
          <Text style={{ fontSize: '12px' }}>
            <strong>Comment</strong>: {ignoreDetails.comment}
          </Text>
        )}
      </Box>
      <Box>
        {ignoreDetails.source &&
          ignoreDetails.source !== IgnoreSourceName.Remote && (
            <Tag>
              <strong>Source</strong> <>{ignoreDetails.source}</>{' '}
            </Tag>
          )}
        <Tag>
          <strong>Category</strong> {ignoreDetails.ignoreType}
        </Tag>
        {ignoreDetails.sourcePath && (
          <Tag>
            <strong>Location</strong> <>{ignoreDetails.sourcePath}</>
          </Tag>
        )}
        {ignoreDetails.timeSnoozeOver && (
          <Tag>
            <strong>Until</strong>{' '}
            {format(
              new Date(ignoreDetails.timeSnoozeOver),
              dateWithoutTimeFormat
            )}
          </Tag>
        )}
        {ignoreDetails.actor && (
          <Tag>
            <strong>By</strong> {ignoreDetails.actor}
          </Tag>
        )}
      </Box>
    </Box>
  )
}

export default IgnoreDetailsView
