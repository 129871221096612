import { ModelConfig } from '@rematch/core'
import L from 'lodash'
import { SCMProvider } from '@spectral/types'
import { RootState, sdkClient } from '../../store'

type SCMsState = {
  github?: {
    onPremDomains: string[]
  }
  gitlab?: {
    onPremDomains: string[]
  }
}
const initialState: SCMsState = {
  github: {
    onPremDomains: [],
  },
  gitlab: {
    onPremDomains: [],
  },
}

export const scmConfigurations: ModelConfig<SCMsState> = {
  state: initialState,
  reducers: {
    setSCM(state: SCMsState, payload): SCMsState {
      return { state, ...payload }
    },
  },
  effects: (dispatch: any) => ({
    async fetchSCMConfigurations() {
      const data = (await sdkClient.teams().getSCMsConfigurations()) as any
      if (L.isEmpty(data)) {
        await dispatch.SCMConfigurations.setSCM(initialState)
      } else {
        await dispatch.SCMConfigurations.setSCM(data)
      }
    },
    async upsertSCMDomains(payload) {
      const {
        scm,
        domains,
      }: { scm: SCMProvider; domains: Array<string> } = payload

      await sdkClient.teams().setSCMOnPremDomains({
        data: {
          scm,
          domains,
        },
      })
      await dispatch.SCMConfigurations.fetchSCMConfigurations()
    },
    async upsertUrlFormats(payload) {
      try {
        const { formats } = payload
        const result: {
          error?: string
        } = await sdkClient.teams().setSCMUrlFormats({
          data: {
            formats,
          },
        })

        if (result.error) {
          return { success: false, error: result.error }
        }
        await dispatch.SCMConfigurations.fetchSCMConfigurations()
        return { success: true, error: null }
      } catch (e) {
        return {
          success: false,
          error: e.message || 'Failed to save templates, please try again',
        }
      }
    },
  }),
  selectors: (_slice, createSelector, _hasProps) => ({
    scms: () => {
      return createSelector(
        (rootState) => (rootState as RootState).SCMConfigurations,
        (scmsState: SCMsState) => scmsState
      )
    },
  }),
}
