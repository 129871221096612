import { DEFAULT_ENGINE, Engines } from '@spectral/types'
import { secretsTagsListByModeLevel } from './spectral-engine-scan-mode'
import { EnginesDefaultTags } from './consts'

enum ScannerOptions {
  IncludeTags = '--include-tags',
  EngineKinds = '--engines',
}

const optionValueSeparator: Record<ScannerOptions, string> = {
  [ScannerOptions.EngineKinds]: ',',
  [ScannerOptions.IncludeTags]: ',',
}

type CLIOptions = Record<ScannerOptions, Array<string>>

const getScannerOptionsByState = (
  engine: Engines,
  isChecked: boolean,
  additionalOptionsState
): CLIOptions => {
  switch (engine) {
    case Engines.Secrets:
      return {
        [ScannerOptions.EngineKinds]: isChecked ? [engine] : [],
        [ScannerOptions.IncludeTags]:
          isChecked && additionalOptionsState
            ? secretsTagsListByModeLevel(additionalOptionsState)
            : [],
      }
    case Engines.IaC:
      return {
        [ScannerOptions.EngineKinds]: isChecked ? [engine] : [],
        [ScannerOptions.IncludeTags]: isChecked
          ? [EnginesDefaultTags[engine]]
          : [],
      }
    case Engines.OpenSource:
      return {
        [ScannerOptions.EngineKinds]: isChecked ? [engine] : [],
        [ScannerOptions.IncludeTags]: [],
      }
    default:
      return {
        [ScannerOptions.EngineKinds]: [],
        [ScannerOptions.IncludeTags]: [],
      }
  }
}

export const generateOptionsString = (
  engines: { engine: Engines; isChecked: boolean; additionalOptionsState }[]
) => {
  const options: CLIOptions = {
    [ScannerOptions.EngineKinds]: [],
    [ScannerOptions.IncludeTags]: [],
  }

  engines.reduce(
    (accOptions, { engine, isChecked, additionalOptionsState }) => {
      const engineOptions = getScannerOptionsByState(
        engine,
        isChecked,
        additionalOptionsState
      )
      Object.values(ScannerOptions).forEach((scannerOption) => {
        accOptions[scannerOption] = accOptions[scannerOption].concat(
          ...engineOptions[scannerOption]
        )
      })
      return accOptions
    },
    options
  )

  // if a single engine is selected, and the only tag is the default tag, we don't need to specify it (same behavior as the CLI)
  if (
    options[ScannerOptions.EngineKinds].length === 1 &&
    options[ScannerOptions.IncludeTags].length === 1 &&
    options[ScannerOptions.IncludeTags][0] ===
      EnginesDefaultTags[options[ScannerOptions.EngineKinds][0]]
  ) {
    options[ScannerOptions.IncludeTags] = []
  }

  // if a single engine is selected, and the only engine kind is the default engine, we don't need to specify it (same behavior as the CLI)
  if (
    options[ScannerOptions.EngineKinds].length === 1 &&
    options[ScannerOptions.EngineKinds][0] === DEFAULT_ENGINE
  ) {
    options[ScannerOptions.EngineKinds] = []
  }

  const optionsStrList = Object.entries(options).map(([key, value]) => {
    return value.length > 0
      ? `${key} ${value.join(optionValueSeparator[key])}`
      : ''
  })

  return optionsStrList.join(' ')
}
