/** @jsx jsx */
import { jsx, Box, Flex, Text } from 'theme-ui'
import zxcvbn from 'zxcvbn'
import { Form, Input } from 'antd'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import {
  DialogPanel,
  PrimaryButtonLarge,
  WelcomeBar,
  SmallHeading,
  HGroup,
} from '../../components'
import ErrorMessage from '../../components/forms/error-message'
import Message from '../../components/forms/message'
import {
  PASSWORD_STRENGTH_STEPS,
  strengthToPercent,
} from '../../common/password-strength'
import PasswordStrengthBar from '../signup/password-strength-bar'

const RecoverFinish = ({
  onSubmit,
  links: { signin },
  error = null,
  message = null,
}) => {
  const [passwordStrength, setPasswordStrength] = useState(null)
  const onPasswordChange = (event) => {
    setPasswordStrength(
      event.target.value
        ? strengthToPercent(zxcvbn(event.target.value).score)
        : null
    )
  }

  return (
    <Box>
      <DialogPanel>
        <WelcomeBar />
        <Box sx={{ flex: 1 }}>
          <SmallHeading text="Complete your password reset" />
          <Box sx={{ p: 5 }}>
            {message && (
              <Box sx={{ mb: '20px' }}>
                <Message message={message} />
                <Box sx={{ my: '10px' }}>
                  You can now <Link to={signin}>sign in</Link>
                </Box>
              </Box>
            )}
            {error ? (
              <Box sx={{ mb: '20px' }}>
                <ErrorMessage error="You have an expired or bad reset details. Please send reset instructions again" />
              </Box>
            ) : (
              false
            )}
            {!error && (
              <Form layout="vertical" hideRequiredMark onFinish={onSubmit}>
                <Form.Item
                  name="password"
                  label={
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text mr={3}>Password</Text>
                      {passwordStrength !== null && (
                        <PasswordStrengthBar
                          strength={passwordStrength}
                          steps={PASSWORD_STRENGTH_STEPS}
                        />
                      )}
                    </Flex>
                  }
                  rules={[
                    {
                      max: 64,
                      message: 'Maximum 64 characters.',
                    },
                    () => ({
                      validateTrigger: 'blur',
                      validator() {
                        if (passwordStrength === 100) {
                          return Promise.resolve()
                        }
                        return Promise.reject('Password is too weak')
                      },
                    }),
                  ]}
                >
                  <Input.Password size="large" onChange={onPasswordChange} />
                </Form.Item>
                <Form.Item
                  name="passwordAgain"
                  label="Password (Again)"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please type your new password again',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        // eslint-disable-next-line
                        return Promise.reject(
                          'Your password do not match. Try again?'
                        )
                      },
                    }),
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item>
                <HGroup sx={{ mt: 5 }}>
                  <PrimaryButtonLarge text="Set New Password" isSubmit />
                </HGroup>
              </Form>
            )}
          </Box>
        </Box>
      </DialogPanel>
    </Box>
  )
}
export default RecoverFinish
