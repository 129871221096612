import tracker from './track'
import { isEmbedded } from './utils'

export default function beamerInitialize() {
  if (!window || isEmbedded()) {
    return
  }
  const beamerId = process.env.REACT_APP_BEAMER_ID
  if (beamerId) {
    window.beamer_config = {
      product_id: beamerId,
      selector: '.beamerTrigger',
      top: -7,
      right: -7,
      bounce: false,
      button_position: 'bottom-right',
      language: 'EN',
      onopen: () => {
        tracker.event('BELL_NOTIFICATION', {
          action: 'open',
        })
      },
      onclose: () => {
        tracker.event('BELL_NOTIFICATION', {
          action: 'close',
        })
      },
    }
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.defer = true
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js'
    head.appendChild(script)
  }
}
