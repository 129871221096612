export enum PageDirection {
  Next = 'next',
  Prev = 'prev',
}

export type PaginationResponseMetaCursor = {
  pageSize: number
  pageBeforeCursor: string | null
  pageAfterCursor: string | null
}
export type PaginationResponseMeta = {
  page: number
  pageSize: number
  total: number
}
