/** @jsx jsx */
import { Image, jsx } from 'theme-ui'
import { AssetType } from '@spectral/types'
import L from 'lodash'
import { Tooltip } from 'antd'
import hostIcon from '../../assets/png/host.png'
import jiraLogo from '../../assets/svg/jira.svg'
import confluenceLogo from '../../assets/svg/confluence.svg'
import s3Logo from '../../assets/svg/s3.png'
import slackLogo from '../../assets/svg/slack.svg'
import gitLogo from '../../assets/png/git.png'
import tfcIcon from '../../assets/png/tfc.png'

const ASSET_TYPE_TO_ICON = {
  [AssetType.S3]: s3Logo,
  [AssetType.Confluence]: confluenceLogo,
  [AssetType.Jira]: jiraLogo,
  [AssetType.Slack]: slackLogo,
  [AssetType.Host]: hostIcon,
  [AssetType.Git]: gitLogo,
  [AssetType.Automation]: tfcIcon,
}

const AssetTypeIcon = ({
  type,
  width = '20px',
  height = '20px',
  minWidth = '20px',
  minHeight = '20px',
}) => {
  return (
    <Tooltip title={L.capitalize(type)}>
      {ASSET_TYPE_TO_ICON[type] && (
        <Image
          sx={{
            width,
            height,
            minWidth,
            minHeight,
            mr: '2px',
          }}
          src={ASSET_TYPE_TO_ICON[type]}
        />
      )}
    </Tooltip>
  )
}

export default AssetTypeIcon
