import { ResponsiveBar } from '@nivo/bar'
import React, { useState } from 'react'
import L from 'lodash'
import { Box, Flex, Text } from 'theme-ui'
import { Radio } from 'antd'
import {
  FilterOutlined,
  BarChartOutlined,
  LineChartOutlined,
} from '@ant-design/icons'
import { ResponsiveLine } from '@nivo/line'
import { colors, nivoTheme } from './nivo-theme'
import { ContentPanel, ContentPanelHeader } from '../panels/content'
import TooltipBox from './tooltip'
import { DropDown } from '../select'
import { Loading } from '../loading'
import EmptyState from './empty-state'

export const ASSET_STATUS = {
  WITH_ISSUES: 'With issues',
  WITHOUT_ISSUES: 'Without issues',
  DIDNT_RUN: "Didn't run",
}

const COLORS = {
  [ASSET_STATUS.WITH_ISSUES]: colors.assetResultsOverTime.issuesFoundColor,
  [ASSET_STATUS.WITHOUT_ISSUES]: colors.assetResultsOverTime.noIssuesFoundColor,
  [ASSET_STATUS.DIDNT_RUN]: colors.assetResultsOverTime.didntRunColor,
}

const toAssetsOverTimeCol = ({
  date,
  displayDate,
  WITH_ISSUES,
  WITHOUT_ISSUES,
  DIDNT_RUN,
}) => ({
  date,
  displayDate,
  [ASSET_STATUS.WITH_ISSUES]: WITH_ISSUES,
  [ASSET_STATUS.WITHOUT_ISSUES]: WITHOUT_ISSUES,
  [ASSET_STATUS.DIDNT_RUN]: DIDNT_RUN,
})

const toAssetsOverTimeLine = ({ data, id }) => ({
  data,
  id: ASSET_STATUS[id],
})

const BarItem = ({
  data,
  x,
  y,
  width,
  height,
  borderRadius,
  color,
  borderWidth,
  borderColor,
  label,
  shouldRenderLabel,
  labelColor,
  showTooltip,
  hideTooltip,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltip,
  theme,
}) => {
  const handleTooltip = (e) =>
    showTooltip(
      <TooltipBox>
        {data.indexValue}: <strong>{label}</strong>&nbsp;{data.id}
      </TooltipBox>,
      e
    )
  const handleMouseEnter = (e) => {
    onMouseEnter(data, e)
    showTooltip(tooltip, e)
  }
  const handleMouseLeave = (e) => {
    onMouseLeave(data, e)
    hideTooltip(e)
  }
  return (
    <g transform={`translate(${x}, ${y})`}>
      <rect
        width={width}
        height={height}
        rx={borderRadius}
        ry={borderRadius}
        fill={data.fill ? data.fill : color}
        strokeWidth={borderWidth}
        stroke={borderColor}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
        onClick={onClick ? () => onClick(data) : null}
        style={{
          cursor: onClick ? 'pointer' : 'default',
        }}
      />
      {shouldRenderLabel && (
        <text
          x={width / 2}
          y={height / 2}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            ...theme.labels.text,
            pointerEvents: 'none',
            fill: labelColor,
            cursor: onClick ? 'pointer' : 'default',
          }}
        >
          {label}
        </text>
      )}
    </g>
  )
}

const Filters = ({ children }) => (
  <Flex
    sx={{
      p: 3,
      backgroundColor: '#f7fafc',
      border: '1px solid #a0aec0',
      alignItems: 'center',
      height: '50px',
      marginBottom: '15px',
    }}
  >
    <Box sx={{ pr: 3, mr: 3, borderRight: '1px solid #E2E8F0' }}>
      <FilterOutlined />
    </Box>
    {children}
  </Flex>
)

const detectorIdSort = (a, b) => {
  if (a === 'Others') {
    return -1
  }
  if (b === 'Others') {
    return 1
  }
  return a.localeCompare(b)
}

const OverTime = ({
  data,
  orgTeams,
  allDetectors,
  sourcesEmptyState,
  onFilterChanged,
  filters,
}) => {
  const [visibleSeries, setVisibleSeries] = useState({
    [ASSET_STATUS.WITH_ISSUES]: true,
    [ASSET_STATUS.WITHOUT_ISSUES]: true,
    [ASSET_STATUS.DIDNT_RUN]: true,
  })
  const [visualIssuesChart, setVisualIssuesChart] = useState('line')
  const [visualAssetsChart, setVisualAssetsChart] = useState('line')
  const [filteringInProgress, setFilteringInProgress] = useState(null)

  const assetStatusKeys = L(visibleSeries)
    .toPairs()
    .filter((seriesKey) => seriesKey[1])
    .map((seriesKey) => seriesKey[0])
    .value()

  const onLegendItemClicked = (seriesKey) => {
    setVisibleSeries({
      ...visibleSeries,
      [seriesKey]: !visibleSeries[seriesKey],
    })
  }
  const onTeamsFilterChange = async (newSelectedTeams) => {
    setFilteringInProgress('teams')
    await onFilterChanged({
      teams: newSelectedTeams,
    })
    setFilteringInProgress(null)
  }
  const onDetectorsFilterChange = async (newSelectedDetectors) => {
    setFilteringInProgress('detectors')
    await onFilterChanged({
      detectors: newSelectedDetectors,
    })
    setFilteringInProgress(null)
  }

  const onIssuesChartTypeChange = (e) => {
    setVisualIssuesChart(e.target.value)
  }

  const onAssetsChartTypeChange = (e) => {
    setVisualAssetsChart(e.target.value)
  }

  const detectorKeys = L(data?.issuesOverTimeBars)
    .map(L.keys)
    .flatten()
    .uniq()
    .sort(detectorIdSort)
    .pull('date', 'displayDate')
    .value()

  // @ts-ignore
  return (
    <ContentPanel>
      <ContentPanelHeader>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Text color="contentPanelHeaderText">Trends</Text>

          <Flex
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Box sx={{ pr: 2, mx: 2, borderRight: '1px solid #E2E8F0' }}>
              <FilterOutlined />
            </Box>
            <Box sx={{ ml: '10px', width: '300px' }}>
              <DropDown
                placeholder="Teams"
                mode="multiple"
                maxTagCount="responsive"
                value={filters.teams}
                allowClear
                onChange={onTeamsFilterChange}
                disabled={L.isEmpty(orgTeams)}
                options={orgTeams.map((ot) => ot.name)}
              />
            </Box>
          </Flex>
        </Flex>
      </ContentPanelHeader>
      <Box sx={{ p: '24px', height: '400px' }}>
        {!L.isEmpty(data?.assetsOverTimeBars) ? (
          <>
            <Filters>
              {L.map(ASSET_STATUS, (name) => (
                <Flex
                  sx={{
                    alignContent: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    opacity: visibleSeries[name] ? 1 : 0.5,
                  }}
                  key={name}
                  onClick={() => onLegendItemClicked(name)}
                >
                  <Box
                    sx={{
                      backgroundColor: COLORS[name],
                      width: '14px',
                      height: '14px',
                      border: 'outline',
                      mr: 1,
                      position: 'relative',
                      top: '2px',
                    }}
                  />
                  <Text
                    sx={{
                      mr: 3,
                      fontSize: '12px',
                    }}
                  >
                    {name}
                  </Text>
                </Flex>
              ))}
              <Box sx={{ ml: 'auto' }}>
                <Radio.Group defaultValue="line" size="small">
                  <Radio.Button value="line" onChange={onAssetsChartTypeChange}>
                    <LineChartOutlined />
                  </Radio.Button>
                  <Radio.Button value="bar" onChange={onAssetsChartTypeChange}>
                    <BarChartOutlined />
                  </Radio.Button>
                </Radio.Group>
              </Box>
            </Filters>
            {filteringInProgress === 'teams' ? (
              <Loading />
            ) : visualAssetsChart === 'bar' ? (
              <ResponsiveBar
                data={L.map(data?.assetsOverTimeBars, toAssetsOverTimeCol)}
                keys={assetStatusKeys}
                labelSkipHeight={12}
                margin={{ top: 20, right: 50, bottom: 50, left: 60 }}
                padding={0.2}
                labelSkipWidth={22}
                indexBy="displayDate"
                barComponent={BarItem}
                theme={nivoTheme}
                colors={({ id }) => COLORS[id]}
                labelTextColor={colors.assetResultsOverTime.labelTextColor}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -25,
                }}
                axisLeft={{
                  legend: 'No. of assets',
                  legendPosition: 'middle',
                  legendOffset: -50,
                  format: () => null,
                }}
              />
            ) : (
              <ResponsiveLine
                data={data?.assetsOverTimeLines
                  .map(toAssetsOverTimeLine)
                  .filter((line) => assetStatusKeys.includes(line.id))}
                colors={({ id }) => COLORS[id]}
                theme={nivoTheme}
                margin={{ top: 20, right: 30, bottom: 80, left: 60 }}
                xScale={{
                  type: 'time',
                  format: '%Y-%m-%d',
                  useUTC: false,
                  precision: 'day',
                }}
                xFormat="time:%Y-%m-%d"
                yFormat={(value) => `${value}`}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: 'auto',
                  stacked: true,
                }}
                axisLeft={{
                  legend: 'No. of assets',
                  legendPosition: 'middle',
                  legendOffset: -50,
                }}
                axisBottom={{
                  format: '%d.%m',
                  tickValues: 'every day',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -25,
                }}
                curve="monotoneX"
                axisTop={null}
                axisRight={null}
                enableGridX={false}
                pointSize={3}
                pointSymbol={({ size, color, borderWidth, borderColor }) => (
                  <g>
                    <circle
                      fill={color}
                      r={size}
                      strokeWidth={borderWidth}
                      stroke={borderColor}
                    />
                    <circle
                      r={size / 1.5}
                      strokeWidth={borderWidth}
                      stroke={borderColor}
                      fill="#fff"
                    />
                  </g>
                )}
                useMesh
                legends={[]}
                enableSlices={false}
                tooltip={({ point }) => {
                  return (
                    <Box
                      style={{
                        background: 'white',
                        padding: '9px 12px',
                        border: '1px solid #ccc',
                      }}
                    >
                      <Box
                        key={point.id}
                        style={{
                          color: point.serieColor,
                          padding: '3px 0',
                        }}
                      >
                        <strong>{point.serieId}</strong> -{' '}
                        {point.data.yFormatted}
                      </Box>
                    </Box>
                  )
                }}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </Box>
      <Box sx={{ p: '24px', marginTop: '20px', height: '400px' }}>
        <Filters>
          <Box sx={{ width: 300 }}>
            <DropDown
              placeholder="Detectors"
              mode="multiple"
              allowClear
              maxTagCount="responsive"
              value={filters.detectors}
              onChange={onDetectorsFilterChange}
              disabled={L.isEmpty(allDetectors)}
              options={allDetectors}
            />
          </Box>
          <Box sx={{ ml: 'auto' }}>
            <Radio.Group defaultValue="line" size="small">
              <Radio.Button value="line" onChange={onIssuesChartTypeChange}>
                <LineChartOutlined />
              </Radio.Button>
              <Radio.Button value="bar" onChange={onIssuesChartTypeChange}>
                <BarChartOutlined />
              </Radio.Button>
            </Radio.Group>
          </Box>
        </Filters>
        {!L.isEmpty(data?.issuesOverTimeBars) ? (
          <>
            {filteringInProgress ? (
              <Loading />
            ) : visualIssuesChart === 'bar' ? (
              <ResponsiveBar
                data={data?.issuesOverTimeBars}
                keys={detectorKeys}
                labelSkipHeight={12}
                margin={{ right: 30, bottom: 80, left: 60, top: 20 }}
                padding={0.2}
                onClick={null}
                labelSkipWidth={22}
                indexBy="displayDate"
                barComponent={BarItem}
                theme={nivoTheme}
                colors={({ id, data: itemData }) => {
                  const keys = L(itemData)
                    .keys()
                    .sort(detectorIdSort)
                    .pull('date', 'displayDate')
                    .value()
                  return colors.issuesOverTime[L.indexOf(keys, id)]
                }}
                labelTextColor={colors.assetResultsOverTime.labelTextColor}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -25,
                }}
                axisLeft={{
                  legend: 'No. of issues',
                  legendPosition: 'middle',
                  legendOffset: -50,
                  format: () => null,
                }}
              />
            ) : (
              <ResponsiveLine
                data={data?.issuesOverTimeLines}
                colors={colors.issuesOverTime}
                theme={nivoTheme}
                margin={{ top: 20, right: 30, bottom: 80, left: 60 }}
                xScale={{
                  type: 'time',
                  format: '%Y-%m-%d',
                  useUTC: false,
                  precision: 'day',
                }}
                xFormat="time:%Y-%m-%d"
                yFormat={(value) => `${value}`}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: 'auto',
                  stacked: true,
                }}
                axisLeft={{
                  legend: 'No. of issues',
                  legendPosition: 'middle',
                  legendOffset: -50,
                }}
                axisBottom={{
                  format: '%d.%m',
                  tickValues: 'every day',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -25,
                }}
                curve="monotoneX"
                axisTop={null}
                axisRight={null}
                enableGridX={false}
                pointSize={3}
                pointSymbol={({ size, color, borderWidth, borderColor }) => (
                  <g>
                    <circle
                      fill={color}
                      r={size}
                      strokeWidth={borderWidth}
                      stroke={borderColor}
                    />
                    <circle
                      r={size / 1.5}
                      strokeWidth={borderWidth}
                      stroke={borderColor}
                      fill="#fff"
                    />
                  </g>
                )}
                useMesh
                legends={[]}
                enableSlices="x"
                sliceTooltip={({ slice }) => {
                  return (
                    <Box
                      style={{
                        background: 'white',
                        padding: '9px 12px',
                        border: '1px solid #ccc',
                      }}
                    >
                      {slice.points.map((point) => (
                        <Box
                          key={point.id}
                          style={{
                            color: point.serieColor,
                            padding: '3px 0',
                          }}
                        >
                          <strong>{point.serieId}</strong> -{' '}
                          {point.data.yFormatted}
                        </Box>
                      ))}
                    </Box>
                  )
                }}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </Box>
    </ContentPanel>
  )
}
export default OverTime
