import { Collapse } from 'antd'
import React from 'react'
import { Box } from 'theme-ui'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import FilterDivider from './filter-divider'

function FilterCollapse({
  children,
  isDividerShown,
  header,
  filterKey,
  collapsible,
}) {
  return (
    <Box
      className="side-tree-filter"
      sx={{
        transition: 'max-height 0.25s ease',
        overflow: 'hidden',
        '& .ant-collapse-header': {
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          justifyContent: 'space-between',
          '&.ant-collapse-header': {
            py: '0',
            cursor: !collapsible && 'default',
          },
          '.ant-collapse-arrow.ant-collapse-arrow': {
            position: 'static',
            pt: 0,
          },
        },
        '&& .ant-collapse-header, .ant-collapse-content-box': {
          px: 0,
        },
      }}
    >
      <Collapse
        expandIconPosition="right"
        ghost
        defaultActiveKey={!collapsible && filterKey}
        expandIcon={({ isActive }) => {
          if (!collapsible) {
            return null
          }
          if (isActive) {
            return <UpOutlined />
          }
          return <DownOutlined />
        }}
      >
        <Collapse.Panel
          header={header}
          key={filterKey}
          collapsible={!collapsible ? 'disabled' : undefined}
        >
          {children}
        </Collapse.Panel>
      </Collapse>
      {isDividerShown && <FilterDivider />}
    </Box>
  )
}

export default FilterCollapse
