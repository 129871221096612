export const throwIfErrorsOrInvalid = (responseBody) => {
  if (responseBody) {
    // eslint-disable-next-line prettier/prettier
    const validationError = responseBody.validation_errors?.[0]?.message
    if (validationError) {
      throw new Error(validationError)
    }
    const generalErrors = responseBody.error
    if (generalErrors) {
      throw new Error(generalErrors)
    }
  }
}
