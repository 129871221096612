import { Button } from 'antd'
import { format } from 'date-fns'
import L from 'lodash'
import React from 'react'
import * as Yup from 'yup'
import { dateFormat } from '@spectral/types'
import { MondayOption } from '../monday-option'
import {
  CustomFormComponent,
  FieldSectionLayout,
  InputFieldSection,
  PanelForm,
  SelectFieldSection,
  SubmitSection,
  TextFieldSection,
} from '../../../components/panels/content'

const toGroupOptions = (board) => {
  if (!board) return []
  return L.map(board.groups, (group) => ({
    value: group.id,
    name: group.title,
    key: `group_${group.id}`,
  }))
}

type AdditionalField = {
  field: string
  label: string
  field_id: string
  value: string
  name: string
}

const additionalIssueFields = [
  {
    field: 'severity',
    label: 'Severity',
    pathInIssueObj: 'severity',
    mondayAllowedColumnTypes: ['text', 'label'],
    possibleMondayColumnNames: ['severity'],
    valueFormatter: null,
  },
  {
    field: 'asset',
    label: 'Asset',
    pathInIssueObj: 'asset.displayName',
    mondayAllowedColumnTypes: ['text'],
    possibleMondayColumnNames: ['asset'],
    valueFormatter: null,
  },
  {
    field: 'content',
    label: 'Content',
    pathInIssueObj: 'content',
    mondayAllowedColumnTypes: ['text'],
    possibleMondayColumnNames: ['content'],
    valueFormatter: null,
  },
  {
    field: 'firstSeen',
    label: 'First seen',
    pathInIssueObj: 'firstSeen',
    valueFormatter: (val) => format(new Date(val), dateFormat),
    mondayAllowedColumnTypes: ['date'],
    possibleMondayColumnNames: ['first seen', 'first-seen', 'firstseen'],
  },
]

const getAdditionalData = (issue, board): AdditionalField[] => {
  if (!(issue && board)) {
    return []
  }
  const boardColumns = board.columns
  if (!boardColumns) return []

  const columnsToAdd = L(additionalIssueFields).reduce((acc, issueField) => {
    const column = L(boardColumns)
      .filter((col) => {
        return (
          issueField.possibleMondayColumnNames.includes(
            L.lowerCase(col.title.trim())
          ) && issueField.mondayAllowedColumnTypes.includes(col.type)
        )
      })
      .first()
    if (column) {
      const value = L.get(issue, issueField.pathInIssueObj)
      acc.push({
        label: issueField.label,
        field_id: column.id,
        name: column.title,
        value: issueField.valueFormatter
          ? issueField.valueFormatter(value)
          : value,
      })
      return acc
    }
    return acc
  }, [])
  return columnsToAdd
}

export const MondayCreateItemContent = ({
  issue,
  description,
  descriptionPostfix,
  onFinish,
  isItemCreationInProgress,
  board,
}) => {
  if (!board) return null

  const groupOptions = toGroupOptions(board)
  const groupId = groupOptions[0]?.value
  const additionalData = getAdditionalData(issue, board)

  const initialValues = {
    description,
    groupId,
  }

  L(additionalData).forEach((ad) => {
    initialValues[ad.field_id] = ad.value
  })

  // eslint-disable-next-line no-nested-ternary
  return (
    <fieldset disabled={isItemCreationInProgress}>
      <PanelForm
        onSubmit={(values) =>
          onFinish({ ...values, boardId: board.id }, descriptionPostfix)
        }
        initialValues={initialValues}
        validationSchema={Yup.object({
          groupId: Yup.string().required('Required'),
          summary: Yup.string().required('Required'),
        })}
      >
        <SelectFieldSection
          name="groupId"
          label="Group"
          placeholder="Group"
          layout={FieldSectionLayout.Vertical}
          options={groupOptions}
          filterOption={(input, option) =>
            option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onRenderOption={({ name }) => <MondayOption label={name} />}
          required
          merge
        />
        <InputFieldSection
          name="summary"
          label="Summary"
          allowClear
          layout={FieldSectionLayout.Vertical}
          placeholder="Summary"
          required
          merge
        />
        <CustomFormComponent>
          {(setFieldValue, setFieldTouched, validateForm) => (
            <Button
              type="link"
              style={{ marginLeft: '8px', fontSize: '14px' }}
              onClick={async () => {
                await setFieldValue(
                  'summary',
                  `Spectral | ${issue.asset.id} (${L.last(
                    issue.filePath.split('/')
                  )}) - ${issue.detectorName} (${issue.detectorId})`
                )
                await setFieldTouched('summary')
                validateForm()
              }}
              size="small"
            >
              Use recommended summary template
            </Button>
          )}
        </CustomFormComponent>
        <TextFieldSection
          name="description"
          label="Description"
          height={300}
          merge
          layout={FieldSectionLayout.Vertical}
        />
        {additionalData.map((ad) => {
          return (
            <InputFieldSection
              name={ad.field_id}
              label={ad.label}
              merge
              layout={FieldSectionLayout.Vertical}
              disabled
            />
          )
        })}
        <SubmitSection merge text="Create" />
      </PanelForm>
    </fieldset>
  )
}

const MondayCreateItemModalView = ({
  issue,
  onFinish,
  isItemCreationInProgress,
  board,
  description,
  descriptionPostfix,
}) => {
  return (
    <MondayCreateItemContent
      issue={issue}
      onFinish={onFinish}
      isItemCreationInProgress={isItemCreationInProgress}
      descriptionPostfix={descriptionPostfix}
      description={description}
      board={board}
    />
  )
}
export default MondayCreateItemModalView
