/** @jsx jsx */
import { Radio, Card, Button } from 'antd'
import { Box, Flex, jsx } from 'theme-ui'
import { Link } from 'react-router-dom'
import { BASE_URL, BASE_URL_HOST, BASE_URL_PROTOCOL } from '../../common/utils'
import { paths } from '../../routes'
import DownloadSnippet from './download-snippet'
import SnippetsViewer from './snippets-viewer'
import { imageMap } from '../../common/sources/imageMap'
import { PrimaryButtonLarge } from '../../components'
import { supportWidget } from '../../common/support-widget'
import { WizardMode } from '../sources-wizard/types'
import { OperatingSystem } from '../../common/sources/enums'
import IntegrationWrapper from './integration-wrapper'
import { MessageBox, MessageBoxVariant } from '../../components/messageBox'
import PersonalTokenAlert from './personal-token-alert'

const SnippetWindow = ({
  mode,
  scanKey,
  dsn,
  operatingSystem,
  digestLinks,
  setOperatingSystem,
  dataSource,
  downloadSnippet = null,
  onCopy = null,
  showHelpButton = false,
  isScansSettingHidden = false,
  shouldShowTeamToken,
  onSwitchTokenShown,
}) => {
  const filteredDigestLinks =
    operatingSystem === OperatingSystem.MACLINUX
      ? {
          darwin: digestLinks.darwin,
          linux: digestLinks.linux,
        }
      : {
          win: digestLinks.win,
        }
  return (
    <IntegrationWrapper
      title={
        mode === WizardMode.audit
          ? `Audit with ${dataSource.title}`
          : `Set up with ${dataSource.title}`
      }
      imgSrc={imageMap[dataSource?.tileKey]}
      extraOnRight={
        (mode === WizardMode.audit || mode === WizardMode.discover) && (
          <Flex>
            <Radio.Group
              onChange={setOperatingSystem}
              value={operatingSystem}
              optionType="button"
              options={[
                { label: 'Mac/Linux', value: OperatingSystem.MACLINUX },
                { label: 'Windows', value: OperatingSystem.WINDOWS },
              ]}
            />
          </Flex>
        )
      }
    >
      {dataSource.selfHostedRelevantProviders?.length > 0 && (
        <MessageBox variant={MessageBoxVariant.Tip}>
          <Box>
            If the Git server that is hosting the code is{' '}
            <strong>
              on-premise ({dataSource.selfHostedRelevantProviders.join('/')}){' '}
            </strong>
            you should configure the domain name of the server in the{' '}
            <Link to={paths.closed.settingsSCM}> Settings section </Link>
            before scanning.
          </Box>
          <Box sx={{ mb: '15px' }}>
            If not configured, investigate capabilities will be limited for the{' '}
            findings of the scan.
          </Box>
        </MessageBox>
      )}
      {dataSource.customInto && (
        <MessageBox variant={MessageBoxVariant.Info}>
          <Box sx={{ mb: '15px' }}>{dataSource.customInto}</Box>
        </MessageBox>
      )}
      {shouldShowTeamToken && (
        <Box sx={{ my: 4 }}>
          <PersonalTokenAlert onSwitch={onSwitchTokenShown} />
        </Box>
      )}
      {downloadSnippet && (
        <DownloadSnippet
          download={downloadSnippet}
          dynamicData={{
            scanKey,
            baseUrl: BASE_URL,
            digestLinks: filteredDigestLinks,
          }}
          onCopy={onCopy}
        />
      )}
      {dataSource?.snippets && (
        <Box>
          <Card title="Scan">
            <SnippetsViewer
              dynamicData={{
                scanKey,
                dsn,
                baseUrl: BASE_URL,
                baseUrlProtocol: BASE_URL_PROTOCOL,
                baseUrlHost: BASE_URL_HOST,
                digestLinks,
              }}
              instructions={dataSource?.instructions}
              snippets={dataSource.snippets}
              onCopy={onCopy}
              isScansSettingHidden={isScansSettingHidden}
            />
          </Card>
        </Box>
      )}
      <Flex sx={{ mt: '40px', justifyContent: 'center' }}>
        <Link to={paths.closed.assets} sx={{ mr: 3 }}>
          <PrimaryButtonLarge text="I'm done - View my results" />
        </Link>
        {showHelpButton && (
          <Button size="large" onClick={() => supportWidget.open()}>
            Talk to an expert
          </Button>
        )}
      </Flex>
    </IntegrationWrapper>
  )
}

export default SnippetWindow
