/** @jsx jsx */
import { jsx, Text, Box } from 'theme-ui'
import { Card, Modal } from 'antd'
import { AiOutlineDelete } from 'react-icons/ai'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import IntegrationsIcons from './integrations-icons'

const confirmRemove = ({ team, onDelete }) => {
  Modal.confirm({
    title: 'Delete team',
    icon: <ExclamationCircleOutlined />,
    content: (
      <Box>
        <Text>
          Are you sure you want to delete <strong>{team.name}</strong>?
        </Text>
      </Box>
    ),
    okText: 'Delete Team',
    onOk: () => onDelete({ orgTeams: [team] }),
    okButtonProps: { danger: true },
    cancelText: 'Cancel',
  })
}

export const TeamCard = ({ orgTeam, integrations, onDelete, editOrgTeam }) => (
  <Card
    title={<strong>{orgTeam.name}</strong>}
    extra={
      <AiOutlineDelete
        style={{ cursor: 'pointer' }}
        key="remove"
        onClick={() => confirmRemove({ team: orgTeam, onDelete })}
      />
    }
    key={orgTeam.orgTeamId}
    actions={[
      <Text sx={{ color: 'primary' }} onClick={() => editOrgTeam(orgTeam)}>
        Edit team settings
      </Text>,
    ]}
  >
    <Card.Meta
      title={<Text>Integrations:</Text>}
      description={
        <IntegrationsIcons
          orgTeam={orgTeam}
          globalIntegrations={integrations}
        />
      }
    />
  </Card>
)
