import { LoginType } from '@spectral/types'
import { useCallback, useState } from 'react'
import { BASE_URL_HOST, BASE_URL_PROTOCOL } from '../common/utils'

const DSN = ({ scanKey }) => `${BASE_URL_PROTOCOL}//${scanKey}@${BASE_URL_HOST}`

const useDSN = (teamKey: string, userApiKey: string, loginType: LoginType) => {
  const toScanKey = useCallback(
    (isTeamTokenShown = false) =>
      loginType === LoginType.Team && isTeamTokenShown ? teamKey : userApiKey,
    [loginType, teamKey, userApiKey]
  )

  const [scanKey, setScanKey] = useState(toScanKey())

  const handleTokenShownChanged = useCallback(
    (isTeamTokenShown) => {
      setScanKey(toScanKey(isTeamTokenShown))
    },
    [toScanKey]
  )

  return {
    dsn: DSN({ scanKey }),
    scanKey,
    setIsTeamTokenShown: handleTokenShownChanged,
  }
}

export default useDSN
