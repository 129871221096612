/** @jsx jsx */

import { Box, jsx, Text, Flex } from 'theme-ui'
import { NoPublicScanAudit, NoPublicScanMitigate } from '../mode-buttons'
import { WizardMode } from '../types'

const selected = {
  border: '1px solid #fff',
  boxShadow: 'tileFocused',
}
const tile = {
  p: '20px',
  pt: '30px',
  my: '10px',
  borderRadius: '4px',
  cursor: 'pointer',
  background: 'white',
  border: 'outline',
  ':hover': selected,
}
const Modes = ({ onModeSelect, showModes }) => {
  return (
    <Box>
      <Box sx={{ textAlign: 'center', mb: '80px' }}>
        <Text variant="wizardheading">What do you want to do?</Text>
        <Text variant="wizardsub">
          You can scan your entire organization, or protect your code in real
          time
        </Text>
      </Box>
      <Flex sx={{ justifyContent: 'center' }}>
        <Box>
          {showModes.some((mode) => mode === WizardMode.mitigate) && (
            <Box sx={tile} onClick={() => onModeSelect(WizardMode.mitigate)}>
              <NoPublicScanMitigate />
            </Box>
          )}
          {showModes.some((mode) => mode === WizardMode.audit) && (
            <Box sx={tile} onClick={() => onModeSelect(WizardMode.audit)}>
              <NoPublicScanAudit />
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  )
}
export default Modes
