/** @jsx jsx */
import { AssetCategory } from '@spectral/types'
import { Radio, Tabs } from 'antd'
import { useCallback, useState } from 'react'
import L from 'lodash'
import { useHistory } from 'react-router-dom'
import { Box, Grid, jsx } from 'theme-ui'
import tracker from '../../common/track'
import EmptyState from '../../components/reports/empty-state'
import HotspotChart from '../../components/reports/hotspot-chart'
import { colors } from '../../components/reports/nivo-theme'
import OverTime from '../../components/reports/over-time'
import PieChart from '../../components/reports/pie-chart'
import { paths } from '../../routes'
import Stats from './stats'

const { TabPane } = Tabs

const HOTSPOT_RESULTS_LIMIT = 5
const SOURCES_RESULTS_LIMIT = 8

enum CodeIssuesTableData {
  IssuesOnly,
  IacOnly,
  DiscoverIssuesOnly,
  OpenSourceIssuesOnly,
  Total,
}

const CodeIssuesTableDataToLabel: Record<CodeIssuesTableData, string> = {
  [CodeIssuesTableData.IssuesOnly]: 'Secrets',
  [CodeIssuesTableData.IacOnly]: 'IaC',
  [CodeIssuesTableData.DiscoverIssuesOnly]: 'CI/CD hardening',
  [CodeIssuesTableData.OpenSourceIssuesOnly]: 'OpenSource',
  [CodeIssuesTableData.Total]: 'Total',
}

const toTopIssuesCountAssets = (data) =>
  L(data)
    .map(({ issuesCount, displayName, kind, assetId }) => ({
      kind,
      count: issuesCount,
      id: displayName,
      assetId,
    }))
    .reverse()
    .take(HOTSPOT_RESULTS_LIMIT)
    .value()

const toTopRules = (data) =>
  L(Object.entries(data))
    .map(([ruleId, value]: [string, any]) => ({
      id: value.detectorName,
      count: value.count,
      ruleId,
    }))
    .sortBy('count')
    .reverse()
    .take(HOTSPOT_RESULTS_LIMIT)
    .value()

const toTopTeams = (data) =>
  L(Object.entries(data))
    .map(([orgTeam, issuesCount]) => ({
      id: orgTeam,
      count: issuesCount as number,
    }))
    .sortBy('count')
    .reverse()
    .take(HOTSPOT_RESULTS_LIMIT)
    .value()

const toSeverityPieDataWithColor = (severityPieData) =>
  severityPieData.map((severityData) => ({
    ...severityData,
    color: colors.severityPie[severityData.id],
  }))

const TabView = ({
  data,
  links: { sources },
  filters,
  onFilterChanged,
  orgTeams,
  category,
}) => {
  const history = useHistory()
  const [
    selectedSeverityAggregationType,
    setSelectedSeverityAggregationType,
  ] = useState(CodeIssuesTableData.IssuesOnly)

  const setSeverityAggregationType = (type) => {
    tracker.event('REPORTS_SEVERITY_AGGREGATION_TYPE', {
      type: CodeIssuesTableDataToLabel[type],
    })
    setSelectedSeverityAggregationType(type)
  }

  const handleHSTopAsset = useCallback(
    async (itemData) => {
      tracker.event('REPORTS_TOP_ASSETS_WITH_ISSUES_DRILLDOWN', {
        asset: itemData.id,
      })
      history.push(paths.closed.asset(encodeURIComponent(itemData.assetId)))
    },
    [history]
  )

  const severityPieData = {
    [CodeIssuesTableData.IssuesOnly]: data?.severityPie,
    [CodeIssuesTableData.IacOnly]: data?.severityPieIac,
    [CodeIssuesTableData.DiscoverIssuesOnly]: data?.severityPieDiscoverIssues,
    [CodeIssuesTableData.OpenSourceIssuesOnly]:
      data?.severityPieOpenSourceIssues,
    [CodeIssuesTableData.Total]: data?.severityPieTotal,
  }
  return (
    <Box>
      {!L.isEmpty(data) ? (
        <Box>
          <Stats stats={data?.stats} />
          <Grid columns={[1, null, 3]}>
            <PieChart
              title="All active Issues by severity"
              data={toSeverityPieDataWithColor(
                severityPieData[selectedSeverityAggregationType]
              )}
              extraOnHeader={
                category === AssetCategory.Code && (
                  <Radio.Group
                    defaultValue={selectedSeverityAggregationType}
                    size="small"
                  >
                    <Radio.Button
                      value={CodeIssuesTableData.IssuesOnly}
                      onChange={({ target }) => {
                        setSeverityAggregationType(target.value)
                      }}
                    >
                      Secrets
                    </Radio.Button>
                    <Radio.Button
                      value={CodeIssuesTableData.IacOnly}
                      onChange={({ target }) => {
                        setSeverityAggregationType(target.value)
                      }}
                    >
                      IaC
                    </Radio.Button>
                    <Radio.Button
                      value={CodeIssuesTableData.DiscoverIssuesOnly}
                      onChange={({ target }) => {
                        setSeverityAggregationType(target.value)
                      }}
                    >
                      Hardening
                    </Radio.Button>
                    <Radio.Button
                      value={CodeIssuesTableData.OpenSourceIssuesOnly}
                      onChange={({ target }) => {
                        setSeverityAggregationType(target.value)
                      }}
                    >
                      Open source
                    </Radio.Button>

                    <Radio.Button
                      value={CodeIssuesTableData.Total}
                      onChange={({ target }) => {
                        setSelectedSeverityAggregationType(target.value)
                      }}
                    >
                      Total
                    </Radio.Button>
                  </Radio.Group>
                )
              }
            />
            <PieChart
              title="All active Issues by sources"
              data={L.take(data?.sourcesPie, SOURCES_RESULTS_LIMIT)}
              colorsScheme={colors.sourcesPie}
            />
            <PieChart
              title="All Issues by status"
              data={data?.statusPie.map((statusData) => ({
                ...statusData,
                color: colors.statusPie[statusData.id],
              }))}
            />
          </Grid>
          <Grid columns={[1, null, 3]}>
            <HotspotChart
              title="Top assets with issues"
              data={toTopIssuesCountAssets(data?.topIssuesCountAssets)}
              onChartItemClick={handleHSTopAsset}
            />
            <HotspotChart title="Top issues" data={toTopRules(data?.rules)} />
            <HotspotChart
              title="Top teams with issues"
              data={toTopTeams(data?.issuesByTeam)}
            />
          </Grid>
          <OverTime
            onFilterChanged={onFilterChanged}
            filters={filters}
            orgTeams={orgTeams}
            allDetectors={data.allDetectors}
            data={data}
            sourcesEmptyState={sources}
          />
        </Box>
      ) : (
        <EmptyState sources={sources} />
      )}
    </Box>
  )
}

const Report = ({
  data,
  links: { sources },
  filters,
  onFilterChanged,
  orgTeams,
}) => {
  return (
    data && (
      <Tabs key="reportsTabs">
        {Object.values(AssetCategory).map((category) => (
          <TabPane tab={L.capitalize(category)} key={category}>
            <TabView
              data={data[category]}
              links={{ sources }}
              filters={filters}
              onFilterChanged={onFilterChanged}
              orgTeams={orgTeams}
              category={category}
            />
          </TabPane>
        ))}
      </Tabs>
    )
  )
}

export default Report
