/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import mondayLogo from '../../assets/svg/monday.svg'
import { select } from '../../redux/store'
import MondayCreateItemView from '../../blocks/monday-create-item-modal-board-id/view'

export default function MondayCreateItem({ issue, onClose, onSubmit }) {
  const dispatch = useDispatch()

  const workspaces = useSelector(select.Monday.workspaces)
  const {
    isWorkspacesFetchingInProgress,
    isBoardFetchingInProgress,
    isItemCreationInProgress,
    fetchWorkspacesError,
    fetchBoardError,
    createItemError,
  } = useSelector(select.Monday.createItemModal)

  const board = useSelector(select.Monday.board)

  const onSubmitFetchBoard = ({ boardId }) =>
    dispatch.Monday.fetchBoardById({ boardId })

  useEffect(() => {
    if (
      !isWorkspacesFetchingInProgress &&
      !fetchWorkspacesError &&
      (!workspaces || workspaces.length === 0)
    ) {
      dispatch.Monday.fetchWorkspaces()
    }
  }, [
    dispatch.Monday,
    workspaces,
    isWorkspacesFetchingInProgress,
    fetchWorkspacesError,
  ])

  useEffect(() => {
    dispatch.error.hide({
      name: 'Monday',
      action: 'createItem',
    })
    return () => {
      dispatch.Monday.reset()
    }
  }, [dispatch.Monday, dispatch.error, issue])

  const onFinish = async (values) => {
    await dispatch.Monday.createItem({
      itemData: values,
      issue,
    })
    onSubmit()
  }

  return (
    <MondayCreateItemView
      mondayLogo={mondayLogo}
      onClose={onClose}
      onFinish={onFinish}
      workspaces={workspaces}
      issue={issue}
      board={board}
      onSubmitFetchBoard={onSubmitFetchBoard}
      isWorkspacesFetchingInProgress={isWorkspacesFetchingInProgress}
      isBoardFetchingInProgress={isBoardFetchingInProgress}
      isItemCreationInProgress={isItemCreationInProgress}
      fetchBoardError={fetchBoardError}
      createItemError={createItemError}
    />
  )
}
