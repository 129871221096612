/** @jsx jsx */
import { Box, Flex, Text, jsx } from 'theme-ui'
import { ISSUE_UNASSIGNED } from '@spectral/types'
import { AiOutlineUserDelete } from 'react-icons/ai'
import { Avatar, Tooltip } from 'antd'
import { AvatarSize } from 'antd/lib/avatar/SizeContext'

export const MemberSelectDropdownItem = ({
  member,
  shouldDisplayName = true,
  size = 'large',
}: {
  member: any
  size?: AvatarSize
  shouldDisplayName?: boolean
}) => {
  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Tooltip title={!shouldDisplayName && member?.fullName}>
        <Avatar
          size={size}
          style={{
            marginRight: '10px',
            minWidth: '24px',
          }}
          src={member?.avatarUrl}
        >
          {member?.fullName?.toUpperCase()}
        </Avatar>
      </Tooltip>
      {shouldDisplayName && (
        <Text
          variant="small"
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          title={member?.fullName}
        >
          {member?.fullName}
        </Text>
      )}
    </Flex>
  )
}

export const MemberUnassignedDropdownItem = ({
  shouldDisplayName = true,
  size = 'large',
}: {
  size?: AvatarSize
  shouldDisplayName?: boolean
}) => {
  return (
    <Tooltip title={!shouldDisplayName && ISSUE_UNASSIGNED}>
      <Flex sx={{ alignItems: 'center' }}>
        <Avatar
          size={size}
          style={{
            marginRight: '10px',
          }}
        >
          <Box sx={{ marginTop: `3px` }}>
            <AiOutlineUserDelete size={size === 'large' ? 40 : 24} />
          </Box>
        </Avatar>
        {shouldDisplayName && <Text variant="small">{ISSUE_UNASSIGNED}</Text>}
      </Flex>
    </Tooltip>
  )
}
