/** @jsx jsx */
import { Flex, Heading, Image, Box, jsx } from 'theme-ui'
import { Button } from 'antd'
import { imageMap } from '../../../common/sources/imageMap'
import IntegrationWrapper from '../integration-wrapper'
import { supportWidget } from '../../../common/support-widget'
import { TextStyle } from './share'
import awsDeployStack from '../../../assets/png/cloudformation-launch-stack.png'
import { SnippetBox } from '../../../components/panels/snippet-box'
import PersonalTokenAlert from '../personal-token-alert'
import EnvVarsTable from './common/envVarsTable'

const envVarsData = [
  {
    key: '1',
    name: 'SPECTRAL_DSN',
    required: true,
    description: 'Your Spectral DSN retrieved from SpectralOps',
  },
  {
    key: '2',
    name: 'CHECK_POLICY',
    required: true,
    description:
      'If Spectral finds issues - how should we handle the run? The policies are based on the Spectral issue severity - error / warning / info (Valid values: "Fail on any issue" / "Fail on warnings and above" / "Fail on errors only" / "Always Pass")',
  },
  {
    key: '3',
    name: ' GITLAB_WEBHOOK_SECRET',
    required: true,
    description: 'Any strong secret would be fine',
  },
  {
    key: '4',
    name: 'GITLAB_TOKEN',
    required: true,
    description:
      'Generate it in your Gitlab profile -> Access Tokens, check the "api" scope',
  },
  {
    key: '5',
    name: 'STRICT_MODE',
    required: false,
    description:
      'If set to true, check status is based on all issues found in the modified files (even if the issues are old)',
  },
  {
    key: '6',
    name: 'SPECTRAL_TAGS',
    required: false,
    description:
      " Tags list to run Spectral with, separated by commas (eg base,iac,audit). Default is 'base'",
  },
]

const GitlabBotSourceIntegration = ({
  dataSource,
  shouldShowTeamToken,
  onSwitchTokenShown,
}) => {
  return (
    <IntegrationWrapper
      title="Gitlab Bot"
      imgSrc={imageMap[dataSource?.tileKey]}
    >
      <Flex
        sx={{
          gap: 3,
          flexDirection: 'column',
          code: {
            backgroundColor: 'black',
            borderRadius: '4px',
            color: 'white',
            py: '2px',
            px: '5px',
          },
        }}
      >
        {shouldShowTeamToken && (
          <Box sx={{ my: 4 }}>
            <PersonalTokenAlert onSwitch={onSwitchTokenShown} />
          </Box>
        )}
        <TextStyle>
          Monitor, alert, and discover sensitive data in your code for each
          merge request. Get instant feedback on any change you make in a merge
          request. Spectral Bot can be installed directly on organizations, user
          accounts, groups and grant access to specific Projects.
        </TextStyle>
        <Box sx={{ mb: '15px', mt: '15px' }}>
          <Heading sx={{ mb: '25px' }} as="h2" variant="smallheading">
            Integration Environment Variables
          </Heading>
          <EnvVarsTable envVarsData={envVarsData} />
        </Box>
        <Heading as="h2" variant="smallheading">
          Deploy the bot
        </Heading>
        Deploy using one of the following methods:
        <ul>
          <li>
            <Heading as="h3" variant="smalltitle">
              Cloud Formation
            </Heading>
            <TextStyle>
              <Box sx={{ mb: 2 }}>
                Go ahead and&nbsp;
                <a
                  href="https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-gitlab-webhook-scanner/cloudformation-template.json&stackName=spectral-gitlab-webhook-scanner&param_StrictMode=false"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Image sx={{ mr: 2 }} src={awsDeployStack} />
                </a>
              </Box>
            </TextStyle>
          </li>
          <li>
            <Heading as="h3" variant="smalltitle">
              Terraform
            </Heading>
            <TextStyle>
              <Box sx={{ mb: 2 }}>
                Deploy AWS resources using our&nbsp;
                <a
                  href="https://github.com/SpectralOps/spectral-terraform-lambda-integration"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terraform module
                </a>{' '}
                (set the <code>integration_type</code> param value to{' '}
                <strong>gitlab</strong>).
              </Box>
            </TextStyle>
          </li>
          <li>
            <Heading as="h3" variant="smalltitle">
              Docker
            </Heading>
            <TextStyle>
              <Box sx={{ mb: 2 }}>
                Go to our&nbsp;
                <a
                  href="https://hub.docker.com/r/checkpoint/spectral-gitlab-scanner"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  DockerHub repo for the Gitlab scanner
                </a>{' '}
                and follow instructions.
              </Box>
            </TextStyle>
          </li>
        </ul>
        <Heading as="h2" variant="smallheading">
          Setup Gitlab Project Webhooks
        </Heading>
        <TextStyle>
          Setup Gitlab webhooks by running the create_webhooks executable.
          Download it for&nbsp;
          <a
            href="https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-gitlab-webhook-scanner/create_webhooks/mac/create_webhooks"
            rel="noopener noreferrer"
            target="_blank"
          >
            Mac
          </a>
          &nbsp;(
          <a
            href="https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-gitlab-webhook-scanner/create_webhooks/mac/digest"
            rel="noopener noreferrer"
            target="_blank"
          >
            digest
          </a>
          ),&nbsp;
          <a
            href="https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-gitlab-webhook-scanner/create_webhooks/linux/create_webhooks"
            rel="noopener noreferrer"
            target="_blank"
          >
            Linux
          </a>
          &nbsp;(
          <a
            href="https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-gitlab-webhook-scanner/create_webhooks/linux/digest"
            rel="noopener noreferrer"
            target="_blank"
          >
            digest
          </a>
          )&nbsp;or&nbsp;
          <a
            href="https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-gitlab-webhook-scanner/create_webhooks/windows/create_webhooks.exe"
            rel="noopener noreferrer"
            target="_blank"
          >
            Windows
          </a>
          &nbsp;(
          <a
            href="https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-gitlab-webhook-scanner/create_webhooks/windows/digest"
            rel="noopener noreferrer"
            target="_blank"
          >
            digest
          </a>
          ) and use it:
          <SnippetBox
            htmlCodeSnippet={{
              __html: `./create_webhooks -t GITLAB_TOKEN -u LAMBDA_FULL_URL -s WEBHOOK_SECRET -g GROUP_ID `,
            }}
            codeCopy="./create_webhooks -t GITLAB_TOKEN -u LAMBDA_FULL_URL -s WEBHOOK_SECRET -g GROUP_ID "
          />
          This will setup webhooks for the projects under the group GROUP_ID
          (recursively). Look at <code>./create_webhooks -h</code> for usage.
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          That&#39;s it{' '}
          <span role="img" aria-label="strong">
            💪
          </span>
        </Heading>
        <TextStyle>
          Now merge requests for all the projects you selected are protected by
          Spectral. Any issues found will be written as a note on the MR.
        </TextStyle>
      </Flex>
      <Flex sx={{ mt: '40px', justifyContent: 'center' }}>
        <Button size="large" onClick={() => supportWidget.open()}>
          Need help? Talk to an expert
        </Button>
      </Flex>
    </IntegrationWrapper>
  )
}

export default GitlabBotSourceIntegration
