import { ModelConfig } from '@rematch/core'
import { sdkClient } from '../../store'

export type RecoverFinishState = {
  error: string | null
  message: string | null
  recoverValid: boolean
}

export type RecoverFinishPayload = {
  pid: string
  resetToken: string
  password: string
  passwordAgain: string
  handleRecoveryFinish: Function
}

const initialState: RecoverFinishState = {
  error: null,
  message: null,
  recoverValid: false,
}

export const recoverFinish: ModelConfig<RecoverFinishState> = {
  state: initialState,
  reducers: {
    setMessage(state: RecoverFinishState, message: string): RecoverFinishState {
      return { ...state, message }
    },

    setRecoverValid(
      state: RecoverFinishState,
      recoverValid: boolean
    ): RecoverFinishState {
      return { ...state, recoverValid }
    },

    setError(state: RecoverFinishState, error: string): RecoverFinishState {
      return { ...state, error }
    },

    reset(state: RecoverFinishState): RecoverFinishState {
      return initialState
    },
  },
  effects: (dispatch: any) => ({
    async recoverValidate({ pid, resetToken }: RecoverFinishPayload) {
      try {
        await sdkClient.auth().recoverValidate({
          params: { pid, resetToken },
        })
        dispatch.RecoverFinish.setRecoverValid(true)
      } catch (e) {
        dispatch.RecoverFinish.setRecoverValid(false)
        dispatch.RecoverFinish.setError(e.toString())
      }
    },
    async recoverFinish({
      pid,
      resetToken,
      password,
      passwordAgain,
      handleRecoveryFinish,
    }: RecoverFinishPayload) {
      try {
        await sdkClient.auth().recoverFinish({
          data: { pid, resetToken, password, passwordAgain },
          params: { pid, resetToken },
        })
        dispatch.RecoverFinish.setMessage(
          'Your password has been reset successfully.'
        )
        handleRecoveryFinish()
      } catch (e) {
        dispatch.RecoverFinish.setError(e.toString())
      }
    },
  }),
}
