/** @jsx jsx */
import { useEffect } from 'react'
import { jsx, Box } from 'theme-ui'
import track from '../../common/track'
import { Loading } from '../loading'

const SubPage = ({
  name,
  isLoading = false,
  sx = null,
  className = null,
  children,
}) => {
  useEffect(() => {
    track.subPageLoaded(name)
  }, [name])

  return (
    <Box className={className} sx={sx}>
      {isLoading && <Loading />}
      {!isLoading && children}
    </Box>
  )
}
export { SubPage }
