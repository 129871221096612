import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { OrgTeam, defaultOrgTeam } from '@spectral/types'
import { Drawer, Form, Button, Input, Collapse } from 'antd'
import { Box, Text, Flex } from 'theme-ui'
import { PanelImageHeader } from '../../../components/panels/content'
import { paths } from '../../../routes'
import { OrgTeamIntegrations } from './org-teams'

export const LinkToGlobalIntegrations = ({ children }) => {
  return <Link to={paths.closed.settingsIntegrations}>{children}</Link>
}

const IntegrationNotActive = () => (
  <Flex>
    <Text>
      Global integration is inactive -{' '}
      <LinkToGlobalIntegrations>Change</LinkToGlobalIntegrations>
    </Text>
  </Flex>
)

const mapToForm = (currentOrgTeam) => {
  let integrations = {}
  if (!currentOrgTeam.integrations) {
    integrations = {
      ...defaultOrgTeam.integrations,
      teamName: currentOrgTeam.name,
    }
  } else {
    integrations = {
      teamName: currentOrgTeam.name,
      slackWebhookUrl: currentOrgTeam.integrations.slack?.webhookUrls,
      msTeamsWebhookUrl: currentOrgTeam.integrations.msTeams?.webhookUrls,
      jiraProject: currentOrgTeam.integrations.jira?.projectId,
      pagerDutyRoutingServiceKey:
        currentOrgTeam.integrations.pd?.routingServiceKey,
      mondayWorkspaceId: currentOrgTeam.integrations.monday?.workspaceId,
    }
  }

  return { ...integrations, teamName: currentOrgTeam.name }
}

const mapToOrgTeam = (currentOrgTeamSetting, formData) =>
  ({
    orgTeamId: currentOrgTeamSetting.orgTeamId || undefined,
    name: formData.teamName,
    integrations: {
      slack: {
        webhookUrls:
          formData.slackWebhookUrl?.length > 0
            ? formData.slackWebhookUrl
            : null,
      },
      msTeams: {
        webhookUrls:
          formData.msTeamsWebhookUrl?.length > 0
            ? formData.msTeamsWebhookUrl
            : null,
      },
      jira: { projectId: formData.jiraProject },
      pd: { routingServiceKey: formData.pagerDutyRoutingServiceKey },
      monday: {
        workspaceId: formData.mondayWorkspaceId,
      },
    },
  } as OrgTeam)

export default ({
  teams,
  currentOrgTeamSetting,
  integrations,
  addOrUpdate,
  settingsShown,
  orgTeamSave,
  showSettings,
}: {
  integrations: OrgTeamIntegrations
  teams: any
  currentOrgTeamSetting: any
  addOrUpdate: any
  settingsShown: any
  orgTeamSave: any
  showSettings: any
}) => {
  const [form] = Form.useForm()

  const initialValues = mapToForm(currentOrgTeamSetting)
  useEffect(() => {
    form.setFieldsValue(mapToForm(currentOrgTeamSetting))
  }, [form, currentOrgTeamSetting])

  return (
    <Drawer
      // https://github.com/ant-design/ant-design/issues/21543#issuecomment-598515368
      getContainer={false}
      //
      title={
        addOrUpdate === 'Add' ? (
          'Add team'
        ) : (
          <Text>
            Update <strong>{currentOrgTeamSetting.name}</strong> settings
          </Text>
        )
      }
      width={600}
      destroyOnClose
      onClose={() => {
        form.resetFields()
        showSettings()
      }}
      visible={settingsShown}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <Box
          style={{
            textAlign: 'left',
          }}
        >
          <Button
            type="primary"
            form="form-org-teams"
            htmlType="submit"
            style={{ marginRight: 8 }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              form.resetFields()
              showSettings()
            }}
          >
            Cancel
          </Button>
        </Box>
      }
    >
      <Form
        form={form}
        initialValues={initialValues}
        id="form-org-teams"
        layout="vertical"
        onFinish={(formData) => {
          form.resetFields()
          orgTeamSave({
            orgTeams: [mapToOrgTeam(currentOrgTeamSetting, formData)],
          })
        }}
      >
        <Form.Item
          sx={{ mb: 4 }}
          name="teamName"
          fieldKey="teamName"
          label="Team name"
          rules={[
            { required: true, message: 'Please enter team name' },
            () => ({
              validator(_, value) {
                if (value) {
                  if (
                    !teams.some(
                      // some other org team (id not equal) has the same name
                      (orgTeam: OrgTeam) =>
                        currentOrgTeamSetting.orgTeamId !== orgTeam.orgTeamId &&
                        orgTeam.name.trim() === value.trim()
                    )
                  )
                    return Promise.resolve()
                  return Promise.reject(
                    new Error('This team already exists, try another name')
                  )
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <Input placeholder="Please enter team name" />
        </Form.Item>
        <Collapse
          defaultActiveKey={Object.values(integrations)
            .filter((integration) => integration.isActive)
            .map(
              (filteredIntegration) =>
                `${filteredIntegration.displayName}_panel`
            )}
        >
          {Object.values(integrations).map((integration) => (
            <Collapse.Panel
              header={
                <PanelImageHeader
                  img={integration.logoSvg}
                  title={integration.displayName}
                />
              }
              extra={!integration.isActive && <IntegrationNotActive />}
              key={`${integration.displayName}_panel`}
              collapsible={!integration.isActive ? 'disabled' : 'header'}
            >
              {integration.component({
                ...integration.orgTeamSettings,
                form,
              })}
            </Collapse.Panel>
          ))}
        </Collapse>
      </Form>
    </Drawer>
  )
}
