import { ActivityItem, AuditAction } from '@spectral/types'
import React from 'react'
import L from 'lodash'
import { Text } from 'theme-ui'
import { Link } from 'react-router-dom'
import { paths } from '../../routes'

const namify = (email, firstname, lastname) => {
  if (firstname && lastname)
    return `${L.capitalize(firstname)} ${L.capitalize(lastname)}`
  if (firstname) return L.capitalize(firstname)
  return email
}
export const resolveTemplate = (activityItem: ActivityItem) => {
  const actor = namify(
    activityItem.actorEmail,
    activityItem.actorFirstName,
    activityItem.actorLastName
  )
  const count = L.size(activityItem.data?.issues)

  switch (activityItem.kind) {
    case AuditAction.SET_SCM_URL_FORMATS:
      return {
        text: (
          <Text
            sx={{
              'a, a:hover, a:visited, a:active &': {
                color: 'inherit !important',
              },
            }}
          >
            {' '}
            <Link
              to={{
                pathname: paths.closed.settingsSCM,
              }}
            >
              <strong>{actor}</strong> updated{' '}
              <strong>unsupported SCM URLs</strong> configuration
            </Link>
          </Text>
        ),
        date: activityItem.date,
      }
    case AuditAction.UPDATE_REMOTE_CONFIG:
    case AuditAction.UPDATE_SCAN_CONFIG:
      return {
        text: (
          <Text
            sx={{
              'a, a:hover, a:visited, a:active &': {
                color: 'inherit !important',
              },
            }}
          >
            {' '}
            <Link
              to={{
                pathname: paths.closed.remoteConfig,
                state: { selectedAssetType: activityItem.data.assetType },
              }}
            >
              <strong>{actor}</strong> updated{' '}
              <strong>{activityItem.data.assetType}</strong> configuration
            </Link>
          </Text>
        ),
        date: activityItem.date,
      }
    case AuditAction.ADD_IGNORES_BULK:
      return {
        text: (
          <Text>
            <strong>{actor}</strong> ignored{' '}
            {count === 1 ? 'an issue' : `${count} issues`}
          </Text>
        ),
        date: activityItem.date,
      }

    case AuditAction.REMOVE_IGNORES_BULK:
      return {
        text: (
          <Text>
            <strong>{actor}</strong> removed ignore{' '}
            {count === 1 ? 'from an issue' : `from ${count} issues`}
          </Text>
        ),
        date: activityItem.date,
      }
    case AuditAction.RESOLVE_ISSUES:
      return {
        text: (
          <Text>
            <strong>{actor}</strong> resolved an issue
          </Text>
        ),
        date: activityItem.date,
      }
    case AuditAction.UNRESOLVE_ISSUES:
      return {
        text: (
          <Text>
            <strong>{actor}</strong> unresolved an issue
          </Text>
        ),
        date: activityItem.date,
      }
    case AuditAction.ADD_MEMBER:
      return {
        text: (
          <Text>
            <strong>{actor}</strong> invited member{' '}
            <strong>{activityItem.data.email}</strong>
          </Text>
        ),
        date: activityItem.date,
      }
    case AuditAction.USER_ACCEPTED_INVITE:
      return {
        text: (
          <Text>
            <strong>{actor}</strong> ({activityItem.actorEmail}) joined the team
          </Text>
        ),
        date: activityItem.date,
      }

    case AuditAction.REMOVE_MEMBER_OR_INVITE:
      return {
        text: (
          <Text>
            <strong>{actor}</strong> removed member{' '}
            <strong>{activityItem.data.email}</strong>
          </Text>
        ),
        date: activityItem.date,
      }

    case AuditAction.DELETE_ASSET:
      // eslint-disable-next-line no-case-declarations
      const hasMultipleAssets = activityItem.data.assetIds?.length > 1
      // eslint-disable-next-line no-case-declarations
      const text = hasMultipleAssets
        ? `deleted ${activityItem.data.assetIds[0]} +${
            activityItem.data.assetIds.length - 1
          } more `
        : `deleted an asset ${activityItem.data.assetIds || ''}`
      return {
        text: (
          <Text>
            <strong>{actor}</strong> {text}
          </Text>
        ),
        date: activityItem.date,
      }
    case AuditAction.ADD_IGNORES_BULK_SNOOZE:
      return {
        text: (
          <Text>
            <strong>{actor}</strong> snoozed{' '}
            {count === 1 ? 'an issue' : `${count} issues`} for{' '}
            {activityItem.data.daysToSnooze}d
          </Text>
        ),
        date: activityItem.date,
      }
    default:
      return null
  }
}
