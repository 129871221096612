import React from 'react'

const Slicer = ({ maxItems, showMore = null, children }) => {
  const numberOfItems = children.length
  const visibleItems =
    numberOfItems > maxItems ? children.slice(0, maxItems) : children
  return (
    <>
      {visibleItems}
      {showMore && numberOfItems > maxItems ? showMore : false}
    </>
  )
}

export default Slicer
