import { SortDirection } from '@spectral/types'
import { getDetectorsIds } from '../../../common/utils'

const orderMap = {
  ascend: 'ASC',
  descend: 'DESC',
}

export const toQuerySorter = (sorter) => {
  if (!sorter) return ''
  const fieldsMap = {
    firstSeen: 'createdAt',
  }

  const by =
    fieldsMap[sorter.field] ||
    sorter.field ||
    fieldsMap[sorter.columnKey] ||
    sorter.columnKey
  const direction =
    SortDirection[sorter.order?.toUpperCase()] || SortDirection.DESC
  return `${by},${direction}`
}

export const tableFiltersToQuery = ({
  tableFilters,
  tablePagination,
  tableSorter,
}) => {
  const queryParams = {
    severity: tableFilters.severity?.toString() || '',
    assetId: tableFilters.asset || '',
    detectorId: tableFilters.detectorId
      ? getDetectorsIds(tableFilters.detectorId).toString()
      : '',
    orgTeams: tableFilters.orgTeam?.toString() || '',
    content: tableFilters.content?.toString() || '',
    sorter:
      toQuerySorter({
        field: tableSorter.field || tableSorter.columnKey,
        order: orderMap[tableSorter.order],
      }) || '',
    page: tablePagination?.current,
    pageSize: tablePagination?.pageSize,
  }

  return { queryParams }
}
