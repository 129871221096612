/** @jsx jsx */
import { useEffect } from 'react'
import { jsx, Flex, Text } from 'theme-ui'
import { ReactComponent as SpectralFullLogo } from './assets/svg/spectral-full-logo.svg'
import track from './common/track'

const NotSupportedMobile = () => {
  useEffect(() => {
    track.event('NOT_SUPPORTED_MOBILE')
  }, [])
  return (
    <Flex
      sx={{
        minHeight: '100vh',
        minWidth: '100vw',
        margin: 0,
        top: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        p: 3,
        textAlign: 'center',
      }}
    >
      <Flex
        sx={{
          height: '70px',
          width: '150px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          mb: 3,
        }}
      >
        <SpectralFullLogo />
      </Flex>
      <Text variant="wizardsub" sx={{ mb: 3 }}>
        Device not supported
      </Text>
      <Text sx={{ mb: 13 }}>
        Sorry, SpectralOps isn&#39;t available on this device
      </Text>
      <a
        href="https://spectralops.io"
        rel="noopener noreferrer"
        target="_blank"
      >
        SpectralOps.io
      </a>
    </Flex>
  )
}

export default NotSupportedMobile
