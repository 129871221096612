import { Typography } from 'antd'
import React from 'react'
import { Box } from 'theme-ui'
import {
  MessageBox,
  MessageBoxVariant,
} from '../../../../components/messageBox'

const { Text } = Typography

const ConfigurationTypesMessageBox = () => {
  return (
    <MessageBox variant={MessageBoxVariant.Tip} title="Important">
      <ul>
        <li>
          <Box>
            In order to figure out if an asset scan used asset type
            configuration, please check if scan banner named{' '}
            <Text code>remote_cfg</Text> holds the value <Text code>Yes</Text>.
          </Box>
        </li>
        <li>
          If you have a local <Text code>spectral.yaml</Text> in your asset:
          <ul>
            <li>
              <strong>match_ignores</strong> - the local{' '}
              <Text code>match_ignores</Text> section would be merged with the
              asset type`s <Text code>match_ignores</Text> section, meaning that
              the list of ignores would contain ignores configured locally in
              <Text code>spectral.yaml</Text>, and also ignores defined per
              asset type.
            </li>
            <li>
              <strong>projects</strong> - the <Text code>projects</Text>{' '}
              configuration of the asset type would not take place, and the
              local <Text code>projects</Text> configuration would be applied if
              it exists in
              <Text code>spectral.yaml</Text>.
            </li>
          </ul>
        </li>
      </ul>
    </MessageBox>
  )
}
export default ConfigurationTypesMessageBox
