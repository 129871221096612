const DEFAULT_QUICKSCAN_REPO = 'https://github.com/airbnb/kafka'
const ISSUES_DEFAULT_TAB = 'issues'
const SCAN_RESULTS_FILENAME = 'spectral_scan_report'
const ISSUES_REPORT_FILENAME = 'issues_report'
export {
  DEFAULT_QUICKSCAN_REPO,
  ISSUES_DEFAULT_TAB,
  SCAN_RESULTS_FILENAME,
  ISSUES_REPORT_FILENAME,
}

export const EXPORT_FILENAME_DATE_FORMAT = 'MMddyyyy'

export const DOCS_URL = 'https://guides.spectralops.io/docs/'
