/** @jsx jsx */
import { SearchOutlined } from '@ant-design/icons'
import {
  AssetDisplayStatus,
  AssetPageTab,
  ASSETS_PAGE_SIZE,
  SortDirection,
} from '@spectral/types'
import { useHistory } from 'react-router-dom'
import { Input } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex, jsx, Text } from 'theme-ui'
import { isEmpty } from 'lodash'
import AssetsList from '../../blocks/assets-page'
import ScannedDateFilter from '../../blocks/assets-page/filters/scanned-date-filter'
import assetsPageTracker from '../../common/track/assets-page'
import AssetsPageSideFilters, {
  SideFilterType,
} from '../../components/filters/side-filters'
import SortSelector from '../../components/filters/sort-selector'
import { Page, PageTitle } from '../../components/page'
import useDebouncedFieldFilter from '../../hooks/useDebouncedFieldFilter'
import useListPageManagement from '../../hooks/useListPageManagement'
import { Dispatch, select } from '../../redux/store'
import { paths } from '../../routes'
import DynamicDataFilter from '../../components/filters/dynamic-data-filter'
import useFiltersActions from '../../hooks/useFiltersActions'
import FiltersSidePanel from '../filters-side-panel'
import { toFilterView } from '../../blocks/asset-page/shared/transform-filters'
import FilterDivider from '../../components/filters/filter-divider'

const assetsFiltersSetup = (filters) => [
  {
    key: 'scannedDate',
    title: 'Last scanned',
    component: ScannedDateFilter,
    isActive: true,
    type: SideFilterType.Date,
    collapsible: false,
  },
  {
    key: 'orgTeams',
    title: 'Team',
    component: DynamicDataFilter({
      searchable: true,
      showAllOptions: false,
      searchPlaceholder: 'Search team',
    }),
    isActive: !isEmpty(filters.orgTeams),
    type: SideFilterType.Checkbox,
    data: toFilterView({
      filterData: filters.orgTeams,
      shouldCapitalize: false,
    }),
    collapsible: true,
  },
  {
    key: 'assetCategory',
    title: 'Category',
    component: DynamicDataFilter({
      searchable: false,
      showAllOptions: true,
    }),
    isActive: !isEmpty(filters.category),
    type: SideFilterType.Checkbox,
    data: toFilterView({
      filterData: filters.category,
      shouldCapitalize: false,
    }),
    collapsible: true,
  },
  {
    key: 'severity',
    title: 'Highest severity',
    component: DynamicDataFilter({
      searchable: false,
      showAllOptions: true,
      shouldSort: false,
    }),
    isActive: !isEmpty(filters.severity),
    type: SideFilterType.Checkbox,
    data: toFilterView({
      filterData: filters.severity,
      shouldCapitalize: true,
    }),
    shouldShowAllValues: true,
    collapsible: true,
  },
  {
    key: 'assetSource',
    title: 'Source',
    component: DynamicDataFilter({
      searchable: true,
      showAllOptions: false,
    }),
    isActive: !isEmpty(filters.sources),
    type: SideFilterType.Checkbox,
    data: toFilterView({
      filterData: filters.sources,
      shouldCapitalize: false,
    }),
    collapsible: true,
  },
]

const issuesFiltersSetup = (filters) => [
  {
    key: 'issuesTypes',
    title: 'Issues Type',
    component: DynamicDataFilter({
      searchable: false,
      showAllOptions: true,
    }),
    isActive: !isEmpty(filters.issuesTypes),
    type: SideFilterType.Checkbox,
    data: toFilterView({
      filterData: filters.issuesTypes,
      shouldCapitalize: true,
    }),
    collapsible: true,
  },
  {
    key: 'detector',
    title: 'Detectors',
    component: DynamicDataFilter({
      searchable: true,
      showAllOptions: false,
      searchPlaceholder: 'Search detector',
    }),
    tooltip: 'Assets that contain the detector',
    isActive: !isEmpty(filters.detectors),
    type: SideFilterType.Checkbox,
    data: toFilterView({
      filterData: filters.detectors,
      shouldCapitalize: false,
    }),
    collapsible: true,
  },
  {
    key: 'tag',
    title: 'Compliance framework',
    component: DynamicDataFilter({
      searchable: true,
      showAllOptions: false,
      searchPlaceholder: 'Search framework',
    }),
    tooltip: 'Assets that contain the framework',
    isActive: !isEmpty(filters.tags),
    type: SideFilterType.Checkbox,
    data: toFilterView({
      filterData: filters.tags,
      shouldCapitalize: false,
    }),
    collapsible: true,
  },
]

const sortOptions = [
  { key: 'displayName', title: 'Asset name' },
  { key: 'lastScanDate', title: 'Last scan date' },
  { key: 'highestSeverity', title: 'Severity' },
]

export default () => {
  const dispatch: Dispatch = useDispatch()
  const history = useHistory()
  const [
    currentQueryStringParams,
    setQueryStringParam,
    clearQueryStringParam,
    onPaginationChange,
    trackFilter,
    trackSort,
  ] = useListPageManagement(
    [
      'status',
      'orgTeams',
      'severity',
      'detector',
      'displayName',
      'scannedDateStart',
      'scannedDateEnd',
      'assetSource',
      'assetCategory',
      'tag',
      'issuesTypes',
    ],
    { page: '1', sortBy: 'lastScanDate', sortDirection: SortDirection.DESC },
    dispatch.AssetsPage.fetchAssets,
    dispatch.AssetsPage.resetAssets,
    assetsPageTracker.filter.bind(assetsPageTracker),
    assetsPageTracker.sort.bind(assetsPageTracker),
    assetsPageTracker.pagination.bind(assetsPageTracker)
  )

  const { page, scannedDateStart, scannedDateEnd } = currentQueryStringParams
  const { data, stats, status, filtersData } = useSelector(
    select.AssetsPage.assetsPage
  )
  const {
    pageError,
    isRefreshing,
    isDetailsLoaded,
    isFiltersDataLoaded,
    loaded,
  } = status

  const [
    onDebouncedValueChange,
    currentDebouncedValues,
  ] = useDebouncedFieldFilter(
    {
      displayName: currentQueryStringParams.displayName,
      orgTeams: currentQueryStringParams.orgTeams,
      assetCategory: currentQueryStringParams.assetCategory,
      assetSource: currentQueryStringParams.assetSource,
      severity: currentQueryStringParams.severity,
      detector: currentQueryStringParams.detector,
      tag: currentQueryStringParams.tag,
      issuesTypes: currentQueryStringParams.issuesTypes,
    },
    setQueryStringParam,
    clearQueryStringParam,
    trackFilter,
    currentQueryStringParams
  )

  useEffect(() => {
    return dispatch.AssetsPage.resetFilters
  }, [])

  const onAssetClicked = useCallback(
    (asset) => {
      assetsPageTracker.assetClicked()
      if (AssetDisplayStatus.NoIssues === asset.displayStatus) {
        history.push(
          `${paths.closed.asset(encodeURIComponent(asset.id))}?tab=${
            AssetPageTab.Secrets
          }`
        )
      }
    },
    [history]
  )

  const assetsFiltersMetadata = assetsFiltersSetup(filtersData)
  const issuesFiltersMetadata = issuesFiltersSetup(filtersData)

  const filterActions = useFiltersActions({
    setFilter: onDebouncedValueChange,
    trackFilter,
    clearFilter: clearQueryStringParam,
  })

  return (
    <Page
      sx={{
        padding: '40px 0 0 40px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      name="ASSETS"
      title="Assets"
      isError={pageError}
      isLoading={!loaded}
    >
      <PageTitle>Assets</PageTitle>
      {isFiltersDataLoaded && (
        <Flex
          sx={{
            marginTop: '20px',
            paddingRight: '40px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Input
              allowClear
              placeholder="Search for assets..."
              value={currentDebouncedValues.displayName}
              onChange={(event) => {
                onDebouncedValueChange({ displayName: event.target.value })
              }}
              prefix={<SearchOutlined />}
              disabled={isRefreshing}
            />
          </Box>
          <Box sx={{ paddingLeft: '15px', minWidth: '200px' }}>
            <SortSelector
              sortBy={currentQueryStringParams.sortBy}
              sortDirection={currentQueryStringParams.sortDirection}
              ifRefreshing={isRefreshing}
              options={sortOptions}
              setSort={setQueryStringParam}
              trackSort={trackSort}
            />
          </Box>
        </Flex>
      )}
      <Flex sx={{ paddingTop: '30px', height: '0', flex: 1 }}>
        {filtersData.orgTeams && isFiltersDataLoaded && (
          <FiltersSidePanel>
            <Text variant="summary" sx={{ mb: 4 }}>
              Asset Filters
            </Text>
            <AssetsPageSideFilters
              isDataRefreshing={isRefreshing}
              filtersValues={{
                ...currentDebouncedValues,
                scannedDateStart,
                scannedDateEnd,
              }}
              filtersMetadata={assetsFiltersMetadata}
              filterActions={filterActions}
            />
            {issuesFiltersMetadata.some(({ data: d }) => !isEmpty(d)) && (
              <Box sx={{ my: 4 }}>
                <FilterDivider />
                <Text variant="summary">Issue Filters</Text>
              </Box>
            )}
            <AssetsPageSideFilters
              isDataRefreshing={isRefreshing}
              filtersValues={{
                ...currentDebouncedValues,
                scannedDateStart,
                scannedDateEnd,
              }}
              filtersMetadata={issuesFiltersMetadata}
              filterActions={filterActions}
            />
          </FiltersSidePanel>
        )}
        <Box sx={{ width: '100%' }}>
          <AssetsList
            isRefreshing={isRefreshing}
            data={data}
            isDetailsLoaded={isDetailsLoaded}
            onAssetClicked={onAssetClicked}
            pagination={{
              currentPage: page ? parseInt(page, 10) : 1,
              onPaginationChange,
              totalItems: stats.total,
              pageSize: ASSETS_PAGE_SIZE,
            }}
          />
        </Box>
      </Flex>
    </Page>
  )
}
