import { organizationNamePatternAsRegex } from '@spectral/types'
import React from 'react'
import { Box } from 'theme-ui'
import * as Yup from 'yup'
import {
  InputFieldSection,
  SubmitSection,
  PanelForm,
} from '../../../../components/panels/content'

const TeamName = ({ currentTeam, onUpdateTeamName }) => {
  return (
    <PanelForm
      onSubmit={onUpdateTeamName}
      initialValues={{
        teamName: currentTeam.name,
      }}
      validationSchema={Yup.object({
        teamName: Yup.string()
          .required('Please enter a company name.')
          .min(2, 'Minimum 2 characters.')
          .max(64, 'Maximum 64 characters.')
          .matches(organizationNamePatternAsRegex, 'Invalid character.'),
      })}
    >
      <Box>
        <InputFieldSection
          name="teamName"
          label="Organization name"
          description="The organization name of Spectral account"
          placeholder="Your organization"
          required
          merge
        />
        <SubmitSection text="Update" />
      </Box>
    </PanelForm>
  )
}

export default TeamName
