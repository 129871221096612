/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Menu } from 'antd'

import {
  SettingsIcon,
  DocsIcon,
  CollapseIcon,
  ExpandIcon,
  SupportIcon,
} from '../../assets/sidebar/button-menu-icons'
import { supportWidget } from '../../common/support-widget'
import { DOCS_URL } from '../../utils/constants'

export default ({
  collapsed,
  location,
  links: { settings = '#' },
  onCollapseClick,
}) => {
  return (
    <StyledMenu
      mode="inline"
      inlineCollapsed={collapsed}
      selectedKeys={[
        location.pathname.match(/\/settings/) ? settings : location.pathname,
      ]}
      defaultSelectedKeys={['1']}
    >
      <Menu.Item
        key={settings}
        className="sp-menu-settings"
        icon={SettingsIcon}
      >
        <NavLink className="e2e-test-sidebar-settings-button" to={settings}>
          Settings
        </NavLink>
      </Menu.Item>
      <Menu.Item key="docs" className="sp-menu-docs" icon={DocsIcon}>
        <a rel="noopener noreferrer" target="_blank" href={DOCS_URL}>
          {' '}
          Docs
        </a>
      </Menu.Item>
      <Menu.Item key="support" className="sp-menu-support" icon={SupportIcon}>
        <a onClick={supportWidget.open}>Support</a>
      </Menu.Item>
      <Menu.Item
        key="collapse"
        className="sp-menu-collapse"
        icon={collapsed ? ExpandIcon : CollapseIcon}
      >
        <a onClick={onCollapseClick}>{collapsed ? 'Expand' : 'Collapse'}</a>
      </Menu.Item>
    </StyledMenu>
  )
}

const StyledMenu = styled(Menu)`
  &&&&& {
    border-right: 0;
    margin: 20px 0px;
    background-color: transparent;
    transition-duration: 0.05s;

    .ant-menu-item {
      display: flex;
      margin: 0 0;
      background-color: transparent;
      padding: 0 19px;
      a,
      svg {
        color: #ffffffa8;
      }
      &::after {
        border: 0;
      }

      .anticon {
        display: flex;
        flex-shrink: 0;
        font-size: 20px;
        width: 40px;
        justify-content: center;
        align-items: center;
        margin-right: ${(props) => (props.inlineCollapsed ? '' : '15px')};
        svg {
          transition: color 0.2s;
          fill: none;
        }
      }
    }
    .ant-menu-item-selected {
      a,
      svg {
        color: #fff;
      }
    }
  }
`
