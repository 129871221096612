import React from 'react'
import { Box } from 'theme-ui'
import * as Yup from 'yup'
import {
  SubmitSection,
  PanelForm,
  MultiInputFieldSection,
} from '../../../../components/panels/content'
import { validateDomainArray } from '../../../../utils/validators'

const GitOnPrem = ({
  platformId,
  onPremDomains,
  onSubmit,
  label,
  description,
  icon,
  otherDomains = [],
}) => {
  return (
    <PanelForm
      onSubmit={({ domains }) => onSubmit(domains, platformId)}
      initialValues={{
        domains: onPremDomains || [],
      }}
      validationSchema={Yup.object({
        domains: validateDomainArray(
          otherDomains,
          'Can not set identical domain for multiple platforms. '
        ),
      })}
    >
      <Box>
        <MultiInputFieldSection
          placeholder={`my.${label
            .replace(/[ ]/g, '.')
            .toLowerCase()}.domain.com`}
          name="domains"
          label={label}
          description={description}
          icon={icon}
        />
        <SubmitSection text="Update" />
      </Box>
    </PanelForm>
  )
}

export default GitOnPrem
