import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import { EventsWebhookIntegration } from '../../../../blocks/settings/integrations/events-webhook/events-webhook'

export const EventsWebhookIntegrationConnected = () => {
  const { eventsWebhookSettings } = useSelector(select.Teams.teamSettings)
  const { isTestInProgress } = useSelector(
    select.Settings.eventsWebhookSettings
  )
  const dispatch = useDispatch()
  return (
    <EventsWebhookIntegration
      isActivated={eventsWebhookSettings.enabled}
      toggleEventsWebhook={dispatch.Teams.setEventsWebhookIntegrationStatus}
      setEventsWebhookSettings={dispatch.Teams.setEventsWebhookSettings}
      webhookURL={eventsWebhookSettings.webhookURL}
      secret={eventsWebhookSettings.secret}
      onTestClicked={dispatch.Settings.testEventsWebhook}
      isTestInProgress={isTestInProgress}
    />
  )
}
