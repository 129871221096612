import { ModelConfig } from '@rematch/core'
import { sdkClient } from '../../store'
import tracker from '../../../common/track'
import { paths } from '../../../routes'

type SettingsState = {}
const initialState: SettingsState = {}
export const settings: ModelConfig<SettingsState> = {
  state: initialState,
  reducers: {},
  effects: (dispatch: any) => ({
    async testPagerDuty(payload: { routingServiceKey: string }) {
      const result: any = await sdkClient.teams().testPagerDutyService({
        data: payload,
      })
      tracker.testIntegration('pager-duty')
      return result.testSucceeded
    },
    async testCustomWebhook(payload: {
      webhookUrl: string
      signatureToken: string
    }) {
      try {
        const result: any = await sdkClient.teams().testCustomWebhook({
          data: payload,
        })
        return { isTestSuccessful: true, message: result.message }
      } catch (error) {
        return { isTestSuccessful: false, message: error.message }
      }
    },
    async testEventsWebhook(payload: {
      webhookUrl: string
      signatureToken: string
    }) {
      try {
        const result: any = await sdkClient.teams().testEventsWebhook({
          data: payload,
        })
        return { isTestSuccessful: true, message: result.message }
      } catch (error) {
        return { isTestSuccessful: false, message: error.message }
      }
    },
  }),
  selectors: (_slice, createSelector, _hasProps) => ({
    customWebhookSettings() {
      return (state) => {
        return {
          isTestInProgress: state.loading.effects.Settings.testCustomWebhook,
        }
      }
    },
    eventsWebhookSettings() {
      return (state) => {
        return {
          isTestInProgress: state.loading.effects.Settings.testEventsWebhook,
        }
      }
    },
    pagerDutySettings() {
      return (state) => {
        return {
          isTestInProgress: state.loading.effects.Settings.testPagerDuty,
        }
      }
    },
    settingsBadges(models) {
      return createSelector(
        models.CustomRules.hasCustomRulesDraftVersion,
        (hasCustomRulesDraftVersion) => {
          return {
            [paths.closed.customRules]: {
              showBadge: hasCustomRulesDraftVersion,
              tooltip: 'There is a custom rules version waiting for review',
            },
          }
        }
      )
    },
  }),
}
