/* eslint-disable no-nested-ternary */
import React from 'react'
import { Box, Flex } from 'theme-ui'
import { Alert } from 'antd'
import { Loading } from '../../../../components/loading'
import SecretSprawlList from '../secret-sprawl-list.tsx/view'

const SecretSprawlView = ({
  asset,
  secrets,
  expandedSecret,
  isRefreshing,
  loaded,
  onChangeSecretExpansion,
  onPageChanged,
  currentPage,
  userCanSeeAllAssets,
}) => {
  return (
    <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
      {loaded ? (
        <Flex sx={{ flexDirection: 'column', width: '100%', pr: '40px' }}>
          {!userCanSeeAllAssets && (
            <Flex>
              <Alert
                type="info"
                message="Only assets that you have access to are shown here. There might be other assets not associated to your teams that have the same secret."
              />
            </Flex>
          )}
          <SecretSprawlList
            secrets={secrets}
            isRefreshing={isRefreshing}
            expandedSecret={expandedSecret}
            onChangeSecretExpansion={onChangeSecretExpansion}
            onPageChanged={onPageChanged}
            currentPage={currentPage}
            isNotActiveAsset={asset.isNotActive}
          />
        </Flex>
      ) : (
        <Loading />
      )}
    </Box>
  )
}

export default SecretSprawlView
