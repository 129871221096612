declare global {
  interface Window {
    HubSpotConversations: any
  }
}
class SupportWidget {
  open() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load()
      window.HubSpotConversations.widget.open()
    }
  }

  load() {
    if (window.HubSpotConversations) window.HubSpotConversations.widget.load()
  }
}

const supportWidget = new SupportWidget()

export { supportWidget }
