import { Dropdown, Menu, Modal } from 'antd'
import L from 'lodash'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import React from 'react'
import { Severity } from '@spectral/types'
import { Box } from 'theme-ui'
import {
  BaseSeverityTag,
  SeverityChangeDropdown,
} from '../../components/tags/severity'

const changeSeverityConfirm = (detectorId, detectorName, onOk) => {
  return new Promise((resolve) => {
    Modal.confirm({
      title: 'Severity Change',
      icon: <ExclamationCircleOutlined translate="no" />,
      content: `This will affect all issues with detector ${detectorId} (${detectorName}).`,
      okText: 'OK',
      centered: true,
      cancelText: 'Cancel',
      onOk() {
        onOk()
      },
      onCancel() {
        return resolve(true)
      },
    })
  })
}

export default ({
  detectorId,
  detectorName,
  currentSeverity,
  onSeverityChanged,
}) => {
  return (
    <Box sx={{ '.ant-dropdown-trigger': { width: '130px' } }}>
      <Dropdown
        overlay={
          <Menu>
            {L.filter(
              [
                Severity.Critical,
                Severity.High,
                Severity.Medium,
                Severity.Low,
                Severity.Informational,
              ],
              (severity) => severity !== currentSeverity
            ).map((severity) => (
              <Menu.Item
                key={severity}
                onClick={() => {
                  changeSeverityConfirm(detectorId, detectorName, async () => {
                    onSeverityChanged(severity, currentSeverity)
                  })
                }}
              >
                <BaseSeverityTag kind={severity} />
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <SeverityChangeDropdown kind={currentSeverity} />
      </Dropdown>
    </Box>
  )
}
