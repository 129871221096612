import { OperatingSystem } from '../../../common/sources/enums'

export const snippets = {
  [OperatingSystem.WINDOWS]: [
    {
      title:
        'Go to any target folder (could be a repo or even an empty folder)',
      code: '$ cd my-folder',
      type: 'shell',
    },
    {
      title:
        'Run init command to create the required file structure for custom rules usage, and create your rules under ".spectral/rules" folder',
      code: '$ spectral.exe custom-rules --dsn {dsn} init',
      type: 'shell',
    },
    {
      title:
        'Publish your rules by running the command and submit your rules for review',
      code: '$ spectral.exe custom-rules --dsn {dsn} publish',
      type: 'shell',
    },
  ],
  [OperatingSystem.MACLINUX]: [
    {
      title:
        'Go to any target folder (could be a repo or even an empty folder)',
      code: '$ cd my-folder',
      type: 'shell',
    },
    {
      title:
        'Run init command to create the required file structure for custom rules usage, and create your rules under ".spectral/rules" folder',
      code: '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral custom-rules init',
      type: 'shell',
    },
    {
      title:
        'Publish your rules by running the command and submit your rules for review',
      code:
        '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral custom-rules publish',
      type: 'shell',
    },
  ],
}
