/** @jsx jsx */
import { jsx } from 'theme-ui'
import View from '../../../blocks/source-integration/independent-integrations/github-native'

const GithubBotSourceIntegration = ({
  source: { data },
  setIsTeamTokenShown,
  shouldShowTeamToken,
}) => {
  return (
    <View
      dataSource={data}
      onSwitchTokenShown={setIsTeamTokenShown}
      shouldShowTeamToken={shouldShowTeamToken}
    />
  )
}

export default GithubBotSourceIntegration
