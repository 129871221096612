import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'
import { SortDirection } from '@spectral/types'
import { Button, Dropdown, Menu, Tooltip } from 'antd'
import React from 'react'
import { Box, Flex } from 'theme-ui'

type Props = {
  sortBy: string
  sortDirection: string
  options: any
  ifRefreshing: boolean
  setSort: Function
  trackSort: Function
}

const menu = (options, onSelect, sortBy, sortDirection) => {
  const generateSortDirectionDisplayText = (option) => {
    let direcrtionDescription = 'ascending'
    if (option.key !== sortBy) {
      direcrtionDescription = 'ascending'
    } else if (sortDirection === SortDirection.ASC) {
      direcrtionDescription = 'descending'
    }
    return direcrtionDescription
  }

  return (
    <Menu>
      {options.map((option) => {
        return (
          <Menu.Item
            onClick={() => onSelect(option.key)}
            style={{ minWidth: '182px' }}
            key={option.key}
          >
            <Tooltip
              title={`Click to sort ${generateSortDirectionDisplayText(
                option
              )}`}
            >
              {option.title}
            </Tooltip>
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

const SortSelector = ({
  sortBy,
  sortDirection,
  options,
  ifRefreshing,
  setSort,
  trackSort,
}: Props) => {
  const changeSort = (selectedSortByKey) => {
    let newSortDirection = SortDirection.ASC
    if (selectedSortByKey === sortBy) {
      newSortDirection =
        sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC
    }

    setSort({
      sortBy: selectedSortByKey,
      sortDirection: newSortDirection,
    })
    trackSort(selectedSortByKey, newSortDirection)
  }

  return (
    <Dropdown
      disabled={ifRefreshing}
      placement="bottomRight"
      overlay={menu(options, changeSort, sortBy, sortDirection)}
    >
      <Button style={{ minWidth: '182px' }}>
        <Flex sx={{ justifyContent: 'space-between' }}>
          {options.find((option) => option.key === sortBy)?.title}
          <Box>
            {sortDirection === SortDirection.ASC ? (
              <SortAscendingOutlined />
            ) : (
              <SortDescendingOutlined />
            )}
          </Box>
        </Flex>
      </Button>
    </Dropdown>
  )
}

export default SortSelector
