import React from 'react'
import { PanelForm } from '../../components/panels/content'
import { AssetMappingFormField } from '../asset-mapping/asset-mapping'

export const AssetsSettings = ({ settings: { assetMapping } }) => (
  <PanelForm
    onSubmit={assetMapping.onSave}
    initialValues={{ assetMapping: assetMapping.data }}
  >
    <AssetMappingFormField name="assetMapping" />
  </PanelForm>
)
