import track from '../track'

class AssetsPage {
  pagination({ page }) {
    track.event('ASSETS_PAGE_PAGINATION', { page })
  }

  assetClicked() {
    track.event('ASSETS_PAGE_ASSET_CLICKED')
  }

  filter(filterType, values) {
    track.event('ASSETS_PAGE_FILTERED', {
      filterType,
      values,
    })
  }

  sort(sortBy, sortDirection) {
    track.event('ASSETS_PAGE_SORTED', {
      sortBy,
      sortDirection,
    })
  }

  seeIssuesClicked(issuesType) {
    track.event('SEE_ISSUES_CLICKED', { issuesType })
  }
}

export default new AssetsPage()
