/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Progress } from 'antd'

const PasswordStrengthBar = ({ strength, steps }) => (
  <Progress
    percent={strength}
    steps={steps}
    status={strength === 100 ? 'success' : 'normal'}
    strokeColor={strength === 100 ? 'green' : 'red'}
    success={{
      percent: 100,
    }}
    format={(percent) => {
      if (percent < 100) {
        return `Too weak`
      }
      return 'Perfect 💪'
    }}
  />
)
export default PasswordStrengthBar
