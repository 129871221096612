import React from 'react'
import SideFilterTree from './side-tree-filter'

type DynamicDataFilterParams = {
  searchable: boolean
  showAllOptions?: boolean
  searchPlaceholder?: string
  shouldSort?: boolean
  tooltip?: string
}

const DynamicDataFilter = ({
  searchable,
  searchPlaceholder,
  showAllOptions = false,
  shouldSort = true,
}: DynamicDataFilterParams) => ({
  data,
  onCheck,
  onClear,
  isRefreshing,
  checkedKeys = [],
  onExpandFilter,
  onCollapseFilter,
  currentCountItemsToDisplay,
  title,
}) => {
  return (
    <SideFilterTree
      showSearch={searchable}
      isRefreshing={isRefreshing}
      onCheck={onCheck}
      onClear={onClear}
      title={title}
      showAllOptions={showAllOptions}
      searchPlaceholder={searchPlaceholder}
      options={data}
      checkedKeys={checkedKeys}
      shouldSort={shouldSort}
      onExpandFilter={onExpandFilter}
      onCollapseFilter={onCollapseFilter}
      currentCountItemsToDisplay={currentCountItemsToDisplay}
    />
  )
}

export default DynamicDataFilter
