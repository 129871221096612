/** @jsx jsx */
import { Team } from '@spectral/backend-sdk'
import { useParams, Link } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { jsx, Box } from 'theme-ui'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { LoginType } from '@spectral/types'
import { features } from '../../common/features'
import { Dispatch, RootState, select } from '../../redux/store'
import { Page, PageTitle, TitleChevron } from '../../components/page'
import { getIntegration, downloadSnippetByMode } from '../../common/sources'
import useQuerystring from '../../hooks/useQuerystring'
import { supportWidget } from '../../common/support-widget'
import { paths } from '../../routes'
import {
  OperatingSystem,
  SourceIntegrationComponent,
} from '../../common/sources/enums'
import tracker from '../../common/track'
import SnippetWindow from '../../blocks/source-integration/snippet-window'
import useDSN from '../../hooks/useDSN'

type Props = {
  currentTeam: null | Team
  fetchCurrentTeam: () => void
}

const SourceIntegration = ({ currentTeam, fetchCurrentTeam }: Props) => {
  const isError = useSelector(
    (state) => state.error.effects.Teams.fetchCurrentTeam
  )
  const canUserViewOrganizationSettings = useSelector(
    select.Users.canViewOrganizationSettings
  )

  const { integrationId } = useParams()
  const [{ chat }] = useQuerystring()
  const [operatingSystem, setOperatingSystem] = useState(
    OperatingSystem.MACLINUX
  )
  const { pid: teamPid, name: teamName, key: teamKey, loginType } = currentTeam

  useEffect(() => {
    if (chat) supportWidget.open()
  }, [chat])

  useEffect(() => {
    fetchCurrentTeam()
  }, [fetchCurrentTeam])

  useLayoutEffect(() => {
    if (features.preloadChat) supportWidget.load()
  })

  const {
    downloaderBaseUrl,
    agentDetails: { version },
    apiKey,
  } = useSelector((state) => state.Auth.user)

  const digestLinks = {
    darwin: `${downloaderBaseUrl}/v${version}/spectral_${version}_darwin_amd64.shas.txt`,
    linux: `${downloaderBaseUrl}/v${version}/spectral_${version}_linux_amd64.shas.txt`,
    win: `${downloaderBaseUrl}/v${version}/spectral_${version}_win32_amd64.shas.txt`,
  }

  const integrationDataSource = getIntegration(operatingSystem, integrationId)

  const handleSnippetCopied = useCallback(
    ({ value }) => {
      tracker.event('INTEGRATION_SNIPPET_COPY', {
        integrationId,
        value,
        teamPid,
        teamName,
      })
    },
    [currentTeam, integrationId]
  )

  const { dsn, scanKey, setIsTeamTokenShown } = useDSN(
    teamKey,
    apiKey,
    loginType
  )

  const handleChangeOS = useCallback(
    ({ target }) => {
      setOperatingSystem(target.value)
    },
    [setOperatingSystem]
  )

  const renderSwitch = () => {
    switch (integrationDataSource.component) {
      // deprecated old integration sources (ci / audit)
      case SourceIntegrationComponent.SnippetWindow:
        return (
          <SnippetWindow
            mode={integrationDataSource.mode}
            scanKey={scanKey}
            dsn={dsn}
            operatingSystem={operatingSystem}
            digestLinks={digestLinks}
            setOperatingSystem={handleChangeOS}
            dataSource={integrationDataSource.data}
            downloadSnippet={downloadSnippetByMode(
              integrationDataSource.mode,
              operatingSystem
            )}
            onCopy={handleSnippetCopied}
            isScansSettingHidden={integrationDataSource.isScansSettingHidden}
            shouldShowTeamToken={
              canUserViewOrganizationSettings && loginType === LoginType.Team
            }
            onSwitchTokenShown={setIsTeamTokenShown}
          />
        )
      default: {
        const DataSourceComponent = integrationDataSource.component
        return (
          <DataSourceComponent
            source={integrationDataSource}
            scanKey={scanKey}
            dsn={dsn}
            setIsTeamTokenShown={setIsTeamTokenShown}
            handleSnippetCopied={handleSnippetCopied}
            shouldShowTeamToken={
              canUserViewOrganizationSettings && loginType === LoginType.Team
            }
          />
        )
      }
    }
  }

  return (
    <Page
      sx={{ padding: '40px' }}
      name="SOURCES"
      title="Add sources"
      isLoading={!currentTeam}
      isError={!integrationDataSource || isError}
    >
      {integrationDataSource && (
        <Box>
          <PageTitle>
            <Link to={paths.closed.sources}> Sources </Link>
            <TitleChevron /> {integrationDataSource.data.title}
          </PageTitle>
          <Box sx={{ mt: '40px' }}>{renderSwitch()}</Box>
        </Box>
      )}
    </Page>
  )
}

const mapState = (state: RootState) => ({
  currentTeam: select.Teams.current(state),
})
const mapDispatch = (dispatch: Dispatch) => ({
  fetchCurrentTeam: dispatch.Teams.fetchCurrentTeam,
})

export default connect(mapState, mapDispatch)(SourceIntegration)
