/** @jsx jsx */
import { AssetType, OpenSourceIssueType, RoleType } from '@spectral/types'
import {
  ExclamationCircleOutlined,
  FileSearchOutlined,
} from '@ant-design/icons'
import { Checkbox, Tag, Tooltip, Button } from 'antd'
import { Box, Flex, jsx, Link, Text } from 'theme-ui'
import theme from '../../../../common/theme'
import IssueTags from '../../../../components/asset-page/issue-tags/issue-tags'
import assetPageTracker from '../../../../common/track/asset-page'
import IgnorePopover from '../../../ignore-popover/view'
import DetectorInfo from '../../../detector-name/view'
import DetectorSeverity from '../../severity-change-dropdown'
import IssueActions from '../../issue-action/actions'
import RegressionPopOver from '../../../regression-popover/view'
import ResolvePopOver from '../../../resolve-popover/view'
import Restricted from '../../../../containers/role-based-render/restricted'

const PackageName = ({ link, isIgnored, name }) => {
  return (
    <Box mr={2}>
      {link ? (
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={assetPageTracker.investigateClicked}
          sx={{
            fontWeight: 700,
            color: isIgnored && theme.stylebook.colors.gray['500'],
          }}
        >
          {name}
        </Link>
      ) : (
        <Text>{name}</Text>
      )}
    </Box>
  )
}

const OpenSourceStatus = ({ openSourceStatus }) => {
  const statusesDic = {
    [OpenSourceIssueType.VULNERABLE]: {
      statusText: 'Vulnerability',
      tooltip: 'This package contains vulnerabilities that you should fix.',
    },
    [OpenSourceIssueType.MALICIOUS]: {
      statusText: 'Malicious',
      tooltip:
        'This package is a malicious package. You should remove it ASAP.',
      tagColor: 'red',
    },
  }
  if (!statusesDic[openSourceStatus]) return null
  const { statusText, tooltip, tagColor } = statusesDic[openSourceStatus]
  return (
    <Tooltip title={tooltip}>
      <Tag color={tagColor}>
        <ExclamationCircleOutlined sx={{ mr: 1 }} />
        {statusText}
      </Tag>
    </Tooltip>
  )
}

const OpenSourceDetectorInfo = ({
  isIgnored,
  link,
  detectorId,
  detectorName,
  openSourceIssueStatus,
}) => {
  return (
    <Flex>
      Details:&nbsp;&nbsp;
      <Box ml={1}>
        <DetectorInfo
          isIgnored={isIgnored}
          link={link}
          detectorId={detectorId}
          detectorName={detectorName}
          onClick={() => assetPageTracker.playbookClicked(detectorId)}
          showTooltip={false}
        />
      </Box>
      <Box ml={10}>
        <OpenSourceStatus openSourceStatus={openSourceIssueStatus} />
      </Box>
    </Flex>
  )
}

const PackageVersion = ({ version }) =>
  version && (
    <Box>
      <Flex>
        Version:&nbsp;&nbsp;
        <Box>
          <Text
            variant="ellipsis"
            sx={{
              ml: 1,
              fontWeight: 700,
              wordBreak: 'break-word',
            }}
          >
            {version}
          </Text>
        </Box>
      </Flex>
    </Box>
  )

const PackageRemediationAdvice = ({ remediation }) => {
  const advice = remediation?.action
  return advice ? (
    <Box>
      <Flex>
        Remediation:&nbsp;&nbsp;
        <Box>
          <Text
            sx={{
              fontWeight: 700,
              wordBreak: 'break-word',
            }}
          >
            {advice}
          </Text>
        </Box>
      </Flex>
    </Box>
  ) : null
}

const OpenSourceIssueItem = ({
  issue,
  actionHandlers,
  isSelected,
  onIssueSelectionChanged,
  integrations,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
}) => {
  const {
    detectorId,
    detectorName,
    blameUrl,
    uri,
    variant,
    firstSeen,
    displaySeverity,
    originalSeverity,
    ignore: {
      isIgnored,
      ignoreType,
      comment,
      ignoreDate,
      actor,
      timeSnoozeOver,
    },
    metadata: {
      attributes: {
        name: packageName,
        version,
        remediation,
        package_remediation,
        url: detectorExternalInfoUrl,
        status: openSourceIssueStatus,
      },
      originalCreatedAt,
      resolvedDate,
    },
    status,
    asset,
  } = issue
  const [
    handleIgnore,
    handleSnooze,
    handleAssigneeChange,
    handleAssigneeRemove,
    handleSearchAssignee,
    _handleSeverityChange,
    handleResolve,
    handleUnresolve,
    handleAssetIssuesSeverityChange,
  ] = actionHandlers

  const linkToCode = uri || blameUrl
  const detectorExternalLink = detectorExternalInfoUrl
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Flex sx={{ mb: 2 }}>
        <Box mx={3} sx={{ minWidth: '16px' }}>
          <Restricted roles={[RoleType.Owner, RoleType.Admin, RoleType.Member]}>
            <Checkbox
              checked={isSelected}
              onChange={(event) => {
                onIssueSelectionChanged([issue], event.target.checked)
              }}
            />
          </Restricted>

          {isIgnored && (
            <IgnorePopover
              ignoreType={ignoreType}
              ignoreDate={ignoreDate}
              comment={comment}
              actor={actor}
              timeSnoozeOver={timeSnoozeOver}
            />
          )}
          <RegressionPopOver
            originalCreatedAt={originalCreatedAt}
            status={status}
          />
          <ResolvePopOver resolvedDate={resolvedDate} status={status} />
        </Box>
        <Box mr={2}>
          <Flex mb={2}>
            <Box mr={2} sx={{ minWidth: '110px' }}>
              <DetectorSeverity
                displaySeverity={displaySeverity}
                originalSeverity={originalSeverity}
                detectorId={detectorId}
                detectorName={detectorName}
                onSeverityChanged={handleAssetIssuesSeverityChange}
              />
            </Box>
            <PackageName
              name={packageName}
              link={linkToCode}
              isIgnored={isIgnored}
            />
          </Flex>
          <Box mb={2}>
            <OpenSourceDetectorInfo
              isIgnored={isIgnored}
              link={detectorExternalLink}
              detectorId={detectorId}
              detectorName={detectorId}
              openSourceIssueStatus={openSourceIssueStatus}
            />
            <PackageVersion version={version} />
            <PackageRemediationAdvice
              remediation={remediation || package_remediation}
            />
          </Box>
          {linkToCode && (
            <Button
              size="small"
              icon={<FileSearchOutlined />}
              onClick={() =>
                window.open(linkToCode, '_blank', 'noopener,noreferrer')
              }
            >
              Investigate
            </Button>
          )}
          <IssueTags issue={issue} integrations={integrations} />
        </Box>
      </Flex>
      <Box sx={{ minWidth: '250px' }}>
        <IssueActions
          title="Issue has been exposed for this long"
          firstSeen={firstSeen}
          isIgnored={isIgnored}
          handleIgnore={handleIgnore}
          handleSnooze={handleSnooze}
          issue={issue}
          handleResolve={handleResolve}
          handleUnresolve={handleUnresolve}
          assignableMembers={assignableMembers}
          isLoadingAssignableMembers={isLoadingAssignableMembers}
          isAssignableMembersLoaded={isAssignableMembersLoaded}
          handleAssigneeChange={handleAssigneeChange}
          handleAssigneeRemove={handleAssigneeRemove}
          handleSearchAssignee={handleSearchAssignee}
        />
      </Box>
    </Flex>
  )
}

export default OpenSourceIssueItem
