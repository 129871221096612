/** @jsx jsx */
import { Box, Flex, jsx, Text } from 'theme-ui'
import { Spin, Tooltip } from 'antd'

const IntegrationStatus = ({ isConnected, isLoaded, variant = null }) =>
  // eslint-disable-next-line no-nested-ternary
  isConnected ? (
    <Text>
      {variant === 'static' ? (
        <Tooltip title="You have scanned assets from this integration">
          <span
            role="img"
            aria-label="Integrated"
            style={{ marginRight: '8px' }}
          >
            🟢
          </span>
          <strong>Integrated</strong>
        </Tooltip>
      ) : (
        "Yee-haw, you're all set up! 🟢"
      )}
    </Text>
  ) : isLoaded ? (
    <Box>
      {variant === 'static' ? (
        <Tooltip title="You have no scanned assets from this integration">
          <span
            role="img"
            aria-label="Not integrated"
            style={{ marginRight: '4px' }}
          >
            ⚪
          </span>{' '}
          <strong>Not integrated</strong>
        </Tooltip>
      ) : (
        <Flex sx={{ alignItems: 'center' }}>
          <Text mr={2}>Still waiting for that first finding to arrive</Text>{' '}
          <Spin style={{ position: 'relative', top: '4px' }} />
        </Flex>
      )}
    </Box>
  ) : (
    <Flex sx={{ alignItems: 'center' }}>
      <Text mr={2}>Checking integration status</Text>
      <Spin style={{ position: 'relative', top: '4px' }} />
    </Flex>
  )

export default IntegrationStatus
