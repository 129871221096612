import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FloatingUserModal from '../../blocks/modals/floating-user-modal'
import {
  floatingUserSeen,
  githubAppPostInstallPersistence,
} from '../../redux/persistence'
import SetExtraDetailsModal from '../../blocks/set-extra-details-modal/view'
import GoToSourcesPageModal from '../../blocks/go-to-sources-modal/view'
import SuccessSubscriptionModal from '../../blocks/success-subscription-modal/view'
import { paths } from '../../routes'
import { ScansInProgressFromCodeSecurityMonitorModal } from '../../blocks/scans-in-progress-modal'
import useQuerystring from '../../hooks/useQuerystring'
import PostGithubAppInstallModal from '../../blocks/post-github-app-install'

const postGithubAppInstallModal = 'postGithubAppInstall'
const goToSourceModal = 'goToSource'
const updateOrgTeamModal = 'needUpdateOrgTeam'
const floatingUserModal = 'floatingUser'

const modalsPriority = (
  postGithubAppInstall,
  goToSource,
  needUpdateOrgTeam,
  floatingUser
) => {
  if (postGithubAppInstall) return postGithubAppInstallModal
  if (goToSource) return goToSourceModal
  if (needUpdateOrgTeam) return updateOrgTeamModal
  if (floatingUser) return floatingUserModal
  return ''
}

export default () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [currentQuery, setQuery] = useQuerystring()
  const [postGithubAppInstall, setPostGithubAppInstall] = useState('')
  const [
    goToSourcesModalManuallyClosed,
    setGoToSourcesModalManuallyClosed,
  ] = useState(false)

  useEffect(() => {
    if (currentQuery.postGithubAppInstall) {
      githubAppPostInstallPersistence.persist(currentQuery.postGithubAppInstall)
      setPostGithubAppInstall(currentQuery.postGithubAppInstall)
    }
  }, [currentQuery.postGithubAppInstall, setQuery])

  const { isFloatingUser, isNeedUpdateOrgName, hasScanForTeam } = useSelector(
    (state) => state.Auth.user
  )

  const [displayFloatingUserModal, setDisplayFloatingUserModal] = useState(
    !floatingUserSeen.get() && isFloatingUser
  )

  const setFloatingUserModalSeen = () => {
    setDisplayFloatingUserModal(false)
    floatingUserSeen.persist(true)
  }

  const isPostGithubAppInstall =
    postGithubAppInstall &&
    !location.pathname.includes(paths.closed.sources) &&
    !location.pathname.includes(paths.closed.settingsSCM)

  const displayGoToSourcesModal =
    !goToSourcesModalManuallyClosed &&
    !isNeedUpdateOrgName &&
    !hasScanForTeam &&
    !location.pathname.includes(paths.closed.sources) &&
    !location.pathname.includes(paths.closed.settingsSCM)

  const isLoadingFetchUser = useSelector(
    (state) => state.loading.effects.Auth.me
  )

  const refreshUserState = useCallback(() => {
    dispatch.Auth.refetchUser()
  }, [dispatch.Auth])

  const visibleModal = modalsPriority(
    isPostGithubAppInstall,
    displayGoToSourcesModal,
    isNeedUpdateOrgName,
    displayFloatingUserModal
  )

  const clearPostGithubAppInstall = useCallback(() => {
    setPostGithubAppInstall('')
  }, [setPostGithubAppInstall])

  return (
    <>
      {visibleModal === postGithubAppInstallModal && (
        <PostGithubAppInstallModal
          refreshUserState={refreshUserState}
          setQuery={setQuery}
          fetchAssetsSummary={dispatch.Assets.fetchAssetsSummary}
          githubAppScanSucceeded={hasScanForTeam}
          postGithubAppInstall={postGithubAppInstall}
          disposeModal={clearPostGithubAppInstall}
        />
      )}
      {visibleModal === goToSourceModal && (
        <GoToSourcesPageModal
          refreshUserState={refreshUserState}
          isLoading={isLoadingFetchUser}
          onClose={() => setGoToSourcesModalManuallyClosed(true)}
        />
      )}
      {visibleModal === updateOrgTeamModal && (
        <SetExtraDetailsModal submitOrgName={dispatch.Teams.updateTeamName} />
      )}
      {visibleModal === floatingUserModal && (
        <FloatingUserModal onOk={setFloatingUserModalSeen} />
      )}
      <SuccessSubscriptionModal />
      <ScansInProgressFromCodeSecurityMonitorModal />
    </>
  )
}
