/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { Skeleton } from 'antd'

const MenuSkeleton = ({ lines }) => {
  return (
    <Flex
      sx={{
        justifyContent: 'space-around',
        pl: '24px',
        pr: '16px',
        flexDirection: 'column',
      }}
    >
      {Array(lines)
        .fill(null)
        .map((_, i) => i)
        .map((key) => (
          <Skeleton.Button
            key={key}
            active
            size="small"
            shape="square"
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 'auto',
              marginBottom: '28px',
              marginTop: key === 0 && '14px',
            }}
          />
        ))}
    </Flex>
  )
}

export default MenuSkeleton
