import React from 'react'
import L from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import EmailVerificationAlert from './view'

export default () => {
  const {
    isEmailAlertVisible,
    isVerificationSent,
    isLoading,
    error,
    email,
  } = useSelector((state) => ({
    isEmailAlertVisible: state.EmailVerification.isEmailAlertVisible,
    isVerificationSent: state.EmailVerification.isVerificationSent,
    isLoading: state.loading.effects.EmailVerification.resendEmailVerification,
    error: state.error.effects.EmailVerification.resendEmailVerification,
    email: state.Auth.user.username,
  }))
  const {
    EmailVerification: { setIsEmailAlertVisible, resendEmailVerification },
  } = useDispatch()

  if (isEmailAlertVisible) {
    return (
      <EmailVerificationAlert
        email={email}
        onResendClicked={resendEmailVerification}
        onCloseClicked={L.partial(setIsEmailAlertVisible, false)}
        isVerificationSent={isVerificationSent}
        isLoading={isLoading}
        error={error}
      />
    )
  }
  return null
}
