import { v4 as uuidv4 } from 'uuid'
import { IssueIgnoreAction } from '@spectral/types'

const mixpanel = require('mixpanel-browser')

declare global {
  interface Window {
    dataLayer: Array<any>
  }
}

const mpSessionConfig = {
  // 30 minutes in milliseconds
  timeout: 1800000,

  getSessionId() {
    return uuidv4()
  },

  setNewSessionId() {
    mixpanel.register({
      'session ID': mpSessionConfig.getSessionId(),
    })
  },

  // if any of the checks fail set a new session id
  checkSessionId() {
    if (!mixpanel.get_property('last event time'))
      mpSessionConfig.setNewSessionId()
    if (!mixpanel.get_property('session ID')) mpSessionConfig.setNewSessionId()
    if (
      Date.now() - mixpanel.get_property('last event time') >
      mpSessionConfig.timeout
    )
      mpSessionConfig.setNewSessionId()
  },
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  loaded(mp) {
    // check for a session_id
    mpSessionConfig.checkSessionId()
    // mixpanel.track() always checks for a session id
    const originalTrack = mp.track
    mp.track = (...args) => {
      mpSessionConfig.checkSessionId()
      mp.register({ 'last event time': Date.now() })
      originalTrack.apply(mp, args)
    }
  },
})

type IgnoreEvent = {
  action: IssueIgnoreAction.add | IssueIgnoreAction.remove
  page: string
  ignoreType?: string
  buttonType: string
}
class Track {
  user(id) {
    mixpanel.identify(id)
  }

  addOrUpdateUser(
    { username, firstName, lastName },
    company = '',
    newUser = false
  ) {
    mixpanel.people.set_once({
      $email: username,
      $first_name: firstName,
      $last_name: lastName,
      'Sign up date': newUser && new Date(Date.now()).toISOString(),
      USER_ID: username,
      company,
    })
  }

  page(name, title, location, referringSource) {
    if (window.dataLayer)
      window.dataLayer.push({
        event: `visit:${name}`,
        title,
        url: location.pathname,
      })

    mixpanel.track(`visit:${name}`, {
      type: 'page',
      title,
      url: location.pathname,
      referringSource,
    })
  }

  subPageLoaded(name) {
    this.event('VISIT_SUB_PAGE', { tabName: name })
  }

  modal(name, action) {
    this.event('MODAL_CLICKED', { action, name })
  }

  event(name, data = {}) {
    if (window.dataLayer) window.dataLayer.push({ event: name, ...data })
    mixpanel.track(name, { type: 'event', ...data })
  }

  ignore({ action, page, ignoreType, buttonType }: IgnoreEvent) {
    this.event('IGNORE_CLICKED', { action, page, ignoreType, buttonType })
  }

  resolve({ page, buttonType }) {
    this.event('RESOLVE_CLICKED', { page, buttonType })
  }

  unresolve({ page, buttonType }) {
    this.event('UNRESOLVE_CLICKED', { page, buttonType })
  }

  severityChange(detectorId, newSeverity, oldSeverity) {
    this.event('SEVERITY_CHANGE_CLICKED', {
      detectorId,
      newSeverity,
      oldSeverity,
    })
  }

  assignIssues(count) {
    this.event('ASSIGN_ISSUES', { count })
  }

  unassignIssues(count) {
    this.event('UNASSIGN_ISSUES', { count })
  }

  globalIntegrationStatus(integration, status) {
    this.event('SET_GLOBAL_INTEGRATION', { integration, status })
  }

  testIntegration(integration) {
    this.event('TEST_INTEGRATION', { integration })
  }

  filterIssues({ assetName, dateFrom, dateTo }) {
    this.event('FILTER_ISSUES', { assetName, dateFrom, dateTo })
  }

  clickTag({ tagKind, numVisible }) {
    this.event('TAG_CLICKED', { tagKind, numVisible })
  }

  mondayBoards({ numBoards }) {
    this.event('MONDAY_BOARDS', { numBoards })
  }

  changedScanEnginesSettings(engines) {
    this.event('SNIPPET_SCAN_ENGINES_SETTINGS_CHANGED', engines)
  }

  changedScanSpectralEnginesSecurityLevel(securityLevel) {
    this.event('SNIPPET_SCAN_SPECTRAL_ENGINE_SECURITY_LEVEL', securityLevel)
  }

  customRulesVersionReviewed(status) {
    this.event('CUSTOM_RULES_VERSION_REVIEWED', { status })
  }

  customRulesFeatureActivated() {
    this.event('CUSTOM_RULES_FEATURE_ACTIVATED')
  }

  filterScans({
    assetName,
    branches,
    severities,
    sources,
    teams,
    dateFrom,
    dateTo,
  }) {
    this.event('FILTER_SCANS', {
      assetName,
      branches,
      severities,
      sources,
      teams,
      dateFrom,
      dateTo,
    })
  }

  filterMembers() {
    this.event('SETTINGS_FILTER_MEMBERS')
  }

  filterInvites() {
    this.event('SETTINGS_FILTER_INVITES')
  }

  filterAssigneeMembers() {
    this.event('FILTER_ASSIGNEE_MEMBERS')
  }
}

export default new Track()
