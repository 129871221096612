import React from 'react'
import DisposableQueryModal from '../../components/disposable-query-modal'

const SucceededModal = ({ postGithubAppInstall }) => {
  return (
    <DisposableQueryModal
      title="Yay! 🥳"
      okText="Got it"
      qsParam="postGithubAppInstall"
    >
      <div>
        Congratulations, your scan succeeded. You can add additional
        repositories in{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://github.com/settings/installations/${postGithubAppInstall}`}
        >
          your Github settings
        </a>
        . Scan results will appear in the dashboard automatically.
      </div>
    </DisposableQueryModal>
  )
}

export default SucceededModal
