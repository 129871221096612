/** @jsx jsx */
import { Link } from 'react-router-dom'
import { Modal, Button } from 'antd'
import { jsx, Flex, Text, Box } from 'theme-ui'
import { useEffect } from 'react'
import { PrimaryButtonLarge } from '../../components/buttons'
import { paths } from '../../routes'
import { Loading } from '../../components/loading'

const GoToSourcesPageModal = ({ refreshUserState, isLoading, onClose }) => {
  useEffect(() => {
    refreshUserState()
  }, [refreshUserState])

  return (
    <Modal
      title={
        isLoading
          ? 'Checking your account...'
          : 'There are no scans in your account'
      }
      visible
      keyboard={false}
      footer={null}
      onCancel={onClose}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          <Text>
            We have not received scans from your integration yet. Please setup
            your CI with Spectral.
          </Text>
          <Flex sx={{ justifyContent: 'flex-end', mt: 3 }}>
            <Link to={paths.closed.sources} sx={{ ml: 2 }}>
              <PrimaryButtonLarge text="Setup your CI" />
            </Link>
            <Link to={`${paths.closed.sources}?chat=true`} sx={{ ml: 2 }}>
              <Button size="large">Talk to an expert</Button>
            </Link>
          </Flex>
        </Box>
      )}
    </Modal>
  )
}

export default GoToSourcesPageModal
