import { init, RematchRootState, ModelEffects } from '@rematch/core'
import { SpectralBackendSDK } from '@spectral/backend-sdk'
import createLoadingPlugin from '@rematch/loading'
import selectPlugin, { ModelSelectors } from '@rematch/select'
import { SentryEnhancer } from './enhancers/sentryEnhancer'
import { models, RootModel } from './models'
import errorWrapperPlugin from './rematch-error-wrapper-plugin'
import { authPersistence } from './persistence'
import { UNAUTHORIZED_ERROR_MSG } from './constants'
import { isCypress, isEmbedded } from '../common/utils'

const loadingPlugin = createLoadingPlugin({
  asNumber: true,
})

export const store = init({
  plugins: [loadingPlugin, selectPlugin(), errorWrapperPlugin()],
  models,
  redux: {
    enhancers: [SentryEnhancer],
  },
})

const badAuthHandler = () => {
  authPersistence.remove()
  window.location.reload()
  throw new Error(UNAUTHORIZED_ERROR_MSG)
}

export const sdkClient = new SpectralBackendSDK(badAuthHandler)

export const empty = <T>(data: T): { fetchStatus: FetchStatus; data: T } => ({
  fetchStatus: 'none',
  data,
})
export const loaded = <T>(data: T): { fetchStatus: FetchStatus; data: T } => ({
  fetchStatus: 'loaded',
  data,
})

export const boot = () =>
  new Promise((resolve, _reject) => {
    if (!isEmbedded() || isCypress()) {
      store.dispatch.Auth.checkAuthorization()
    }
    resolve()
  })

export const { dispatch } = store

export type FetchStatus = 'none' | 'loaded'
export type Store = typeof store
export type Dispatch = typeof store.dispatch
type ExtractRematchLoadingStateFromEffectsObject<
  effects extends ModelEffects<any>
> = {
  [effectKey in keyof effects]: boolean
}

type ExtractRematchSelectorsFromSelectorsObject<
  selectors extends ModelSelectors<any>
> = {
  [selectorKey in keyof selectors]: boolean
}

interface LoadingState<M extends RootModel> {
  loading: {
    global: boolean
    models: { [k in keyof M]: boolean }
    effects: {
      [k in keyof M]: ExtractRematchLoadingStateFromEffectsObject<
        ModelEffects<any>
      >
    }
  }
}

interface ErrorState<M extends RootModel> {
  error: {
    effects: {
      [k in keyof M]: ExtractRematchLoadingStateFromEffectsObject<
        ModelEffects<any>
      >
    }
  }
}

interface SelectState<M extends RootModel> {
  select: {
    [k in keyof M]: ExtractRematchSelectorsFromSelectorsObject<
      ModelSelectors<any>
    >
  }
}
export type RootState = RematchRootState<typeof models> &
  LoadingState<typeof models> &
  SelectState<typeof models> &
  ErrorState<typeof models>
export const { select } = store
export default store
