/* eslint-disable no-unused-expressions */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  AssetPageTab,
  ScanInsightsSortBy,
  SortDirection,
} from '@spectral/types'
import { Dispatch, select } from '../../redux/store'
import { SubPage } from '../../components/tab/sub-page'
import useListPageManagement from '../../hooks/useListPageManagement'
import assetPageTracker from '../../common/track/asset-page'
import ScanHistoryView from '../../blocks/asset-page/scan-history/view'
import useQueryStringState from '../../hooks/useQueryStringState'

export const scanHistoryInitialParams = {
  tab: AssetPageTab.ScansHistory,
  page: 1,
  pageSize: 10,
  sortBy: ScanInsightsSortBy.CREATED_AT,
  sortDirection: SortDirection.DESC,
}

const ScansHistory = () => {
  const dispatch: Dispatch = useDispatch()
  const { assetId } = useParams()

  const { scanInsights, isLoading, pagination } = useSelector(
    select.ScanInsights.assetScansHistoryPage
  )
  const [
    currentParamsValues,
    saveParamValueInQueryString,
  ] = useQueryStringState(['expandedScan'])

  const [
    _currentQueryStringParams,
    _setQueryStringParam,
    _clearQueryStringParam,
    onPaginationChange,
    _trackFilter,
    _trackSort,
    _fetchData,
  ] = useListPageManagement(
    ['tab'],
    scanHistoryInitialParams,
    dispatch.ScanInsights.fetchAssetScanInsights,
    dispatch.ScanInsights.reset,
    null,
    null,
    assetPageTracker.pagination.bind(assetPageTracker),
    { assetId }
  )

  const onChangeScanExpansion = (updatedExpandedScan) => {
    saveParamValueInQueryString({ expandedScan: updatedExpandedScan }, true)
  }
  return (
    <SubPage isLoading={isLoading} name="Scans History" sx={{ height: '100%' }}>
      <ScanHistoryView
        isLoading={isLoading}
        scanInsights={scanInsights}
        pagination={pagination}
        onPageChanged={onPaginationChange}
        onChangeScanExpansion={onChangeScanExpansion}
        expendedScan={currentParamsValues.expandedScan}
      />
    </SubPage>
  )
}

export default ScansHistory
