import React from 'react'
import { Box } from 'theme-ui'
import { Loading } from '../../../components/loading'
import ScanHistoryList from './scan-history-list/view'

const ScanHistoryView = ({
  scanInsights,
  isLoading,
  onPageChanged,
  pagination,
  onChangeScanExpansion,
  expendedScan,
}) => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {!isLoading ? (
        <ScanHistoryList
          isLoading={isLoading}
          scanInsights={scanInsights}
          onPageChanged={onPageChanged}
          pagination={pagination}
          onChangeScanExpansion={onChangeScanExpansion}
          expendedScan={expendedScan}
        />
      ) : (
        <Loading />
      )}
    </Box>
  )
}

export default ScanHistoryView
