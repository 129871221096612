import Icon from '@ant-design/icons/lib/components/Icon'
import { Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Box } from 'theme-ui'
import { FaCheck } from 'react-icons/fa'

type Props = {
  value: string
  text: string
  onCopy?: Function | null
  icon?: React.ReactNode
}
export const CopyText = ({
  value,
  text,
  onCopy = null,
  icon = null,
}: Props) => {
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }, [copied])

  return (
    <CopyToClipboard
      text={value.trim()}
      onCopy={() => {
        if (onCopy && typeof onCopy === 'function') onCopy({ value })
        setCopied(true)
      }}
    >
      <Box sx={{ display: 'inline', cursor: 'pointer' }}>
        {copied ? (
          <Tag style={{ width: '100%' }} icon={<Icon component={FaCheck} />}>
            Copied
          </Tag>
        ) : (
          <Tag style={{ width: '100%' }} icon={icon}>
            {text}
          </Tag>
        )}
      </Box>
    </CopyToClipboard>
  )
}
