/* eslint-disable react/no-danger */
/** @jsx jsx */
import { Text, jsx, Box } from 'theme-ui'
import L from 'lodash'
import { Card } from 'antd'
import { useState } from 'react'
import {
  cleanSnippetToCopy,
  interpolate,
  isSecretIncluded,
} from './utils/helper'
import { SnippetBox } from '../../components/panels/snippet-box'

const buildSnippet = (code, key, baseUrl) => {
  return code.replace(/{teamKey}/g, key).replace(/{baseUrl}/g, baseUrl)
}
const DownloadSnippet = ({ download, dynamicData, onCopy = null }) => {
  const { scanKey, baseUrl, digestLinks } = dynamicData
  const [isSecretShown, setSecretShown] = useState(false)
  const handleRevealClick = () => {
    setSecretShown((currentSecretVisibility) => !currentSecretVisibility)
  }
  const htmlCodeSnippet = interpolate({
    snippetCode: download.snippet,
    dynamicData,
    isSecretShown,
  })

  return (
    <Card sx={{ mb: '40px' }} title="Get Spectral">
      <Text
        dangerouslySetInnerHTML={{
          __html: download.text
            ? buildSnippet(download.text, scanKey, baseUrl)
            : download.text,
        }}
      />
      <SnippetBox
        htmlCodeSnippet={{
          __html: htmlCodeSnippet,
        }}
        codeCopy={cleanSnippetToCopy(
          interpolate({
            snippetCode: download.snippet,
            dynamicData,
            isSecretShown: true,
          })
        )}
        onCopy={onCopy}
        onRevealClick={handleRevealClick}
        isSecretIncluded={isSecretIncluded(download.snippet)}
        isSecretShown={isSecretShown}
      />
      {download.instructions && (
        <Box sx={{ mt: '20px', mb: '40px' }}>{download.instructions}</Box>
      )}
      <Box sx={{ mt: '20px', mb: '40px' }}>
        It's recommended to verify the digest of a downloaded runnable file
        before you run it. You can use Spectral's{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/spectralops/preflight"
        >
          Preflight
        </a>{' '}
        to verify. See the SHA digests of the binary, the download script and
        the gzip:{' '}
        {L.size(digestLinks) === 2 ? (
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={digestLinks.darwin}
            >
              darwin
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={digestLinks.linux}
            >
              linux
            </a>
          </span>
        ) : (
          <a target="_blank" rel="noopener noreferrer" href={digestLinks.win}>
            windows
          </a>
        )}
      </Box>
    </Card>
  )
}

export default DownloadSnippet
