import { Tag, Tooltip } from 'antd'
import React from 'react'
import { Text, Box, Flex } from 'theme-ui'
import capitalize from 'lodash/capitalize'
import { AssetStats, AssetType } from '@spectral/types'
import AssetGitVisibility from '../../components/asset-git-visibility/AssetVisibility'
import AssetIcon from '../../components/asset-icon/AssetIcon'
import AssetScanDate from '../../components/asset-page/asset-scan-date/asset-scan-date'
import { SeverityCountTag } from '../../components/tags/severity'
import { assetDisplayStatusColorsMap } from './index'

const AssetHeader = ({
  asset: {
    displayName,
    kind,
    orgTeam,
    lastScanDate,
    stats,
    source,
    displayStatus,
    isNotActive,
    isPublic,
  },
}) => {
  const orderedStats = {
    [AssetStats.Critical]: stats[AssetStats.Critical],
    [AssetStats.High]: stats[AssetStats.High],
    [AssetStats.Medium]: stats[AssetStats.Medium],
    [AssetStats.Low]: stats[AssetStats.Low],
    [AssetStats.Informational]: stats[AssetStats.Informational],
    [AssetStats.Ignore]: stats.ignore,
  }
  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Box sx={{ flex: 1 }}>
        <Flex sx={{ alignItems: 'center' }}>
          <Box mr={1}>
            <AssetIcon kind={kind} source={source} />
          </Box>
          <Box>
            <Tooltip title={assetDisplayStatusColorsMap[displayStatus].tooltip}>
              <Text variant="ellipsis" sx={{ fontWeight: '600', mr: 1 }}>
                {displayName}
              </Text>
            </Tooltip>
          </Box>
        </Flex>
        {[AssetType.Automation].includes(kind) === false && (
          <Flex sx={{ alignItems: 'center' }}>
            <Box sx={{ color: 'paleText', mr: 2 }}>{capitalize(source)}</Box>
            <AssetGitVisibility isPublic={isPublic} />
          </Flex>
        )}
      </Box>
      <Box sx={{ mr: 3 }}>
        <>
          {stats.iac > 0 && (
            <Tooltip title="IaC Issues in this asset">
              <Tag>IaC</Tag>
            </Tooltip>
          )}
          {stats[AssetStats.OpenSource] > 0 && (
            <Tooltip title="Open source issues in this asset">
              <Tag>Open source</Tag>
            </Tooltip>
          )}
        </>
      </Box>
      <Flex sx={{ flexDirection: 'column', alignItems: 'end', width: '400px' }}>
        <Flex sx={{ fontSize: '12px', width: '400px', justifyContent: 'end' }}>
          <AssetScanDate
            isNotActive={isNotActive}
            lastScanDate={lastScanDate}
          />
          &bull;&nbsp;&nbsp;
          <Text variant="ellipsis">{orgTeam}</Text>
        </Flex>
        <Flex sx={{ mt: 2, height: '22px' }}>
          {Object.entries(orderedStats).map(
            ([severity, count]) =>
              count > 0 && (
                <Box sx={{ ml: '2px' }} key={`asset_sev_${severity}`}>
                  <SeverityCountTag count={count} countOnly kind={severity} />
                </Box>
              )
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AssetHeader
