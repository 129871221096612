import { Box } from 'theme-ui'
import React from 'react'
import { Modal } from 'antd'

const multipleDomainsModalConfig = (teamPid) => {
  return {
    title: 'Failed to setup SSO',
    content: (
      <Box>
        In order to setup several email domains please contact us at{' '}
        <a
          href={`mailto:support@spectralops.io?subject=Multiple saml domains setup (${teamPid})`}
        >
          support@spectralops.io
        </a>
      </Box>
    ),
  }
}

const showMultipleDomainsModal = (teamPid: string) =>
  Modal.error(multipleDomainsModalConfig(teamPid))

export default showMultipleDomainsModal
