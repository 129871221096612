/** @jsx jsx */
import { Modal } from 'antd'
import { jsx, Box, Text } from 'theme-ui'

const FloatingUserModal = ({ onOk }) => (
  <Modal
    title="You are not assigned to any team"
    visible
    cancelButtonProps={{ style: { display: 'none' } }}
    onOk={onOk}
  >
    <Box>
      <Text>Please contact your account administrator</Text>
    </Box>
  </Modal>
)

export default FloatingUserModal
