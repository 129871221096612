/** @jsx jsx */
import { jsx, Text, Box } from 'theme-ui'
import L from 'lodash'
import { Form, Select } from 'antd'
import { LinkToGlobalIntegrations } from '../org-teams'
import { MondayOption } from '../../../monday-create-item-modal-board-id/monday-option'

const selectMondayWorkspace = ({
  currentWorkspaceId,
  workspaces = [],
  error = null,
  isLoading = false,
}) => {
  return (
    <Box>
      <Form.Item name="mondayWorkspaceId" sx={{ flexGrow: 1 }}>
        {workspaces && (
          <Select
            placeholder="Assign Monday workspace to org team"
            style={{ width: 300 }}
            disabled={
              !workspaces ||
              (workspaces && workspaces.length === 0) ||
              (!currentWorkspaceId && (isLoading || error))
            }
          >
            {workspaces &&
              workspaces.length > 0 &&
              workspaces.map((ws) => (
                <Select.Option value={ws.id} pid={ws.id} key={ws.id}>
                  <MondayOption label={ws.name} />
                </Select.Option>
              ))}
            <Select.Option
              value={null}
              pid={null}
              key="select_team_monday_workspace_null"
            >
              <Text sx={{ ml: 2 }}>None</Text>
            </Select.Option>
          </Select>
        )}
      </Form.Item>
    </Box>
  )
}

export const OrgTeamMondayIntegration = ({
  domain,
  workspaces,
  currentWorkspaceId,
}) => {
  return (
    <Box key="monday_wrapper">
      {!L.isEmpty(workspaces) ? (
        <Box>
          <Box sx={{ flexGrow: 1 }}>Monday workspace</Box>
          {selectMondayWorkspace({
            currentWorkspaceId,
            workspaces,
          })}
        </Box>
      ) : (
        <Box>
          {!domain ? (
            <Text>
              Monday integration is not set up. Go to{' '}
              <LinkToGlobalIntegrations>
                Integrations page
              </LinkToGlobalIntegrations>
            </Text>
          ) : (
            <Text>
              Monday integration failed. Go to{' '}
              <LinkToGlobalIntegrations>
                Integrations page
              </LinkToGlobalIntegrations>
            </Text>
          )}
        </Box>
      )}
    </Box>
  )
}
