import styled from 'styled-components'

/**
 * Set color, size and if less contrast
 * @param {object} props.cubeColor Set HSL color ex: { h: 145, s: 50, l: 40 }
 * @param {number} props.size Set width size of cube, the height will be width * 1.15 auto
 * @param {boolean} props.lessContrast Reduced too much-contrasting cube
 */
const CubeStyled = styled.div`
  --color-h: ${(props) =>
    props.cubeColor.h !== null ? props.cubeColor.h : 208};
  --color-s: ${(props) =>
    props.cubeColor.s !== null ? props.cubeColor.s : 22}%;
  --color-l: ${(props) =>
    props.cubeColor.l !== null ? props.cubeColor.l : 71}%;
  --color-hsl: var(--color-h), var(--color-s), var(--color-l);

  --color-light-1: hsl(
    var(--color-h),
    var(--color-s),
    calc(var(--color-l) / 0.9)
  );
  --color-light-2: hsl(
    var(--color-h),
    var(--color-s),
    calc(var(--color-l) / 0.8)
  );
  --color-light-3: hsl(
    var(--color-h),
    var(--color-s),
    calc(var(--color-l) / 0.6)
  );
  --color: hsl(var(--color-hsl));
  --color-dark-2: ${(props) => `hsl(
    var(--color-h),
    var(--color-s),
    calc(var(--color-l) * ${props.lessContrast ? 1 : 0.85}));`}
  --color-dark-1: ${(props) => `hsl(
    var(--color-h),
    var(--color-s),
    calc(var(--color-l) * ${props.lessContrast ? 1 : 0.95}));`}

  position: relative;
  width: ${(props) => props.size || 42}px;
  height: ${(props) => (props.size || 42) * 1.15}px;
  background-color: var(--color-hsl);
  background: linear-gradient(-50deg, var(--color-light-2) 20%, var(--color) 100%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);

  margin: auto 0;

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: ${(props) => props.size || 42}px;
    height: ${(props) => props.size || 42}px;
    top: 50%;
  }

  &::before {
    left: 0;
    background: linear-gradient(
      5deg,
      var(--color) 0%,
      var(--color-dark-2) 110%
    );
    transform: skewY(27.5deg) scaleX(0.5) translateY(-28.8%);
    transform-origin: bottom left;
  }

  &::after {
    right: 0;
    background: linear-gradient(
      -50deg,
      var(--color-dark-1) 0%,
      var(--color-light-1) 110%
    );
    transform: skewy(-27.5deg) scaleX(0.5) translateY(-28.8%);
    transform-origin: bottom right;
  }
`

export default CubeStyled
