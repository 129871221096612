/** @jsx jsx */
import { useState } from 'react'
import { jsx, Box, Flex, Text } from 'theme-ui'
import { SnippetBox } from '../../components/panels/snippet-box'
import {
  boldParameters,
  cleanSnippetToCopy,
  interpolate,
  isSecretIncluded,
} from './utils/helper'

const SnippetView = ({ snippet, dynamicData, onCopy }) => {
  const [isSecretShown, setSecretShown] = useState(false)
  const handleRevealClick = () => {
    setSecretShown((currentSecretVisibility) => !currentSecretVisibility)
  }

  const htmlCodeSnippet = interpolate({
    snippetCode: snippet.code,
    dynamicData,
    isSecretShown,
  })

  return (
    <Box sx={{ marginBottom: '30px' }}>
      {snippet.title && (
        <Box dangerouslySetInnerHTML={{ __html: snippet.title }} />
      )}
      <Box sx={{ alignItems: 'center', mt: 2 }}>
        <SnippetBox
          htmlCodeSnippet={{
            __html: boldParameters(htmlCodeSnippet),
          }}
          codeCopy={cleanSnippetToCopy(
            interpolate({
              snippetCode: snippet.code,
              dynamicData,
              isSecretShown: true,
            })
          )}
          onCopy={onCopy}
          onRevealClick={handleRevealClick}
          isSecretIncluded={isSecretIncluded(snippet.code)}
          isSecretShown={isSecretShown}
        />
      </Box>
      <Flex sx={{ alignItems: 'center' }}>
        <Text sx={{ marginLeft: '10px', fontSize: '12px' }}>
          {snippet.params &&
            snippet.params.map((param) => (
              <li key={param.name}>
                <b>[{param.name}]</b> {param.explain} e.g: {param.example}
              </li>
            ))}
        </Text>
      </Flex>
      {snippet.info && (
        <Text sx={{ marginTop: 2, fontSize: '12px' }}>{snippet.info}</Text>
      )}
      {snippet.postDescription && (
        <Text
          sx={{ marginTop: 2 }}
          dangerouslySetInnerHTML={{
            __html: interpolate({
              snippetCode: snippet.postDescription,
              dynamicData,
              isSecretShown,
            }),
          }}
        />
      )}
    </Box>
  )
}

export default SnippetView
