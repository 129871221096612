import React, { useEffect, useState } from 'react'
import { Button, Tooltip } from 'antd'
import merge from 'lodash/merge'
import { FaRegCopy, FaCheck } from 'react-icons/fa'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import Icon from '@ant-design/icons'

type Props = {
  value: string
  onCopy?: Function | null
  buttonStyles?: any
  size?: SizeType
  text?: string
}
export const CopyButton = ({
  value,
  onCopy = null,
  buttonStyles = null,
  size = 'middle',
  text = '',
}: Props) => {
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 300)
  }, [copied])
  return (
    <Tooltip title="Copy to clipboard">
      <CopyToClipboard
        text={value.trim()}
        onCopy={() => {
          if (onCopy && typeof onCopy === 'function') onCopy({ value })
          setCopied(true)
        }}
      >
        <Button
          style={merge(
            {
              backgroundColor: 'white',
              zIndex: 2,
            },
            buttonStyles
          )}
          type="ghost"
          size={size}
          htmlType="button"
          icon={
            copied ? (
              <Icon component={FaCheck} />
            ) : (
              <Icon component={FaRegCopy} />
            )
          }
        >
          {text}
        </Button>
      </CopyToClipboard>
    </Tooltip>
  )
}
