/** @jsx jsx */
import { Box, Text, Flex, jsx } from 'theme-ui'
import moment from 'moment'
import L from 'lodash'
import { AssetDisplayStatus } from '@spectral/types'
import HiveCard from '../hiveCard'
import { PrimaryButtonLarge } from '../../components'

const now = moment()
const data = {
  teamName: 'Paracode',
  lastScanTime: now,
  assets: [
    {
      name: 'paracode/billing',
      severity: AssetDisplayStatus.Medium,
      id: '123123-123123',
      assetId: '123123-123123',
      createdAt: now,
    },
    {
      name: 'paracode/code',
      severity: AssetDisplayStatus.Informational,
      id: '123123-123124',
      assetId: '123123-123124',
      createdAt: now,
    },
    {
      name: 'paracode/svc',
      severity: AssetDisplayStatus.NoIssues,
      id: '123123-123125',
      assetId: '123123-123125',
      createdAt: now,
    },
    {
      name: 'paracode/services',
      severity: AssetDisplayStatus.High,
      id: '123123-123126',
      assetId: '123123-123126',
      createdAt: now,
    },
    {
      name: 'paracode/devops-repo',
      severity: AssetDisplayStatus.High,
      id: '123123-123127',
      assetId: '123123-123127',
      createdAt: now,
    },
    {
      name: 'paracode/code-generator',
      severity: AssetDisplayStatus.High,
      id: '123123-123128',
      assetId: '123123-123128',
      createdAt: now,
    },
    {
      name: 'paracode/frontend',
      severity: AssetDisplayStatus.Informational,
      id: '123123-123129',
      assetId: '123123-123129',
      createdAt: now,
    },
  ],
  totalNumberOfHigh: 1,
  totalNumberOfMedium: 2,
}

export const Welcome = ({ onButtonClick = null }) => {
  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <Text variant="wizardheading" title="">
        Get started with Spectral
      </Text>
      <Text variant="wizardsub">
        Scan your code and assets and uncover secrets, API tokens, and
        misconfiguration
      </Text>

      <Box
        sx={{
          mx: '200px',
          my: '90px',
          width: '330px',
          position: 'relative',
          height: '250px',
        }}
      >
        <HiveCard
          sx={{
            width: '330px',
            position: 'absolute',
            marginLeft: '-200px',
            transform: 'scale(0.95)',
          }}
          teamName="Billing Team"
          lastScanTime={data.lastScanTime}
          assets={data.assets}
          totalNumberOfHigh={20}
          totalNumberOfMedium={5}
        />
        <HiveCard
          sx={{
            background: 'white',
            width: '330px',
            position: 'absolute',
            zIndex: 30,
            boxShadow: '0px 4px 30px rgba(37, 8, 98, 0.18)',
          }}
          teamName={`${data.teamName}`}
          lastScanTime={data.lastScanTime}
          assets={L.concat(data.assets, data.assets)}
          totalNumberOfHigh={20}
          totalNumberOfMedium={5}
        />
        <HiveCard
          sx={{
            width: '330px',
            position: 'absolute',
            marginLeft: '200px',
            transform: 'scale(0.8)',
          }}
          teamName="Custom Services"
          lastScanTime={data.lastScanTime}
          assets={data.assets}
          totalNumberOfHigh={20}
          totalNumberOfMedium={5}
        />
      </Box>
      <PrimaryButtonLarge
        onClick={onButtonClick}
        text="Add projects to Spectral"
      />
    </Flex>
  )
}
