import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import TeamName from '../../../../blocks/settings/teams/team-name/view'

const TeamNameConnected = () => {
  const dispatch = useDispatch()
  const currentTeam = useSelector(select.Teams.current)

  return (
    <TeamName
      currentTeam={currentTeam}
      onUpdateTeamName={dispatch.Teams.updateTeamName}
    />
  )
}

export default TeamNameConnected
