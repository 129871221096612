import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Form } from 'antd'

export const ReCaptchaFormItem = ({ enabled, recaptchaRef, sitekey }) => {
  return enabled ? (
    <Form.Item
      name="recaptcha"
      rules={[
        {
          required: true,
          message: 'Must solve ReCaptcha',
        },
      ]}
    >
      <ReCAPTCHA ref={recaptchaRef} sitekey={sitekey} />
    </Form.Item>
  ) : null
}
