import React from 'react'
import { Flex, Box } from 'theme-ui'
import DisposableQueryModal from '../../components/disposable-query-modal'

export default () => {
  return (
    <DisposableQueryModal title="Yay! 🥳" okText="Got it" qsParam="message">
      <Flex sx={{ gap: 3, flexDirection: 'column' }}>
        <Box>
          Your changes have been saved. We&#39;ll continuously monitor your
          code. You can add additional organizations and users to monitor in the
          sources page.
        </Box>
        <Box>
          Scan results will be refreshed automatically in the dashboard.
        </Box>
      </Flex>
    </DisposableQueryModal>
  )
}
