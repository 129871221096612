/** @jsx jsx */
import { OptInFeatures, PlanType } from '@spectral/types'
import { Button, Card, Tag } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { jsx, Flex, Text, Box } from 'theme-ui'
import L from 'lodash'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { ReactElement } from 'react'
import { select } from '../../redux/store'
import { LinkButton } from '../../components'
import { paths } from '../../routes'
import RestrictedLayout from '../restricted-layout/restricted-layout'
import tracker from '../../common/track'
import theme from '../../common/theme'

type Props = {
  featureDisplayName: string
  minimumPlan: PlanType
  optInFeature?: OptInFeatures
  optInMessage?: ReactElement
  children: any
}

const PlanRestricted = ({
  featureDisplayName,
  minimumPlan = PlanType.Free,
  optInMessage,
  optInFeature,
  children,
}: Props) => {
  const dispatch = useDispatch()

  const currentTeam = useSelector(select.Teams.current)
  const isTeamLoaded = useSelector(select.Teams.isTeamLoaded)

  const teamHasTeamPlanOrUp = useSelector(select.Plan.hasTeamPlanOrUp)
  const teamHasBusinessPlanOrUp = useSelector(select.Plan.hasBusinessPlanOrUp)
  const teamHasEnterprisePlan = useSelector(select.Plan.hasEnterprisePlan)
  const isLoadingPlans = useSelector(select.Plan.isLoading)

  const isSavingFeaturesOptIn = useSelector(select.Teams.isSavingFeaturesOptIn)

  const capitalizeMinimumPlan = L.capitalize(minimumPlan)

  const renderPlanRestriction = () => (
    <Card>
      <Flex sx={{ flexDirection: 'column', gap: 3, alignItems: 'flex-start' }}>
        <Tag color="purple">UPGRADE TO {minimumPlan.toUpperCase()} PLAN</Tag>
        <Text variant="wizardsub" sx={{ lineHeight: 'unset' }}>
          Get <strong>{featureDisplayName}</strong> with Spectral{' '}
          {capitalizeMinimumPlan}
        </Text>
        <LinkButton
          text={`Upgrade to ${capitalizeMinimumPlan}`}
          to={paths.closed.settingsPlans}
          type="primary"
          size="large"
        />
      </Flex>
    </Card>
  )

  const isFeatureOptedIn = Object.keys(
    currentTeam?.optedInFeatures || {}
  ).includes(optInFeature)

  const onFeatureOptIn = async () => {
    await dispatch.Teams.optInFeatures([optInFeature])
    tracker.customRulesFeatureActivated()
  }

  const renderOptInRestriction = () => (
    <Card
      className="plan-restricted-hidden-element"
      style={{ width: '500px', fontSize: '15px' }}
      title={
        <Box>
          <ExclamationCircleOutlined
            style={{
              marginRight: '10px',
              color: theme.stylebook.colors.severities.informationalHEX,
            }}
          />
          {featureDisplayName}
        </Box>
      }
    >
      <Box>{optInMessage}</Box>
      <Box sx={{ mt: '30px' }}>
        <Button
          type="primary"
          size="middle"
          loading={isSavingFeaturesOptIn}
          onClick={onFeatureOptIn}
        >
          Activate
        </Button>
      </Box>
    </Card>
  )

  const render = () => {
    if (
      !(
        isLoadingPlans ||
        teamHasEnterprisePlan ||
        minimumPlan === PlanType.Free ||
        (minimumPlan === PlanType.Team && teamHasTeamPlanOrUp) ||
        (minimumPlan === PlanType.Business && teamHasBusinessPlanOrUp)
      )
    ) {
      return (
        <RestrictedLayout
          modalContainerId="restricted-area-modal-container"
          renderContent={renderPlanRestriction}
        >
          {children}
        </RestrictedLayout>
      )
    }

    if (optInFeature && isTeamLoaded && !isFeatureOptedIn) {
      return (
        <RestrictedLayout
          modalContainerId="restricted-area-modal-container"
          renderContent={renderOptInRestriction}
        >
          {children}
        </RestrictedLayout>
      )
    }

    return children
  }

  return render()
}

export default PlanRestricted
