/** @jsx jsx */
import { Flex, jsx } from 'theme-ui'
import { useSelector, useDispatch } from 'react-redux'
import { Page, PageTitle } from '../../components/page'
import { select } from '../../redux/store'
import Plans from '../../blocks/settings/plans'

export default () => {
  const isLoading = useSelector(select.Plan.isLoading)
  const currentPlanType = useSelector(select.Plan.currentPlanType)
  const subscriptionMaxContributors = useSelector(
    select.Plan.subscriptionMaxContributors
  )
  const dispatch = useDispatch()

  const handleCheckoutClick = async (numOfContributors) => {
    await dispatch.Plan.redirectToCheckout({
      contributors: numOfContributors,
    })
  }

  const handleManageSubscriptionClick = async () => {
    await dispatch.Plan.redirectToPortalSession()
  }

  return (
    <Page name="PLANS" title="Plans & Pricing" isLoading={isLoading}>
      <Flex sx={{ padding: '40px', gap: '40px', flexDirection: 'column' }}>
        <PageTitle>Plans & Pricing</PageTitle>
        <Plans
          currentPlanType={currentPlanType}
          onCheckoutClick={handleCheckoutClick}
          onManageSubscriptionClick={handleManageSubscriptionClick}
          subscriptionMaxContributors={subscriptionMaxContributors}
        />
      </Flex>
    </Page>
  )
}
