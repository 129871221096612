import React from 'react'
import DisposableQueryModal from '../../components/disposable-query-modal'

const SuccessSubscriptionModal = () => {
  return (
    <DisposableQueryModal title="Yay! 🥳" okText="Got it" qsParam="success">
      <div>Thanks for your subscription. your plan has been updated</div>
    </DisposableQueryModal>
  )
}

export default SuccessSubscriptionModal
