import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AlertType, AssetDisplayStatus } from '@spectral/types'
import { authPersistence } from '../../redux/persistence'
import { paths } from '../../routes'
import { select } from '../../redux/store'
import alertsTracker from '../../common/track/alerts'
import CriticalIssuesAlertView from '../../blocks/critical-issues-alert/view'

const CriticalIssuesAlert = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const shouldDisplayCriticalIssuesBanner = useSelector(
    select.Auth.criticalIssuesBanner
  )

  const [isDismissModalDisplayed, setIsDissmisModalDisplayed] = useState(false)
  const [isBannerDisplayed, setIsBannerDisplayed] = useState(false)

  useEffect(() => {
    setIsBannerDisplayed(shouldDisplayCriticalIssuesBanner)
  }, [shouldDisplayCriticalIssuesBanner])

  const onReviewAssetsClicked = useCallback(() => {
    history.push({
      pathname: paths.closed.assets,
      search: `?page=1&severity=${AssetDisplayStatus.Critical}`,
    })
    alertsTracker.investigateCriticalIssues()
  }, [history])

  const onDismiss = useCallback(() => {
    setIsDissmisModalDisplayed(true)
  }, [])

  const onDismissConfirmation = useCallback(() => {
    alertsTracker.dismissCriticalIssuesBanner()
    authPersistence.persist({
      ...authPersistence.get(),
      shouldDisplayCriticalIssuesBanner: false,
    })
    dispatch.Auth.dismissAlert(AlertType.CriticalIssuesBanner)
    setIsDissmisModalDisplayed(false)
    setIsBannerDisplayed(false)
  }, [])

  const onDismissCancelation = useCallback(() => {
    setIsDissmisModalDisplayed(false)
  }, [])

  return (
    <CriticalIssuesAlertView
      isBannerDisplayed={isBannerDisplayed}
      isDismissModalDisplayed={isDismissModalDisplayed}
      onDismiss={onDismiss}
      onDismissCancelation={onDismissCancelation}
      onDismissConfirmation={onDismissConfirmation}
      onReviewAssetsClicked={onReviewAssetsClicked}
    />
  )
}

export default CriticalIssuesAlert
