/** @jsx jsx */
import { jsx, Flex, Text, Box } from 'theme-ui'
import { Modal, Alert } from 'antd'
import MondayBoardFetcher from './board-fetcher/index'
import MondayCreateItemContent from './create-item/index'

const MondayCreateItemView = ({
  mondayLogo,
  onClose,
  onFinish,
  workspaces,
  issue,
  board,
  onSubmitFetchBoard,
  isWorkspacesFetchingInProgress,
  isItemCreationInProgress,
  isBoardFetchingInProgress,
  fetchBoardError,
  createItemError,
}) => {
  return (
    <Modal
      visible
      footer={null}
      title={
        <Flex>
          <img width="20" src={mondayLogo} alt="Monday" />
          <Text ml={2}>Create Monday Item</Text>
        </Flex>
      }
      onCancel={onClose}
      width={800}
    >
      <Box>
        {fetchBoardError && <Alert type="error" message={fetchBoardError} />}
        <MondayBoardFetcher
          workspaces={workspaces}
          onSubmit={onSubmitFetchBoard}
          isWorkspacesFetchingInProgress={isWorkspacesFetchingInProgress}
          board={board}
        />
        <MondayCreateItemContent
          onSubmit={onFinish}
          issue={issue}
          board={board}
          isItemCreationInProgress={isItemCreationInProgress}
          isBoardFetchingInProgress={isBoardFetchingInProgress}
          createItemError={createItemError}
        />
      </Box>
    </Modal>
  )
}

export default MondayCreateItemView
