/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { Typography } from 'antd'

const { Text } = Typography

const messageBoxVariants = {
  tip: {
    color: '#36b37e',
    defaultTitle: 'Tip',
  },
  info: {
    color: '#00b8d9',
    defaultTitle: 'Info',
  },
}

export enum MessageBoxVariant {
  Tip = 'tip',
  Info = 'info',
}

type Props = {
  children: any
  title?: string
  variant: MessageBoxVariant
}

export const MessageBox = ({ children, variant, title }: Props) => {
  const { color, defaultTitle } = messageBoxVariants[variant]

  return (
    <Box
      sx={{
        my: 4,
        border: `1px solid ${color}`,
        borderRadius: '5px',
        fontSize: '14px',
      }}
    >
      <Box sx={{ p: '14px', pb: '2px', backgroundColor: 'transparent' }}>
        <Box sx={{ fontWeight: 'bold' }}>
          <Text
            sx={{
              color,
              display: 'flex',
              textTransform: 'uppercase',
              marginBottom: '7px',
            }}
          >
            {title || defaultTitle}
          </Text>
        </Box>
        {children}
      </Box>
    </Box>
  )
}
