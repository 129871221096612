import {
  INVALID_ISSUER_URL,
  CERTIFICATE_MISSING,
  CERTIFICATE_INVALID,
  GENERAL_SAML_DATA_ERROR,
  INVALID_NAMEID_FORMAT,
  EMAIL_DOMAIN_NOT_VEIRIFIED,
} from '@spectral/types'

export default {
  [INVALID_ISSUER_URL]: 'Issuer URL is invalid',
  [CERTIFICATE_MISSING]: 'Certificate is missing',
  [CERTIFICATE_INVALID]: 'Certificate is invalid',
  [GENERAL_SAML_DATA_ERROR]: 'An error occured, unable to save SSO Setup',
  [INVALID_NAMEID_FORMAT]: 'Name ID is not of type Email format',
  [EMAIL_DOMAIN_NOT_VEIRIFIED]:
    'Please verify your email address before setting up SSO',
}
