import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { CustomRulesVersionStatus, LoginType } from '@spectral/types'
import * as Yup from 'yup'
import { Box, Flex } from 'theme-ui'
import AceEditor, { diff as DiffEditor } from 'react-ace'
import yaml from 'js-yaml'
import { Button, Card, Radio, Tooltip } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { PageTitle } from '../../../components/page'
import {DOCS_URL} from '../../../utils/constants'
import 'ace-builds/src-noconflict/mode-yaml'
import 'ace-builds/src-noconflict/theme-eclipse'
import 'ace-builds/src-noconflict/snippets/yaml'
import {
  ContentPanel,
  ContentPanelHeader,
  InputFieldSection,
  PanelForm,
  SelectFieldSection,
  SubmitSection,
} from '../../../components/panels/content'
import RejectDraftCustomRulesVersionConifrmationModal from './modals/reject-custom-rules-draft-version-confirmation-modal'
import ActivateDraftCustomRulesVersionConifrmationModal from './modals/activate-custom-rules-draft-version-confirmation-modal'
import SnippetsViewer from '../../source-integration/snippets-viewer'
import {
  BASE_URL,
  BASE_URL_HOST,
  BASE_URL_PROTOCOL,
} from '../../../common/utils'
import useDSN from '../../../hooks/useDSN'
import { snippets } from './snippetsSetup'
import { showErrorNotification } from '../../../common/notifications'
import theme from '../../../common/theme'
import {
  ActiveVersionWithNotRulesAlert,
  renderActiveVersionAlert,
} from './alerts'
import { EmphasedText } from './common'
import { OperatingSystem } from '../../../common/sources/enums'
import DeactivateCustomRulesVersionConfirmationModal from './modals/deactivate-custom-rules-draft-version-confirmation-modal'

type Props = {
  activeVersion: any
  draftVersion: any
  approver?: string
  approveDate?: Date
  onReview: (status: string, comment: string) => void
  onDeactivate: () => void
  reviewError: any
  isSubmittingReview: boolean
  isDeactivatingVersion: boolean
  teamKey: string
  userApiKey: string
  loginType: LoginType
}

const CustomRules = ({
  activeVersion,
  draftVersion,
  approver,
  approveDate,
  onReview,
  onDeactivate,
  reviewError,
  isSubmittingReview,
  isDeactivatingVersion,
  teamKey,
  userApiKey,
  loginType,
}: Props) => {
  const { dsn } = useDSN(teamKey, userApiKey, loginType)
  const [
    showActivateConfirmationModal,
    setShowActivateConfirmationModal,
  ] = useState(false)
  const [
    showRejectConfirmationModal,
    setShowRejectConfirmationModal,
  ] = useState(false)
  const [
    showDeactivateConfirmationModal,
    setShowDeactivateConfirmationModal,
  ] = useState(false)
  const [reviewComment, setReviewComment] = useState('')
  const [operatingSystem, setOperatingSystem] = useState(
    OperatingSystem.MACLINUX
  )

  useEffect(() => {
    if (reviewError) {
      showErrorNotification('Review failed', reviewError)
    }
  }, [reviewError])

  const onReviewSubmit = ({ status, comment }, { setSubmitting }) => {
    setReviewComment(comment)
    if (status === CustomRulesVersionStatus.Active) {
      setShowActivateConfirmationModal(true)
    } else {
      setShowRejectConfirmationModal(true)
    }
    setSubmitting(false)
  }

  const onReviewConfirm = async (status: CustomRulesVersionStatus) => {
    await onReview(status, reviewComment)
    setShowRejectConfirmationModal(false)
    setShowActivateConfirmationModal(false)
  }

  const renderFeedbackForm = () => {
    return (
      <PanelForm
        onSubmit={onReviewSubmit}
        validationSchema={Yup.object({
          comment: Yup.string()
            .required('Review comment is required.')
            .min(4)
            .max(128),
          status: Yup.string().required(),
        })}
        initialValues={{
          comment: '',
          status: CustomRulesVersionStatus.Active,
        }}
      >
        <SelectFieldSection
          name="status"
          label="Review status"
          options={[
            {
              value: CustomRulesVersionStatus.Active,
              label: 'Active',
              key: CustomRulesVersionStatus.Active,
            },
            {
              value: CustomRulesVersionStatus.Rejected,
              label: 'Rejected',
              key: CustomRulesVersionStatus.Rejected,
            },
          ]}
          required
        />
        <InputFieldSection
          name="comment"
          label="Summarize your review"
          description="Write a short summary of the review, findings, and main causes for selected status"
          placeholder="This version contains..."
          required
          merge
        />
        <SubmitSection text="Submit Review" />
      </PanelForm>
    )
  }

  const changeOperatingSystem = useCallback(() => {
    setOperatingSystem((prevOperationSystem) => {
      if (prevOperationSystem === OperatingSystem.MACLINUX) {
        return OperatingSystem.WINDOWS
      }
      return OperatingSystem.MACLINUX
    })
  }, [])

  const renderSnippets = () => {
    return (
      <Card sx={{ mb: '40px' }} title="How to create new custom rules version?">
        <Box>
          <Flex
            sx={{
              marginBottom: '10px',
              marginTop: '10px',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <ExclamationCircleOutlined style={{ marginRight: '5px' }} /> Make
              sure to use supported Spectral version
            </Box>
            <Radio.Group
              onChange={changeOperatingSystem}
              value={operatingSystem}
              optionType="button"
              options={[
                { label: 'Mac/Linux', value: OperatingSystem.MACLINUX },
                { label: 'Windows', value: OperatingSystem.WINDOWS },
              ]}
            />
          </Flex>
          <SnippetsViewer
            dynamicData={{
              teamKey,
              dsn,
              baseUrl: BASE_URL,
              baseUrlProtocol: BASE_URL_PROTOCOL,
              baseUrlHost: BASE_URL_HOST,
              digestLinks: [],
            }}
            snippets={snippets[operatingSystem]}
            isScansSettingHidden
            instructions={undefined}
          />
        </Box>
      </Card>
    )
  }

  const renderDiffEditor = () => {
    return (
      <ContentPanel>
        <Box style={{ borderBottom: theme.stylebook.borders.contentPanel }}>
          <ContentPanelHeader>
            Compare currect custom rules{' '}
            <EmphasedText>Active version</EmphasedText> VS{' '}
            <EmphasedText>New version</EmphasedText>
          </ContentPanelHeader>
          <Box
            sx={{
              '.codeMarker': {
                background: theme.stylebook.text.editor.diffColor,
                position: 'absolute',
                zIndex: 20,
              },
            }}
          >
            <DiffEditor
              value={[yaml.dump(activeVersion), yaml.dump(draftVersion)]}
              height="450px"
              width="100%"
              mode="yaml"
              fontSize={14}
              theme="eclipse"
            />
          </Box>
        </Box>
        {renderFeedbackForm()}
      </ContentPanel>
    )
  }

  const renderStandardEditor = (
    version: any,
    panelHeaderText: ReactNode,
    renderForm: boolean
  ) => {
    return (
      <ContentPanel>
        <Box style={{ borderBottom: theme.stylebook.borders.contentPanel }}>
          <ContentPanelHeader>{panelHeaderText}</ContentPanelHeader>
          <AceEditor
            readOnly
            value={yaml.dump(version)}
            height="500px"
            width="100%"
            mode="yaml"
            fontSize={14}
            theme="eclipse"
            name="configuration"
            editorProps={{ $blockScrolling: true }}
          />
        </Box>
        {renderForm && renderFeedbackForm()}
      </ContentPanel>
    )
  }

  const renderEditor = () => {
    if (draftVersion && activeVersion) {
      return renderDiffEditor()
    }

    if (draftVersion) {
      return (
        <>
          {renderStandardEditor(
            draftVersion,
            <Flex>
              Please review custom rules{' '}
              <EmphasedText>new version:</EmphasedText>
            </Flex>,
            true
          )}
        </>
      )
    }

    if (activeVersion) {
      if (activeVersion.length === 0) {
        return ActiveVersionWithNotRulesAlert
      }
      return renderStandardEditor(
        activeVersion,
        <Flex>
          Current custom rules <EmphasedText>active version:</EmphasedText>
        </Flex>,
        false
      )
    }

    return null
  }

  const onDeactivationButtonClicked = useCallback(() => {
    setShowDeactivateConfirmationModal(true)
  }, [])

  const onDeactivationModalCancel = useCallback(() => {
    setShowDeactivateConfirmationModal(false)
  }, [])

  return (
    <Box sx={{ p: '40px' }}>
      <PageTitle>Custom Rules</PageTitle>
      <Flex sx={{ mb: '30px', mt: '10px', justifyContent: 'space-between' }}>
        <Box>
          <Box sx={{ mb: '7px' }}>
            Supported in Spectral version{' '}
            <strong>
              <code>1.9.131</code>
            </strong>{' '}
            and above.
          </Box>
          <Box>
            Enhance your findings by creating custom rules and apply them on all
            organization scans
          </Box>
          <Flex>
            In order to get instructions for creating custom rules please refer
            to our{' '}
            <a
              style={{ marginLeft: '5px' }}
              href={`${DOCS_URL}custom-rules`}
              target="_blank"
              rel="noopener noreferrer"
            >
              docs
            </a>
          </Flex>{' '}
        </Box>
        {activeVersion && (
          <Box>
            <Tooltip title="Deactivate the current active custom rules version">
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={onDeactivationButtonClicked}
              >
                Deactivate version
              </Button>
            </Tooltip>
          </Box>
        )}
      </Flex>

      <Box sx={{ mb: '40px' }}>
        {renderActiveVersionAlert(
          activeVersion,
          draftVersion,
          approver,
          approveDate
        )}
      </Box>
      <Box sx={{ mb: '40px' }}>{renderEditor()}</Box>
      <Box sx={{ mb: '30px' }}>{renderSnippets()}</Box>

      <DeactivateCustomRulesVersionConfirmationModal
        visible={showDeactivateConfirmationModal}
        isLoading={isDeactivatingVersion}
        onOk={onDeactivate}
        onCancel={onDeactivationModalCancel}
      />

      <RejectDraftCustomRulesVersionConifrmationModal
        visible={showRejectConfirmationModal}
        isLoading={isSubmittingReview}
        onOk={() => onReviewConfirm(CustomRulesVersionStatus.Rejected)}
        onCancel={() => setShowRejectConfirmationModal(false)}
      />

      <ActivateDraftCustomRulesVersionConifrmationModal
        visible={showActivateConfirmationModal}
        isLoading={isSubmittingReview}
        onOk={() => onReviewConfirm(CustomRulesVersionStatus.Active)}
        onCancel={() => setShowActivateConfirmationModal(false)}
      />
    </Box>
  )
}

export default CustomRules
