/** @jsx jsx */
import { WarningOutlined } from '@ant-design/icons'
import { Box, jsx } from 'theme-ui'
import { Popover } from 'antd'
import { formatDistanceToNow } from 'date-fns'
import { IssueStatus } from '@spectral/types'
import theme from '../../common/theme'

const RegressionPopOver = ({ originalCreatedAt, status }) => {
  return originalCreatedAt && status && status !== IssueStatus.Resolved ? (
    <Box>
      <Popover
        content={
          <Box>
            <Box>
              This issue was first seen{' '}
              {formatDistanceToNow(new Date(originalCreatedAt))} ago
            </Box>
          </Box>
        }
        title="Regression"
        trigger="hover"
      >
        <WarningOutlined style={{ color: theme.stylebook.colors.red }} />
      </Popover>
    </Box>
  ) : null
}

export default RegressionPopOver
