import { digestInfo, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const bitbucketPipeline = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'Bitbucket Pipeline',
    content: 'Integrate as a test or script step',
    tileKey: 'bitbucketPipeline',
    selfHostedRelevantProviders: [selfHostedProviders.bitbucketServer],
    snippets: [
      {
        title:
          'Store your SPECTRAL_DSN in <a href="https://support.atlassian.com/bitbucket-cloud/docs/variables-and-secrets/" target="_blank" rel="noopener noreferrer">Bitbucket Pipeline Variables</a>',
        code: `{dsn}`,
        type: 'yaml',
      },
      {
        title:
          "Here's an example of Bitbucket Pipeline configuration that uses Spectral:",
        code: `image: atlassian/default-image:2

pipelines:
  default:
    - parallel:
      - step:
          name: 'Install & run Spectral'
          script:
            - curl -L "{baseUrl}/latest/x/sh?dsn=$SPECTRAL_DSN" | sh
            - $HOME/.spectral/spectral scan --ok {options}
`,
        type: 'yaml',
        postDescription: digestInfo,
      },
    ],
  },
}
