import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { IssueIgnoreAction, ReportIssue } from '@spectral/types'
import { Button, Popover, Tooltip } from 'antd'
import React from 'react'
import IgnoreMenu from '../ignore-panel'

interface Props {
  onIgnoreClicked: (any) => void
  onSnoozeClicked: (any) => void
  selectedIssues: Array<ReportIssue>
  compact?: boolean
  buttonType: string
}

const IgnoreButton = ({
  onIgnoreClicked,
  onSnoozeClicked,
  selectedIssues,
  compact = false,
  buttonType,
}: Props) => {
  const ignored = selectedIssues.filter((issue) => issue.ignore.isIgnored)
  const notIgnored = selectedIssues.filter((issue) => !issue.ignore.isIgnored)
  const view: string =
    // eslint-disable-next-line no-nested-ternary
    (ignored.length === 0 && notIgnored.length === 0) ||
    (ignored.length !== 0 && notIgnored.length !== 0)
      ? 'disabled'
      : ignored.length > notIgnored.length
      ? `exclude`
      : `ignore`
  switch (view) {
    case 'disabled':
      return (
        <Tooltip title={compact && 'Ignore'}>
          <Button
            shape={compact ? 'circle' : null}
            icon={<EyeInvisibleOutlined />}
            disabled
          >
            {!compact && 'Ignore'}
          </Button>
        </Tooltip>
      )
    case 'ignore':
      return (
        <Popover
          placement="bottomLeft"
          title={null}
          trigger="click"
          content={IgnoreMenu({
            onIgnoreClicked,
            onSnoozeClicked,
            selectedIssues,
            buttonType,
          })}
        >
          <Tooltip title={compact && 'Ignore'}>
            <Button
              shape={compact ? 'circle' : null}
              icon={<EyeInvisibleOutlined />}
            >
              {!compact && `Ignore (${selectedIssues.length})`}
            </Button>
          </Tooltip>
        </Popover>
      )
    default:
      return (
        <Tooltip title={compact && 'Remove ignore'}>
          <Button
            icon={<EyeOutlined />}
            shape={compact ? 'circle' : null}
            onClick={() =>
              onIgnoreClicked({
                issues: selectedIssues,
                ignoreAction: IssueIgnoreAction.remove,
                buttonType,
              })
            }
            disabled={view === 'disabled'}
          >
            {!compact && `Remove ignore (${selectedIssues.length})`}
          </Button>
        </Tooltip>
      )
  }
}

export default ({
  onIgnoreClicked,
  onSnoozeClicked,
  selectedIssues,
  compact,
  buttonType,
}: Props) => {
  return (
    <IgnoreButton
      onSnoozeClicked={onSnoozeClicked}
      onIgnoreClicked={onIgnoreClicked}
      selectedIssues={selectedIssues}
      compact={compact}
      buttonType={buttonType}
    />
  )
}
