import { digestInfo, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const jenkins = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'Jenkins',
    content: 'Integrate as a step in Jenkins pipelines',
    tileKey: 'jenkins',
    selfHostedRelevantProviders: [
      selfHostedProviders.gitlabSelfHosted,
      selfHostedProviders.githubEnterprise,
      selfHostedProviders.bitbucketServer,
    ],
    snippets: [
      {
        title:
          'Set up your DSN in <a href="https://www.jenkins.io/doc/book/pipeline/jenkinsfile/#handling-credentials" target="_blank" rel="noopener noreferrer">Jenkins Credentials store</a>',
        code: `{dsn}`,
        type: 'shell',
      },
      {
        title: "Here's an example run of Spectral on Jenkins:",
        code: `pipeline {
  agent any
  environment {
    SPECTRAL_DSN = credentials('spectral-dsn')
  }
  stages {
    stage('install Spectral') {
      steps {
        sh "curl -L '{baseUrl}/latest/x/sh?dsn=$SPECTRAL_DSN' | sh"
      }
    }
    stage('scan for issues') {
      steps {
        sh "$HOME/.spectral/spectral scan --ok {options}"
      }
    }
    stage('build') {
      steps {
        // your build scripts
        sh "./build.sh"
      }
    }
  }
}`,
        type: 'shell',
        postDescription: digestInfo,
      },
    ],
  },
}
