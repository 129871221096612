/** @jsx jsx */
import { Modal } from 'antd'
import { jsx, Box } from 'theme-ui'
import { Loading } from '../../components/loading'

const InProgressModal = () => {
  return (
    <Modal
      title="Scan in progress…"
      visible
      closable={false}
      keyboard={false}
      maskClosable={false}
      footer={null}
    >
      <Box>
        <Loading />
      </Box>
    </Modal>
  )
}

export default InProgressModal
