import React, { useEffect } from 'react'
import { Box } from 'theme-ui'
import { useSelector, useDispatch } from 'react-redux'
import { PlanType } from '@spectral/types'
import { Page } from '../../components/page'
import { AssetsSettings } from '../../blocks/settings/assets'
import { select, Dispatch } from '../../redux/store'
import RestrictedArea from '../restricted-area/restricted-area'

export default () => {
  const dispatch: Dispatch = useDispatch()
  const { assetMapping, isLoading } = useSelector(select.Teams.assetMapping)
  const hasTeamPlanOrUp = useSelector(select.Plan.hasTeamPlanOrUp)

  useEffect(() => {
    if (!assetMapping && hasTeamPlanOrUp) dispatch.Teams.fetchAssetMapping()
  }, [dispatch.Teams, assetMapping, hasTeamPlanOrUp])

  const updateMappings = dispatch.Teams.setTeamAssetMapping

  return (
    <Page name="SETTINGS_ASSETS" title="Assets Settings" isLoading={isLoading}>
      <RestrictedArea
        minimumPlan={PlanType.Team}
        featureDisplayName="Asset Mapping and Teams"
      >
        <Box sx={{ padding: '40px' }}>
          {assetMapping && (
            <AssetsSettings
              settings={{
                assetMapping: {
                  data: assetMapping,
                  onSave: updateMappings,
                },
              }}
            />
          )}
        </Box>
      </RestrictedArea>
    </Page>
  )
}
