import React from 'react'
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import qs from 'query-string'

import { useLocation } from 'react-router'
import * as Sentry from '@sentry/react'
import { routes, paths } from './routes'
import App from './app'
import { isEmbedded, isCypress } from './common/utils'

function Private({ children }) {
  const { pathname, search } = useLocation()
  const isLoggedIn = useSelector((state) => state.Auth.user)
  const returnToParams = qs.parse(search)
  returnToParams.returnTo = pathname + search
  if (isLoggedIn) {
    return children
  }

  if (isEmbedded() && !isCypress()) {
    return (
      <Redirect
        to={{
          pathname: paths.open.errorPage,
        }}
      />
    )
  }

  return (
    <Redirect
      to={{
        pathname: paths.open.signin,
        search: qs.stringify(returnToParams),
      }}
    />
  )
}

export default function Routes() {
  return (
    <Sentry.ErrorBoundary fallback={<p>Loading failed! Please reload.</p>}>
      <Router>
        <Helmet defaultTitle="Spectral" titleTemplate="%s - Spectral" />
        <Switch>
          {routes.open.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact}>
              <route.component />
            </Route>
          ))}
          <Private>
            <App />
          </Private>
        </Switch>
      </Router>
    </Sentry.ErrorBoundary>
  )
}
