import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import { PagerDutyIntegrations } from '../../../../blocks/settings/integrations/pager-duty/pager-duty'

export const PagerDutyIntegrationConnected = () => {
  const { pdSettings } = useSelector(select.Teams.teamSettings)
  const { isTestInProgress } = useSelector(select.Settings.pagerDutySettings)
  const { enabled } = pdSettings
  const dispatch = useDispatch()
  return (
    <PagerDutyIntegrations
      enabled={enabled}
      togglePagerDuty={dispatch.Teams.setPagerDutyIntegrationStatus}
      setPagerDutySettings={dispatch.Teams.setPagerDutySettings}
      routingServiceKey={pdSettings.routingServiceKey}
      isTestInProgress={isTestInProgress}
      onTestClicked={dispatch.Settings.testPagerDuty}
    />
  )
}
