import React, { useEffect, useState } from 'react'
import L from 'lodash'
import * as Yup from 'yup'
import { MondayOption } from '../monday-option'
import {
  FieldSectionLayout,
  PanelForm,
  SelectFieldSection,
} from '../../../components/panels/content'

const toWorkspaceOptions = (workspaces) => {
  return L.map(workspaces, (ws) => ({
    value: ws.id,
    label: ws.name,
    key: `workspace_${ws.name}`,
  }))
}

const toBoardOptions = (workspaces, selectedWorkspace) => {
  if (!workspaces || !(workspaces.length > 0) || !selectedWorkspace) return []
  return L(workspaces)
    .filter((ws) => ws.id === selectedWorkspace)
    .first()
    ?.boards.map((board) => ({
      value: board?.id,
      label: board?.name,
      key: `board_${board.id}`,
    }))
}

export const FetcherByWorkspaces = ({ workspaces, onSubmit }) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState(null)
  const workspaceOptions = toWorkspaceOptions(workspaces)
  const boardOptions = toBoardOptions(workspaces, selectedWorkspace)

  useEffect(() => {
    if (!selectedWorkspace && workspaceOptions && workspaceOptions.length > 0) {
      setSelectedWorkspace((L.first(workspaceOptions) as any)?.value)
    }
  }, [workspaceOptions, selectedWorkspace, setSelectedWorkspace])

  const initialValues = {
    workspaceId: selectedWorkspace,
  }

  return (
    <PanelForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={Yup.object({
        boardId: Yup.string().required('Required'),
      })}
    >
      <SelectFieldSection
        name="workspaceId"
        label="Workspace"
        placeholder="Workspace"
        layout={FieldSectionLayout.Vertical}
        options={workspaceOptions}
        filterOption={(input, option) =>
          option.children.props.label
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        onRenderOption={({ label }) => <MondayOption label={label} />}
        onSelectAction={setSelectedWorkspace}
        required
        merge
      />
      <SelectFieldSection
        name="boardId"
        label="Board"
        placeholder="Board"
        layout={FieldSectionLayout.Vertical}
        options={boardOptions}
        filterOption={(input, option) =>
          option.children.props.label
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        onRenderOption={({ label }) => <MondayOption label={label} />}
        onSelectAction={(boardId) => onSubmit({ boardId })}
        required
        merge
      />
    </PanelForm>
  )
}

export default FetcherByWorkspaces
