import { v4 as uuidv4 } from 'uuid'
import Track from '../../../common/track'

export type IWizardTracking = {
  started: () => void
  ended: () => void
  progress: ({ fromStep }) => void
  back: ({ fromStep }) => void
  dataSourceSelected: ({ mode, dataSource }) => void
  modeSelected: ({ mode }) => void
  finishedGetStated: () => void
  quickScanExecute: ({ url }) => void
  quickScanBlurInput: ({ target: { value } }) => void
  snippetCopied: ({ value }) => void
  welcomeButtonClicked: () => void
  init: ({ teamPid, teamName }) => void
}
class WizardTracking implements IWizardTracking {
  correlationId = null

  teamPid = null

  teamName = null

  tracker = null

  constructor(tracker) {
    this.tracker = tracker
  }

  init({ teamPid, teamName }) {
    this.correlationId = uuidv4()
    this.teamPid = teamPid
    this.teamName = teamName
  }

  started() {
    this.tracker('WIZARD_STARTED', {
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  ended() {
    this.tracker('WIZARD_ENDED', {
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  progress({ fromStep }) {
    this.tracker('WIZARD_PROGRESS', {
      fromStep,
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  back({ fromStep }) {
    this.tracker('WIZARD_BACK', {
      fromStep,
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  dataSourceSelected({ mode, dataSource }) {
    this.tracker('WIZARD_DATA_SOURCE_SELECTED', {
      mode,
      dataSource,
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  modeSelected({ mode }) {
    this.tracker('WIZARD_MODE_SELECTED', {
      mode,
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  finishedGetStated() {
    this.tracker('WIZARD_GET_STARTED_FINISHED', {
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  quickScanExecute({ url }) {
    this.tracker('WIZARD_QUICK_SCAN_EXECUTE', {
      url,
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  quickScanBlurInput({ target: { value } }) {
    this.tracker('WIZARD_QUICK_SCAN_INPUT_BLUR', {
      url: value,
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  welcomeButtonClicked() {
    this.tracker('WIZARD_WELCOME_BUTTON_CLICKED', {
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  snippetCopied({ value }) {
    this.tracker('WIZARD_SNIPPET_COPY', {
      value,
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }

  githubAppPublicScan() {
    this.tracker('GITHUBAPP_PUBLIC_REPO_SCAN', {
      uuid: this.correlationId,
      teamPid: this.teamPid,
      teamName: this.teamName,
    })
  }
}

const mixPanelTracker = Track.event
// eslint-disable-next-line no-console
const consoleTracker = console.log

const mixPanelTracking = new WizardTracking(mixPanelTracker)
const consoleTracking = new WizardTracking(consoleTracker)

export { mixPanelTracking, consoleTracking }
