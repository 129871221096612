import { scannerLocationInstruction, scannerOptions } from './shared'
import { SourceIntegrationComponent } from '../enums'

const gitlabInstructions = {
  tooltip:
    'Personal token give a permission to clone GitLab repository. "api" scope must be checked in order for the spectral scanner to get access to the repositories.',
  text:
    'Use existing GitLab Personal Token (or issue a new one <a href="https://gitlab.com/-/profile/personal_access_tokens" target="_blank" rel="noopener noreferer">here</a>), with "api" scope checked.',
}

export const auditGitlab = {
  mode: 'audit',
  component: SourceIntegrationComponent.SnippetWindow,
  'MAC/Linux': {
    title: 'Gitlab',
    content: 'Audit repos, groups, and organizations.',
    tileKey: 'gitlab',
    snippets: [
      {
        title: 'Scan Gitlab repo',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral gitlab -k repo -t [GITLAB_TOKEN] [YOUR_REPO] {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR_REPO',
            example: 'https://gitlab.com/acme-corp-demo/kubernetes',
            explain: 'full path to the repository.',
          },
        ],
      },
      {
        title: 'Scan Gitlab user',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral gitlab -k user -t [GITLAB_TOKEN] [YOUR_USER] {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR_USER',
            example: 'yourmaggie',
          },
        ],
      },
      {
        title:
          'Scan Gitlab group (scan your entire org by adding “all-groups” parameter)',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral gitlab -k group -t [GITLAB_TOKEN] [YOUR-GROUP] {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR-GROUP',
            example: 'ACME-Corp-Demo',
          },
        ],
      },
    ],
    instructions: [
      scannerLocationInstruction,
      gitlabInstructions,
      {
        text: scannerOptions,
      },
    ],
  },
  Windows: {
    title: 'Gitlab',
    content: 'Audit repos, groups, and organizations.',
    tileKey: 'gitlab',
    snippets: [
      {
        title: 'Scan Gitlab repo',
        code:
          '$ spectral.exe gitlab -k repo -t [GITLAB_TOKEN] [YOUR_REPO] --dsn {dsn} {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR_REPO',
            explain: 'full path to the repository.',
            example: 'https://gitlab.com/acme-corp-demo/kubernetes',
          },
        ],
      },
      {
        title: 'Scan Gitlab user',
        code:
          '$ spectral.exe gitlab -k user -t [GITLAB_TOKEN] [YOUR_USER] --dsn {dsn} {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR_USER',
            example: 'yourmaggie',
          },
        ],
      },
      {
        title:
          'Scan Gitlab group (scan your entire org by adding “all-groups” parameter)',
        code:
          '$ spectral.exe gitlab -k group -t [GITLAB_TOKEN] [YOUR-GROUP] --dsn {dsn} {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR-GROUP',
            example: 'ACME-Corp-Demo',
          },
        ],
      },
    ],
    instructions: [
      scannerLocationInstruction,
      gitlabInstructions,
      {
        text: scannerOptions,
      },
    ],
  },
}
