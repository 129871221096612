import { isEmpty } from 'lodash'
import { useCallback, useState } from 'react'

const filterDateFormat = 'MM/DD/yyyy HH:mm:ss'
export const DEFAULT_MAX_ITEMS_TO_DISPLAY = 3
const useFiltersActions = ({ setFilter, trackFilter, clearFilter }) => {
  const [currentCountItemsToDisplay, setCurrentCountItemsToDisplay] = useState(
    {}
  )
  const onExpandFilter = useCallback(
    (filterKey, itemsToDisplay) => {
      setCurrentCountItemsToDisplay({
        ...currentCountItemsToDisplay,
        [filterKey]: itemsToDisplay,
      })
    },
    [setCurrentCountItemsToDisplay, currentCountItemsToDisplay]
  )

  const onCollapseFilter = useCallback(
    (filterKey) => {
      setCurrentCountItemsToDisplay({
        ...currentCountItemsToDisplay,
        [filterKey]: DEFAULT_MAX_ITEMS_TO_DISPLAY,
      })
    },
    [setCurrentCountItemsToDisplay, currentCountItemsToDisplay]
  )

  const onCheckboxFilterCheck = useCallback(
    (filterKey) => (checkedKeys) => {
      setFilter({ [filterKey]: checkedKeys })
      trackFilter?.(filterKey, checkedKeys.length)
    },
    [setFilter, trackFilter]
  )

  const onCheckboxFiltersClear = useCallback(
    (filterKeys: Array<string>) => {
      const filtersToUpdate = filterKeys.reduce(
        (toUpdate, filterKey) => {
          toUpdate[filterKey] = []
          return toUpdate
        },
        { page: 1 }
      )
      setFilter(filtersToUpdate)
      clearFilter?.(filterKeys)
      trackFilter?.(filterKeys, 0)
    },
    [setFilter, trackFilter, clearFilter]
  )

  const onDateFilterChanged = useCallback(
    (filterName) => (datesRange) => {
      const datesRangeFormat = datesRange && [
        datesRange[0]?.startOf('day').format(filterDateFormat),
        datesRange[1]?.endOf('day').format(filterDateFormat),
      ]

      if (!isEmpty(datesRangeFormat)) {
        setFilter({
          [`${filterName}Start`]: datesRangeFormat[0],
          [`${filterName}End`]: datesRangeFormat[1],
        })

        trackFilter?.(filterName, {
          startDate: datesRangeFormat[0],
          endDate: datesRangeFormat[1],
        })
      } else {
        clearFilter?.([`${filterName}Start`, `${filterName}End`])
      }
    },
    [setFilter, trackFilter, clearFilter]
  )

  return {
    onExpandFilter,
    onCollapseFilter,
    onCheckboxFilterCheck,
    onCheckboxFiltersClear,
    onDateFilterChanged,
    currentCountItemsToDisplay,
  }
}

export default useFiltersActions
