import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Select, Button } from 'antd'
import styled from 'styled-components'
import {
  getViewRolesWithoutOwner,
  RoleDisplayText,
  RoleType,
} from '@spectral/types'
import { AiOutlineDelete } from 'react-icons/ai'
import { UserNameView } from './common-view/member-username-common-view'
import Membership from '../../model/membership'
import EmbeddedHidden from '../../containers/embeded-hidden/embeded-hidden'

const StyledTagsSelect = styled(Select)`
  .ant-select-selection-overflow {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`
const selectOrgTeams = ({ member, orgTeams, changeMemberOrgTeams }) => (
  <Box>
    <StyledTagsSelect
      mode="tags"
      placeholder="Assign member to team"
      maxTagCount={2}
      defaultValue={member.orgTeams ? member.orgTeams : []}
      onChange={(val) => {
        changeMemberOrgTeams(val, member)
      }}
      style={{ width: 300 }}
    >
      {orgTeams.map((team) => (
        <Select.Option
          value={team.name}
          pid={member.pid}
          key={`select_org_team_${team.orgTeamId}`}
        >
          {team.name}
        </Select.Option>
      ))}
    </StyledTagsSelect>
  </Box>
)

const selectRoleMember = ({ member, changeMemberRole }) => (
  <Box>
    <Select
      defaultValue={member.role}
      style={{ width: 130, gridColumnStart: 2 }}
      onChange={changeMemberRole}
    >
      {getViewRolesWithoutOwner().map((role) => (
        <Select.Option
          value={role}
          pid={member.pid}
          key={`select_role_members_${role}_${member.username}`}
        >
          {RoleDisplayText[role]}
        </Select.Option>
      ))}
    </Select>
  </Box>
)

const removeButton = ({ member, removeMember }) => (
  <Button
    size="small"
    danger
    style={{ fontSize: '25px', height: '40px', paddingTop: '2px' }}
    onClick={() => removeMember(member.username)}
  >
    <AiOutlineDelete />
  </Button>
)

const adminView = ({
  member,
  orgTeams,
  removeMember,
  changeMemberRole,
  changeMemberOrgTeams,
}) => {
  // @ts-ignores
  const hideAction = member.role === RoleType.Owner || member.isCurrentUser

  return (
    <Flex
      sx={{ mb: '16px', justifyContent: 'space-between' }}
      key={member.username}
    >
      <UserNameView member={member} />
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ margin: '0 10px 0 10px', width: '300px' }}>
          {Membership.isAssignableToOrgTeam(member) &&
            selectOrgTeams({ member, orgTeams, changeMemberOrgTeams })}
        </Box>
        <EmbeddedHidden>
          <Box
            sx={{
              margin: '0 20px 0 10px',
              width: '120px',
              textAlign: 'center',
            }}
          >
            {!hideAction && selectRoleMember({ member, changeMemberRole })}
            {hideAction && <Text>{member.role}</Text>}
          </Box>
          <Box sx={{ margin: '0 16px 0 10px', width: '40px' }}>
            {!hideAction && removeButton({ member, removeMember })}
          </Box>
        </EmbeddedHidden>
      </Flex>
    </Flex>
  )
}

export default adminView
