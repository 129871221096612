import React from 'react'
import { Box } from 'theme-ui'
import theme from '../../common/theme'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, select } from '../../redux/store'

type Props = {
  children: any
}

const FiltersSidePanel = ({ children }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const {
    isFilterSideMenuExpanded: isExpanded
  } = useSelector(select.GlobalPreferences.globalPreferences)
  return (
    <Box
      sx={{
        width: isExpanded ? '330px' : '36px',
        transition: 'width 0.5s, background-color 0.5s, padding-right 0.5s',
        overflow: 'auto',
        paddingRight: isExpanded ? '30px' : '6px',
        height: '100%',
        paddingBottom: '30px',
        paddingTop: '6px',
        scrollbarGutter: 'stable',
        borderRight: `1px solid ${theme.stylebook.colors.gray[300]}`,
        '&:hover': {
          backgroundColor: isExpanded ? 'auto' : 'gray.100',
          cursor: isExpanded ? 'default' : 'pointer',
        },
      }}
      onClick={!isExpanded && dispatch.GlobalPreferences.toggleIsFilterSideMenuExpanded}
    >
      <Box sx={{
        position: 'relative', 
        textAlign: 'right', 
      }}>
        <Tooltip title={isExpanded ? 'Collapse filters panel' : 'Expand filters panel'}>
          <Button onClick={(event) => {
            event.stopPropagation()
            dispatch.GlobalPreferences.toggleIsFilterSideMenuExpanded()
            }} icon={isExpanded ? <ArrowLeftOutlined /> : <ArrowRightOutlined />} size="small" shape="circle" />
        </Tooltip>
      </Box>
      <Box sx={{display: isExpanded ? 'auto' : 'none'}}>
        {children}
      </Box>
    </Box>
  )
}

export default FiltersSidePanel
