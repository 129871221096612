import React from 'react'
import { useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import Dsn from '../../../../blocks/settings/teams/dsn/view'

const DSNConnected = () => {
  const currentTeam = useSelector(select.Teams.current)
  const { apiKey } = useSelector((state) => state.Auth.user)

  return <Dsn currentTeam={currentTeam} userApiKey={apiKey} />
}

export default DSNConnected
