import { FileSearchOutlined } from '@ant-design/icons'
import { removeFilePathSlash } from '@spectral/types'
import { Tooltip } from 'antd'
import React from 'react'
import { Box, Link, Text } from 'theme-ui'
import theme from '../../../common/theme'

const IssuePath = ({ issue, onClick, maxWidth = 700 }) => {
  const {
    blameUrl,
    uri,
    filePath,
    metadata,
    ignore: { isIgnored },
  } = issue
  const virtualPath = metadata?.virtual_path
  const filePathPreset = removeFilePathSlash(filePath)
  const pathText = metadata?.issueKey || metadata?.pageTitle || filePathPreset

  const url = virtualPath ? uri || blameUrl : blameUrl || uri

  let tooltipText = metadata?.stage
    ? 'View run'
    : `View source in file: ${filePathPreset}`

  if (virtualPath) tooltipText += ` » ${virtualPath}`

  return (
    <Box sx={{ maxWidth, mr: 2 }}>
      <Tooltip title={tooltipText}>
        <Link
          href={url}
          target="_blank"
          onClick={onClick}
          sx={{
            color: isIgnored
              ? theme.stylebook.colors.gray['500']
              : theme.stylebook.colors.text,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FileSearchOutlined style={{ marginRight: '2px' }} />
          <Text variant="ellipsis">{pathText}</Text>
        </Link>

        {virtualPath && (
          <Box sx={{ pl: 12 }}>
            <Text variant="ellipsis">└ {virtualPath}</Text>
          </Box>
        )}
      </Tooltip>
    </Box>
  )
}

export default IssuePath
