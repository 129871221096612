/** @jsx jsx */
import { Box, Flex, jsx, Text } from 'theme-ui'
import L from 'lodash'
import { Button, Popover, Tooltip } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'
import moment from 'moment'
import { memo } from 'memodiff'
import { AiFillCheckCircle } from 'react-icons/ai'
import { AssetStats } from '@spectral/types'
import { SeverityCountTag } from '../../components/tags/severity'
import Cube from '../../components/cube/cube'
import AssetTypeIcon from '../../components/asset-type-icon/AssetTypeIcon'

const MAX_CUBES_FOR_TEAM = 100

const SIZES = {
  xl: {
    itemsInRow: 10,
    maxRows: 10,
    minItems: 65,
    maxItems: MAX_CUBES_FOR_TEAM,
  },
  lg: {
    itemsInRow: 8,
    maxRows: 8,
    minItems: 37,
    maxItems: 64,
  },
  md: {
    itemsInRow: 6,
    maxRows: 6,
    minItems: 26,
    maxItems: 36,
  },
  sm: {
    itemsInRow: 5,
    maxRows: 5,
    minItems: 1,
    maxItems: 25,
  },
}

const TooltipGlobalStyle = createGlobalStyle`
  .ant-tooltip-inner {
    display: inline-block
  }
`

type Props = {
  className?: string
  teamName: string
  lastScanTime: string
  assets: Array<{
    name: string
    id: string
    assetId: string
    severity: string
    createdAt: Date
    uri: string
  }>
  totalNumberOfCritical: number
  totalNumberOfHigh: number
  totalNumberOfMedium: number
  totalNumberOfLow: number
  totalNumberOfInformational: number
  totalNumberOfIgnored: number
  numberOfHiddenRepositories: number
  onAssetClick: (assetId, assetKind) => void
  startTour: () => void
  highlightedAssetId?: string
  assetTypes: string[]
  onQuickScanHighlightComplete: () => void
}
const HiveCardFC = ({
  className = undefined,
  teamName,
  lastScanTime,
  assets,
  totalNumberOfCritical,
  totalNumberOfHigh,
  totalNumberOfMedium,
  totalNumberOfLow,
  totalNumberOfInformational,
  totalNumberOfIgnored,
  numberOfHiddenRepositories,
  onAssetClick,
  highlightedAssetId = null,
  assetTypes = [],
  onQuickScanHighlightComplete,
}: Props) => {
  function getViewSettings(numberOfItems) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(SIZES)) {
      const { minItems, maxItems } = value
      if (numberOfItems >= minItems && numberOfItems <= maxItems)
        return SIZES[key]
    }
    return Object.values(SIZES)[0]
  }
  const viewAssets = assets.slice(0, MAX_CUBES_FOR_TEAM)
  const viewSettings = getViewSettings(viewAssets.length)
  const dataInRows = viewAssets.reduce((result, cur, idx) => {
    const row = Math.floor(idx / viewSettings.itemsInRow)
    if (result[row]) result[row].push(cur)
    else result[row] = [cur]
    return result
  }, {})
  const numberOfRows = Object.keys(dataInRows).length

  return (
    <div
      className={className}
      sx={{
        padding: '20px',
        borderRadius: '4px',
        transition: 'all 0.2s',
        background: 'rgba(255, 255, 255, 0.6)',

        border: `1px solid}`,
        borderColor: '#eee',
        '&:hover': {
          background: 'white',
          boxShadow: '0px 4px 14px #e7e7f3',
          border: '1px solid',
          borderColor: '#eee',
        },
      }}
    >
      <TooltipGlobalStyle />
      <Flex sx={{ marginBottom: '40px' }}>
        <Flex sx={{ mr: '8px', mt: '4px' }}>
          {assetTypes.map((assetType) => (
            <Flex
              sx={{
                ml: '2px',
              }}
              key={`icon_${assetType}`}
            >
              <AssetTypeIcon type={assetType} />
            </Flex>
          ))}
        </Flex>
        <Flex sx={{ flexDirection: 'column' }}>
          <Text sx={{ fontWeight: 'bold', fontFamily: 'fira' }}>
            {' '}
            {teamName}
          </Text>
          <Text sx={{ fontSize: '12px', color: 'paleText' }}>
            {numberOfHiddenRepositories > 0
              ? assets.length + numberOfHiddenRepositories
              : assets.length}{' '}
            assets | <strong>{moment(lastScanTime).fromNow()}</strong>
          </Text>
        </Flex>
        <Flex
          sx={{
            marginLeft: 'auto',
            alignItems: 'flex-start',
          }}
        >
          {totalNumberOfCritical > 0 && (
            <SeverityCountTag
              sx={{ ml: 1 }}
              count={totalNumberOfCritical}
              countOnly
              key={`summary_sev_${AssetStats.Critical}`}
              kind={AssetStats.Critical}
            />
          )}
          {totalNumberOfHigh > 0 && (
            <SeverityCountTag
              sx={{ ml: 1 }}
              count={totalNumberOfHigh}
              countOnly
              key={`summary_sev_${AssetStats.High}`}
              kind={AssetStats.High}
            />
          )}
          {totalNumberOfMedium > 0 && (
            <SeverityCountTag
              sx={{ ml: 1 }}
              count={totalNumberOfMedium}
              countOnly
              key={`summary_sev_${AssetStats.Medium}`}
              kind={AssetStats.Medium}
            />
          )}
          {totalNumberOfLow > 0 && (
            <SeverityCountTag
              sx={{ ml: 1 }}
              count={totalNumberOfLow}
              countOnly
              key={`summary_sev_${AssetStats.Low}`}
              kind={AssetStats.Low}
            />
          )}
          {totalNumberOfInformational > 0 && (
            <SeverityCountTag
              sx={{ ml: 1 }}
              count={totalNumberOfInformational}
              countOnly
              key={`summary_sev_${AssetStats.Informational}`}
              kind={AssetStats.Informational}
            />
          )}
          {totalNumberOfIgnored > 0 && (
            <SeverityCountTag
              sx={{ ml: 1 }}
              count={totalNumberOfIgnored}
              countOnly
              key="summary_sev_ignored"
              kind={AssetStats.Ignore}
            />
          )}
        </Flex>
      </Flex>
      <Flex
        sx={{
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          paddingLeft: numberOfRows > 1 ? '21px' : '0',
        }}
      >
        {Object.keys(dataInRows).length === 0 ? (
          <Box sx={{ textAlign: 'center' }}>
            <AiFillCheckCircle size={20} />
            <Text sx={{ fontSize: '11px' }}>No Issues</Text>
          </Box>
        ) : (
          false
        )}
        {Object.keys(dataInRows).map((rowKey, idx) => {
          return (
            <Flex
              key={rowKey}
              sx={{
                marginLeft: (idx + 1) % 2 === 0 ? '42px' : '0',
                marginTop: '-12px',
                width: '100%',
              }}
            >
              {dataInRows[rowKey].map((asset) => {
                const cubeView = asset.severity
                const assetSeverityTags = L.filter(
                  [
                    {
                      severity: AssetStats.Critical,
                      counter: asset.totalCritical,
                    },
                    {
                      severity: AssetStats.High,
                      counter: asset.totalHigh,
                    },
                    {
                      severity: AssetStats.Medium,
                      counter: asset.totalMedium,
                    },
                    {
                      severity: AssetStats.Low,
                      counter: asset.totalLow,
                    },
                    {
                      severity: AssetStats.Informational,
                      counter: asset.totalInformational,
                    },
                    {
                      severity: AssetStats.Ignore,
                      counter: asset.totalIgnored,
                    },
                  ],
                  'counter'
                )
                const isHighlighted =
                  highlightedAssetId &&
                  asset.uri.toLowerCase() === highlightedAssetId.toLowerCase()

                const hideTooltip = () => {
                  onQuickScanHighlightComplete()
                }

                return (
                  <Popover
                    visible={isHighlighted}
                    title="Here's your new scan. Take a look inside?"
                    content={
                      <Flex sx={{ justifyContent: 'flex-end' }}>
                        <Button size="small" onClick={hideTooltip}>
                          Got it
                        </Button>
                      </Flex>
                    }
                    key={`${asset.assetId}_popover`}
                  >
                    <Tooltip
                      title={
                        <Box>
                          <Flex sx={{ alignItems: 'center' }}>
                            <AssetTypeIcon type={asset.kind} />
                            <Text
                              sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                color: 'white',
                                ml: '8px',
                              }}
                            >
                              {asset.name}
                            </Text>
                          </Flex>
                          <Flex sx={{ marginTop: 2 }}>
                            {assetSeverityTags.map((tag) => (
                              <Box
                                sx={{ marginRight: 1, marginBottom: 1 }}
                                key={`${tag.severity}${tag.counter}`}
                              >
                                <SeverityCountTag
                                  kind={tag.severity}
                                  count={tag.counter}
                                  countOnly
                                />
                              </Box>
                            ))}
                          </Flex>
                        </Box>
                      }
                      key={asset.id}
                    >
                      <Box
                        key={asset.id}
                        className={` ${
                          isHighlighted ? 'quick-scan-highlighted-card' : ''
                        }
                          e2e-test-dashboard-cube`}
                        onClick={() => {
                          onAssetClick(asset.assetId, asset.kind)
                        }}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Cube view={cubeView} />
                      </Box>
                    </Tooltip>
                  </Popover>
                )
              })}
            </Flex>
          )
        })}
        {numberOfHiddenRepositories > 0 ? (
          <Text sx={{ fontSize: '12px', mt: '8px' }}>
            +{numberOfHiddenRepositories} Repositories
          </Text>
        ) : (
          false
        )}
      </Flex>
    </div>
  )
}

const HiveCard = memo(HiveCardFC, L.isEqual)
// HiveCard.whyDidYouRender = true
export default HiveCard
