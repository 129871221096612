import { digestInfo, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const gcpbuild = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'Google Cloud Build',
    content: 'Integrate as a test or script step',
    tileKey: 'gcpbuild',
    selfHostedRelevantProviders: [
      selfHostedProviders.gitlabSelfHosted,
      selfHostedProviders.githubEnterprise,
      selfHostedProviders.bitbucketServer,
    ],
    snippets: [
      {
        title:
          'Store your SPECTRAL_DSN in <a href="https://cloud.google.com/cloud-build/docs/securing-builds/use-secrets" target="_blank" rel="noopener noreferrer">Google Cloud Secret Manager</a>',
        code: `{dsn}`,
        type: 'yaml',
      },
      {
        title: "Here's an example run of Spectral on Google Cloud Build:",
        code: `steps:
  - name: gcr.io/cloud-builders/gcloud
  
  id: Spectral
  entrypoint: bash
  args:
    - -c
    - |
      curl -L "{baseUrl}/latest/x/sh?dsn=$$SPECTRAL_DSN" | sh
      $$HOME/.spectral/spectral scan --ok {options}

  secretEnv: 
    - SPECTRAL_DSN

availableSecrets:
  secretManager:
    - versionName: projects/PROJECT_ID/secrets/SPECTRAL_DSN/versions/latest
      env: SPECTRAL_DSN
`,
        type: 'yaml',
        postDescription: digestInfo,
      },
    ],
  },
}
