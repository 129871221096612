import { OperatingSystem } from '../../common/sources/enums'
import { getIntegrationByModeAndOS } from '../../common/sources/sources'
import { ActionType } from './types'

export const initialState = {
  mode: null,
  dataSource: { title: null },
  currentStep: 0,
  operatingSystem: OperatingSystem.MACLINUX,
}

export const wizardReducer = (state, action) => {
  switch (action.type) {
    case ActionType.SET_MODE:
      return { ...state, mode: action.payload.mode }
    case ActionType.SET_DATA_SOURCE:
      return { ...state, dataSource: action.payload.dataSource }
    case ActionType.DECREASE_STEP:
      return { ...state, currentStep: state.currentStep - 1 }
    case ActionType.INCREASE_STEP:
      return { ...state, currentStep: state.currentStep + 1 }
    case ActionType.CHANGE_OPERATING_SYSTEM: {
      const newOS =
        state.operatingSystem === OperatingSystem.MACLINUX
          ? OperatingSystem.WINDOWS
          : OperatingSystem.MACLINUX
      return {
        ...state,
        operatingSystem: newOS,
        dataSource: getIntegrationByModeAndOS(
          state.mode,
          newOS,
          state.dataSource.tileKey
        ),
      }
    }
    case ActionType.RESET:
      return { ...initialState, ...action.payload?.overrideInitialState }
    default:
      throw new Error()
  }
}
