export enum ConfigType {
  REMOTE_CONFIG = 'remoteConfig',
  OVERRIDES = 'overrides',
}

export enum MatchIgnoresConfigValues {
  Text = 'matchText',
  LineText = 'matchLineText',
  RuleId = 'matchRuleId',
  RuleName = 'matchRuleName',
  Path = 'matchPath',
  FingerPrint = 'matchFingerprint',
}

export enum HardeningFeatures {
  Ok = 'ok',
  ExcludeTags = 'excludeTags',
  Exclude = 'exclude',
  Include = 'include',
  MatchIgnores = 'matchIgnores',
  MatchIgnoresConfig = 'matchIgnoresConfig',
  FailOnError = 'failOnError',
  FailOnCritical = 'failOnCritical',
  IgnoreRemoteConfig = 'ignoreRemoteConfig',
  Since = 'since',
  MaxSize = 'maxSize',
  ShowMatch = 'showMatch',
  SendLocalIgnores = 'sendLocalIgnores',
}

export enum FallbackMode {
  Strict = 'Strict',
  WarnAndProceed = 'WarnAndProceed',
}

export const DEFAULT_FALLBACK_MODE = FallbackMode.Strict
