import React from 'react'
import styled from 'styled-components'
import { Box } from 'theme-ui'
import { NavLink } from 'react-router-dom'
import { paths } from '../../routes'

const stylesColors = {
  menuItemTextColor: '#000000',
  menuItemActiveBackgroundColor: '#aedffc',
  hoverItemBackgroundColor: '#dff2fe',
  hoverItemTextColor: '#2a6496',
}

const EmbeddedMenu = ({ location, links }) => {
  const hive = links.hive || '#'
  const assets = links.assets || '#'
  const sources = links.sources || '#'
  const reports = links.reports || '#'

  return (
    <EmbeddedMenuStyled as="aside">
      <NavLink className="e2e-test-sidebar-dashboard-button" to={hive}>
        <MenuItemStyled isActiveLink={location?.pathname === hive}>
          Dashboard
        </MenuItemStyled>
      </NavLink>
      <NavLink className="e2e-test-sidebar-assets-button" to={assets}>
        <MenuItemStyled isActiveLink={location?.pathname === assets}>
          Assets
        </MenuItemStyled>
      </NavLink>
      <NavLink className="e2e-test-sidebar-sources-button" to={sources}>
        <MenuItemStyled isActiveLink={location?.pathname === sources}>
          Sources
        </MenuItemStyled>
      </NavLink>
      <NavLink className="e2e-test-sidebar-reports-button" to={reports}>
        <MenuItemStyled isActiveLink={location?.pathname === reports}>
          Reports
        </MenuItemStyled>
      </NavLink>
      <NavLink to={paths.closed.settings}>
        <MenuItemStyled
          isActiveLink={
            location?.pathname.indexOf(paths.closed.settings) !== -1
          }
        >
          Settings
        </MenuItemStyled>
      </NavLink>
    </EmbeddedMenuStyled>
  )
}

const EmbeddedMenuStyled = styled(Box)`
  width: 100%;
  font-size: 13px;
  font-weight: 200;
  font-family: 'Open Sans', sans-serif;
  margin-top: 23px;
`
const MenuItemStyled = styled(Box)`
  color: ${stylesColors.menuItemTextColor};
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 200;
  padding: 8px 18px;
  white-space: nowrap;
  background-color: ${({ isActiveLink }) =>
    isActiveLink && stylesColors.menuItemActiveBackgroundColor};
  :hover {
    background-color: ${stylesColors.hoverItemBackgroundColor};
    color: ${stylesColors.hoverItemTextColor};
  }
  a:visited {
    color: inherit;
  }
`
export default EmbeddedMenu
