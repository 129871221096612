import { digestInfo, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const githubActions = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'Github Actions',
    content: 'Integrate as a step in Github Actions',
    tileKey: 'githubActions',
    selfHostedRelevantProviders: [selfHostedProviders.githubEnterprise],
    snippets: [
      {
        title:
          'Store your SPECTRAL_DSN in <a href="https://docs.github.com/en/actions/configuring-and-managing-workflows/creating-and-storing-encrypted-secrets" target="_blank" rel="noopener noreferrer">Github Actions Secret Store</a>',
        code: `{dsn}`,
        type: 'yaml',
      },
      {
        title: "Here's an example Github workflow with Spectral:",
        code: `name: Main
on:
  push:
    branches: [ main ]
    
env:
  SPECTRAL_DSN: \${{ secrets.SPECTRAL_DSN }}

jobs:
  scan:
    runs-on: ubuntu-latest
    steps:
    - uses: actions/checkout@v2
    - name: Install and run Spectral CI
      uses: checkpointsw/spectral-github-action@v3
      with:
        spectral-dsn: \${{ env.SPECTRAL_DSN }}
        spectral-args: scan --ok {options}`,
        type: 'yaml',
        postDescription: digestInfo,
      },
    ],
  },
}
