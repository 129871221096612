import React, { useEffect } from 'react'
import { Box } from 'theme-ui'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../blocks/layout'
import { RootState, select } from '../../redux/store'
import { paths } from '../../routes'
import EmailVerificationAlert from '../../blocks/email-verification-alert'
import LicenseExpirationAlert from '../Alerts/plan-expiration-alert'
import EmbeddedLayout from '../../blocks/embedded-layout/embedded-layout'
import { isEmbedded, isCypress } from '../../common/utils'
import CriticalIssuesAlert from '../Alerts/critical-issues-banner'
import { RoleType } from '@spectral/types'

const USER_BAR = {
  links: [
    {
      allowedRoles: [RoleType.Admin, RoleType.Owner],
      text: 'Invite people to Spectral',
      link: `${paths.closed.settingsTeam}`,
    },
    { text: 'Settings', link: paths.closed.settings },
    {
      allowedRoles: [RoleType.Admin, RoleType.Owner],
      text: 'Team',
      link: paths.closed.settingsTeam,
    },
  ],
}

const LayoutConnected = ({ children }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    firstName,
    lastName,
    username,
    avatarUrl,
    isEmailVerified,
  } = useSelector((state) => state.Auth.user)
  const userSettings = useSelector((state) => state.Auth.userSettings)
  const { role } = useSelector((state) => state.Auth.user)
  const isCollapsed: boolean = useSelector(
    (state: RootState) => state.Sidebar.isCollapsed
  )
  const team = useSelector(select.Teams.current)
  const showUpgrade = useSelector(select.Plan.isFreePlan)

  useEffect(() => {
    dispatch.Sidebar.loadSidebarCollapse()
  }, [dispatch.Sidebar])

  const userbar = {
    user: {
      name: `${firstName} ${lastName}`,
      avatarUrl,
      username,
      notifications:
        isEmailVerified && userSettings?.notification?.email?.enabled,
    },
    team,
    links: USER_BAR.links.filter(
      (link) => !link.allowedRoles || link.allowedRoles.includes(role)
    ),
    logout: dispatch.Auth.logout,
    upgradePath: paths.closed.settingsPlans,
    showUpgrade,
  }

  const sidebarCollapseToggle = () => {
    dispatch.Sidebar.updateSidebarCollapse()
  }

  const onRenderAlerts = () => {
    return (
      <Box>
        <EmailVerificationAlert />
        <LicenseExpirationAlert />
        <CriticalIssuesAlert />
      </Box>
    )
  }

  return (
    <>
      {
        // we want to distinguish between standalone and when loading inside iframe (for example cloudguard)
        isEmbedded() && !isCypress() ? (
          <EmbeddedLayout location={location}>{children}</EmbeddedLayout>
        ) : (
          <Layout
            onRenderAlerts={onRenderAlerts}
            header={{ userbar }}
            location={location}
            sidebar={{ sidebarCollapseToggle, isCollapsed }}
          >
            {children}
          </Layout>
        )
      }
    </>
  )
}

export default LayoutConnected
