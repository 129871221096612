import { digestInfo, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const gitlabci = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'Gitlab CI/CD',
    content: 'Integrate as a test or script step',
    tileKey: 'gitlabci',
    selfHostedRelevantProviders: [selfHostedProviders.gitlabSelfHosted],
    snippets: [
      {
        title:
          'Store your SPECTRAL_DSN in <a href="https://docs.gitlab.com/ee/ci/variables/#custom-environment-variables" target="_blank" rel="noopener noreferrer">Gitlab CI/CD Variables</a>',
        code: `{dsn}`,
        type: 'yaml',
      },
      {
        title:
          "Here's an example of GitLab CI configuration that uses Spectral:",
        code: `build-job:
  stage: build
  script:
    - curl -L "{baseUrl}/latest/x/sh?dsn=$SPECTRAL_DSN" | sh
    # This takes your SPECTRAL_DSN from the variables store in Gitlab CI/CD
    - $HOME/.spectral/spectral scan --ok {options}
`,
        type: 'yaml',
        postDescription: digestInfo,
      },
    ],
  },
}
