import { Box, Message } from 'theme-ui'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { UNKNOWN_DETECTORS } from '@spectral/types'
import {
  ContentPanel,
  ContentPanelHeader,
  FieldSectionLayout,
  PanelForm,
  TextFieldSection,
} from '../../../../components/panels/content'
import ErrorMessage from '../../../../components/forms/error-message'
import Banner, { BannerType } from '../../../../components/banner'

const RulesInput = ({
  selectedAssetType,
  saveConfigurationError,
  currentIncludeRules = [],
  currentExcludeRules = [],
  disabled = false,
  onSubmit,
}) => {
  return (
    <Box sx={{ mt: '50px' }}>
      <ContentPanel>
        <ContentPanelHeader>
          {`Configure rules usage for ${selectedAssetType} scans`}
        </ContentPanelHeader>
        <Banner
          type={BannerType.Warning}
          messageText="These settings will not take effect if Spectral is run with --include-tags or --exclude-tags"
          actionButtonText=""
          actioButtonCallback={null}
          dismissButtonText=""
          dismissButtonCallback={null}
        />

        {saveConfigurationError?.errorId === UNKNOWN_DETECTORS && (
          <ErrorMessage
            error={`Unknown detectors: ${saveConfigurationError.data.join(
              ', '
            )}`}
            showIcon
          />
        )}
        <PanelForm
          onSubmit={(values) => {
            onSubmit({
              include: isEmpty(values.include)
                ? []
                : values.include.split(',').filter((x) => !!x),
              exclude: isEmpty(values.exclude)
                ? []
                : values.exclude.split(',').filter((x) => !!x),
            })
          }}
          initialValues={{
            include: currentIncludeRules.join(','),
            exclude: currentExcludeRules.join(','),
          }}
        >
          <TextFieldSection
            name="include"
            label="Include rules"
            description="Comma separated detector IDs to scan"
            placeholder="CLD001,DB002,..."
            layout={FieldSectionLayout.Vertical}
            disabled={disabled || !isEmpty(currentExcludeRules)}
            height={40}
            submit
            merge
          />
          <TextFieldSection
            name="exclude"
            label="Exclude rules"
            description="Comma separated detector IDs to exclude from scans"
            placeholder="CLD001,DB002,..."
            layout={FieldSectionLayout.Vertical}
            disabled={disabled || !isEmpty(currentIncludeRules)}
            height={40}
            submit
            merge
          />
        </PanelForm>
      </ContentPanel>
    </Box>
  )
}

export default RulesInput
