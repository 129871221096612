import React from 'react'
import { RoleType } from '@spectral/types'
import { Box, Flex, Text } from 'theme-ui'
import { BsFillPersonPlusFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { paths } from '../../routes'
import Restricted from '../../containers/role-based-render/restricted'

// using props to fix console error.
// see: https://github.com/react-component/menu/issues/142
export default () => {
  return (
    <Restricted roles={[RoleType.Owner, RoleType.Admin]}>
      <Box
        sx={{
          borderTop: '1px solid #d4dae2',
          paddingX: 3,
          paddingY: 2,
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <BsFillPersonPlusFill
            size={24}
            style={{
              marginRight: '10px',
              color: '#ccc',
            }}
          />
          <Link to={paths.closed.settingsTeam}>
            <Text variant="small">Invite new member</Text>
          </Link>
        </Flex>
      </Box>
    </Restricted>
  )
}
