/** @jsx jsx */
import { useSelector } from 'react-redux'
import { jsx, Box } from 'theme-ui'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import View from '../../blocks/sources'
import { Page, PageTitle } from '../../components/page'
import { paths } from '../../routes'
import WizardConnected from './wizard-connected'
import useQuerystring from '../../hooks/useQuerystring'
import {
  pocModePersistance,
  githubAppPostInstallPersistence,
} from '../../redux/persistence'
import { DOCS_URL } from '../../utils/constants'
import { SourceIntegrationKey } from '../../common/sources/enums'
import { features } from '../../common/features'

const POC = 'poc'

type Source = {
  displayName: string
  description?: string
  key: string
  handleClickSource?: Function
  hidden?: boolean
}

type Category = {
  displayName: string
  description?: string
  key: string
  sources?: Source[]
  subCategories?: Category[]
  hidden?: boolean
}

export type DataSources = {
  code?: Category
  log?: Category
  host?: Category
  productivity?: Category
  securityMonitoring?: Category
  discover?: Category
}

const Sources = () => {
  const user = useSelector((state) => state.Auth.user)
  const { hasScanForTeam } = useSelector((state) => state.Auth.user)
  const [{ mode }] = useQuerystring()
  useEffect(() => {
    const isPocMode = pocModePersistance.get() || mode?.toLowerCase() === POC
    pocModePersistance.persist(isPocMode)
  }, [mode])

  const githubAppInstallationId = githubAppPostInstallPersistence.get()

  const history = useHistory()
  const handleClickSource = (category, integrationId) => {
    history.push({
      pathname: paths.closed.source(category, integrationId),
      search: ``,
    })
  }

  const dataSources: DataSources = {
    code: {
      displayName: 'Code',
      key: 'code',
      subCategories: [
        {
          displayName: 'Protect your CI/CD pipelines',
          description: 'Monitor private & public repositories in your CI',
          key: 'ci/cd',
          sources: [
            {
              displayName: 'GitLab CI/CD',
              key: SourceIntegrationKey.GitlabCi,
              handleClickSource: () => {
                window.open(
                  `${DOCS_URL}gitlab_cicd`,
                  '_blank',
                  'noopener,noreferrer'
                )
              },
            },
            {
              displayName: 'GitHub Action',
              key: SourceIntegrationKey.GithubActions,
            },
            {
              displayName: 'Travis',
              key: SourceIntegrationKey.TravisCi,
            },
            {
              displayName: 'Jenkins',
              key: SourceIntegrationKey.Jenkins,
            },
            {
              displayName: 'CircleCI',
              key: SourceIntegrationKey.CircleCi,
            },
            {
              displayName: 'Jfrog Pipelines',
              key: SourceIntegrationKey.JfrogPipline,
            },
            {
              displayName: 'AWS CodeBuild',
              key: SourceIntegrationKey.Codebuild,
            },
            {
              displayName: 'Azure DevOps Pipeline',
              key: SourceIntegrationKey.Azuredevops,
            },
            {
              displayName: 'Bitbucket Pipeline',
              key: SourceIntegrationKey.BitbucketPipeline,
            },
            {
              displayName: 'Google Cloud Build',
              key: SourceIntegrationKey.Gcpbuild,
            },
            {
              displayName: 'Other CI Systems',
              key: SourceIntegrationKey.OtherCi,
            },
          ],
        },
        {
          displayName: 'CLI (Audit mode)',
          description: 'Get a full view of your organization / single repo',
          key: 'audit',
          sources: [
            {
              displayName: 'GitLab',
              key: SourceIntegrationKey.AuditGitlab,
            },
            {
              displayName: 'GitHub',
              key: SourceIntegrationKey.AuditGithub,
            },
            {
              displayName: 'Local repos',
              key: SourceIntegrationKey.AuditLocal,
            },
          ],
        },
        {
          displayName: 'Public Repositories',
          hidden: !features.publicScanAvailable,
          description: 'Scan your public repositories (Github Account needed)',
          key: 'public',
          sources: [
            {
              displayName: 'Connect with GitHub',
              key: 'github-connect',
              handleClickSource: () => {
                // tracker.githubAppPublicScan()
                window.open(
                  `https://github.com/apps/${
                    process.env.REACT_APP_GITHUB_APP_NAME
                  }/installations/new?state=${encodeURIComponent(
                    user.username
                  )}`,
                  '_blank',
                  'noopener,noreferrer'
                )
              },
            },
          ],
        },
        {
          displayName: 'Spectral Git Provider Bot',
          description:
            'Integrate spectral across your entire organization with a bot',
          key: 'git-provider-bot',
          sources: [
            {
              displayName: 'Spectral GitHub Bot',
              key: 'github-native',
              handleClickSource: () => {
                window.open(
                  `${DOCS_URL}github_bot`,
                  '_blank',
                  'noopener,noreferrer'
                )
              },
            },
            {
              displayName: 'Spectral Gitlab Bot',
              key: 'gitlab-bot',
              handleClickSource: () => {
                window.open(
                  `${DOCS_URL}gitlab_bot`,
                  '_blank',
                  'noopener,noreferrer'
                )
              },
            },
          ],
        },
        {
          displayName: 'Cloud automations',
          description: 'Scan cloud automation tools resources',
          key: 'automation',
          sources: [
            {
              displayName: 'Terraform Cloud',
              key: 'tfc',
              handleClickSource: () => {
                window.open(
                  `${DOCS_URL}terraform_cloud`,
                  '_blank',
                  'noopener,noreferrer'
                )
              },
            },
          ],
        },
        {
          displayName: 'IDEs',
          description: 'Integrate Spectral with your IDE',
          key: 'ides',
          sources: [
            {
              displayName: 'Visual Studio Code',
              key: 'vs-code',
              handleClickSource: () => {
                window.open(
                  `https://marketplace.visualstudio.com/items?itemName=SpectralOps.spectral-vscode-extension`,
                  '_blank',
                  'noopener,noreferrer'
                )
              },
            },
          ],
        },
        {
          displayName: 'Pre-receive Git hooks',
          description: 'Prevent issues from reaching your remote repos',
          key: 'bitbucket',
          sources: [
            {
              displayName: 'Bitbucket Server',
              key: 'bitbucket',
              handleClickSource: () => {
                window.open(
                  `${DOCS_URL}bitbucket_server_plugin`,
                  '_blank',
                  'noopener,noreferrer'
                )
              },
            },
            {
              displayName: 'Gitlab Server',
              key: 'gitlab',
              handleClickSource: () => {
                window.open(
                  `${DOCS_URL}gitlab_pre_receive_hook`,
                  '_blank',
                  'noopener,noreferrer'
                )
              },
            },
          ],
        },
      ],
    },
    log: {
      displayName: 'Logs',
      description:
        'Shift left your GRC strategy (governance, risk management, and compliance).discover and control your application log information.',
      key: 'log',
      hidden: true,
      sources: [
        {
          displayName: 'Kubernetes',
          key: 'kubernetes',
        },
        {
          displayName: 'HTTP Collector',
          key: 'http',
        },
      ],
    },
    host: {
      displayName: 'Host & Storage',
      description:
        'Discover, Observe, and protect secrets & sensitive data in your cloud environment, storage and hosts.',
      key: 'host',
      hidden: true,
      sources: [
        {
          displayName: 'S3 (AWS)',
          key: 's3',
        },
        {
          displayName: 'On Premise',
          key: 'on-premise',
        },
        {
          displayName: 'Local',
          key: 'host-other',
        },
      ],
    },
    productivity: {
      displayName: 'Productivity',
      description:
        'Monitor & search for API Keys ,password and more in your productivity tools such as Jira & Confluence.',
      key: 'productivity',
      sources: [
        {
          displayName: 'Jira',
          key: SourceIntegrationKey.Jira,
          handleClickSource: () => {
            window.open(`${DOCS_URL}jira`, '_blank', 'noopener,noreferrer')
          },
        },
        {
          displayName: 'Confluence',
          key: SourceIntegrationKey.Confluence,
          handleClickSource: () => {
            window.open(
              `${DOCS_URL}confluence`,
              '_blank',
              'noopener,noreferrer'
            )
          },
        },
      ],
    },
    discover: {
      displayName: 'CI/CD Hardening',
      description:
        'Discover misconfiguration (such as permissions, repo visibility etc) in remote service providers.',
      key: 'cicd-hardening',
      sources: [
        {
          displayName: 'GitHub',
          key: SourceIntegrationKey.GithubDiscover,
        },
        {
          displayName: 'GitLab',
          key: SourceIntegrationKey.GitlabDiscover,
        },
      ],
    },
  }

  return (
    <Page sx={{ padding: '40px' }} name="SOURCES" title="Add sources">
      <PageTitle>Sources</PageTitle>
      <span className="e2e-test-sources-page-title" />
      {hasScanForTeam || pocModePersistance.get() || githubAppInstallationId ? (
        <Box sx={{ mt: '40px' }}>
          <View
            dataSources={dataSources}
            handleClickSource={handleClickSource}
          />
        </Box>
      ) : (
        <Box sx={{ mt: '40px' }}>
          <WizardConnected />
        </Box>
      )}
    </Page>
  )
}

export default Sources
