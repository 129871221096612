import React from 'react'
import { Box } from 'theme-ui'
import MondayBoardFetcherById from './fetcher-by-id'
import MondayBoardFetcherByWorkspaces from './fetcher-by-ws'
import { Loading } from '../../../components/loading'

const MondayBoardFetcherForm = ({
  workspaces = null,
  board,
  onSubmit,
  isWorkspacesFetchingInProgress,
}) => {
  return (
    <Box>
      {isWorkspacesFetchingInProgress ? (
        <Loading />
      ) : (
        <Box>
          {workspaces && workspaces.length > 0 ? (
            <MondayBoardFetcherByWorkspaces
              workspaces={workspaces}
              onSubmit={onSubmit}
            />
          ) : (
            <MondayBoardFetcherById onSubmit={onSubmit} board={board} />
          )}
        </Box>
      )}
    </Box>
  )
}

export default MondayBoardFetcherForm
