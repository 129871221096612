/** @jsx jsx */
import { Box, jsx, Heading, Text } from 'theme-ui'
import { Badge } from 'antd'
import { ListItemWithIcon, ListGroup } from './list-items'
import { LinkButton } from '../../../components'
import PlanCard from './plan-card'

const EnterprisePlan = ({ isCurrentPlan }) => {
  const planCard = (
    <PlanCard>
      <Box>
        <Heading as="h3" variant="cardTitle">
          Enterprise
        </Heading>
        <Text variant="summary">
          Security scans for the entire organization
        </Text>
      </Box>
      <Box>
        {isCurrentPlan ? (
          <Text variant="title">Current Plan</Text>
        ) : (
          <Text variant="title">Let&#39;s talk!</Text>
        )}
      </Box>
      <Box>
        <LinkButton
          sx={{ pointerEvents: isCurrentPlan ? 'none' : 'auto' }}
          text={isCurrentPlan ? 'Current Plan' : 'Contact Sales'}
          href="mailto:sales@spectralops.io?subject=Upgrade%20to%20Enterprise%20plan"
          block
          size="large"
        />
      </Box>
      <Box>
        <Text sx={{ mb: 3 }}>All the benefits of Business, plus:</Text>
      </Box>
      <Box>
        <ListGroup>
          <ListItemWithIcon text="Unlimited scans" />
          <ListItemWithIcon text="Unlimited repositories" />
          <ListItemWithIcon text="200 contributors plus" />
          <ListItemWithIcon text="Dedicated CSE support" />
          <ListItemWithIcon text="No limit retention" />
        </ListGroup>
      </Box>
    </PlanCard>
  )
  if (isCurrentPlan) {
    return (
      <Badge.Ribbon text="Your Plan" color="cyan">
        {planCard}
      </Badge.Ribbon>
    )
  }
  return planCard
}

export default EnterprisePlan
