/** @jsx jsx */
import { jsx, Box, Flex, Text } from 'theme-ui'
import { Form, Input, Tabs, Button } from 'antd'
import { Link } from 'react-router-dom'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { useState } from 'react'
import {
  DialogPanel,
  WeakLink,
  LinkButton,
  WelcomeBar,
  SmallHeading,
  HGroup,
  PrimaryButtonLarge,
} from '../../components'
import { cubes } from '../../common/icons'
import { ReactComponent as GoogleIcon } from '../../assets/svg/connect-google.svg'
import { ReactComponent as GithubIcon } from '../../assets/svg/connect-github.svg'
import { ReactComponent as AzureIcon } from '../../assets/svg/connect-azure.svg'

import ErrorMessage from '../../components/forms/error-message'
import Message from '../../components/forms/message'
import { ReCaptchaFormItem } from '../../components/recaptcha'
import useReCaptcha from '../../hooks/useReCaptcha'
import { isEmbedded } from '../../common/utils'

enum SigninTabs {
  Login = 'Login',
  Sso = 'Sso',
}

const { TabPane } = Tabs

const Signin = ({
  onSubmit,
  error = null,
  ssoLoginError = null,
  loginAllowedProvider = null,
  links: { signup, recover },
  activeTabs = { login: true, sso: true },
  team = null,
  recoverFinishMessage = null,
  resendEmailVerification = null,
  fetchSsoLoginTeamPid = null,
  isVerificationSent = false,
  isGenericError = false,
  recaptcha: {
    enabled: recaptchaEnabled = true,
    siteKey: recaptchaSiteKey = null,
  },
  returnTo = null,
}) => {
  if (isEmbedded()) {
    activeTabs.sso = false
  }
  const [activeTabKey, setActiveTabKey] = useState(SigninTabs.Login)
  const onLogin =
    activeTabKey === SigninTabs.Login ? onSubmit : fetchSsoLoginTeamPid
  const [recaptchaRef, resetReCaptchaPostSubmit] = useReCaptcha({
    onSubmit: onLogin,
  })
  const onFinish = resetReCaptchaPostSubmit
  return (
    <Box>
      <DialogPanel>
        <WelcomeBar />
        <Box sx={{ flex: 1 }}>
          <SmallHeading text="Sign in to Spectral" />

          <Tabs
            onChange={(key) => setActiveTabKey(SigninTabs[key])}
            defaultActiveKey="1"
            tabBarStyle={{ paddingLeft: '64px' }}
          >
            {activeTabs.login && (
              <TabPane tab="Login" key={SigninTabs.Login} sx={{ p: 5 }}>
                <Flex sx={{ flexDirection: ['column', null, 'row'] }}>
                  <Box sx={{ width: '300px' }}>
                    <Box sx={{ mb: '20px' }}>
                      {loginAllowedProvider && (
                        <ErrorMessage
                          error={`Your account administrator allows login with the following providers only: ${loginAllowedProvider}`}
                        />
                      )}
                      {error && (
                        <ErrorMessage
                          error={
                            <Box>
                              <Text>{error}</Text>
                              {isGenericError && (
                                <Box sx={{ mt: 2 }}>
                                  {isVerificationSent ? (
                                    <Flex sx={{ alignItems: 'center', gap: 2 }}>
                                      <AiOutlineCheckCircle
                                        size={20}
                                        sx={{ color: 'green' }}
                                      />
                                      Verification email sent.
                                    </Flex>
                                  ) : (
                                    <Button
                                      size="small"
                                      type="ghost"
                                      onClick={resendEmailVerification}
                                    >
                                      Resend verification email
                                    </Button>
                                  )}
                                </Box>
                              )}
                            </Box>
                          }
                        />
                      )}
                      {recoverFinishMessage && (
                        <Message message={recoverFinishMessage} />
                      )}
                    </Box>
                    <Form
                      layout="vertical"
                      hideRequiredMark
                      onFinish={onFinish}
                    >
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: 'We gotta have that email first.',
                          },
                          {
                            type: 'email',
                            message: 'Nope. That is not an email address.',
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                          {
                            required: true,
                            message: 'Password please.',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input size="large" type="password" />
                      </Form.Item>
                      <ReCaptchaFormItem
                        enabled={recaptchaEnabled}
                        sitekey={recaptchaSiteKey}
                        recaptchaRef={recaptchaRef}
                      />
                      <HGroup sx={{ mt: 5 }}>
                        <PrimaryButtonLarge
                          className="e2e-test-sign-in-button"
                          text="Sign in"
                          icon={cubes}
                          isSubmit
                        />
                        <Box ml={3}>
                          <WeakLink to={recover}>Forgot password?</WeakLink>
                        </Box>
                      </HGroup>
                    </Form>
                  </Box>
                  <Flex
                    sx={{
                      borderLeft: ['none', null, '1px solid #eee'],
                      mt: ['60px', null, 0],
                      pl: [0, null, '30px'],
                      ml: [0, null, '30px'],
                      flexDirection: 'column',
                      pt: '33px',
                    }}
                  >
                    <Box sx={{ mb: '20px' }}>
                      <LinkButton
                        sx={{ display: 'block' }}
                        icon={<GoogleIcon sx={{ width: 22, height: 22 }} />}
                        text="Sign in with Google"
                        href="/api/connect/google"
                      />
                    </Box>
                    <Box>
                      <LinkButton
                        sx={{ display: 'block' }}
                        icon={<GithubIcon sx={{ width: 22, height: 22 }} />}
                        text="Sign in with Github"
                        href="/api/connect/github"
                      />
                    </Box>
                    <Box sx={{ mt: '20px' }}>
                      <LinkButton
                        sx={{ display: 'block' }}
                        icon={<AzureIcon sx={{ width: 22, height: 22 }} />}
                        text="Sign in with Azure Devops"
                        href="/api/connect/visualstudio"
                      />
                    </Box>
                  </Flex>
                </Flex>
              </TabPane>
            )}
            {activeTabs.sso && (
              <TabPane tab="Single Sign-on" key={SigninTabs.Sso} sx={{ p: 5 }}>
                {!team && (
                  <Box sx={{ minWidth: '680px' }}>
                    <Flex
                      sx={{
                        marginTop: '7px',
                        flexDirection: 'column',
                        alignContent: 'space-between',
                      }}
                    >
                      {ssoLoginError && (
                        <Flex sx={{ mb: '20px' }}>
                          <ErrorMessage
                            error={
                              <Flex>
                                <Text>{ssoLoginError}</Text>
                              </Flex>
                            }
                          />
                        </Flex>
                      )}
                      <Form
                        layout="vertical"
                        hideRequiredMark
                        onFinish={onFinish}
                      >
                        <Form.Item
                          name="email"
                          label="Email"
                          rules={[
                            {
                              required: true,
                              message: 'We gotta have that email first.',
                            },
                            {
                              type: 'email',
                              message: 'Nope. That is not an email address.',
                            },
                          ]}
                          sx={{ width: '300px' }}
                        >
                          <Input size="large" />
                        </Form.Item>
                        <ReCaptchaFormItem
                          enabled={recaptchaEnabled}
                          sitekey={recaptchaSiteKey}
                          recaptchaRef={recaptchaRef}
                        />
                        <HGroup>
                          <PrimaryButtonLarge
                            text="Sign in"
                            icon={cubes}
                            isSubmit
                          />
                        </HGroup>
                      </Form>
                    </Flex>
                  </Box>
                )}
                {team && (
                  <Box sx={{ textAlign: 'center' }}>
                    <Box sx={{ mb: '80px' }}>
                      <h2>Welcome to {team.name}</h2>
                    </Box>
                    <PrimaryButtonLarge
                      text="Authenticate with SSO"
                      // eslint-disable-next-line no-return-assign
                      onClick={() =>
                        (window.location.href = `/api/sso/${team.pid}/login${
                          returnTo
                            ? `?returnTo=${encodeURIComponent(returnTo)}`
                            : ''
                        }`)
                      }
                    />
                  </Box>
                )}
              </TabPane>
            )}
          </Tabs>
        </Box>
      </DialogPanel>
      {!team && (
        <Box sx={{ padding: '30px', textAlign: 'center' }}>
          Need an account? <Link to={signup}>Sign up here</Link>
          <Box sx={{ padding: '10px', textAlign: 'center' }}>
            Any issue?{' '}
            <a
              href="mailto:support@spectralops.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a>
          </Box>
        </Box>
      )}
      <Box sx={{ padding: '50px', textAlign: 'center', fontSize: '11px' }}>
        By signing in, I accept the SpectralOps{' '}
        <a href="/tos.html" target="_blank">
          terms and conditions
        </a>
      </Box>
    </Box>
  )
}

export default Signin
