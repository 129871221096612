import { scannerLocationInstruction, scannerOptions } from './shared'
import { SourceIntegrationComponent } from '../enums'

const githubInstructions = {
  tooltip:
    'Personal tokens give permission to clone Github repositories. No additional scope needed.',
  text:
    'Use existing GitHub Personal Token (or issue a new one <a href="https://github.com/settings/tokens/new" target="_blank" rel="noopener noreferer">here</a>)',
}

export const auditGithub = {
  mode: 'audit',
  component: SourceIntegrationComponent.SnippetWindow,
  'MAC/Linux': {
    title: 'Github',
    content: 'Audit repositories and organizations.',
    tileKey: 'github',
    selected: true,
    snippets: [
      {
        title: 'Scan Github repo',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral github -k repo -t [GITHUB_TOKEN] [YOUR_REPO] {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR_REPO',
            example: 'https://github.com/ACME-Corp-Demo/tensorflow',
            explain: 'full path to the repository.',
          },
        ],
      },
      {
        title: 'Scan Github user',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral github -k user -t [GITHUB_TOKEN] [YOUR_USER] {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR_USER',
            example: 'maggie',
          },
        ],
      },
      {
        title: 'Scan Github organization',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral github -k org -t [GITHUB_TOKEN] [YOUR_ORGANIZATION] {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR_ORGANIZATION',
            example: 'ACME-Corp-Demo',
          },
        ],
      },
    ],
    instructions: [
      scannerLocationInstruction,
      githubInstructions,
      {
        text: scannerOptions,
      },
    ],
  },
  Windows: {
    title: 'Github',
    content: 'Audit repositories and organizations.',
    tileKey: 'github',
    selected: true,
    snippets: [
      {
        title: 'Scan Github repo',
        code:
          '$ spectral.exe github -k repo -t [GITHUB_TOKEN] [YOUR_REPO] --dsn {dsn} {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR_REPO',
            example: 'https://github.com/ACME-Corp-Demo/tensorflow',
            explain: 'full path to the repository.',
          },
        ],
      },
      {
        title: 'Scan Github user',
        code:
          '$ spectral.exe github -k user -t [GITHUB_TOKEN] [YOUR_USER] --dsn {dsn} {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR_USER',
            example: 'maggie',
          },
        ],
      },
      {
        title: 'Scan Github organization',
        code:
          '$ spectral.exe github -k org -t [GITHUB_TOKEN] [YOUR_ORGANIZATION] --dsn {dsn} {options}',
        type: 'shell',
        params: [
          {
            name: 'YOUR_ORGANIZATION',
            example: 'ACME-Corp-Demo',
          },
        ],
      },
    ],
    instructions: [
      scannerLocationInstruction,
      githubInstructions,
      {
        text: scannerOptions,
      },
    ],
  },
}
