import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { routes } from './routes'
import Modals from './containers/modals'
import LayoutConnected from './containers/layout/layout-connected'
import beamerInitialize from './common/beamer'
import hubspotInitialize from './common/hubspot'

function AppRouter() {
  return (
    <Switch>
      {routes.redirect.map((route) => (
        <Route
          exact={route.exact}
          path={route.from}
          key={`${route.to}_redirect_route`}
          render={(props) => {
            return (
              <Redirect
                key={`${route.to}_redirect`}
                to={`${route.to}${props.location.search}`}
              />
            )
          }}
        />
      ))}
      {routes.closed.map((route, idx) => (
        // XXX when adding router types, this explodes.
        // @ts-ignore
        <Route key={idx} path={`${route.path}`} exact={route.exact}>
          <route.component />
        </Route>
      ))}
    </Switch>
  )
}

export default function Main() {
  beamerInitialize()
  hubspotInitialize()
  return (
    <LayoutConnected>
      <AppRouter />
      <Modals />
    </LayoutConnected>
  )
}
