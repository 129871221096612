export const digestInfo = `It's recommended to verify the digest of a downloaded runnable file before you run it. You can use Spectral's <a target="_blank"
          rel="noopener noreferrer" href="https://github.com/spectralops/preflight">Preflight</a> to verify. Follow <a target="_blank"
          rel="noopener noreferrer" href="{digestLinkLinux}">this link</a> to see SHA digests of the binary, the download script and the gzip. `

export const scannerOptions = 'Scan using one of the following methods:'

export const scannerLocationInstruction = {
  tooltip:
    'Spectral scanner scans all files and folders from where it runs from',
  text: `First create a new folder called spectralscan`,
}

export enum selfHostedProviders {
  githubEnterprise = 'GitHub Enterprise',
  gitlabSelfHosted = 'GitLab Self-Hosted',
  bitbucketServer = 'BitBucket Server',
}
