import { ModelConfig } from '@rematch/core'
import { RootState, sdkClient } from '../../store'

type ReportsState = {
  data: any
  filters: any
}

const reportsDefaultState = {
  data: null,
  filters: {},
}

export const reports: ModelConfig<ReportsState> = {
  state: { ...reportsDefaultState },
  reducers: {
    setReportData(state: ReportsState, { data }: any) {
      const newState = {
        ...state,
        data,
      }
      return newState
    },
    setFilter(state: ReportsState, filter: any) {
      const newState = { ...state, filters: { ...state.filters, ...filter } }
      return newState
    },
    reset(_state: ReportsState) {
      return { ...reportsDefaultState }
    },
  },
  effects: (dispatch: any) => ({
    async fetchReports(_payload, rootState: RootState) {
      const { filters } = rootState.Reports
      const data = await sdkClient.reports().getReportsData({
        data: {
          filters: {
            ...filters,
            detectors: filters.detectors?.map(
              (detectorDisplayName) => detectorDisplayName.split(' - ')[0]
            ),
          },
        },
      })
      dispatch.Reports.setReportData({
        data,
      })
    },
    resetState() {
      dispatch.Reports.reset()
    },
  }),
  selectors: () => ({
    reportsPage() {
      return (state) => {
        return {
          isLoading:
            !state.Reports.data &&
            state.loading.effects.Reports.fetchReports !== 0,
          isError: state.error.effects.Reports.fetchReports,
          data: state.Reports.data,
          filters: state.Reports.filters,
        }
      }
    },
  }),
}
