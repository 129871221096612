import React from 'react'
import { Box } from 'theme-ui'
import * as Yup from 'yup'
import {
  FieldSectionLayout,
  InputFieldSection,
  PanelForm,
  SubmitSection,
} from '../../../components/panels/content'

const FetcherById = ({ board, onSubmit }) => {
  return (
    <Box>
      <PanelForm
        onSubmit={onSubmit}
        initialValues={{ boardId: null }}
        validationSchema={Yup.object({
          boardId: Yup.string()
            .required('Required')
            .matches(
              RegExp('^[\\d]{10}$'),
              'Must be a valid board id - 10 digits number'
            ),
        })}
      >
        <InputFieldSection
          name="boardId"
          label={`Board ID${board ? ` - ${board.name}` : ''}`}
          allowClear
          layout={FieldSectionLayout.Vertical}
          description="Insert Monday board ID. Board id can be found in the url when presenting board"
          placeholder="1234567890"
          required
          merge
        />
        <SubmitSection merge text="Continue" />
      </PanelForm>
    </Box>
  )
}

export default FetcherById
