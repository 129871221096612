import React from 'react'
import {
  PanelForm,
  ContentPanel,
  ContentPanelHeader,
  SwitchFieldSection,
  SubmitSection,
} from '../../../../components/panels/content'

const Notification = ({
  readOnly = false,
  setTeamSettings = null,
  enabled,
  enableWeeklyDigestReport,
  enableDailyDigestReport,
  slackIgnoreActionEnabled,
  msTeamsIgnoreActionEnabled,
}) => {
  return (
    <ContentPanel>
      <ContentPanelHeader>Notifications Settings</ContentPanelHeader>
      <PanelForm
        onSubmit={setTeamSettings}
        initialValues={{
          enabled,
          enableWeeklyDigestReport,
          enableDailyDigestReport,
          slackIgnoreActionEnabled: slackIgnoreActionEnabled.status,
          msTeamsIgnoreActionEnabled: msTeamsIgnoreActionEnabled.status,
        }}
      >
        <SwitchFieldSection
          name="enabled"
          label="Enable Email notification"
          disabled={readOnly}
          description="You can choose to enable or disable email notification for the team members"
        />
        <SwitchFieldSection
          name="enableDailyDigestReport"
          label="Enable Daily Email report"
          disabled={readOnly}
          description="Enable the option to subscribe to daily report for the team members"
        />
        <SwitchFieldSection
          name="enableWeeklyDigestReport"
          label="Enable Weekly Email report"
          disabled={readOnly}
          description="Enable the option to subscribe to weekly report for the team members"
        />
        {slackIgnoreActionEnabled.isShown && (
          <SwitchFieldSection
            name="slackIgnoreActionEnabled"
            label="Enable Slack ignore notifications"
            disabled={readOnly}
            description="Send Slack notifications every ignore action (add / remove ignore)"
          />
        )}
        {msTeamsIgnoreActionEnabled.isShown && (
          <SwitchFieldSection
            name="msTeamsIgnoreActionEnabled"
            label="Enable Microsoft Teams ignore notifications"
            disabled={readOnly}
            description="Send Microsoft Teams notifications every ignore action (add / remove ignore)"
          />
        )}
        <SubmitSection text="Save settings" />
      </PanelForm>
    </ContentPanel>
  )
}

export default Notification
