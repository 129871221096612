const getReportsPath = (baseUrl, category) => {
  return `${baseUrl}/reports/${category}`
}

export const RoutesBuilder = ({ baseUrl = '' }) => ({
  filteredIssues: ({ assetId }) => {
    return `${baseUrl}/assets/${encodeURIComponent(assetId)}`
  },
  filteredIssuesWithSource: ({ assetId, referringSource }) => {
    return `${baseUrl}/assets/${encodeURIComponent(
      assetId
    )}?referringSource=${referringSource}`
  },
  issueByIssueId: ({ pid, assetId }) => {
    return `${baseUrl}/assets/${encodeURIComponent(assetId)}?issueId=${pid}`
  },
  issuesByAssignee: ({ assignee, assetId }) => {
    return `${baseUrl}/assets/${encodeURIComponent(
      assetId
    )}?assignees=${encodeURIComponent(assignee)}`
  },
  reports: ({ category }) => {
    return `${getReportsPath(baseUrl, category)}`
  },
  ssoLogin: ({ teamPid, returnTo }) => {
    return `${baseUrl}/signin/${teamPid}${
      returnTo ? `?returnTo=${encodeURIComponent(returnTo)}` : ''
    }`
  },
})

export interface PaginationInfo {
  page: number
  pageSize: number
}

export enum AssetPageTab {
  Secrets = 'secrets',
  Iac = 'iac',
  OpenSource = 'open-source',
  SecretsSprawl = 'sprawl',
  DiscoverIssues = 'cicd-hardening',
  ScansHistory = 'scans-history',
}
