import { concat, isEmpty, omit, pick, sortBy as LsortBy } from 'lodash'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import DiscoverIssuesListSideFilters from '../../../../components/filters/side-filters'
import { Loading } from '../../../../components/loading'
import useDebouncedFieldFilter from '../../../../hooks/useDebouncedFieldFilter'
import {
  buildAssignableMembersData,
  filtersSetup,
} from '../../shared/list-page-setup'
import DiscoverIssuesListView from '../discover-list/view'
import useFiltersActions from '../../../../hooks/useFiltersActions'
import AppliedFilters from '../../../../components/applied-filters'
import {
  removeEmptyFilters,
  transformAssigneeFiltersToDisplay,
  transformFiltersToDisplay,
} from '../../shared/transform-filters'
import FiltersSidePanel from '../../../../containers/filters-side-panel'

const sortOptions = [
  { key: 'detectorId', title: 'Detector' },
  { key: 'createdAt', title: 'Exposure' },
  { key: 'severity', title: 'Severity' },
]

const DiscoverIssuesView = ({
  asset,
  discoverIssues,
  filtersData,
  currentQueryStringParams,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  setQueryStringParam,
  clearQueryStringParam,
  trackSort,
  trackFilter,
  onPaginationChange,
  pagination,
  onDiscoverIssuesSelectionChanged,
  isRefreshing,
  actionHandlers,
  selectedDiscoverIssues,
  exportActions,
  isDownloadingExport,
  integrations,
  onActionIntegrationSubmit,
  loaded,
  isDataFiltered,
}) => {
  const { issueId } = currentQueryStringParams
  const [
    onDebouncedValueChange,
    currentDebouncedValues,
  ] = useDebouncedFieldFilter(
    {
      path: currentQueryStringParams.path,
      severity: currentQueryStringParams.severity,
      detectorId: currentQueryStringParams.detectorId,
      assignees: currentQueryStringParams.assignees,
      status: currentQueryStringParams.status,
    },
    setQueryStringParam,
    clearQueryStringParam,
    trackFilter,
    currentQueryStringParams
  )

  const isHiddenFilters =
    isEmpty(filtersData.status) && !isRefreshing && !isDataFiltered

  const filtersMetadata = filtersSetup({
    assignableMembers: {
      ...buildAssignableMembersData(filtersData.assignees),
      isActive: !isHiddenFilters || filtersData.assignees?.length > 0,
    },
    ...filtersData,
    detectors: filtersData?.detectors || [],
  })

  const fullDebouncedValues = removeEmptyFilters(currentDebouncedValues)

  const filtersToDisplay = transformFiltersToDisplay(
    omit(fullDebouncedValues, 'assignees')
  )
  const assigneesFilterToDisplay = transformAssigneeFiltersToDisplay(
    pick(fullDebouncedValues, 'assignees'),
    assignableMembers
  )

  const currentFiltersData = LsortBy(
    concat(filtersToDisplay, assigneesFilterToDisplay),
    'filterName'
  )
  const filterActions = useFiltersActions({
    setFilter: onDebouncedValueChange,
    trackFilter,
    clearFilter: clearQueryStringParam,
  })

  return (
    <Flex sx={{ height: '100%', flexDirection: 'column' }}>
      {!isEmpty(currentFiltersData) && (
        <Box sx={{ mb: 3 }}>
          <AppliedFilters
            filters={currentFiltersData}
            onClearAll={() =>
              filterActions.onCheckboxFiltersClear(
                currentFiltersData.map(({ filterKey }) => filterKey)
              )
            }
            onRemoveFilter={filterActions.onCheckboxFilterCheck}
            onClearFilter={filterActions.onCheckboxFiltersClear}
          />
        </Box>
      )}
      <Flex sx={{ height: '0', flex: 1 }}>
        {!issueId && !isHiddenFilters && (
          <FiltersSidePanel>
            <Box sx={{ mb: 2 }} />
            <DiscoverIssuesListSideFilters
              filtersValues={currentDebouncedValues}
              filterActions={filterActions}
              isDataRefreshing={isRefreshing}
              filtersMetadata={filtersMetadata}
            />
          </FiltersSidePanel>
        )}
        <Box sx={{ width: '100%', height: '100%' }}>
          {loaded ? (
            <DiscoverIssuesListView
              onPaginationChange={onPaginationChange}
              currentQueryStringParams={currentQueryStringParams}
              clearQueryStringParam={clearQueryStringParam}
              totalDiscoverIssues={pagination.total}
              discoverIssues={discoverIssues}
              isRefreshing={isRefreshing}
              actionHandlers={actionHandlers}
              selectedDiscoverIssues={selectedDiscoverIssues}
              exportActions={exportActions}
              isDownloadingExport={isDownloadingExport}
              integrations={integrations}
              onActionIntegrationSubmit={onActionIntegrationSubmit}
              assignableMembers={assignableMembers}
              isDataFiltered={isDataFiltered}
              isNotActiveAsset={asset.isNotActive}
              setQueryStringParam={setQueryStringParam}
              trackSort={trackSort}
              sortOptions={sortOptions}
              isLoadingAssignableMembers={isLoadingAssignableMembers}
              isAssignableMembersLoaded={isAssignableMembersLoaded}
              onDiscoverIssuesSelectionChanged={
                onDiscoverIssuesSelectionChanged
              }
            />
          ) : (
            <Loading />
          )}
        </Box>
      </Flex>
    </Flex>
  )
}

export default DiscoverIssuesView
