import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { omit } from 'lodash'
import useQuerystring from './useQuerystring'

export type QueryStringParam = {
  key: string
  value: string | number | object
}

function useQueryStringState(
  paramsKeys: Array<string>
): [any, Function, Function] {
  const location = useLocation()
  const [, setQuery, updateQuery] = useQuerystring()

  function getParamsValuesFromQueryString() {
    const result = {}
    const queryString = qs.parse(location.search)
    paramsKeys.forEach((key) => {
      const paramValue = queryString[key] || null
      if (paramValue) {
        result[key] = paramValue
      }
    })

    return result
  }

  function saveParamValueInQueryString(
    params,
    shouldMergeWithCurrentQueryString = false,
    shouldReplaceHistory = false
  ) {
    const newQueryString = {
      ...getParamsValuesFromQueryString(),
      ...params,
    }
    if (shouldMergeWithCurrentQueryString) {
      updateQuery(newQueryString, shouldReplaceHistory)
    } else {
      setQuery(newQueryString, shouldReplaceHistory)
    }
  }

  function clearParam(param) {
    const previousParams = getParamsValuesFromQueryString()
    const newQueryString = {
      ...omit(previousParams, param),
    }
    setQuery(newQueryString, true)
  }

  const currentParamsValues = getParamsValuesFromQueryString()
  return [currentParamsValues, saveParamValueInQueryString, clearParam]
}

export default useQueryStringState
