/** @jsx jsx */
import {
  CheckOutlined,
  ClockCircleOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import { Box, Flex, jsx } from 'theme-ui'
import { Tooltip } from 'antd'
import React, { useCallback, useState } from 'react'
import { IssueStatus, RoleType } from '@spectral/types'
import ButtonWithTooltip from '../../../components/buttons/button-with-tooltip'
import { PassedPeriod } from '../../../components/formatters'
import { ActionButtonType } from '../../../common/track/utils'
import IgnoreButton from '../../ignore-button'
import AssigneeSelector from '../../assignee-change-dropdown-new'
import Restricted from '../../../containers/role-based-render/restricted'

const IssueActions = ({
  title,
  firstSeen,
  isIgnored,
  handleIgnore,
  handleSnooze,
  issue,
  handleResolve,
  handleUnresolve,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  handleAssigneeChange,
  handleAssigneeRemove,
  handleSearchAssignee,
}) => {
  const [isActionInProgress, setIsActionInProgress] = useState(false)
  const onResolveClick = useCallback(async () => {
    setIsActionInProgress(true)
    await handleResolve([issue], ActionButtonType.SINGLE)
    setIsActionInProgress(false)
  }, [handleResolve, issue])

  const onUnresolveClick = useCallback(async () => {
    setIsActionInProgress(true)
    await handleUnresolve([issue], ActionButtonType.SINGLE)
    setIsActionInProgress(false)
  }, [handleUnresolve, issue])

  const issueActionButtonProps =
    issue.status === IssueStatus.Resolved
      ? {
          icon: <UndoOutlined />,
          tooltip: 'Unresolve',
          onActionClick: onUnresolveClick,
        }
      : {
          icon: <CheckOutlined />,
          tooltip: 'Resolve',
          onActionClick: onResolveClick,
        }

  return (
    <React.Fragment>
      <Restricted roles={[RoleType.ReadOnly]}>
        <Flex sx={{ alignItems: 'center', minWidth: '0' }}>
          <Tooltip title={title}>
            <Flex sx={{ alignItems: 'center', mr: '60px' }}>
              <Box mr={1}>
                <ClockCircleOutlined />
              </Box>
              <PassedPeriod createdAt={firstSeen} />
            </Flex>
          </Tooltip>
        </Flex>
      </Restricted>
      <Restricted roles={[RoleType.Owner, RoleType.Admin, RoleType.Member]}>
        <Flex sx={{ alignItems: 'center', minWidth: '300px' }}>
          <Tooltip title={title}>
            <Flex sx={{ alignItems: 'center', mr: '60px' }}>
              <Box mr={1}>
                <ClockCircleOutlined />
              </Box>
              <PassedPeriod createdAt={firstSeen} />
            </Flex>
          </Tooltip>
          <React.Fragment>
            <Box mr={2}>
              <Tooltip title={isIgnored ? 'Remove ignore' : 'Ignore'}>
                <IgnoreButton
                  onIgnoreClicked={handleIgnore}
                  onSnoozeClicked={handleSnooze}
                  selectedIssues={[issue].filter(
                    (i) => !i.metadata?.ignore_categorization
                  )}
                  compact
                  buttonType={ActionButtonType.SINGLE}
                />
              </Tooltip>
            </Box>
            <Box mr={2}>
              <ButtonWithTooltip
                tooltip={issueActionButtonProps.tooltip}
                onActionClick={issueActionButtonProps.onActionClick}
                icon={issueActionButtonProps.icon}
                isLoading={isActionInProgress}
                shape="circle"
                disabled={!!issue.metadata?.ignore_categorization}
              />
            </Box>
            <Box>
              <AssigneeSelector
                issue={issue}
                assignableMembers={assignableMembers}
                isLoadingAssignableMembers={isLoadingAssignableMembers}
                isAssignableMembersLoaded={isAssignableMembersLoaded}
                onAssigneeChanged={handleAssigneeChange}
                onAssigneeRemove={handleAssigneeRemove}
                onSearchAssignee={(nameFilter) => {
                  handleSearchAssignee({ nameFilter, assetId: issue.asset.id })
                }}
              />
            </Box>
          </React.Fragment>
        </Flex>
      </Restricted>
    </React.Fragment>
  )
}

export default IssueActions
