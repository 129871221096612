import { differenceInDays } from 'date-fns'
import numeral from 'numeral'

export const timeSinceInDays = (since) => {
  const diff = differenceInDays(new Date(), new Date(since))
  const diffDays = diff > 0 ? diff : 1
  const postfix = diffDays > 1 ? 'days' : 'day'
  return `${diffDays} ${postfix}`
}

export const toDisplayNumber = (number: number, format = '0.00a') => {
  if (number < 1000) {
    return number
  }
  return numeral(number).format(format).toUpperCase()
}

export const removeFilePathSlash = (filePath: string) =>
  filePath[0] === '/' ? filePath.substring(1) : filePath
