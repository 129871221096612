import React, { useState, useEffect } from 'react'
import { Box, Flex } from 'theme-ui'
import { Tree, Typography } from 'antd'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'
import { SearchOutlined } from '@ant-design/icons'
import { createGlobalStyle } from 'styled-components'
import { SortDirection } from '@spectral/types'
import SmallInput from '../inputs/small-input'
import SideFilterCollapse from './side-filter-collapse'
import FilterTotalDisplay from './filter-total-display'
import { DEFAULT_MAX_ITEMS_TO_DISPLAY } from './side-filters'

const FilterTreeStyle = createGlobalStyle`
  &.ant-tree {
    background: unset !important;
  }
  .ant-tree-switcher {
    width: 0 !important;
  }
  & .ant-tree-treenode {
    padding: 0 !important;
    margin-bottom: 4px !important;
  }
  .ant-tree-treenode.ant-tree-treenode-switcher-close {
    width: 100%;
  }
  .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
    width: 100%;
  }
`

const { Text } = Typography
type Option = {
  key: string
  title?: string
  renderTitle?: JSX.Element
  total: number
}
type Props = {
  title: string
  options: Array<Option>
  onCheck: any
  onClear: any
  checkedKeys: Array<string>
  isRefreshing: boolean
  showSearch?: boolean
  showAllOptions?: boolean
  searchPlaceholder?: string
  shouldSort?: boolean
  onExpandFilter: any
  onCollapseFilter: any
  currentCountItemsToDisplay: number
  tooltip?: string
  key?: string
}

const SideFilterTree = ({
  options,
  onCheck,
  checkedKeys,
  isRefreshing,
  showSearch = false,
  showAllOptions = false,
  shouldSort = true,
  searchPlaceholder = 'Search',
  onExpandFilter,
  onCollapseFilter,
  currentCountItemsToDisplay,
}: Props) => {
  const formattedOptions = options.map((option) => ({
    ...option,
    selectable: false,
  }))

  const [filteredOptions, setFilteredOptions] = useState(formattedOptions)
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    setFilteredOptions(
      shouldSort
        ? orderBy(
            formattedOptions.filter((option) =>
              option.title?.toLowerCase().includes(searchValue.toLowerCase())
            ),
            [(option) => option.total],
            [SortDirection.DESC]
          )
        : formattedOptions.filter((option) =>
            option.title?.toLowerCase().includes(searchValue.toLowerCase())
          )
    )
  }, [options, searchValue, shouldSort])

  const displayedOptions = take(
    filteredOptions,
    currentCountItemsToDisplay
  ).map((option: any) => {
    return {
      ...option,
      title: option.renderTitle ? (
        option.renderTitle
      ) : (
        <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
          <Box>
            <Text
              ellipsis={{ tooltip: option.title }}
              style={{
                maxWidth: '190px',
                wordBreak: 'break-word',
              }}
            >
              {option.title}
            </Text>
          </Box>
          <Box>
            <FilterTotalDisplay total={option.total} />
          </Box>
        </Flex>
      ),
    }
  })

  return (
    <Box
      sx={{
        transition: 'max-height 0.25s ease',
        overflow: 'hidden',
      }}
    >
      <Box>
        {showSearch && (
          <SmallInput
            disabled={isRefreshing}
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value)
            }}
            allowClear
            style={{
              width: '100%',
              marginBottom: '13px',
              height: '24px',
            }}
            placeholder={searchPlaceholder}
            prefix={<SearchOutlined />}
          />
        )}

        {displayedOptions.length > 0 ? (
          <Box sx={{ mb: '5px' }}>
            <FilterTreeStyle />
            <Tree
              disabled={isRefreshing}
              style={{ fontSize: '12px', background: 'transparent' }}
              checkable
              checkedKeys={checkedKeys}
              onCheck={onCheck}
              treeData={displayedOptions}
            />
          </Box>
        ) : (
          <Box sx={{ fontSize: '12px' }}>No results found</Box>
        )}
      </Box>
      <SideFilterCollapse
        showAllOptions={showAllOptions}
        options={filteredOptions}
        optionsToDisplay={displayedOptions}
        maxItemsToDisplay={DEFAULT_MAX_ITEMS_TO_DISPLAY}
        onExpandFilter={onExpandFilter}
        onCollapseFilter={onCollapseFilter}
        currentCountItemsToDisplay={currentCountItemsToDisplay}
      />
    </Box>
  )
}

export default SideFilterTree
