/** @jsx jsx */
import { Link } from 'react-router-dom'
import { Modal, Button } from 'antd'
import { jsx, Flex, Text, Box } from 'theme-ui'
import { LinkButton } from '../../components/buttons'
import { paths } from '../../routes'

const FailedModal = ({ disposeModal, postGithubAppInstall }) => {
  return (
    <Modal
      title="Scan failed 🤷‍♂️"
      visible
      closable={false}
      keyboard={false}
      maskClosable={false}
      footer={null}
    >
      <Box>
        <Text>
          Your scan failed because you tried scanning a private repository.
          Please try again on a public one.
        </Text>
        <Flex sx={{ justifyContent: 'flex-end', mt: 3 }}>
          <Link to={paths.closed.sources} sx={{ ml: 2 }} onClick={disposeModal}>
            <LinkButton
              text="Try scan again"
              type="primary"
              href={`https://github.com/settings/installations/${postGithubAppInstall}`}
            />
          </Link>
          <Link
            to={`${paths.closed.sources}?chat=true`}
            sx={{ ml: 2 }}
            onClick={disposeModal}
          >
            <Button size="large">Talk to an expert</Button>
          </Link>
        </Flex>
      </Box>
    </Modal>
  )
}

export default FailedModal
