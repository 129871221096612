/** @jsx jsx */
// eslint-disable-next-line
import { jsx, Flex } from 'theme-ui'
import { Shuki } from '../../common/icons'

export const WelcomeBar = () => (
  <Flex
    sx={{
      width: 80,
      background: 'linear-gradient(0deg, #250764, #250764)',
      justifyContent: 'center',
      borderRadius: '4px 0 0 4px',
    }}
  >
    <Shuki sx={{ mt: 20 }} />
  </Flex>
)
