/* eslint-disable react/no-danger */
/** @jsx jsx */
import { Slider, Tooltip } from 'antd'
import { jsx, Box, Flex, Text } from 'theme-ui'
import { SecretsEngineTags } from './consts'

export enum SecretsEngineScanMode {
  Base = 0,
  Security = 1,
  Audit = 2,
}

const SecretsEngineScanModeLevelToTags: Record<
  SecretsEngineScanMode,
  Array<SecretsEngineTags>
> = {
  [SecretsEngineScanMode.Base]: [SecretsEngineTags.Base],
  [SecretsEngineScanMode.Security]: [
    SecretsEngineTags.Base,
    SecretsEngineTags.Audit,
  ],
  [SecretsEngineScanMode.Audit]: [
    SecretsEngineTags.Base,
    SecretsEngineTags.Audit3,
  ],
}

export const SECRET_MODE_DEFAULT_LEVEL = SecretsEngineScanMode.Security

export const secretsTagsListByModeLevel = ({
  mode,
}: {
  mode: SecretsEngineScanMode
}): Array<SecretsEngineTags> => {
  return SecretsEngineScanModeLevelToTags[mode]
}

export const SecretsEngineModePicker = ({
  secretsTagsEnd,
  onChangeScanMode,
}) => {
  return (
    <Flex
      sx={{
        gap: '10px',
        alignItems: 'center',
        mt: '20px',
        ml: 'auto',
      }}
    >
      <Box sx={{ mr: '40px' }}>
        <Text>Mode:</Text>
      </Box>
      <Box>
        <Slider
          sx={{ my: '20px', width: '350px' }}
          max={2}
          value={secretsTagsEnd}
          tooltipVisible={false}
          autoFocus
          marks={{
            0: {
              style: {
                top: '-48px',
              },
              label: (
                <Tooltip title="The developer mode-ruleset is based on the highest precision detectors and ensures a low false-positive rate.">
                  Developer
                </Tooltip>
              ),
            },
            1: {
              style: {
                top: '-48px',
              },
              label: (
                <Tooltip title="Security mode increases the recall of results, this may result in a lower precision rate.">
                  Security
                </Tooltip>
              ),
            },
            2: {
              style: {
                top: '-48px',
              },
              label: (
                <Tooltip title="The audit mode includes a wide range of generic detectors which may result in a bigger recall yet lower precision and false-positive.">
                  Audit
                </Tooltip>
              ),
            },
          }}
          onChange={onChangeScanMode}
        />
      </Box>
    </Flex>
  )
}
