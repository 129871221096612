import React from 'react'
import { Dropdown, Button, Menu } from 'antd'
import { Flex, Box, Text } from 'theme-ui'
import styled from 'styled-components'
import { AssetDisplayStatus } from '@spectral/types'
import CubeIcon from '../../../components/cube-icon'
import CardCube from '../../../components/cube/cube'

const FiltersMenu = ({
  activeFilters,
  toggleFilter,
  showProblemsOnly,
  showAll,
}) => {
  const {
    critical,
    high,
    medium,
    low,
    informational,
    no_issues,
    not_active,
  } = activeFilters
  const Item = styled(Menu.Item)`
    && {
      padding: 10px 20px;
      width: 250px;
    }
  `

  const ItemWithSep = styled(Item)`
    border-top: 1px solid #eee;
  `
  return (
    <Menu>
      <Item onClick={() => toggleFilter(AssetDisplayStatus.Critical)}>
        <Flex>
          <Box sx={{ width: '25px', textAlign: 'left' }}>
            <CubeIcon type={AssetDisplayStatus.Critical} disabled={!critical} />
          </Box>
          <Text sx={{ fontSize: '14px' }}>Critical</Text>
        </Flex>
      </Item>

      <Item onClick={() => toggleFilter(AssetDisplayStatus.High)}>
        <Flex>
          <Box sx={{ width: '25px', textAlign: 'left' }}>
            <CubeIcon type={AssetDisplayStatus.High} disabled={!high} />
          </Box>
          <Text sx={{ fontSize: '14px' }}>High</Text>
        </Flex>
      </Item>

      <Item onClick={() => toggleFilter(AssetDisplayStatus.Medium)}>
        <Flex>
          <Box sx={{ width: '25px', textAlign: 'left' }}>
            <CubeIcon type={AssetDisplayStatus.Medium} disabled={!medium} />
          </Box>
          <Text sx={{ fontSize: '14px' }}>Medium</Text>
        </Flex>
      </Item>

      <Item onClick={() => toggleFilter(AssetDisplayStatus.Low)}>
        <Flex>
          <Box sx={{ width: '25px', textAlign: 'left' }}>
            <CubeIcon type={AssetDisplayStatus.Low} disabled={!low} />
          </Box>
          <Text sx={{ fontSize: '14px' }}>Low</Text>
        </Flex>
      </Item>

      <Item onClick={() => toggleFilter(AssetDisplayStatus.Informational)}>
        <Flex>
          <Box sx={{ width: '25px', textAlign: 'left' }}>
            <CubeIcon
              type={AssetDisplayStatus.Informational}
              disabled={!informational}
            />
          </Box>
          <Text sx={{ fontSize: '14px' }}>Informational</Text>
        </Flex>
      </Item>

      <Item onClick={() => toggleFilter(AssetDisplayStatus.NoIssues)}>
        <Flex>
          <Box sx={{ width: '25px', textAlign: 'left' }}>
            <CubeIcon
              type={AssetDisplayStatus.NoIssues}
              disabled={!no_issues}
            />
          </Box>
          <Text sx={{ fontSize: '14px' }}>No Issues</Text>
        </Flex>
      </Item>

      <Item onClick={() => toggleFilter(AssetDisplayStatus.NotActive)}>
        <Flex>
          <Box sx={{ width: '25px', textAlign: 'left' }}>
            <CubeIcon
              type={AssetDisplayStatus.NotActive}
              disabled={!not_active}
            />
          </Box>
          <Text sx={{ fontSize: '14px' }}>Not Active</Text>
        </Flex>
      </Item>
      <ItemWithSep onClick={() => showProblemsOnly()}>
        <Flex>
          <Text sx={{ fontSize: '14px' }}>Show Problems Only</Text>
        </Flex>
      </ItemWithSep>
      <Item onClick={() => showAll()}>
        <Flex>
          <Text sx={{ fontSize: '14px' }}>Show All</Text>
        </Flex>
      </Item>
    </Menu>
  )
}
const FiltersMenuButton = ({
  activeFilters,
  toggleFilter,
  showProblemsOnly,
  showAll,
}) => {
  return (
    <Dropdown
      overlay={FiltersMenu({
        activeFilters,
        toggleFilter,
        showProblemsOnly,
        showAll,
      })}
    >
      <Button type="ghost" size="middle">
        <Flex>
          <CardCube view={AssetDisplayStatus.Critical} size={14} />
          <Box sx={{ ml: '-4px' }}>
            <CardCube view={AssetDisplayStatus.High} size={14} />
          </Box>
          <Box sx={{ ml: '-4px' }}>
            <CardCube view={AssetDisplayStatus.Medium} size={14} />
          </Box>
          <Box sx={{ ml: '-4px' }}>
            <CardCube view={AssetDisplayStatus.Low} size={14} />
          </Box>
          <Box sx={{ ml: '-4px' }}>
            <CardCube view={AssetDisplayStatus.Informational} size={14} />
          </Box>
          <Box sx={{ ml: '-4px' }}>
            <CardCube view={AssetDisplayStatus.NoIssues} size={14} />
          </Box>
        </Flex>
      </Button>
    </Dropdown>
  )
}

export default FiltersMenuButton
