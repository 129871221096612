import { Flex } from 'theme-ui'
import React, { useCallback, useState } from 'react'
import { Checkbox } from 'antd'
import { map, size } from 'lodash'
import IgnoreButton from '../../ignore-button'
import ResolveButton from '../../resolved-button/view'
import AssignButton from '../../assign-button/view'
import IssueActionIntegrations from '../../../containers/issue-action-integrations/issue-action-integrations'
import TableActions from '../../table-actions/table-actions'
import { ActionButtonType } from '../../../common/track/utils'
import Restricted from '../../../containers/role-based-render/restricted'
import { RoleType } from '@spectral/types'

const IssuesListActions = ({
  actionHandlers,
  selectedIssues,
  issues,
  isDownloadingExport,
  integrations,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  onActionIntegrationSubmit,
  exportActions,
  onSecretSelectionChanged,
  isSelectAllShown = true,
  currentQueryStringParams,
  setQueryStringParam,
  trackSort,
  sortOptions,
}) => {
  const [isActionInProgressResolve, setIsActionInProgressResolve] = useState(
    false
  )
  const [isActionInProgressAssign, setIsActionInProgressAssign] = useState(
    false
  )
  const [
    handleIgnore,
    handleSnooze,
    handleAssigneeChange,
    handleAssigneeRemove,
    handleAssigneeSearch,
    _handleSeverityChange,
    handleResolve,
    handleUnresolve,
  ] = actionHandlers
  const selectedIssuesIds = map(selectedIssues, 'pid')

  const isAllSelected =
    size(selectedIssues) === size(issues) && size(issues) > 0
  const onSelectAll = () => {
    onSecretSelectionChanged(issues, !isAllSelected)
  }

  const onAssignClick = useCallback(
    async ({ assigneePid }) => {
      setIsActionInProgressAssign(true)
      await handleAssigneeChange({
        assigneePid,
        issueIds: selectedIssuesIds,
      })
      setIsActionInProgressAssign(false)
    },
    [selectedIssuesIds]
  )

  const onUnassignClick = useCallback(async () => {
    setIsActionInProgressAssign(true)
    await handleAssigneeRemove({ issueIds: selectedIssuesIds })
    setIsActionInProgressAssign(false)
  }, [handleAssigneeRemove, selectedIssuesIds])

  const onSearchAssignee = useCallback(
    async (payload) => {
      await handleAssigneeSearch({
        nameFilter: payload,
        assetId: selectedIssues[0].asset.id,
      })
    },
    [handleAssigneeSearch, selectedIssues]
  )

  const onReolveClick = useCallback(async () => {
    setIsActionInProgressResolve(true)
    await handleResolve(selectedIssues, ActionButtonType.BULK)
    setIsActionInProgressResolve(false)
  }, [handleResolve, selectedIssues])

  const onUnreolveClick = useCallback(async () => {
    setIsActionInProgressResolve(true)
    await handleUnresolve(selectedIssues, ActionButtonType.BULK)
    setIsActionInProgressResolve(false)
  }, [handleUnresolve, selectedIssues])
  return (
    <Flex
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: '10px',
      }}
    >
      <Flex sx={{ paddingLeft: 3, alignItems: 'center', gap: '10px' }}>
        <Restricted roles={[RoleType.Owner, RoleType.Admin, RoleType.Member]}>
          <React.Fragment>
            {isSelectAllShown && (
              <Checkbox checked={isAllSelected} onChange={onSelectAll} />
            )}
            <IgnoreButton
              onIgnoreClicked={handleIgnore}
              onSnoozeClicked={handleSnooze}
              selectedIssues={selectedIssues.filter(
                (issue) => !issue.metadata?.ignore_categorization
              )}
              buttonType={ActionButtonType.BULK}
            />
            <ResolveButton
              isLoading={isActionInProgressResolve}
              selectedIssues={selectedIssues.filter(
                (issue) => !issue.metadata?.ignore_categorization
              )}
              onResolveClicked={onReolveClick}
              onUnreoslveClick={onUnreolveClick}
            />
            <AssignButton
              isLoading={isActionInProgressAssign}
              selectedIssues={selectedIssues}
              onUnassignClick={onUnassignClick}
              onAssignClick={onAssignClick}
              assignableMembers={assignableMembers}
              isLoadingAssignableMembers={isLoadingAssignableMembers}
              isAssignableMembersLoaded={isAssignableMembersLoaded}
              onSearchAssignee={onSearchAssignee}
            />
            <IssueActionIntegrations
              integrations={integrations}
              selectedIssues={selectedIssues}
              onSubmit={onActionIntegrationSubmit}
            />
          </React.Fragment>
        </Restricted>
      </Flex>
      <TableActions
        isDownloadingExport={isDownloadingExport}
        actions={exportActions}
        currentQueryStringParams={currentQueryStringParams}
        setQueryStringParam={setQueryStringParam}
        trackSort={trackSort}
        sortOptions={sortOptions}
      />
    </Flex>
  )
}

export default IssuesListActions
