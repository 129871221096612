import { digestInfo, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const travisCI = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'Travis',
    content: 'Integrate as a test or script step',
    tileKey: 'travisCI',
    selfHostedRelevantProviders: [
      selfHostedProviders.gitlabSelfHosted,
      selfHostedProviders.githubEnterprise,
      selfHostedProviders.bitbucketServer,
    ],
    snippets: [
      {
        title:
          'Store your SPECTRAL_DSN in <a href="https://docs.travis-ci.com/user/environment-variables/" target="_blank" rel="noopener noreferrer">Travis Env Store</a>',
        code: `{dsn}`,
        type: 'yaml',
      },
      {
        title: "Here's an example run of Spectral on Travis CI:",
        code: `language: ruby
install:
  - curl -L "{baseUrl}/latest/x/sh?dsn=$SPECTRAL_DSN" | sh
  - $HOME/.spectral/spectral --version
script:
  - $HOME/.spectral/spectral scan --ok {options}`,
        type: 'yaml',
        postDescription: digestInfo,
      },
    ],
  },
}
