import { digestInfo, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const circleCI = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'CircleCI',
    content: 'Integrate as a test or script step',
    tileKey: 'circleCI',
    selfHostedRelevantProviders: [
      selfHostedProviders.gitlabSelfHosted,
      selfHostedProviders.githubEnterprise,
      selfHostedProviders.bitbucketServer,
    ],
    snippets: [
      {
        title:
          'Store your SPECTRAL_DSN in <a href="https://circleci.com/docs/2.0/env-vars/#:~:text=Secrets%20Masking,-Secrets%20masking%20is&text=Environment%20variables%20may%20hold%20project,echo%20or%20print%20are%20used." target="_blank" rel="noopener noreferrer">CircleCI Secret Store</a>',
        code: `{dsn}`,
        type: 'yaml',
      },
      {
        title:
          "Here's how your CircleCI integration would look like with Spectral:",
        code: `version: 2.1
workflows:
test-env-vars:
  jobs:
    - build:
        context: 
          - SPECTRAL_DSN
          
jobs:
  build:
    docker:
      - image: circleci/node:latest
    steps:
      - checkout
      - run: curl -L "{baseUrl}/latest/x/sh?dsn=$SPECTRAL_DSN" | sh
      # This takes your SPECTRAL_DSN from secret store in CircleCI
      - run: $HOME/.spectral/spectral scan --ok {options}
`,
        type: 'yaml',
        postDescription: digestInfo,
      },
    ],
  },
}
