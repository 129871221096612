/** @jsx jsx */
import { jsx, Flex, Text, Box } from 'theme-ui'
import { Input, Form, Button } from 'antd'
import { AiOutlineDelete } from 'react-icons/ai'
import { PlusOutlined } from '@ant-design/icons'
import L from 'lodash'
import { MS_TEAMS_WEBHOOK_URL_REGEX } from '@spectral/types'

export const OrgTeamMsTeamsIntegration = () => {
  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Box sx={{ mb: '20px' }}>
        <Text sx={{ fontWeight: 600 }}>Creating a Microsoft Teams webhook</Text>
        <Text>1. Add webhook app to existing channel</Text>
        <Text>2. Configure webhook URL for Spectral alerts</Text>
        <Text>3. Paste your webhook URL here</Text>
        <Text sx={{ mt: '10px' }}>
          For more information visit{' '}
          <a
            href="https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook"
            rel="noopener noreferrer"
            target="_blank"
          >
            the Microsoft Teams docs
          </a>
        </Text>
      </Box>
      <Form.List
        name="msTeamsWebhookUrl"
        rules={[
          {
            validator: async (_, msTeamsWebhookUrls) => {
              if (msTeamsWebhookUrls) {
                const webhooksValues = L.without(
                  msTeamsWebhookUrls,
                  '',
                  undefined
                )
                const uniqWebhooks = L.uniq(webhooksValues)
                if (uniqWebhooks.length !== webhooksValues.length)
                  return Promise.reject(
                    new Error('This webhook is already attached to the team')
                  )
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Form.ErrorList errors={errors} />
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? 'Webhook URLs' : ''}
                key={field.key}
                sx={{ mb: 1 }}
              >
                <Flex>
                  <Form.Item
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        message: 'Microsoft Teams webhook url is required',
                      },
                      {
                        pattern: new RegExp(MS_TEAMS_WEBHOOK_URL_REGEX),
                        message:
                          'Please enter a valid Microsoft Teams webhook url',
                      },
                    ]}
                    sx={{ flexGrow: 1 }}
                  >
                    <Input placeholder="Please enter Microsoft Teams webhook url" />
                  </Form.Item>
                  <AiOutlineDelete
                    sx={{
                      cursor: 'pointer',
                      ml: '10px',
                      alignSelf: 'flex-start',
                      minHeight: '40px',
                    }}
                    onClick={() => remove(field.name)}
                  />
                </Flex>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Webhook
              </Button>
            </Form.Item>
          </Box>
        )}
      </Form.List>
    </Flex>
  )
}
