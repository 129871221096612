import { Avatar } from 'antd'
import { AiOutlineUserDelete } from 'react-icons/ai'
import { ISSUE_UNASSIGNED } from '@spectral/types'
import React from 'react'
import { Box, Flex } from 'theme-ui'
import { orderBy } from 'lodash'
import FilterTotalDisplay from '../../../components/filters/filter-total-display'

const AssigneeOption = ({ assignee }) => {
  return (
    <Flex>
      <Box>
        <Avatar
          style={{ height: '20px', width: '20px', marginRight: '8px' }}
          src={assignee.avatarUrl}
        >
          {assignee.title?.toUpperCase()[0]}
        </Avatar>
      </Box>
      <Flex
        sx={{
          marginLeft: '3px',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box>{assignee.title}</Box>
        <Box>
          <FilterTotalDisplay total={assignee.total} />
        </Box>
      </Flex>
    </Flex>
  )
}

const unassignedOption = (unassignedIssuesCount) => ({
  key: ISSUE_UNASSIGNED.toLowerCase(),
  title: ISSUE_UNASSIGNED,
  renderTitle: (
    <Flex>
      <Box>
        <Avatar style={{ height: '20px', width: '20px', marginRight: '8px' }}>
          <Box sx={{ height: '0px', width: '30px', marginLeft: '-5px' }}>
            <AiOutlineUserDelete size={35} />
          </Box>
        </Avatar>
      </Box>
      <Flex
        sx={{
          marginLeft: '3px',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ textAlign: 'left' }}>{ISSUE_UNASSIGNED}</Box>
        <Box>
          <FilterTotalDisplay total={unassignedIssuesCount || 0} />
        </Box>
      </Flex>
    </Flex>
  ),
  total: unassignedIssuesCount,
})

export const assigneeOptions = (data) => {
  const initialOptionsArray =
    data.unassignedIssuesCount > 0
      ? [unassignedOption(data.unassignedIssuesCount)]
      : []

  return data.options?.length > 0
    ? initialOptionsArray.concat(
        orderBy(data.options, 'total', 'desc')
          .filter((assignee) => assignee.total > 0)
          .map((assignee) => ({
            key: assignee.id,
            title: assignee.title,
            renderTitle: <AssigneeOption assignee={assignee} />,
            total: assignee.total,
          }))
      )
    : []
}
