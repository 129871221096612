import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import IssueActionIntegrationsView from '../../blocks/issue-action-integrations/view'

const IssueActionIntegrations = ({
  integrations,
  selectedIssues,
  onSubmit,
}) => {
  const dispatch = useDispatch()
  const isCreateJiraIssueModalVisible = useSelector(
    (state) => state.Jira.isModalVisible
  )
  const isCreateMondayItemModalVisible = useSelector(
    (state) => state.Monday.isModalVisible
  )

  return (
    <IssueActionIntegrationsView
      onSubmit={onSubmit}
      setJiraModalVisibility={(isVisible) => {
        dispatch.Jira.setIsModalVisible(isVisible)
      }}
      setMondayModalVisibility={(isVisible) => {
        dispatch.Monday.setIsModalVisible(isVisible)
      }}
      integrations={integrations}
      selectedIssues={selectedIssues}
      isCreateJiraIssueModalVisible={isCreateJiraIssueModalVisible}
      isCreateMondayItemModalVisible={isCreateMondayItemModalVisible}
    />
  )
}

export default IssueActionIntegrations
