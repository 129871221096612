import { Empty } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { PrimaryButtonMedium } from '../buttons'

const EmptyState = ({ sources = null }) => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>{`No data${sources ? ', add a scan' : ''}`}</span>}
    >
      {sources ? (
        <Link to={sources}>
          <PrimaryButtonMedium text="Add Sources" />
        </Link>
      ) : null}
    </Empty>
  )
}

export default EmptyState
