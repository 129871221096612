import L from 'lodash'
import {
  AssetDisplayStatus,
  AssetStats,
  ReportAssetView,
  SortDirection,
} from '@spectral/types'
import { FetchStatus, RootState, sdkClient } from '../../store'
import { empty, loaded } from '../../utils'
import { hiveFiltersPersistence } from '../../persistence'

export type HiveFilters = {
  [AssetDisplayStatus.Critical]: boolean
  [AssetDisplayStatus.High]: boolean
  [AssetDisplayStatus.Medium]: boolean
  [AssetDisplayStatus.Low]: boolean
  [AssetDisplayStatus.Informational]: boolean
  [AssetDisplayStatus.NotActive]: boolean
  [AssetDisplayStatus.NoIssues]: boolean
  assetTypes: string[]
}

type AssetsState = {
  assets: { fetchStatus: FetchStatus; data: Array<ReportAssetView> }
  assetKindsStats: { fetchStatus: FetchStatus; data: any }
  hiveFilters: HiveFilters
}
const initialState: AssetsState = {
  assets: empty([]),
  assetKindsStats: empty([]),
  hiveFilters: {
    [AssetDisplayStatus.Critical]: true,
    [AssetDisplayStatus.High]: true,
    [AssetDisplayStatus.Medium]: true,
    [AssetDisplayStatus.Low]: true,
    [AssetDisplayStatus.Informational]: true,
    [AssetDisplayStatus.NoIssues]: true,
    [AssetDisplayStatus.NotActive]: true,
    assetTypes: [],
  },
}

export const assets = {
  state: initialState,
  reducers: {
    set(state: AssetsState, assetsData: any) {
      return {
        ...state,
        assets: {
          data: loaded(assetsData.assetsSummary),
          count: assetsData.count,
        },
      }
    },
    setSingle(state: AssetsState, newAsset: any) {
      const newState = { ...state }
      const currentIndex = L.findIndex(newState.assets.data, {
        id: newAsset.id,
      })
      newState.assets.data[currentIndex] = newAsset
      return newState
    },
    setScans(state: AssetsState, scansData: any) {
      return { ...state, scans: loaded(scansData) }
    },
    setAssetKindsStats(state: AssetsState, data: any) {
      return { ...state, assetKindsStats: loaded(data) }
    },
    setHiveFilters(state: AssetsState, hiveFilters: HiveFilters) {
      return { ...state, hiveFilters }
    },
  },
  effects: (dispatch: any) => ({
    async fetchAssetsSummary() {
      const { data } = (await sdkClient.assets().assetsSummary()) as {
        data: any
      }

      dispatch.Assets.set(data)
    },
    async fetchAsset(payload) {
      const { assetId } = payload
      const asset = await sdkClient
        .assets()
        .assetById({ params: { assetId: encodeURIComponent(assetId) } })
      if (!L.isEmpty(asset)) {
        dispatch.Assets.setSingle(asset)
      }

      return asset
    },
    hiveShowProblemsOnly(payload, rootState: RootState) {
      dispatch.Assets.updateHiveFilters({
        ...rootState.Assets.hiveFilters,
        error: true,
        warning: true,
        info: false,
        not_active: false,
        no_issues: false,
      })
    },
    hiveShowAll(payload, rootState: RootState) {
      dispatch.Assets.updateHiveFilters({
        ...rootState.Assets.hiveFilters,
        error: true,
        warning: true,
        info: true,
        not_active: true,
        no_issues: true,
        assetTypes: [],
      })
    },
    toggleHiveFilter(filter, rootState: RootState) {
      dispatch.Assets.updateHiveFilters({
        ...rootState.Assets.hiveFilters,
        [filter]: !rootState.Assets.hiveFilters[filter],
      })
    },
    setHiveAssetTypeFilter(payload, rootState: RootState) {
      dispatch.Assets.updateHiveFilters({
        ...rootState.Assets.hiveFilters,
        assetTypes: payload,
      })
    },
    updateHiveFilters(hiveFilters: HiveFilters) {
      hiveFiltersPersistence.persist(hiveFilters)
      dispatch.Assets.setHiveFilters(hiveFilters)
    },
    loadHiveFilters() {
      const filters = hiveFiltersPersistence.get()
      let newFilters = {}
      if (filters.error || filters.warning || filters.info) {
        newFilters = {
          [AssetDisplayStatus.Critical]: true,
          [AssetDisplayStatus.High]: true,
          [AssetDisplayStatus.Medium]: true,
          [AssetDisplayStatus.Low]: true,
          [AssetDisplayStatus.Informational]: true,
          [AssetDisplayStatus.NotActive]: true,
          [AssetDisplayStatus.NoIssues]: true,
          assetTypes: filters.assetTypes || [],
        }
      } else if (filters) {
        // backwards competability - remove somtime
        newFilters = {
          [AssetDisplayStatus.Critical]: filters[AssetDisplayStatus.Critical],
          [AssetDisplayStatus.High]: filters[AssetDisplayStatus.High],
          [AssetDisplayStatus.Medium]: filters[AssetDisplayStatus.Medium],
          [AssetDisplayStatus.Low]: filters[AssetDisplayStatus.Low],
          [AssetDisplayStatus.Informational]:
            filters[AssetDisplayStatus.Informational],
          [AssetDisplayStatus.NotActive]:
            filters[AssetDisplayStatus.NotActive] || filters.old,
          [AssetDisplayStatus.NoIssues]:
            filters[AssetDisplayStatus.NoIssues] || filters.none,
          assetTypes: filters.assetTypes || [],
        }
        dispatch.Assets.setHiveFilters(newFilters)
      }
    },
    async deleteAsset(payload) {
      try {
        const result = (await sdkClient.assets().deleteAsset({
          params: { assetId: encodeURIComponent(payload) },
        })) as any
        if (result.result === 'success') {
          dispatch.Auth.refetchUser()
          dispatch.Assets.fetchAssetsSummary()
        }
        return result
      } catch (err) {
        return false
      }
    },
  }),
  selectors: (slice, createSelector, _hasProps) => ({
    hivePage() {
      return createSelector(
        // @ts-ignore
        (rootState: RootState) => rootState.Assets,
        (rootState: RootState, _props) => rootState,
        (assetsState, rootState) => {
          const allAssets = assetsState.assets.data.data || []
          const { hiveFilters } = assetsState
          const HIVE_CARD_MAX_ITEMS = 100

          const activeFilters = Object.keys(hiveFilters).reduce((acc, cur) => {
            if (hiveFilters[cur] === true) acc.push(cur)
            return acc
          }, [])

          const filteredAssets = allAssets
            .filter((asset) => {
              return activeFilters.includes(asset.displayStatus)
            })
            .filter(
              (asset) =>
                hiveFilters?.assetTypes?.length === 0 ||
                hiveFilters.assetTypes.includes(asset.category)
            )
          const assetsByOrgTeams = L.groupBy(filteredAssets, 'orgTeam')
          const {
            critical,
            high,
            medium,
            low,
            informational,
            ignored,
          } = filteredAssets.reduce(
            (stats, asset) => {
              stats[AssetStats.Critical] += asset.stats[AssetStats.Critical]
              stats[AssetStats.High] += asset.stats[AssetStats.High]
              stats[AssetStats.Medium] += asset.stats[AssetStats.Medium]
              stats[AssetStats.Low] += asset.stats[AssetStats.Low]
              stats[AssetStats.Informational] +=
                asset.stats[AssetStats.Informational]
              stats.ignored += asset.stats[AssetStats.Ignore]
              return stats
            },
            {
              critical: 0,
              high: 0,
              medium: 0,
              low: 0,
              informational: 0,
              ignored: 0,
            }
          )

          const hiveStats = {
            totalAssets: assetsState.assets.count,
            latestAssetsCount: allAssets.length,
            filteredAssetCount: filteredAssets.length,
            filteredStats: {
              critical,
              high,
              medium,
              low,
              informational,
              ignored,
            },
          }

          const hiveCardsData = Object.entries(assetsByOrgTeams).map(
            ([orgTeam, teamAssets]) => {
              const latestScannedAsset = L.maxBy(teamAssets, 'lastScanDate')

              const {
                teamCritical,
                teamHigh,
                teamMedium,
                teamLow,
                teamInformational,
                teamIgnored,
              } = teamAssets.reduce(
                (stats, asset) => {
                  stats.teamCritical += asset.stats[AssetStats.Critical]
                  stats.teamHigh += asset.stats[AssetStats.High]
                  stats.teamMedium += asset.stats[AssetStats.Medium]
                  stats.teamLow += asset.stats[AssetStats.Low]
                  stats.teamInformational +=
                    asset.stats[AssetStats.Informational]
                  stats.teamIgnored += asset.stats[AssetStats.Ignore]
                  return stats
                },
                {
                  teamCritical: 0,
                  teamHigh: 0,
                  teamMedium: 0,
                  teamLow: 0,
                  teamInformational: 0,
                  teamIgnored: 0,
                }
              )

              const visibleTeamAssets = L.orderBy(
                teamAssets,
                ['lastScanDate'],
                [SortDirection.DESC]
              ).slice(0, HIVE_CARD_MAX_ITEMS)
              const numberOfHiddenRepositories =
                teamAssets.length - HIVE_CARD_MAX_ITEMS
              return {
                teamName: orgTeam,
                assets: L.orderBy(
                  visibleTeamAssets.map((teamAsset) => ({
                    assetId: teamAsset.id,
                    createdAt: teamAsset.lastScanDate,
                    name: teamAsset.displayName,
                    severity:
                      teamAsset.displayStatus === AssetDisplayStatus.NoIssues &&
                      teamAsset.isNotActive
                        ? AssetDisplayStatus.NotActive
                        : teamAsset.displayStatus,
                    totalCritical: teamAsset.stats[AssetStats.Critical],
                    totalHigh: teamAsset.stats[AssetStats.High],
                    totalMedium: teamAsset.stats[AssetStats.Medium],
                    totalLow: teamAsset.stats[AssetStats.Low],
                    totalInformational:
                      teamAsset.stats[AssetStats.Informational],
                    totalIgnored: teamAsset.stats[AssetStats.Ignore],
                    uri: teamAsset.uri,
                    kind: teamAsset.kind,
                  })),
                  ['createdAt'],
                  [SortDirection.DESC]
                ),
                lastScanTime: latestScannedAsset.lastScanDate,
                totalNumberOfCritical: teamCritical,
                totalNumberOfHigh: teamHigh,
                totalNumberOfMedium: teamMedium,
                totalNumberOfLow: teamLow,
                totalNumberOfInformational: teamInformational,
                totalNumberOfIgnored: teamIgnored,
                numberOfHiddenRepositories,
                assetTypes: L.uniq(
                  visibleTeamAssets.map((asset) => asset.kind)
                ),
              }
            }
          )
          return {
            data: hiveCardsData,
            hiveStats,
            assetTypes: L.uniq(allAssets.map((asset) => asset.kind)),
            status: {
              loaded:
                assetsState.assets.data.fetchStatus === 'loaded' &&
                rootState.loading.effects.Auth.fetchSettings < 1,
              pageError:
                assetsState.assets.data.fetchStatus === 'none' &&
                rootState.error.effects.Assets.fetchAssetsSummary !== null,
              isRefreshing:
                rootState.loading.effects.Assets.fetchAssetsSummary > 0,
            },
          }
        }
      )
    },
    getAssets() {
      /// The purpose of this selector is to get all the assets and issues filtered by global filters and not specific to any page
      return createSelector(
        (rootState) => rootState.Assets,
        (assetsState) => {
          return assetsState.assets.data.data
        }
      )
    },
    getAssetsDropDown() {
      return createSelector(
        (rootState) => rootState.Assets,
        (assetsState) => {
          const allAssets = assetsState.assets.data.data || []
          return allAssets.map(({ id, displayName, kind }) => ({
            label: displayName,
            key: id,
            value: displayName,
            assetDisplayName: displayName,
            assetType: kind,
            assetId: id,
          }))
        }
      )
    },
    assetKindsStats() {
      return createSelector(
        (rootState) => rootState.Assets,
        (assetsState) => {
          return {
            data: assetsState.assetKindsStats.data,
            status: {
              loaded: assetsState.assetKindsStats.fetchStatus === 'loaded',
            },
          }
        }
      )
    },
    needLoading() {
      return createSelector(
        (rootState) => rootState.Assets,
        (rootState) => rootState.loading.effects.Assets.fetchAssetsSummary,
        (assetsState, loading) => {
          return assetsState.assets.data.fetchStatus === 'none' && !loading
        }
      )
    },
  }),
}
