export const organizationNamePatternAsString =
  "^[0-9A-Za-z'.!-]+(?:\\s[0-9A-Za-z'.!-]+)*$"

export const organizationNamePatternAsRegex = new RegExp(
  organizationNamePatternAsString
)

export const SLACK_WEBHOOK_URL_REGEX =
  '^(https://hooks.slack.com/services)/.+/.+/.+'

export const MS_TEAMS_WEBHOOK_URL_REGEX =
  '^(https://.+.webhook.office.com/).+/.+/.+/.+'

export const domainRegex =
  '^(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\\.)+[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$'

export const DOMAIN_WITHOUT_PROTOCOL_REGEX =
  '^(?!.*://)(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\\.)+[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$'

export const isUrl = (path) => {
  try {
    const url = new URL(path)
    return url.protocol === 'https:' || url.protocol === 'http:'
  } catch (err) {
    return false
  }
}
