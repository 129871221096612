/** @jsx jsx */
import { jsx, Text, Link } from 'theme-ui'
import { Tooltip } from 'antd'
import theme from '../../common/theme'

const DetectorInfo = ({
  isIgnored,
  link,
  detectorId,
  detectorName,
  onClick,
  showTooltip = true,
}) => {
  return (
    <Text
      sx={{
        fontWeight: 700,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: isIgnored
          ? theme.stylebook.colors.gray['500']
          : theme.stylebook.colors.text,
      }}
    >
      {link ? (
        <Tooltip
          title={showTooltip && `Open playbook for detector ${detectorId}`}
        >
          <Link
            onClick={onClick}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {detectorName}
          </Link>
        </Tooltip>
      ) : (
        <Tooltip
          title={showTooltip && `Playbook for ${detectorId} is coming soon`}
        >
          {detectorName}
        </Tooltip>
      )}
    </Text>
  )
}

export default DetectorInfo
