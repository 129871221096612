import { OperatingSystem } from './enums'

export const download = {
  [OperatingSystem.MACLINUX]: {
    snippet: "$ curl -L '{baseUrl}/latest/x/sh?key={teamKey}' | sh",
  },
  [OperatingSystem.WINDOWS]: {
    text:
      'You can download the <a href="{baseUrl}/latest/dl/installer?key={teamKey}">Spectral Installer from This Link</a> and run the installer, or you can use your PowerShell with:\n',
    snippet: 'iwr {baseUrl}/latest/ps1?key={teamKey} -useb | iex',
  },
}
