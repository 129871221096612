import React from 'react'
import * as Yup from 'yup'
import { notification } from 'antd'
import {
  ButtonField,
  CustomFormComponent,
  InputFieldSection,
  PanelForm,
  SubmitSection,
} from '../../../../components/panels/content'
import webhooksLogo from '../../../../assets/png/webhooks.png'
import { IntegrationSection } from '../../../../components/integrations/integration-sections'

export const CustomWebhookIntegration = ({
  isActivated,
  toggleCustomWebhook,
  setCustomWebhookSettings,
  isTestInProgress,
  webhookUrl,
  signatureToken,
  onTestClicked,
}) => {
  return (
    <IntegrationSection
      enabled={isActivated}
      onEnableToggle={({ custom_webhook_integrations_checkbox }) =>
        toggleCustomWebhook(custom_webhook_integrations_checkbox)
      }
      integrationName="custom_webhook"
      headerLogo={webhooksLogo}
      headerTitle="Custom Webhook Global Integration"
      sectionTitle=""
      sectionDescription="Integrate Spectral with your custom webhook to receive notifications when new issues discovered"
      sectionRenderedContent={null}
    >
      <PanelForm
        onSubmit={setCustomWebhookSettings}
        initialValues={{
          webhookUrl,
          signatureToken,
        }}
        validationSchema={Yup.object({
          webhookUrl: Yup.string()
            .url('Must be a valid URL')
            .required('Required'),
          signatureToken: Yup.string().required('Required'),
        })}
      >
        <InputFieldSection
          name="webhookUrl"
          label="URL"
          description="The url to send notifications to"
          placeholder="https://my.domain.com/spectral/reciever"
          required
          merge
        />
        <InputFieldSection
          name="signatureToken"
          label="Signature token"
          description="Required to verify the message integrity on the receiving side"
          placeholder="3e257e57bc674b94bac10b1a4bda41e7"
          required
          merge
        />
        <CustomFormComponent>
          {(
            _setFieldValue,
            _setFieldTouched,
            _validateForm,
            values,
            isValid
          ) => (
            <ButtonField
              disabled={!isValid}
              loading={isTestInProgress}
              description="We'll send a dummy issue to your webhook"
              onClick={async () => {
                const { isTestSuccessful, message } = await onTestClicked({
                  webhookUrl: values.webhookUrl,
                  signatureToken: values.signatureToken,
                })
                if (isTestSuccessful) {
                  notification.success({
                    message: 'Success!',
                    description: message,
                  })
                } else {
                  notification.error({
                    message: 'Failed',
                    description: message,
                  })
                }
              }}
              name="test"
              label="Test"
            />
          )}
        </CustomFormComponent>
        <SubmitSection text="Update" />
      </PanelForm>
    </IntegrationSection>
  )
}
