/** @jsx jsx */
import { Flex, jsx, Box } from 'theme-ui'
import { PlanType } from '@spectral/types'
import FreePlan from './plans/free-plan'
import BusinessPlan from './plans/business-plan'
import EnterprisePlan from './plans/enterprise-plan'
import TeamPlan from './plans/team-plan'

const cardWidthStyle = { width: '300px' }

const Plans = ({
  currentPlanType,
  onCheckoutClick,
  onManageSubscriptionClick,
  subscriptionMaxContributors,
}) => {
  return (
    <Box>
      <Flex sx={{ gap: 3, margin: 'auto' }}>
        <Box sx={cardWidthStyle}>
          <FreePlan isCurrentPlan={currentPlanType === PlanType.Free} />
        </Box>
        <Box sx={cardWidthStyle}>
          <TeamPlan
            isCurrentPlan={currentPlanType === PlanType.Team}
            onCheckoutClick={onCheckoutClick}
            onManageSubscriptionClick={onManageSubscriptionClick}
            subscriptionMaxContributors={subscriptionMaxContributors}
          />
        </Box>
        <Box sx={cardWidthStyle}>
          <BusinessPlan isCurrentPlan={currentPlanType === PlanType.Business} />
        </Box>
        <Box sx={cardWidthStyle}>
          <EnterprisePlan
            isCurrentPlan={currentPlanType === PlanType.Enterprise}
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default Plans
