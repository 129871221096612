import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Table, Tooltip, Typography } from 'antd'
import React from 'react'
import { Box } from 'theme-ui'

const { Text } = Typography

const MINIMUM_ROWS_FOR_SCROLL = 4
const SCROLL_HEIGHT = 275

type Props = {
  onDelete: Function
  onEdit: Function
  ignores: []
  canUserEdit: boolean
}

const EllipsisCellRender = (text: string) => (
  <Box sx={{ paddingLeft: '16px' }}>
    <Text
      ellipsis={{ tooltip: text }}
      style={{
        maxWidth: '95%',
      }}
    >
      {text || '*'}
    </Text>
  </Box>
)

const IgnoresTable = ({ onDelete, onEdit, canUserEdit, ignores }: Props) => {
  const hasData = ignores.length > 0

  const iconCellRender = (onClick, IconComponent, generateToolTipText) => (
    text: string,
    record
  ) => (
    <Box
      sx={{
        cursor: canUserEdit ? 'pointer' : 'default',
      }}
      onClick={canUserEdit ? () => onClick?.(record.index) : null}
    >
      <Tooltip title={generateToolTipText(record)}>
        <IconComponent
          style={{ color: `${canUserEdit ? '' : 'lightgray'}` }}
          disabled={!canUserEdit}
        />
      </Tooltip>
    </Box>
  )

  const buildIgnoreDecription = (ignore) => {
    const queryText = Object.keys(ignore)
      .filter((key) => !!ignore[key] && key !== 'index')
      .map((key) => `${key} match "${ignore[key]}"`)
      .join(' AND ')

    return `Ignore if ${queryText}`
  }

  const ignoresTableColumns = [
    {
      width: '140px',
      title: 'Rule ID',
      dataIndex: 'rule_id',
      key: 'rule_id',
      filterSearch: true,
      render: EllipsisCellRender,
    },
    {
      title: 'Rule Name',
      dataIndex: 'rule_name',
      key: 'rule_name',
      width: '20%',
      filterSearch: true,
      render: EllipsisCellRender,
    },
    {
      title: 'Path',
      dataIndex: 'path',
      key: 'path',
      width: '20%',
      filterSearch: true,
      render: EllipsisCellRender,
    },
    {
      title: 'Fingerprint',
      dataIndex: 'match_fingerprint',
      key: 'match_fingerprint',
      width: '20%',
      filterSearch: true,
      render: EllipsisCellRender,
    },
    {
      title: 'Finding Text',
      dataIndex: 'match_text',
      key: 'match_text',
      width: '20%',
      filterSearch: true,
      render: EllipsisCellRender,
    },
    {
      title: 'Finding Line Text',
      dataIndex: 'match_line_text',
      key: 'match_line_text',
      width: '20%',
      filterSearch: true,
      render: EllipsisCellRender,
    },
    {
      width: '40px',
      render: iconCellRender(null, InfoCircleOutlined, buildIgnoreDecription),
    },
    {
      width: '40px',
      render: iconCellRender(
        onEdit,
        EditOutlined,
        () => 'Click to edit ignore'
      ),
    },
    {
      width: '40px',
      render: iconCellRender(
        onDelete,
        DeleteOutlined,
        () => 'Click to delete ignore'
      ),
    },
  ]

  return (
    <Box
      sx={{
        '.ant-table-cell': {
          padding: '0',
          height: '60px',
        },
        '.ant-table-cell div not:(.ant-empty)': {
          height: hasData ? '60px' : 'inherit',
          lineHeight: hasData ? '60px' : 'inherit',
        },
        'th.ant-table-cell': {
          paddingLeft: '16px',
        },
      }}
    >
      <Table
        columns={ignoresTableColumns}
        dataSource={ignores}
        pagination={false}
        scroll={
          ignores.length > MINIMUM_ROWS_FOR_SCROLL ? { y: SCROLL_HEIGHT } : null
        }
      />
    </Box>
  )
}

export default IgnoresTable
