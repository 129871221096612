import React from 'react'
import { Avatar } from 'antd'
import { Flex, Text } from 'theme-ui'

export const UserNameView = ({ member }) => {
  const name = `${member.firstName} ${member.lastName}`
  return (
    <Flex sx={{ alignItems: 'center', minWidth: '150px' }}>
      <Avatar style={{ marginRight: '8px' }} src={member.avatarUrl}>
        {member.email[0].toUpperCase()}
      </Avatar>
      <Flex sx={{ flexDirection: 'column' }}>
        <Text variant="small">{name}</Text>
        <Text variant="small">{member.email}</Text>
      </Flex>
    </Flex>
  )
}
