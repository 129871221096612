import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { INTEGRATION_STATUS_INTERVAL } from './consts'
import { select } from '../../../redux/store'

export const useStatsFetch = () => {
  const dispatch = useDispatch()
  const { data: assetKindsStats, status } = useSelector(
    select.Assets.assetKindsStats
  )

  useEffect(() => {
    const fetchInterval = setInterval(
      dispatch.Assets.fetchAssetKindsStats,
      INTEGRATION_STATUS_INTERVAL
    )
    dispatch.Assets.fetchAssetKindsStats()
    return () => {
      clearInterval(fetchInterval)
    }
    // eslint-disable-next-line
  }, [])

  return [assetKindsStats, status.loaded]
}
