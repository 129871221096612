import L from 'lodash'
import { RoleType } from '@spectral/types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NotificationSettings from '../../../../blocks/settings/teams/notification/view'
import Restricted from '../../../role-based-render/restricted'
import { select } from '../../../../redux/store'

const NotificationSettingsConnected = () => {
  const dispatch = useDispatch()
  const { emailSettings, slackSettings, msTeamsSettings } = useSelector(
    select.Teams.teamSettings
  )

  useEffect(() => {
    if (!emailSettings || L.isEmpty(emailSettings))
      dispatch.Teams.fetchSettings()
  }, [dispatch.Teams, emailSettings])
  return (
    <NotificationSettings
      setTeamSettings={dispatch.Teams.setTeamSettings}
      enabled={emailSettings.enabled}
      enableWeeklyDigestReport={emailSettings.enableWeeklyDigestReport}
      enableDailyDigestReport={emailSettings.enableDailyDigestReport}
      slackIgnoreActionEnabled={{
        status: slackSettings.slackIgnoreActionEnabled,
        isShown: slackSettings.enabled,
      }}
      msTeamsIgnoreActionEnabled={{
        status: msTeamsSettings.msTeamsIgnoreActionEnabled,
        isShown: msTeamsSettings.enabled,
      }}
    />
  )
}

export default NotificationSettingsConnected
