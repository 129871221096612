import { SearchOutlined } from '@ant-design/icons'
import React from 'react'
import { Box } from 'theme-ui'
import SmallInput from '../../../components/inputs/small-input'
import FilterCollapse from '../../../components/filters/filter-collapse'
import { FilterHeader } from '../../../components/filters/filter-header'

const PathFilter = ({ onValueChanged, isRefreshing, path }) => {
  return (
    <FilterCollapse
      isDividerShown
      header={<FilterHeader title="Path" />}
      filterKey="path"
      collapsible={false}
    >
      <Box sx={{ mt: 1 }}>
        <SmallInput
          disabled={isRefreshing}
          value={path}
          onChange={(event) => {
            onValueChanged({ path: event.target.value })
          }}
          allowClear
          style={{
            width: '100%',
            marginBottom: 2,
            height: '24px',
          }}
          placeholder="Search path"
          prefix={<SearchOutlined />}
        />
      </Box>
    </FilterCollapse>
  )
}

export default PathFilter
