import React from 'react'
import { useThemeUI } from 'theme-ui'

import { AssetDisplayStatus } from '@spectral/types'
import CubeStyled from './cube-styled'

type Props = {
  view: AssetDisplayStatus
  size?: number
}
const Cube = ({ view, size = 42 }: Props) => {
  const { theme } = useThemeUI()
  switch (view) {
    case AssetDisplayStatus.Critical:
      return (
        <CubeStyled
          // @ts-ignore
          cubeColor={theme.colors.severities.criticalHSL}
          size={size}
        />
      )
    case AssetDisplayStatus.High:
      return (
        <CubeStyled
          // @ts-ignore
          cubeColor={theme.colors.severities.highHSL}
          size={size}
        />
      )
    case AssetDisplayStatus.Medium:
      return (
        <CubeStyled
          // @ts-ignore
          cubeColor={theme.colors.severities.mediumHSL}
          size={size}
        />
      )
    case AssetDisplayStatus.Low:
      return (
        <CubeStyled
          // @ts-ignore
          cubeColor={theme.colors.severities.lowHSL}
          size={size}
        />
      )
    case AssetDisplayStatus.Informational:
      return (
        <CubeStyled
          // @ts-ignore
          cubeColor={theme.colors.severities.informationalHSL}
          size={size}
        />
      )
    case AssetDisplayStatus.NoIssues:
      return (
        // @ts-ignore
        <CubeStyled cubeColor={theme.colors.severities.noneHSL} size={size} />
      )
    case AssetDisplayStatus.NotActive:
      return (
        <CubeStyled
          // @ts-ignore
          cubeColor={theme.colors.severities.oldHSL}
          lessContrast
          size={size}
        />
      )
    default:
      return (
        // @ts-ignore
        <CubeStyled cubeColor={theme.colors.severities.oldHSL} size={size} />
      )
  }
}

export default Cube
