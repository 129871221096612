import React from 'react'
import { Box } from 'theme-ui'
import theme from '../../common/theme'

const AdditionalActionButton = ({ onClick, text, style = null }) => (
  <Box
    sx={{
      color: theme.stylebook.colors.primary,
      fontSize: style?.fontSize || '12px',
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    {text}
  </Box>
)

export default AdditionalActionButton
