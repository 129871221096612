import React from 'react'
import * as Yup from 'yup'
import { Box, Text } from 'theme-ui'
import { Link } from 'react-router-dom'
import { SLACK_WEBHOOK_URL_REGEX } from '@spectral/types'
import {
  PanelForm,
  InputPasswordFieldSection,
  SubmitSection,
} from '../../../components/panels/content'
import slackLogo from '../../../assets/svg/slack.svg'
import { IntegrationSection } from '../../../components/integrations/integration-sections'

export const SlackIntegrations = ({
  setSlackEnableDisable,
  setSlackUrl,
  enabled,
  slackWebhookUrl,
}) => {
  return (
    <IntegrationSection
      enabled={enabled}
      onEnableToggle={setSlackEnableDisable}
      integrationName="Slack"
      headerLogo={slackLogo}
      headerTitle="Slack Global Integration"
      sectionTitle=""
      sectionDescription="Use Slack for real time notifications"
      sectionRenderedContent={() => (
        <Box sx={{ mt: '20px' }}>
          <Text>
            This settings will direct all system notification to the configured
            channel
          </Text>
          <Text>
            For settings a specific team channel, go to{' '}
            <Link to="/settings/org-teams">Teams</Link>
          </Text>
          <Text sx={{ fontWeight: 600, marginTop: '20px' }}>
            Creating a Slack webhook
          </Text>
          <Text>1. Create a Slack app</Text>
          <Text>2. Enable incoming webhooks</Text>
          <Text>3. Copy your webhook URL here</Text>
          <Text sx={{ mt: '10px' }}>
            For more information visit{' '}
            <a
              href="https://api.slack.com/messaging/webhooks"
              rel="noopener noreferrer"
              target="_blank"
            >
              the Slack docs
            </a>
          </Text>
        </Box>
      )}
    >
      <PanelForm
        onSubmit={setSlackUrl}
        validationSchema={Yup.object({
          slackWebhookUrl: Yup.string().matches(
            RegExp(SLACK_WEBHOOK_URL_REGEX),
            'Must be a valid Slack Webhook'
          ),
        })}
        initialValues={{
          enabled,
          slackWebhookUrl,
        }}
      >
        <InputPasswordFieldSection
          name="slackWebhookUrl"
          label="Slack Webhook URL"
          description="Paste your Slack Webhook URL here"
          placeholder="https://hooks.slack.com/services/T000/B000/XXX"
          merge
        />
        <SubmitSection text="Update" />
      </PanelForm>
    </IntegrationSection>
  )
}
