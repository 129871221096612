/** @jsx jsx */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line
import React from 'react'
import { jsx } from 'theme-ui'
import { Checkbox as ADCheckbox } from 'antd'

export const Checkbox = (props) => (
  <ADCheckbox
    sx={{ '.ant-checkbox-inner::after': { top: '6px' } }}
    {...props}
  />
)
