/** @jsx jsx */
import { Modal } from 'antd'
import { jsx, Box, Text } from 'theme-ui'

const CriticalIssuesDismissModal = ({ onOk, onCancel }) => (
  <Modal
    title="Dismiss"
    visible
    onOk={onOk}
    onCancel={onCancel}
    okText="Got it"
  >
    <Box>
      <Text>
        This notification appears every time spectral finds new critical issues
      </Text>
    </Box>
  </Modal>
)

export default CriticalIssuesDismissModal
