/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useSelector } from 'react-redux'
import { select } from '../../../redux/store'

import { OrgTeamMondayIntegration } from '../../../blocks/settings/org-teams/integrations/monday'

export const OrgTeamMondayIntegrationConnected = ({ boardId }) => {
  const workspaces = useSelector(select.Monday.workspaces)
  const { domain } = useSelector(select.Teams.teamSettings).mondaySettings
  return (
    <OrgTeamMondayIntegration
      domain={domain}
      workspaces={workspaces}
      currentWorkspaceId={boardId}
    />
  )
}
