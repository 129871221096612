import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from '../redux/store'
import { IssueIgnoreTypes } from '@spectral/types'

type PostActions = {
  ignore?: () => any
  resolve?: (issues) => any
  unResolve?: (issues) => any
  snooze?: () => any
  assigneeChange?: () => any
  assigneeRemove?: () => any
  severityChange?: () => any
}
export const useIssuesTableActions = (
  page = 'issues',
  postActions: PostActions = null
) => {
  const dispatch: Dispatch = useDispatch()
  const handleIgnore = useCallback(
    async ({ issues, ignoreType, ignoreAction, comment, buttonType }) => {
      // Note: this await is necessary to make sure we update ignores before fetching data again
      await dispatch.Issues.ignoreIssues({
        issues,
        action: ignoreAction,
        ignoreType,
        comment,
        page,
        buttonType,
      })
      // eslint-disable-next-line no-unused-expressions
      postActions?.ignore && postActions.ignore()
    },
    [dispatch.Issues, page, postActions]
  )

  const handleSnooze = useCallback(
    async (props) => {
      const { issues, snoozeAction, daysToSnooze, comment, buttonType } = props
      // Note: this await is necessary to make sure we update ignores before fetching data again
      await dispatch.Issues.ignoreIssues({
        issues,
        action: snoozeAction,
        ignoreType: IssueIgnoreTypes.snooze,
        daysToSnooze,
        comment,
        page,
        buttonType,
      })
      // eslint-disable-next-line no-unused-expressions
      postActions?.snooze && postActions.snooze()
    },
    [dispatch.Issues, page, postActions]
  )
  const handleAssigneeChange = useCallback(
    async ({ assigneePid, issueIds }) => {
      await dispatch.Issues.assignIssues({ assigneePid, issueIds })
      // eslint-disable-next-line no-unused-expressions
      postActions?.assigneeChange && postActions.assigneeChange()
    },
    [dispatch.Issues, postActions]
  )

  const handleAssigneeRemove = useCallback(
    async ({ issueIds }) => {
      await dispatch.Issues.unassignIssues({ issueIds })
      // eslint-disable-next-line no-unused-expressions
      postActions?.assigneeRemove && postActions.assigneeRemove()
    },
    [dispatch.Issues, postActions]
  )

  const handleSeverityChange = useCallback(
    (payload) => {
      dispatch.Issues.changeDetectorSeverity(payload)
      // eslint-disable-next-line no-unused-expressions
      postActions?.severityChange && postActions.severityChange()
    },
    [dispatch.Issues, postActions]
  )

  const handleAssigneeSearch = useCallback(
    (payload) => {
      dispatch.AssetPage.fetchAssignees(payload)
    },
    [dispatch.AssetPage]
  )

  const handleAssetIssuesSeverityChange = useCallback(
    (payload) => {
      dispatch.AssetPage.changeAssetIssuesDetectorSeverity(payload)
      // eslint-disable-next-line no-unused-expressions
      postActions?.severityChange && postActions.severityChange()
    },
    [dispatch.AssetPage, postActions]
  )

  const handleResolve = useCallback(
    async (issues, buttonType) => {
      await dispatch.Issues.resolveIssues({
        issues,
        page,
        buttonType,
      })
      // eslint-disable-next-line no-unused-expressions
      postActions?.resolve && postActions.resolve(issues)
    },
    [dispatch.Issues, page, postActions]
  )

  const handleUnresolve = useCallback(
    async (issues, buttonType) => {
      await dispatch.Issues.unresolveIssues({
        issues,
        page,
        buttonType,
      })
      // eslint-disable-next-line no-unused-expressions
      postActions?.unResolve && postActions.unResolve(issues)
    },
    [dispatch.Issues, page, postActions]
  )

  return [
    handleIgnore,
    handleSnooze,
    handleAssigneeChange,
    handleAssigneeRemove,
    handleAssigneeSearch,
    handleSeverityChange,
    handleResolve,
    handleUnresolve,
    handleAssetIssuesSeverityChange,
  ]
}
