import { isEmpty, slice } from 'lodash'
import { assigneeOptions } from '../filters/assignee-filter'
import { SideFilterType } from '../../../components/filters/side-filters'
import DynamicDataFilter from '../../../components/filters/dynamic-data-filter'
import { toFilterView } from './transform-filters'

export const filtersSetup = (filtersData, filtersToAdd = []) => {
  const filters = [
    {
      key: 'assignees',
      title: 'Assignee',
      component: DynamicDataFilter({
        searchable: true,
        showAllOptions: false,
        searchPlaceholder: 'Search assignee',
      }),
      isActive: filtersData.assignableMembers.isActive,
      type: SideFilterType.Checkbox,
      data: assigneeOptions(filtersData.assignableMembers),
      collapsible: true,
    },
    {
      key: 'detectorId',
      title: 'Detector',
      component: DynamicDataFilter({
        searchable: true,
        showAllOptions: false,
        searchPlaceholder: 'Search detector',
      }),
      isActive: !isEmpty(filtersData.detectors),
      type: SideFilterType.Checkbox,
      data: toFilterView({
        filterData: filtersData.detectors,
        shouldCapitalize: false,
      }),
      collapsible: true,
    },
    {
      key: 'severity',
      title: 'Severity',
      component: DynamicDataFilter({
        searchable: false,
        shouldSort: false,
        showAllOptions: true,
      }),
      isActive: !isEmpty(filtersData.severity),
      type: SideFilterType.Checkbox,
      data: toFilterView({
        filterData: filtersData.severity,
        shouldCapitalize: true,
      }),
      shouldShowAllValues: true,
      collapsible: true,
    },
    {
      key: 'status',
      title: 'Status',
      component: DynamicDataFilter({
        searchable: false,
        shouldSort: false,
        showAllOptions: true,
      }),
      isActive: !isEmpty(filtersData.status),
      type: SideFilterType.Checkbox,
      data: toFilterView({
        filterData: filtersData.status,
        shouldCapitalize: true,
      }),
      shouldShowAllValues: true,
      collapsible: true,
    },
  ]
  filtersToAdd.forEach((filterToAdd) => {
    filters.splice(filterToAdd.index, 0, filterToAdd.filter)
  })
  return filters.filter(
    (filter) => filter.type === SideFilterType.Date || !isEmpty(filter.data)
  )
}

export const buildAssignableMembersData = (assigneesFiltersData) => {
  return {
    unassignedIssuesCount: assigneesFiltersData?.find(
      (assignee) => assignee.id === null
    )?.total,
    options: assigneesFiltersData || [],
  }
}

export const itemsToDisplayInPage = (dataSource, currentPage, pageSize) => {
  return slice(dataSource, (currentPage - 1) * pageSize, currentPage * pageSize)
}

export const sortOptions = [
  { key: 'content', title: 'Content' },
  { key: 'detectorId', title: 'Detector' },
  { key: 'createdAt', title: 'Exposure' },
  { key: 'path', title: 'Path' },
  { key: 'severity', title: 'Severity' },
  { key: 'status', title: 'Status' },
]
