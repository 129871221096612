export enum OperatingSystem {
  MACLINUX = 'MAC/Linux',
  WINDOWS = 'Windows',
}

export enum SourceIntegrationComponent {
  SnippetWindow = 'SnippetWindow',
}

export enum DocSectionType {
  Text,
  SubTitle,
  List,
  Snippet,
}

export enum SourceIntegrationKey {
  AuditGithub = 'audit-github',
  AuditGitlab = 'audit-gitlab',
  AuditLocal = 'audit-local',
  AuditDocker = 'audit-docker',
  TravisCi = 'travis-ci',
  CircleCi = 'circle-ci',
  Jenkins = 'jenkins',
  Codebuild = 'codebuild',
  GithubActions = 'github-actions',
  GitlabCi = 'gitlab-ci',
  BitbucketPipeline = 'bitbucket-pipeline',
  Azuredevops = 'azuredevops',
  Gcpbuild = 'gcpbuild',
  JfrogPipline = 'jfrog-pipline',
  OtherCi = 'other-ci',
  Jira = 'jira',
  Confluence = 'confluence',
  GithubNative = 'github-native',
  GitlabBot = 'gitlab-bot',
  GithubDiscover = 'github',
  TerraformCloud = 'tfc',
  GitlabDiscover = 'gitlab',
}
