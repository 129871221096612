/** @jsx jsx */
import { Flex, jsx, Text } from 'theme-ui'
import { AiOutlineCheck } from 'react-icons/ai'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

export const ListGroup = ({ children }) => (
  <Flex
    as="ul"
    sx={{
      flexDirection: 'column',
      gap: '16px',
      paddingInlineStart: '12px',
    }}
  >
    {children}
  </Flex>
)

export const ListItemWithIcon = ({
  icon = (
    <AiOutlineCheck
      sx={{ color: '#1aa260', flexBase: 'auto', alignSelf: 'start' }}
      size={22}
    />
  ),
  text,
  tooltip = null,
}) => (
  <Flex as="li">
    {icon}
    <Text sx={{ ml: 2, flex: 1 }}>
      {text}{' '}
      {tooltip && (
        <Tooltip title={tooltip}>
          &nbsp;
          <QuestionCircleOutlined />
        </Tooltip>
      )}
    </Text>
  </Flex>
)
