/** @jsx jsx */
// eslint-disable-next-line
import { jsx, Flex, Box, Text } from 'theme-ui'
import styled from 'styled-components'
import { Menu, Button, Avatar, Dropdown } from 'antd'
import { NavLink } from 'react-router-dom'
import { FaRegBell } from 'react-icons/fa'
import { LinkButton } from '../buttons/link-button'
import { DOCS_URL } from '../../utils/constants'

const InactiveItem = styled(Menu.Item)`
  &&:hover {
    background: none;
    cursor: inherit;
  }
`
const Item = styled(Menu.Item)`
  && {
    padding: 8px 20px;
  }
`
const UserMenu = ({
  user: { name, avatarUrl, notifications, username },
  team,
  links,
  logout,
  showUpgrade = false,
  upgradePath = '',
}) => (
  <Menu>
    <InactiveItem>
      <Flex sx={{ flexDirection: 'row', mx: '10px', my: '20px' }}>
        <Avatar size={60} src={avatarUrl}>
          {name[0]}
        </Avatar>
        <Flex sx={{ ml: '10px', p: '7px', flexDirection: 'column' }}>
          <Box sx={{ fontWeight: 800 }}>{name}</Box>
          <Text color="gray.500">{username}</Text>
          <Box sx={{ mt: '10px' }}>
            <Box>
              Notifications are{' '}
              <span sx={{ fontWeight: 700 }}>
                {notifications ? 'on' : 'off'}
              </span>
            </Box>
          </Box>
        </Flex>
      </Flex>

      <Text variant="smalltitle">{team && team.name}</Text>
    </InactiveItem>
    {showUpgrade && (
      <Item key={`${upgradePath}-Free plan`}>
        <NavLink to={upgradePath}>
          <Flex>
            Free plan
            <LinkButton
              sx={{ ml: 3 }}
              size="small"
              type="primary"
              text="Upgrade"
              to={upgradePath}
            />
          </Flex>
        </NavLink>
      </Item>
    )}
    {links.map(({ link, text }) => (
      <Item key={`${link}-${text}`}>
        <NavLink to={link}>{text}</NavLink>
      </Item>
    ))}
    <Item key="spectral_docs">
      <a href={DOCS_URL} target="_blank" rel="noopener noreferrer">
        {' '}
        Docs{' '}
      </a>
    </Item>
    <Item key="logout" onClick={logout}>
      Log out
    </Item>
    <InactiveItem>
      <Box sx={{ height: '20px' }} />
    </InactiveItem>
  </Menu>
)

export const UserBar = ({
  className = undefined,
  user,
  team,
  links,
  logout,
  showUpgrade,
  upgradePath,
  isOpen = undefined,
}) => (
  <Flex
    className={className}
    sx={{
      justifyContent: 'center',
    }}
  >
    <Button size="large" className="beamerTrigger">
      <FaRegBell size={24} />
    </Button>
    <Box sx={{ width: '10px' }} />
    <Dropdown
      trigger={['click']}
      overlay={UserMenu({
        user,
        team,
        links,
        logout,
        showUpgrade,
        upgradePath,
      })}
      placement="bottomRight"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(isOpen ? { visible: isOpen } : {})}
    >
      <Button size="large">
        <Avatar sx={{ '&&': { mt: '-3px', mr: '15px' } }} src={user.avatarUrl}>
          {user.name[0]}
        </Avatar>
        {user.name}
      </Button>
    </Dropdown>
  </Flex>
)
