/** @jsx jsx */
import { Flex, Box, jsx, Heading, Text } from 'theme-ui'
import { Slider, Badge } from 'antd'
import { useEffect } from 'react'
import numeral from 'numeral'
import { ListItemWithIcon, ListGroup } from './list-items'
import PlanCard from './plan-card'
import { PRICE_FORMAT } from '../../../common/display-formats'
import { PrimaryButtonLarge } from '../../../components/buttons'
import useQuerystring from '../../../hooks/useQuerystring'
import theme from '../../../common/theme'

const PRICE_PER_CONTRIBUTOR_PER_MONTH = 19
const MIN_CONTRIBUTOR = 10
const MAX_CONTRIBUTOR = 50
const DEFAULT_CONTRIBUTORS_QUANTITY_TEAM_PLAN = 30

const isValidContributorsQuantityRange = (contributorsQuantity) => {
  return (
    contributorsQuantity &&
    contributorsQuantity >= MIN_CONTRIBUTOR &&
    contributorsQuantity <= MAX_CONTRIBUTOR
  )
}

const TeamPlan = ({
  isCurrentPlan,
  onCheckoutClick,
  onManageSubscriptionClick,
  subscriptionMaxContributors,
}) => {
  const [{ contributors }, , updateQuery] = useQuerystring()
  useEffect(() => {
    if (isValidContributorsQuantityRange(contributors)) {
      updateQuery({ contributors })
    } else {
      updateQuery({
        contributors:
          subscriptionMaxContributors ||
          DEFAULT_CONTRIBUTORS_QUANTITY_TEAM_PLAN,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Badge.Ribbon
      text={isCurrentPlan ? 'Your Plan' : 'Most Popular'}
      color={isCurrentPlan && theme.stylebook.colors.cyan}
    >
      <PlanCard>
        <Box>
          <Heading as="h3" variant="cardTitle">
            Team
          </Heading>
          <Text variant="summary">For most of the teams</Text>
        </Box>
        <Box>
          <Text variant="title">
            {numeral(contributors * PRICE_PER_CONTRIBUTOR_PER_MONTH).format(
              PRICE_FORMAT
            )}
          </Text>
          <Text>
            {numeral(PRICE_PER_CONTRIBUTOR_PER_MONTH).format(PRICE_FORMAT)} per
            developer/month
          </Text>
          <Text variant="summary">
            {numeral(
              contributors * PRICE_PER_CONTRIBUTOR_PER_MONTH * 12
            ).format(PRICE_FORMAT)}{' '}
            billed annually
          </Text>
        </Box>
        <Box>
          {isCurrentPlan ? (
            <PrimaryButtonLarge
              text="Manage Subscription"
              onClick={onManageSubscriptionClick}
              block
            />
          ) : (
            <PrimaryButtonLarge
              text="Checkout"
              onClick={() => {
                onCheckoutClick(Number(contributors))
              }}
              block
            />
          )}
          <Flex sx={{ flexDirection: 'column', mt: 3 }}>
            <Slider
              min={10}
              max={50}
              disabled={isCurrentPlan}
              onChange={(e) => {
                updateQuery({ contributors: e })
              }}
              value={contributors}
              tooltipVisible={false}
              autoFocus
              style={{ marginTop: '2px', marginBottom: '3px' }}
            />
            <Text sx={{ m: 'auto' }}>{contributors} Contributors</Text>
          </Flex>
        </Box>
        <Box>
          <Text sx={{ mb: 3 }}>All the benefits of Free, plus:</Text>
        </Box>
        <Box>
          <ListGroup>
            <ListItemWithIcon text="Unlimited scans" />
            <ListItemWithIcon text="Up to 100 repositories" />
            <ListItemWithIcon text="Up to 50 contributors" />
            <ListItemWithIcon text="Chat and email support" />
            <ListItemWithIcon text="Six months of data retention" />
            <ListItemWithIcon text="Mail, Slack, MS Teams, PagerDuty and webhook notifications" />
            <ListItemWithIcon text="Asset Mapping" />
            <ListItemWithIcon text="Reports & Insights" />
          </ListGroup>
        </Box>
      </PlanCard>
    </Badge.Ribbon>
  )
}

export default TeamPlan
