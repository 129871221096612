import { PlanType, RoleType } from '@spectral/types'

export const paths = {
  open: {
    signin: '/signin',
    errorPage: '/error',
    signinSSO: (teamPid) => `/signin/${teamPid}`,
    signup: '/signup',
    sso: '/sso',
    recover: '/recover',
    recoverWithToken: (pid, resetToken) => `/recover/${pid}/${resetToken}`,
    cloudGuardConnect: '/cloudguard/connect',
    emailVerification: (token) => `/email-verification/${token}`,
  },

  closed: {
    hive: '/',
    assets: '/assets',
    asset: (assetId) => `/assets/${assetId}`,
    reports: '/reports',
    settings: '/settings',
    settingsTeam: '/settings/team',
    settingsProfile: '/settings/profile',
    settingsSCM: '/settings/scm',
    settingsIntegrations: '/settings/integrations',
    settingsAssets: '/settings/assets',
    settingsDeleteAsset: '/settings/delete-asset',
    settingsPlans: '/settings/plans',
    orgTeam: '/settings/org-teams',
    remoteConfig: '/settings/scan-configuration',
    customRules: '/settings/custom-rules',
    sso: '/settings/sso',
    sources: '/sources',
    source: (category, integrationId) =>
      `/sources/${category}/${integrationId}`,
  },
}

export const routes = {
  redirect: [],
  open: [
    {
      path: paths.open.signup,
      exact: true,
      component: require('./containers/signup').default,
    },
    {
      path: paths.open.signin,
      exact: true,
      component: require('./containers/signin').default,
    },
    {
      path: paths.open.errorPage,
      exact: true,
      component: require('./containers/error').default,
    },
    {
      path: paths.open.signinSSO(':teamPid'),
      exact: true,
      component: require('./containers/signin').default,
    },
    {
      path: paths.open.sso,
      exact: true,
      component: require('./containers/sso').default,
    },
    {
      path: paths.open.recover,
      exact: true,
      component: require('./containers/recover').default,
    },
    {
      path: paths.open.cloudGuardConnect,
      exact: true,
      component: require('./containers/cloud-guard-connect').default,
    },
    {
      path: paths.open.emailVerification(':token'),
      exact: true,
      component: require('./containers/email-verification').default,
    },
    {
      path: paths.open.recoverWithToken(':pid', ':resetToken'),
      exact: true,
      component: require('./containers/recover/finish').default,
    },
  ],
  closed: [
    {
      path: paths.closed.hive,
      exact: true,
      component: require('./containers/Hive').default,
    },
    {
      path: paths.closed.sources,
      exact: true,
      component: require('./containers/Sources').default,
    },
    {
      path: paths.closed.source(':category', ':integrationId'),
      exact: true,
      component: require('./containers/source-integration').default,
    },
    {
      path: paths.closed.reports,
      exact: true,
      component: require('./containers/reports').default,
    },
    {
      path: paths.closed.assets,
      exact: true,
      component: require('./containers/assets').default,
    },
    {
      path: paths.closed.asset(':assetId'),
      exact: true,
      component: require('./containers/asset').default,
    },
    {
      path: paths.closed.settings,
      component: require('./containers/Settings').default,
      nested: [
        {
          path: paths.closed.settingsProfile,
          exact: true,
          label: 'Profile',
          component: require('./containers/Settings/profile').default,
        },
        {
          path: paths.closed.settingsTeam,
          exact: true,
          role: RoleType.Admin,
          label: 'Organization',
          component: require('./containers/Settings/team').default,
        },
        {
          path: paths.closed.settingsSCM,
          exact: true,
          role: RoleType.Admin,
          label: 'SCM',
          component: require('./containers/Settings/scm').default,
        },
        {
          path: paths.closed.settingsIntegrations,
          exact: true,
          role: RoleType.Admin,
          label: 'Integrations',
          component: require('./containers/Settings/integrations').default,
        },
        {
          path: paths.closed.settingsAssets,
          exact: true,
          role: RoleType.Admin,
          label: 'Asset Mapping',
          component: require('./containers/Settings/assets').default,
        },
        {
          path: paths.closed.orgTeam,
          exact: true,
          role: RoleType.Admin,
          label: 'Teams',
          component: require('./containers/Settings/org-teams').default,
        },
        {
          path: paths.closed.settingsDeleteAsset,
          exact: true,
          role: RoleType.Admin,
          label: 'Delete Asset',
          component: require('./containers/Settings/delete-asset').default,
        },
        {
          path: paths.closed.settingsPlans,
          exact: true,
          role: RoleType.Admin,
          planRestricted: [PlanType.Enterprise, PlanType.Poc],
          label: 'Plans & Pricing',
          component: require('./containers/Settings/plans').default,
        },
        {
          path: paths.closed.remoteConfig,
          exact: true,
          label: 'Scan Configuration',
          component: require('./containers/Settings/remote-config/remote-config')
            .default,
        },

        {
          path: paths.closed.customRules,
          exact: true,
          label: 'Custom Rules',
          role: RoleType.Admin,
          component: require('./containers/Settings/custom-rules/custom-rules-connected')
            .default,
        },
        {
          path: paths.closed.sso,
          exact: true,
          role: RoleType.Owner,
          label: 'SSO Setup',
          component: require('./containers/Settings/sso-setup/sso-setup')
            .default,
        },
      ],
    },
  ],
}
