import React, { useCallback, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Page } from '../../components/page'
import useQuerystring from '../../hooks/useQuerystring'
import { sdkClient } from '../../redux/store'
import { paths } from '../../routes'

const CloudGuardConnect = () => {
  const [{ nonce }] = useQuerystring()
  const currentUser = useSelector((state) => state.Auth.user)
  const [isError, setIsError] = useState(false)
  const dispatch = useDispatch()

  const failHandler = useCallback(() => {
    setIsError(true)
    dispatch.Auth.logout()
  }, [dispatch, setIsError])

  useEffect(() => {
    ;(async () => {
      if (nonce) {
        try {
          const user = (await sdkClient.auth().cloudGuardConnect({
            data: { nonce },
          })) as any
          if (!user.pid) {
            failHandler()
          } else {
            dispatch.Auth.postUserFetch(user)
          }
        } catch (error) {
          failHandler()
        }
      } else {
        failHandler()
      }
    })()
  }, [nonce, dispatch, failHandler])

  if (currentUser) {
    return <Redirect to={paths.closed.hive} />
  }

  return (
    <Page
      name="CLOUD-GUARD-CONNECT"
      title="Cloud Guard Connect"
      isError={isError}
      isLoading={!currentUser && !isError}
    />
  )
}

export default CloudGuardConnect
