import track from '../track'

class Alerts {
  investigateCriticalIssues() {
    track.event('INVESTIGATE_CRITICAL_ISSUES_BANNER')
  }

  dismissCriticalIssuesBanner() {
    track.event('DISMISS_CRITICAL_ISSUES_BANNER')
  }
}

export default new Alerts()
