import { Severity } from './severity'

export type IssuesEffect = {
  new: {
    [Severity.Critical]: number
    [Severity.High]: number
    [Severity.Medium]: number
    [Severity.Low]: number
    [Severity.Informational]: number
  }
  resolved: number
  total: number
}

export type ScanInsights = {
  pid: string
  teamId: number
  assetId: string
  orgTeamName: string
  issuesEffect: IssuesEffect
  scanContext: any
}

export enum ScanInsightsSortBy {
  CREATED_AT = 'createdAt',
}
