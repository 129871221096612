import { auth } from './auth'
import { signup } from './signup/signup'
import { teams } from './teams/teams'
import { reports } from './reports/reports'
import { recover } from './recover'
import { recoverFinish } from './recover-finish'
import { sidebar } from './sidebar/sidebar'
import { emailVerification } from './email-verification'
import { assets } from './assets'
import { issuesModel } from './issues'
import { usersModel } from './users'
import { globalPreferences } from './global-preferences'
import { plan } from './plan'
import { jira } from './jira'
import { monday } from './monday'
import { settings } from './settings'
import { assetsPage } from './pages/assets-page'
import { assetPage } from './pages/asset-page'
import { scmConfigurations } from './on-prem-scm'
import { customRules } from './custom-rules'
import { invitesModel } from './invites'
import { scanInsights } from './scan-insights/scan-insights'

export interface RootModel {
  Auth: typeof auth
  Signup: typeof signup
  Recover: typeof recover
  RecoverFinish: typeof recoverFinish
  Teams: typeof teams
  Reports: typeof reports
  Sidebar: typeof sidebar
  EmailVerification: typeof emailVerification
  Plan: typeof plan
  Assets: typeof assets
  Jira: typeof jira
  Monday: typeof monday
  Settings: typeof settings
  AssetsPage: typeof assetsPage
  AssetPage: typeof assetPage
  GlobalPreferences: typeof globalPreferences
  SCMConfigurations: typeof scmConfigurations
  CustomRules: typeof customRules
  Issues: typeof issuesModel
  Users: typeof usersModel
  Invites: typeof invitesModel
  ScanInsights: typeof scanInsights
}

export const models: RootModel = {
  Auth: auth,
  Signup: signup,
  Recover: recover,
  RecoverFinish: recoverFinish,
  Teams: teams,
  Reports: reports,
  Sidebar: sidebar,
  EmailVerification: emailVerification,
  Plan: plan,
  Assets: assets,
  Jira: jira,
  Monday: monday,
  Settings: settings,
  AssetsPage: assetsPage,
  AssetPage: assetPage,
  GlobalPreferences: globalPreferences,
  SCMConfigurations: scmConfigurations,
  CustomRules: customRules,
  Issues: issuesModel,
  Users: usersModel,
  Invites: invitesModel,
  ScanInsights: scanInsights,
}
