import { SourceIntegrationComponent } from '../enums'

const title = 'GitLab CI/CD Hardening'
const customInto =
  'Gitlab token needs the following minimal permissions: read_api'
export const discoverGitlab = {
  mode: 'discover',
  isScansSettingHidden: true,
  component: SourceIntegrationComponent.SnippetWindow,
  'MAC/Linux': {
    title,
    content: '',
    tileKey: 'gitlab',
    snippets: [
      {
        title: 'Discover GitLab repo',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral discover gitlab -k repo [YOUR_REPO]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_REPO',
            example: 'https://gitlab.com/acme-corp-demo/kubernetes',
            explain: 'full path to the repository.',
          },
        ],
      },
      {
        title: 'Discover GitLab user',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral discover gitlab -k user [YOUR_USER]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_USER',
            example: 'maggie',
          },
        ],
      },
      {
        title:
          'Discover Gitlab Group (scan your entire org by adding “all-groups” parameter)',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral discover gitlab -k group [YOUR_GROUP]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_GROUP',
            example: 'ACME-Corp-Demo',
          },
        ],
      },
    ],
    customInto,
  },
  Windows: {
    title,
    content: '',
    tileKey: 'gitlab',
    snippets: [
      {
        title: 'Discover GitLab repo',
        code:
          '$ spectral.exe scan --dsn {dsn} discover gitlab -k repo [YOUR_REPO]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_REPO',
            example: 'https://gitlab.com/acme-corp-demo/kubernetes',
            explain: 'full path to the repository.',
          },
        ],
      },
      {
        title: 'Discover GitLab user',
        code:
          '$ spectral.exe scan --dsn {dsn} discover gitlab -k user [YOUR_USER]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_USER',
            example: 'maggie',
          },
        ],
      },
      {
        title:
          'Discover Gitlab Group (scan your entire org by adding “all-groups” parameter)',
        code:
          '$ spectral.exe scan --dsn {dsn} discover gitlab -k group [YOUR_GROUP]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_GROUP',
            example: 'ACME-Corp-Demo',
          },
        ],
      },
    ],
    customInto,
  },
}
