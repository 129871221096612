import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import { MondayIntegration } from '../../../../blocks/settings/integrations/monday/monday'

export const MondayIntegrationConnected = () => {
  const { mondaySettings } = useSelector(select.Teams.teamSettings)
  const { MondaySettingsError } = useSelector(select.Teams.settingsPageStatus)
  const { enabled } = mondaySettings
  const dispatch = useDispatch()
  return (
    <MondayIntegration
      enabled={enabled}
      setMondayEnableDisable={dispatch.Teams.setMondayIntegrationStatus}
      setMondaySettings={dispatch.Teams.setMondaySettings}
      domain={mondaySettings.domain}
      token={mondaySettings.token}
      MondaySettingsError={MondaySettingsError}
    />
  )
}
