const LOCALSTORAGE_TOKEN_KEY = 'spectralJWT'
const HIVE_FILTERS_KEY = 'spectralHiveFilters'
const SIDEBAR_COLLAPSE_STATE = 'spectralSidebarCollapse'
const GITHUB_APP_POST_INSTALL = 'githubAppPostInstall'
const FLOATING_USER_SEEN_KEY = 'spectralFloatingUserSeen'
const FLOATING_DOMAINS_SEEN_KEY = 'spectralFloatingUnrecognizedDomainsSeen'
const POC_MODE = 'pocMode'
const LICENSE_ALERT_CLOSED = 'alertClosed'
const TEAM = 'team'
export const FEATURE_FLAGS = 'featureFlags'

export class LocalStorePersistence {
  private key = undefined

  constructor(key) {
    this.key = key
  }

  persist(val: any) {
    localStorage.setItem(this.key, JSON.stringify(val))
  }

  get(): any {
    return JSON.parse(localStorage.getItem(this.key))
  }

  remove() {
    localStorage.removeItem(this.key)
  }
}

const authPersistence = new LocalStorePersistence(LOCALSTORAGE_TOKEN_KEY)
const teamPersistence = new LocalStorePersistence(TEAM)
const hiveFiltersPersistence = new LocalStorePersistence(HIVE_FILTERS_KEY)
const sidebarCollapsePersistance = new LocalStorePersistence(
  SIDEBAR_COLLAPSE_STATE
)
const githubAppPostInstallPersistence = new LocalStorePersistence(
  GITHUB_APP_POST_INSTALL
)
const floatingUserSeen = new LocalStorePersistence(FLOATING_USER_SEEN_KEY)
const floatingUnrecognizedDomainSeen = new LocalStorePersistence(
  FLOATING_DOMAINS_SEEN_KEY
)
const pocModePersistance = new LocalStorePersistence(POC_MODE)
const licenseAlertClosed = new LocalStorePersistence(LICENSE_ALERT_CLOSED)
export {
  authPersistence,
  hiveFiltersPersistence,
  githubAppPostInstallPersistence,
  sidebarCollapsePersistance,
  floatingUserSeen,
  floatingUnrecognizedDomainSeen,
  pocModePersistance,
  licenseAlertClosed,
  teamPersistence,
}
