import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import { SlackIntegrations } from '../../../../blocks/settings/integrations/slack'

export const SlackIntegrationConnected = () => {
  const { slackSettings } = useSelector(select.Teams.teamSettings)
  const dispatch = useDispatch()
  return (
    <SlackIntegrations
      setSlackUrl={dispatch.Teams.setSlackUrl}
      setSlackEnableDisable={dispatch.Teams.setSlackIntegrationStatus}
      enabled={slackSettings.enabled}
      slackWebhookUrl={slackSettings.slackWebhookUrl}
    />
  )
}
