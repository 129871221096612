/** @jsx jsx */
import { Image, jsx } from 'theme-ui'
import { GitSourceCloud } from '@spectral/types'
import L from 'lodash'
import { Tooltip } from 'antd'
import githubLogo from '../../assets/svg/github.svg'
import googleLogo from '../../assets/svg/google.svg'
import bitbucketLogo from '../../assets/png/bitbucket.png'
import azureLogo from '../../assets/png/azuredevops.png'
import awsCodeCommitLogo from '../../assets/png/codecommit.png'
import gitlabLogo from '../../assets/svg/gitlab.svg'
import gitLogo from '../../assets/png/git.png'

const ASSET_SOURCE_TO_ICON = {
  [GitSourceCloud.GithubCom]: githubLogo,
  [GitSourceCloud.GoogleCom]: googleLogo,
  [GitSourceCloud.BitbucketOrg]: bitbucketLogo,
  [GitSourceCloud.GitlabCom]: gitlabLogo,
  [GitSourceCloud.AzureDevCom]: azureLogo,
  [GitSourceCloud.AzureVisualStudioCom]: azureLogo,
  [GitSourceCloud.AwsCodeCommit]: awsCodeCommitLogo,
}

const DEFAULT_ICON_SIZE = '20px'

const AssetGitSourceIcon = ({
  source,
  width = DEFAULT_ICON_SIZE,
  height = DEFAULT_ICON_SIZE,
  minWidth = DEFAULT_ICON_SIZE,
  minHeight = DEFAULT_ICON_SIZE,
}) => {
  return (
    <Tooltip title={L.capitalize(source)}>
      <Image
        sx={{
          width,
          height,
          minWidth,
          minHeight,
          mr: '2px',
        }}
        src={ASSET_SOURCE_TO_ICON[source] || gitLogo}
      />
    </Tooltip>
  )
}

export default AssetGitSourceIcon
