import React from 'react'

import {
  CopyFieldSection,
  PanelForm,
} from '../../../../components/panels/content'
import useDSN from '../../../../hooks/useDSN'

const Dsn = ({ currentTeam, userApiKey }) => {
  const { key: teamKey, loginType } = currentTeam
  const { dsn } = useDSN(teamKey, userApiKey, loginType)
  return (
    <PanelForm onSubmit={() => {}} initialValues={{ dsn }}>
      <CopyFieldSection
        name="dsn"
        value={dsn}
        label="DSN"
        description="Your fully qualified DSN"
        isSecret
      />
    </PanelForm>
  )
}

export default Dsn
