/** @jsx jsx */
// eslint-disable-next-line
import { jsx, Flex, Box } from 'theme-ui'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

export const LinkButton = ({
  text,
  className = undefined,
  href = null,
  to = null,
  icon = null,
  loading = false,
  size = 'large',
  type = null,
  block = false,
}) => {
  const Comp = (
    <Button
      className={className}
      size={size as SizeType}
      type={type}
      loading={loading}
      block={block}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(href && { href })}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          mx: 2,
          justifyContent: block && 'center',
        }}
      >
        {icon && icon}
        <Box ml={icon && 3}>{text}</Box>
      </Flex>
    </Button>
  )
  if (to) {
    return <Link to={to}>{Comp}</Link>
  }
  return Comp
}
