const DSN_SECRET_PLACEHOLDER = '{dsn}'
const TEAM_KEY_SECRET_PLACEHOLDER = '{teamKey}'
export const SECRETS_PLACEHOLDER = [
  DSN_SECRET_PLACEHOLDER,
  TEAM_KEY_SECRET_PLACEHOLDER,
]

export const isSecretIncluded = (code) => {
  const secretsTested = SECRETS_PLACEHOLDER.map((placeholder) =>
    new RegExp(placeholder, 'm').test(code)
  )
  return secretsTested.some((secretTested) => secretTested)
}

export const cleanSnippetToCopy = (snippet: string) =>
  snippet.split('##')[0].replace(/[\\$]\s+/, '')

export const boldParameters = (code) => {
  return code.replace(/\[(.+?)\]/g, (_, capture) => `<b>[${capture}]</b>`)
}

const toStars = (str: string, visualPrefixCount = 0) =>
  Array.from(str)
    .map((char, index) => (index >= visualPrefixCount ? '*' : char))
    .join('')

export const interpolate = ({ snippetCode, dynamicData, isSecretShown }) => {
  const {
    baseUrlProtocol,
    optionsStr,
    baseUrlHost,
    scanKey = '',
    dsn = '',
    baseUrl,
    digestLinks,
  } = dynamicData
  return `${snippetCode
    .replace('{baseUrlProtocol}', baseUrlProtocol)
    .replace('{baseUrlHost}', baseUrlHost)
    .replace(
      TEAM_KEY_SECRET_PLACEHOLDER,
      isSecretShown ? scanKey : toStars(scanKey)
    )
    .replace(DSN_SECRET_PLACEHOLDER, isSecretShown ? dsn : toStars(dsn, 12))
    .replace(/{baseUrl}/g, baseUrl)
    .replace('{options}', optionsStr)
    .replace('{digestLinkDarwin}', digestLinks.darwin)
    .replace('{digestLinkLinux}', digestLinks.linux)
    .replace('{digestLinkWin}', digestLinks.win)}`
}
