import { DEFAULT_UNSUPPORTED_SCM_URL_FORMATS_KEY } from './config'
import ScmUrlFormat from './scm-url-format'
import { RawUrlFormats, VerifiedUrlFormats } from './types'

export default class ScmUrlFormatsConfig {
  public rawConfig: RawUrlFormats
  private verifiedConfig: VerifiedUrlFormats

  constructor(rawUrlFormatsConfig: RawUrlFormats) {
    const verifiedUrlFormatsConfig = Object.keys(rawUrlFormatsConfig).reduce(
      (acc: VerifiedUrlFormats, currentKey: string) => {
        if (
          (rawUrlFormatsConfig[currentKey].uri &&
            !rawUrlFormatsConfig[currentKey].blameUrl) ||
          (!rawUrlFormatsConfig[currentKey].uri &&
            rawUrlFormatsConfig[currentKey].blameUrl)
        ) {
          throw new Error('Both formats must be nulls or contain a value')
        }

        return {
          ...acc,
          [currentKey]: {
            uri: new ScmUrlFormat(rawUrlFormatsConfig[currentKey].uri),
            blameUrl: new ScmUrlFormat(
              rawUrlFormatsConfig[currentKey].blameUrl
            ),
          },
        }
      },
      {}
    )

    this.verifiedConfig = verifiedUrlFormatsConfig
    this.rawConfig = rawUrlFormatsConfig
  }

  public getScmFormats(scm: string) {
    if (!this.verifiedConfig.hasOwnProperty(scm)) {
      throw new Error(`SCM ${scm} is not defined in setting`)
    }

    return {
      blameUrl: this.verifiedConfig[scm].blameUrl,
      uri: this.verifiedConfig[scm].uri,
    }
  }

  public buildIssueUrls(
    issue,
    scm: string = DEFAULT_UNSUPPORTED_SCM_URL_FORMATS_KEY
  ) {
    if (!this.verifiedConfig.hasOwnProperty(scm)) {
      throw new Error(`SCM ${scm} is not defined in setting`)
    }

    return {
      blameUrl: this.verifiedConfig[scm].blameUrl.buildUrlFromIssue(issue),
      finding: this.verifiedConfig[scm].uri.buildUrlFromIssue(issue),
    }
  }

  public buildConcatDataSources(
    scm = DEFAULT_UNSUPPORTED_SCM_URL_FORMATS_KEY
  ): { blameUrl: string[]; uri: string[] } {
    if (!this.verifiedConfig.hasOwnProperty(scm)) {
      throw new Error(`SCM ${scm} is not defined in setting`)
    }

    const scmUrlFormats = this.verifiedConfig[scm]
    return {
      blameUrl: scmUrlFormats.blameUrl.buildConcatDataSources(),
      uri: scmUrlFormats.uri.buildConcatDataSources(),
    }
  }
}
