import { ModelConfig } from '@rematch/core'
import { sidebarCollapsePersistance } from '../../persistence'

type SidebarState = {
  isCollapsed: boolean
}

const initialState: SidebarState = {
  isCollapsed: false,
}
export const sidebar: ModelConfig<SidebarState> = {
  state: initialState,
  reducers: {
    setSidebarCollapse(state: SidebarState, isCollapsed) {
      return { ...state, isCollapsed }
    },
  },
  effects: (dispatch: any) => ({
    updateSidebarCollapse(payload, rootState) {
      const { isCollapsed } = rootState.Sidebar;
      sidebarCollapsePersistance.persist(!isCollapsed)
      dispatch.Sidebar.setSidebarCollapse(!isCollapsed)
    },
    loadSidebarCollapse() {
      const sidebarCollapse = sidebarCollapsePersistance.get()
      if (sidebarCollapse) dispatch.Sidebar.setSidebarCollapse(sidebarCollapse)
    },
  })
}