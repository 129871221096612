import { Divider } from 'antd'
import React from 'react'
import theme from '../../common/theme'

const FilterDivider = () => (
  <Divider
    style={{
      color: theme.stylebook.colors.gray[300],
      marginBottom: '20px',
      marginTop: '20px',
    }}
  />
)

export default FilterDivider
