/** @jsx jsx */
import { OpenSourceIssueType, removeFilePathSlash } from '@spectral/types'
import { Collapse, List, Pagination } from 'antd'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import parseInt from 'lodash/parseInt'
import size from 'lodash/size'
import flatten from 'lodash/flatten'
import React, { useState } from 'react'
import { Box, Flex, jsx, Text } from 'theme-ui'
import styled from 'styled-components'
import SingleIssueAlert from '../../../../components/asset-page/single-issue/single-issue-alert'
import { Loading } from '../../../../components/loading'
import { toElementId } from '../../utils'
import EmptyAsset from '../../empty-asset'
import { itemsToDisplayInPage } from '../../shared/list-page-setup'
import OpenSourceFilesCollapse from '../../../../components/collapse/styled-collapse'
import OpenSourceIssueItem from './item'
import IssuesListActions from '../../issues-list-actions'

const { Panel } = Collapse

const IN_FILE_ISSUES_PAGE_SIZE = 5

const OpenSourceIssuesList = styled(List)`
  .ant-list-items {
    min-height: ${({ hasMinHeight }) => (hasMinHeight ? '500px' : '0')};
  }
`

const sortOpenSourceIssuesByStatus = (issues) =>
  issues.sort((issueA, issueB) => {
    const issueAStatus = issueA.metadata.attributes.status
    const issueBStatus = issueB.metadata.attributes.status
    if (
      issueAStatus === OpenSourceIssueType.MALICIOUS &&
      issueBStatus !== OpenSourceIssueType.MALICIOUS
    )
      return -1
    if (
      issueBStatus === OpenSourceIssueType.MALICIOUS &&
      issueAStatus !== OpenSourceIssueType.MALICIOUS
    )
      return 1
    return 0
  })

const OpenSourceFilePanelContent = ({
  isRefreshingOpenSourceFileDetails,
  filePath,
  issues,
  actionHandlers,
  selectedIssuesIds,
  currentQueryStringParams,
  onIssueSelectionChanged,
  integrations,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
}) => {
  const [inFileIssuesPage, setInFileIssuesPage] = useState(1)
  const { issueId } = currentQueryStringParams
  if (isRefreshingOpenSourceFileDetails) {
    return <Loading />
  }

  const sortedIssues = issues && sortOpenSourceIssuesByStatus(issues)
  return sortedIssues ? (
    <Box>
      <div id={toElementId(filePath)} />
      <OpenSourceIssuesList
        hasMinHeight={sortedIssues.length > IN_FILE_ISSUES_PAGE_SIZE}
        dataSource={itemsToDisplayInPage(
          sortedIssues,
          inFileIssuesPage,
          IN_FILE_ISSUES_PAGE_SIZE
        )}
        renderItem={(openSourceIssue: any) => (
          <List.Item>
            <OpenSourceIssueItem
              issue={openSourceIssue}
              actionHandlers={actionHandlers}
              isSelected={includes(selectedIssuesIds, openSourceIssue.pid)}
              onIssueSelectionChanged={onIssueSelectionChanged}
              integrations={integrations}
              assignableMembers={assignableMembers}
              isLoadingAssignableMembers={isLoadingAssignableMembers}
              isAssignableMembersLoaded={isAssignableMembersLoaded}
            />
          </List.Item>
        )}
      />
      {!issueId && (
        <Box sx={{ mt: 3, textAlign: 'right' }}>
          <Pagination
            current={inFileIssuesPage}
            onChange={(page) => setInFileIssuesPage(page)}
            total={size(sortedIssues)}
            pageSize={IN_FILE_ISSUES_PAGE_SIZE}
            showSizeChanger={false}
            size="small"
          />
        </Box>
      )}
    </Box>
  ) : (
    <Loading />
  )
}

const OpenSourceFilesList = ({
  onPaginationChange,
  isRefreshing,
  isRefreshingOpenSourceFileDetails,
  openSourceFiles,
  currentQueryStringParams,
  clearQueryStringParam,
  actionHandlers,
  onIssueSelectionChanged,
  selectedIssues,
  expandedFile,
  onChangeFileExpansion,
  exportActions,
  isDownloadingExport,
  integrations,
  onActionIntegrationSubmit,
  totalIssues,
  assignableMembers,
  isLoadingAssignableMembers,
  isAssignableMembersLoaded,
  isNotActiveAsset,
  isDataFiltered,
  setQueryStringParam,
  trackSort,
  sortOptions,
}) => {
  const { page, issueId } = currentQueryStringParams
  const selectedIssuesIds = map(selectedIssues, 'pid')

  const resetSingleIssue = () => {
    clearQueryStringParam(['issueId', 'sortBy', 'path', 'sortDirection'])
  }
  const numTotalIssues = size(
    flatten(map(openSourceFiles, ({ issues }) => issues || []))
  )

  return (
    <Flex sx={{ height: '100%', flexDirection: 'column', pr: '40px' }}>
      {!isRefreshing && !isEmpty(openSourceFiles) && (
        <IssuesListActions
          actionHandlers={actionHandlers}
          selectedIssues={selectedIssues}
          issues={openSourceFiles}
          isDownloadingExport={isDownloadingExport}
          integrations={integrations}
          assignableMembers={assignableMembers}
          isLoadingAssignableMembers={isLoadingAssignableMembers}
          isAssignableMembersLoaded={isAssignableMembersLoaded}
          onActionIntegrationSubmit={onActionIntegrationSubmit}
          exportActions={exportActions}
          onSecretSelectionChanged={onIssueSelectionChanged}
          isSelectAllShown={false}
          currentQueryStringParams={currentQueryStringParams}
          setQueryStringParam={setQueryStringParam}
          trackSort={trackSort}
          sortOptions={sortOptions}
        />
      )}
      {isRefreshing ? ( // eslint-disable-line no-nested-ternary
        <Box sx={{ height: '100%' }}>
          <Loading />
        </Box>
      ) : isEmpty(openSourceFiles) && !isRefreshingOpenSourceFileDetails ? (
        <Box sx={{ height: '100%', mt: 3 }}>
          <EmptyAsset
            isNotActive={isNotActiveAsset}
            isNoDataShown={isDataFiltered}
          />
        </Box>
      ) : (
        <React.Fragment>
          {issueId && openSourceFiles && numTotalIssues === 1 && (
            <SingleIssueAlert
              alertType="info"
              alertText="You are viewing a single issue"
              onReset={resetSingleIssue}
              resetText="view all issues in this asset"
            />
          )}
          {issueId && openSourceFiles && numTotalIssues === 0 && (
            <SingleIssueAlert
              alertType="error"
              alertText="Requested issue does not exist"
              onReset={resetSingleIssue}
              resetText="view all issues in this asset"
            />
          )}
          <OpenSourceFilesCollapse
            onChange={(updatedlyExpandedFile) =>
              onChangeFileExpansion(
                updatedlyExpandedFile ? [updatedlyExpandedFile] : null
              )
            }
            activeKey={expandedFile}
            bordered={false}
            accordion
          >
            {map(openSourceFiles, ({ filePath, count, issues }) => (
              <Panel
                header={
                  <Box id={toElementId(filePath)}>
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Flex>
                        <Text>{removeFilePathSlash(filePath)}</Text>
                      </Flex>

                      <Flex>
                        <Text sx={{ fontSize: '12px' }}>{count} issues</Text>
                      </Flex>
                    </Flex>
                  </Box>
                }
                key={filePath}
              >
                <OpenSourceFilePanelContent
                  isRefreshingOpenSourceFileDetails={
                    isRefreshingOpenSourceFileDetails
                  }
                  filePath={filePath}
                  issues={issues}
                  currentQueryStringParams={currentQueryStringParams}
                  actionHandlers={actionHandlers}
                  selectedIssuesIds={selectedIssuesIds}
                  onIssueSelectionChanged={onIssueSelectionChanged}
                  integrations={integrations}
                  assignableMembers={assignableMembers}
                  isLoadingAssignableMembers={isLoadingAssignableMembers}
                  isAssignableMembersLoaded={isAssignableMembersLoaded}
                />
              </Panel>
            ))}
          </OpenSourceFilesCollapse>
        </React.Fragment>
      )}
      {!issueId && (
        <Flex sx={{ pb: 2, pt: 2, justifyContent: 'end' }}>
          <Pagination
            current={page ? parseInt(page) : 1}
            onChange={onPaginationChange}
            total={totalIssues}
            pageSize={10}
            showSizeChanger={false}
            disabled={isRefreshing}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default OpenSourceFilesList
