import React, { useEffect } from 'react'
import { Box } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import { Page } from '../../components/page'
import { select } from '../../redux/store'
import OnPremiseInstancesConnected from './scms/git-on-prem/on-prem-settings-connected'
import FallbackUrlsSettings, {
  SampleIssue,
} from './scms/url-formats/url-formats-settings'
import { RoleType } from '@spectral/types'
import Restricted from '../role-based-render/restricted'

export default () => {
  const dispatch = useDispatch()
  const { pageError, isLoading } = useSelector(select.Teams.teamPageStatus)

  const { scms } = useSelector(select.SCMConfigurations.scms)

  useEffect(() => {
    dispatch.Teams.fetchCurrentTeam()
  }, [dispatch.Teams])

  useEffect(() => {
    dispatch.SCMConfigurations.fetchSCMConfigurations()
  }, [dispatch.SCMConfigurations])

  return (
    <Page
      name="SETTINGS_SCM"
      title="Source Control Management Settings"
      isLoading={isLoading}
      isError={pageError}
    >
      <Box sx={{ padding: '40px' }}>
        <OnPremiseInstancesConnected scms={scms} />
        <Restricted roles={[RoleType.Owner]}>
          <FallbackUrlsSettings
            formats={scms?.unsupported.urlFormats.default}
            isParentLoading={!scms}
          />
        </Restricted>
      </Box>
    </Page>
  )
}
