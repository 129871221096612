import React, { useEffect } from 'react'
import { Box } from 'theme-ui'
import { useDispatch, useSelector } from 'react-redux'
import { notification } from 'antd'
import { Page } from '../../components/page'
import { DeleteAsset } from '../../blocks/settings/delete-asset'
import { select } from '../../redux/store'

export default () => {
  const dispatch = useDispatch()
  const assetsDropDownData = useSelector(select.Assets.getAssetsDropDown)

  useEffect(() => {
    dispatch.Assets.fetchAssetsSummary()
  }, [dispatch.Assets])

  const getAssetName = (asset) =>
    `${asset.assetType}://${asset.assetDisplayName}`

  return (
    <Page name="SETTINGS_DELETE_ASSET" title="Delete Asset">
      <Box sx={{ padding: '40px' }}>
        <DeleteAsset
          assetsDropDownData={assetsDropDownData}
          getAssetName={getAssetName}
          onAssetDelete={async (assetSelected) => {
            const assetName = getAssetName(assetSelected)
            const result = await dispatch.Assets.deleteAsset(
              assetSelected.assetId
            )
            if (result.result === 'success') {
              notification.success({
                message: 'Success',
                description: `Asset ${assetName} has been deleted.`,
              })
            } else if (result.result === 'noSuchAsset') {
              notification.info({
                message: `Couldn't find any scans for ${assetName}.`,
              })
            } else {
              notification.error({
                message: 'Error',
                description: `There was a problem with deleting ${assetName}. Please try again later.`,
              })
            }
          }}
        />
      </Box>
    </Page>
  )
}
