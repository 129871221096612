import React from 'react'
import { Button } from 'antd'
import { IoMdRefresh } from 'react-icons/io'

export const RefreshButton = ({ isRefreshing, onClick = null }) => (
  <Button
    loading={isRefreshing}
    type="ghost"
    size="middle"
    htmlType="button"
    onClick={onClick}
    icon={<IoMdRefresh size={26} />}
  />
)
