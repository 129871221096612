/** @jsx jsx */
import { Empty } from 'antd'
import { jsx, Box, Text, Flex, Image } from 'theme-ui'
import noIssuesImgSrc from '../../assets/svg/no-issues.svg'
import noScansImgSrc from '../../assets/svg/no-scans.svg'
import theme from '../../common/theme'

const defaultNoIssue = {
  text: "No issues! You're awesome!",
  colorText: theme.stylebook.colors.severities.noneHEX,
  imageSrc: noIssuesImgSrc,
}

const notActiveAsset = {
  text: 'Not scanned recently.',
  colorText: theme.stylebook.colors.severities.oldHEX,
  imageSrc: noScansImgSrc,
}

const EmptyAsset = ({ isNotActive, isNoDataShown = false }) => {
  const { text, colorText, imageSrc } = isNotActive
    ? notActiveAsset
    : defaultNoIssue

  if (isNoDataShown) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }
  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '300px',
          flexBasis: 'auto',
          flexGrow: 0,
        }}
      >
        <Image src={imageSrc} alt={text} />
      </Box>
      <Text variant="smallheading" sx={{ color: colorText }}>
        {text}
      </Text>
    </Flex>
  )
}

export default EmptyAsset
