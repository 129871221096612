import L from 'lodash'
import styled from 'styled-components'
import React, { useState, useRef } from 'react'
import { Flex, Text } from 'theme-ui'
import { Select } from 'antd'

const StyledSelect = styled(Select)`
  .ant-select-clear {
    font-size: 16px;
    top: 48%;
  }
`

export const SingleTagSelect = ({
  onChange = undefined,
  value = undefined,
  placeholder = undefined,
  options,
  disabled = false,
}) => (
  <StyledSelect
    mode="tags"
    placeholder={placeholder}
    value={value}
    onChange={(selectedItems) => onChange(L.last(selectedItems))}
    disabled={disabled}
    style={{ width: '100%' }}
  >
    {options.map((item) => (
      <Select.Option key={item} value={item}>
        {item}
      </Select.Option>
    ))}
  </StyledSelect>
)

export const AddableDropDown = ({
  onChange = undefined,
  value = undefined,
  placeholder = undefined,
  options,
  disabled = false,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const selectEl = useRef(null)
  return (
    <Select
      ref={selectEl}
      showSearch
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onSearch={setSearchValue}
      disabled={disabled}
      style={{ width: '100%' }}
      notFoundContent={
        <Flex
          style={{ cursor: 'pointer', width: '100%' }}
          onClick={() => {
            onChange(searchValue)
            selectEl.current.blur()
          }}
        >
          Click to add{' '}
          <Text sx={{ ml: '5px', fontWeight: '700' }}>{searchValue}</Text>
        </Flex>
      }
    >
      {options.map((item) => (
        <Select.Option key={item} value={item}>
          {item}
        </Select.Option>
      ))}
    </Select>
  )
}

export const DropDown = ({
  dropdownMatchSelectWidth = null,
  optionLabelProp = null,
  onChange = undefined,
  value = undefined,
  placeholder = undefined,
  maxTagCount = null,
  options = null,
  disabled = false,
  allowClear = false,
  mode = '',
  notFoundContent = null,
  children = null,
}) => {
  const selectEl = useRef(null)
  return (
    <StyledSelect
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      optionLabelProp={optionLabelProp}
      allowClear={allowClear}
      ref={selectEl}
      // @ts-ignore
      mode={mode}
      maxTagCount={maxTagCount}
      showSearch
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      style={{ width: '100%' }}
      notFoundContent={notFoundContent}
    >
      {options
        ? options.map((item) => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))
        : children}
    </StyledSelect>
  )
}
