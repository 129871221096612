import React from 'react'
import { Box } from 'theme-ui'
import { useSelector } from 'react-redux'
import { Page } from '../../components/page'
import { select } from '../../redux/store'
import NotificationSettingsConnected from './team/notification-settings/notification-settings-connected'
import TeamMembersConnected from './team/team-members/team-members-connected'
import TeamSettingsConnected from './team/team-settings/team-settings-connected'
import PlanSettingsConnected from './team/plan-settings/plan-settings-connected'
import EmbeddedHidden from '../embeded-hidden/embeded-hidden'

export default () => {
  const { pageError, isLoading } = useSelector(select.Teams.teamPageStatus)

  return (
    <Page
      name="SETTINGS_TEAM"
      title="Team Settings"
      isLoading={isLoading}
      isError={pageError}
    >
      <Box sx={{ padding: '40px' }}>
        <TeamSettingsConnected />
        <NotificationSettingsConnected />
        <TeamMembersConnected />
        <EmbeddedHidden>
          <PlanSettingsConnected />
        </EmbeddedHidden>
      </Box>
    </Page>
  )
}
