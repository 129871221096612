import { SourceIntegrationComponent } from '../enums'

const title = 'GitHub CI/CD Hardening'
const customInto =
  'Github token needs the following minimal permissions: repo, read:packages, read:org, read:repo_hook, read:ssh_signing_key'
export const discoverGithub = {
  mode: 'discover',
  isScansSettingHidden: true,
  component: SourceIntegrationComponent.SnippetWindow,
  'MAC/Linux': {
    title,
    content: '',
    tileKey: 'github',
    snippets: [
      {
        title: 'Discover GitHub repo',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral discover github -k repo [YOUR_REPO]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_REPO',
            example: 'https://github.com/ACME-Corp-Demo/tensorflow',
            explain: 'full path to the repository.',
          },
        ],
      },
      {
        title: 'Discover GitHub user',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral discover github -k user [YOUR_USER]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_USER',
            example: 'maggie',
          },
        ],
      },
      {
        title: 'Discover GitHub organization',
        code:
          '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral discover github -k org [YOUR_ORGANIZATION]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_ORGANIZATION',
            example: 'ACME-Corp-Demo',
          },
        ],
      },
    ],
    customInto,
  },
  Windows: {
    title,
    content: '',
    tileKey: 'github',
    snippets: [
      {
        title: 'Discover GitHub repo',
        code: '$ spectral.exe --dsn {dsn} discover github -k repo [YOUR_REPO]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_REPO',
            example: 'https://github.com/ACME-Corp-Demo/tensorflow',
            explain: 'full path to the repository.',
          },
        ],
      },
      {
        title: 'Discover GitHub user',
        code: '$ spectral.exe --dsn {dsn} discover github -k user [YOUR_USER]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_USER',
            example: 'maggie',
          },
        ],
      },
      {
        title: 'Discover GitHub organization',
        code: '$ spectral.exe --dsn {dsn} discover github -k user [YOUR_REPO]',
        type: 'shell',
        params: [
          {
            name: 'YOUR_REPO',
            example: 'ACME-Corp-Demo',
          },
        ],
      },
    ],
    customInto,
  },
}
