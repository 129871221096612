import React, { useState } from 'react'
import { Box } from 'theme-ui'
import * as Yup from 'yup'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  ContentPanel,
  ContentPanelHeader,
  PanelForm,
  SelectFieldSection,
  SubmitSection,
} from '../../components/panels/content'

export const DeleteAsset = ({
  onAssetDelete,
  assetsDropDownData,
  getAssetName,
}) => {
  const [assetSelected, setAssetSelected] = useState(null)
  return (
    <Box>
      <ContentPanel>
        <ContentPanelHeader>Delete Asset</ContentPanelHeader>
        <PanelForm
          onSubmit={async ({ _assetName }, { resetForm }) => {
            Modal.confirm({
              title: 'Delete asset',
              icon: <ExclamationCircleOutlined translate="no" />,
              content: `Are you sure you want to delete asset: ${getAssetName(
                assetSelected
              )}?`,
              okText: 'Yes',
              okType: 'danger',
              cancelText: 'No',
              onOk: async () => {
                await onAssetDelete(assetSelected)
                await resetForm()
              },
            })
          }}
          initialValues={{
            assetName: 'Asset name',
          }}
          validationSchema={Yup.object({
            assetName: Yup.string().required('Asset id is required.'),
          })}
        >
          <SelectFieldSection
            name="assetName"
            label="Enter the asset name you want to delete"
            options={assetsDropDownData}
            groupBy="assetType"
            notFoundContent={<Box>No assets found</Box>}
            filterOption={(input, option) => {
              if (option.options) {
                return option.options.every((groupOption) =>
                  groupOption.value.toLowerCase().includes(input.toLowerCase())
                )
              }
              return option.value.toLowerCase().includes(input.toLowerCase())
            }}
            onSelectAction={(_val, option) => {
              setAssetSelected(option)
            }}
            required
          />
          <SubmitSection danger text="Delete" />
        </PanelForm>
      </ContentPanel>
    </Box>
  )
}
