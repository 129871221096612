import React from 'react'
import { Alert } from 'antd'

type Props = {
  error: string | React.ReactNode
  showIcon?: boolean
}
const ErrorMessage = ({ error, showIcon = false }: Props) => {
  return <Alert message={error} type="error" showIcon={showIcon} />
}

export default ErrorMessage
