import React, { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Page } from '../../components/page'
import { select } from '../../redux/store'
import { paths } from '../../routes'

const EmailVerification = () => {
  const { token } = useParams()
  const currentUser = useSelector((state) => state.Auth.user)
  const { isLoading, error } = useSelector(
    select.EmailVerification.verificationStatus
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch.EmailVerification.verifyEmail({ token })
  }, [token, dispatch])

  if (currentUser) {
    return <Redirect to={paths.closed.sources} />
  }

  if (error) {
    return <Redirect to={paths.open.signin} />
  }

  return (
    <Page
      name="EMAIL-VERIFICATION"
      title="Email Verification"
      isError={!!error}
      isLoading={isLoading}
    />
  )
}

export default EmailVerification
