import React, { useEffect } from 'react'
import * as Yup from 'yup'
import {
  getViewRolesWithoutOwner,
  RoleDisplayText,
  RoleType,
} from '@spectral/types'
import { notification } from 'antd'

import {
  InputFieldSection,
  PanelForm,
  SelectFieldSection,
  SubmitSection,
} from '../../../../components/panels/content'

const AddMember = ({
  sendInvite = null,
  readOnly = false,
  inviteError = '',
}) => {
  useEffect(() => {
    if (inviteError) {
      notification.error({
        key: inviteError,
        message: 'Failed to invite',
        description: inviteError,
        duration: 4,
      })
    }
  }, [inviteError])

  const roleTypes = getViewRolesWithoutOwner().map((role) => ({
    value: role,
    label: RoleDisplayText[role],
    key: role,
  }))

  return (
    <PanelForm
      onSubmit={async ({ inviteEmail, roleType }, { resetForm }) => {
        await sendInvite({ inviteEmail, roleType })
        resetForm()
      }}
      validationSchema={Yup.object({
        inviteEmail: Yup.string()
          .email('Nope. That is not an email address.')
          .required('We gotta have that email first.'),
      })}
      initialValues={{
        inviteEmail: '',
        roleType: RoleType.Member,
      }}
    >
      <InputFieldSection
        name="inviteEmail"
        label="Invite team member"
        description="This will send an invite via email to your team member"
        placeholder="maggie@example.com"
        disabled={readOnly}
        required
        merge
      />
      <SelectFieldSection
        name="roleType"
        label="Member role type"
        options={roleTypes}
        disabled={readOnly}
        required
      />
      <SubmitSection text="Send Invite" disabled={readOnly} />
    </PanelForm>
  )
}
export default AddMember
