import {
  PaginationInfo,
  ReportAssetView,
  ReportIssue,
  SortDirection,
  ASSETS_PAGE_SIZE,
  AssetSecretSprawlView,
  AssetPageTab,
  IssuesSortBy,
  IssueKind,
} from '@spectral/types'
import size from 'lodash/size'
import concat from 'lodash/concat'
import map from 'lodash/map'
import some from 'lodash/some'
import isNil from 'lodash/isNil'
import flatten from 'lodash/flatten'
import { castArray } from 'lodash'
import { FetchStatus, RootState, sdkClient } from '../../../store'
import { empty, loaded } from '../../../utils'
import { toQuerySorter } from '../utils'

const DEFAULT_FILE_DETAILS_PAGE_SIZE = 1000
const DEFAULT_PAGE_NUMBER = 1

const initialState = {
  assetInfo: empty({}),
  assignableMembers: empty([]),
  secrets: empty({
    data: [],
    pagination: { page: DEFAULT_PAGE_NUMBER, pageSize: ASSETS_PAGE_SIZE },
    filtersData: {},
  }),
  iacFiles: empty({
    data: [],
    pagination: { page: DEFAULT_PAGE_NUMBER, pageSize: ASSETS_PAGE_SIZE },
    filtersData: {},
  }),
  openSourceFiles: empty({
    data: [],
    pagination: { page: DEFAULT_PAGE_NUMBER, pageSize: ASSETS_PAGE_SIZE },
    filtersData: {},
  }),
  secretsSprawl: empty({
    data: [],
  }),
  discoverIssues: empty({
    data: [],
    pagination: { page: DEFAULT_PAGE_NUMBER, pageSize: ASSETS_PAGE_SIZE },
    filtersData: {},
  }),
}
type AssetPageState = {
  assetInfo: { fetchStatus: FetchStatus; data: ReportAssetView }
  assignableMembers: { fetchStatus: FetchStatus; data: Array<any> }
  secrets: {
    fetchStatus: FetchStatus
    data: {
      data: Array<ReportIssue>
      pagination: PaginationInfo
      filtersData: any
    }
  }
  iacFiles: {
    fetchStatus: FetchStatus
    data: {
      data: Array<{ filepath: string; issues?: Array<ReportIssue> }>
      pagination: PaginationInfo
      filtersData: any
    }
  }
  openSourceFiles: {
    fetchStatus: FetchStatus
    data: {
      data: Array<{
        filepath: string
        issues?: Array<ReportIssue>
      }>
      pagination: PaginationInfo
      filtersData: any
    }
  }
  secretsSprawl: {
    fetchStatus: FetchStatus
    data: Array<AssetSecretSprawlView>
  }
  discoverIssues: {
    fetchStatus: FetchStatus
    data: {
      data: Array<ReportIssue>
      pagination: PaginationInfo
      filtersData: any
    }
  }
}

const buildBaseIssuesFilters = (params) => {
  return {
    detectorId: concat([], params.detectorId).join(','),
    severity: concat([], params.severity).join(','),
    assignees: concat([], params.assignees || []).map((a) => a.toLowerCase()),
    content: concat([], params.content).join(','),
    resources: concat([], params.resources).join(','),
    packagesNames: concat([], params.packagesNames).join(','),
    OpenSourceIssueTypes: concat([], params.OpenSourceIssueTypes).join(','),
    assetId: params.assetId,
    issueKinds: params.issueKinds,
    sorter: toQuerySorter({
      field: params.sortBy,
      order: params.sortDirection?.toUpperCase(),
    }),
    tag: concat([], params.tag).join(','),
    iac: !!params.iac,
  }
}

export const assetPage = {
  state: initialState,
  reducers: {
    setAssetInfo(state: AssetPageState, assetInfo: ReportAssetView) {
      return {
        ...state,
        assetInfo: loaded(assetInfo),
      }
    },
    setAssignableMembers(state: AssetPageState, assignableMembers: Array<any>) {
      return {
        ...state,
        assignableMembers: loaded(assignableMembers),
      }
    },
    setSecretsSprawl(
      state: AssetPageState,
      secretsSprawl: Array<AssetSecretSprawlView>
    ) {
      return {
        ...state,
        secretsSprawl: loaded({ data: secretsSprawl }),
      }
    },
    upsertSecretsLocally(state: AssetPageState, issues) {
      return {
        ...state,
        secrets: loaded({
          data: issues,
          pagination: state.secrets.data.pagination,
          filtersData: state.secrets.data.filtersData,
        }),
      }
    },
    upsertDiscoverIssueLocally(state: AssetPageState, issues) {
      return {
        ...state,
        discoverIssues: loaded({
          data: issues,
          pagination: state.discoverIssues.data.pagination,
          filtersData: state.discoverIssues.data.filtersData,
        }),
      }
    },
    upsertIacLocally(state: AssetPageState, iacFiles) {
      return {
        ...state,
        iacFiles: loaded({
          data: iacFiles,
          pagination: state.iacFiles.data.pagination,
          filtersData: state.iacFiles.data.filtersData,
        }),
      }
    },
    upsertOpenSourceLocally(state: AssetPageState, openSourceFiles) {
      return {
        ...state,
        openSourceFiles: loaded({
          data: openSourceFiles,
          pagination: state.openSourceFiles.data.pagination,
          filtersData: state.openSourceFiles.data.filtersData,
        }),
      }
    },
    setSecrets(state: AssetPageState, issues) {
      return { ...state, secrets: loaded({ ...state.secrets.data, ...issues }) }
    },
    resetAssetInfo(state: AssetPageState) {
      return {
        ...state,
        assetInfo: initialState.assetInfo,
        assignableMembers: initialState.assignableMembers,
      }
    },
    resetFilters(state: AssetPageState) {
      return {
        ...state,
        secrets: {
          ...initialState.secrets,
          data: {
            filtersData: initialState.secrets.data.filtersData,
            ...state.secrets.data,
          },
        },
        iacFiles: {
          ...initialState.iacFiles,
          data: {
            filtersData: initialState.iacFiles.data.filtersData,
            ...state.iacFiles.data,
          },
        },
        discoverIssues: {
          ...initialState.discoverIssues,
          data: {
            filtersData: initialState.discoverIssues.data.filtersData,
            ...state.discoverIssues.data,
          },
        },
      }
    },
    resetSecrets(state: AssetPageState) {
      return {
        ...state,
        secrets: {
          ...state.secrets,
          data: {
            ...initialState.secrets.data,
            pagination: state.secrets.data.pagination,
            filtersData: state.secrets.data.filtersData,
          },
        },
      }
    },
    setIacFiles(state: AssetPageState, files) {
      return {
        ...state,
        iacFiles: loaded({ ...state.iacFiles.data, ...files }),
      }
    },
    setopenSourceFiles(state: AssetPageState, files) {
      return {
        ...state,
        openSourceFiles: loaded({ ...state.openSourceFiles.data, ...files }),
      }
    },
    resetIacFiles(state: AssetPageState) {
      return {
        ...state,
        iacFiles: {
          ...state.iacFiles,
          data: {
            ...initialState.iacFiles.data,
            pagination: state.iacFiles.data.pagination,
            filtersData: state.iacFiles.data.filtersData,
          },
        },
      }
    },
    resetOpenSourceFiles(state: AssetPageState) {
      return {
        ...state,
        openSourceFiles: {
          ...state.openSourceFiles,
          data: {
            ...initialState.openSourceFiles.data,
            pagination: state.openSourceFiles.data.pagination,
            filtersData: state.openSourceFiles.data.filtersData,
          },
        },
      }
    },
    setDiscoverIssues(state: AssetPageState, issues) {
      return {
        ...state,
        discoverIssues: loaded({ ...state.discoverIssues.data, ...issues }),
      }
    },
    resetDiscoverIssues(state: AssetPageState) {
      return {
        ...state,
        discoverIssues: {
          ...state.discoverIssues,
          data: {
            ...initialState.discoverIssues.data,
            pagination: state.discoverIssues.data.pagination,
            filtersData: state.discoverIssues.data.filtersData,
          },
        },
      }
    },
  },
  effects: (dispatch: any) => ({
    async fetchSingleIssue(payload: any) {
      const { filters } = payload
      const result: {
        data: any
        pagination: any
        filtersData: any
      } = (await sdkClient.issues().filterIssues({
        params: {
          assetId: decodeURIComponent(filters.assetId),
          issueIds: filters.issueId,
          page: DEFAULT_PAGE_NUMBER,
          pageSize: 1000,
          sorter: toQuerySorter({
            field: filters.sortBy,
            order:
              filters.sortDirection === SortDirection.DESC
                ? 'descend'
                : 'ascend',
          }),
          exactPaths: filters.iacFiles?.toString(),
        },
      })) as any
      const issue = result.data && size(result.data) > 0 ? result.data[0] : null
      if (issue) {
        payload.callBack(issue)
      }
    },
    async fetchAssetInfo(payload) {
      const asset = await sdkClient
        .assets()
        .assetById({ params: { assetId: payload.assetId } })

      dispatch.AssetPage.setAssetInfo(asset)
    },
    async fetchAssignees(payload) {
      const assignableMembers = await sdkClient
        .users()
        .getAssetAssignableUsers({
          params: { assetId: payload.assetId, nameFilter: payload.nameFilter },
        })
      dispatch.AssetPage.setAssignableMembers(assignableMembers)
    },
    async fetchSecretsSprawl(payload) {
      const res = (await sdkClient
        .issues()
        .secretsSprawl({ params: { assetId: payload.assetId } })) as Array<
        AssetSecretSprawlView
      >
      dispatch.AssetPage.setSecretsSprawl(res)
    },
    async exportIssues(payload) {
      const response = await sdkClient.issues().exportIssues({
        params: {
          ...payload,
          ...buildBaseIssuesFilters(payload),
          sorter: [AssetPageTab.Iac, AssetPageTab.OpenSource].includes(
            payload.tab
          )
            ? toQuerySorter({
                field: IssuesSortBy.PATH,
                order: SortDirection.ASC,
              })
            : toQuerySorter({
                field: payload.sortBy,
                order: payload.sortDirection,
              }),
        },
      })

      return response
    },
    async fetchSecretIssues(payload) {
      const result: {
        data: any
        pagination: any
        filtersData: any
      } = (await sdkClient.issues().filterIssues({
        params: {
          ...payload,
          ...buildBaseIssuesFilters(payload),
          issueKinds: castArray(IssueKind.Secrets),
          page: payload.page,
          pageSize: payload.pageSize || 10,
        },
      })) as any
      if (payload.updateOnlyFilters) {
        dispatch.AssetPage.setSecrets({ filtersData: result.filtersData })
      } else {
        dispatch.AssetPage.setSecrets(result)
      }
    },
    async fetchDiscoverIssues(payload, rootState: RootState) {
      const { token } = rootState.Auth.user
      const result: {
        data: any
        pagination: any
        filtersData: any
      } = (await sdkClient.issues(token).filterIssues({
        params: {
          ...payload,
          ...buildBaseIssuesFilters(payload),
          issueKinds: castArray(IssueKind.Cicd),
          page: payload.page,
          pageSize: 10,
        },
      })) as any
      if (payload.updateOnlyFilters) {
        dispatch.AssetPage.setDiscoverIssues({
          filtersData: result.filtersData,
        })
      } else {
        dispatch.AssetPage.setDiscoverIssues(result)
      }
    },
    async fetchIacFiles(payload, rootState: RootState) {
      const {
        sortBy,
        sortDirection,
        page,
        assetId,
        assignees,
        detectorId,
        status,
        resources,
        severity,
        tag,
        path,
      } = payload
      const iacFiles = (await sdkClient.assets().assetsDetails({
        data: {
          assetIds: [assetId],
          page,
          pageSize: 10,
          shouldReturnFiltersData: true,
          sortBy,
          sortDirection,
          assignees,
          detectorId,
          status,
          resources,
          severity,
          tag,
          path,
        },
      })) as {
        data: any
        pagination: any
        filtersData: any
      }
      if (payload.updateOnlyFilters) {
        dispatch.AssetPage.setIacFiles({
          ...rootState.AssetPage.iacFiles.data,
          filtersData: iacFiles.filtersData,
        })
      } else {
        dispatch.AssetPage.setIacFiles({
          ...iacFiles,
          data: map(
            iacFiles.data?.iac.iacFilesPaths[0]?.iacFiles || [],
            ({ path, count }) => ({
              filePath: path,
              count,
              // @ts-ignore
              issues: rootState.AssetPage.iacFiles.data?.data?.find(
                ({ filePath }) => filePath === path
              )?.issues,
            })
          ),
        })
      }
    },
    async fetchIacFilesDetails(payload, rootState: RootState) {
      const result: {
        data: any
        pagination: any
        filtersData: any
      } = (await sdkClient.issues().filterIssues({
        params: {
          ...payload,
          ...buildBaseIssuesFilters(payload),
          status: payload.status,
          issueKinds: castArray(IssueKind.Iac),
          page: DEFAULT_PAGE_NUMBER,
          pageSize: DEFAULT_FILE_DETAILS_PAGE_SIZE,
          exactPaths: payload.iacFiles?.toString(),
          sorter: toQuerySorter({
            field: IssuesSortBy.SEVERITY,
            order: SortDirection.ASC,
          }),
        },
      })) as any
      const iacFiles = []
      // eslint-disable-next-line no-restricted-syntax
      for (const iacFile of rootState.AssetPage.iacFiles.data.data) {
        if (
          result.data.length > 0 &&
          iacFile.filePath === payload.iacFiles[0]
        ) {
          iacFiles.push({
            filePath: iacFile.filePath,
            count: result.data.length,
            issues: result.data,
          })
        } else if (iacFile.filePath !== payload.iacFiles[0]) {
          iacFiles.push({ ...iacFile })
        }
      }

      dispatch.AssetPage.setIacFiles({
        pagination: rootState.AssetPage.iacFiles.data.pagination,
        data: iacFiles,
      })
    },

    async fetchOpenSourceFiles(filters, rootState: RootState) {
      const {
        sortBy,
        sortDirection,
        page,
        assetId,
        assignees,
        detectorId,
        status,
        packagesNames,
        OpenSourceIssueTypes,
        severity,
        path,
      } = filters
      const openSourceFiles = (await sdkClient.assets().assetDetailsOpenSource({
        data: {
          assetId,
          assignees,
          detectorId,
          sortBy,
          sortDirection,
          status,
          packagesNames,
          OpenSourceIssueTypes,
          severity,
          page,
          path,
          pageSize: 10,
        },
      })) as {
        data: any
        pagination: any
        filtersData: any
      }
      if (filters.updateOnlyFilters) {
        dispatch.AssetPage.setopenSourceFiles({
          ...rootState.AssetPage.openSourceFiles.data,
          filtersData: openSourceFiles.filtersData,
        })
      } else {
        dispatch.AssetPage.setopenSourceFiles({
          ...openSourceFiles,
          data: map(
            openSourceFiles.data?.openSource.OpenSourceFilesPaths[0]
              ?.openSourceFiles || [],
            ({ path: filePath, count }) => ({
              filePath,
              count,
              // @ts-ignore
              issues: rootState.AssetPage.openSourceFiles.data?.data?.find(
                ({ filePath: fp }) => fp === path
              )?.issues,
            })
          ),
        })
      }
    },
    async fetchOpenSourceFilesDetails(payload, rootState: RootState) {
      const result: {
        data: any
        pagination: any
        filtersData: any
      } = (await sdkClient.issues().filterIssues({
        params: {
          ...buildBaseIssuesFilters(payload),
          status: payload.status,
          issueKinds: castArray(IssueKind.Oss),
          page: DEFAULT_PAGE_NUMBER,
          pageSize: DEFAULT_FILE_DETAILS_PAGE_SIZE,
          exactPaths: payload.openSourceFiles?.toString(),
          sorter: toQuerySorter({
            field: IssuesSortBy.SEVERITY,
            order: SortDirection.ASC,
          }),
        },
      })) as any
      const openSourceFiles = []
      // eslint-disable-next-line no-restricted-syntax
      for (const openSourceFile of rootState.AssetPage.openSourceFiles.data
        .data) {
        if (
          result.data.length > 0 &&
          openSourceFile.filePath === payload.openSourceFiles[0]
        ) {
          openSourceFiles.push({
            filePath: openSourceFile.filePath,
            count: result.data.length,
            issues: result.data,
          })
        } else if (openSourceFile.filePath !== payload.openSourceFiles[0]) {
          openSourceFiles.push({ ...openSourceFile })
        }
      }

      dispatch.AssetPage.setopenSourceFiles({
        pagination: rootState.AssetPage.openSourceFiles.data.pagination,
        data: openSourceFiles,
      })
    },

    changeAssetIssuesDetectorSeverity(payload, rootState: RootState) {
      const { detectorId, newSeverity } = payload

      const updateIssuesSeverity = (
        issues,
        detectorIdChanged,
        newDetectorSeverity
      ) => {
        if (!issues) return issues
        return issues.map((issue) => {
          if (issue.detectorId === detectorIdChanged) {
            return {
              ...issue,
              displaySeverity: newDetectorSeverity,
            }
          }
          return issue
        })
      }

      const isSecretIssues = some(
        rootState.AssetPage.secrets.data.data,
        (issue) => issue.detectorId === detectorId
      )

      const isDiscoverIssues = some(
        rootState.AssetPage.discoverIssues.data.data,
        (issue) => issue.detectorId === detectorId
      )

      const isOpenSourceIssues = some(
        flatten(
          rootState.AssetPage.openSourceFiles.data.data.map(
            (file) => file.issues || []
          )
        ),
        (issue) => issue.detectorId === detectorId
      )
      if (isSecretIssues) {
        const issues = rootState.AssetPage.secrets.data.data
        const newIssues = updateIssuesSeverity(issues, detectorId, newSeverity)
        dispatch.AssetPage.upsertSecretsLocally(newIssues)
      } else if (isDiscoverIssues) {
        const issues = rootState.AssetPage.discoverIssues.data.data
        const newIssues = updateIssuesSeverity(issues, detectorId, newSeverity)
        dispatch.AssetPage.upsertDiscoverIssueLocally(newIssues)
      } else if (isOpenSourceIssues) {
        const openSourceFiles = rootState.AssetPage.openSourceFiles.data.data
        const updatedOpenSourceFiles = openSourceFiles.map(
          ({ filePath, issues }) => {
            return {
              filePath,
              issues: updateIssuesSeverity(issues, detectorId, newSeverity),
            }
          }
        )
        dispatch.AssetPage.upsertOpenSourceLocally(updatedOpenSourceFiles)
      } else {
        const iacFiles = rootState.AssetPage.iacFiles.data.data
        const updatedIacFiles = iacFiles.map(({ filePath, issues }) => {
          return {
            filePath,
            issues: updateIssuesSeverity(issues, detectorId, newSeverity),
          }
        })
        dispatch.AssetPage.upsertIacLocally(updatedIacFiles)
      }
    },
  }),
  selectors: (slice, createSelector, _hasProps) => ({
    assetPage() {
      return createSelector(
        // @ts-ignore
        (rootState: RootState) => rootState.AssetPage.assetInfo,
        (rootState: RootState) => rootState.AssetPage.assignableMembers,
        (rootState: RootState, _props) => rootState,
        (assetInfo, assignableMembers, rootState) => {
          return {
            assetInfo: assetInfo.data,
            assignableMembers: assignableMembers.data,
            status: {
              loaded: assetInfo.fetchStatus === 'loaded',
              isRefreshing:
                rootState.loading.effects.AssetPage.fetchAssetInfo > 0,
              isAssignableMembersLoaded:
                assignableMembers.fetchStatus === 'loaded',
              isLoadingAssignableMembers:
                rootState.loading.effects.AssetPage.fetchAssignees > 0,
              pageError:
                assetInfo.fetchStatus === 'none' &&
                !isNil(rootState.error.effects.AssetPage.fetchAssetInfo),
            },
          }
        }
      )
    },
    secretsList() {
      return createSelector(
        (rootState: RootState) => rootState.AssetPage.secrets,
        (rootState: RootState, _props) => rootState,
        (secrets, rootState) => {
          return {
            secrets: secrets.data.data,
            pagination: secrets.data.pagination,
            filtersData: secrets.data.filtersData,
            status: {
              loaded: secrets.fetchStatus === 'loaded',
              isRefreshing:
                rootState.loading.effects.AssetPage.fetchSecretIssues > 0,
              pageError:
                secrets.fetchStatus === 'none' &&
                isNil(rootState.error.effects.AssetPage.fetchSecretIssues),
            },
          }
        }
      )
    },
    secretsSprawl() {
      return createSelector(
        (rootState: RootState) => rootState.AssetPage.secretsSprawl,
        (rootState: RootState, _props) => rootState,
        (secretsSprawl, rootState) => {
          return {
            secrets: secretsSprawl.data.data,
            status: {
              loaded: secretsSprawl.fetchStatus === 'loaded',
              isRefreshing:
                rootState.loading.effects.AssetPage.fetchSecretsSprawl > 0,
              pageError:
                secretsSprawl.fetchStatus === 'none' &&
                !isNil(rootState.error.effects.AssetPage.fetchSecretsSprawl),
            },
          }
        }
      )
    },
    iacFilesList() {
      return createSelector(
        // @ts-ignore
        (rootState: RootState) => rootState.AssetPage.iacFiles,
        (rootState: RootState, _props) => rootState,
        (iacFiles, rootState) => {
          return {
            iacFiles: iacFiles.data.data,
            pagination: iacFiles.data.pagination,
            filtersData: iacFiles.data.filtersData,
            status: {
              loaded: iacFiles.fetchStatus === 'loaded',
              isRefreshing:
                rootState.loading.effects.AssetPage.fetchIacFiles > 0,
              isRefreshingIacFileDetails:
                rootState.loading.effects.AssetPage.fetchIacFilesDetails > 0,
              pageError:
                iacFiles.fetchStatus === 'none' &&
                !isNil(rootState.error.effects.AssetPage.fetchIacFiles),
            },
          }
        }
      )
    },
    openSourceFilesList() {
      return createSelector(
        // @ts-ignore
        (rootState: RootState) => rootState.AssetPage.openSourceFiles,
        (rootState: RootState, _props) => rootState,
        (openSourceFiles, rootState) => {
          return {
            openSourceFiles: openSourceFiles.data.data,
            pagination: openSourceFiles.data.pagination,
            filtersData: openSourceFiles.data.filtersData,
            status: {
              loaded: openSourceFiles.fetchStatus === 'loaded',
              isRefreshing:
                rootState.loading.effects.AssetPage.fetchOpenSourceFiles > 0,
              isRefreshingIacFileDetails:
                rootState.loading.effects.AssetPage
                  .fetchOpenSourceFilesDetails > 0,
              pageError:
                openSourceFiles.fetchStatus === 'none' &&
                !isNil(rootState.error.effects.AssetPage.fetchOpenSourceFiles),
            },
          }
        }
      )
    },
    isDownloadingExport() {
      return createSelector(
        (rootState) => (rootState as RootState).loading,
        (loadingState) => {
          return loadingState.effects.AssetPage.exportIssues
        }
      )
    },
    discoverList() {
      return createSelector(
        (rootState: RootState) => rootState.AssetPage,
        (rootState: RootState, _props) => rootState,
        (assetPageState, rootState) => {
          return {
            discoverIssues: assetPageState.discoverIssues.data.data,
            pagination: assetPageState.discoverIssues.data.pagination,
            filtersData: assetPageState.discoverIssues.data.filtersData,
            status: {
              loaded: assetPageState.discoverIssues.fetchStatus === 'loaded',
              isRefreshing:
                rootState.loading.effects.AssetPage.fetchDiscoverIssues > 0,
              pageError:
                assetPageState.discoverIssues.fetchStatus === 'none' &&
                isNil(rootState.error.effects.AssetPage.fetchDiscoverIssues),
            },
          }
        }
      )
    },
  }),
}
