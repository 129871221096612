/** @jsx jsx */
import { organizationNamePatternAsRegex } from '@spectral/types'
import { Modal, Form, Input } from 'antd'
import { jsx, Flex } from 'theme-ui'
import { PrimaryButtonLarge } from '../../components/buttons/primary'

export const SetExtraDetailsContent = ({ submitOrgName }) => (
  <Form layout="vertical" hideRequiredMark onFinish={submitOrgName}>
    <Form.Item
      style={{ width: '100%' }}
      name="teamName"
      label="Please set your organization name"
      rules={[
        {
          required: true,
          whitespace: true,
          message: 'Please enter a company name.',
        },
        {
          min: 2,
          message: 'Minimum 2 characters.',
        },
        {
          max: 64,
          message: 'Maximum 64 characters.',
        },
        {
          pattern: organizationNamePatternAsRegex,
          message: 'Invalid character.',
        },
      ]}
    >
      <Input size="large" placeholder="Organization Name" />
    </Form.Item>
    <Flex sx={{ justifyContent: 'flex-end' }}>
      <PrimaryButtonLarge
        key="submit"
        isSubmit
        text="Save and go to Spectral"
      />
    </Flex>
  </Form>
)

const SetExtraDetailsModal = ({ submitOrgName }) => {
  return (
    <Modal
      title="One more thing 😎"
      visible
      closable={false}
      keyboard={false}
      maskClosable={false}
      footer={null}
    >
      <SetExtraDetailsContent submitOrgName={submitOrgName} />
    </Modal>
  )
}

export default SetExtraDetailsModal
