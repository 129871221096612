import { auditGithub } from './code/audit-github'
import { auditGitlab } from './code/audit-gitlab'
import { auditLocal } from './code/audit-local'
import { azuredevops } from './code/azuredevops'
import { bitbucketPipeline } from './code/bitbucket-pipeline'
import { circleCI } from './code/circle-ci'
import { codebuild } from './code/codebuild'
import { download } from './download'
import { gcpbuild } from './code/gcpbuild'
import { githubActions } from './code/github-actions'
import { gitlabci } from './code/gitlabci'
import { jenkins } from './code/jenkins'
import { jfrogPipline } from './code/jfrog-pipline'
import { otherCI } from './code/other-ci'
import { travisCI } from './code/travis-ci'
import Sources from './sources.types'
import { OperatingSystem } from './enums'

const wizardSources = {
  download,
  audit: {
    [OperatingSystem.WINDOWS]: [
      auditLocal[OperatingSystem.WINDOWS],
      auditGitlab[OperatingSystem.WINDOWS],
      auditGithub[OperatingSystem.WINDOWS],
    ],
    [OperatingSystem.MACLINUX]: [
      auditLocal[OperatingSystem.MACLINUX],
      auditGitlab[OperatingSystem.MACLINUX],
      auditGithub[OperatingSystem.MACLINUX],
    ],
  },
  mitigate: [
    travisCI.data,
    circleCI.data,
    jenkins.data,
    codebuild.data,
    githubActions.data,
    gitlabci.data,
    bitbucketPipeline.data,
    azuredevops.data,
    gcpbuild.data,
    jfrogPipline.data,
    otherCI.data,
  ],
  firstUse: {
    [OperatingSystem.WINDOWS]: [
      {
        tileKey: 'try-spectral',
        snippets: [
          {
            title:
              'Let\'s start with an example - first, you need to install spectral scanner.</br> You can download the <a href="{baseUrl}/latest/dl/installer?key={teamKey}">Spectral Installer from This Link</a> and run the installer, or you can use your PowerShell with:\n',
            code: `iwr {baseUrl}/latest/ps1?key={teamKey} -useb | iex`,
            type: 'PowerShell',
          },
          {
            title: "Next, let's run a scan with a dummy secret:",
            code: `$ mkdir spectral-test && cd spectral-test
$ echo AKIAIOSFODNN7EXAMPLX > foo.txt
$ spectral scan
## RESULT
/Users/superhero/spectral-test/foo.txt
    0:20 <span style="color: red;">Error</span>        Visible AWS key CLD001`,
            type: 'Terminal',
            info:
              "There are no real code repositories in the above example, so the UI won't present any data.",
          },
        ],
      },
    ],
    [OperatingSystem.MACLINUX]: [
      {
        tileKey: 'try-spectral',
        snippets: [
          {
            title:
              "Let's start with an example - first, you need to install spectral scanner:",
            code: "$ curl -L '{baseUrl}/latest/x/sh?key={teamKey}' | sh",
            type: 'shell',
          },
          {
            title: "Next, let's run a scan with a dummy secret:",
            code: `$ mkdir spectral-test && cd spectral-test
$ echo AKIAIOSFODNN7EXAMPLX > foo.txt
$ $HOME/.spectral/spectral scan
## RESULT
/Users/superhero/spectral-test/foo.txt
    0:20 <span style="color: red;">Error</span>        Visible AWS key CLD001`,
            type: 'Terminal',
            info:
              "There are no real code repositories in the above example, so the UI won't present any data.",
          },
        ],
      },
    ],
  },
} as Sources

export const getIntegrationByModeAndOS = (mode, os, integrationId) => {
  return os
    ? wizardSources[mode][os].find(
        (integration) => integration.tileKey === integrationId
      )
    : wizardSources[mode][integrationId].find(
        (integration) => integration.tileKey === integrationId
      )
}
export const getDownloadSnippet = (operatingSystem) =>
  wizardSources.download[operatingSystem]

export default wizardSources
