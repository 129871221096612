import { Box, Text } from 'theme-ui'
import React from 'react'
import * as Yup from 'yup'
import {
  InputFieldSection,
  InputPasswordFieldSection,
  PanelForm,
  SubmitSection,
} from '../../../../components/panels/content'
import jiraLogo from '../../../../assets/svg/jira.svg'
import { IntegrationSection } from '../../../../components/integrations/integration-sections'
import { validateDomainString } from '../../../../utils/validators'

export const JiraIntegrations = ({
  isActivated,
  setJiraEnableDisable,
  setJiraSettings,
  domain,
  integratorEmail,
  integratorApiToken,
  JiraSettingsError,
}) => {
  return (
    <IntegrationSection
      enabled={isActivated}
      onEnableToggle={setJiraEnableDisable}
      integrationName="Jira"
      headerLogo={jiraLogo}
      headerTitle="Jira Global Integration"
      sectionTitle=""
      sectionDescription="Open Jira tickets directly from Spectral"
      integrationError={JiraSettingsError}
      sectionRenderedContent={() => (
        <Box sx={{ mt: '20px' }}>
          <Text sx={{ fontWeight: 600 }}>Integrate Jira with Spectral</Text>
          <Text>
            1. Choose a Jira user to control the Spectral access to Jira
          </Text>
          <Text>
            Recommended - create a designated user for the Spectral integration
          </Text>
          <Text>
            2. Create API Token for the Jira user{' '}
            <a
              href="https://id.atlassian.com/manage-profile/security/api-tokens"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
          </Text>
          <Text>3. Fill in the following details:</Text>
        </Box>
      )}
    >
      <PanelForm
        onSubmit={setJiraSettings}
        initialValues={{
          domain,
          integratorEmail,
          integratorApiToken,
        }}
        validationSchema={Yup.object({
          integratorEmail: Yup.string()
            .email('Nope. That is not an email address.')
            .required('Cannot integrate without user email.'),
          domain: validateDomainString().required(
            'Cannot integrate without domain.'
          ),
          integratorApiToken: Yup.string().required('Api token required'),
        })}
      >
        <InputFieldSection
          name="domain"
          label="Jira domain"
          description="Enter your Jira domain here"
          placeholder="yourdomain.atlassian.net"
          required
          merge
        />
        <InputFieldSection
          name="integratorEmail"
          label="Jira user email"
          description="Enter the Jira user email here"
          placeholder="integrator@your-domain.com"
          required
          merge
        />
        <InputPasswordFieldSection
          name="integratorApiToken"
          label="Jira API token"
          description="Paste your Jira API Token here"
          placeholder="Jira private API Token"
          required
          merge
        />
        <SubmitSection text="Update" />
      </PanelForm>
    </IntegrationSection>
  )
}
