/** @jsx jsx */
import { Flex, jsx } from 'theme-ui'
import { DownOutlined } from '@ant-design/icons'
import { BaseSeverityTag } from './base-severity-tag'

export const SeverityChangeDropdown = (props) => {
  const { kind } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Flex {...props} sx={{ alignItems: 'center' }}>
      <BaseSeverityTag kind={kind} emphasized />
      <DownOutlined style={{ marginLeft: '4px' }} />
    </Flex>
  )
}
