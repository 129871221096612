/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { jsx, Flex, Box, Text } from 'theme-ui'
import { Button } from 'antd'
import { imageMap } from '../../common/sources/imageMap'

const selected = {
  border: '1px solid #fff',
  boxShadow: 'tileFocused',
}
export const SourcesTile = ({
  className = undefined,
  tileKey,
  title,
  content = '',
  isSelected = false,
  onClick,
}) => (
  <Flex
    className={className}
    sx={{
      alignItems: content ? 'initial' : 'center',
      border: 'outline',
      width: '200px',
      maxHeight: '200px',
      bg: 'white',
      borderRadius: '4px',
      padding: '12px',
      flexDirection: 'column',
      textOverflow: 'ellipsis',
      transition: 'all 0.2s',
      ':hover': selected,
      ...(isSelected && selected),
    }}
  >
    <Flex
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        mb: content ? '10px' : '20px',
      }}
    >
      {imageMap[tileKey] && (
        <Box sx={{ mr: '10px' }}>
          <img
            sx={{ width: '30px', height: '30px' }}
            src={imageMap[tileKey]}
            alt={tileKey}
          />
        </Box>
      )}
      <Box>
        <Text variant="cardTitle">{title}</Text>
      </Box>
    </Flex>
    {content && <Box sx={{ mb: '10px' }}>{content}</Box>}
    <Flex sx={{ mt: 'auto', justifyContent: 'center' }}>
      <Button
        data-e2e-test="setup-button"
        sx={{ fontSize: '0.9em' }}
        onClick={onClick}
      >
        Setup
      </Button>
    </Flex>
  </Flex>
)
