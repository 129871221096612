import { digestInfo, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const codebuild = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'AWS CodeBuild',
    content: 'Integrate as a test or script step',
    tileKey: 'codebuild',
    selfHostedRelevantProviders: [
      selfHostedProviders.gitlabSelfHosted,
      selfHostedProviders.githubEnterprise,
      selfHostedProviders.bitbucketServer,
    ],
    snippets: [
      {
        title:
          'Store your SPECTRAL_DSN in your <a href="https://docs.aws.amazon.com/codebuild/latest/userguide/build-spec-ref.html#secrets-manager-build-spec" target="_blank" rel="noopener noreferrer">CodeBuild SecretsManager integration</a>',
        code: `{dsn}`,
        type: 'yaml',
      },
      {
        title:
          "Here's how your AWS CodeBuild integration would look like with Spectral:",
        code: `version: 0.2
env:
  secrets-manager:
    SPECTRAL_DSN: your-secrets-arn:SPECTRAL_DSN
phases:
  build:
    commands:
      - ...
  post_build:
    commands:
      - curl -L "{baseUrl}/latest/x/sh?dsn=$SPECTRAL_DSN" | sh
      - $HOME/.spectral/spectral scan --ok {options}
`,
        type: 'yaml',
        postDescription: digestInfo,
      },
    ],
  },
}
