/** @jsx jsx */
// eslint-disable-next-line
import { jsx, Flex, Box } from 'theme-ui'
import { Button } from 'antd'

export const PrimaryButtonLarge = ({
  text,
  icon = null,
  shape = null,
  isSubmit = false,
  loading = false,
  onClick = null,
  disabled = false,
  className = null,
  block = false,
  danger = false,
  e2eData = null,
}) => (
  <Button
    data-e2e-test={e2eData || text}
    type="primary"
    size="large"
    shape={shape}
    htmlType={isSubmit ? 'submit' : 'button'}
    loading={loading}
    onClick={onClick}
    disabled={disabled}
    className={className}
    block={block}
    danger={danger}
  >
    <Flex
      sx={{ flexDirection: 'row', mx: 2, justifyContent: block && 'center' }}
    >
      {icon && icon}
      <Box ml={icon && 3}>{text}</Box>
    </Flex>
  </Button>
)

export const PrimaryButtonMedium = ({
  text,
  icon = null,
  shape = null,
  isSubmit = false,
  onClick = null,
  disabled = false,
  danger = false,
  loading = false,
}) => (
  <Button
    type="primary"
    size="middle"
    loading={loading}
    shape={shape}
    htmlType={isSubmit ? 'submit' : 'button'}
    onClick={onClick}
    disabled={disabled}
    danger={danger}
  >
    <Flex sx={{ flexDirection: 'row', mx: 2, alignItems: 'center' }}>
      {icon}
      <Box ml={icon ? 3 : 0}>{text}</Box>
    </Flex>
  </Button>
)

export const PrimaryButtonSmall = ({
  text,
  icon = null,
  isSubmit = false,
  onClick = null,
  disabled = false,
  danger = false,
}) => (
  <Button
    type="primary"
    size="small"
    htmlType={isSubmit ? 'submit' : 'button'}
    onClick={onClick}
    disabled={disabled}
    danger={danger}
  >
    <Flex sx={{ flexDirection: 'row', mx: 2, alignItems: 'center' }}>
      {icon}
      <Box ml={icon ? 3 : 0}>{text}</Box>
    </Flex>
  </Button>
)
