import { Engines } from '@spectral/types'

export enum SecretsEngineTags {
  Base = 'base',
  Audit = 'audit',
  Audit3 = 'audit3',
}

export enum IaCEngineTags {
  IaC = 'iac',
}

export const EnginesDefaultTags: Record<Engines, string> = {
  [Engines.Secrets]: SecretsEngineTags.Base,
  [Engines.Spectral]: SecretsEngineTags.Base,
  [Engines.IaC]: IaCEngineTags.IaC,
  [Engines.OpenSource]: null,
}
