import { useState, useCallback, useMemo } from 'react'
import merge from 'lodash/merge'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'

function useQuerystring() {
  const history = useHistory()
  const location = useLocation()
  const [currentQuery, setCurrent] = useState<any>({})

  useMemo(() => {
    const newCurrent = qs.parse(location.search) as any
    setCurrent(newCurrent)
  }, [location])

  const setQuery = useCallback(
    (newValues, shouldReplace = false) => {
      const params = {
        search: qs.stringify(newValues),
      }
      if (shouldReplace) {
        history.replace(params)
      } else {
        history.push(params)
      }
    },
    [history]
  )

  const updateQuery = (newValues, shouldReplace = false) => {
    const params = {
      search: qs.stringify(merge({}, currentQuery, newValues)),
    }
    if (shouldReplace) {
      history.replace(params)
    } else {
      history.push(params)
    }
  }

  return [currentQuery, setQuery, updateQuery]
}

export default useQuerystring
