import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import { CustomWebhookIntegration } from '../../../../blocks/settings/integrations/custom-webhook/custom-webhook'

export const CustomWebhookIntegrationConnected = () => {
  const { customWebhookSettings } = useSelector(select.Teams.teamSettings)
  const { isTestInProgress } = useSelector(
    select.Settings.customWebhookSettings
  )
  const dispatch = useDispatch()
  return (
    <CustomWebhookIntegration
      isActivated={customWebhookSettings.enabled}
      toggleCustomWebhook={dispatch.Teams.setCustomWebhookIntegrationStatus}
      setCustomWebhookSettings={dispatch.Teams.setCustomWebhookSettings}
      webhookUrl={customWebhookSettings.webhookUrl}
      signatureToken={customWebhookSettings.signatureToken}
      onTestClicked={dispatch.Settings.testCustomWebhook}
      isTestInProgress={isTestInProgress}
    />
  )
}
