/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PlanType } from '@spectral/types'
import { Dispatch, select } from '../../redux/store'
import { Page } from '../../components/page'
import Report from '../../blocks/reports/view'
import { paths } from '../../routes'
import tracker from '../../common/track'
import RestrictedArea from '../restricted-area/restricted-area'

export default () => {
  const dispatch: Dispatch = useDispatch()
  const { isLoading, isError, data, filters } = useSelector(
    select.Reports.reportsPage
  )
  const orgTeams = useSelector(select.Teams.orgTeams)
  const hasTeamPlanOrUp = useSelector(select.Plan.hasTeamPlanOrUp)

  useEffect(() => {
    if (hasTeamPlanOrUp) dispatch.Reports.fetchReports()
    dispatch.Teams.fetchOrgTeams()
  }, [dispatch.Reports, hasTeamPlanOrUp, dispatch.Teams])

  const onFilterChanged = useCallback(
    async (filterValue) => {
      tracker.event('REPORTS_FILTER', { filters: filterValue })
      await dispatch.Reports.setFilter(filterValue)
      await dispatch.Reports.fetchReports()
    },
    [dispatch.Reports]
  )

  return (
    <Page
      name="REPORTS"
      title="Reports"
      isError={isError}
      isLoading={isLoading}
    >
      <RestrictedArea
        minimumPlan={PlanType.Team}
        featureDisplayName="Reports and Insights"
      >
        <Box sx={{ p: '40px' }}>
          <span className="e2e-test-reports-page-title" />
          <Report
            data={data}
            orgTeams={orgTeams}
            filters={filters}
            links={{ sources: paths.closed.sources }}
            onFilterChanged={onFilterChanged}
          />
        </Box>
      </RestrictedArea>
    </Page>
  )
}
