import { ModelConfig } from '@rematch/core'
import { CurrentTeamResponse, Team } from '@spectral/backend-sdk'
import { ActivityItem, LoginType } from '@spectral/types'
import L, { isEmpty } from 'lodash'
import tracker from '../../../common/track'
import { teamPersistence } from '../../persistence'
import { FetchStatus, RootState, sdkClient } from '../../store'
import { empty, loaded } from '../../utils'

type TeamsState = {
  current: {
    data: Team
    fetchStatus: FetchStatus
  }
  settings: {
    fetchStatus: FetchStatus
    data: {
      notification: {
        slack: {
          slackWebhookUrl: string | null
          enabled: boolean
        }
        msTeams: {
          msTeamsWebhookUrl: string | null
          enabled: boolean
        }
      }
      integrations: {
        jira: {
          domain: string | null
          integratorEmail: string | null
          integratorApiToken: string | null
          isActivated: boolean
        }
        pd: {
          enabled: boolean
          routingServiceKey: string | null
        }
        monday: {
          domain: string | null
          enabled: boolean
          token: string | null
        }
      }
      apiKey: string | null
    }
  }

  assetMapping: {
    fetchStatus: FetchStatus
    data: {
      assetMapping: {
        mapping: Array<any>
        orgTeams: Array<string>
      }
    }
  }
  activity: { fetchStatus: FetchStatus; data: Array<ActivityItem> }
  orgTeams: any
  agentConfigSuccess: boolean
}

const initialState: TeamsState = {
  current: empty({
    pid: '',
    key: '',
    name: '',
    members: [],
    canSeeGitIssuesOnly: false,
    agentConfig: [],
    loginType: LoginType.Personal,
    optedInFeatures: null,
  }),
  settings: empty({
    apiKey: null,
    notification: {
      slack: {
        slackWebhookUrl: null,
        enabled: false,
      },
      msTeams: {
        msTeamsWebhookUrl: null,
        enabled: false,
      },
    },
    integrations: {
      jira: {
        domain: null,
        integratorApiToken: null,
        integratorEmail: null,
        isActivated: false,
      },
      pd: {
        enabled: false,
        routingServiceKey: null,
      },
      monday: {
        domain: null,
        enabled: false,
        token: null,
      },
    },
  }),
  assetMapping: empty(null),
  activity: empty([]),
  orgTeams: empty([]),
  agentConfigSuccess: false,
}
export const teams: ModelConfig<TeamsState> = {
  state: initialState,
  reducers: {
    setCurrentTeam(state: TeamsState, team: Team) {
      return { ...state, current: loaded(team), error: null }
    },
    setSettings(state: TeamsState, settings) {
      return { ...state, settings: loaded(settings) }
    },
    setOrgTeams(state: TeamsState, orgTeams) {
      return { ...state, orgTeams: loaded(orgTeams) }
    },
    setAssetMapping(state: TeamsState, mapping) {
      return { ...state, assetMapping: loaded(mapping) }
    },
    setActivity(state: TeamsState, activity) {
      return { ...state, activity: loaded(activity) }
    },
    updateAgentConfigSuccess(state: TeamsState, success) {
      return { ...state, agentConfigSuccess: success }
    },
    setAgentConfig(state: TeamsState, agentConfig) {
      return {
        ...state,
        current: loaded({ ...state.current.data, agentConfig }),
      }
    },
    reset() {
      return initialState
    },
  },
  effects: (dispatch: any) => ({
    async fetchActivity() {
      const activity = await sdkClient.teams().getActivity()
      dispatch.Teams.setActivity(activity)
    },
    async fetchCurrentTeam() {
      const currentTeam: CurrentTeamResponse = await sdkClient
        .teams()
        .currentTeam()
      teamPersistence.persist({ pid: currentTeam.team.pid })
      dispatch.Plan.setPlanDetails(currentTeam.team.plan)
      // Plan data saved in plan model state
      dispatch.Teams.setCurrentTeam(L.omit(currentTeam.team, 'plan'))
    },
    async updateTeamName(payload: any) {
      await sdkClient.teams().updateSettings({
        data: {
          teamData: { name: payload.teamName },
        },
      })
      dispatch.Auth.me()
    },
    async updateAuthSettings(payload: any) {
      const response = await sdkClient.teams().setAuthSettings({
        data: {
          ...payload,
        },
      })

      return response
    },
    async updateAgentConfig(payload: any) {
      const { agentConfig, assetType } = payload

      dispatch.Teams.updateAgentConfigSuccess(null)

      try {
        await sdkClient.teams().setAgentConfig({
          data: {
            config: agentConfig,
            assetType,
          },
        })
        dispatch.Teams.updateAgentConfigSuccess(true)
        dispatch.Teams.fetchCurrentTeam()
        dispatch.Teams.updateAgentConfigSuccess(null)
      } catch (err) {
        dispatch.Teams.updateAgentConfigSuccess(false)
        throw err
      }
    },
    async resetAgentConfig(payload: any) {
      const { configurationType, assetType } = payload

      const { data } = (await sdkClient.teams().resetAgentConfig({
        data: {
          configurationType,
          assetType,
        },
      })) as {
        data: any
      }
      dispatch.Teams.setAgentConfig(data)
      dispatch.Teams.fetchCurrentTeam()
    },
    async updateMembersSeeAllAssets(payload: any) {
      const { membersSeeAllAssets } = payload
      await sdkClient.teams().setMembersSeeAllAssets({
        data: {
          membersSeeAllAssets,
        },
      })
      dispatch.Auth.refetchUser()
      dispatch.Teams.fetchCurrentTeam()
    },
    async optInFeatures(features) {
      await sdkClient.teams().optInFeatures({
        data: { features },
      })
      dispatch.Teams.fetchCurrentTeam()
    },
    async generateKey(_payload) {
      try {
        await sdkClient.teams().generateKey()
        dispatch.Teams.fetchCurrentTeam()
      } catch (error) {
        // TODO: Handle error
        console.log(error)
      }
    },
    async setMondayIntegrationStatus(payload: any) {
      try {
        const status = payload.Monday_integrations_checkbox
        await sdkClient.teams().setSettings({
          data: {
            integrations: {
              monday: {
                enabled: status,
              },
            },
          },
        })
        tracker.globalIntegrationStatus('monday', status)
        dispatch.Teams.fetchSettings()
      } catch (e) {
        // TODO: Handle
        console.log(e)
      }
    },
    async setJiraIntegrationStatus(payload: any) {
      try {
        await sdkClient.teams().setSettings({
          data: {
            integrations: {
              jira: {
                isActivated: payload.Jira_integrations_checkbox,
              },
            },
          },
        })
        tracker.globalIntegrationStatus(
          'jira',
          payload.Jira_integrations_checkbox
        )
        dispatch.Teams.fetchSettings()
      } catch (e) {
        // TODO: Handle
        console.log(e)
      }
    },
    async setSlackIntegrationStatus(payload: any) {
      try {
        await sdkClient.teams().setSettings({
          data: {
            notification: {
              slack: {
                enabled: payload.Slack_integrations_checkbox,
              },
            },
          },
        })
        tracker.globalIntegrationStatus(
          'slack',
          payload.Slack_integrations_checkbox
        )
        dispatch.Teams.fetchSettings()
      } catch (e) {
        // TODO: Handle
        console.log(e)
      }
    },
    async setMsTeamsIntegrationStatus(payload: any) {
      try {
        await sdkClient.teams().setSettings({
          data: {
            notification: {
              msTeams: {
                enabled: payload.MsTeams_integrations_checkbox,
              },
            },
          },
        })
        tracker.globalIntegrationStatus(
          'Microsoft Teams',
          payload.MsTeams_integrations_checkbox
        )
        dispatch.Teams.fetchSettings()
      } catch (e) {
        // TODO: Handle
        console.log(e)
      }
    },
    async setPagerDutyIntegrationStatus(payload: any) {
      await sdkClient.teams().setPagerDutySettings({
        data: {
          enabled: payload,
        },
      })
      tracker.globalIntegrationStatus('pd', payload)
      dispatch.Teams.fetchSettings()
    },
    async setCustomWebhookIntegrationStatus(
      payload: any,
      _rootState: RootState
    ) {
      await sdkClient.teams().setCustomWebhookSettings({
        data: {
          enabled: payload,
        },
      })
      tracker.globalIntegrationStatus('CustomWebhook', payload)
      dispatch.Teams.fetchSettings()
    },
    async setEventsWebhookIntegrationStatus(
      payload: any,
      _rootState: RootState
    ) {
      await sdkClient.teams().setEventsWebhookStatus({
        data: {
          enabled: payload,
        },
      })
      tracker.globalIntegrationStatus('CustomWebhook', payload)
      dispatch.Teams.fetchSettings()
    },
    async setMondaySettings(payload: any) {
      try {
        await sdkClient.teams().setMondayIntegration({
          data: {
            integrations: {
              monday: {
                token: payload.token,
              },
            },
          },
        })
        dispatch.Teams.fetchSettings()
      } catch (e) {
        throw new Error(`Failed to set Monday integration`)
      }
    },
    async setJiraSettings(payload: any) {
      try {
        await sdkClient.teams().setJiraIntegration({
          data: {
            integrations: {
              jira: {
                domain: payload.domain,
                integratorEmail: payload.integratorEmail,
                integratorApiToken: payload.integratorApiToken,
              },
            },
          },
        })
        dispatch.Teams.fetchSettings()
      } catch (e) {
        throw new Error(`Failed to set Jira integration`)
      }
    },
    async updateOrgTeamJiraProject(
      { projectId, orgTeamId },
      _rootState: RootState
    ) {
      try {
        await sdkClient.teams().updateOrgTeams({
          data: { jira: { projectId, orgTeamId } },
        })
        dispatch.Teams.fetchCurrentTeam()
      } catch (error) {
        dispatch.Teams.setError(error)
      }
    },

    async setSlackUrl(payload: any) {
      try {
        await sdkClient.teams().setSettings({
          data: {
            notification: {
              slack: {
                slackWebhookUrl: payload.slackWebhookUrl
                  ? payload.slackWebhookUrl
                  : null,
                enabled: payload.enabled,
              },
            },
          },
        })
        dispatch.Teams.fetchSettings()
      } catch (e) {
        // TODO: Handle
        console.log(e)
      }
    },
    async setMsTeamsUrl(payload: any) {
      try {
        await sdkClient.teams().setSettings({
          data: {
            notification: {
              msTeams: {
                msTeamsWebhookUrl: payload.msTeamsWebhookUrl
                  ? payload.msTeamsWebhookUrl
                  : null,
                enabled: payload.enabled,
              },
            },
          },
        })
        dispatch.Teams.fetchSettings()
      } catch (e) {
        // TODO: Handle
        console.log(e)
      }
    },
    async setPagerDutySettings(payload: any) {
      await sdkClient.teams().setSettings({
        data: {
          integrations: {
            pd: payload,
          },
        },
      })
      dispatch.Teams.fetchSettings()
    },
    async setCustomWebhookSettings(payload: any) {
      await sdkClient.teams().setSettings({
        data: {
          integrations: {
            customWebhook: payload,
          },
        },
      })
      dispatch.Teams.fetchSettings()
    },
    async setEventsWebhookSettings(payload: any) {
      await sdkClient.teams().setSettings({
        data: {
          eventsWebhook: payload,
        },
      })
      dispatch.Teams.fetchSettings()
    },
    async setTeamSettings(payload: any) {
      try {
        await sdkClient.teams().setSettings({
          data: {
            notification: {
              email: {
                enabled: payload.enabled,
                enableWeeklyDigestReport: payload.enableWeeklyDigestReport,
                enableDailyDigestReport: payload.enableDailyDigestReport,
              },
              slack: {
                slackIgnoreActionEnabled: payload.slackIgnoreActionEnabled,
              },
              msTeams: {
                msTeamsIgnoreActionEnabled: payload.msTeamsIgnoreActionEnabled,
              },
            },
          },
        })
        dispatch.Teams.fetchSettings()
      } catch (e) {
        console.log(e)
      }
    },
    async fetchSettings(_payload, rootState) {
      try {
        const settings: any = await sdkClient.teams().getSettings()
        if (!settings || !settings.notification)
          throw new Error('Loading settings failed')
        dispatch.Teams.setSettings(settings)
      } catch (e) {
        // TODO: Handle error
        console.log(e)
      }
    },
    async fetchOrgTeams() {
      try {
        const { orgTeams }: any = await sdkClient.teams().getOrgTeams()
        dispatch.Teams.setOrgTeams(orgTeams)
      } catch (e) {
        // TODO: Handle error
        console.log(e)
      }
    },
    async deleteOrgTeams({ orgTeams }) {
      try {
        await sdkClient.teams().deleteOrgTeams({ data: { orgTeams } })
        dispatch.Teams.fetchOrgTeams()
        dispatch.Teams.fetchCurrentTeam()
        dispatch.Teams.fetchAssetMapping()
      } catch (e) {
        // TODO: Handle error
        console.log(e)
      }
    },
    async updateOrgTeams({ orgTeams }) {
      try {
        await sdkClient.teams().updateOrgTeams({ data: { orgTeams } })
        dispatch.Teams.fetchOrgTeams()
        dispatch.Teams.fetchCurrentTeam()
        dispatch.Teams.fetchAssetMapping()
      } catch (e) {
        // TODO: Handle error
        console.log(e)
      }
    },
    async changeRuleSeverity({ detectorId, newSeverity, oldSeverity }) {
      await sdkClient.teams().setSettings({
        data: {
          customSeverity: { [detectorId]: { newSeverity, oldSeverity } },
        },
      })
      dispatch.Teams.fetchSettings()
      dispatch.Auth.refetchUser()
    },
    async setTeamAssetMapping(mapping: any) {
      const updatedMappings = mapping.assetMapping.map((singleMapping) => ({
        assetId: singleMapping.assetId,
        orgTeamKey: singleMapping.orgTeamKey,
      }))
      await sdkClient.teams().updateAssetMapping({
        data: { assetMapping: updatedMappings },
      })
      dispatch.Teams.fetchAssetMapping()
      dispatch.Auth.refetchUser()
    },
    async fetchAssetMapping(_payload, rootState) {
      const assetMapping = await sdkClient.teams().getAssetMapping()
      dispatch.Teams.setAssetMapping(assetMapping)
    },
    async fetchUsage(_payload) {
      const usage: any = await sdkClient.teams().getUsage()
      dispatch.Plan.setUsage(usage)
    },
  }),
  selectors: (slice, createSelector, _hasProps) => ({
    assetMapping() {
      return (state) => {
        return {
          assetMapping: state.Teams.assetMapping.data,
          isLoading:
            state.Teams.assetMapping.fetchStatus === 'none' &&
            (state.loading.effects.Teams.fetchAssetMapping as unknown) > 0,
        }
      }
    },
    teamSettings() {
      return slice((teamsState) => {
        return {
          slackSettings: teamsState.settings?.data.notification?.slack || {},
          msTeamsSettings:
            teamsState.settings?.data.notification?.msTeams || {},
          jiraSettings: teamsState.settings?.data.integrations?.jira || {},
          pdSettings: teamsState.settings?.data.integrations?.pd || {},
          customWebhookSettings:
            teamsState.settings?.data.integrations?.customWebhook || {},
          eventsWebhookSettings: teamsState.settings?.data.eventsWebhook || {},
          mondaySettings: teamsState.settings?.data.integrations?.monday || {},
          key: teamsState.current?.data?.key || null,
          apiKey: teamsState.settings?.data.apiKey,
          emailSettings: teamsState.settings?.data.notification?.email || {},
        }
      })
    },
    current() {
      return slice((teamsState) => {
        return teamsState.current.data
      })
    },
    orgTeams() {
      return createSelector(
        (rootState) => (rootState as RootState).Teams,
        (teamsState) => {
          return teamsState.orgTeams.data
        }
      )
    },
    orgTeamsSettingsPage() {
      return createSelector(
        (rootState) => (rootState as RootState).Teams,
        (rootState) => (rootState as RootState).loading,
        (teamsState, loadingState) => {
          const { orgTeams } = teamsState
          return {
            isSlackActive:
              teamsState.settings?.data.notification?.slack?.enabled || false,
            isMsTeamsActive:
              teamsState.settings?.data.notification?.msTeams?.enabled || false,
            isJiraActive:
              teamsState.settings?.data.integrations?.jira?.isActivated ||
              false,
            isPagerDutyActive:
              teamsState.settings?.data.integrations?.pd?.enabled || false,
            isMondayEnabled:
              teamsState.settings?.data.integrations?.monday?.enabled || false,
            orgTeams: orgTeams.data,
            isLoading:
              orgTeams.fetchStatus === 'none' &&
              (loadingState.effects.Teams.fetchOrgTeams as unknown) > 0,
          }
        }
      )
    },
    settingsPageStatus() {
      return (state) => ({
        isLoadingSettings:
          state.loading.effects.Teams.fetchSettings > 0 &&
          !state.Teams.settings.data,
        isLoadingSetSlackURL: state.loading.effects.Teams.setSlackUrl > 0,
        JiraSettingsError: state.error.effects.Teams.setJiraSettings || null,
        MondaySettingsError:
          state.error.effects.Teams.setMondaySettings || null,
      })
    },
    activity() {
      return (state) => {
        return {
          isLoading: state.loading.effects.Teams.fetchActivity > 0,
          loaded: state.Teams.activity.fetchStatus === 'loaded',
          activity: state.Teams.activity.data,
        }
      }
    },
    teamPageStatus() {
      return (state) => {
        return {
          isLoading: !state.Teams.current.data,
          pageError: state.error.effects.Teams.fetchCurrentTeam,
        }
      }
    },
    agentConfigStatus() {
      return (state) => {
        return {
          isLoading: state.loading.effects.Teams.updateAgentConfig !== 0,
          error: state.error.effects.Teams.updateAgentConfig
            ? JSON.parse(state.error.effects.Teams.updateAgentConfig)
            : null,
          success: state.Teams.agentConfigSuccess,
        }
      }
    },
    isSavingFeaturesOptIn() {
      return (state) => state.loading.effects.Teams.optInFeatures !== 0
    },
    isTeamLoaded() {
      return (state) => state.Teams.current.fetchStatus !== 'none'
    },
    assignableMembers: () => {
      return createSelector(
        (rootState) => (rootState as RootState).Teams,
        (teamsState) => {
          return teamsState.current.data.members
        }
      )
    },
    isSSOConfigured() {
      return slice((teamsState) => {
        return !isEmpty(teamsState.current.data.authSettings?.saml)
      })
    },
  }),
}
