import React from 'react'
import { Text, Box } from 'theme-ui'
import {
  PanelForm,
  ContentPanel,
  ContentPanelHeader,
  SwitchFieldSection,
  SubmitSection,
} from '../../components/panels/content'

export const ProfileSettings = ({
  isEmailVerified,
  isTeamEmailEnabled,
  isTeamDailyDigestReportEnabled,
  isTeamWeeklyDigestReportEnabled,
  settings: {
    onSettingsSave,
    email: {
      isEmailNotificationsEnabled,
      isDailyEmailReportEnabled,
      isWeeklyEmailReportEnabled,
    },
  },
}) => (
  <Box>
    <ContentPanel>
      <ContentPanelHeader>Notifications Settings</ContentPanelHeader>
      <PanelForm
        onSubmit={(vals) =>
          onSettingsSave({
            notification: {
              email: {
                enabled: vals.emailNotificationEnabled,

                subscribeToDailyDigestReport: vals.dailyEmailReportEnabled,
                subscribeToWeeklyDigestReport: vals.weeklyEmailReportEnabled,
              },
            },
          })
        }
        initialValues={{
          emailNotificationEnabled:
            isEmailVerified &&
            isTeamEmailEnabled &&
            isEmailNotificationsEnabled,
          dailyEmailReportEnabled:
            isTeamDailyDigestReportEnabled && isDailyEmailReportEnabled,
          weeklyEmailReportEnabled:
            isTeamWeeklyDigestReportEnabled && isWeeklyEmailReportEnabled,
        }}
      >
        <SwitchFieldSection
          name="emailNotificationEnabled"
          label="Email on new issues"
          description="Send me alert emails when new issues are discovered"
          disabled={!isEmailVerified || !isTeamEmailEnabled}
          controlNote={
            !isEmailVerified ? (
              <Text color="red" sx={{ fontSize: '14px' }}>
                Email notifications are disabled. Please verify your email
                first.
              </Text>
            ) : null
          }
          tooltipNote={
            !isTeamEmailEnabled
              ? 'Configuration is restricted by admin. Contact team admin for more information'
              : null
          }
        />
        <SwitchFieldSection
          name="dailyEmailReportEnabled"
          label="Subscribe to Daily Report"
          description="Send me a daily reports of the team's issues"
          disabled={!isEmailVerified || !isTeamDailyDigestReportEnabled}
          tooltipNote={
            !isTeamDailyDigestReportEnabled
              ? 'Configuration is restricted by admin. Contact team admin for more information'
              : null
          }
        />
        <SwitchFieldSection
          name="weeklyEmailReportEnabled"
          label="Subscribe to Weekly Report"
          description="Send me a weekly reports of the team's issues"
          disabled={!isEmailVerified || !isTeamWeeklyDigestReportEnabled}
          tooltipNote={
            !isTeamWeeklyDigestReportEnabled
              ? 'Configuration is restricted by admin. Contact team admin for more information'
              : null
          }
        />
        <SubmitSection text="Save settings" />
      </PanelForm>
    </ContentPanel>
  </Box>
)
