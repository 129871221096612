import { scannerLocationInstruction, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const auditLocal = {
  mode: 'audit',
  component: SourceIntegrationComponent.SnippetWindow,
  'MAC/Linux': {
    title: 'Local repos',
    content: 'Audit any local Git repo',
    tileKey: 'other',
    selfHostedRelevantProviders: [
      selfHostedProviders.gitlabSelfHosted,
      selfHostedProviders.githubEnterprise,
      selfHostedProviders.bitbucketServer,
    ],
    snippets: [
      {
        title: 'Go to your cloned repo',
        code: '$ cd my-repo',
        type: 'shell',
      },
      {
        title: 'Scan with Spectral and use your DSN',
        code: '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral scan {options}',
        type: 'shell',
      },
      {
        title: 'OR, if you want to scan multiple repos at once:',
        code: '$ cd my-projects',
        type: 'shell',
      },
      {
        title: 'Scan with Spectral "local" mode and use your DSN',
        code: '$ SPECTRAL_DSN={dsn} $HOME/.spectral/spectral local {options}',
        type: 'shell',
      },
    ],
    instructions: [scannerLocationInstruction],
  },
  Windows: {
    title: 'Local repos',
    content: 'Audit any local Git repo',
    tileKey: 'other',
    selfHostedRelevantProviders: [
      selfHostedProviders.gitlabSelfHosted,
      selfHostedProviders.githubEnterprise,
      selfHostedProviders.bitbucketServer,
    ],
    snippets: [
      {
        title: 'Go to your cloned repo',
        code: '$ cd my-repo',
        type: 'shell',
      },
      {
        title: 'Scan with Spectral and use your DSN',
        code: '$ spectral.exe scan --dsn {dsn} {options}',
        type: 'shell',
      },
      {
        title: 'OR, if you want to scan multiple repos at once:',
        code: '$ cd my-projects',
        type: 'shell',
      },
      {
        title: 'Scan with Spectral "local" mode and use your DSN',
        code: '$ spectral.exe local --dsn {dsn} {options}',
        type: 'shell',
      },
    ],
    instructions: [scannerLocationInstruction],
  },
}
