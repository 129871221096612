export default function hubspotInitialize() {
  if (!window) {
    return
  }
  const hubspotId = process.env.REACT_APP_HS_ID
  if (hubspotId) {
    window.hsConversationsSettings = {
      loadImmediately: false,
    }
    const scriptEl = document.createElement('script')
    scriptEl.innerHTML = `!function(e,t){if(!document.getElementById(e)){var c=document.createElement("script");c.src="https://js.hs-analytics.net/analytics/1678875000000/${hubspotId}.js",c.type="text/javascript",c.id=e;var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(c,n)}}("hs-analytics");!function(t,e,r){if(!document.getElementById(t)){var n=document.createElement("script");for(var a in n.src="https://js.usemessages.com/conversations-embed.js",n.type="text/javascript",n.id=t,r)r.hasOwnProperty(a)&&n.setAttribute(a,r[a]);var i=document.getElementsByTagName("script")[0];i.parentNode.insertBefore(n,i)}}("hubspot-messages-loader",0,{"data-loader":"hs-scriptloader","data-hsjs-portal":${hubspotId},"data-hsjs-env":"prod","data-hsjs-hublet":"na1"});!function(t,e,r){if(!document.getElementById(t)){var n=document.createElement("script");for(var a in n.src="https://js.hsadspixel.net/fb.js",n.type="text/javascript",n.id=t,r)r.hasOwnProperty(a)&&n.setAttribute(a,r[a]);var i=document.getElementsByTagName("script")[0];i.parentNode.insertBefore(n,i)}}("hs-ads-pixel-${hubspotId}",0,{"data-ads-portal-id":${hubspotId},"data-ads-env":"prod","data-loader":"hs-scriptloader","data-hsjs-portal":${hubspotId},"data-hsjs-env":"prod","data-hsjs-hublet":"na1"});var _hsp=window._hsp=window._hsp||[];_hsp.push(['addEnabledFeatureGates',[]]);!function(t,e,r){if(!document.getElementById(t)){var n=document.createElement("script");for(var a in n.src="https://js.hs-banner.com/v2/${hubspotId}/banner.js",n.type="text/javascript",n.id=t,r)r.hasOwnProperty(a)&&n.setAttribute(a,r[a]);var i=document.getElementsByTagName("script")[0];i.parentNode.insertBefore(n,i)}}("cookieBanner-${hubspotId}",0,{"data-cookieconsent":"ignore","data-hs-ignore":true,"data-loader":"hs-scriptloader","data-hsjs-portal":${hubspotId},"data-hsjs-env":"prod","data-hsjs-hublet":"na1"});`
    document.head.appendChild(scriptEl)
  }
}
