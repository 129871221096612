import { toDisplayNumber } from '@spectral/types'
import React from 'react'

type Props = {
  total: number
}

const FilterTotalDisplay = ({ total }: Props) => {
  const displayedTotal = toDisplayNumber(total)
  return <span>{displayedTotal}</span>
}

export default FilterTotalDisplay
