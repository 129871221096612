import { notification } from 'antd'

export default async (testRoutingServiceKey, key) => {
  const isTestSuccessful = await testRoutingServiceKey({
    routingServiceKey: key,
  })
  if (isTestSuccessful) {
    notification.success({
      message: 'Success!',
      description: `We've sent a dummy issue to your PagerDuty service`,
    })
  } else {
    notification.error({
      message: 'Failed',
      description: `We were unable to send a dummy request to your PagerDuty service. Make sure the key is correct.`,
    })
  }
}
