import React from 'react'
import { AssetDisplayStatus } from '@spectral/types'
import { ReactComponent as Disabled } from '../../assets/svg/small-cubes/disabled.svg'
import Cube from '../cube/cube'

type Props = {
  type: AssetDisplayStatus
  disabled?: boolean
}

const map = {
  [AssetDisplayStatus.Informational]: (
    <Cube view={AssetDisplayStatus.Informational} size={17} />
  ),
  [AssetDisplayStatus.Low]: <Cube view={AssetDisplayStatus.Low} size={17} />,
  [AssetDisplayStatus.Medium]: (
    <Cube view={AssetDisplayStatus.Medium} size={17} />
  ),
  [AssetDisplayStatus.High]: <Cube view={AssetDisplayStatus.High} size={17} />,
  [AssetDisplayStatus.Critical]: (
    <Cube view={AssetDisplayStatus.Critical} size={17} />
  ),
  [AssetDisplayStatus.NoIssues]: (
    <Cube view={AssetDisplayStatus.NoIssues} size={17} />
  ),
  [AssetDisplayStatus.NotActive]: (
    <Cube view={AssetDisplayStatus.NotActive} size={17} />
  ),
}
const CubeIcon = ({ type, disabled }: Props) => {
  if (disabled) {
    return <Disabled />
  }
  return map[type]
}

export default CubeIcon
