import { ModelConfig } from '@rematch/core'
import { sdkClient, RootState } from '../../store'
import tracker from '../../../common/track'

type MondayState = {
  board: {
    id: string
    name: string
    columns: any[]
    groups: any[]
  }[]
  workspaces: {
    id: string
    name: string
    boards: {
      id: string
      name: string
    }[]
  }[]
  isModalVisible: boolean
}

const INITIAL_STATE = {
  workspaces: [],
  board: null,
  isModalVisible: false,
}

export const monday: ModelConfig<MondayState> = {
  state: INITIAL_STATE,
  reducers: {
    setMondayWorkspaces(state: MondayState, data: any) {
      return { ...state, workspaces: data }
    },
    setMondayCurrentBoard(state: MondayState, data: any) {
      return { ...state, board: data }
    },
    setIsModalVisible(state: MondayState, isVisible: any) {
      return { ...state, isModalVisible: isVisible }
    },
    reset() {
      return INITIAL_STATE
    },
  },
  effects: (dispatch: any) => ({
    async fetchWorkspaces(payload, rootState: RootState) {
      const { enabled } = rootState.Teams.settings?.data.integrations?.monday
      if (!enabled) return
      const data = (await sdkClient
        .integrations()
        .getMondayBoards({ params: payload })) as any
      if (data.errorCode === 'integrationFailed') {
        dispatch.Monday.setMondayWorkspaces([])
        throw new Error('Failed to fetch boards')
      } else {
        tracker.mondayBoards({
          numBoards: data.map((ws) => ws.boards).flat().length,
        })
        dispatch.Monday.setMondayWorkspaces(data)
      }
    },
    async fetchBoardById(payload, rootState: RootState) {
      const { enabled } = rootState.Teams.settings?.data.integrations?.monday
      if (!enabled) return
      const data = (await sdkClient
        .integrations()
        .getMondayBoardById({ params: payload })) as any
      if (data.errorCode === 'integrationFailed') {
        dispatch.Monday.setMondayCurrentBoard(null)
        throw new Error(`Failed to fetch board (board id - ${payload.boardId})`)
      } else {
        dispatch.Monday.setMondayCurrentBoard(data)
      }
    },
    async createItem(payload, rootState: RootState) {
      const { enabled } = rootState.Teams.settings?.data.integrations?.monday
      if (!enabled) return
      const data = {
        itemData: payload.itemData,
        issue: payload.issue,
      }
      const response = await sdkClient.integrations().createMondayItem({ data })
      if (response === 'monday_item_creation_failed') {
        throw new Error('Failed to create Monday item')
      }
      tracker.event('MONDAY_CREATE_ITEM', {
        detector: payload.issue.detectorId,
      })
      dispatch.Monday.setIsModalVisible(false)
      dispatch.Issues.upsert((response as any).updatedIssues)
    },
  }),
  selectors: (_slice, createSelector, _hasProps) => ({
    workspaces() {
      return createSelector(
        (rootState) => (rootState as RootState).Monday,
        (mondayState) => mondayState.workspaces
      )
    },
    board() {
      return createSelector(
        (rootState) => (rootState as RootState).Monday,
        (mondayState) => mondayState.board
      )
    },
    createItemModal() {
      return (state) => ({
        isWorkspacesFetchingInProgress:
          state.loading.effects.Monday.fetchWorkspaces !== 0,
        isItemCreationInProgress: state.loading.effects.Monday.createItem !== 0,
        isBoardFetchingInProgress:
          state.loading.effects.Monday.fetchBoardById !== 0,
        fetchBoardError: state.error.effects.Monday.fetchBoardById,
        createItemError: state.error.effects.Monday.createItem,
        fetchWorkspacesError: state.error.effects.Monday.fetchWorkspaces,
      })
    },
  }),
}
