import { ModelConfig } from '@rematch/core'
import { sdkClient } from '../../store'

export type RecoverState = {
  message: string | null
}

export type RecoverPayload = {
  email: string
}

const initialState: RecoverState = {
  message: null,
}

export const recover: ModelConfig<RecoverState> = {
  state: initialState,
  reducers: {
    setMessage(state: RecoverState, message: string): RecoverState {
      return { ...state, message }
    },
    reset(state: RecoverState): RecoverState {
      return initialState
    },
  },
  effects: (dispatch: any) => ({
    async recover({ email }: RecoverPayload) {
      await sdkClient.auth().recover({
        data: { email },
      })
      dispatch.Recover.setMessage(
        'Instructions sent. Please check your email in a few minutes.'
      )
    },
  }),
}
