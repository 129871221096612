import { ModelConfig } from '@rematch/core'
import { sdkClient, RootState } from '../../store'
import tracker from '../../../common/track'

/* this normalize the payload data because of 2 problems:
 * 1. antd form selector cannot hold objects (see https://github.com/ant-design/ant-design/issues/5670), 
  so they must have been stringify
  (see packages/spectral-client/src/blocks/jira-create-issue-modal/jira-custom-field.tsx -
    OptionsField, ArrayOptionsField components)
 * 2. We currently do not have an infra for multiple strings (which are not of a set of options) field,
    so we require a string with schema of Json Array of strings (see "label" field type in jira-custom-field.tsx) 
 */
const normalizeIssueData = (issueData) => {
  const normalizedPayload = {}
  Object.keys(issueData).forEach((key) => {
    // array of stringify objects
    if (Array.isArray(issueData[key])) {
      normalizedPayload[key] = issueData[key].map((p) => {
        try {
          return JSON.parse(p)
        } catch (err) {
          return p
        }
      })
    } else {
      try {
        // stringify object
        // or
        // stringified array of string
        normalizedPayload[key] = JSON.parse(issueData[key])
      } catch (err) {
        normalizedPayload[key] = issueData[key]
      }
    }
  })
  return normalizedPayload
}

type JiraState = {
  projects: any
  isModalVisible: boolean
}

const INITIAL_STATE = {
  projects: null,
  isModalVisible: false,
}

export const jira: ModelConfig<JiraState> = {
  state: INITIAL_STATE,
  reducers: {
    setJiraProjects(state: JiraState, data: any) {
      return { ...state, projects: data }
    },
    setIsModalVisible(state: JiraState, isVisible: any) {
      return { ...state, isModalVisible: isVisible }
    },
    reset() {
      return INITIAL_STATE
    },
  },
  effects: (dispatch: any) => ({
    async fetchProjects(payload, rootState: RootState) {
      const { domain } = rootState.Teams.settings?.data.integrations?.jira
      if (!domain) return
      const data = (await sdkClient
        .integrations()
        .getJiraProjects({ params: payload })) as any
      if (data.errorCode === 'integrationFailed') {
        dispatch.Jira.setJiraProjects([])
      } else {
        dispatch.Jira.setJiraProjects(data)
      }
    },
    async createIssue(payload, rootState: RootState) {
      const data = {
        issueData: normalizeIssueData(payload.issueData),
        issue: payload.issue,
      }
      const response = await sdkClient.integrations().createJiraIssue({ data })
      if (response === 'jira_issue_creation_failed') {
        throw new Error('Failed to create Jira issue')
      }
      tracker.event('JIRA_CREATE_ISSUE', {
        detector: payload.issue.detectorId,
      })
      dispatch.Jira.setIsModalVisible(false)
      dispatch.Issues.upsert((response as any).updatedIssues)
    },
  }),
  selectors: () => ({
    createIssueModal() {
      return (state) => ({
        isFormLoading: state.loading.effects.Jira.fetchProjects !== 0,
        isIssueCreationInProgress: state.loading.effects.Jira.createIssue !== 0,
        createIssueError: state.error.effects.Jira.createIssue,
        formDataError: state.error.effects.Jira.fetchProjects,
        projects: state.Jira.projects,
      })
    },
  }),
}
