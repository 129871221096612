/* eslint-disable func-names */
import * as Yup from 'yup'
import { domainRegex } from '@spectral/types'

export const validateDomainString = (
  restrictedDomains = [],
  restrictedDomainsErrMsg = ''
) =>
  Yup.string()
    .matches(RegExp('^(?!.*://)'), 'Domain name should not contain protocol.')
    .matches(RegExp(domainRegex), 'Must be a valid domain name.')
    .notOneOf(restrictedDomains, restrictedDomainsErrMsg)
    .nullable()
    .max(100)

Yup.addMethod(Yup.array, 'unique', function (message) {
  return this.test('unique', message, function (list) {
    const duplicates = []
    if (list) {
      list.forEach((item, index) => {
        if (list.indexOf(item) !== index) {
          // Mark the current field as a duplicate
          duplicates.push(index)
        }
      })
    }

    if (duplicates.length > 0) {
      // Set the error only on the duplicated field
      return this.createError({
        path: `${this.path}.${duplicates[0]}`, // path to the specific duplicated field
        message,
      })
    }

    return true
  })
})

export const validateDomainArray = (
  restrictedDomains = [],
  restrictedDomainsErrMsg = ''
) => {
  return (
    Yup.array()
      .of(validateDomainString(restrictedDomains, restrictedDomainsErrMsg))
      // @ts-ignore
      .unique('Domains should be unique.')
  )
}
