import React from 'react'
import Icon from '@ant-design/icons'
import { ReactComponent as Hive } from './hive.svg'
import { ReactComponent as Assets } from './assets.svg'
import { ReactComponent as Issues } from './issues.svg'
import { ReactComponent as LogsIssueSVG } from './logs-issues.svg'
import { ReactComponent as CodeIssueSVG } from './code-issues.svg'
import { ReactComponent as HostIssueSVG } from './host-issues.svg'
import { ReactComponent as ProductivityIssueSVG } from './productivity-issues.svg'
import { ReactComponent as Reports } from './reports.svg'
import { ReactComponent as Sources } from './sources.svg'

export const HiveIcon = <Icon component={Hive} />
export const AssetsIcon = <Icon component={Assets} />
export const IssuesIcon = <Icon component={Issues} />
export const ReportsIcon = <Icon component={Reports} />
export const SourcesIcon = <Icon component={Sources} />
export const LogsIssues = (
  <Icon
    style={{ fontSize: '28px', paddingTop: '10px' }}
    component={LogsIssueSVG}
  />
)
export const CodeIssues = (
  <Icon
    style={{ fontSize: '28px', paddingTop: '10px' }}
    component={CodeIssueSVG}
  />
)
export const HostIssues = (
  <Icon
    style={{ fontSize: '28px', paddingTop: '10px' }}
    component={HostIssueSVG}
  />
)
export const ProductivityIssues = (
  <Icon
    style={{ fontSize: '28px', paddingTop: '10px' }}
    component={ProductivityIssueSVG}
  />
)
