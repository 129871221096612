import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import TeamKey from '../../../../blocks/settings/teams/team-key/view'

const TeamKeyConnected = () => {
  const dispatch = useDispatch()
  const currentTeam = useSelector(select.Teams.current)

  useEffect(() => {
    if (!currentTeam) dispatch.Teams.fetchCurrentTeam()
  }, [dispatch.Teams, currentTeam])

  return (
    <TeamKey
      currentTeam={currentTeam}
      onGenerateConfirm={dispatch.Teams.generateKey}
    />
  )
}

export default TeamKeyConnected
