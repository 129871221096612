const darkInk = '#11336a'

module.exports = {
  stylebook: {
    styles: {
      global: {
        fontFamily: 'inter',
        fontSize: '16px',
        color: 'darkInk',
        bg: 'white',
        '*::placeholder': {
          color: 'gray.400',
        },
        '*, *::before, &::after': {
          borderColor: 'gray.200',
          wordWrap: 'break-word',
        },
        fontFeatureSettings: `"pnum"`,
        fontVariantNumeric: 'proportional-nums',
      },
    },
    fonts: {
      inter:
        '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
      fira:
        '"Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
    },
    colors: {
      placeHolder: '#aab4c6',
      // this is for theme-ui automatic body color mode
      text: darkInk,
      paleText: '#7A8394',
      background: '#fcfcfc',
      contentPanelBg: '#fff',
      contentPanelHeader: '#f2f2f2',
      contentPanelHeaderText: '#7d777e',
      disabledInputBackground: '#fafafa',
      inputBorderColor: '#dee5ee',
      // end theme-ui
      cyan: 'cyan',
      darkInk,
      board: '#F8F8FC',
      controlBorder: '#d4dae2',
      red: '#F25767',
      green: '#3f8600',
      redScale: {
        50: '#fff1f0',
        60: '#fccfcf',
      },
      orangeScale: {
        40: '#fff3cc',
        50: '#ffedcc',
        60: '#ffe6cc',
      },
      yellowScale: {
        50: '#fff7e6',
        700: '#fa8c16',
      },
      blueScale: {
        40: '#cce7ff',
        50: '#e6f7ff',
        700: '#1890ff',
      },
      gray: {
        50: '#F7FAFC',
        100: '#EDF2F7',
        200: '#E2E8F0',
        300: '#CBD5E0',
        400: '#A0AEC0',
        500: '#8596ad',
        600: '#4A5568',
        700: '#2D3748',
        800: '#1A202C',
        900: '#171923',
      },
      primary: '#762AEB',
      primaryScale: {
        50: '#f1e7fd',
        100: '#d9c5f9',
        200: '#bf9df6',
        300: '#a573f3',
        400: '#8e50ef',
        500: '#762AEB',
        600: '#6c25e4',
        700: '#5b1adc',
        800: '#4a12d6',
        900: '#2600ce',
      },
      severities: {
        criticalHSL: { h: 0, s: 86, l: 63 },
        highHSL: { h: 30, s: 100, l: 50 },
        mediumHSL: { h: 39, s: 100, l: 59 },
        lowHSL: { h: 46, s: 100, l: 70 },
        informationalHSL: { h: 208, s: 100, l: 75 },
        noneHSL: { h: 148, s: 73, l: 46 }, // green
        oldHSL: { h: 194, s: 60, l: 90 },
        criticalHEX: '#f24e4e',
        highHEX: '#ff8000',
        mediumHEX: '#ffb72d',
        lowHEX: '#ffdb66',
        informationalHEX: '#80c3ff',
        noneHEX: '#32955b',
        oldHEX: '#a5b6c5',
      },
    },
    borders: {
      outline: '1px solid #DEE5EE',
      contentPanel: '1px solid rgb(204, 204, 204)',
      contentPanelSection: '1px solid rgb(235, 235, 235)',
    },
    shadows: {
      outline: '0 0 0 2px rgba(118, 42, 235, 0.2)',
      primaryButton: '0px 4px 14px rgba(37, 8, 98, 0.18)',
      lgFocused: '0 3px 15px -3px  #b698f2',
      tileFocused: '0 3px 15px -3px  #d4d4d4',
      panelFocused: '0 3px 15px -3px  #d4d4d4',
      heading: 'inset 0px -1px 0px #e6e6e6',
      contentPanel: 'rgba(37, 11, 54, 0.04) 0px 2px 0px',
    },
    buttonSizes: {
      lg: { h: 12, minW: 12, fontSize: 'sm', px: 6 },
      md: { h: 10, minW: 10, fontSize: 'sm', px: 4 },
      sm: { h: 8, minW: 8, fontSize: 'sm', px: 3 },
      xlg: { h: '64px', minW: 12, px: 6, fontSize: 'md' },
      xs: { h: 6, minW: 6, fontSize: 'xs', px: 2 },
    },
    buttons: {
      solid: {
        boxShadow: 'primaryButton',
        color: 'white',
        bg: 'primaryScale.500',
        '&:hover': { bg: 'primaryScale.400' },
        '&:focus': {
          bg: 'primaryScale.400',
          boxShadow: 'lgFocused',
        },
      },
      outline: {
        boxShadow: 'none',
        color: 'primaryScale.500',
        bg: 'transparent',
        border: '1px solid',
        borderColor: 'primaryScale.500',
        '&:hover': { bg: 'primaryScale.50' },
        '&:focus': {
          bg: 'primaryScale.50',
          boxShadow: 'lgFocused',
        },
      },
    },
    text: {
      default: {
        fontFamily: 'Inter',
        fontSize: '16px',
        color: 'darkInk',
      },
      display: {
        fontFamily: 'Inter',
        fontSize: '16px',
        color: 'darkInk',
      },
      heading: {
        fontFamily: 'fira',
        fontSize: '72px',
        letterSpacing: '-0.02em',
        lineHeight: '79px',
        fontWeight: 800,
        color: 'darkInk',
      },
      wizardheading: {
        fontFamily: 'fira',
        fontSize: '32px',
        letterSpacing: '-0.03em',
        fontWeight: 400,
        lineHeight: '53px',
        color: 'darkInk',
      },
      wizardsub: {
        fontFamily: 'Inter',
        fontSize: '22px',
        letterSpacing: '-0.03em',
        fontWeight: 400,
        lineHeight: '53px',
        color: 'darkInk',
      },
      subheading: {
        fontFamily: 'fira',
        fontSize: '48px',
        letterSpacing: '-0.03em',
        fontWeight: 600,
        lineHeight: '53px',
        color: 'darkInk',
      },
      smallheading: {
        fontFamily: 'fira',
        letterSpacing: '-0.02em',
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '36px',
        color: 'darkInk',
      },
      title: {
        fontFamily: 'inter',
        letterSpacing: '-0.03em',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '36px',
        color: 'darkInk',
        '-webkit-font-smoothing': 'antialiased',
      },
      smalltitle: {
        fontFamily: 'inter',
        letterSpacing: '-0.03em',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '40px',
        color: 'darkInk',
        '-webkit-font-smoothing': 'antialiased',
      },
      summary: {
        fontFamily: 'inter',
        letterSpacing: '-0.03em',
        fontWeight: 500,
        color: 'gray.500',
      },
      paleSummary: {
        fontFamily: 'inter',
        letterSpacing: '-0.03em',
        fontWeight: 500,
        color: 'gray.400',
      },
      cardSmallTitle: {
        fontFamily: 'inter',
        letterSpacing: '-0.01em',
        fontSize: '14px',
        fontWeight: 500,
        color: 'darkInk',
      },
      cardTitle: {
        fontFamily: 'inter',
        letterSpacing: '-0.01em',
        fontWeight: 600,
        color: 'darkInk',
      },
      ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      link: {
        transition: 'color 0.5s',
        cursor: 'pointer',
        color: 'gray.400',
        '&:hover': { color: 'primaryScale.400' },
      },
      editor: {
        diffColor: '#ede3b7',
      },
      badExample: {
        fontStyle: 'italic',
        color: 'red',
      },
      small: {
        fontSize: '14px',
      },
    },
    icons: {
      modals: {
        danger: {
          iconColor: '#ff4d4f',
        },
      },
    },
  },
}
