import { Button, Dropdown, Menu } from 'antd'
import { Box, Flex, Text } from 'theme-ui'
import MenuItem from 'antd/lib/menu/MenuItem'
import React, { useCallback, useEffect, useState } from 'react'
import { isEmpty, debounce } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import {
  MemberSelectDropdownItem,
  MemberUnassignedDropdownItem,
} from '../../components/members/member-select-dropdown-new'
import Invite from './invite-new-member'
import { features } from '../../common/features'
import SmallInput from '../../components/inputs/small-input'
import tracker from '../../common/track'
import { Spinner } from '../../components/spinner'

const findOtherMembers = (membersList, currentAssigneeUsername) =>
  membersList?.filter(({ username }) => username !== currentAssigneeUsername)

const trackFilter = () => {
  tracker.filterAssigneeMembers()
}

const toMembersWithFullName = (members) =>
  members?.map((member) => ({
    ...member,
    fullName: `${member.firstName} ${member.lastName}`,
  }))

const DefaultDropdownButton = ({ currentAssigneeMember }) =>
  currentAssigneeMember ? (
    <Button type="link" style={{ padding: 0, display: 'flex' }}>
      <MemberSelectDropdownItem
        member={currentAssigneeMember}
        shouldDisplayName={false}
      />
    </Button>
  ) : (
    <Button type="link" style={{ padding: 0, display: 'flex' }}>
      <MemberUnassignedDropdownItem shouldDisplayName={false} />
    </Button>
  )

type Props = {
  issue?: any
  assignableMembers: any[]
  isLoadingAssignableMembers: boolean
  isAssignableMembersLoaded: boolean
  onAssigneeChanged?: (data) => void
  onAssigneeRemove?: (data) => void
  onSearchAssignee: (data) => void
  dropdownButton?: React.ReactNode | boolean
}

export default ({
  issue = null,
  assignableMembers,
  isLoadingAssignableMembers = false,
  isAssignableMembersLoaded,
  onAssigneeChanged,
  onAssigneeRemove,
  onSearchAssignee,
  dropdownButton = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const otherMembers = findOtherMembers(assignableMembers, issue?.assignee)
  const otherMembersWithFullName = toMembersWithFullName(otherMembers)

  const fetchSearchAssignee = (value) => {
    trackFilter()
    onSearchAssignee(value)
  }

  const debouncedHandleSearchAssignee = useCallback(
    debounce(fetchSearchAssignee, 400, { trailing: true }),
    []
  )

  useEffect(() => {
    if (isOpen) {
      debouncedHandleSearchAssignee(searchValue)
    }

    return () => debouncedHandleSearchAssignee.cancel()
  }, [debouncedHandleSearchAssignee, searchValue])
  const currentAssigneeMember = issue?.assignee
    ? {
        username: issue.assignee,
        fullName: issue.assigneeName,
        avatarUrl: issue.assigneeAvatarUrl,
      }
    : null

  const handleSearchAssignee = (e) => {
    setSearchValue(e.target.value)
  }

  const isUnassignOptionShown =
    currentAssigneeMember !== null && searchValue === ''

  return (
    <Dropdown
      trigger={['click']}
      visible={isOpen}
      onVisibleChange={(visible) => {
        setIsOpen(visible)
        if (visible) {
          fetchSearchAssignee(searchValue)
        } else {
          setSearchValue('')
        }
      }}
      overlay={
        <Box
          sx={{
            bg: 'white',
            border: '1px solid #d4dae2',
            borderRadius: '4px',
            width: '220px',
          }}
        >
          <Flex
            sx={{ paddingX: 3, paddingY: 2, borderBottom: '1px solid #d4dae2' }}
          >
            <SmallInput
              value={searchValue}
              onChange={handleSearchAssignee}
              allowClear
              autoFocus
              placeholder="Search member"
              prefix={<SearchOutlined />}
            />
          </Flex>
          {isAssignableMembersLoaded &&
            !isLoadingAssignableMembers &&
            isEmpty(otherMembersWithFullName) &&
            !isUnassignOptionShown && (
              <Text variant="small" sx={{ p: 3 }}>
                No members match the filter
              </Text>
            )}
          {isLoadingAssignableMembers && (
            <Flex sx={{ justifyContent: 'center', my: 3 }}>{Spinner}</Flex>
          )}
          <Menu
            style={{
              maxHeight: '150px',
              overflowY: 'auto',
              background: 'transparent',
            }}
          >
            {!isLoadingAssignableMembers && isUnassignOptionShown && (
              <MenuItem
                style={{ lineHeight: '24px', height: '24px' }}
                key="unassigned"
                onClick={() => {
                  setIsOpen(false)
                  onAssigneeRemove({ issueIds: [issue?.pid] })
                }}
              >
                <MemberUnassignedDropdownItem size="small" />
              </MenuItem>
            )}
            {!isLoadingAssignableMembers &&
              otherMembersWithFullName?.map((member) => (
                <Menu.Item
                  style={{ lineHeight: '24px', height: '24px' }}
                  key={member.username}
                  onClick={() => {
                    setIsOpen(false)
                    onAssigneeChanged({
                      assignee: member?.username,
                      assigneePid: member?.pid,
                      issueIds: [issue?.pid],
                    })
                  }}
                >
                  <MemberSelectDropdownItem member={member} size="small" />
                </Menu.Item>
              ))}
          </Menu>
          {!features.isRestrictionSettingsMode && <Invite />}
        </Box>
      }
    >
      <Box>
        {dropdownButton && dropdownButton}
        {!dropdownButton && (
          <DefaultDropdownButton
            currentAssigneeMember={currentAssigneeMember}
          />
        )}
      </Box>
    </Dropdown>
  )
}
