/** @jsx jsx */
import { Box, Flex, Heading, Image, jsx, Link } from 'theme-ui'
import { Button } from 'antd'
import { imageMap } from '../../../common/sources/imageMap'
import IntegrationWrapper from '../integration-wrapper'
import { supportWidget } from '../../../common/support-widget'
import { ListSection, Sections, TextStyle } from './share'
import awsDeployStack from '../../../assets/png/cloudformation-launch-stack.png'
import IntegrationStatus from '../integration-status'
import PersonalTokenAlert from '../personal-token-alert'
import EnvVarsTable from './common/envVarsTable'

const envVarsData = [
  {
    key: '1',
    name: 'SPECTRAL_DSN',
    required: true,
    description: 'Your Spectral DSN retrieved from SpectralOps',
  },
  {
    key: '2',
    name: 'CHECK_POLICY',
    required: true,
    description:
      'If Spectral finds issues - how should we handle the run? The policies are based on the Spectral issue severity - critical / high / medium / low / informational (Valid values: "Fail on any issue" / "Fail on low and above" / "Fail on medium and above" / "Fail on high and above" / "Fail on critical only" / "Always Pass")',
  },
  {
    key: '3',
    name: 'HMAC_KEY',
    required: true,
    description:
      'A key that will be used for securing your Run Task by validating the request payload signature, should be identical to the HMAC key you set will set in the Run Task',
  },
  {
    key: '4',
    name: 'TERRAFORM_USER_KEY',
    required: false,
    description:
      'User key created by Terraform (required for pre-plan run task)',
  },
]

const SectionSubTitle = ({ children }) => (
  <Box sx={{ fontSize: '18px', fontWeight: 'bold', marginTop: '15px' }}>
    {children}
  </Box>
)

const SectionTitle = ({ children }) => (
  <Heading sx={{ mt: '30px', mb: '15px' }} as="h2" variant="smallheading">
    {children}
  </Heading>
)

const TfcSourceIntegration = ({
  dataSource,
  isConnected,
  statusLoaded,
  shouldShowTeamToken,
  onSwitchTokenShown,
}) => {
  return (
    <IntegrationWrapper
      title={dataSource.title}
      imgSrc={imageMap[dataSource?.tileKey]}
      extraOnRight={
        <IntegrationStatus
          variant="static"
          isConnected={isConnected}
          isLoaded={statusLoaded}
        />
      }
    >
      <Flex
        sx={{
          gap: 3,
          flexDirection: 'column',
        }}
      >
        {shouldShowTeamToken && (
          <Box sx={{ my: 4 }}>
            <PersonalTokenAlert onSwitch={onSwitchTokenShown} />
          </Box>
        )}
      </Flex>
      <TextStyle>
        Protect your infrastructue by detecting potential issues in your
        Terraform configuration and plan before applying the changes to
        production!
      </TextStyle>
      <TextStyle sx={{ marginTop: '5px' }}>
        <strong>NOTE! </strong> the instructions on this page are identical for
        Terraform Enterprise users as well.
      </TextStyle>
      <SectionTitle>Run tasks stages</SectionTitle>
      Spectral can be integrated with Terraform cloud in two stages of the
      Terraform run:
      <SectionSubTitle>Pre-plan</SectionSubTitle>
      <TextStyle>
        This stage takes place right before the plan stage.
        <br />
        In this stage, Spectral would scan your Terraform configuration deployed
        in this run for misconfigurations.
      </TextStyle>
      <SectionSubTitle>Post-plan</SectionSubTitle>
      <TextStyle>
        This stage takes place between the plan and apply stages.
        <br />
        In this stage, Spectral would scan the generated plan of the current run
        for potential issues before applying the changes to your live
        infrastructure.
      </TextStyle>
      <TextStyle sx={{ marginTop: '15px' }}>
        <Flex>
          You can read more about run tasks in Terraform Cloud{' '}
          <Link
            href="https://www.terraform.io/cloud-docs/workspaces/settings/run-tasks"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ marginLeft: '5px', marginRight: '5px' }}
          >
            here
          </Link>
          and
          <Link
            href="https://www.terraform.io/cloud-docs/integrations/run-tasks"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ marginLeft: '5px' }}
          >
            here
          </Link>
          .
        </Flex>
      </TextStyle>
      <SectionTitle>Integration Environment Variables</SectionTitle>
      <EnvVarsTable envVarsData={envVarsData} />
      <SectionTitle>Setup</SectionTitle>
      <TextStyle>
        <TextStyle sx={{ marginTop: '10px' }}>
          This integration is based on an AWS lambda function which is being
          triggered by the Run Task at the relevant stage.
        </TextStyle>
        The setup process of a Spectral run task has several steps:
        <Sections sx={{ counterReset: 'section' }}>
          <ListSection>
            <Box sx={{ fontSize: '18px', fontWeight: 'bold' }}>
              AWS Lambda Installation
            </Box>
            Create the required AWS resources in one of of the following
            methods:{' '}
            <ul>
              <li>
                <Box>
                  Using this
                  <Link
                    sx={{ marginLeft: '5px' }}
                    href="https://github.com/SpectralOps/spectral-terraform-lambda-integration"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terraform module
                  </Link>{' '}
                  (set the <code>integration_type</code> param value to{' '}
                  <strong> terraform</strong>
                  ).
                </Box>
              </li>
              <li>
                <Flex>
                  Cloud formation installer -
                  <Link
                    sx={{ marginLeft: '5px' }}
                    href="https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-tfc-scanner/cloudformation-template.json&stackName=spectral-tfc-scanner"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Image sx={{ mr: 2 }} src={awsDeployStack} />
                  </Link>
                </Flex>
              </li>
            </ul>
            <Box>
              Make sure to supply all the required environment variables,
              including <code>SPECTRAL_DSN</code>, <code>CHECK_POLICY</code> and{' '}
              <code>HMAC_KEY</code>. For additional details about the required
              environment variables please refer to our{' '}
              <Link
                href="/docs/integration/tfc#step-1---create-cloud-resources-required-by-spectral"
                rel="noopener noreferrer"
                target="_blank"
              >
                docs
              </Link>
              .
            </Box>
            <Box>
              In case you are about to create a pre-plan Run Task, please create
              a{' '}
              <Link
                href="https://app.terraform.io/app/settings/tokens"
                rel="noopener noreferrer"
                target="_blank"
              >
                user API key in Terraform
              </Link>
              , and then set this key in the <code>TERRAFORM_USER_KEY</code> env
              variable.
            </Box>
            <Box>
              After resources creation is finished, grab the gateway api URL (if
              you are using the terraform module - use <code>rest_api_url</code>{' '}
              output), we`ll use it later.
            </Box>
          </ListSection>
          <ListSection>
            <Box sx={{ fontSize: '18px', fontWeight: 'bold' }}>
              Create a Run Task
            </Box>
            <Flex>
              {' '}
              In this step, we will create a new Run Task in your Terraform
              Cloud organization.
            </Flex>
            Get to your organization settings in Terraform Cloud, then select
            "Run Tasks", and then click on "Create run task" button.
            <br />
            While creating the run task, please paste the gateway api URL from
            step 1 in "Endpoint URL" field.
            <br />
            <Flex>
              Finally, set the HMAC key field with the exact same value you had
              set into the HMAC_KEY environment variable in the previous step.{' '}
            </Flex>
            <Flex>
              <TextStyle sx={{ marginTop: '10px' }}>
                For more detailed instructions for creating a Run Task for
                Spectral please refer to our{' '}
                <Link
                  href="/docs/integration/tfc#step-2---create-run-task-in-terraform-cloud"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  docs
                </Link>
                .
              </TextStyle>
            </Flex>
          </ListSection>
          <ListSection>
            <Box sx={{ fontSize: '18px', fontWeight: 'bold' }}>
              Assign Run Task to a workspace
            </Box>
            <Flex>
              Assign your new Run Task to the target workspace in your Terraform
              Cloud organization.
            </Flex>
            You can do that by clicking on your workspace settings, selecting
            "Run Tasks", and click on your target Run Task.
            <br />
            Then, set the task stage to be "Pre-plan" or "Post-plan", set the
            enforcement level to "Mandatory", and click the "Create" button.
            <Flex>
              <TextStyle sx={{ marginTop: '10px' }}>
                For more detailed instructions for attaching the Run Task to
                your workspace, please refer to our{' '}
                <Link
                  href="/docs/integration/tfc/#step-3---add-the-run-task-to-your-workspace"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  docs
                </Link>
                .
              </TextStyle>
            </Flex>
          </ListSection>
        </Sections>
      </TextStyle>
      <SectionTitle>
        That&#39;s it{' '}
        <span role="img" aria-label="strong">
          💪
        </span>
      </SectionTitle>
      <TextStyle>
        Any configuration or plan modifications would be scanned for issues
        before the infrastructure changes are applied.
      </TextStyle>
      <SectionTitle>Give it a spin</SectionTitle>
      <TextStyle>
        Trigger a run in your workspace, the run task should be executed on its
        relevant stage.
      </TextStyle>
      <SectionTitle>Integration Status</SectionTitle>
      <IntegrationStatus isConnected={isConnected} isLoaded={statusLoaded} />
      <Flex sx={{ mt: '40px', justifyContent: 'center' }}>
        <Button size="large" onClick={() => supportWidget.open()}>
          Need help? Talk to an expert
        </Button>
      </Flex>
    </IntegrationWrapper>
  )
}

export default TfcSourceIntegration
