import { Team } from '@spectral/backend-sdk'
import React, { useEffect, useLayoutEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Flex } from 'theme-ui'
import { useLocation } from 'react-router-dom'
import { Dispatch, RootState, select } from '../../redux/store'
import {
  WizardSelectModeCiOrAudit,
  NoPublicScansWizardSelectModeCiOrAudit,
} from '../../blocks/sources-wizard'
import sources from '../../common/sources/sources'
import { OperatingSystem } from '../../common/sources/enums'
import { mixPanelTracking as wizardTracking } from '../../blocks/sources-wizard/utils/tracking'
import { features } from '../../common/features'
import useQuerystring from '../../hooks/useQuerystring'
import { supportWidget } from '../../common/support-widget'

type Props = {
  currentTeam: null | Team
  fetchCurrentTeam: () => void
}

const WizardConnected = ({ currentTeam, fetchCurrentTeam }: Props) => {
  const location = useLocation()

  const {
    downloaderBaseUrl,
    agentDetails: { version },
  } = useSelector((state) => state.Auth.user)

  const digestLinks = {
    darwin: `${downloaderBaseUrl}/v${version}/spectral_${version}_darwin_amd64.shas.txt`,
    linux: `${downloaderBaseUrl}/v${version}/spectral_${version}_linux_amd64.shas.txt`,
    win: `${downloaderBaseUrl}/v${version}/spectral_${version}_win32_amd64.shas.txt`,
  }

  const { name: teamName, pid: teamPid, key: teamKey, loginType } = currentTeam
  const user = useSelector((state) => state.Auth.user)

  const relevantSources = {
    ...sources,
    audit: {
      [OperatingSystem.WINDOWS]: sources.audit[OperatingSystem.WINDOWS],
      [OperatingSystem.MACLINUX]: sources.audit[OperatingSystem.MACLINUX],
    },
    mitigate: sources.mitigate,
  }

  const linkExternal = (exLink) => {
    window.open(
      `${exLink.baseUrl}?usp=pp_url&${exLink.emailQsKey}=${user.username}&` +
        `${exLink.fullNameQsKey}=${user.firstName} ${user.lastName}`,
      '_blank',
      'noopener,noreferrer'
    )
  }

  const [{ chat }] = useQuerystring()
  useEffect(() => {
    if (chat) supportWidget.open()
  }, [chat])

  useLayoutEffect(() => {
    if (features.preloadChat) supportWidget.load()
  })

  useEffect(() => {
    fetchCurrentTeam()
  }, [fetchCurrentTeam])

  return (
    <Flex sx={{ justifyContent: 'center' }}>
      {features.publicScanAvailable ? (
        <>
          <WizardSelectModeCiOrAudit
            teamKey={teamKey}
            teamPid={teamPid}
            teamName={teamName}
            loginType={loginType}
            userApiKey={user.apiKey}
            sources={relevantSources}
            linkExternal={linkExternal}
            digestLinks={digestLinks}
            tracker={wizardTracking}
            username={user.username}
            location={location}
          />
        </>
      ) : (
        <NoPublicScansWizardSelectModeCiOrAudit
          teamKey={currentTeam && currentTeam.key}
          teamPid={teamPid}
          teamName={teamName}
          loginType={loginType}
          userApiKey={user.apiKey}
          sources={relevantSources}
          linkExternal={linkExternal}
          digestLinks={digestLinks}
          tracker={wizardTracking}
          location={location}
        />
      )}
    </Flex>
  )
}

const mapState = (state: RootState) => ({
  currentTeam: select.Teams.current(state),
})
const mapDispatch = (dispatch: Dispatch) => ({
  fetchCurrentTeam: dispatch.Teams.fetchCurrentTeam,
})

export default connect(mapState, mapDispatch)(WizardConnected)
