import React, { useEffect } from 'react'
import { Flex } from 'theme-ui'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import View from './view'
import { Dispatch, RootState } from '../../redux/store'
import { Spinner } from '../../components/spinner'
import { paths } from '../../routes'

const RecoverFinishConnected = ({
  isLoggedin,
  onSubmit,
  error,
  message,
  recoverValid,
}) => {
  // we need to combine email, reset token, and passwords
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch.RecoverFinish.recoverValidate(params)
  }, [dispatch.RecoverFinish, params])

  const handleRecoveryFinish = () => {
    history.push(paths.open.signin)
  }

  if (isLoggedin) return <Redirect to="/" />
  return (
    <Flex sx={{ justifyContent: 'center', pt: '60px', alignItems: 'center' }}>
      {(recoverValid || error) && (
        <View
          links={{ signin: paths.open.signin }}
          onSubmit={(form) =>
            onSubmit({ ...form, handleRecoveryFinish, ...params })
          }
          error={error}
          message={message}
        />
      )}
      {!recoverValid && !error && Spinner}
    </Flex>
  )
}

const mapDispatch = (dispatch: Dispatch) => ({
  onSubmit: ({
    pid,
    resetToken,
    password,
    passwordAgain,
    handleRecoveryFinish,
  }) =>
    dispatch.RecoverFinish.recoverFinish({
      pid,
      resetToken,
      password,
      passwordAgain,
      handleRecoveryFinish,
    }),
})

const mapState = (state: RootState) => ({
  isLoggedin: state.Auth.user,
  error: state.RecoverFinish.error,
  message: state.RecoverFinish.message,
  recoverValid: true,
})

export default connect(mapState, mapDispatch)(RecoverFinishConnected)
