/** @jsx jsx */
import { jsx, Flex, Box, Text } from 'theme-ui'

import L from 'lodash'
import { Tag, Tabs, Input } from 'antd'

import { useEffect, useState } from 'react'
import { useFormikContext, useField } from 'formik'
import { Spinner } from '../../components/spinner'
import { DropDown } from '../../components/select/index'
import { PanelTable, AssetColumn } from '../../components/tables'
import { WeakLink } from '../../components/buttons/weak-link'
import { paths } from '../../routes'

const { TabPane } = Tabs
const UNMAPPED_KEY = 'Unmapped'

export const AssetMappingFormField = ({ name }) => {
  const [, meta, helpers] = useField({ name })
  const formik = useFormikContext()
  return (
    <AssetMappingTable
      data={meta.initialValue}
      onSave={(val) => {
        helpers.setValue(val)
        formik.submitForm()
      }}
    />
  )
}

const AssetMappingTable = ({ isLoading = false, data, onSave = null }) => {
  const [filterTerm, setFilterTerm] = useState('')
  const [tableData, setTableData] = useState(data.assetMapping.mapping)
  const [selectedAssets, setSelectedAssets] = useState([])
  const [activeRowKeys, setActiveRowKeys] = useState([])
  const [activeTabKey, setActiveTabKey] = useState('unmapped')
  const [selectedTeam, setSelectedTeam] = useState()
  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: activeRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedAssets(selectedRows)
      setActiveRowKeys(selectedRowKeys)
    },
  }

  useEffect(() => {
    setSelectedTeam(
      selectedAssets.length === 1 ||
        new Set(selectedAssets.map((asset) => asset.orgTeamKey)).size === 1
        ? selectedAssets[0].orgTeamKey
        : undefined
    )
  }, [selectedAssets])

  const resetTableState = () => {
    setSelectedAssets([])
    setActiveRowKeys([])
    setSelectedTeam(null)
  }

  const { orgTeams } = data
  const rowAction = (val) => {
    L.forEach(selectedAssets, (asset) => {
      const d = L.find(tableData, (x) => x.assetId === asset.assetId)
      if (d) {
        d.orgTeamKey = val === UNMAPPED_KEY ? null : val
      }
    })
    setTableData([...tableData])
    resetTableState()
    onSave(selectedAssets)
  }

  const columns = [
    {
      title: () => (
        <Flex sx={{ alignItems: 'center' }}>
          <Box>
            <Text color="contentPanelHeaderText">Map to team:</Text>
          </Box>
          <Box sx={{ ml: '30px', width: 300 }}>
            <DropDown
              value={selectedTeam}
              onChange={rowAction}
              disabled={selectedAssets.length === 0}
              options={
                activeTabKey === 'mapped'
                  ? [UNMAPPED_KEY, ...orgTeams]
                  : orgTeams
              }
              notFoundContent={
                <Box sx={{ textAlign: 'center' }}>
                  <Flex>
                    <Text sx={{ fontSize: '14px' }}>
                      No teams found
                      {data.orgTeams?.length === 0 && (
                        <WeakLink to={paths.closed.orgTeam}>
                          Create your first team
                        </WeakLink>
                      )}
                    </Text>
                  </Flex>
                </Box>
              }
            />
          </Box>
        </Flex>
      ),
      dataIndex: 'assetName',
      key: 'assetName',
      render: (assetName, asset) => (
        <AssetColumn
          assetName={asset.assetName}
          assetSource={asset.assetSource}
        />
      ),
    },
    {
      title: 'Organization Team',
      dataIndex: 'orgTeamKey',
      key: 'orgTeamKey',
      render: (orgTeamKey) => <Tag sx={{ fontSize: '14px' }}>{orgTeamKey}</Tag>,
    },
  ]

  const unmapped = tableData.filter((td) => !td.orgTeamKey)
  const mapped = tableData.filter((td) => !!td.orgTeamKey)

  const filterAssets = ({ target: { value } }) => {
    setFilterTerm(value)
    setTableData(
      data.assetMapping.mapping.filter((asset) => asset.assetId.toLowerCase().includes(value.toLowerCase().trim()))
    )
  }

  return (
    <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
      <TabPane
        tab={`Unmapped${unmapped.length > 0 ? ` (${unmapped.length})` : ''}`}
        key="unmapped"
      >
        <Input
          style={{ marginBottom: '10px' }}
          placeholder="Asset name"
          addonBefore="Filter assets"
          value={filterTerm}
          onChange={filterAssets}
        />
        <PanelTable
          loading={isLoading ? { indicator: Spinner } : false}
          columns={columns}
          rowKey={({ assetId }) => assetId}
          dataSource={unmapped}
          rowSelection={rowSelection}
        />
      </TabPane>
      <TabPane
        tab={`Mapped${mapped.length > 0 ? ` (${mapped.length})` : ''}`}
        key="mapped"
      >
        <Input
          style={{ marginBottom: '10px' }}
          placeholder="Asset name"
          addonBefore="Filter assets"
          value={filterTerm}
          onChange={filterAssets}
        />
        <PanelTable
          loading={isLoading ? { indicator: Spinner } : false}
          columns={columns}
          rowKey={({ assetId }) => assetId}
          dataSource={mapped}
          rowSelection={rowSelection}
        />
      </TabPane>
    </Tabs>
  )
}

export default AssetMappingTable
