import React from 'react'
import SmallDateRange from '../inputs/small-date-range'

const DateSideFilter = ({
  onChange,
  disabled,
  startDate,
  endDate,
  datesToDisableCallback,
  defaultPickerValue,
  allowEmpty,
}) => {
  return (
    <SmallDateRange
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      disabled={disabled}
      format="DD-MM-YYYY"
      datesToDisableCallback={datesToDisableCallback}
      defaultPickerValue={defaultPickerValue}
      allowEmpty={allowEmpty}
    />
  )
}

export default DateSideFilter
