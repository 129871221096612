import { CheckOutlined, UndoOutlined } from '@ant-design/icons'
import { IssueStatus } from '@spectral/types'
import React from 'react'
import ButtonWithTooltip from '../../components/buttons/button-with-tooltip'

const ResolveButton = ({
  isLoading,
  onResolveClicked,
  onUnreoslveClick,
  selectedIssues,
}) => {
  const resolveableIssues = selectedIssues.filter((issue) =>
    [IssueStatus.Active, IssueStatus.Ignored].includes(issue.status)
  )
  const resolvedIssue = selectedIssues.filter(
    (issue) => issue.status === IssueStatus.Resolved
  )

  let view
  if (
    (resolvedIssue.length === 0 && resolveableIssues.length === 0) ||
    (resolvedIssue.length !== 0 && resolveableIssues.length !== 0)
  ) {
    view = 'disabled'
  } else if (resolvedIssue.length > 0 && resolveableIssues.length === 0) {
    view = 'unresolve'
  } else {
    view = 'resolve'
  }

  switch (view) {
    case 'disabled':
      return (
        <ButtonWithTooltip
          isLoading={isLoading}
          icon={<CheckOutlined />}
          text="Resolve"
          disabled
          onActionClick={null}
        />
      )
    case 'resolve':
      return (
        <ButtonWithTooltip
          isLoading={isLoading}
          icon={<CheckOutlined />}
          text={`Resolve (${resolveableIssues.length})`}
          disabled={false}
          onActionClick={onResolveClicked}
        />
      )
    case 'unresolve':
      return (
        <ButtonWithTooltip
          isLoading={isLoading}
          icon={<UndoOutlined />}
          text="Unresolve"
          disabled={false}
          onActionClick={onUnreoslveClick}
        />
      )
    default:
      return (
        <ButtonWithTooltip
          isLoading={isLoading}
          icon={<CheckOutlined />}
          text="Resolve"
          disabled={false}
          onActionClick={onResolveClicked}
        />
      )
  }
}

export default ResolveButton
