import React from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as QueryString from 'query-string'
import { Page } from '../../components/page'
import { paths } from '../../routes'
import track from '../../common/track'

export default () => {
  const location = useLocation()
  const params = QueryString.parse(location.search) || {}
  const isNewUser = 'isNewUser' in params
  const { vendor } = params
  const user = useSelector((state) => state.Auth.user)
  if (isNewUser && user) {
    track.event('SIGNUP_COMPLETED_SSO', { username: user.username, vendor })
  }
  if (!isNewUser && user) {
    track.event('LOGIN_SSO', { username: user.username, vendor })
  }
  const dispatch = useDispatch()
  dispatch.Auth.me()

  let redirectUrl = null
  if (params.returnTo) {
    redirectUrl = params.returnTo
  } else {
    redirectUrl = isNewUser ? paths.closed.sources : paths.closed.hive
  }

  return (
    <Page name="SSO" title="Single Sign On">
      {!user && 'Linking your account...'}
      {user && <Redirect to={decodeURIComponent(redirectUrl)} />}
    </Page>
  )
}
