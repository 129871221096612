import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import { Box } from 'theme-ui'
import { colors, nivoTheme } from './nivo-theme'
import TooltipBox from './tooltip'
import { ContentPanel, ContentPanelHeader } from '../panels/content'
import EmptyState from './empty-state'

const BarComponent = ({
  x,
  width,
  color,
  label,
  data,
  onClick,
  showTooltip,
  hideTooltip,
  onMouseEnter,
  onMouseLeave,
  tooltip,
}) => {
  const handleTooltip = (e) =>
    showTooltip(
      <TooltipBox>
        {data.indexValue}: <strong>{label}</strong>
      </TooltipBox>,
      e
    )
  const handleMouseEnter = (e) => {
    onMouseEnter(data, e)
    showTooltip(tooltip, e)
  }
  const handleMouseLeave = (e) => {
    onMouseLeave(data, e)
    hideTooltip(e)
  }
  return (
    <g
      transform={`translate(${x}, ${data.index * 50})`}
      onClick={() => {
        if (onClick !== null) onClick(data.data)
      }}
    >
      <rect
        style={{ cursor: onClick === null ? 'default' : 'pointer' }}
        width={width}
        height={20}
        fill={color}
        strokeWidth="0"
        stroke="white"
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleTooltip}
        onMouseLeave={handleMouseLeave}
      />
      <text
        x={8}
        y={10}
        dominantBaseline="central"
        style={{
          fontSize: 12,
          fill: '#FFFFFF',
          cursor: onClick === null ? 'default' : 'pointer',
        }}
      >
        {label}
      </text>
      <text
        x={0}
        y={30}
        dominantBaseline="central"
        style={{
          fontSize: 12,
          cursor: onClick === null ? 'default' : 'pointer',
          fill: '#4A5568',
        }}
      >
        {data.indexValue}
      </text>
    </g>
  )
}

type HotSpotData = Array<{ id: string; count: number }>

type Props = {
  data: HotSpotData
  title: string
  onChartItemClick?: (itemData) => void
}

const HotspotChart = ({ title, data, onChartItemClick }: Props) => (
  <ContentPanel>
    <ContentPanelHeader>{title}</ContentPanelHeader>
    <Box sx={{ height: '300px', p: '24px' }}>
      {data.length !== 0 ? (
        <ResponsiveBar
          data={data}
          labelSkipHeight={12}
          padding={0.1}
          labelSkipWidth={10}
          keys={['count']}
          indexBy="id"
          onClick={onChartItemClick || null}
          barComponent={BarComponent}
          theme={nivoTheme}
          layout="horizontal"
          colors={colors.default}
          labelTextColor={colors.assetResultsOverTime.labelTextColor}
          enableGridX={false}
          enableGridY={false}
          axisBottom={{
            format: () => null,
          }}
        />
      ) : (
        <EmptyState />
      )}
    </Box>
  </ContentPanel>
)

export default HotspotChart
