/** @jsx jsx */
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Flex, jsx } from 'theme-ui'
import Menu from './menu'
import BottomMenu from './bottom-menu'
import { ReactComponent as Shuki } from '../../assets/sidebar/shukiLogo.svg'

type Props = {
  collapsed: boolean
  links: { [key: string]: Function | string }
  location: any
  onCollapseClick: () => void
}

const Sidebar = ({
  collapsed = true,
  links,
  location,
  onCollapseClick,
}: Props) => {
  return (
    <StyledSidebar trigger={null} collapsible collapsed={collapsed}>
      <Logo to={links.hive}>
        <Shuki />
      </Logo>

      <Menu links={links} collapsed={collapsed} location={location} />
      <BottomSection>
        <BottomMenu
          links={links}
          collapsed={collapsed}
          location={location}
          onCollapseClick={onCollapseClick}
        />
      </BottomSection>
    </StyledSidebar>
  )
}
const StyledSidebar = styled(Flex)`
  width: ${({ collapsed }) => (collapsed ? 80 : 200)}px;
  flex-direction: column;
  transition: width 200ms;
  overflow: none;
  height: 100vh;
  position: fixed;
  left: 0;
  background: linear-gradient(180deg, #5f22b9 0%, #230d2e 100%);
  z-index: 900;
`
const Logo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

const BottomSection = styled.div`
  width: 100%;
  margin-top: auto;
`

export default Sidebar
