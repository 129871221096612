import { ExclamationCircleOutlined } from '@ant-design/icons'
import React from 'react'
import { Box, Text } from 'theme-ui'
import Banner, { BannerType } from '../../components/banner'
import CriticalIssuesDismissModal from '../modals/critical-issues-dismiss-modal'
import theme from '../../common/theme'

const CriticalIssuesAlert = ({
  isDismissModalDisplayed,
  isBannerDisplayed,
  onDismissConfirmation,
  onDismissCancelation,
  onReviewAssetsClicked,
  onDismiss,
}) => {
  return (
    <Box>
      {isDismissModalDisplayed && (
        <CriticalIssuesDismissModal
          onOk={() => onDismissConfirmation()}
          onCancel={onDismissCancelation}
        />
      )}
      {isBannerDisplayed && (
        <Box sx={{ width: '100%' }}>
          <Banner
            actionButtonText="Review assets"
            messageText={
              <Text>
                Oh oh, you have some{' '}
                <span
                  style={{
                    color: theme.stylebook.colors.severities.criticalHEX,
                  }}
                >
                  critical
                </span>{' '}
                issues
              </Text>
            }
            actioButtonCallback={onReviewAssetsClicked}
            dismissButtonCallback={onDismiss}
            dismissButtonText="Dismiss"
            type={BannerType.Error}
            icon={<ExclamationCircleOutlined />}
          />
        </Box>
      )}
    </Box>
  )
}

export default CriticalIssuesAlert
