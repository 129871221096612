/* eslint-disable @typescript-eslint/no-use-before-define */
/** @jsx jsx */
import { useEffect, useReducer } from 'react'
import { jsx } from 'theme-ui'
import { LoginType } from '@spectral/types'
import SelectDataSource from './steps/select-data-source'
import Modes from './steps/no-public-scan-select-mode'
import SnippetWindow from '../source-integration/snippet-window'
import { IWizardTracking } from './utils/tracking'
import Source from '../../common/sources/sources.types'
import Wizard from './view'
import { IDigestLinks, ActionType, WizardMode } from './types'
import { wizardReducer, initialState } from './reducer'
import { getDownloadSnippet } from '../../common/sources/sources'
import useDSN from '../../hooks/useDSN'

type Props = {
  teamKey: string
  tracker: IWizardTracking
  teamPid: string
  teamName: string
  loginType: LoginType
  userApiKey: string
  sources: Source
  linkExternal: Function
  digestLinks: IDigestLinks
  location: any
}

const getModeText = (mode) => {
  if (mode === 'mitigate') {
    return 'Scan & Protect'
  }
  if (mode === 'audit') {
    return 'Audit'
  }
  return 'Select your option'
}
const getDatasourceText = (mode, text) => {
  if (text || !mode) {
    return text
  }

  if (mode === WizardMode.mitigate) {
    return 'Select your CI System'
  }
  if (mode === WizardMode.audit) {
    return 'Select code location'
  }
  return null
}

const WizardSelectModeCiOrAudit = ({
  teamKey,
  tracker,
  teamPid,
  teamName,
  loginType,
  userApiKey,
  sources,
  linkExternal,
  digestLinks,
  location,
}: Props) => {
  const [state, dispatch] = useReducer(wizardReducer, initialState)
  const { mode, dataSource, currentStep, operatingSystem } = state

  useEffect(() => {
    dispatch({ type: ActionType.RESET })
  }, [location])

  const { dsn, scanKey, setIsTeamTokenShown } = useDSN(
    teamKey,
    userApiKey,
    loginType
  )

  const onBack = () => {
    tracker?.back({ fromStep: steps[currentStep].trackingName })
    if (currentStep === 1) {
      dispatch({ type: ActionType.SET_MODE, payload: { mode: null } })
      dispatch({
        type: ActionType.SET_DATA_SOURCE,
        payload: { dataSource: { title: null } },
      })
    }
    dispatch({ type: ActionType.DECREASE_STEP })
  }

  const forward = () => {
    tracker?.progress({ fromStep: steps[currentStep].trackingName })
    dispatch({ type: ActionType.INCREASE_STEP })
  }

  const modeSelected = (selectedMode) => {
    tracker?.modeSelected({ mode: selectedMode })
    dispatch({ type: ActionType.SET_MODE, payload: { mode: selectedMode } })
    forward()
  }

  const dataSourceSelected = (selectedDataSource) => {
    tracker?.dataSourceSelected({ mode, dataSource: selectedDataSource })
    if (selectedDataSource.externalLink)
      linkExternal(selectedDataSource.externalLink)
    else {
      dispatch({
        type: ActionType.SET_DATA_SOURCE,
        payload: { dataSource: selectedDataSource },
      })
      forward()
    }
  }

  const snippetWindow = {
    title: dataSource?.title ? `Scan with ${dataSource.title}` : 'Scan',
    trackingName: 'Snippet Window',
    component: SnippetWindow,
    props: {
      scanKey,
      dsn,
      mode,
      operatingSystem,
      setOperatingSystem: () =>
        dispatch({ type: ActionType.CHANGE_OPERATING_SYSTEM }),
      downloadSnippet: getDownloadSnippet(operatingSystem),
      dataSource,
      digestLinks,
      onCopy: tracker?.snippetCopied.bind(tracker),
      shouldShowTeamToken: loginType === LoginType.Team,
      onSwitchTokenShown: setIsTeamTokenShown,
    },
  }

  const steps = [
    {
      title: getModeText(mode),
      trackingName: 'Mode Selection',
      component: Modes,
      props: {
        onModeSelect: modeSelected,
        showModes: [WizardMode.audit, WizardMode.mitigate],
      },
    },
    {
      title: getDatasourceText(mode, dataSource.title),
      trackingName: 'Data Source Selection',
      component: SelectDataSource,
      props: {
        onDataSourceSelect: dataSourceSelected,
        dataSource,
        mode,
        operatingSystem,
        sources,
      },
    },
    snippetWindow,
  ]

  return (
    <Wizard
      tracker={tracker}
      steps={steps}
      onBack={onBack}
      currentStep={currentStep}
      teamPid={teamPid}
      teamName={teamName}
    />
  )
}

export default WizardSelectModeCiOrAudit
