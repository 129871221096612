/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useSelector } from 'react-redux'
import { select } from '../../../redux/store'
import { OrgTeamJiraIntegration } from '../../../blocks/settings/org-teams/integrations/jira'

export const OrgTeamJiraIntegrationConnected = ({ projectId }) => {
  const { jiraSettings } = useSelector(select.Teams.teamSettings) || {}
  const { domain } = jiraSettings || {}
  const { projects } = useSelector(select.Jira.createIssueModal) || {}
  return (
    <OrgTeamJiraIntegration
      projects={projects}
      currentJiraProjectId={projectId}
      domain={domain}
    />
  )
}
