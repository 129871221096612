/** @jsx jsx */
// eslint-disable-next-line
import React from 'react'
import { jsx } from 'theme-ui'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

export const WeakLink = ({ children, to }) => (
  <Link to={to}>
    <Button type="link" sx={{ color: 'gray.400' }}>
      {children}
    </Button>
  </Link>
)
