import { Alert, Button, Modal } from 'antd'
import { Flex, Text, Box } from 'theme-ui'
import L from 'lodash'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import jiraLogo from '../../assets/png/jira-logo.png'
import { Loading } from '../../components/loading'
import { JiraProject } from './jira-project'
import {
  JiraCustomField,
  getNonSupportedFields,
  fieldsValidators,
} from './jira-custom-field'
import {
  CustomFormComponent,
  FieldSectionLayout,
  InputFieldSection,
  PanelForm,
  SelectFieldSection,
  SubmitSection,
  TextFieldSection,
} from '../../components/panels/content'
import { getRequiredFields } from './util'

const supportedIssueTypes = ['Task', 'Bug', 'Story']
const exclude_required_fields = [
  'description',
  'summary',
  'issuetype',
  'project',
  'reporter',
]

const NotSupportedAlert = ({ notSupportedList }) => (
  <Box sx={{ margin: '20px' }}>
    <Alert
      type="error"
      message="Non supported issue type."
      description={`Some fields are required, but are not supported by us: ${notSupportedList}. Please choose a different project or issue type.`}
      closable={false}
      banner
    />
  </Box>
)

const toProjectOptions = (projects) =>
  L.map(projects, (project) => ({
    value: project?.key,
    label: project?.name,
    avatar: project?.avatarUrls['16x16'],
    key: `project_${project.key}`,
  }))

const toIssueTypeOptions = (projects, selectedProject) => {
  if (!selectedProject) return []
  const currentProject = L(
    L.filter(projects, (project) => project.key === selectedProject)
  ).first()
  if (!currentProject) return []
  const supportedProjectIssueTypes = L.filter(
    currentProject.issuetypes,
    (issueType) => supportedIssueTypes.includes(issueType.name)
  )
  return L.map(supportedProjectIssueTypes, (issueType) => ({
    value: issueType.name,
    name: issueType.name,
    key: `issue_type_${issueType.name}`,
  }))
}

export const JiraCreateIssueContent = ({
  issue,
  onFinish,
  isFormLoading,
  isIssueCreationInProgress,
  createIssueError,
  formDataError,
  description,
  projects,
  currentProject,
  setCurrentProject,
  currentIssueType,
  setCurrentIssueType,
}) => {
  const projectOptions = toProjectOptions(projects)
  const issueTypesOptions = toIssueTypeOptions(projects, currentProject)
  const requiredFields = getRequiredFields(
    projects,
    currentProject,
    currentIssueType,
    exclude_required_fields
  )

  const notSupportedRequiredFields = getNonSupportedFields(requiredFields)
  const isSupportingAllFields = notSupportedRequiredFields.length === 0

  useEffect(() => {
    if (!currentProject && projectOptions && projectOptions.length > 0) {
      setCurrentProject((L.first(projectOptions) as any)?.value)
    }
  }, [projectOptions, currentProject, setCurrentProject])

  const initialValues = {
    projectKey: currentProject,
    description,
    issueTypeKey: currentIssueType,
  }
  const requiredFieldsValidator = isSupportingAllFields
    ? fieldsValidators(requiredFields)
    : {}

  // eslint-disable-next-line no-nested-ternary
  return !isFormLoading && !formDataError ? (
    <fieldset disabled={isIssueCreationInProgress}>
      <PanelForm
        onSubmit={onFinish}
        initialValues={initialValues}
        validationSchema={Yup.object({
          projectKey: Yup.string().required('Required'),
          summary: Yup.string().required('Required'),
          ...requiredFieldsValidator,
        })}
      >
        {createIssueError && <Alert type="error" message={createIssueError} />}
        <SelectFieldSection
          name="projectKey"
          label="Project"
          description=""
          placeholder="Project"
          layout={FieldSectionLayout.Vertical}
          options={projectOptions}
          filterOption={(input, option) =>
            option.children.props.label
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onRenderOption={({ label, avatar }) => (
            <JiraProject label={label} avatar={avatar} />
          )}
          onSelectAction={setCurrentProject}
          required
          merge
        />
        <SelectFieldSection
          name="issueTypeKey"
          label="Issue type"
          description=""
          placeholder="Issue type"
          layout={FieldSectionLayout.Vertical}
          options={issueTypesOptions}
          filterOption={(input, option) =>
            option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onRenderOption={({ name }) => <Text>{name}</Text>}
          onSelectAction={setCurrentIssueType}
          required
          merge
        />
        <InputFieldSection
          name="summary"
          label="Summary"
          allowClear
          layout={FieldSectionLayout.Vertical}
          description=""
          placeholder="Summary"
          required
          merge
        />
        <CustomFormComponent>
          {(setFieldValue, setFieldTouched, validateForm) => (
            <Button
              type="link"
              style={{ marginLeft: '8px', fontSize: '14px' }}
              onClick={async () => {
                await setFieldValue(
                  'summary',
                  `Spectral | ${issue.asset.id} (${L.last(
                    issue.filePath.split('/')
                  )}) - ${issue.detectorName} (${issue.detectorId})`
                )
                await setFieldTouched('summary')
                validateForm()
              }}
              size="small"
            >
              Use recommended summary template
            </Button>
          )}
        </CustomFormComponent>
        <TextFieldSection
          name="description"
          label="Description"
          height={300}
          merge
          description=""
          layout={FieldSectionLayout.Vertical}
        />
        {isSupportingAllFields ? (
          requiredFields.map((field) => (
            <JiraCustomField field={field} key={field.key} />
          ))
        ) : (
          <NotSupportedAlert
            notSupportedList={notSupportedRequiredFields
              .map((f) => f.name)
              .join(',')}
          />
        )}
        <SubmitSection merge disabled={!isSupportingAllFields} text="Create" />
      </PanelForm>
    </fieldset>
  ) : isFormLoading ? (
    <Loading />
  ) : (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      Sorry about that, something broke. Please try again later.
    </Flex>
  )
}

const JiraCreateIssueModalView = ({
  issue,
  onFinish,
  onClose,
  isFormLoading,
  isIssueCreationInProgress,
  createIssueError,
  formDataError,
  projects,
  description,
  currentProject,
  setCurrentProject,
  currentIssueType,
  setCurrentIssueType,
}) => {
  return (
    <Modal
      visible
      footer={null}
      title={
        <Flex>
          <img width="20" src={jiraLogo} alt="Jira" />
          <Text ml={2}>Create Jira Issue</Text>
        </Flex>
      }
      onCancel={onClose}
      width={800}
    >
      <JiraCreateIssueContent
        issue={issue}
        onFinish={onFinish}
        isFormLoading={isFormLoading}
        isIssueCreationInProgress={isIssueCreationInProgress}
        createIssueError={createIssueError}
        formDataError={formDataError}
        projects={projects}
        description={description}
        currentProject={currentProject}
        setCurrentProject={setCurrentProject}
        currentIssueType={currentIssueType}
        setCurrentIssueType={setCurrentIssueType}
      />
    </Modal>
  )
}
export default JiraCreateIssueModalView
