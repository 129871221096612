import github from '../../assets/svg/github.svg'
import google from '../../assets/svg/google.svg'
import gitlab from '../../assets/svg/gitlab.svg'
import ubuntu from '../../assets/svg/ubuntu.svg'
import circleCI from '../../assets/svg/circle-ci.svg'
import codebuild from '../../assets/svg/aws-codebuild.svg'
import azuredevops from '../../assets/png/azuredevops.png'
import tfc from '../../assets/png/tfc.png'
import vsCode from '../../assets/png/vs-code.png'
import gcpbuild from '../../assets/png/gcpbuild.png'
import nas from '../../assets/png/nas.png'
import jfrogPipline from '../../assets/png/jfrog-pipeline.png'
import jenkins from '../../assets/svg/jenkins.svg'
import travisCI from '../../assets/svg/travis-ci.svg'
import githubActions from '../../assets/png/github-actions.png'
import gitlabci from '../../assets/png/gitlab-ci.png'
import bitbucketPipeline from '../../assets/png/bitbucket.png'
import other from '../../assets/svg/git.svg'
import jira from '../../assets/svg/jira.svg'
import confluence from '../../assets/svg/confluence.svg'
import slack from '../../assets/svg/slack.svg'
import s3 from '../../assets/svg/s3.png'
import kubernetes from '../../assets/svg/kubernetes.svg'
import http from '../../assets/svg/http.svg'

export const imageMap = {
  'audit-github': github,
  'monitor-github': github,
  'github-connect': github,
  'github-native': github,
  'gitlab-bot': gitlab,
  'audit-gitlab': gitlab,
  'monitor-gitlab': gitlab,
  'audit-local': other,
  'other-ci': other,
  'circle-ci': circleCI,
  'travis-ci': travisCI,
  'github-actions': githubActions,
  'gitlab-ci': gitlabci,
  'bitbucket-pipeline': bitbucketPipeline,
  'jfrog-pipline': jfrogPipline,
  'on-premise': nas,
  'host-other': other,
  'github-discover': github,
  'vs-code': vsCode,
  bitbucket: bitbucketPipeline,
  tfc,
  ubuntu,
  jenkins,
  codebuild,
  azuredevops,
  gcpbuild,
  jira,
  confluence,
  slack,
  s3,
  kubernetes,
  http,
  github,
  google,
  gitlab,
  other,
  circleCI,
  travisCI,
  githubActions,
  gitlabci,
  bitbucketPipeline,
  jfrogPipline,
}
