import React from 'react'

const cubes = (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.32"
      width="6.89747"
      height="6.87455"
      transform="matrix(-0.867456 0.495859 2.22512e-08 1 18.9129 4.42114)"
      fill="white"
    />
    <rect
      width="6.89746"
      height="6.89746"
      transform="matrix(0.867457 0.497513 -0.867457 0.497513 12.9296 0.989502)"
      fill="url(#cubes_icon_small_0)"
    />
    <rect
      opacity="0.32"
      width="6.89747"
      height="6.87455"
      transform="matrix(-0.867456 0.495859 2.22512e-08 1 24.8963 14.7158)"
      fill="white"
    />
    <rect
      width="6.89746"
      height="6.89746"
      transform="matrix(0.867457 0.497513 -0.867457 0.497513 18.913 11.2842)"
      fill="url(#cubes_icon_small_1)"
    />
    <rect
      opacity="0.32"
      width="6.89747"
      height="6.87455"
      transform="matrix(-0.867456 0.495859 2.22512e-08 1 12.9295 14.7158)"
      fill="white"
    />
    <rect
      width="6.89746"
      height="6.89746"
      transform="matrix(0.867457 0.497513 -0.867457 0.497513 6.94623 11.2842)"
      fill="url(#cubes_icon_small_2)"
    />
    <defs>
      <linearGradient
        id="cubes_icon_small_0"
        x1="7.97734"
        y1="1.07989"
        x2="1.38136"
        y2="9.59896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="cubes_icon_small_1"
        x1="7.97734"
        y1="1.07989"
        x2="1.38136"
        y2="9.59896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="cubes_icon_small_2"
        x1="7.97734"
        y1="1.07989"
        x2="1.38136"
        y2="9.59896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default cubes
