import React from 'react'
import { Box, Flex } from 'theme-ui'
import { ReportIssueIgnoreDetails, ValidationResult } from '@spectral/types'
import { Tag } from 'antd'
import IgnoreDetailsView from './ignore-details-view'

import { DateFormat } from '../../../components/formatters'

const IssueValidationDisplayMapper = {
  valid: 'Valid',
  invalid: 'Invalid',
}

interface Props {
  isExpanded: boolean
  detectorDescription?: string
  fingerprint?: string
  ignoreDetails?: ReportIssueIgnoreDetails
}
const IssueCollapseContent = ({
  isExpanded,
  detectorDescription = null,
  fingerprint = null,
  ignoreDetails = null,
  validation = null,
}) => {
  if (!detectorDescription && !fingerprint && !validation && !ignoreDetails)
    return null

  const renderValidationResult = () => {
    if (!validation) return null

    if (validation.result === ValidationResult.Error) {
      return (
        <Box>
          The validation of this secret has been errored during the last scan,
          please scan again for updated result
        </Box>
      )
    }
    return (
      <Box>
        This issue has been identified as{' '}
        <strong>
          <code>{IssueValidationDisplayMapper[validation.result]}</code>{' '}
        </strong>
        at <DateFormat date={validation?.lastValidation} />
      </Box>
    )
  }

  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        my: '15px',
        gap: '10px',
        transition: 'max-height 0.3s ease-in-out',
        maxHeight: isExpanded ? '250px' : 0,
        overflow: 'hidden',
      }}
    >
      {detectorDescription && (
        <Box>
          <Box sx={{ fontWeight: 'bold' }}>Detector description:</Box>
          <Box>{detectorDescription}</Box>
        </Box>
      )}
      {validation && (
        <Box>
          <Box sx={{ fontWeight: 'bold' }}>Validation result:</Box>
          {renderValidationResult()}
        </Box>
      )}
      {ignoreDetails && <IgnoreDetailsView ignoreDetails={ignoreDetails} />}
      {fingerprint && (
        <Flex mt={2}>
          <Tag>
            <strong>Fingerprint</strong> {fingerprint}
          </Tag>
        </Flex>
      )}
    </Flex>
  )
}

export default IssueCollapseContent
