import { Box, Image } from 'theme-ui'
import React from 'react'
import { notification } from 'antd'
import { XMLValidator } from 'fast-xml-parser'
import SamlAuthSettings from './saml-auth-settings'
import samlLogo from '../../../assets/svg/saml.svg'
import { PageTitle } from '../../../components/page'
import tracker from '../../../common/track'
import showMultipleDomainsModal from '../../../components/sso-setup/multiple-domains-popup-config'
import {DOCS_URL} from "../../../utils/constants";

type Props = {
  authSettings: any
  onSubmit: Function
  loginURL: string
  ssoURL: string
  audienceURL: string
  isUserEmailVerified: boolean
  userEmailDomain: string
  currentTeamPid: string
}

const SSOSetup = ({
  authSettings,
  onSubmit,
  loginURL,
  ssoURL,
  audienceURL,
  userEmailDomain,
  currentTeamPid,
  isUserEmailVerified = false,
}: Props) => {
  const handleSAMLUpdate = async ({ domains, metadata }, { resetForm }) => {
    if (!isUserEmailVerified) {
      resetForm()
      return notification.error({
        message: 'Please verify your email address before setting up SSO',
      })
    }

    if (domains.length === 1) {
      if (domains[0] !== userEmailDomain) {
        resetForm()
        return notification.error({
          message: 'This email domain does not belong to your organization',
        })
      }
    } else {
      return showMultipleDomainsModal(currentTeamPid)
    }

    const payload = {
      providers: {
        saml: {
          domains: [...domains],
          audience: audienceURL,
        },
      },
    }

    const hasMetadataUpdate = metadata?.length > 0
    if (hasMetadataUpdate) {
      const isValidXML = XMLValidator.validate(metadata)
      // validate returns true (bool) or error object
      if (isValidXML !== true) {
        return notification.error({
          message: 'Metadata file does not contain valid XML',
        })
      }
      // @ts-ignore
      payload.providers.saml.metadata = metadata
    }

    const success = await onSubmit({ ...payload })
    if (!success) {
      resetForm()
      tracker.event('SSO_CONFIGURATION_SAVED', {
        hasMetadataUpdate,
        status: 'error',
      })
    } else {
      tracker.event('SSO_CONFIGURATION_SAVED', {
        hasMetadataUpdate,
        status: 'success',
      })
    }
  }

  const samlConfigurationCompleted =
    authSettings?.providers?.['saml']?.domains.length > 0 &&
    authSettings?.providers?.['saml']?.hasMetadata

  return (
    <Box sx={{ p: '40px' }}>
      <PageTitle>
        SSO Setup (SAML 2.0){' '}
        <Image
          sx={{ height: '37px', marginBottom: '5px' }}
          src={samlLogo}
          alt="Spectral SSO login (SAML 2.0)"
        />
      </PageTitle>

      <Box sx={{ mb: '20px' }}>
        Spectral supports login SSO login via SAML 2.0 with short and simple
        configuration.
        <br />
        <Box sx={{ mb: '10px' }}>
          For full instructions for configuring SSO for Spectral with
          step-by-step guides for{' '}
          <a
            href={`${DOCS_URL}sso-okta`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>OKTA</strong>
          </a>{' '}
          and{' '}
          <a
            href={`${DOCS_URL}sso-onelogin`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>OneLogin</strong>
          </a>{' '}
          please refer to our{' '}
          <a
            href={`${DOCS_URL}sso`}
            target="_blank"
            rel="noopener noreferrer"
          >
            docs
          </a>
          .
        </Box>
      </Box>

      <SamlAuthSettings
        loginUrl={loginURL}
        ssoUrl={ssoURL}
        audienceUrl={audienceURL}
        domains={authSettings?.providers?.['saml']?.domains}
        metadataXMLAlreadySet={authSettings?.providers?.['saml']?.hasMetadata}
        onSubmit={handleSAMLUpdate}
        samlConfigurationCompleted={samlConfigurationCompleted}
      />
    </Box>
  )
}

export default SSOSetup
