import Icon from '@ant-design/icons'
import { AssetType } from '@spectral/types'
import { Button, Tooltip } from 'antd'
import React from 'react'
import { Box, Flex, Image } from 'theme-ui'
import isEmpty from 'lodash/isEmpty'
import jiraLogo from '../../../assets/svg/jira.svg'
import tfcLogo from '../../../assets/png/tfc.png'
import mondayLogo from '../../../assets/svg/monday.svg'
import confluenceLogo from '../../../assets/svg/confluence.svg'
import slackLogo from '../../../assets/svg/slack.svg'
import tracker from '../../../common/track'

const IssueActionButton = ({
  tooltip = null,
  onClick,
  icon = null,
  image = null,
  text,
}) => (
  <Tooltip title={tooltip}>
    <Button
      type="default"
      style={{
        fontSize: '14px',
        marginRight: '4px',
        cursor: onClick ? 'pointer' : 'default',
      }}
      icon={
        // eslint-disable-next-line no-nested-ternary
        image ? (
          <Image
            sx={{
              width: '14px',
              height: '14px',
              mr: 1,
              marginTop: '-2px',
            }}
            src={image}
          />
        ) : icon ? (
          <Icon component={icon} />
        ) : null
      }
      size="small"
      onClick={onClick}
    >
      {text}
    </Button>
  </Tooltip>
)

const tags = {
  jira: {
    isVisible: (issue) => issue?.jira?.issueKey,
    text: 'Jira',
    image: jiraLogo,
    onClick: (issue, integrations) => {
      const jiraEndpoint = integrations?.jira?.jiraEndpoint
      if (!jiraEndpoint) return null
      const jiraIssueLink = `${jiraEndpoint}/browse/${issue.jira?.issueKey}`
      window.open(`//${jiraIssueLink}`, '_blank', 'noopener,noreferrer')
      return true
    },
  },
  monday: {
    isVisible: (issue) => issue?.monday?.mondayItemId,
    text: 'Monday',
    image: mondayLogo,
    onClick: (issue, integrations) => {
      const mondayEndpoint = integrations?.monday?.domain
      if (!mondayEndpoint) return null
      const mondayItemLink = `${mondayEndpoint}/boards/${issue.monday?.mondayBoardId}/pulses/${issue.monday?.mondayItemId}`
      window.open(`//${mondayItemLink}`, '_blank', 'noopener,noreferrer')
      return true
    },
  },
  jiraComment: {
    isVisible: (issue) =>
      issue.asset.type === AssetType.Jira &&
      issue.metadata.source === 'comment',
    text: 'Comment',
    tooltip: () => 'Secret in a Jira issue comment',
    image: jiraLogo,
  },
  jiraDescription: {
    isVisible: (issue) =>
      issue.asset.type === AssetType.Jira &&
      issue.metadata.source === 'description',
    text: 'Description',
    tooltip: () => 'Secret in the Jira issue description',
    image: jiraLogo,
  },
  jiraSummary: {
    isVisible: (issue) =>
      issue.asset.type === AssetType.Jira &&
      issue.metadata.source === 'summary',
    text: 'Summary',
    tooltip: () => 'Secret in the Jira issue summary',
    image: jiraLogo,
  },
  jiraAttachment: {
    isVisible: (issue) =>
      issue.asset.type === AssetType.Jira &&
      issue.metadata.source === 'attachment',
    text: 'Issue Attachment',
    tooltip: (issue) =>
      `Secret in the Jira issue attachment: "${
        issue.metadata.attachmentFileName
      }" ${issue.metadata.virtual_path ?? ` » ${issue.metadata.virtual_path}`}`,
    image: jiraLogo,
  },
  tfcPostPlan: {
    isVisible: (issue) =>
      issue.asset.type === AssetType.Automation &&
      (issue.asset.source === 'Terraform Cloud' ||
        issue.asset.source === 'Terraform Enterprise') &&
      issue.metadata.stage === 'post_plan',
    text: 'Post Plan',
    tooltip: (issue) =>
      `This issue was created from a Post-plan Run Task in Terraform Cloud / Enterprise`,
    image: tfcLogo,
  },
  tfcPrePlan: {
    isVisible: (issue) =>
      issue.asset.type === AssetType.Automation &&
      (issue.asset.source === 'Terraform Cloud' ||
        issue.asset.source === 'Terraform Enterprise') &&
      issue.metadata.stage === 'pre_plan',
    text: 'Pre Plan',
    tooltip: (issue) =>
      `This issue was created from a Pre-plan Run Task in Terraform Cloud / Enterprise`,
    image: tfcLogo,
  },
  confluencePageContent: {
    isVisible: (issue) =>
      issue.asset.type === AssetType.Confluence &&
      issue.metadata.source === 'page',
    text: 'Page content',
    tooltip: () => 'Secret in a Confluence page',
    image: confluenceLogo,
  },
  confluencePageComment: {
    isVisible: (issue) =>
      issue.asset.type === AssetType.Confluence &&
      issue.metadata.source === 'comment',
    text: 'Page comment',
    tooltip: () => 'Secret in a Confluence page comment',
    image: confluenceLogo,
  },
  confluenceAttachment: {
    isVisible: (issue) =>
      issue.asset.type === AssetType.Confluence &&
      issue.metadata.source === 'attachment',
    text: 'Page Attachment',
    tooltip: (issue) =>
      `Secret in the Confluence page attachment: "${
        issue.metadata.attachmentFileName
      }"  ${
        issue.metadata.virtual_path ?? ` » ${issue.metadata.virtual_path}`
      }`,
    image: confluenceLogo,
  },
  slackAttachment: {
    isVisible: (issue) =>
      issue.asset.type === AssetType.Slack &&
      issue.metadata.source === 'attachment',
    text: 'Message Attachment',
    tooltip: (issue) =>
      `Secret in Slack attachment: "${issue.metadata.attachmentFileName}"  ${
        issue.metadata.virtual_path ?? ` » ${issue.metadata.virtual_path}`
      }`,
    image: slackLogo,
    onClick: (issue) => {
      window.open(
        issue.metadata.attachmentPermalink,
        '_blank',
        'noopener,noreferrer'
      )
    },
  },
}

const tagsOnClick = (name, numTags) => {
  tracker.clickTag({ tagKind: name, numVisible: numTags })
}

const IssueTags = ({ issue, integrations, externalConf = {} }) => {
  const tagKeys = Object.keys(tags)
  const numTags = tagKeys.filter((tagKey) => tags[tagKey].isVisible(issue))
    .length
  return (
    <Box sx={{ mb: numTags === 0 ? 0 : '8px' }}>
      <Flex sx={{ flexWrap: 'wrap', gap: '5px' }}>
        {tagKeys
          .filter((tagKey) => tags[tagKey].isVisible(issue))
          .map((tagKey) => {
            const { onClick, tooltip, text, icon, image } = tags[tagKey]
            const clickable = onClick || externalConf[tagKey]?.onClick
            const onClickHandler = () => {
              tagsOnClick(tagKey, numTags)
              if (onClick) onClick(issue, integrations)
              externalConf[tagKey]?.onClick()
            }
            return (
              <IssueActionButton
                onClick={clickable ? onClickHandler : null}
                tooltip={tooltip ? tooltip(issue) : null}
                text={text}
                icon={icon}
                image={image}
                key={`${issue.pid}_${tagKey}`}
              />
            )
          })}
      </Flex>
    </Box>
  )
}

export default IssueTags
