import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import { MsTeamsIntegrations } from '../../../../blocks/settings/integrations/ms-teams'

export const MsTeamsIntegrationConnected = () => {
  const { msTeamsSettings } = useSelector(select.Teams.teamSettings)
  const dispatch = useDispatch()
  return (
    <MsTeamsIntegrations
      setMsTeamsUrl={dispatch.Teams.setMsTeamsUrl}
      setMsTeamsEnableDisable={dispatch.Teams.setMsTeamsIntegrationStatus}
      enabled={msTeamsSettings.enabled}
      msTeamsWebhookUrl={msTeamsSettings.msTeamsWebhookUrl}
    />
  )
}
