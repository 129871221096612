import * as yup from 'yup'

export const EMPTY_CONFIGURATION_ERROR_MESSAGE =
  'Configuration YAML should be an object'
export const WRONG_IGNORE_FORMAT_ERROR_MESSAGE =
  'At least one of the following is required within ignore object: rule_id, rule_name, path, match_text, match_fingerprint, match_line_text'
export const TOP_LEVEL_MISSING_PROPERTY_ERROR_MESSAGE =
  'At least one of the following is required: match_ignores, projects'
export const MISSING_IGNORES_PROPERTY_ERROR_MESSAGE = `The array property 'ignores' is required under 'match_ignores'`
export const EMPTY_IGNORES_ARRAY_ERROR_MESSAGE = `The array property 'ignores' should not be empty, or 'match_ignores' should not exist`
export const EMPTY_PROJECTS_OBJECTS_ERROR_MESSAGE = `The property 'projects' should have at least one project`
export const OBJECT_CANT_BE_NULL =
  'Ignore object can not be null and must have at least one property'
export const MISSING_EXCLUDE_OR_INCLUDE_TAGS =
  'One of the projects rules property should have at least include or exclude properties'
export const MISSING_IDS_OR_TAGS_IN_INCLUDE =
  'Include objects must contain tags or ids propeties'
export const MISSING_IDS_OR_TAGS_IN_EXCLUDE =
  'Exclude objects must contain tags or ids propeties'
export const MISSING_IDS_TAG_IN_INCLUDE =
  'One of your include objects is missing the ids property'
export const MISSING_IDS_TAG_IN_EXCLUDE =
  'One of your exclude objects is missing the ids property'
export const MISSING_TAGS_TAG_IN_INCLUDE =
  'One of your include objects is missing the tags property'
export const MISSING_TAGS_TAG_IN_EXCLUDE =
  'One of your exclude objects is missing the tags property'

const handleIdsAndTagsUsage = (errorMessage, errorName) => {
  return yup
    .object()
    .default(null)
    .nullable()
    .shape({
      tags: yup.array().required(),
      ids: yup.array().required(),
    })
    .noUnknown(true)
    .strict()
    .test(errorName, errorMessage, (object) => {
      return !object || object.tags != null || object.ids != null
    })
}

const schema = yup
  .object()
  .typeError(EMPTY_CONFIGURATION_ERROR_MESSAGE)
  .noUnknown(true)
  .shape({
    match_ignores: yup
      .object()
      .default(null)
      .nullable()
      .noUnknown(true)
      .strict()
      .shape({
        ignores: yup
          .array()
          .of(
            yup.object().noUnknown(true).strict().shape({
              rule_id: yup.string(),
              rule_name: yup.string(),
              match_text: yup.string(),
              match_fingerprint: yup.string(),
              path: yup.string(),
              match_line_text: yup.string(),
            })
          )
          .test(
            'WRONG_IGNORE_FORMAT',
            WRONG_IGNORE_FORMAT_ERROR_MESSAGE,
            (ignores) => {
              // @ts-ignore
              return (
                ignores?.length === 0 ||
                ignores.some(
                  (ignore) =>
                    !!(
                      ignore.match_fingerprint ||
                      ignore.match_text ||
                      ignore.path ||
                      ignore.rule_id ||
                      ignore.rule_name ||
                      ignore.match_line_text
                    )
                )
              )
            }
          ),
      })
      .test(
        'MISSING_IGNORES_ERROR',
        MISSING_IGNORES_PROPERTY_ERROR_MESSAGE,
        (match_ignores) => {
          // @ts-ignore
          return !match_ignores || !!match_ignores.ignores
        }
      ),
    projects: yup
      .array()
      .default(null)
      .nullable()
      .of(
        yup
          .object()
          .noUnknown(true)
          .strict()
          .shape({
            project: yup
              .object()
              .required()
              .noUnknown(true)
              .shape({
                name: yup.string().required(),
              })
              .noUnknown(true)
              .strict()
              .required(),
            input: yup
              .array()
              .required()
              .min(1)
              .of(
                yup
                  .object()
                  .shape({
                    local: yup.string().required(),
                    name: yup.string().required(),
                  })
                  .noUnknown(true)
                  .strict()
              )
              .required(),
            rules: yup
              .object()
              .default(null)
              .nullable()
              .shape({
                roots: yup.array().min(1),
                include: handleIdsAndTagsUsage(
                  MISSING_IDS_OR_TAGS_IN_INCLUDE,
                  'MISSING_IDS_OR_TAGS_IN_INCLUDE'
                ),
                exclude: handleIdsAndTagsUsage(
                  MISSING_IDS_OR_TAGS_IN_EXCLUDE,
                  'MISSING_IDS_OR_TAGS_IN_EXCLUDE'
                ),
              })
              .test(
                'MISSING_RULES_TAG',
                `'roots' tag is missing under rules section or not having more than one root`,
                (rules) => {
                  return (
                    !rules ||
                    !rules.roots ||
                    (rules.roots && rules.roots.length > 0)
                  )
                }
              )
              .test(
                'MISSING_EXCLUDE_OR_INCLUDE_TAGS',
                MISSING_EXCLUDE_OR_INCLUDE_TAGS,
                (rules) => {
                  return (
                    !rules || rules.include != null || rules.exclude != null
                  )
                }
              ),
          })
      )
      .transform((value, originalValue) => {
        return Object.values(originalValue)
      })
      .test(
        'AT_LEAST_ONE_PROJECT',
        EMPTY_PROJECTS_OBJECTS_ERROR_MESSAGE,
        (projects) => {
          return !projects || projects.length > 0
        }
      ),
  })
  .test(
    'TOP_LEVEL_PROPERTY_CHECK',
    TOP_LEVEL_MISSING_PROPERTY_ERROR_MESSAGE,
    (object) => {
      const objectKeys = Object.keys(object)
      return (
        (objectKeys.includes('projects') && object.projects != null) ||
        (objectKeys.includes('match_ignores') && object.match_ignores != null)
      )
    }
  )

export default schema
