import { Severity } from '@spectral/types'
import theme from '../../../common/theme'

const getSeverityConfig = (severityType, counter = 0) => {
  switch (severityType) {
    case Severity.Critical:
      return {
        displayText: `${counter} CRITICAL`,
        strongColor: theme.stylebook.colors.severities.criticalHEX,
        weakColor: '#fde7e7',
      }
    case Severity.High:
      return {
        displayText: `${counter} HIGH`,
        strongColor: theme.stylebook.colors.severities.highHEX,
        weakColor: '#fff2e6',
      }
    case Severity.Medium:
      return {
        displayText: `${counter} MEDIUM`,
        strongColor: theme.stylebook.colors.severities.mediumHEX,
        weakColor: '#fff6e6',
      }
    case Severity.Low:
      return {
        displayText: `${counter} LOW`,
        strongColor: theme.stylebook.colors.severities.lowHEX,
        weakColor: '#fff9e6',
      }
    case 'ignore':
      return {
        displayText: `${counter} IGNORED`,
        strongColor: 'primaryScale.700',
        weakColor: 'primaryScale.50',
      }
    case Severity.Informational:
    default:
      return {
        displayText: `${counter} INFORMATIONAL`,
        strongColor: theme.stylebook.colors.severities.informationalHEX,
        weakColor: '#e6f3ff',
      }
  }
}

export default getSeverityConfig
