import { useCallback, useEffect } from 'react'
import parseInt from 'lodash/parseInt'
import intersection from 'lodash/intersection'
import size from 'lodash/size'
import keys from 'lodash/keys'
import useQueryStringState from './useQueryStringState'

const MAX_PAGE_SIZE = 100

function useListPageManagement(
  filtersKeys: Array<string>,
  defaultQueryStringParams: any,
  fetchCallback: Function,
  resetPageState: Function,
  trackFilterCallback: Function,
  trackSortCallback: Function,
  trackPaginationCallback: Function,
  extraFetchParams = {}
) {
  const [
    currentQueryStringParams,
    setQueryStringParam,
    clearQueryStringParam,
  ] = useQueryStringState([
    ...filtersKeys,
    'page',
    'pageSize',
    'sortBy',
    'sortDirection',
  ])
  const setQueryParam = (
    params,
    shouldMergeWithCurrentQueryString = false,
    shouldReplaceHistory = false
  ) => {
    setQueryStringParam(
      { ...params, page: params.page || 1 },
      shouldMergeWithCurrentQueryString,
      shouldReplaceHistory
    )
  }

  const fetchData = (args?) => {
    if (currentQueryStringParams.page) {
      fetchCallback({
        ...currentQueryStringParams,
        ...extraFetchParams,
        ...args,
        page: parseInt(currentQueryStringParams.page, 10),
      })
    }
  }

  useEffect(() => {
    const isInvalidPageSize = currentQueryStringParams?.pageSize > MAX_PAGE_SIZE

    const isMissingParams =
      size(
        intersection(
          keys(currentQueryStringParams),
          keys(defaultQueryStringParams)
        )
      ) < size(keys(defaultQueryStringParams))

    if (isMissingParams || isInvalidPageSize) {
      if (isInvalidPageSize) {
        setQueryStringParam({ pageSize: MAX_PAGE_SIZE }, true, true)
      }
      if (isMissingParams) {
        const initialParams: any = {}
        Object.keys(defaultQueryStringParams).forEach((paramKey) => {
          if (!currentQueryStringParams[paramKey]) {
            initialParams[paramKey] = defaultQueryStringParams[paramKey]
          }
        })

        if (Object.keys(initialParams).length > 0) {
          setQueryStringParam(initialParams, true, true)
        }
      }
    } else if (defaultQueryStringParams.tab === currentQueryStringParams.tab) {
      fetchData()
    }

    return () => {
      resetPageState()
    }
  }, [JSON.stringify(currentQueryStringParams)])

  const onPaginationChange = useCallback(
    (newPage, pageSize) => {
      setQueryStringParam({ page: newPage, pageSize })
      trackPaginationCallback({ page: newPage, pageSize })
    },
    [setQueryStringParam, trackPaginationCallback]
  )

  const trackFilter = useCallback((filterType, values) => {
    trackFilterCallback(filterType, values)
  }, [])

  const trackSort = useCallback((currentSortBy, currentSortDirtection) => {
    trackSortCallback({ currentSortBy, currentSortDirtection })
  }, [])

  return [
    currentQueryStringParams,
    setQueryParam,
    clearQueryStringParam,
    onPaginationChange,
    trackFilter,
    trackSort,
    fetchData,
  ]
}

export default useListPageManagement
