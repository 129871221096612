import React from 'react'

import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import {
  PanelForm,
  ActionFieldSection,
} from '../../../../components/panels/content'

function showGenerateKeyConfirm({ onOk }) {
  return new Promise((resolve) => {
    Modal.confirm({
      title: 'Generate new token',
      icon: <ExclamationCircleOutlined translate="no" />,
      content:
        'Are you sure? this action cannot be reverted - you will need to update all the agents in your organization',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onOk()
      },
      onCancel() {
        resolve(true)
      },
    })
  })
}

const TeamKey = ({
  currentTeam,
  onGenerateConfirm = null,
  readOnly = false,
}) => {
  return (
    <PanelForm
      onSubmit={() => showGenerateKeyConfirm({ onOk: onGenerateConfirm })}
      initialValues={{
        teamKey: currentTeam.key,
      }}
    >
      <ActionFieldSection
        name="teamKey"
        label="Team Key"
        disabled={readOnly}
        description="The team key to be used in your DSN and scanning agents"
        action="Regenerate Key"
        isSecret
        submit
      />
    </PanelForm>
  )
}

export default TeamKey
