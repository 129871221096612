import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  ActionFieldSection,
  PanelForm,
} from '../../../../components/panels/content'

function showGenerateApiKeyConfirm({ onOk }) {
  return new Promise((resolve, _reject) => {
    Modal.confirm({
      title: 'Generate new personal token',
      icon: <ExclamationCircleOutlined translate="no" />,
      content:
        'Are you sure? this action cannot be reverted - you will need to update your integrations with Spectral',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onOk()
      },
      onCancel() {
        return resolve(true)
      },
    })
  })
}

const ApiKey = ({ apiKey, onGenerateConfirm }) => {
  return (
    <PanelForm
      onSubmit={() => showGenerateApiKeyConfirm({ onOk: onGenerateConfirm })}
      initialValues={{
        apiKey,
      }}
    >
      <ActionFieldSection
        name="apiKey"
        label="Personal Token"
        description="This Token key is used in your DSN to authenticate to our scanning agents and our API"
        action="Regenerate token"
        isSecret
        submit
        value={apiKey}
      />
    </PanelForm>
  )
}

export default ApiKey
