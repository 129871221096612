/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'

import { SourcesTile } from './sources-tile'

export const TileStrip = ({ items, onClick, selectedItem, align = 'left' }) => {
  return (
    <Flex sx={{ flexWrap: 'wrap', justifyContent: align }}>
      {items.map((item) => (
        <SourcesTile
          key={item.tileKey}
          sx={{ mr: '40px', mb: '40px' }}
          onClick={() => onClick(item)}
          externalLink={item.externalLink}
          isSelected={item.tileKey === selectedItem.tileKey}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...item}
        />
      ))}
    </Flex>
  )
}
