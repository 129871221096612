/** @jsx jsx */
import { Spin } from 'antd'
import { jsx, Flex, Text } from 'theme-ui'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export const Loading = ({ text = 'Loading' }) => (
  <Flex
    sx={{
      flexDirection: 'column',
      width: '100%',
      py: '80px',
      alignItems: 'center',
    }}
  >
    <Spin indicator={antIcon} />
    <Text>{text}</Text>
  </Flex>
)
