import { Alert, notification, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Flex } from 'theme-ui'
import {
  ContentPanel,
  ContentPanelHeader,
  PanelForm,
  SubmitSection,
} from '../../../../components/panels/content'
import { Spinner } from '../../../../components/spinner'
import UrlFormatInput from './unsupported-git-platform-url-input'
import * as Yup from 'yup'
import { CopyText } from '../../../../components/buttons/copy-text'
import ScmUrlFormat from '@spectral/types/url-formats/scm-url-format'
import ScmUrlFormatsConfig from '@spectral/types/url-formats/scm-url-format-config'
import { sdkClient } from '../../../../redux/store'
import { isUrl } from '@spectral/types'

interface GitProviderUrlFormats {
  uri: string
  blameUrl: string
}

interface FallbackUrlSettingsParams {
  formats: GitProviderUrlFormats
  isParentLoading: boolean
}

export interface SampleIssue {
  start: number
  commitSha: string
  path: string
  assetUri: string
  branch: string
  assetName: string
}

const issueDataTableColumns = [
  {
    title: 'Placeholder Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
  },
  {
    title: 'Issue Data',
    dataIndex: 'value',
    key: 'value',
    width: '80%',
  },
]

const formatInputValidationTest = (sampleIssue: SampleIssue) =>
  Yup.string()
    .nullable()
    .test('not-url', 'Format is not valid', (format) => {
      try {
        const scmUrlFormat = new ScmUrlFormat(format)

        if (scmUrlFormat.format && sampleIssue) {
          const templateResult = scmUrlFormat.buildUrlFromIssue(sampleIssue)
          return isUrl(templateResult)
        }
      } catch (e) {
        return false
      }

      return true
    })

const FallbackUrlsSettings = ({
  formats,
  isParentLoading,
}: FallbackUrlSettingsParams) => {
  const dispatch = useDispatch()
  const [fetchedSampleIssue, setFetchedSampleIssue] = useState<boolean>(false)
  const [sampleIssue, setSampleIssue] = useState<SampleIssue>()

  useEffect(() => {
    const fetchSampleIssue = async () => {
      const { data } = (await sdkClient.issues().getUnsupportedScm()) as {
        data: any
      }
      setFetchedSampleIssue(true)
      setSampleIssue(
        data
          ? {
              start: data.start,
              commitSha: data.commitSha,
              path: data.path,
              assetUri: data.assetUri,
              branch: data.originBranch,
              assetName: data.assetName,
            }
          : null
      )
    }

    fetchSampleIssue()
  }, [])

  const renderContent = () => {
    const renderSampleIssueData = () => {
      if (fetchedSampleIssue && !sampleIssue) {
        return (
          <Alert
            message="All your issues seem to be originated from supported Git providers"
            type="success"
          />
        )
      }
      return (
        <>
          <Box>
            Even if your SCM is not supported by default in Spectral, you can
            still have the ability to control the construction of issue URLs.
          </Box>
          <Box>
            Below is a real issue data from your account, use the placeholders
            to build a template for an issue URL.
          </Box>
          <Box sx={{ mt: '20px' }}>
            For more information and instructions, please refer our{' '}
            <a
              target="_blank"
              href="https://guides.spectralops.io/docs/scm#unsupported-scm"
            >
              docs
            </a>
            .
            <br />
            <strong>Note:</strong> Saving of new templates would update links in
            your current issues originated from unsupported SCM.
          </Box>
          <Box sx={{ mt: '30px', mb: '25px' }}>
            We recommend, if possible, to use commit SHA in your URLs instead of
            branch name to ensure that the reference is stable, accurate, and
            traceable over time.
          </Box>
          <Box sx={{ mt: '30px', mb: '25px' }}>
            <Table
              columns={issueDataTableColumns}
              dataSource={sampleIssueDataSource}
              pagination={false}
            />
          </Box>
        </>
      )
    }

    if (isParentLoading || !fetchedSampleIssue) {
      return (
        <Flex
          sx={{
            height: '120px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {Spinner}
        </Flex>
      )
    } else {
      return (
        <>
          <Box p="3">{renderSampleIssueData()}</Box>
          <PanelForm
            initialValues={{
              uri: formats.uri,
              blameUrl: formats.blameUrl,
            }}
            validationSchema={Yup.object()
              .shape({
                uri: formatInputValidationTest(sampleIssue),
                blameUrl: formatInputValidationTest(sampleIssue),
              })
              .test(
                'both-or-none',
                'Both uri and blameUrl must be filled or both must be empty',
                function (item) {
                  try {
                    new ScmUrlFormatsConfig({
                      default: {
                        uri: item.uri,
                        blameUrl: item.blameUrl,
                      },
                    })
                  } catch {
                    return this.createError({
                      path: '_form',
                      message:
                        'Both uri and blameUrl formats must be filled or both must be empty',
                    })
                  }

                  return true
                }
              )}
            onSubmit={onSubmit}
          >
            <UrlFormatInput
              key="uriFormatInput"
              sampleIssue={sampleIssue}
              name="uri"
              description="A fallback formula for building the Uri of an issue"
              placeholder="{{{assetUri}}}/src/branch/{{{branch}}}{{{path}}}#L{{{start}}}"
              label="Issue Uri"
              initialValue={formats.uri}
            />
            <UrlFormatInput
              key="blameUrlFormatInput"
              sampleIssue={sampleIssue}
              name="blameUrl"
              description="A fallback formula for building the blame URL of an issue"
              placeholder="{{{assetUri}}}/blame/branch/{{{branch}}}{{{path}}}#L{{{start}}}"
              label="Blame URL"
              initialValue={formats.blameUrl}
            />
            <SubmitSection text="Update" />
          </PanelForm>
        </>
      )
    }
  }

  const onSubmit = async (values) => {
    // prevent saving empty string
    const uriValue = values.uri || null
    const blameUrlValue = values.blameUrl || null

    const result = await onUpdateUrlFormats({
      default: { uri: uriValue, blameUrl: blameUrlValue },
    })
    if (result.error) {
      notification.error({
        message: 'Failed',
        description: result.error,
      })
    } else {
      notification.success({
        message: 'Saved successfully',
      })
    }
  }

  const onUpdateUrlFormats = async (formats) => {
    return dispatch.SCMConfigurations.upsertUrlFormats({ formats })
  }

  const sampleIssueDataSource = sampleIssue
    ? Object.entries(sampleIssue).reduce((acc, [key, value]) => {
        return [
          ...acc,
          {
            name: (
              <Box
                sx={{
                  '.ant-tag': {
                    border: 'none',
                    backgroundColor: 'transparent',
                    fontSize: '16px',
                  },
                }}
              >
                <CopyText value={`{{{${key}}}}`} text={`{{{${key}}}}`} />
              </Box>
            ),
            value,
          },
        ]
      }, [])
    : []

  return (
    <ContentPanel>
      <ContentPanelHeader>
        Unsupported SCM issue url templates
      </ContentPanelHeader>
      {renderContent()}
    </ContentPanel>
  )
}

export default FallbackUrlsSettings
