import { Input } from 'antd'
import React from 'react'
import { Box } from 'theme-ui'

export default ({
  value,
  onChange,
  allowClear,
  style = {},
  placeholder,
  prefix,
  disabled = false,
  autoFocus = false,
}) => {
  return (
    <Box
      sx={{
        'input.ant-input': {
          fontSize: '14px',
        },
      }}
    >
      <Input
        disabled={disabled}
        value={value}
        onChange={onChange}
        allowClear={allowClear}
        size="small"
        style={{ ...style }}
        placeholder={placeholder}
        prefix={prefix}
        autoFocus={autoFocus}
      />
    </Box>
  )
}
