/* eslint-disable @typescript-eslint/no-use-before-define */
/** @jsx jsx */
import { useState, useEffect } from 'react'
import { Steps, Button } from 'antd'
import { Box, Flex, jsx } from 'theme-ui'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FiChevronLeft } from 'react-icons/fi'
import { Welcome } from './welcome'
import { IWizardTracking } from './utils/tracking'
import { CloseOutlined } from '@ant-design/icons'

type Props = {
  tracker: IWizardTracking
  steps: any
  onBack: () => void
  currentStep: number
  startWithWelcome?: boolean
  teamPid: string
  teamName: string
}

const { Step } = Steps
const StyledSteps = styled(Steps)`
  .ant-steps-item-icon {
    margin-top: 3px;
  }
  &.ant-steps {
    margin-bottom: 15px;
    padding: 0 30px;
  }
`
const wizardPanel = {
  justifyContent: 'center',
  p: '80px',
  backgroundColor: '#fff',
  border: '1px solid #d4dae2',
  borderRadius: '4px',
  boxShadow: 'rgba(37, 11, 54, 0.04) 0px 2px 0px',
}

const Wizard = ({
  tracker,
  steps,
  onBack,
  currentStep,
  startWithWelcome = false,
  teamPid,
  teamName,
}: Props) => {
  const [welcome, setWelcome] = useState(startWithWelcome)

  const welcomeClicked = () => {
    tracker?.welcomeButtonClicked()
    setWelcome(false)
  }

  useEffect(() => {
    tracker?.init({ teamPid, teamName })
    tracker?.started()
    return () => tracker?.ended()
  }, [tracker, teamPid, teamName])

  return (
    <Box sx={{ maxWidth: '1050px' }}>
      {welcome && <Welcome onButtonClick={welcomeClicked} />}
      {!welcome && (
        <Box sx={{}}>
          <Flex>
            <StyledSteps current={currentStep}>
              {steps.map((step) => (
                <Step title={step.title} key={step.title} />
              ))}
            </StyledSteps>
            <Link to={'/'}>
              <Button
                shape="circle"
                type="link"
                icon={<CloseOutlined />}
                sx={{border: '0'}}
              />
            </Link>
          </Flex>
          <Flex sx={wizardPanel}>
            {steps[currentStep].component &&
              // @ts-ignore
              steps[currentStep].component(steps[currentStep].props)}
          </Flex>
        </Box>
      )}
      {currentStep > 0 && (
        <Box sx={{ mt: '40px' }}>
          <Button onClick={onBack}>
            <FiChevronLeft sx={{ mb: '-2px' }} />
            Back to previous step
          </Button>
        </Box>
      )}
    </Box>
  )
}
export default Wizard
