import React, { useCallback, useEffect, useState } from 'react'
import { Flex } from 'theme-ui'
import { Redirect, useLocation, Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import * as QueryString from 'query-string'
import { Empty, Button } from 'antd'
import Signup from './view'
import { Dispatch, RootState } from '../../redux/store'
import { paths } from '../../routes'
import { Loading } from '../../components/loading'

const recaptchaEnabled = !!process.env.REACT_APP_RECAPTCHA_ENABLED
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

const SignupConnected = ({
  isLoggedin,
  signup,
  signupError,
  invitationInfo,
  invitationError,
  successMessage,
  resendEmailVerification,
  resendEmailVerificationError,
  isVerificationSent,
}) => {
  const [userEmail, setUserEmail] = useState(null)
  const handleResendEmailVerification = useCallback(async () => {
    await resendEmailVerification({ email: userEmail })
  }, [userEmail, resendEmailVerification])

  const dispatch: Dispatch = useDispatch()
  const location = useLocation()

  // should include the 'invite' param
  const params = QueryString.parse(location.search) || {}
  const isInvitation = 'invite' in params
  const { invite } = params

  useEffect(() => {
    if (isInvitation) dispatch.Auth.fetchInvitationInfo({ invite })
  }, [dispatch.Auth, isInvitation, invite])

  useEffect(() => {
    return dispatch.Signup.resetSignupMessage
  }, [])

  if (isLoggedin) return <Redirect to={paths.closed.sources} />

  const renderSwitch = (param) => {
    switch (param) {
      case 'loading':
        return <Loading />
      case 'error':
        return (
          <Empty description={<span>{invitationError}</span>}>
            <Link to="/signin">
              <Button type="primary">Sign in</Button>
            </Link>
          </Empty>
        )
      default:
        return (
          <Signup
            onSubmit={(data) => {
              setUserEmail(data.email)
              signup({
                ...params,
                ...invitationInfo,
                ...data,
              })
            }}
            recaptcha={{
              enabled: recaptchaEnabled,
              siteKey: recaptchaSiteKey,
            }}
            isInvitation={isInvitation}
            invitationInfo={invitationInfo}
            links={paths.open}
            error={signupError || resendEmailVerificationError}
            successMessage={successMessage}
            resendEmailVerification={handleResendEmailVerification}
            isVerificationSent={isVerificationSent}
          />
        )
    }
  }

  let content
  if (invitationError) {
    content = renderSwitch('error')
  } else if (isInvitation && !invitationInfo.email && !invitationInfo.company) {
    content = renderSwitch('loading')
  } else {
    content = renderSwitch('signup')
  }

  return (
    <Flex sx={{ pt: '60px', justifyContent: 'center', alignItems: 'center' }}>
      {content}
    </Flex>
  )
}

const mapDispatch = (dispatch: Dispatch) => ({
  signup: ({ fullName, email, password, company, invite, recaptcha }) =>
    dispatch.Signup.signup({
      fullName,
      email,
      password,
      company,
      invite,
      recaptcha,
    }),
  resendEmailVerification: async ({ email }) => {
    await dispatch.EmailVerification.resendEmailVerification({ email })
  },
})

const mapState = (state: RootState) => ({
  isLoggedin: state.Auth.user,
  signupError: state.error.effects.Signup.signup,
  invitationInfo: state.Auth.invitationInfo,
  invitationError: state.error.effects.Auth.fetchInvitationInfo,
  successMessage: state.Signup.successMessage,
  resendEmailVerificationError:
    state.error.effects.EmailVerification.resendEmailVerification,
  isVerificationSent: state.EmailVerification.isVerificationSent,
})

export default connect(mapState, mapDispatch)(SignupConnected)
