import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RoutesBuilder } from '@spectral/types'
import { notification } from 'antd'
import spectralDetectors from 'detectorHelp/detectors-help.json'
import { select } from '../../redux/store'
import JiraCreateIssueModalView from './view'
import { DOCS_URL } from '../../utils/constants'

const toJiraIssueFields = ({
  uri,
  detectorId,
  detectorName,
  pid,
  asset: { id, url, displayName },
  metadata: { hint },
}) => {
  let message = `Spectral has detected a secret`
  if (detectorName) message = message.concat(` - ${detectorName}`)

  if (hint) {
    message = message.concat(`\nHint: ${hint}`)
  }

  message = message.concat(`\nAsset name: [${displayName}|${url}]`)

  if (uri) {
    message = message.concat(
      `\nThe issue in your repo can be found [here|${uri}]`
    )
  }

  message = message.concat(
    `\n[See playbook|${spectralDetectors[detectorId]?.link || DOCS_URL}]`,
    `\n[See Spectral issue|${RoutesBuilder({
      baseUrl: window.location.origin,
    }).issueByIssueId({
      pid,
      assetId: id,
    })}]`
  )

  return {
    description: message,
  }
}

const JiraCreateIssueModal = ({ issue, onSubmit, onClose }) => {
  const dispatch = useDispatch()
  const [currentProject, setCurrentProject] = useState(undefined)
  const [currentIssueType, setCurrentIssueType] = useState(undefined)
  const {
    isFormLoading,
    isIssueCreationInProgress,
    createIssueError,
    formDataError,
    projects,
  } = useSelector(select.Jira.createIssueModal)

  useEffect(() => {
    dispatch.error.hide({
      name: 'Jira',
      action: 'createIssue',
    })
    dispatch.Jira.fetchProjects({
      assetId: issue?.asset?.id,
    })
    return () => {
      dispatch.Jira.reset()
    }
  }, [dispatch.Jira, dispatch.error, issue])

  useEffect(() => {
    if (createIssueError) {
      notification.error({
        message: 'Sorry!',
        description: `Something went wrong while creating the Jira ticket, our engineers are working on fixing the issue.`,
      })
    }
  }, [createIssueError])

  const setCurrentProjectWrapper = (proj) => {
    // reset issue type, then set current project
    setCurrentIssueType(undefined)
    setCurrentProject(proj)
  }
  const jiraIssueFields = issue ? toJiraIssueFields(issue) : null

  const onFinish = async (values) => {
    await dispatch.Jira.createIssue({
      issueData: values,
      issue,
    })
    onSubmit()
  }

  return (
    <JiraCreateIssueModalView
      issue={issue}
      description={jiraIssueFields?.description}
      onFinish={onFinish}
      onClose={onClose}
      isFormLoading={isFormLoading}
      isIssueCreationInProgress={isIssueCreationInProgress}
      createIssueError={createIssueError}
      formDataError={formDataError}
      projects={projects}
      currentProject={currentProject}
      setCurrentProject={setCurrentProjectWrapper}
      currentIssueType={currentIssueType}
      setCurrentIssueType={setCurrentIssueType}
    />
  )
}

export default JiraCreateIssueModal
