/** @jsx jsx */
import { Tag } from 'antd'
import isNil from 'lodash/isNil'
import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { jsx } from 'theme-ui'

const AssetGitVisibility = ({ isPublic }) => {
  if (isNil(isPublic)) {
    return null
  }
  return isPublic ? (
    <Tag icon={<UnlockOutlined />}>Public</Tag>
  ) : (
    <Tag icon={<LockOutlined />}>Private</Tag>
  )
}
export default AssetGitVisibility
