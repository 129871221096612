import { Box } from 'theme-ui'
import React from 'react'
import {
  ContentPanel,
  ContentPanelHeader,
  MultiSelectFieldSection,
  PanelForm,
} from '../../../../components/panels/content'

const TagsInput = ({
  selectedAssetType,
  currentIncludeTags = ['base'],
  currentExcludeTags = [],
  disabled = false,
  allTags,
  onSubmit,
}) => {
  return (
    <Box sx={{ mt: '50px' }}>
      <ContentPanel>
        <ContentPanelHeader>
          {`Configure tags usage for ${selectedAssetType} scans`}{' '}
        </ContentPanelHeader>
        <PanelForm
          onSubmit={(values) => {
            onSubmit(values)
          }}
          initialValues={{
            exclude: currentExcludeTags,
            include: currentIncludeTags,
          }}
        >
          <MultiSelectFieldSection
            showSearch
            submit
            disabled={disabled}
            name="include"
            placeholder="Tags to include"
            label="Include tags"
            description={`Only these tags will be included in ${selectedAssetType} scans`}
            options={allTags}
          />
          <MultiSelectFieldSection
            showSearch
            submit
            disabled={disabled}
            name="exclude"
            placeholder="Tags to exclude"
            label="Exclude tags"
            description={`These tags will be excluded from ${selectedAssetType} scans`}
            options={allTags}
          />
        </PanelForm>
      </ContentPanel>
    </Box>
  )
}

export default TagsInput
