/** @jsx jsx */
import L from 'lodash'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import scrollIntoView from 'scroll-into-view-if-needed'
import { Box, Flex, jsx } from 'theme-ui'
import ActivityLog from '../../blocks/activity-log/view'
import tracker from '../../common/track'
import { RefreshButton } from '../../components/buttons/refresh'
import { Page } from '../../components/page'
import { HiveFilters } from '../../redux/models/assets'
import { Dispatch, RootState, select } from '../../redux/store'
import { paths } from '../../routes'
import { AssetTypeFilter } from './asset-type-filter'
import AssetsStats from './assets-stats'
import FiltersMenu from './filters-menu'
import { HiveView } from './hive-view'

const Hive = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch: Dispatch = useDispatch()
  const {
    AssetPage: { resetFilters },
  } = dispatch

  const { data, status, hiveStats } = useSelector(select.Assets.hivePage)

  const { loaded, pageError, isRefreshing } = status
  const activityData = useSelector(select.Teams.activity)

  const hiveFilters: HiveFilters = useSelector(
    (state: RootState) => state.Assets.hiveFilters
  )

  const highlightAssetId = L.get(location, 'state.highlightHiveCard')
  const setCurrentAsset = useCallback(
    (clickedAssetId) => {
      resetFilters()
      history.push(paths.closed.asset(encodeURIComponent(clickedAssetId)))
    },
    [history, resetFilters]
  )

  useEffect(() => {
    ;(async () => {
      await Promise.all([
        dispatch.Assets.loadHiveFilters(),
        dispatch.Assets.fetchAssetsSummary(),
        dispatch.Teams.fetchActivity(),
      ])
      if (highlightAssetId) {
        const node = document.querySelector('.quick-scan-highlighted-card')
        if (node) {
          tracker.event('QUICK_SCAN_HIGHLIGHT', {
            assetId: highlightAssetId,
          })
          scrollIntoView(node, {
            behavior: 'smooth',
            scrollMode: 'if-needed',
            block: 'center',
            inline: 'center',
          })
        }
      }
    })()
  }, [dispatch.Assets, highlightAssetId, dispatch.Teams])

  const onQuickScanHighlightComplete = useCallback(() => {
    tracker.event('QUICK_SCAN_VIEW_RESULTS_COMPLETE', {
      assetId: highlightAssetId,
    })
    history.replace('.', null)
  }, [highlightAssetId, history])

  return (
    <Page
      name="DASHBOARD"
      title="Dashboard"
      isError={pageError}
      isLoading={!loaded}
    >
      <Flex>
        <Box
          sx={{
            flex: 1,
            p: '40px',
            minWidth: '980px',
            height: 'calc(100vh - 88px)',
          }}
        >
          <Flex sx={{ alignSelf: 'center', minWidth: '975px' }}>
            <Box>
              <Flex>
                <FiltersMenu
                  activeFilters={hiveFilters}
                  toggleFilter={dispatch.Assets.toggleHiveFilter}
                  showProblemsOnly={dispatch.Assets.hiveShowProblemsOnly}
                  showAll={dispatch.Assets.hiveShowAll}
                />
                <Box sx={{ ml: '15px' }}>
                  <AssetTypeFilter
                    onChange={dispatch.Assets.setHiveAssetTypeFilter}
                    activeFilters={hiveFilters.assetTypes}
                  />
                </Box>
              </Flex>
            </Box>
            <Box sx={{ ml: '16px' }}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {hiveStats.totalAssets !== 0 && <AssetsStats {...hiveStats} />}
            </Box>

            <Box sx={{ ml: '16px' }}>
              <RefreshButton
                isRefreshing={isRefreshing}
                onClick={dispatch.Assets.fetchAssetsSummary}
              />
            </Box>
          </Flex>
          <HiveView
            sx={{ pt: '40px', pb: '40px' }}
            data={data}
            onAssetClick={setCurrentAsset}
            highlightedAssetId={highlightAssetId}
            onQuickScanHighlightComplete={onQuickScanHighlightComplete}
          />
        </Box>
        <Box
          sx={{
            p: '40px',
            borderLeft: '1px solid #eee',
            maxWidth: '443px',
            width: '443px',
            minWidth: '315px',
          }}
        >
          <ActivityLog
            activity={activityData.activity}
            loaded={activityData.loaded}
          />
        </Box>
      </Flex>
    </Page>
  )
}

export default Hive
