/** @jsx jsx */
import { Box, Flex, Heading, Image, jsx } from 'theme-ui'
import { Button, Tooltip } from 'antd'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { imageMap } from '../../../common/sources/imageMap'
import IntegrationWrapper from '../integration-wrapper'
import { supportWidget } from '../../../common/support-widget'
import { TextStyle } from './share'
import awsDeployStack from '../../../assets/png/cloudformation-launch-stack.png'
import { SnippetBox } from '../../../components/panels/snippet-box'
import PersonalTokenAlert from '../personal-token-alert'

const GithubNativeSourceIntegration = ({
  dataSource,
  onSwitchTokenShown,
  shouldShowTeamToken,
}) => {
  return (
    <IntegrationWrapper
      title="Github Bot"
      imgSrc={imageMap[dataSource?.tileKey]}
    >
      <Flex
        sx={{
          gap: 3,
          flexDirection: 'column',
          code: {
            backgroundColor: 'black',
            borderRadius: '4px',
            color: 'white',
            py: '2px',
            px: '5px',
          },
        }}
      >
        {shouldShowTeamToken && (
          <Box sx={{ my: 4 }}>
            <PersonalTokenAlert onSwitch={onSwitchTokenShown} />
          </Box>
        )}
        <TextStyle>
          Monitor, alert, and discover sensitive data in your code for each
          commit. Get instant feedback on any commit you push to your
          repository. Spectral Bot can be installed directly on organizations
          and user accounts and grant access to specific repositories.
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Setup Github App
        </Heading>
        <TextStyle>
          First you will need to create a{' '}
          <a
            href="https://github.com/settings/apps/new?description=Spectral%20Bot%20protects%20each%20commit%20pushed%20to%20your%20repositories&url=https://spectralops.io&webhook_url=YOUR_BOT_URL&webhook_active=true&events[]=push&events[]=pull_request&pull_requests=write&metadata=read&contents=read&deployments=write&checks=write&statuses=write"
            rel="noopener noreferrer"
            target="_blank"
          >
            new Github app
          </a>
          &nbsp;(you can create the app under your organization account if you
          change the url route from&nbsp;
          <code>https://github.com/settings/apps/new</code> to&nbsp;
          <code>
            https://github.com/organizations/YOUR_ORG_NAME/settings/apps/new
          </code>
          ).
          <br />
          Now, install the app on any number of your repos. <br />
          After you install the bot in the next section, go back to the github
          app settings and enter into webhook url the endpoint url of the bot
          and the webhook secret.
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Deploy the bot
        </Heading>
        Deploy using one of the following two methods:
        <Heading as="h3" variant="smalltitle">
          With AWS Lambda
        </Heading>
        <TextStyle>
          Next we'll deploy the lambda to your
          <Tooltip
            title={
              <Box>
                <strong>Required AWS permissions:</strong>
                <br /> lambda:GetAccountSettings
                <br /> cloudformation:DescribeStacks
                <br /> iam:CreateRole
                <br /> iam:DeleteRole
                <br /> apigateway:POST
                <br /> logs:CreateLogGroup
                <br /> iam:PutRolePolicy
              </Box>
            }
            sx={{ alignItems: 'center' }}
            overlayInnerStyle={{ display: 'inline-block' }}
          >
            {' '}
            AWS account{' '}
            <Box as="span" sx={{ verticalAlign: 'text-top' }}>
              <AiOutlineInfoCircle size={20} />
            </Box>
          </Tooltip>
          <br />
          Go ahead and&nbsp;
          <a
            href="https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=https://spectral-lambda-cloudformation.s3.amazonaws.com/spectral-github-probot-scanner/cloudformation-template.json&stackName=spectral-github-bot-scanner&param_StrictMode=false"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Image sx={{ mr: 2 }} src={awsDeployStack} />
          </a>
          <Box mt={2}>
            <ul>
              <li>
                To fill in the PRIVATE_KEY field, you&apos;ll need to generate a
                private key in your new Github app&apos;s settings and encode it
                with base64 (<code>base64 -i github_app_private_key.pem</code>).
              </li>
              <li>
                The options for the "Check Policy" field are: "Fail on any
                issue" / "Fail on low and above" / "Fail on medium and above" /
                "Fail on high and above" / "Fail on critical only" / "Always
                pass". This is for reference if you want to change it after
                deployment.
              </li>
              <li>
                Set the <code>STRICT_MODE</code> parameter to <code>true</code>{' '}
                to base the check status on all issues found in the modified
                files (even if the issues are old).
              </li>
            </ul>
          </Box>
        </TextStyle>
        <Heading as="h3" variant="smalltitle">
          With Docker
        </Heading>
        <TextStyle>
          Go to our{' '}
          <a
            href="https://hub.docker.com/r/checkpoint/spectral-github-scanner"
            rel="noopener noreferrer"
            target="_blank"
          >
            DockerHub repo for the github scanner
          </a>{' '}
          and follow instructions.
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          Complete the Github App Setup
        </Heading>
        <TextStyle>
          The last thing to do is take the gateway api / container endpoint url
          of the freshly deployed bot and the webhook secret you chose and copy
          them to the Github app (see in the Github app settings page under
          Webhook section). Set the webhook to active.
          <br />
          Now all commits for the selected repositories are protected!
        </TextStyle>
        <Heading as="h2" variant="smallheading">
          That&#39;s it{' '}
          <span role="img" aria-label="strong">
            💪
          </span>
        </Heading>
      </Flex>
      <Flex sx={{ mt: '40px', justifyContent: 'center' }}>
        <Button size="large" onClick={() => supportWidget.open()}>
          Need help? Talk to an expert
        </Button>
      </Flex>
    </IntegrationWrapper>
  )
}

export default GithubNativeSourceIntegration
