import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import useQuerystring from '../../hooks/useQuerystring'

export default ({ children, title, okText, qsParam }) => {
  const [currentQuery, setQuery] = useQuerystring()
  const [isOpen, setOpen] = useState(false)
  useEffect(() => {
    if (!isOpen && currentQuery[qsParam]) {
      setOpen(true)
      Modal.success({
        title,
        content: children,
        okText,
      })
      setQuery({}, true)
    }
  }, [isOpen, title, okText, currentQuery, qsParam, setQuery, children])

  return null
}
