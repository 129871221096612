import React from 'react'
import { Image, Flex, Text } from 'theme-ui'
import slackImage from '../../../assets/svg/slack.svg'
import msTeamsImage from '../../../assets/svg/ms-teams.svg'
import jiraImage from '../../../assets/svg/jira.svg'
import pdImage from '../../../assets/svg/pd.svg'
import mondayImg from '../../../assets/svg/monday.svg'

enum integrationStatus {
  Active = 'active',
  Inactive = 'inactive',
  NotSet = 'not set for team',
}

const getIntegrationStatus = (orgTeamSetting, isActive) => {
  if (!isActive) return integrationStatus.Inactive
  if (isActive && orgTeamSetting) return integrationStatus.Active
  return integrationStatus.NotSet
}

const integrationsMapping = {
  jira: { name: 'Jira', field: 'projectId', src: jiraImage },
  slack: { name: 'Slack', field: 'webhookUrls', src: slackImage },
  msTeams: { name: 'Microsoft Teams', field: 'webhookUrls', src: msTeamsImage },
  pd: { name: 'Pager Duty', field: 'routingServiceKey', src: pdImage },
  monday: { name: 'Monday', field: 'workspaceId', src: mondayImg },
}

const IntegrationsIcons = ({ orgTeam, globalIntegrations }) => {
  return (
    <Flex sx={{ flexDirection: 'column' }}>
      {Object.keys(globalIntegrations).map((integrationKey) => (
        <Flex
          sx={{ alignItems: 'center' }}
          key={`${orgTeam.orgTeamid}_${integrationKey}`}
        >
          <Image
            sx={{
              width: '25px',
              height: '25px',
              mr: 2,
              my: 2,
              filter:
                orgTeam.integrations[integrationKey] &&
                getIntegrationStatus(
                  orgTeam.integrations[integrationKey][
                    integrationsMapping[integrationKey]?.field
                  ],
                  globalIntegrations[integrationKey].isActive
                ) === integrationStatus.Active
                  ? 'grayscale(0)'
                  : 'grayscale(1)',
            }}
            alt={`${integrationKey}-icon`}
            src={integrationsMapping[integrationKey].src}
          />
          <Text>
            {`${integrationsMapping[integrationKey].name} integration is `}
            {getIntegrationStatus(
              orgTeam.integrations[integrationKey]
                ? orgTeam.integrations[integrationKey][
                    integrationsMapping[integrationKey].field
                  ]
                : undefined,
              globalIntegrations[integrationKey].isActive
            )}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}
export default IntegrationsIcons
