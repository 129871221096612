import { digestInfo, selfHostedProviders } from './shared'
import { SourceIntegrationComponent } from '../enums'

export const jfrogPipline = {
  component: SourceIntegrationComponent.SnippetWindow,
  mode: 'mitigate',
  data: {
    title: 'Jfrog Pipelines',
    content: 'Integrate as a test or script step',
    tileKey: 'jfrogPipline',
    selfHostedRelevantProviders: [
      selfHostedProviders.gitlabSelfHosted,
      selfHostedProviders.githubEnterprise,
      selfHostedProviders.bitbucketServer,
    ],
    snippets: [
      {
        title:
          'Store your SPECTRAL_DSN in <a href="https://www.jfrog.com/confluence/display/JFROG/Inserting+Secrets+in+Pipelines" target="_blank" rel="noopener noreferrer">Jfrog Pipelines Variables</a>',
        code: `{dsn}`,
        type: 'yaml',
      },
      {
        title: "Here's an example run of Spectral on Jfrog Pipelines:",
        code: `resources:
- name: myScannedRepo
  type: GitRepo
  configuration:
    # Your JFrog integration with Github
    gitProvider: "integration_name"
    # Github repository
    path: "org-name/repository-name"
    branches:
      include: main

pipelines:
- name: Spectral
  steps:
    - name: SpectralScan
      type: Bash
      configuration:
          integrations:
              - name: spectraldsn
          inputResources:
              - name: myScannedRepo
      execution:
        onExecute:
          - cd dependencyState/resources/myScannedRepo
          - curl -L "{baseUrl}/latest/x/sh?dsn=$int_spectraldsn_SPECTRAL_DSN" | sh
          - $HOME/.spectral/spectral scan --ok --dsn $int_spectraldsn_SPECTRAL_DSN {options}
`,
        type: 'yaml',
        postDescription: digestInfo,
      },
    ],
  },
}
