import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigType, RoleType } from '@spectral/types'
import { select } from '../../../redux/store'
import RemoteConfiguration from '../../../blocks/settings/remote-config/remote-config'
import { Page } from '../../../components/page'
import tracker from '../../../common/track'

export default () => {
  const dispatch = useDispatch()
  const currentTeam = useSelector(select.Teams.current)
  const {
    isLoading: isSavingAgentConfig,
    error: saveConfigurationError,
    success: scanConfigurationSuccess,
  } = useSelector(select.Teams.agentConfigStatus)
  const currentUser = useSelector((state) => state.Auth.user)
  const isTeamLoaded = useSelector(select.Teams.isTeamLoaded)

  const onSave = async (configurations, selectedAssetType) => {
    await dispatch.Teams.updateAgentConfig({
      agentConfig: configurations.find(
        (config) => config.id === selectedAssetType
      ),
      assetType: selectedAssetType,
    })
  }

  const onReset = async (configurationType: ConfigType, selectedAssetType) => {
    await dispatch.Teams.resetAgentConfig({
      configurationType,
      assetType: selectedAssetType,
    })
  }
  const triggerMixPanelConfigurationSavedEvent = (
    selectedAssetType: string,
    status: string,
    errorType = ''
  ) => {
    tracker.event('SCAN_CONFIGURATION_SAVED', {
      assetType: selectedAssetType,
      status,
      errorType,
    })
  }

  const triggerMixPanelShowFullYamlEvent = (selectedAssetType: string) => {
    tracker.event('SHOW_FULL_YAML', {
      assetType: selectedAssetType,
    })
  }

  const triggerMixPanelAddNewIgnoreEvent = (selectedAssetType: string) => {
    tracker.event('NEW_IGNORE_ADDED', {
      assetType: selectedAssetType,
    })
  }

  const triggerMixPanelRemoveIgnoreEvent = (selectedAssetType: string) => {
    tracker.event('IGNORE_REMOVED', {
      assetType: selectedAssetType,
    })
  }

  const triggerMixPanelUpdateIgnoreEvent = (selectedAssetType: string) => {
    tracker.event('IGNORE_UPDATED', {
      assetType: selectedAssetType,
    })
  }

  const triggerMixPanelTagsChangedEvent = (selectedAssetType: string) => {
    tracker.event('TAG_CHANGED', {
      assetType: selectedAssetType,
    })
  }

  const triggerMixPanelOverridesChangedEvent = (
    selectedAssetType,
    hardeningRules
  ) => {
    tracker.event('HARDENING_RULES_CHANGED', {
      assetType: selectedAssetType,
      ...hardeningRules,
    })
  }

  const formattedConfigs = {}
  currentTeam.agentConfig?.reduce((accumulatedConfigs, currentConfig) => {
    accumulatedConfigs[currentConfig.id] = currentConfig.config
    return accumulatedConfigs
  }, formattedConfigs)

  return (
    <Page
      style={{ minWidth: '1200px' }}
      name="SETTINGS_REMOTE_CONFIG"
      title="Scan configuration"
      isLoading={!isTeamLoaded}
    >
      <RemoteConfiguration
        canUserEdit={[RoleType.Owner, RoleType.Admin].includes(
          currentUser.role
        )}
        onSave={onSave}
        onReset={onReset}
        configurationsData={formattedConfigs}
        isSavingAgentConfig={isSavingAgentConfig}
        saveConfigurationError={saveConfigurationError}
        scanConfigurationSuccess={scanConfigurationSuccess}
        triggerMixPanelConfigurationSavedEvent={
          triggerMixPanelConfigurationSavedEvent
        }
        triggerMixPanelShowFullYamlEvent={triggerMixPanelShowFullYamlEvent}
        triggerMixPanelTagsChangedEvent={triggerMixPanelTagsChangedEvent}
        triggerMixPanelRemoveIgnoreEvent={triggerMixPanelRemoveIgnoreEvent}
        triggerMixPanelAddNewIgnoreEvent={triggerMixPanelAddNewIgnoreEvent}
        triggerMixPanelUpdateIgnoreEvent={triggerMixPanelUpdateIgnoreEvent}
        triggerMixPanelOverridesChangedEvent={
          triggerMixPanelOverridesChangedEvent
        }
      />
    </Page>
  )
}
