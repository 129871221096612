/** @jsx jsx */
import { CheckCircleOutlined } from '@ant-design/icons'
import { Box, jsx } from 'theme-ui'
import { Popover } from 'antd'
import { formatDistanceToNow } from 'date-fns'
import { IssueStatus } from '@spectral/types'

const ResolvePopOver = ({ resolvedDate, status }) => {
  return resolvedDate && status && status === IssueStatus.Resolved ? (
    <Box>
      <Popover
        content={
          <Box>
            <Box>
              This issue was resolved{' '}
              {formatDistanceToNow(new Date(resolvedDate))} ago
            </Box>
          </Box>
        }
        trigger="hover"
      >
        <CheckCircleOutlined />
      </Popover>
    </Box>
  ) : null
}

export default ResolvePopOver
