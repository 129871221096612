/** @jsx jsx */
import { useEffect, useState } from 'react'
import { jsx } from 'theme-ui'
import { AutomationSource } from '@spectral/types'
import View from '../../../blocks/source-integration/independent-integrations/tfc'
import { sdkClient } from '../../../redux/store'
import { INTEGRATION_STATUS_INTERVAL } from './consts'

const TfcSourceIntegration = ({
  source: { data },
  setIsTeamTokenShown,
  shouldShowTeamToken,
}) => {
  const [isIntegrated, setIsIntegrated] = useState<boolean>(false)
  const [isFetchingIntegrationCheck, setIsFetchingIntegrationCheck] = useState<
    boolean
  >(true)

  useEffect(() => {
    const fetchIsIntegrated = async () => {
      const isIntegratedResult = await sdkClient.assets().existByFilters({
        params: {
          assetSource: [
            AutomationSource.TerraformCloud,
            AutomationSource.TerraformEnterprise,
          ],
        },
      })
      // @ts-ignore
      setIsIntegrated(isIntegratedResult.exist)
      setIsFetchingIntegrationCheck(false)
    }
    fetchIsIntegrated()
    const fetchInterval = setInterval(
      fetchIsIntegrated,
      INTEGRATION_STATUS_INTERVAL
    )

    return () => {
      clearInterval(fetchInterval)
    }
  }, [])

  return (
    <View
      dataSource={data}
      isConnected={isIntegrated}
      statusLoaded={!isFetchingIntegrationCheck}
      onSwitchTokenShown={setIsTeamTokenShown}
      shouldShowTeamToken={shouldShowTeamToken}
    />
  )
}

export default TfcSourceIntegration
