import React, { useCallback } from 'react'
import * as Yup from 'yup'
import { Box, Flex } from 'theme-ui'
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Tooltip } from 'antd'
import {
  ContentPanel,
  ContentPanelHeader,
  CopyFieldSection,
  FieldSectionLayout,
  FileUploadFieldSection,
  MultiInputFieldSection,
  PanelForm,
  SubmitSection,
} from '../../../components/panels/content'
import { validateDomainString } from '../../../utils/validators'

type Props = {
  domains: Array<string>
  metadataXMLAlreadySet: boolean
  onSubmit: Function
  audienceUrl: string
  ssoUrl: string
  loginUrl: string
  samlConfigurationCompleted: boolean
}

const SAMLAuthSettings = ({
  ssoUrl,
  audienceUrl,
  loginUrl,
  domains,
  metadataXMLAlreadySet,
  onSubmit,
  samlConfigurationCompleted,
}: Props) => {
  const onFileUploadChange = useCallback(
    (fieldName, fileBlob, setFieldValue) => {
      const reader = new FileReader()
      reader.readAsText(fileBlob)
      reader.onload = async (e) => {
        const fileContent = e.target.result
        setFieldValue(fieldName, fileContent)
      }
    },
    []
  )

  return (
    <Flex sx={{ gap: '40px', mt: '40px', flexDirection: 'column' }}>
      <ContentPanel>
        <ContentPanelHeader>
          1. Configure your idP with the following information:
        </ContentPanelHeader>
        <PanelForm
          onSubmit={onSubmit}
          initialValues={{
            ssoUrl,
            audienceUrl,
            loginUrl,
          }}
        >
          <CopyFieldSection
            name="ssoUrl"
            value={ssoUrl}
            label="Single Sign-On URL (ACS)"
            description="ACS (Assertion Consumer Service) URL is where the idP would send it's SAML response after authenticating a user"
          />

          <CopyFieldSection
            name="audienceUrl"
            value={audienceUrl}
            label="Audience URL (Entity ID)"
            description="This is a unique name (in this case a URL, also can be referred as Entity ID) to represent your Spectral Organization within your idP"
          />
        </PanelForm>
      </ContentPanel>

      <ContentPanel>
        <ContentPanelHeader>
          2. Provide Spectral the following information:
        </ContentPanelHeader>
        <PanelForm
          onSubmit={onSubmit}
          initialValues={{
            metadata: '',
            domains: domains || [],
          }}
          validationSchema={Yup.object({
            metadata: Yup.string(),
            domains: Yup.array()
              .of(validateDomainString().min(1))
              .min(1, 'Please add at least one email domain')
              .required('Email domains of your organization are required'),
          })}
        >
          <MultiInputFieldSection
            required
            placeholder="spectralops.io"
            name="domains"
            label="Email domains of your organization"
            description="Only users having an email address of those domains would be able to login through SSO."
            layout={FieldSectionLayout.Horizontal}
          />

          <FileUploadFieldSection
            required
            name="metadata"
            label={
              <Flex>
                Identity Provider metadata
                <Box sx={{ ml: '8px', fontSize: '20px', mt: '-3px' }}>
                  <Tooltip title="While metadata XML already exists, an upload of new metadata would override the existing metadata XML">
                    {' '}
                    <ExclamationCircleOutlined />
                  </Tooltip>
                </Box>
              </Flex>
            }
            description="Upload the Metadata XML generated by you idP (.xml extension required)"
            allowedFileTypes={['.xml']}
            layout={FieldSectionLayout.Horizontal}
            textBelowInput={
              metadataXMLAlreadySet && (
                <Box
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  <CheckCircleOutlined /> Metadata XML already set
                </Box>
              )
            }
            customOnChange={onFileUploadChange}
          />

          <SubmitSection text="Update" />
        </PanelForm>
      </ContentPanel>

      {samlConfigurationCompleted && (
        <ContentPanel>
          <ContentPanelHeader>
            3. Login from your Identity Provider or use Spectral login URL
          </ContentPanelHeader>
          <PanelForm
            onSubmit={null}
            initialValues={{
              loginUrl,
            }}
          >
            <CopyFieldSection
              name="loginUrl"
              value={loginUrl}
              label="Login URL"
              description="This is the URL that your team should use to login via SSO"
            />
          </PanelForm>
        </ContentPanel>
      )}
    </Flex>
  )
}

export default SAMLAuthSettings
