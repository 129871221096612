import React from 'react'
import * as Yup from 'yup'
import { Box, Text } from 'theme-ui'
import { Link } from 'react-router-dom'
import { MS_TEAMS_WEBHOOK_URL_REGEX } from '@spectral/types'
import {
  PanelForm,
  InputPasswordFieldSection,
  SubmitSection,
} from '../../../components/panels/content'
import msTeams from '../../../assets/svg/ms-teams.svg'
import { IntegrationSection } from '../../../components/integrations/integration-sections'

export const MsTeamsIntegrations = ({
  setMsTeamsEnableDisable,
  setMsTeamsUrl,
  enabled,
  msTeamsWebhookUrl,
}) => {
  return (
    <IntegrationSection
      enabled={enabled}
      onEnableToggle={setMsTeamsEnableDisable}
      integrationName="MsTeams"
      headerLogo={msTeams}
      headerTitle="Microsoft Teams Global Integration"
      sectionTitle=""
      sectionDescription="Use Microsoft Teams for real time notifications"
      sectionRenderedContent={() => (
        <Box sx={{ mt: '20px' }}>
          <Text>
            This settings will direct all system notification to the configured
            channel
          </Text>
          <Text>
            For settings a specific team channel, go to{' '}
            <Link to="/settings/org-teams">Teams</Link>
          </Text>
          <Text sx={{ fontWeight: 600, marginTop: '20px' }}>
            Creating a Microsoft Teams webhook
          </Text>
          <Text>1. Add webhook app to existing channel</Text>
          <Text>2. Configure webhook URL for Spectral alerts</Text>
          <Text>3. Copy your webhook URL here</Text>
          <Text sx={{ mt: '10px' }}>
            For more information visit{' '}
            <a
              href="https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook"
              rel="noopener noreferrer"
              target="_blank"
            >
              the Microsoft Teams docs
            </a>
          </Text>
        </Box>
      )}
    >
      <PanelForm
        onSubmit={setMsTeamsUrl}
        validationSchema={Yup.object({
          msTeamsWebhookUrl: Yup.string().matches(
            RegExp(MS_TEAMS_WEBHOOK_URL_REGEX),
            'Must be a valid Microsoft Teams Webhook'
          ),
        })}
        initialValues={{
          enabled,
          msTeamsWebhookUrl,
        }}
      >
        <InputPasswordFieldSection
          name="msTeamsWebhookUrl"
          label="Microsoft Teams Webhook URL"
          description="Paste your Microsoft Teams Webhook URL here"
          placeholder="https://NAME-SPACE.webhook.office.com/webhookb2/T000/IncomingWebhook/B001/R002"
          merge
        />
        <SubmitSection text="Update" />
      </PanelForm>
    </IntegrationSection>
  )
}
