import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 8px 0 0;
  min-width: 0;
`

const Frame = styled.section`
  width: 100%;
  position: relative;
  min-height: auto;
  box-shadow: rgb(88 26 196 / 35%) 0px 8px 6px -7px;
  border: 0.05rem solid rgb(0, 0, 0);
  background: rgb(0, 0, 0);
  color: white;
  padding: 2rem 1.4rem 0px;
  resize: none;
  min-height: 0;
  min-width: 11rem;
  max-width: 100%;
  border-radius: 0.5rem;
  position: relative;
  overflow: auto;
  z-index: 2;

  &:before {
    height: 2rem;
    line-height: 2rem;
    color: rgb(204, 204, 204);
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif,
      'Apple Color Emoji';
    text-align: center;
    border-bottom: 0.05rem solid rgb(0, 0, 0);
    background: padding-box rgb(0, 0, 0);
  }
  &:after {
    top: 0.8rem;
    left: 0.8rem;
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    z-index: 2;
    border-radius: 50%;
    background: rgb(248, 89, 85);
    box-shadow: rgb(248 89 85) 0px 0px 0px 0.2rem,
      rgb(251 190 63) 1.2rem 0px 0px 0.2rem,
      rgb(69 204 75) 2.3rem 0px 0px 0.2rem;
  }
`
const Pre = styled.pre`
  padding: 1.3rem 0.6rem;
  margin-bottom: 0;
`

const Terminal = ({ children }) => {
  return (
    <Wrapper>
      <Frame>
        <Pre>{children}</Pre>
      </Frame>
    </Wrapper>
  )
}

export default Terminal
