import React from 'react'
import * as Yup from 'yup'
import {
  IssueIgnoreTypes,
  IssueIgnoreTypeView,
  IssueIgnoreAction,
} from '@spectral/types'
import { Tabs } from 'antd'
import { Box } from 'theme-ui'
import {
  FieldSectionLayout,
  PanelForm,
  RadioButtonFieldSection,
  SubmitSection,
  TextFieldSection,
} from '../../components/panels/content'

const { TabPane } = Tabs

const tabKeyFalsePositive = 'ignoreFalsePositive'
const tabKeyWontFix = 'ignoreWontFix'
const tabKeySnooze = 'ignoreSnooze'

const ignoreValues = {
  7: 7,
  30: 30,
  90: 90,
}

const IgnoreTimeFrame = () => (
  <RadioButtonFieldSection
    name="ignoreTimeFrame"
    label="Ignore for (Days)"
    description=""
    defaultValue={ignoreValues[7]}
    radioButtonsArray={Object.entries(ignoreValues).map(([key, value]) => ({
      value,
      label: key,
      key: `${value}_${key}`,
    }))}
  />
)

const Ignore = ({ selectedIssues, onIgnoreClicked, type, buttonType }) => {
  return (
    <PanelForm
      validationSchema={Yup.object({
        [`commentIgnore_${type}`]: Yup.string()
          .required('Required')
          .min(1, 'Ignore comment must not be empty')
          .max(200, 'Ignore comment must be 200 characters max'),
      })}
      onSubmit={(vals) => {
        onIgnoreClicked({
          issues: selectedIssues,
          comment: vals[`commentIgnore_${type}`],
          ignoreType: type,
          ignoreAction: IssueIgnoreAction.add,
          buttonType,
        })
      }}
      initialValues={{ commentIgnorePermanent: '' }}
    >
      <Box>
        <TextFieldSection
          name={`commentIgnore_${type}`}
          label=""
          height={100}
          description=""
          placeholder="Add your comment here"
          layout={FieldSectionLayout.Vertical}
          merge
        />
        <SubmitSection text="Save" />
      </Box>
    </PanelForm>
  )
}

const Snooze = ({ selectedIssues, onSnoozeClicked, buttonType }) => {
  return (
    <PanelForm
      validationSchema={Yup.object({
        commentIgnorePostponed: Yup.string()
          .required('Required')
          .min(1, 'Ignore comment must not be empty')
          .max(200, 'Ignore comment must be 200 characters max'),
      })}
      onSubmit={(vals) => {
        onSnoozeClicked({
          issues: selectedIssues,
          comment: vals.commentIgnorePostponed,
          snoozeAction: IssueIgnoreAction.add,
          daysToSnooze: vals.ignoreTimeFrame,
          buttonType,
        })
      }}
      initialValues={{ commentIgnorePostponed: '', ignoreTimeFrame: 7 }}
    >
      <Box>
        <TextFieldSection
          name="commentIgnorePostponed"
          label=""
          height={100}
          description=""
          placeholder="Add your comment here"
          layout={FieldSectionLayout.Vertical}
          merge
        />
        <IgnoreTimeFrame />
        <SubmitSection text="Save" />
      </Box>
    </PanelForm>
  )
}

export default ({
  onIgnoreClicked,
  onSnoozeClicked,
  selectedIssues,
  defaultIgnoreOption = tabKeyFalsePositive,
  buttonType,
}) => {
  return (
    <Box>
      <Box sx={{ minWidth: '450px' }}>
        <Tabs defaultActiveKey={defaultIgnoreOption} type="card">
          <TabPane
            tab={IssueIgnoreTypeView[IssueIgnoreTypes.falsePositive]}
            key={tabKeyFalsePositive}
          >
            <Ignore
              selectedIssues={selectedIssues}
              onIgnoreClicked={onIgnoreClicked}
              type={IssueIgnoreTypes.falsePositive}
              buttonType={buttonType}
            />
          </TabPane>
          <TabPane
            tab={IssueIgnoreTypeView[IssueIgnoreTypes.wontFix]}
            key={tabKeyWontFix}
          >
            <Ignore
              selectedIssues={selectedIssues}
              onIgnoreClicked={onIgnoreClicked}
              type={IssueIgnoreTypes.wontFix}
              buttonType={buttonType}
            />
          </TabPane>
          <TabPane
            tab={IssueIgnoreTypeView[IssueIgnoreTypes.snooze]}
            key={tabKeySnooze}
          >
            <Snooze
              selectedIssues={selectedIssues}
              onSnoozeClicked={onSnoozeClicked}
              buttonType={buttonType}
            />
          </TabPane>
        </Tabs>
      </Box>
    </Box>
  )
}
