/** @jsx jsx */
import { Flex, Box, jsx } from 'theme-ui'
import { Link } from 'react-router-dom'
import { PrimaryButtonLarge } from '../../components'
import { UserBar } from '../../components/bars/user-bar'

const Header = ({ userbar, location, ctaMap }) => {
  const cta = ctaMap[location.pathname]
  return (
    <Box
      sx={{
        height: '88px',
        zIndex: '200',
        position: 'sticky',
        top: '0px',
        p: '20px',
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        backdropFilter: 'blur(12px)',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        boxShadow: 'heading',
      }}
    >
      <Flex
        sx={{
          minWidth: '490px',
          justifyContent: 'flex-end',
        }}
      >
        {cta && (
          <Link to={cta.to}>
            <PrimaryButtonLarge text={cta.text} icon={cta.icon} />
          </Link>
        )}
        <UserBar
          user={{ name: ['name'] }}
          links={[]}
          sx={{ ml: '20px' }}
          location={location}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...userbar}
        />
      </Flex>
    </Box>
  )
}

export default Header
