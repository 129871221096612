import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from '../../../../redux/store'
import MembersSeeAllAssets from '../../../../blocks/settings/teams/members-see-all-assets/view'

const MembersSeeAllAssetsConnected = () => {
  const dispatch = useDispatch()
  const currentTeam = useSelector(select.Teams.current)

  return (
    <MembersSeeAllAssets
      currentTeam={currentTeam}
      submit={dispatch.Teams.updateMembersSeeAllAssets}
    />
  )
}

export default MembersSeeAllAssetsConnected
