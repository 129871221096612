/** @jsx jsx */
import { jsx, Heading, Text, Box } from 'theme-ui'
import { Badge } from 'antd'
import { ListItemWithIcon, ListGroup } from './list-items'
import { LinkButton } from '../../../components'
import PlanCard from './plan-card'

const FreePlan = ({ isCurrentPlan }: { isCurrentPlan: boolean }) => {
  const planCard = (
    <PlanCard>
      <Box>
        <Heading as="h3" variant="cardTitle">
          Free
        </Heading>
        <Text variant="summary">
          Code security for open-source or small teams
        </Text>
      </Box>
      <Box>
        <Text variant="title">$0</Text>
        <Text variant="summary">Free forever</Text>
      </Box>
      <Box>
        <LinkButton
          sx={{ pointerEvents: isCurrentPlan ? 'none' : 'auto' }}
          text={isCurrentPlan ? 'Current Plan' : 'Get Started'}
          href="mailto:sales@spectralops.io"
          block
          size="large"
        />
      </Box>
      <Box>
        <Text sx={{ mb: 3 }}>Start scanning free with key features:</Text>
      </Box>
      <Box>
        <ListGroup>
          <ListItemWithIcon text="Up to 30 scans per day" />
          <ListItemWithIcon text="Up to 10 repositories" />
          <ListItemWithIcon text="Up to 10 contributors" />
          <ListItemWithIcon text="Chat support only" />
          <ListItemWithIcon text="Month of data retention" />
          <ListItemWithIcon text="Mail and Slack notifications" />
          <ListItemWithIcon text="Playbooks" />
        </ListGroup>
      </Box>
    </PlanCard>
  )

  if (isCurrentPlan) {
    return (
      <Badge.Ribbon text="Your Plan" color="cyan">
        {planCard}
      </Badge.Ribbon>
    )
  }
  return planCard
}

export default FreePlan
