import { Box, Text } from 'theme-ui'
import React from 'react'

export default () => (
  <Box sx={{ mt: '20px' }}>
    <Text sx={{ fontWeight: 600 }}>Integrate PagerDuty with Spectral</Text>
    <Text>
      1. Create Routing service key for the required service.{' '}
      <a
        href="https://support.pagerduty.com/docs/services-and-integrations#add-integrations-to-an-existing-service"
        rel="noopener noreferrer"
        target="_blank"
      >
        Instructions
      </a>
    </Text>
    <Text>2. Insert the key.</Text>
    <Text>
      3. You can click on Test in order to send a test event toward the
      configured service.
    </Text>
  </Box>
)
