/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import L from 'lodash'
import { memo } from 'memodiff'
import HiveCard from '../../blocks/hiveCard'

const assetComparator = ({ assets }) => assets.length

const HiveViewFC = ({
  className = null,
  data,
  onAssetClick,
  highlightedAssetId = null,
  onQuickScanHighlightComplete,
}) => (
  <Flex
    className={className}
    sx={{ flexWrap: 'wrap', alignItems: 'flex-start' }}
  >
    {L.sortBy(data, assetComparator)
      .reverse()
      .map((cardData) => {
        return (
          <HiveCard
            sx={{ marginRight: '40px', marginBottom: '40px' }}
            teamName={cardData.teamName}
            lastScanTime={cardData.lastScanTime}
            assets={cardData.assets}
            totalNumberOfCritical={cardData.totalNumberOfCritical}
            totalNumberOfHigh={cardData.totalNumberOfHigh}
            totalNumberOfMedium={cardData.totalNumberOfMedium}
            totalNumberOfLow={cardData.totalNumberOfLow}
            totalNumberOfInformational={cardData.totalNumberOfInformational}
            totalNumberOfIgnored={cardData.totalNumberOfIgnored}
            assetTypes={cardData.assetTypes}
            onAssetClick={onAssetClick}
            key={cardData.teamName}
            numberOfHiddenRepositories={cardData.numberOfHiddenRepositories}
            highlightedAssetId={highlightedAssetId}
            onQuickScanHighlightComplete={onQuickScanHighlightComplete}
          />
        )
      })}
  </Flex>
)

const HiveView = memo(HiveViewFC, L.isEqual)
export { HiveView }
