/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import EmbeddedSidebar from './embedded-sidebar'
import { paths } from '../../routes'

const EmbeddedLayout = ({ children, location }) => {
  return (
    <Box sx={{ height: '100%' }}>
      <EmbeddedSidebar links={paths.closed} location={location} />
      <Box
        sx={{
          marginLeft: '200px',
        }}
      >
        <Box
          sx={{
            flex: 1,
            height: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default EmbeddedLayout
