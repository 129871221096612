import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Tag } from 'antd'
import Slicer from '../../components/slicer'
import { UserNameView } from './common-view/member-username-common-view'

const MAX_TEAMS = 6
const memberView = ({ member }) => {
  return (
    <Flex
      sx={{ mb: '16px', justifyContent: 'space-between' }}
      key={member.username}
    >
      <UserNameView member={member} />
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ margin: '0 10px 0 10px' }}>
          {member.orgTeams && (
            <Slicer
              maxItems={MAX_TEAMS}
              showMore={<Tag>+ {member.orgTeams.length - MAX_TEAMS} more</Tag>}
            >
              {member.orgTeams.map((team) => (
                <Tag key={`member-view-tag-${team}`}>{team}</Tag>
              ))}
            </Slicer>
          )}
        </Box>
        <Box sx={{ margin: '0 10px 0 10px', width: '65px' }}>
          <Text>{member.role}</Text>
        </Box>
      </Flex>
    </Flex>
  )
}

export default memberView
